import {SelectedOptionsMap} from '../../../../../types/product/SelectedOptionsMap'

function getSelectedOptionsIds(selectedOptionsMap: SelectedOptionsMap): string[] {
  let selectedOptionsIds: string[] = []

  for (let optionCategoryId in selectedOptionsMap) {
    for (let checkedOption in selectedOptionsMap[optionCategoryId]) {
      if (selectedOptionsMap[optionCategoryId][checkedOption]) {
        if (!selectedOptionsIds.includes(checkedOption)) {
          selectedOptionsIds.push(checkedOption)
        }
      }
    }
  }
  return selectedOptionsIds
}

export {getSelectedOptionsIds}
