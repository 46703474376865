import {apiGet} from '../../api'
import {CONSTANTS} from '../../../constants'
import {MenuCategoryDTO, ProductMap, WeightMapDto} from 'grilnica-store-share'

async function loadCategoryListService(
  cityId: string,
  errorActions?: any,
): Promise<MenuCategoryDTO[]> {
  try {
    const categoryList: MenuCategoryDTO[] = await apiGet(
      CONSTANTS.apiStoreUrl + '/menu/' + cityId,
      {
        cityId: cityId,
      },
    )
    for (let category of categoryList) {
      if (!category.parentMenuCategoryId) {
        categoryList.push({
          menuCategoryId: category.alias + '-actions',
          alias: category.alias + '-actions',
          name: 'Акции',
          parentMenuCategoryId: category.menuCategoryId,
          isHideInApp: false,
          isActionCategory: true,
        })
      }
    }
    return categoryList
  } catch (error) {
    console.error(`loadCategoryListService`, error)
  }
}

async function loadProductMapService(cityId: string): Promise<ProductMap> {
  try {
    return await apiGet(CONSTANTS.apiStoreUrl + '/product/map-by-category/' + cityId, {
      cityId: cityId,
    })
  } catch (error) {
    console.error('Error loadProductMapService: ', error)
  }
}

async function loadWeightMapService(cityId: string): Promise<WeightMapDto> {
  try {
    return await apiGet(CONSTANTS.apiStoreUrl + '/product/weight-map', {
      cityId,
    })
  } catch (error) {
    console.error('Error loadWeightMapService: ', error)
  }
}

async function loadProductMapTerminalService(cityId: string): Promise<ProductMap> {
  try {
    return await apiGet(CONSTANTS.apiStoreUrl + '/product/map-by-category/by-terminal-alias', {
      cityId: cityId,
    })
  } catch (error) {
    console.info(`Error loadProductMapTerminalService`)
  }
}

export {
  loadCategoryListService,
  loadProductMapService,
  loadWeightMapService,
  loadProductMapTerminalService,
}
