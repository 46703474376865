import React from 'react'
import {Container} from 'grilnica-reactstrap'

interface PageBodyProps {
  children: any
  isContainer?: boolean
}

const PageBody: React.FC<PageBodyProps> = ({isContainer, children}): React.ReactElement => {
  children = isContainer ? <Container size={'normal'}>{children}</Container> : children
  return <main className={'page-body'}>{children}</main>
}

export default PageBody
