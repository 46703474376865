import React, {useEffect} from 'react'
import {connectComponent} from '../../../../store/common'
import {bindInitializeActions} from '../../../../store/ducks/initialize'

interface InitializeAppProps {
  isInitialized: boolean
  initializeActions: any
}

const InitializeApp: React.FC<InitializeAppProps> = ({
  isInitialized,
  initializeActions,
  children,
}): React.ReactElement => {
  useEffect(() => {
    initializeActions.initializeRequest()
  }, [initializeActions])

  if (isInitialized) {
    return <>{children}</>
  } else {
    return null
  }
}

export default connectComponent(
  (state) => ({
    isInitialized: state.initialize.isInitialized,
  }),
  (dispatch) => ({
    initializeActions: bindInitializeActions(dispatch),
  }),
  InitializeApp,
)
