import {SelectedOptionsMap} from '../../../../../types/product/SelectedOptionsMap'
import {OptionCategoryMap} from '../../../../../types/product/OptionCategoryMap'

function getProductVariantOptions(
  selectedOptionsMap: SelectedOptionsMap,
  optionCategoryMap: OptionCategoryMap,
): string[] {
  let productVariantOptions: string[] = []

  for (let optionCategoryId in selectedOptionsMap) {
    for (let checkedOption in selectedOptionsMap[optionCategoryId]) {
      if (optionCategoryMap[optionCategoryId].isProductVariantDefine) {
        if (selectedOptionsMap[optionCategoryId][checkedOption]) {
          productVariantOptions.push(checkedOption)
        }
      }
    }
  }
  return productVariantOptions
}

export {getProductVariantOptions}
