import React, {ReactNode, useMemo} from 'react'
import {ButtonLink, Row} from 'grilnica-reactstrap'
import {SaleCard} from './SaleCard'
import {ActionDTO} from 'grilnica-store-share'
import {getStaticUrl} from '../../../../../utils/static'

interface SaleListProps {
  saleList: ActionDTO[]
}

const SaleList: React.FC<SaleListProps> = ({saleList}): React.ReactElement => {
  const renderContent: React.ReactElement = useMemo(() => {
    if (saleList?.length > 0) {
      const items: ReactNode[] = saleList.map((item) => (
        <SaleCard sale={item} key={item.actionId} />
      ))
      return <Row>{items}</Row>
    } else {
      return (
        <div className={'d-flex flex-column align-items-center pt-x4'}>
          <img
            src={getStaticUrl('/img/no-sale-img.svg')}
            alt={'Акций нет'}
            title={'Акций нет'}
            width={375}
            height={301}
            className={'mb-x6'}
          />
          <p className={'no-sale text-center mb-x6'}>На данный момент акций нет</p>
          <ButtonLink to={'/'} color={'primary'} isRoundedButton>
            Перейти в меню
          </ButtonLink>
        </div>
      )
    }
  }, [saleList])
  return <>{renderContent}</>
}

export {SaleList}
