import {SelectedProductDTO} from 'grilnica-store-share'
import {SelectedOptionsMap} from '../../../../../../../../../types/product/SelectedOptionsMap'
import {OptionCategoryMap} from '../../../../../../../../../types/product/OptionCategoryMap'
import {ProductVariantMap} from '../../../../../../../../../types/product/ProductVariantMap'
import {RestrictionsMap} from '../../../../../../../../../types/product/RestrictionsMap'
import {CountSelectedOptionsMap} from '../../../../../../../../../types/product/CountSelectedOptionsMap'
import {ExtraOptionsMap} from '../../../../../../../../../types/product/ExtraOptionsMap'
import {OptionMap} from '../../../../../../../../../types/product/OptionMap'
import OptionProductMap from '../../../../../../../../../types/product/OptionProductMap'
import {getKeyByOptionIds} from '../utils/getKeyByOptionIds'
import CategoryAndOption from '../../../../../../../../../types/product/CategoryAndOption'

class Product {
  protected _selectedProduct: SelectedProductDTO
  protected _selectedOptionsMap: SelectedOptionsMap
  protected _optionCategoryMap: OptionCategoryMap
  protected _productVariantMap: ProductVariantMap
  protected _restrictionsMap: RestrictionsMap
  protected _countSelectedOptionsMap: CountSelectedOptionsMap
  private _extraOptionsMap: ExtraOptionsMap
  protected _optionMap: OptionMap
  private _sumPriceOptions: number
  protected _optionProductMap: OptionProductMap

  constructor(
    selectedProduct: SelectedProductDTO,
    selectedOptionsMap: SelectedOptionsMap,
    optionCategoryMap: OptionCategoryMap,
    productVariantMap: ProductVariantMap,
    countSelectedOptionsMap: CountSelectedOptionsMap,
    optionMap: OptionMap,
    optionProductMap: OptionProductMap,
  ) {
    this._selectedProduct = selectedProduct
    this._selectedOptionsMap = selectedOptionsMap
    this._optionCategoryMap = optionCategoryMap
    this._productVariantMap = productVariantMap
    this._countSelectedOptionsMap = countSelectedOptionsMap
    this._restrictionsMap = {}
    this._extraOptionsMap = {}
    this._optionMap = optionMap
    this._sumPriceOptions = 0
    this._optionProductMap = optionProductMap
  }

  public getRestrictionsMap(): RestrictionsMap {
    const productVariantOptions: string[] = []
    this.getProductVariantOptionsAndSelectedOptions(productVariantOptions)
    for (let categoryOption in this._optionCategoryMap) {
      if (
        this._optionCategoryMap[categoryOption].productVariantRestrictions &&
        this._productVariantMap
      ) {
        const productVariantId: string =
          this._productVariantMap[getKeyByOptionIds(productVariantOptions)].productVariantId
        this._restrictionsMap[categoryOption] =
          this._optionCategoryMap[categoryOption].productVariantRestrictions[productVariantId]
      } else {
        this._restrictionsMap[categoryOption] = this._optionCategoryMap[categoryOption].restrictions
      }
    }
    return this._restrictionsMap
  }

  public getSelectedOptionsMap(): SelectedOptionsMap {
    return this._selectedOptionsMap
  }

  public getCountSelectedOptionsMap(): CountSelectedOptionsMap {
    return this._countSelectedOptionsMap
  }

  public getSelectedProduct(): SelectedProductDTO {
    return this._selectedProduct
  }

  public getSumPriceOptions(): number {
    return this._sumPriceOptions
  }

  public toggleOption(
    optionCategoryId: string,
    optionId: string,
    isChecked: boolean,
    isOptional: boolean,
  ): void {
    if (!isOptional) {
      this.uncheckRequiredOption(optionCategoryId)
    }
    for (let optionCategoryId of this._optionMap[optionId].optionCategoryIds) {
      this.changeSelectedOption(optionCategoryId, optionId, isChecked)
      this.changeCountSelectedOptionsMap(optionCategoryId, isChecked, isOptional)
    }
  }

  public getExtraOptionsMap(): ExtraOptionsMap {
    for (let optionCategoryId in this._countSelectedOptionsMap) {
      if (
        this._countSelectedOptionsMap[optionCategoryId] >
        this._restrictionsMap[optionCategoryId].max
      ) {
        this._extraOptionsMap[optionCategoryId] = true
      }
    }
    return this._extraOptionsMap
  }

  public addSelectedProductPriceAndProductVariant(): void {
    let productVariantOptions: string[] = []
    let selectedOptions: string[] = []
    this.getProductVariantOptionsAndSelectedOptions(productVariantOptions, selectedOptions)
    if (
      this._productVariantMap &&
      this._productVariantMap[getKeyByOptionIds(productVariantOptions)]
    ) {
      let productVariantPrice: number =
        this._productVariantMap[getKeyByOptionIds(productVariantOptions)].price
      const productVariantId: string =
        this._productVariantMap[getKeyByOptionIds(productVariantOptions)].productVariantId

      this._selectedProduct.price = productVariantPrice
      this._selectedProduct.productVariantId = productVariantId
    } else {
      this._selectedProduct.price = this._selectedProduct.product.price
    }
    if (selectedOptions.length !== 0) {
      for (let selectedOption of selectedOptions) {
        this._sumPriceOptions = this._sumPriceOptions + this._optionMap[selectedOption].price
      }
      this._selectedProduct.price = this._selectedProduct.price + this._sumPriceOptions
    }
  }

  private getProductVariantOptionsAndSelectedOptions(
    productVariantOptions: string[],
    selectedOptions?: string[],
  ): void {
    for (let optionCategoryId in this._selectedOptionsMap) {
      for (let checkedOption in this._selectedOptionsMap[optionCategoryId]) {
        if (this._optionCategoryMap[optionCategoryId].isProductVariantDefine) {
          if (this._selectedOptionsMap[optionCategoryId][checkedOption]) {
            productVariantOptions.push(checkedOption)
          }
        } else if (selectedOptions) {
          if (this._selectedOptionsMap[optionCategoryId][checkedOption]) {
            if (!selectedOptions.includes(checkedOption)) {
              selectedOptions.push(checkedOption)
            }
          }
        }
      }
    }
  }

  private uncheckRequiredOption(optionCategoryId: string): void {
    for (let option in this._selectedOptionsMap[optionCategoryId]) {
      if (this._selectedOptionsMap[optionCategoryId][option]) {
        this._selectedOptionsMap[optionCategoryId][option] = false
      }
    }
  }

  public changeSelectedOption(optionCategoryId: string, optionId: string, isChecked: boolean) {
    if (!this._selectedOptionsMap[optionCategoryId]) {
      this._selectedOptionsMap[optionCategoryId] = {}
    }
    this._selectedOptionsMap[optionCategoryId][optionId] = isChecked

    const optionCurrentProductId: string = this._optionMap[optionId].productId
    if (optionCurrentProductId) {
      const optionIdsCurrentProduct: CategoryAndOption[] =
        this._optionProductMap[optionCurrentProductId]
      for (let optionIdCurrentProduct of optionIdsCurrentProduct) {
        if (optionIdCurrentProduct.optionId !== optionId) {
          if (
            this._optionMap[optionId].isDeleteOptions &&
            !this._optionMap[optionIdCurrentProduct.optionId].isDeleteOptions
          ) {
            if (
              this._selectedOptionsMap[optionIdCurrentProduct.optionCategoryId][
                optionIdCurrentProduct.optionId
              ]
            ) {
              this.setOptionValue(
                optionIdCurrentProduct.optionCategoryId,
                optionIdCurrentProduct.optionId,
                false,
              )
            }
          } else if (
            !this._optionMap[optionId].isDeleteOptions &&
            this._optionMap[optionIdCurrentProduct.optionId].isDeleteOptions
          ) {
            if (
              this._selectedOptionsMap[optionIdCurrentProduct.optionCategoryId][
                optionIdCurrentProduct.optionId
              ]
            ) {
              this.setOptionValue(
                optionIdCurrentProduct.optionCategoryId,
                optionIdCurrentProduct.optionId,
                false,
              )
            }
          } else {
            if (this._selectedOptionsMap[optionCategoryId][optionId]) {
              this.setOptionValue(
                optionIdCurrentProduct.optionCategoryId,
                optionIdCurrentProduct.optionId,
                true,
              )
            } else {
              this.setOptionValue(
                optionIdCurrentProduct.optionCategoryId,
                optionIdCurrentProduct.optionId,
                false,
              )
            }
          }
        }
      }
    }
  }

  private setOptionValue(optionCategoryId: string, optionId: string, value: boolean) {
    let countSelectedOptions: number = this._countSelectedOptionsMap[optionCategoryId]
    if (value) {
      this._selectedOptionsMap[optionCategoryId][optionId] = value
      this._countSelectedOptionsMap[optionCategoryId] = ++countSelectedOptions
    } else {
      this._selectedOptionsMap[optionCategoryId][optionId] = value
      this._countSelectedOptionsMap[optionCategoryId] = --countSelectedOptions
    }
  }

  private changeCountSelectedOptionsMap(
    optionCategoryId: string,
    isChecked: boolean,
    isOptional: boolean,
  ): void {
    let countSelectedOptions: number = this._countSelectedOptionsMap[optionCategoryId]
    if (isChecked) {
      if (isOptional) {
        this._countSelectedOptionsMap[optionCategoryId] = ++countSelectedOptions
      }
    } else {
      this._countSelectedOptionsMap[optionCategoryId] = --countSelectedOptions
    }
  }
}

export default Product
