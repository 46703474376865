import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {
  ProductDTO,
  ProductVariantDTO,
  CatalogRecommendationGroupDTO,
  WeightMapDto,
} from 'grilnica-store-share'
import {Button, ButtonIcon, Col} from 'grilnica-reactstrap'
import {
  arrowLeftOutlineSVG,
  arrowRightOutlineSVG,
  basketSVG,
} from '../../../../../components/svg/SystemIcons'
import {isEmpty} from 'grilnica-share'
import {LazyImage} from '../../../../../components/lazyImage/LazyImage'
import {CONSTANTS} from '../../../../../../../constants'
import {getMinDefaultWeight} from '../../../../../../utils/menu'

const CARD_SIZE: number = 276

interface RecommendationsProductsProps {
  id: string
  products: ProductDTO[]
  catalogRecommendationsGroup: CatalogRecommendationGroupDTO
  addRecommendationProductInBasket: (product: ProductDTO) => void
  weightMap: WeightMapDto
}

const RecommendationsProducts: React.FC<RecommendationsProductsProps> = ({
  id,
  products,
  catalogRecommendationsGroup,
  addRecommendationProductInBasket,
  weightMap,
}): React.ReactElement => {
  const [containerWidth, setContainerWidth] = useState<number>(null)
  const [scrollPos, setScrollPos] = useState<number>(null)
  const [scrollWidth, setScrollWidth] = useState<number>(null)
  const [isShowRightArrow, setIsShowRightArrow] = useState<boolean>(false)
  const [isShowLeftArrow, setIsShowLeftArrow] = useState<boolean>(false)
  const [isShowButton, setIsShowButton] = useState<boolean>(false)

  useEffect(() => {
    const element = document.getElementById('container-nav-' + id)

    element.addEventListener('mouseenter', () => {
      setIsShowButton(true)
    })
    element.addEventListener('mouseleave', () => {
      setIsShowButton(false)
    })

    setContainerWidth(element.clientWidth)
    setScrollWidth(element.scrollWidth)
    setScrollPos(element.scrollLeft)
    if (element.scrollLeft >= CARD_SIZE) {
      setIsShowLeftArrow(true)
    } else {
      setIsShowLeftArrow(false)
    }
    if (element.scrollLeft + element.clientWidth !== element.scrollWidth) {
      setIsShowRightArrow(true)
    } else {
      setIsShowRightArrow(false)
    }
  }, [id])

  const getRenderProductCard = useCallback(
    (product: ProductDTO, index: number) => {
      let weight: string = null
      if (product) {
        weight = getMinDefaultWeight(product, weightMap)
      }
      let price: number = product.price
      let salePrice: number = null
      let minPrice: number = null
      if (product.productVariants?.length > 0) {
        const productVariants: ProductVariantDTO[] = product.productVariants
        minPrice = productVariants[0].price
        for (let itemPV of productVariants) {
          if (minPrice > itemPV.price) {
            minPrice = itemPV.price
          }
          if (!isEmpty(itemPV.salePrice) && minPrice > itemPV.salePrice) {
            minPrice = itemPV.salePrice
          }
        }
      } else {
        salePrice = product.salePrice ? price - (price - product.salePrice) : null
      }
      return (
        <div
          key={catalogRecommendationsGroup.catalogRecommendationGroupId + '-' + index}
          id={catalogRecommendationsGroup.catalogRecommendationGroupId + '-' + index}
          className={'pl-x2 pr-x2'}>
          <div
            className={'rp-card'}
            onClick={() => {
              addRecommendationProductInBasket(product)
            }}>
            <div className={'rp-img'}>
              <LazyImage
                src={CONSTANTS.cdnUrl + '/' + product.imageFileName}
                typePlaceholder={'product'}
                alt={product.name}
                title={product.name}
              />
            </div>
            <div className={'d-flex flex-column justify-content-between p-x2 pl-x1 w-100'}>
              <p className={'rp-name mb-x2'}>{product.name}</p>
              <div className={'d-flex flex-row justify-content-between align-items-center w-100'}>
                <span className={'caption-2 text-gray-700 mr-x1'}>{weight}</span>
                <Button
                  onClick={() => {}}
                  size={'xs'}
                  iconPath={basketSVG}
                  iconPosition={'left'}
                  isRoundedButton
                  color={isEmpty(salePrice) ? 'primary' : 'sale'}>
                  {salePrice ? salePrice : minPrice ? `от ${minPrice}` : price} &#8381;
                </Button>
              </div>
            </div>
          </div>
        </div>
      )
    },
    [
      catalogRecommendationsGroup?.catalogRecommendationGroupId,
      addRecommendationProductInBasket,
      weightMap,
    ],
  )

  const renderProducts: React.ReactElement = useMemo(() => {
    let items: React.ReactElement[] = products.map((product, index) =>
      getRenderProductCard(product, index),
    )
    return <>{items}</>
  }, [getRenderProductCard, products])

  const renderContent: React.ReactElement = useMemo(() => {
    return (
      <Col md={{size: 6, offset: 3}}>
        <p className={'caption-select-promo mt-x8 mb-x2'}>{catalogRecommendationsGroup.name}</p>
        <div
          id={'container-nav-' + id}
          onScroll={(e: any) => {
            const posLeft = e.target.scrollLeft
            setScrollPos(posLeft)
            if (posLeft > 0) {
              setIsShowLeftArrow(true)
            } else {
              setIsShowLeftArrow(false)
            }
            if (posLeft + containerWidth !== scrollWidth) {
              setIsShowRightArrow(true)
            } else {
              setIsShowRightArrow(false)
            }
          }}
          className={'recommendations-product-list'}>
          {isShowLeftArrow && (
            <div className={'rp-arrow-l'}>
              {isShowButton && (
                <ButtonIcon
                  iconPath={arrowLeftOutlineSVG}
                  title={'Назад'}
                  onClick={() => {
                    if (scrollPos && scrollPos > 0) {
                      let activeElement = null
                      if (scrollPos < CARD_SIZE) {
                        activeElement = document.getElementById(
                          catalogRecommendationsGroup.catalogRecommendationGroupId + '-' + 0,
                        )
                      } else {
                        let index = Math.floor(scrollPos / CARD_SIZE)
                        if (scrollPos - CARD_SIZE * index < CARD_SIZE / 2) {
                          index = index - 1
                        }
                        activeElement = document.getElementById(
                          catalogRecommendationsGroup.catalogRecommendationGroupId +
                            '-' +
                            (index > 0 ? index : 0),
                        )
                      }
                      if (activeElement) {
                        activeElement.scrollIntoView({
                          behavior: 'smooth',
                          block: 'nearest',
                          inline: 'start',
                        })
                      }
                    }
                  }}
                />
              )}
            </div>
          )}
          {renderProducts}
          {isShowRightArrow && (
            <div className={'rp-arrow-r'}>
              {isShowButton && (
                <ButtonIcon
                  iconPath={arrowRightOutlineSVG}
                  title={'Вперёд'}
                  onClick={() => {
                    if (!isEmpty(scrollPos)) {
                      let activeElement = null
                      if (scrollPos + containerWidth > scrollWidth - CARD_SIZE) {
                        activeElement = document.getElementById(
                          catalogRecommendationsGroup.catalogRecommendationGroupId +
                            '-' +
                            (products.length - 1),
                        )
                      } else {
                        let index: number = Math.floor(scrollPos / CARD_SIZE)
                        let addIndex: number = Math.floor(containerWidth / CARD_SIZE)
                        if (addIndex === 2) {
                          addIndex = addIndex - 1
                        } else if (addIndex < 1) {
                          addIndex += 1
                        }
                        index = index + addIndex
                        activeElement = document.getElementById(
                          catalogRecommendationsGroup.catalogRecommendationGroupId +
                            '-' +
                            (index <= 0 ? 1 : index),
                        )
                      }
                      if (activeElement) {
                        activeElement.scrollIntoView({
                          behavior: 'smooth',
                          block: 'nearest',
                          inline: 'start',
                        })
                      }
                    }
                  }}
                />
              )}
            </div>
          )}
        </div>
      </Col>
    )
  }, [
    catalogRecommendationsGroup?.catalogRecommendationGroupId,
    catalogRecommendationsGroup?.name,
    containerWidth,
    id,
    isShowButton,
    isShowLeftArrow,
    isShowRightArrow,
    products?.length,
    renderProducts,
    scrollPos,
    scrollWidth,
  ])

  return <>{renderContent}</>
}

export {RecommendationsProducts}
