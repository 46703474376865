import React, {useMemo} from 'react'
import {CONSTANTS} from '../../../../constants'
import {ReactSVG} from 'react-svg'

interface IconSvgButtonProps {
  onClick: () => void
  iconFileName: string
  size?: number
  isSign?: boolean
  sign?: string
  className?: string
  isActive?: boolean
}

const IconSvgButton: React.FC<IconSvgButtonProps> = ({
  isActive,
  iconFileName,
  size,
  isSign,
  sign,
  className,
  onClick,
}): React.ReactElement => {
  const renderContent: React.ReactElement = useMemo(() => {
    const resultClassName: string =
      'icon-svg-button ' + (isActive ? 'active ' : ' ') + (className ? className : ' ')
    return (
      <span onClick={onClick} className={resultClassName}>
        <ReactSVG src={CONSTANTS.cdnUrl + '/' + iconFileName} />
        {isSign && <span className={'sign'}>{sign}</span>}
      </span>
    )
  }, [isActive, className, onClick, iconFileName, isSign, sign])
  return <>{renderContent}</>
}

export {IconSvgButton}
