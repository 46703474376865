import React, {useMemo} from 'react'
import {logoSVG} from '../../svg'
import {
  Badge,
  ButtonIcon,
  Container,
  DropdownToggleType,
  GRDropdown,
  GRDropdownItem,
  GRDropdownMenu,
  GRDropdownToggle,
} from 'grilnica-reactstrap'
import {BasketState} from '../../../../store/ducks/basket'
import {Link} from 'react-router-dom'
import {ButtonBasketDesk} from './ButtonBasketDesk'
import {CityDTO} from 'grilnica-share'
import {reFormatPhone} from '../../../site/utils/UserUtils'
import {getCountItemBasket} from '../../../../utils/basket/getCountProductFromBasket'
import {SearchInputHeader} from './SearchInputHeader'
import {basketSVG, menuSVG, phoneSVG} from '../../svg/SystemIcons'
import {ymTarget} from '../../../../utils/metrica/ym/ymTarget'
import {gaTarget} from '../../../../utils/metrica/ga/gaTarget'
import {ContactDetailsMap} from 'grilnica-store-share'

interface HeaderProps {
  onOpen: () => void
  basket: BasketState
  city: CityDTO
  contactDetailsMap: ContactDetailsMap
  searchValue: string
  handleChangeSearchValue: (value: string) => void
}

const Header: React.FC<HeaderProps> = ({
  onOpen,
  basket,
  city,
  contactDetailsMap,
  searchValue,
  handleChangeSearchValue,
}): React.ReactElement => {
  const renderDropdownItems: React.ReactElement[] = useMemo(() => {
    const dropdownItems: React.ReactElement[] = []
    let phoneNumber: string = null
    let additionalPhoneNumber: string = null
    if (city?.cityId && contactDetailsMap) {
      phoneNumber = contactDetailsMap[city.cityId]?.phoneNumber
      additionalPhoneNumber = contactDetailsMap[city.cityId]?.additionalPhoneNumber
    } else {
      phoneNumber = contactDetailsMap?.default?.phoneNumber
    }

    if (phoneNumber) {
      dropdownItems.push(
        <GRDropdownItem
          key={reFormatPhone(phoneNumber)}
          text={reFormatPhone(phoneNumber)}
          link={'tel:' + phoneNumber}
          onClick={() => {
            ymTarget('ZVONOK')
            gaTarget('click-call', 'callback')
          }}
        />,
      )
    }
    if (additionalPhoneNumber) {
      dropdownItems.push(
        <GRDropdownItem
          key={reFormatPhone(additionalPhoneNumber)}
          text={reFormatPhone(additionalPhoneNumber)}
          link={'tel:' + additionalPhoneNumber}
          onClick={() => {
            ymTarget('ZVONOK')
            gaTarget('click-call', 'callback')
          }}
        />,
      )
    }

    return dropdownItems
  }, [city?.cityId, contactDetailsMap])

  const renderPhoneBlock: React.ReactElement = useMemo(() => {
    let phoneNumber: string = null
    let additionalPhoneNumber: string = null

    if (city?.cityId && contactDetailsMap) {
      phoneNumber = contactDetailsMap[city.cityId]?.phoneNumber
      additionalPhoneNumber = contactDetailsMap[city.cityId]?.additionalPhoneNumber
    } else {
      phoneNumber = contactDetailsMap?.default?.phoneNumber
    }

    return (
      <React.Fragment>
        <div className={'header-tel'}>
          {phoneNumber && (
            <a
              href={'tel:' + reFormatPhone(phoneNumber)}
              onClick={() => {
                ymTarget('ZVONOK')
                gaTarget('click-call', 'callback')
              }}>
              {reFormatPhone(phoneNumber)}
            </a>
          )}
          {additionalPhoneNumber && (
            <a
              href={'tel:' + reFormatPhone(additionalPhoneNumber)}
              onClick={() => {
                ymTarget('ZVONOK')
                gaTarget('click-call', 'callback')
              }}>
              {reFormatPhone(additionalPhoneNumber)}
            </a>
          )}
        </div>
        <div className={'header-tel-icon-btn '}>
          {!additionalPhoneNumber ? (
            <ButtonIcon
              iconPath={phoneSVG}
              title={'Номер для заказа'}
              isHoverArea
              onClick={() => {
                ymTarget('ZVONOK')
                gaTarget('click-call', 'callback')
              }}
              href={'tel:' + reFormatPhone(phoneNumber)}
            />
          ) : renderDropdownItems.length > 0 ? (
            <GRDropdown id={'linkTelDropdown'} direction={'auto'}>
              <GRDropdownToggle
                type={DropdownToggleType.iconButton}
                iconPath={phoneSVG}
                iconPosition={'left'}
                iconTitle={'Номер для заказа'}
                isHoverArea
              />
              <GRDropdownMenu>{renderDropdownItems}</GRDropdownMenu>
            </GRDropdown>
          ) : null}
        </div>
      </React.Fragment>
    )
  }, [city?.cityId, contactDetailsMap, renderDropdownItems])

  const renderContent: React.ReactElement = useMemo(() => {
    return (
      <div className={'info'}>
        <Container size={'normal'} className={'info-container'}>
          <div className={'d-flex flex-row align-items-center'}>
            <ButtonIcon
              className={'menu-icon-btn mr-secondary'}
              isHoverArea
              iconPath={menuSVG}
              onClick={() => {
                onOpen()
              }}
              title={'Меню'}
            />
            <Link className={'logo mr-secondary'} title={'Грильница'} to={'/'}>
              {logoSVG}
            </Link>
          </div>
          <div className={'d-flex flex-row align-items-center justify-content-end'}>
            {renderPhoneBlock}
            <SearchInputHeader
              isSearchOpenAlways
              className={'show-open-search mr-x6'}
              searchValue={searchValue}
              handleChangeSearchValue={handleChangeSearchValue}
            />
            <ButtonBasketDesk
              priceFull={basket.priceFull}
              count={getCountItemBasket(basket?.selectedProducts)}
              id={'general'}
            />
            <ButtonIcon
              className={'menu-icon-btn '}
              to={'/basket'}
              isHoverArea
              badge={
                basket?.priceFull > 0 && basket?.selectedProducts?.length > 0 ? (
                  <Badge
                    isBorder={false}
                    size={'xs'}
                    color={'sale'}
                    countAlert={getCountItemBasket(basket.selectedProducts)}
                    to={'/basket'}
                  />
                ) : undefined
              }
              iconPath={basketSVG}
              title={'Корзина'}
            />
          </div>
        </Container>
      </div>
    )
  }, [
    basket.priceFull,
    basket.selectedProducts,
    handleChangeSearchValue,
    onOpen,
    renderPhoneBlock,
    searchValue,
  ])

  return <React.Fragment>{renderContent}</React.Fragment>
}

export {Header}
