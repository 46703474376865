import React, {useMemo} from 'react'
import {ProductDTO} from 'grilnica-store-share/lib/product/ProductDTO'
import {Row} from 'grilnica-reactstrap'
import ProductItem from './productItem/ProductItem'
import {MenuCategoryDTO, ProductMap, RestaurantByProductMap} from 'grilnica-store-share'
import {IsShowRestaurantByProductMap} from '../../../../../../../types/restaurantProduct/IsShowRestaurantByProductMap'
import {ErrorLoadMenuComponent} from '../../../../../../components/common/ErrorLoadMenuComponent'

interface ProductListProps {
  productList: ProductDTO[]
  url: string
  restaurantByProductMap: RestaurantByProductMap
  isShowRestaurantByProductMap: IsShowRestaurantByProductMap
  colSize?: any
  productMap: ProductMap
  newCategoryList: MenuCategoryDTO[]
  menuAlias: string
  isTerminal: boolean
}

const ProductList: React.FC<ProductListProps> = ({
  productList,
  url,
  restaurantByProductMap,
  colSize,
  productMap,
  newCategoryList,
  menuAlias,
  isShowRestaurantByProductMap,
  isTerminal,
}): React.ReactElement => {
  const renderContent: React.ReactElement = useMemo(() => {
    return (
      <Row className={'product-list'}>
        {productList?.length ? (
          productList.map((product) => {
            if (
              (isShowRestaurantByProductMap[product.productId] !== undefined || isTerminal) &&
              (product.price || product.salePrice || product.isShowWithZeroPrice) &&
              !product.isHideInApp
            ) {
              let prefix = ''
              for (let category of newCategoryList) {
                if (
                  product.menuCategoryId === category.menuCategoryId &&
                  menuAlias !== category.alias
                ) {
                  prefix = '/' + category.alias
                }
              }
              return (
                <ProductItem
                  key={product.productId}
                  product={product}
                  restaurantByProductMap={restaurantByProductMap}
                  url={url + prefix}
                  colSize={colSize}
                  productMap={productMap}
                  menuCategoryList={newCategoryList}
                  isSaleModal={false}
                />
              )
            }
          })
        ) : (
          <ErrorLoadMenuComponent className={'w-100 mb-x12'} />
        )}
      </Row>
    )
  }, [
    productList,
    isShowRestaurantByProductMap,
    isTerminal,
    restaurantByProductMap,
    url,
    colSize,
    productMap,
    newCategoryList,
    menuAlias,
  ])
  return <>{renderContent}</>
}

export {ProductList}
