import React, {useCallback, useEffect, useMemo, useReducer, useState} from 'react'
import {SelectedProductDTO, WeightMapDto} from 'grilnica-store-share'
import {ComboProductModalBody} from './ComboProductModalBody'
import {toggleOptionService} from '../../../../../productList/productItem/services/product/toggleOptionService'
import {
  initialProductItemState,
  ProductItemActions,
  ProductItemActionsTypes,
  ProductItemReducer,
  ProductItemState,
} from '../../../../../productList/productItem/ducks/ProductItemDuck'
import {ProductDTO} from 'grilnica-store-share/lib/product/ProductDTO'
import {selectProductService} from '../../../../../productList/productItem/services/product/selectProductService'
import {getWeightWithOptions} from '../../../../../../../../../../../utils/menu/getWeight'

export const NewProductDispatch = React.createContext(null)

interface ComboProductModalProps {
  isOpenModal: boolean
  onCloseModal: () => void
  selectedProduct: SelectedProductDTO
  comboGroupId: string
  comboProductId: string
  toggleComboProduct: (
    comboGroupId: string,
    comboProductId: string,
    selectedProduct: SelectedProductDTO,
  ) => void
  weightMap: WeightMapDto
}

const ComboProductModal: React.FC<ComboProductModalProps> = ({
  isOpenModal,
  onCloseModal,
  selectedProduct,
  toggleComboProduct,
  comboGroupId,
  comboProductId,
  weightMap,
}): React.ReactElement => {
  const [productState, dispatch] = useReducer<React.Reducer<ProductItemState, ProductItemActions>>(
    ProductItemReducer,
    initialProductItemState,
  )
  const [isUpdate, setIsUpdate] = useState<boolean>(false)

  const saveComboProduct = useCallback(() => {
    if (isUpdate) {
      toggleComboProduct(comboGroupId, comboProductId, productState.selectedProduct)
    }
    onCloseModal()
  }, [
    comboGroupId,
    comboProductId,
    isUpdate,
    onCloseModal,
    productState.selectedProduct,
    toggleComboProduct,
  ])

  const toggleOption = useCallback(
    (optionCategoryId: string, optionId: string, isChecked: boolean, isOptional: boolean) => {
      setIsUpdate(true)
      toggleOptionService(
        optionCategoryId,
        optionId,
        isChecked,
        isOptional,
        productState.supportsMaps,
        productState.selectedProduct,
        dispatch,
      )
    },
    [productState.selectedProduct, productState.supportsMaps, dispatch],
  )

  const getProduct = useCallback(async () => {
    let newProductDB: ProductDTO = selectedProduct.product
    await selectProductService(newProductDB, dispatch, selectedProduct)
  }, [selectedProduct])

  useEffect(() => {
    getProduct()
  }, [getProduct])

  const renderContent: React.ReactElement = useMemo(() => {
    let weight: string = null
    if (productState.selectedProduct?.product) {
      weight = getWeightWithOptions(productState.selectedProduct, weightMap, 'active')
    }

    return (
      productState.selectedProduct && (
        <NewProductDispatch.Provider value={dispatch}>
          <ComboProductModalBody
            selectedProduct={productState.selectedProduct}
            toggleOption={toggleOption}
            saveComboProduct={saveComboProduct}
            supportsMaps={productState.supportsMaps}
            isOpenModal={isOpenModal}
            onCloseModal={() => {
              onCloseModal()
              dispatch({
                type: ProductItemActionsTypes.CLEAR_SELECTED_PRODUCT,
              })
            }}
            isComboProduct
            weight={weight}
          />
        </NewProductDispatch.Provider>
      )
    )
  }, [
    productState.selectedProduct,
    productState.supportsMaps,
    weightMap,
    toggleOption,
    saveComboProduct,
    isOpenModal,
    onCloseModal,
  ])

  return <>{renderContent}</>
}

export {ComboProductModal}
