import React, {useEffect, useMemo, useRef, useState} from 'react'
import {Container, PageNav, PageNavItem} from 'grilnica-reactstrap'
import {MenuCategoryDTO, ProductMap} from 'grilnica-store-share'
import {connectComponentWithoutActions} from '../../../../store/common'
import {shortLogoWhiteSVG} from '../../svg'
import {BasketState} from '../../../../store/ducks/basket'
import {isShowMenuCategory} from '../../../../utils/menu/isShowMenuCategory'
import {SearchInputHeader} from './SearchInputHeader'
import {ButtonBasketDesk} from './ButtonBasketDesk'
import {Redirect, useLocation} from 'react-router-dom'
import {State} from '../../../../store/ducks'
import {IsShowRestaurantByProductMap} from '../../../../types/restaurantProduct/IsShowRestaurantByProductMap'
import {getCountItemBasket} from '../../../../utils/basket/getCountProductFromBasket'
import {ErrorInitializeEnum} from '../../../../types/common/ErrorInitializeEnum'
import {isEmptyObject} from 'grilnica-share'
import {ErrorLoadMenuNavComponent} from '../../common/ErrorLoadMenuNavComponent'

interface MenuNavigationProps {
  categoryList: MenuCategoryDTO[]
  basket: BasketState
  productMap: ProductMap
  isShowRestaurantByProductMap: IsShowRestaurantByProductMap
  className?: string
  searchValue: string
  handleChangeSearchValue: (value: string) => void
  isTerminal: boolean
  errorInitialize: ErrorInitializeEnum
}

const MenuNavigation: React.FC<MenuNavigationProps> = ({
  categoryList,
  basket,
  productMap,
  isShowRestaurantByProductMap,
  className,
  searchValue,
  handleChangeSearchValue,
  isTerminal,
  errorInitialize,
}): React.ReactElement => {
  const [redirectUrl, setRedirectUrl] = useState<string>(null)

  const [scrollLeft, setScrollLeft] = useState<number>(0)
  const [scrollWidth, setScrollWidth] = useState<number>(0)
  const [clientWidth, setClientWidth] = useState<number>(0)

  const ref = useRef(null)

  useEffect(() => {
    if (ref.current && scrollWidth === 0) {
      setScrollWidth(ref.current.scrollWidth)
      setClientWidth(ref.current.clientWidth)
    }
  }, [scrollWidth])

  const getCategoryList = useMemo((): MenuCategoryDTO[] => {
    let newCategories: MenuCategoryDTO[] = []
    if (!isEmptyObject(productMap)) {
      for (let category of categoryList || []) {
        if (
          !category.parentMenuCategoryId &&
          isShowMenuCategory(
            category,
            productMap,
            categoryList,
            isShowRestaurantByProductMap,
            isTerminal,
          )
        ) {
          newCategories.push(category)
        }
      }
    }
    return newCategories
  }, [categoryList, isShowRestaurantByProductMap, productMap, isTerminal])

  // useEffect(() => {
  //   const element = document.getElementById('header-menu-nav')
  //   if (element.offsetTop > 137) {
  //     setIsScroll(true)
  //   } else {
  //     setIsScroll(false)
  //   }
  //   window.addEventListener('scroll', () => {
  //     if (element.offsetTop > 137) {
  //       setIsScroll(true)
  //     } else {
  //       setIsScroll(false)
  //     }
  //   })
  // }, [])

  const location = useLocation()

  let renderMenuContent: React.ReactElement = useMemo(() => {
    const activeAlias: string = location.pathname.substr(6)
    const menuItems: React.ReactElement[] = getCategoryList.map((menuItem: MenuCategoryDTO) => {
      return (
        !menuItem.parentMenuCategoryId && (
          <PageNavItem
            key={menuItem.menuCategoryId}
            to={`/menu/${menuItem.alias}`}
            name={menuItem.name}
            id={menuItem.alias}
          />
        )
      )
    })
    return (
      <PageNav
        id={'-menu'}
        navRef={ref}
        className={'border-0 '}
        isNoBorderTop
        isWrap={false}
        activeElementId={activeAlias}
        onScroll={(event) => {
          setScrollLeft(event.currentTarget.scrollLeft)
        }}>
        {menuItems}
      </PageNav>
    )
  }, [getCategoryList, location.pathname])

  const renderLogo: React.ReactElement = useMemo(() => {
    return (
      <div
        className={'logo'}
        onClick={() => {
          window.scrollTo(0, 0)
          setRedirectUrl('/')
          setTimeout(() => {
            setRedirectUrl(null)
          }, 150)
        }}>
        {shortLogoWhiteSVG}
      </div>
    )
  }, [])

  const renderNav: React.ReactElement = useMemo(() => {
    return (
      <React.Fragment>
        <div className={'gradient'}>
          <div className={scrollLeft <= 20 ? '' : 'left-gradient'} id={'left-gradient'} />
          <div className={'overflow-auto'} id={'menu-content'}>
            {renderMenuContent}
          </div>
          <div className={clientWidth + scrollLeft <= scrollWidth - 20 ? 'right-gradient' : ''} />
        </div>
        {handleChangeSearchValue && (
          <SearchInputHeader
            className={'ml-x3 show-search-input'}
            handleChangeSearchValue={handleChangeSearchValue}
            searchValue={searchValue}
          />
        )}
      </React.Fragment>
    )
  }, [
    clientWidth,
    handleChangeSearchValue,
    renderMenuContent,
    scrollLeft,
    scrollWidth,
    searchValue,
  ])

  const renderBasket: React.ReactElement = useMemo(() => {
    return (
      <div className={'basket'}>
        <div className={'d-flex align-items-center'}>
          {handleChangeSearchValue && (
            <SearchInputHeader
              handleChangeSearchValue={handleChangeSearchValue}
              searchValue={searchValue}
            />
          )}
          <ButtonBasketDesk
            priceFull={basket?.priceFull}
            style={'inversion'}
            id={'navigation'}
            count={getCountItemBasket(basket.selectedProducts)}
          />
        </div>
      </div>
    )
  }, [basket?.priceFull, basket.selectedProducts, handleChangeSearchValue, searchValue])

  const renderContent: React.ReactElement = useMemo(() => {
    let isErrorLoad: boolean = !!errorInitialize
    return (
      <div
        className={'header-menu-nav ' + 'scroll ' + (className ? className : '')}
        id={'header-menu-nav'}>
        <Container size={isTerminal ? 'terminal' : 'normal'} className={' '}>
          <div
            className={'d-flex flex-row justify-content-between align-items-center overflow-auto'}>
            <div className={'header-menu-nav-container w-100'}>
              {!isErrorLoad && renderLogo}
              {isErrorLoad ? <ErrorLoadMenuNavComponent /> : renderNav}
            </div>
            {!isErrorLoad && renderBasket}
          </div>
          {redirectUrl && <Redirect to={redirectUrl} />}
        </Container>
      </div>
    )
  }, [className, errorInitialize, isTerminal, redirectUrl, renderBasket, renderLogo, renderNav])

  return <React.Fragment>{renderContent}</React.Fragment>
}
export default connectComponentWithoutActions(
  (state: State) => ({
    categoryList: state.menu.categoryList,
    productMap: state.menu.productMap,
    basket: state.basket,
    isShowRestaurantByProductMap: state.restaurantProduct.isShowRestaurantByProductMap ?? {},
    isTerminal: state.terminal?.terminalAlias?.length > 0,
    errorInitialize: state.initialize.errorInitialize,
  }),
  MenuNavigation,
)
