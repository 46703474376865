import React, {useMemo} from 'react'
import ProductItem from '../../../containers/menu/components/product/productList/productItem/ProductItem'
import {MenuCategoryDTO, ProductDTO, ProductMap, RestaurantByProductMap} from 'grilnica-store-share'
import {getUrl} from '../../../utils/search/getUrl'

interface ProductWeekOrDayCardProps {
  isProductWeek: boolean
  product: ProductDTO
  restaurantByProductMap: RestaurantByProductMap
  productMap: ProductMap
  categoryList: MenuCategoryDTO[]
  currentDay: number
}

const ProductWeekOrDayCard: React.FC<ProductWeekOrDayCardProps> = ({
  isProductWeek,
  product,
  productMap,
  categoryList,
  restaurantByProductMap,
  currentDay,
}): React.ReactElement => {
  const renderContent: React.ReactElement = useMemo(() => {
    const className: string = isProductWeek ? 'sale-wrapper-week' : 'sale-wrapper-day'
    return (
      <div className={className}>
        {product && (
          <div className={'product-list'}>
            <ProductItem
              key={product.productId}
              product={product}
              restaurantByProductMap={restaurantByProductMap}
              url={getUrl(product, categoryList)}
              colSize={3}
              productMap={productMap}
              classNameProductCard={'sale-product-card'}
              menuCategoryList={categoryList}
              isSaleModal
              currentDayProps={currentDay}
            />
          </div>
        )}
      </div>
    )
  }, [categoryList, currentDay, isProductWeek, product, productMap, restaurantByProductMap])

  return <>{renderContent}</>
}

export {ProductWeekOrDayCard}
