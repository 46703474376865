import {BaseAction} from '../../common/BaseAction'
import {RecommendationsProductActionsTypes} from './types'
import {RecommendationsProductState} from '../types/RecommendationsProductState'

const initialRecommendationsProductState: RecommendationsProductState = {
  recommendationProductMap: null,
  isRequest: false,
}

export default function recommendationsProduct(
  state: RecommendationsProductState = initialRecommendationsProductState,
  dispatch: BaseAction,
): RecommendationsProductState {
  switch (dispatch.type) {
    case RecommendationsProductActionsTypes.SET_RECOMMENDATION_PRODUCT_MAP: {
      return {
        ...state,
        recommendationProductMap: dispatch.payload,
      }
    }

    case RecommendationsProductActionsTypes.SET_IS_REQUEST: {
      return {
        ...state,
        isRequest: dispatch.payload,
      }
    }

    default:
      return state
  }
}
