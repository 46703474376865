/**
 * Обрезает строку по 27 символов и дописывает '...'
 * @param str
 */
export function cutToLength(str: string): string {
  if (!str) {
    return null
  }
  if (str.length >= 30) {
    return str.substr(0, 27) + '...'
  }
  return str
}
