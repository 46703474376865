import React, {useCallback, useMemo} from 'react'
import {OptionCategoryDTO, RestrictionsDTO} from 'grilnica-store-share'
import {CustomCheckbox} from 'grilnica-reactstrap'
import {SelectedOptionsMap} from '../../../../../../../../../types/product/SelectedOptionsMap'
import {RestrictionsMap} from '../../../../../../../../../types/product/RestrictionsMap'
import {CountSelectedOptionsMap} from '../../../../../../../../../types/product/CountSelectedOptionsMap'
import {checkSelectedAllOptions} from '../../../productList/productItem/utils/checkSelectedAllOptions'
import {IconSvgButton} from '../../../../../../../../components/button/IconSvgButton'

interface OptionCategoryItemProps {
  optionCategory: OptionCategoryDTO
  selectedOptionsMap: SelectedOptionsMap
  isOptional: boolean
  restrictionsMap: RestrictionsMap
  countSelectedOptionsMap: CountSelectedOptionsMap
  toggleOption: (
    optionCategoryId: string,
    optionId: string,
    isChecked?: boolean,
    isOptional?: boolean,
  ) => void
  isExtraOptionCategory: boolean
  isComboProduct?: boolean
  isNotForSale?: boolean
}

const OptionCategoryItem: React.FC<OptionCategoryItemProps> = ({
  restrictionsMap,
  optionCategory,
  countSelectedOptionsMap,
  selectedOptionsMap,
  isOptional,
  toggleOption,
  isExtraOptionCategory,
  isComboProduct,
  isNotForSale,
}): React.ReactElement => {
  const isDisabled = useMemo(() => {
    const restriction: RestrictionsDTO = restrictionsMap[optionCategory.optionCategoryId]

    return checkSelectedAllOptions(optionCategory, restriction, countSelectedOptionsMap)
  }, [countSelectedOptionsMap, optionCategory, restrictionsMap])

  const isActive = useCallback(
    (optionId: string) => {
      return selectedOptionsMap[optionCategory.optionCategoryId][optionId]
    },
    [optionCategory.optionCategoryId, selectedOptionsMap],
  )

  const renderContent = useMemo(() => {
    if (optionCategory && optionCategory.options && Array.isArray(optionCategory.options)) {
      const items: React.ReactNode[] = []

      if (isComboProduct && optionCategory.isProductVariantDefine) {
        return null
      }

      if (optionCategory.isProductVariantDefine) {
        for (let option of optionCategory.options) {
          items.push(
            <IconSvgButton
              className={''}
              isActive={isActive(option.optionId)}
              iconFileName={option.iconFileName}
              isSign={!!option.description}
              sign={option.description}
              onClick={() => {
                if (!isActive(option.optionId)) {
                  toggleOption(
                    optionCategory.optionCategoryId,
                    option.optionId,
                    !isActive(option.optionId),
                    isOptional,
                  )
                }
              }}
              key={option.optionId}
            />,
          )
        }
      } else {
        for (let option of optionCategory.options) {
          items.push(
            <CustomCheckbox
              id={option.optionId}
              key={option.optionId} //TODO:: &#8381;₽
              label={option.name + (option.price > 0 ? ', +' + option.price + '₽' : '')}
              checked={isActive(option.optionId)}
              type={isOptional ? 'checkbox' : 'radio'}
              onChange={(value) => {
                toggleOption(optionCategory.optionCategoryId, option.optionId, value, isOptional)
              }}
              color={'red'}
              disabled={(isDisabled && !isActive(option.optionId)) || isNotForSale}
            />,
          )
        }
      }

      return (
        <div className={'product-option'}>
          <p className={'title '}>{optionCategory.name}</p>
          <div className={'list'}>{items}</div>
        </div>
      )
    }
  }, [isActive, isComboProduct, isDisabled, isNotForSale, isOptional, optionCategory, toggleOption])

  return <>{renderContent}</>
}

export {OptionCategoryItem}
