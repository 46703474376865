import React from 'react'
import {Container} from 'grilnica-reactstrap'

interface SiteTestBannerProps {}

const SiteTestBanner: React.FC<SiteTestBannerProps> = ({}): React.ReactElement => {
  return (
    <div className={'site-test-banner'}>
      <Container size={'normal'}>
        <h2 className={'text-banner'}>Это тестовая версия сайта</h2>
      </Container>
    </div>
  )
}
export {SiteTestBanner}
