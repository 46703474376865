import React, {useMemo} from 'react'
import {Col, LoadingModal, Row} from 'grilnica-reactstrap'
import {OrderButtonSave} from './OrderButtonSave'
import {FeedbackBlock} from './FeedbackBlock'

interface PlaceOrderBlockProps {
  saveOrder: () => void
  isRequestSaveOrder: boolean
  refreshPrice: () => void
  isErrorUpdateCheckActions: boolean
  error: string
}

const PlaceOrderBlock: React.FC<PlaceOrderBlockProps> = ({
  isRequestSaveOrder,
  saveOrder,
  error,
  refreshPrice,
  isErrorUpdateCheckActions,
}): React.ReactElement => {
  const renderContent: React.ReactElement = useMemo(() => {
    return (
      <Row>
        <Col sm={{size: 6, offset: 3}}>
          {error && <FeedbackBlock error={error} />}
          <LoadingModal isOpen={isRequestSaveOrder} loadingTitle={'Регистрируем ваш заказ'} />
          <OrderButtonSave
            saveOrder={saveOrder}
            refreshPrice={refreshPrice}
            isErrorUpdateCheckActions={isErrorUpdateCheckActions}
          />
        </Col>
      </Row>
    )
  }, [isRequestSaveOrder, error, saveOrder, isErrorUpdateCheckActions, refreshPrice])

  return <>{renderContent}</>
}

export {PlaceOrderBlock}
