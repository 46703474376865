import React from 'react'
import {PageNav, PageNavItem} from 'grilnica-reactstrap'

interface NabAuthNavProps {
  signInType: 'account' | 'fast'
  onChangeAuthType: (signInType: 'account' | 'fast') => void
}

const TabAuthNav: React.FC<NabAuthNavProps> = ({
  signInType,
  onChangeAuthType,
}): React.ReactElement => {
  return (
    <PageNav isNoBorderTop id={'-auth'} className={'mb-x2'}>
      <PageNavItem
        name={'Личный кабинет'}
        active={signInType === 'account'}
        className={'w-50 d-flex justify-content-center font-weight-bold'}
        onClick={() => {
          onChangeAuthType('account')
        }}
      />
      <PageNavItem
        name={'Быстрый заказ'}
        active={signInType === 'fast'}
        className={'w-50 d-flex justify-content-center font-weight-bold'}
        onClick={() => {
          onChangeAuthType('fast')
        }}
      />
    </PageNav>
  )
}

export {TabAuthNav}
