import {bindActionCreators} from 'redux'
import {BaseAction} from '../common/BaseAction'
import {IsShowRestaurantByProductMap} from '../../types/restaurantProduct/IsShowRestaurantByProductMap'
import {RestaurantByProductMap} from 'grilnica-store-share'

export interface RestaurantProductState {
  restaurantByProductMap: RestaurantByProductMap
  isShowRestaurantByProductMap: IsShowRestaurantByProductMap
}

export enum RestaurantProductActionsTypes {
  GET_RESTAURANT_BY_PRODUCT_MAP_REQUEST = 'productRestaurant/GET_RESTAURANT_BY_PRODUCT_MAP_REQUEST',
  SET_RESTAURANT_BY_PRODUCT_MAP = 'productRestaurant/SET_RESTAURANT_BY_PRODUCT_MAP',
  SET_IS_SHOW_RESTAURANT_BY_PRODUCT_MAP = 'productRestaurant/SET_IS_SHOW_RESTAURANT_BY_PRODUCT_MAP',
}

const INITIAL_STATE: RestaurantProductState = {
  restaurantByProductMap: {},
  isShowRestaurantByProductMap: {},
}

export const Actions = {
  getRestaurantByProductMapRequest: (): BaseAction => ({
    type: RestaurantProductActionsTypes.GET_RESTAURANT_BY_PRODUCT_MAP_REQUEST,
  }),

  setRestaurantByProductMap: (productByRestaurantMap: RestaurantByProductMap): BaseAction => ({
    type: RestaurantProductActionsTypes.SET_RESTAURANT_BY_PRODUCT_MAP,
    payload: productByRestaurantMap,
  }),

  setIsShowRestaurantByProductMap: (
    isShowRestaurantByProductMap: IsShowRestaurantByProductMap,
  ): BaseAction => ({
    type: RestaurantProductActionsTypes.SET_IS_SHOW_RESTAURANT_BY_PRODUCT_MAP,
    payload: isShowRestaurantByProductMap,
  }),
}

export function bindRestaurantProductActions(dispatch: any) {
  return bindActionCreators(Actions, dispatch)
}

export default function restaurantProduct(
  state: RestaurantProductState = INITIAL_STATE,
  action: BaseAction,
): RestaurantProductState {
  switch (action.type) {
    case RestaurantProductActionsTypes.SET_RESTAURANT_BY_PRODUCT_MAP: {
      return {
        ...state,
        restaurantByProductMap: action.payload,
      }
    }

    case RestaurantProductActionsTypes.SET_IS_SHOW_RESTAURANT_BY_PRODUCT_MAP: {
      return {
        ...state,
        isShowRestaurantByProductMap: action.payload,
      }
    }

    default: {
      return {
        ...state,
      }
    }
  }
}
