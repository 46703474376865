import {SelectedComboProductMap} from 'grilnica-store-share/lib/product/SelectedComboProductMap'

function getSelectedComboProductPrice(selectedComboProductMap: SelectedComboProductMap): number {
  let selectedComboProductPrice: number = 0

  for (let comboGroupId in selectedComboProductMap) {
    if (selectedComboProductMap.hasOwnProperty(comboGroupId)) {
      selectedComboProductPrice =
        // @ts-ignore
        selectedComboProductPrice + (selectedComboProductMap[comboGroupId][0].allOptionPrice || 0)
    }
  }

  return selectedComboProductPrice
}

export {getSelectedComboProductPrice}
