import {ProductDTO, ProductVariantDTO} from 'grilnica-store-share'
import React from 'react'
import {isEmpty, transformVariable} from 'grilnica-share'

/**
 * Получить блок с ценой для превью товара
 * @param product
 */
export function getProductPriceBlock(product: ProductDTO): React.ReactElement {
  let price: number = product.price
  let salePrice: number = null
  if (product.productVariants?.length > 0) {
    const productVariants: ProductVariantDTO[] = product.productVariants
    let minPrice = productVariants[0].price
    for (let itemPV of productVariants) {
      if (minPrice > itemPV.price) {
        minPrice = itemPV.price
      }
      if (!isEmpty(itemPV.salePrice) && minPrice > itemPV.salePrice) {
        minPrice = itemPV.salePrice
      }
    }
    return <p className={'product-price-block price mb-0'}>{`от ${minPrice} `}&#8381;</p>
  } else {
    salePrice = product.salePrice ? price - (price - product.salePrice) : null
  }
  return (
    <>
      {!salePrice ? (
        <p className={'product-price-block price mb-0'}>
          {transformVariable(price, 0) + ' '}&#8381;
        </p>
      ) : (
        <div className={'d-flex align-items-center'}>
          <p className={'product-price-block new-price mb-0 mr-x2'}>{salePrice + ' '}&#8381;</p>
          <p className={'product-price-block old-price mb-0'}>{price + ' '}&#8381;</p>
        </div>
      )}
    </>
  )
}
