import React, {ReactNode, useCallback, useContext, useEffect, useMemo, useState} from 'react'
import {Button, ButtonLink, Col, Container, Loading, Row} from 'grilnica-reactstrap'
import {ClientDTO} from 'grilnica-store-share'
import {connectComponent} from '../../../../store/common'
import {OrderDTO} from 'grilnica-store-share/lib/order/OrderDTO'
import {getCreatedOrdersService} from '../../../../services/order'
import {OrderItemProfile} from './components/OrderItemProfile'
import {bindAuthActions} from '../../../../store/ducks/auth'
import {getFullUrl} from '../../../../utils/url'
import {State} from '../../../../store/ducks'
import {UrlPrefixContext} from '../../components/App'
import {EditProfileBlock} from './components/EditProfileBlock'
import {logoutSVG} from '../../../components/svg/SystemIcons'
import {getStaticUrl} from '../../../../utils/static'
import {CityDTO} from 'grilnica-share'

interface ProfilePageProps {
  client: ClientDTO
  authActions: any
  selectedCity: CityDTO
}

const ProfilePage: React.FC<ProfilePageProps> = ({
  client,
  authActions,
  selectedCity,
}): React.ReactElement => {
  const [newClient, setNewClient] = useState<ClientDTO>({...client})
  const [isUpdate, setIsUpdate] = useState<boolean>(false)
  const [clientOrders, setClientOrders] = useState<OrderDTO[]>()
  const urlPrefix: string = useContext(UrlPrefixContext)

  const saveClient = useCallback(() => {
    authActions.saveClientDataRequest(newClient)
  }, [authActions, newClient])

  const loadClientOrders = useCallback(async () => {
    const orders: OrderDTO[] = await getCreatedOrdersService(selectedCity?.cityId)
    setClientOrders(orders)
  }, [selectedCity?.cityId])

  const onLogOut = useCallback(() => {
    authActions.logOutRequest(() => {
      window.location.href = getFullUrl('/', urlPrefix)
    })
  }, [urlPrefix, authActions])

  useEffect(() => {
    loadClientOrders()
  }, [loadClientOrders])

  useEffect(() => {
    setNewClient({...client})
  }, [client])

  const handleSubmit = useCallback(async (): Promise<void> => {
    await saveClient()
    setIsUpdate(false)
  }, [saveClient])

  const handleChangeClient = useCallback(
    (key: string, value: any) => {
      newClient[key] = value
      setNewClient({...newClient})
      setIsUpdate(true)
    },
    [newClient],
  )

  const getClientOrders = useMemo(() => {
    if (clientOrders) {
      const items: ReactNode[] = clientOrders.map((item) => (
        <OrderItemProfile order={item} key={item.orderId} />
      ))
      return <div className={'profile-order-list'}>{items}</div>
    } else {
      return <Loading />
    }
  }, [clientOrders])

  const renderListOrders = useMemo(() => {
    return (
      <div className={'profile-card pl-0 pr-i-x1 ' + (clientOrders ? 'basket' : '')}>
        <div>
          <h2 className={'h2 text-center px-x4 '}>Список заказов</h2>
          <div className={'history-order custom-scroll'}>
            {clientOrders?.length > 0 ? (
              getClientOrders
            ) : (
              <div className={'text-center mb-x8 mt-x4'}>
                <img
                  src={getStaticUrl('/img/basket-img.svg')}
                  alt={'История ваших заказов'}
                  title={'История ваших заказов'}
                  className={'mb-x8'}
                />
                <p className={'order-list mb-0'}>
                  Здесь хранится история ваших заказов. <br /> Чтобы сделать заказ перейдите в меню.
                </p>
              </div>
            )}
          </div>
        </div>
        <div className={'d-flex justify-content-center px-x4'}>
          <ButtonLink color={'primary'} to={'/'} isRoundedButton className={'button-go-menu'}>
            Перейти в меню
          </ButtonLink>
        </div>
      </div>
    )
  }, [clientOrders, getClientOrders])

  const renderContent = useMemo(() => {
    if (client) {
      return (
        <>
          <div className={'d-flex align-items-center justify-content-between mb-x10'}>
            <h1 className={'h1 mb-0'}>Личный кабинет</h1>
            <Button
              color={'primary'}
              style={'text'}
              size={'sm'}
              iconPath={logoutSVG}
              isRoundedButton
              onClick={() => {
                onLogOut()
              }}>
              Выйти
            </Button>
          </div>
          <Row>
            <Col md={6}>
              {client && newClient?.phone ? (
                <EditProfileBlock
                  client={newClient}
                  isUpdate={isUpdate}
                  handleSubmit={handleSubmit}
                  handleChangeClient={handleChangeClient}
                  onCancel={() => {
                    setNewClient({...client})
                    setIsUpdate(false)
                  }}
                  isExistsBirthDate={!!client.birthDate}
                />
              ) : null}
            </Col>
            <Col md={6}>{renderListOrders}</Col>
          </Row>
        </>
      )
    } else {
      return <Loading />
    }
  }, [client, handleChangeClient, handleSubmit, isUpdate, newClient, onLogOut, renderListOrders])

  if (!client) {
    return null
  }

  return (
    <Container size={'normal'} className={'mt-x10 mb-x10'}>
      {renderContent}
    </Container>
  )
}

export default connectComponent(
  (state: State) => ({
    client: state.auth.client,
    selectedCity: state.city.selectedCity,
  }),
  (dispatch) => ({
    authActions: bindAuthActions(dispatch),
  }),
  ProfilePage,
)
