import React, {Component} from 'react'
import {Provider} from 'react-redux'

interface Props {
  store: any
}

class ReduxApp extends Component<Props> {
  render() {
    return <Provider store={this.props.store}>{this.props.children}</Provider>
  }
}

export default ReduxApp
