import {OptionCategoryDTO, RestrictionsDTO} from 'grilnica-store-share'
import {CountSelectedOptionsMap} from '../../../../../../../../../types/product/CountSelectedOptionsMap'

const checkSelectedAllOptions = (
  optionCategory: OptionCategoryDTO,
  restriction: RestrictionsDTO,
  countSelectedOptionsMap: CountSelectedOptionsMap,
) => {
  if (restriction.max !== optionCategory?.options?.length) {
    return (
      restriction.min !== restriction.max &&
      countSelectedOptionsMap[optionCategory.optionCategoryId] >= restriction.max
    )
  }
}
export {checkSelectedAllOptions}
