import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {MenuCategoryDTO, ProductMap, RestaurantByProductMap} from 'grilnica-store-share'
import {Row} from 'grilnica-reactstrap'
import {ProductDTO} from 'grilnica-store-share/lib/product/ProductDTO'
import {CityDTO} from 'grilnica-share'
import ProductItem from '../../../menu/components/product/productList/productItem/ProductItem'
import {connectComponentWithoutActions} from '../../../../../../store/common'
import {getRestaurantByProductMapService} from '../../../../../../services/restaurantProduct'
import {getUrl} from '../../../../utils/search/getUrl'

interface SalePageProductListProps {
  productList: ProductDTO[]
  categoryList: MenuCategoryDTO[]
  selectedCity: CityDTO
  productMap: ProductMap
  colSize: any
}

const SalePageProductList: React.FC<SalePageProductListProps> = ({
  productList,
  categoryList,
  selectedCity,
  colSize,
  productMap,
}): React.ReactElement => {
  const [restaurantByProductMap, setRestaurantByProductMap] = useState<RestaurantByProductMap>(null)

  const getRestaurantByProduct = useCallback(async () => {
    const {cityId} = selectedCity
    if (cityId) {
      let newRestaurantByProductMap: RestaurantByProductMap
      newRestaurantByProductMap = await getRestaurantByProductMapService(cityId)
      setRestaurantByProductMap(newRestaurantByProductMap)
    }
  }, [selectedCity])

  useEffect(() => {
    getRestaurantByProduct()
  }, [categoryList, getRestaurantByProduct])

  const renderContent: React.ReactElement = useMemo(() => {
    return (
      <Row className={'product-list'}>
        {productList.map((product) => {
          return (
            <ProductItem
              key={product.productId}
              product={product}
              productMap={productMap}
              restaurantByProductMap={restaurantByProductMap}
              url={getUrl(product, categoryList || [])}
              menuCategoryList={categoryList}
              colSize={colSize}
            />
          )
        })}
      </Row>
    )
  }, [productList, productMap, restaurantByProductMap, categoryList, colSize])

  return <>{renderContent}</>
}

export default connectComponentWithoutActions(
  (state: any) => ({
    categoryList: state.menu.categoryList,
    selectedCity: state.city.selectedCity,
    productMap: state.menu.productMap,
  }),
  SalePageProductList,
)
