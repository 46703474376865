import React, {ReactNode, useCallback, useEffect, useMemo, useState} from 'react'
import {Col, Container, Loading, Row} from 'grilnica-reactstrap'
import {Map} from '../restaurants/Map'
import moment from 'moment-timezone'
import {CityDTO} from 'grilnica-share'
import {State} from '../../../../store/ducks'
import {DeliveryPriceItem} from './components/DeliveryPriceItem'
import {AreaRegionOfDeliveryDTO} from '../../../../types/address/AreaRegionOfDeliveryDTO'
import {connectComponent} from '../../../../store/common'
import {loadAreaOfDeliveryListService} from '../../../../services/address'

interface DeliveryPageProps {
  city: CityDTO
  initAreaOfDeliveryList: AreaRegionOfDeliveryDTO[]
}

const DeliveryPage: React.FC<DeliveryPageProps> = ({
  city,
  initAreaOfDeliveryList,
}): React.ReactElement => {
  const [areaOfDeliveryList, setAreaOfDeliveryList] =
    useState<AreaRegionOfDeliveryDTO[]>(initAreaOfDeliveryList)
  const [selectedAreaOfDelivery, setSelectedAreaOfDelivery] = useState<AreaRegionOfDeliveryDTO>()

  const loadMap = useCallback(async () => {
    moment.tz().locale('ru')
    const newAreaOfDeliveryList: AreaRegionOfDeliveryDTO[] = await loadAreaOfDeliveryListService(
      city.cityId,
    )
    setAreaOfDeliveryList(newAreaOfDeliveryList)
  }, [city.cityId])

  useEffect(() => {
    loadMap()
  }, [loadMap])

  const getDeliveryPrice = useMemo(() => {
    if (areaOfDeliveryList) {
      let deliveryPrice: number = Infinity
      for (let i = 0; i < areaOfDeliveryList.length; i++) {
        if (areaOfDeliveryList[i].regionOfDelivery.mainPrice < deliveryPrice) {
          deliveryPrice = areaOfDeliveryList[i].regionOfDelivery.mainPrice
        }
      }
      return deliveryPrice
    }
  }, [areaOfDeliveryList])

  const renderCriterionOfDelivery = useMemo(() => {
    if (areaOfDeliveryList) {
      const addedRegionOfDeliveryId: string[] = []
      const leftColItems: ReactNode[] = []
      const rightColItems: ReactNode[] = []
      areaOfDeliveryList.forEach((item) => {
        if (!addedRegionOfDeliveryId.includes(item.regionOfDelivery.regionOfDeliveryId)) {
          if (leftColItems.length <= rightColItems.length) {
            leftColItems.push(
              <DeliveryPriceItem
                regionOfDelivery={item.regionOfDelivery}
                key={item.areaOfDeliveryId}
              />,
            )
            addedRegionOfDeliveryId.push(item.regionOfDelivery.regionOfDeliveryId)
          } else {
            rightColItems.push(
              <DeliveryPriceItem
                regionOfDelivery={item.regionOfDelivery}
                key={item.areaOfDeliveryId}
              />,
            )
            addedRegionOfDeliveryId.push(item.regionOfDelivery.regionOfDeliveryId)
          }
        }
      })
      return (
        <Row>
          <Col md={6}>{leftColItems}</Col>
          <Col md={6}>{rightColItems}</Col>
        </Row>
      )
    } else {
      return <Loading />
    }
  }, [areaOfDeliveryList])

  return (
    <Container size={'normal'} className={'mt-x10 mb-x10'}>
      <section>
        <h1 className={'h1 text-center mb-x4'}>Доставка</h1>
        <p className={'about-description text-center mb-x6'}>
          Кликните на карту, чтобы узнать стоимость доставки
        </p>
        {areaOfDeliveryList && (
          <p className={'delivery-price text-center mb-x6'}>
            {'Стоимость доставки: '}
            {selectedAreaOfDelivery ? (
              <>{selectedAreaOfDelivery.regionOfDelivery.mainPrice} &#8381;</>
            ) : (
              <>от {getDeliveryPrice} &#8381;</>
            )}
          </p>
        )}

        {city && areaOfDeliveryList && (
          <Map
            className={'mb-x10'}
            areaRegionOfDeliveryList={areaOfDeliveryList}
            city={city}
            onChangeSelectedAreaOfDelivery={(areaOfDelivery) => {
              setSelectedAreaOfDelivery(areaOfDelivery)
            }}
            isRestaurantPage={false}
          />
        )}

        <h2 className={'h2 text-center mb-x6'}>Условия доставки</h2>
        {renderCriterionOfDelivery}
      </section>
    </Container>
  )
}

export default connectComponent(
  (state: State) => ({
    city: state.city.selectedCity,
    initAreaOfDeliveryList: state.initialize.areaOfDeliveryList,
  }),
  () => ({}),
  DeliveryPage,
)
