import React, {useMemo} from 'react'
import {errorLoadDataSVG} from '../svg'
import {Button} from 'grilnica-reactstrap'

interface ErrorLoadMenuComponentProps {
  className?: string
}

const ErrorLoadMenuComponent: React.FC<ErrorLoadMenuComponentProps> = ({
  className,
}): React.ReactElement => {
  const renderContent: React.ReactElement = useMemo(() => {
    return (
      <div
        className={
          'd-flex flex-column align-items-center justify-content-center ' +
          (className ? className : '')
        }>
        {errorLoadDataSVG}
        <p className={'text-body-2 text-center my-x6'}>Не удалось загрузить информацию</p>
        <Button
          color={'primary'}
          size={'md'}
          isRoundedButton
          onClick={() => {
            window.location.reload()
          }}>
          Обновить страницу
        </Button>
      </div>
    )
  }, [className])

  return <React.Fragment>{renderContent}</React.Fragment>
}

export {ErrorLoadMenuComponent}
