import {call, fork, take, put, all, cancel, select} from 'redux-saga/effects'
import {SocketEventTypeEnum, SocketUserTypeEnum, TerminalDTO} from 'grilnica-share'
import {eventChannel} from 'redux-saga'
import {State} from '../../ducks'
import {CONSTANTS} from '../../../../constants'
import {SocketActions, SocketActionsTypes} from '../duck'
import {PaymentModalActions} from '../../paymentModal/duck'
import {SocketData} from '../../paymentModal/saga/generators'
import {TerminalState} from '../../terminal/types'
import {TerminalActions} from '../../terminal/duck'

declare var io: any

export async function getAuthorizationHeader(terminalAlias) {
  return 'Terminal ' + terminalAlias
}

async function connect(terminalAlias: string) {
  const socket = io(CONSTANTS.socketUrl, {
    reconnection: true,
    secure: true,
    timeout: 10000,
    rejectUnauthorized: false,
    transportOptions: {
      polling: {
        extraHeaders: {
          authorization: await getAuthorizationHeader(terminalAlias),
          userType: SocketUserTypeEnum.TERMINAL,
        },
      },
    },
  })

  return new Promise((resolve, reject) => {
    socket.on('connect', () => {
      console.log('Socket connect success :-)')
      resolve({socket})
    })

    socket.on('connect_error', () => {
      console.log('Socket connect failed :-(')
      reject(new Error('ws:connect_failed '))
    })
  }).catch((error) => ({socket, error}))
}

function* flow() {
  const terminalState: TerminalState = yield select((state: State) => state.terminal)
  const terminalAlias: string = terminalState?.terminalAlias
  while (true) {
    yield take(SocketActionsTypes.CONNECT_SOCKET)
    const {socket, error} = yield call(connect, terminalAlias)
    if (socket) {
      console.log('connection to socket succeeded')
      const ioTask = yield fork(handleIO, socket)
      yield take(SocketActionsTypes.DISCONNECT_SOCKET)
      yield cancel(ioTask)
      socket.disconnect()
      console.log('disconnection to socket succeeded')
    } else {
      console.log('Error connecting socket ' + error)
    }
  }
}

function* handleIO(socket: any) {
  yield fork(read, socket)
}

function* read(socket: any) {
  const channel = yield call(subscribe, socket)
  while (true) {
    let action = yield take(channel)
    yield put(action)
  }
}

// function* onEvent({payload}: any) {
//   const dataa = payload.data
//   console.log('&&&&&&&&&&&&&&&&&& dataa=', dataa)
//   const prepareOrder: PrepareOrder = yield select((state: State) => state.order.prepareOrder)
//   //прежний заказ
//   if (dataa.order.orderId === prepareOrder.orderId) {
//     console.log('!!!')
//   }
// }

function subscribe(socket: any) {
  return eventChannel((emit) => {
    socket.on(SocketEventTypeEnum.CHANGE_PAYMENT_TERMINAL_ERROR, (data: SocketData) => {
      emit(PaymentModalActions.controller(data))
    })
    socket.on(SocketEventTypeEnum.CHANGE_PAYMENT_TERMINAL_STAGE, (data: any) => {
      emit(PaymentModalActions.controller(data))
    })
    socket.on(SocketEventTypeEnum.REDIRECT_TO_HOME_TERMINAL, (data: any) => {
      emit(TerminalActions.controllerSocketRedirectToHome(data))
    })
    socket.on(SocketEventTypeEnum.REDIRECT_TO_REPAIR_TERMINAL, (data: any) => {
      emit(TerminalActions.controllerSocketRedirectToRepair(data))
    })

    socket.on('disconnect', (error: any) => {
      console.log(error, 'Disconnect')
    })
    socket.on('error', (error: any) => {
      console.log(error + ' Error while trying to connect, TODO: proper handle of this event')
    })

    return () => {}
  })
}

function* initializeSocket() {
  console.log('*initializeSocket')
  yield all([fork(flow)])
}

function* connectSocket() {
  console.log('*connectSocket')
  const terminalState: TerminalState = yield select((state: State) => state.terminal)
  const terminal: TerminalDTO = terminalState?.terminal
  if (terminal) {
    yield put(SocketActions.connectSocket())
  }
}

function* disconnectSocket() {
  console.log('*disconnectSocket')
  yield put(SocketActions.disconnectSocket())
}

export {initializeSocket, connectSocket, disconnectSocket}
