import {ProductDTO, ProductVariantDTO, SelectedProductDTO, WeightMapDto} from 'grilnica-store-share'
import {getActiveWeight, getMinWeight} from './index'

const convertWeight = (value: number): number => {
  return Math.round(value * 1000)
}

const getMinDefaultWeight = (product: ProductDTO, weightMap: WeightMapDto): string => {
  const productWeight: number = product.weight
  const productVariants: ProductVariantDTO[] = product?.productVariants

  let selectedOptionIds: string[] = []
  for (let optionCategory of product.optionCategories || []) {
    for (let option of optionCategory.options || []) {
      if (option.restrictions.isDefault) {
        selectedOptionIds.push(option.optionId)
      }
    }
  }
  const optionsWeight = getOptionsWeight(selectedOptionIds, weightMap) || 0

  let weightText: string =
    productWeight && productWeight !== 0
      ? `${convertWeight(productWeight + optionsWeight)} г`
      : undefined
  if (productVariants?.length > 0) {
    let isDiffWeight: boolean = false
    let tempWeight: number =
      productVariants[0].weight && productVariants[0].weight !== 0
        ? productVariants[0].weight
        : undefined
    for (let variant of productVariants) {
      if (variant.weight !== tempWeight) {
        isDiffWeight = true
        tempWeight = variant.weight && variant.weight !== 0 ? variant.weight : undefined
        break
      }
    }
    if (tempWeight === undefined || tempWeight === 0) {
      weightText = undefined
    } else {
      if (isDiffWeight) {
        weightText = `от ${convertWeight(getMinWeight(productVariants) + optionsWeight)} г`
      } else {
        weightText = `${convertWeight(tempWeight + optionsWeight)} г`
      }
    }
  }
  return weightText
}

function getMinWeightText(productVariants: ProductVariantDTO[]) {
  let weightText: string | undefined
  let isDiffWeight: boolean = false
  let tempWeight: number =
    productVariants[0].weight && productVariants[0].weight !== 0
      ? productVariants[0].weight
      : undefined
  for (let variant of productVariants) {
    if (variant.weight !== tempWeight) {
      isDiffWeight = true
      tempWeight = variant.weight && variant.weight !== 0 ? variant.weight : undefined
      break
    }
  }
  if (tempWeight === undefined || tempWeight === 0) {
    weightText = undefined
  } else {
    if (isDiffWeight) {
      weightText = `от ${convertWeight(getMinWeight(productVariants))} г`
    } else {
      weightText = `${convertWeight(tempWeight)} г`
    }
  }
  return weightText
}

const getWeightWithOptions = (
  selectedProduct: SelectedProductDTO,
  weightMap: WeightMapDto,
  type: 'min' | 'active',
): string => {
  const {productVariantId} = selectedProduct
  const {product} = selectedProduct
  const {weight: productWeight, productVariants} = product

  let productWeightInKg: number | undefined

  if (productVariants?.length > 0) {
    if (type === 'min') {
      return getMinWeightText(productVariants)
    } else {
      if (productVariantId) {
        productWeightInKg = getActiveWeight(productVariants, productVariantId)
      }
    }
  } else {
    productWeightInKg = productWeight
  }

  const selectedOptions = selectedProduct.selectedOptions
  const allSelectedOptionIds = []
  for (const selectedOption of selectedOptions ?? []) {
    if (selectedOption.optionIds) {
      allSelectedOptionIds.push(...selectedOption.optionIds)
    }
  }
  const optionsWeight = getOptionsWeight(allSelectedOptionIds, weightMap)
  return productWeightInKg
    ? `${convertWeight(productWeightInKg + (optionsWeight ?? 0))} г`
    : undefined
}

function getOptionsWeight(optionIds: string[], weightMap: WeightMapDto): number | undefined {
  if (!optionIds) {
    return undefined
  }
  if (!weightMap) {
    return undefined
  }
  let resultWeight = 0
  for (const optionId of optionIds) {
    let optionWeight: number | undefined
    // Вес может быть разным, в зависимости от модификаторов
    // Напримет, вес соуса зависит от размера шаурмы
    const weights = weightMap[optionId]
    if (weights) {
      for (const weight of weights) {
        if (optionIds.includes(weight.dependsOnObjectId)) {
          optionWeight = weight.weight
          break
        }
      }
      // Если не нашли в зависимости от модификаторов, то берем по умолчанию
      if (optionWeight === undefined) {
        optionWeight = weights.find((w) => !w.dependsOnObjectId)?.weight
      }
      resultWeight += optionWeight ?? 0
    }
  }
  return resultWeight
}

export {getMinDefaultWeight, getWeightWithOptions, convertWeight}
