import {END} from 'redux-saga'

import {createStore, applyMiddleware, compose} from 'redux'
import rootReducer from './ducks'
import rootSaga from './sagas'
import createSagaMiddleware from 'redux-saga'

const __DEV__ = process.env.NODE_ENV === 'development'
const isClient: boolean = !!(
  typeof window !== 'undefined' &&
  window.document &&
  window.document.createElement
)
const isServer = !isClient

function createAppStore(preloadedState?: any): any {
  const sagaMiddleware = createSagaMiddleware()

  const middleware = [sagaMiddleware]
  const middlewares: any[] = [applyMiddleware(...middleware)]

  let composes: any
  if (__DEV__) {
    composes = compose(...middlewares)
  } else {
    composes = compose(...middlewares)
  }

  const store: any = createStore(rootReducer, preloadedState, composes)
  store.runSaga = sagaMiddleware.run
  store.closeSagas = () => store.dispatch(END)

  if (!isServer) {
    sagaMiddleware.run(rootSaga)
  }
  return store
}

export {createAppStore, isServer, isClient}
