import React, {useMemo} from 'react'
import {ButtonIcon, COLORS, Icon} from 'grilnica-reactstrap'
import {SelectedProductDTO} from '../../../../types/product/SelectedProductDTO'
import {CardImage} from '../../../site/containers/restaurants/components/CardImage'
import {placeholderSVG} from '../../svg'
import {deleteSVG, minusSVG, plusSVG} from '../../svg/SystemIcons'

interface BasketPreviewItemProps {
  selectedProduct: SelectedProductDTO
  deleteProduct: () => void
  addCountProduct: () => void
  removeCountProduct: () => void
}

const BasketPreviewItem: React.FC<BasketPreviewItemProps> = ({
  selectedProduct,
  removeCountProduct,
  addCountProduct,
  deleteProduct,
}): React.ReactElement => {
  const renderRemove: React.ReactElement = useMemo(() => {
    return (
      <ButtonIcon
        iconPath={minusSVG}
        title={'Убрать'}
        isHoverArea
        className={'mr-x3'}
        onClick={removeCountProduct}
      />
    )
  }, [removeCountProduct])

  const renderCount: React.ReactElement = useMemo(() => {
    return <p className={'preview-dish-count mb-0 mr-x3'}>{selectedProduct?.count}</p>
  }, [selectedProduct?.count])

  const renderAdd: React.ReactElement = useMemo(() => {
    return selectedProduct?.count < selectedProduct?.maxCountInOrder ? (
      <ButtonIcon
        iconPath={plusSVG}
        isHoverArea
        title={'Добавить'}
        className={'mr-x6'}
        onClick={addCountProduct}
      />
    ) : (
      <div className={'empty-icon-basket ml-x6'} />
    )
  }, [addCountProduct, selectedProduct?.count, selectedProduct?.maxCountInOrder])

  const renderBasketPreviewItemCountButtons: React.ReactElement = useMemo(() => {
    return (
      <React.Fragment>
        {selectedProduct?.maxCountInOrder !== 1 && renderRemove}
        {selectedProduct?.maxCountInOrder !== 1 && renderCount}
        {selectedProduct?.maxCountInOrder !== 1 && renderAdd}
      </React.Fragment>
    )
  }, [renderAdd, renderCount, renderRemove, selectedProduct?.maxCountInOrder])

  return (
    <div className={'basket-preview-item'}>
      <div className={'d-flex align-items-center mr-x6'}>
        <CardImage
          imageFileName={selectedProduct.product.imageFileName}
          alt={selectedProduct.product.name}
          height={48}
          width={48}
          className={'mr-x2 preview-placeholder'}
          placeholder={<div className={'preview-item-img'}>{placeholderSVG}</div>}
        />
        <p className={'preview-description mb-0'}>{selectedProduct.product.name}</p>
      </div>
      <div className={'d-flex align-items-center'}>
        {renderBasketPreviewItemCountButtons}
        <p className={'dish-price mb-0 mr-x4'}>
          {selectedProduct.fullPrice * selectedProduct.count} &#8381;
        </p>
        <ButtonIcon
          iconPath={deleteSVG}
          isHoverArea
          title={'Удалить'}
          className={'mr-x2'}
          onClick={deleteProduct}
        />
      </div>
    </div>
  )
}

export {BasketPreviewItem}
