import React, {useMemo} from 'react'
import {ProductDTO} from 'grilnica-store-share'
import {Alert, ButtonLink, Col, Divider, Modal, ModalBody, Row} from 'grilnica-reactstrap'
import {CONSTANTS} from '../../../../../../../../../../constants'
import {WeightBlock} from '../../../../../../../../components/product/WeightBlock'
import {LazyImage} from '../../../../../../../../components/lazyImage/LazyImage'
import {Link} from 'react-router-dom'

interface ProductPreviewModalProps {
  product: ProductDTO
  weight: string
  priceBlock: React.ReactElement
  productLink: string
  isNotForSale: boolean
  onClose: () => void
  isOpen: boolean
}

const ProductPreviewModal: React.FC<ProductPreviewModalProps> = ({
  product,
  weight,
  priceBlock,
  productLink,
                                                                   isNotForSale,
  onClose,
  isOpen,
}): React.ReactElement => {
  const renderImage = useMemo(() => {
    return (
      <Link to={productLink}>
        <LazyImage
          src={CONSTANTS.cdnUrl + '/' + product.imageFileName}
          typePlaceholder={'product'}
          alt={product.name}
          title={product.name}
        />
      </Link>
    )
  }, [product.imageFileName, product.name, productLink])

  const renderInfo = useMemo(() => {
    return (
      <div className={'d-flex flex-column'}>
        <Link to={productLink} className={'h1 text-gray-800 font-weight-medium mb-x3'}>
          {product.name}
        </Link>
        <Link
          to={productLink}
          className={'sec-text-body-2 text-gray-700 ' + (weight ? 'mb-x3' : 'mb-0')}>
          {product.description}
        </Link>
        <WeightBlock weight={weight} className={'text-gray-700 mb-0'} />
        {isNotForSale && (
          <Alert
            message={'Данный товар не доступен для розничной продажи'}
            color={'danger'}
            iconPosition={'start'}
            className={'mt-x6'}
          />
        )}
      </div>
    )
  }, [isNotForSale, product.description, product.name, productLink, weight])

  const renderFooter = useMemo(() => {
    return (
      <div className={'d-flex flex-row justify-content-between align-items-center'}>
        {priceBlock}
        <ButtonLink
          size={'sm'}
          style={'outline'}
          isRoundedButton
          color={'primary'}
          to={productLink}>
          Подробнее
        </ButtonLink>
      </div>
    )
  }, [priceBlock, productLink])

  const renderContent: React.ReactElement = useMemo(() => {
    return (
      <Modal isOpen={isOpen} onClose={onClose} size={'lg'} className={'px-0 pb-0 pt-x4'}>
        <ModalBody className={'custom-scroll mb-x4'}>
          <Row>
            <Col xl={4} lg={4} md={5} sm={12} xs={12}>
              {renderImage}
            </Col>
            <Col xl={8} lg={8} md={7} sm={12} xs={12}>
              {renderInfo}
            </Col>
          </Row>
          <Divider className={'mt-x6 mb-x4'} />
          <Row>
            <Col
              xl={{offset: 4, size: 8}}
              lg={{offset: 4, size: 8}}
              md={{offset: 5, size: 7}}
              sm={12}
              xs={12}>
              {renderFooter}
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    )
  }, [isOpen, onClose, renderFooter, renderImage, renderInfo])

  return <>{renderContent}</>
}

export {ProductPreviewModal}
