import React from 'react'
import {NavLink} from 'react-router-dom'
import {ReactSVG} from 'react-svg'
import {CONSTANTS} from '../../../../../constants'

interface MenuNavItemProps {
  id: string
  to: string
  className?: string
  disabled?: boolean
  name: string
  iconFileName: string
  active?: boolean
}

const MenuNavItem: React.FC<MenuNavItemProps> = ({
  id,
  to,
  className,
  disabled,
  name,
  iconFileName,
  active,
}): React.ReactElement => {
  const classNameResult = 'big-menu-item ' + (active ? 'active' : '')

  return (
    <NavLink
      draggable={false}
      id={id}
      to={to}
      className={classNameResult}
      onClick={(e: any) => {
        disabled && e.preventDefault()
      }}>
      <ReactSVG src={CONSTANTS.cdnUrl + '/' + iconFileName} />
      <p className={'mb-0 ml-x1'}>{name}</p>
    </NavLink>
  )
}

export {MenuNavItem}
