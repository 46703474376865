import {bindActionCreators} from 'redux'
import {BaseAction} from '../common/BaseAction'
import {
  AddMinutesOrderType,
  AutoChoiceRestaurantType,
  CheckDiscountResultDTO,
  ClientAddressDTO,
  DeliveryTypeEnum,
  OrderStatusEnum,
  PrepareOrder,
  PrepareOrderTime,
} from 'grilnica-store-share'
import {OrderDTO} from 'grilnica-store-share/lib/order/OrderDTO'
import {RestaurantDTO} from 'grilnica-share'
import {DeliveryInfo} from '../../types/order/DeliveryInfo'

export interface OrderState {
  prepareOrder: PrepareOrder
  isShowChooseDeliveryOrderTimeModal: boolean
  isShowChoosePickupOrderTimeModal: boolean
  isShowChooseRestaurantModal: boolean
  isShowDiscardedDeliveryTimeModal: boolean
  discardedDeliveryTimeDeliveryType?: DeliveryTypeEnum
  createdOrders: OrderDTO[]
  deliveryInfo: DeliveryInfo
  checkedOrder: CheckDiscountResultDTO
  priceResult: number
  isRequestUpdateDeliveryPriceOrder: boolean
  isRequestGetCheckedOrder: boolean
  selectedOrder: OrderDTO
  isRequestSaveOrder: boolean
  isShowChooseOnlinePaymentTypeModal: boolean
  isRequestGetCreatedOrders: boolean
  isRequestUpdateCreatedOrders: boolean
  isRequestUpdateSelectedOrder: boolean
  lastOrderStatus: OrderStatusEnum
  addMinutesOrder: AddMinutesOrderType
  autoChoiceRestaurant: AutoChoiceRestaurantType
  //terminal
  isRequestSaveOrderTerminal: boolean
  orderInfo: OrderDTO | undefined //Информация по заказу на определенных этапах оформления заказа на терминале / место хранения заказа в процессе выполнения оплаты заказов(заказы которые нужно только обновить они уже в iiko) на терминале
  isRequestPayOrderForPayment: boolean
}

export enum OrderActionsTypes {
  SET_PREPARE_ORDER_FIELD = 'order/SET_PREPARE_ORDER_FIELD',
  TOGGLE_IS_SHOW_CHOOSE_ORDER_TIME_MODAL = 'order/TOGGLE_IS_SHOW_CHOOSE_ORDER_TIME_MODAL',
  CREATE_DELIVERY_ORDER_REQUEST = 'order/CREATE_DELIVERY_ORDER_REQUEST',
  TOGGLE_IS_SHOW_CHOOSE_RESTAURANT_MODAL = 'order/TOGGLE_IS_SHOW_CHOOSE_RESTAURANT_MODAL',
  TOGGLE_IS_SHOW_DISCARDED_DELIVERY_TIME_MODAL = 'order/TOGGLE_IS_SHOW_DISCARDED_DELIVERY_TIME_MODAL',
  ADD_FAVOURITE_RESTAURANT_REQUEST = 'order/ADD_FAVOURITE_RESTAURANT_REQUEST',
  SET_IS_CREATING_ORDER = 'order/SET_IS_CREATING_ORDER',
  SET_DELIVERY_INFO = 'order/SET_DELIVERY_INFO',
  SET_PREPARE_ORDER_PREPARE_TIME_REQUEST = 'order/SET_PREPARE_ORDER_PREPARE_TIME_REQUEST',
  SET_PREPARE_ORDER_SELECTED_ADDRESS_REQUEST = 'order/SET_PREPARE_ORDER_SELECTED_ADDRESS_REQUEST',
  SET_CHECKED_ORDER = 'order/SET_CHECKED_ORDER',
  GET_CHECKED_ORDER_REQUEST = 'order/GET_CHECKED_ORDER_REQUEST',
  UPDATE_DELIVERY_PRICE_REQUEST = 'order/UPDATE_DELIVERY_PRICE_REQUEST',
  SET_PRICE_RESULT = 'order/SET_PRICE_RESULT',
  TOGGLE_IS_REQUEST_UPDATE_DELIVERY_PRICE_ORDER = 'order/TOGGLE_IS_REQUEST_UPDATE_DELIVERY_PRICE_ORDER',
  TOGGLE_IS_REQUEST_GET_CHECKED_ORDER = 'order/TOGGLE_IS_REQUEST_GET_CHECKED_ORDER',
  SAVE_ORDER_REQUEST = 'order/SAVE_ORDER_REQUEST',
  TOGGLE_IS_REQUEST_SAVE_ORDER = 'order/TOGGLE_IS_REQUEST_SAVE_ORDER',
  SET_SELECTED_ORDER_REQUEST = 'order/SET_SELECTED_ORDER_REQUEST',
  SET_SELECTED_ORDER = 'order/SET_SELECTED_ORDER',
  CLEAR_PREPARE_ORDER = 'order/CLEAR_PREPARE_ORDER',
  GET_CREATED_ORDERS_REQUEST = 'order/GET_CREATED_ORDERS_REQUEST',
  SET_CREATED_ORDERS = 'order/SET_CREATED_ORDERS',
  TOGGLE_IS_SHOW_CHOOSE_ONLINE_PAYMENT_MODAL = 'order/TOGGLE_IS_SHOW_CHOOSE_ONLINE_PAYMENT_MODAL',
  SET_PREPARE_ORDER = 'order/SET_PREPARE_ORDER',
  TOGGLE_IS_REQUEST_GET_CREATED_ORDERS = 'order/TOGGLE_IS_REQUEST_GET_CREATED_ORDERS',
  TOGGLE_IS_REQUEST_UPDATE_CREATED_ORDERS = 'order/TOGGLE_IS_REQUEST_UPDATE_CREATED_ORDERS',
  UPDATE_SELECTED_ORDER_REQUEST = 'order/UPDATE_SELECTED_ORDER_REQUEST',
  TOGGLE_IS_REQUEST_UPDATE_SELECTED_ORDER = 'order/TOGGLE_IS_REQUEST_UPDATE_SELECTED_ORDER',
  SET_DISCOUNT_SUM = 'order/SET_DISCOUNT_SUM',
  SET_PREPARE_ORDER_RESTAURANT_REQUEST = 'order/SET_PREPARE_ORDER_RESTAURANT_REQUEST',
  SET_PREPARE_ORDER_DELIVERY_TYPE_REQUEST = 'order/SET_PREPARE_ORDER_DELIVERY_TYPE_REQUEST',
  CHANGE_SELECTED_ORDER = 'order/CHANGE_SELECTED_ORDER',
  SET_LAST_ORDER_STATUS = 'order/SET_LAST_ORDER_STATUS',
  CLEAR_LAST_ORDER_STATUS = 'order/CLEAR_LAST_ORDER_STATUS',
  SET_CLIENT_ADDRESS = 'order/CLEAR_LAST_ORDER_STATU',
  SAVE_ORDER_SUCCESS = 'order/SAVE_ORDER_SUCCESS',
  REPEAT_ORDER = 'order/REPEAT_ORDER',
  SET_ADD_MINUTES_ORDER = 'order/SET_ADD_MINUTES_ORDER',
  SET_AUTO_CHOICE_RESTAURANT = 'order/SET_AUTO_CHOICE_RESTAURANT',
  CHANGE_AUTO_CHOICE_RESTAURANT_REQUEST = 'order/CHANGE_AUTO_CHOICE_RESTAURANT_REQUEST',
  //terminal
  SAVE_ORDER_TERMINAL_REQUEST = 'order/SAVE_ORDER_TERMINAL_REQUEST',
  TOGGLE_IS_REQUEST_SAVE_ORDER_TERMINAL = 'order/TOGGLE_IS_REQUEST_SAVE_ORDER_TERMINAL',
  SET_ORDER_INFO = 'order/SET_ORDER_INFO',
  PAY_ORDER_FOR_PAYMENT_REQUEST = 'order/PAY_ORDER_FOR_PAYMENT_REQUEST',
  TOGGLE_IS_REQUEST_PAY_ORDER_FOR_PAYMENT = 'order/TOGGLE_IS_REQUEST_PAY_ORDER_FOR_PAYMENT',
  // CANCEL_PAY_ORDER_FOR_PAYMENT_REQUEST = 'order/CANCEL_PAY_ORDER_FOR_PAYMENT_REQUEST',
}

const INITIAL_STATE: OrderState = {
  prepareOrder: {
    name: null,
    phone: null,
    selectedAddressText: null,
    selectedAddress: null,
    addedAddress: null,
    countPersons: null,
    commentary: null,
    prepareTime: {orderTime: null, isQuickly: true},
    paymentType: null,
    isCallBack: false,
    additionPhone: null,
    deliveryType: DeliveryTypeEnum.DELIVERY,
    cityId: null,
    priceFull: null,
  },
  isShowChooseDeliveryOrderTimeModal: false,
  isShowChoosePickupOrderTimeModal: false,
  isShowChooseRestaurantModal: false,
  isShowDiscardedDeliveryTimeModal: false,
  createdOrders: [],
  deliveryInfo: null,
  checkedOrder: null,
  priceResult: null,
  isRequestUpdateDeliveryPriceOrder: false,
  isRequestGetCheckedOrder: false,
  selectedOrder: null,
  isRequestSaveOrder: false,
  isShowChooseOnlinePaymentTypeModal: false,
  isRequestGetCreatedOrders: false,
  isRequestUpdateCreatedOrders: false,
  isRequestUpdateSelectedOrder: false,
  lastOrderStatus: null,
  addMinutesOrder: {
    pickupMinutes: 30,
    deliveryMinutes: 60,
  },
  autoChoiceRestaurant: {
    restaurantId: null,
    isAutoChoice: false,
  },
  //terminal
  isRequestSaveOrderTerminal: false,
  orderInfo: null,
  isRequestPayOrderForPayment: false,
}

export const Actions = {
  toggleIsShowChooseOrderTimeModal: (
    isShow: boolean,
    deliveryType: DeliveryTypeEnum,
  ): BaseAction => ({
    type: OrderActionsTypes.TOGGLE_IS_SHOW_CHOOSE_ORDER_TIME_MODAL,
    payload: {isShow, deliveryType},
  }),

  createDeliveryOrderRequest: (): BaseAction => ({
    type: OrderActionsTypes.CREATE_DELIVERY_ORDER_REQUEST,
  }),

  toggleIsShowChooseRestaurantModal: (isShow: boolean): BaseAction => ({
    type: OrderActionsTypes.TOGGLE_IS_SHOW_CHOOSE_RESTAURANT_MODAL,
    payload: isShow,
  }),

  toggleIsShowDiscardedDeliveryTimeModal: (
    isShow: boolean,
    currentDeliveryTypeId?: DeliveryTypeEnum,
  ): BaseAction => ({
    type: OrderActionsTypes.TOGGLE_IS_SHOW_DISCARDED_DELIVERY_TIME_MODAL,
    payload: {isShow, currentDeliveryTypeId},
  }),

  setIsCreatingOrder: (isCreatingOrder: boolean): BaseAction => ({
    type: OrderActionsTypes.SET_IS_CREATING_ORDER,
    payload: isCreatingOrder,
  }),

  getCheckedOrderRequest: (): BaseAction => ({
    type: OrderActionsTypes.GET_CHECKED_ORDER_REQUEST,
  }),

  setCheckedOrder: (checkedOrder: CheckDiscountResultDTO): BaseAction => ({
    type: OrderActionsTypes.SET_CHECKED_ORDER,
    payload: checkedOrder,
  }),

  setDeliveryInfo: (deliveryInfo: DeliveryInfo): BaseAction => ({
    type: OrderActionsTypes.SET_DELIVERY_INFO,
    payload: deliveryInfo,
  }),

  setPrepareOrderPrepareTimeRequest: (prepareTime: PrepareOrderTime): BaseAction => ({
    type: OrderActionsTypes.SET_PREPARE_ORDER_PREPARE_TIME_REQUEST,
    payload: prepareTime,
  }),

  setPrepareOrderSelectedAddressRequest: (selectedAddress: ClientAddressDTO): BaseAction => ({
    type: OrderActionsTypes.SET_PREPARE_ORDER_SELECTED_ADDRESS_REQUEST,
    payload: selectedAddress,
  }),

  updateDeliveryPrice: (): BaseAction => ({
    type: OrderActionsTypes.UPDATE_DELIVERY_PRICE_REQUEST,
  }),

  setPriceResult: (priceResult: number): BaseAction => ({
    type: OrderActionsTypes.SET_PRICE_RESULT,
    payload: priceResult,
  }),

  toggleIsRequestUpdateDeliveryPriceOrder: (isRequest: boolean): BaseAction => ({
    type: OrderActionsTypes.TOGGLE_IS_REQUEST_UPDATE_DELIVERY_PRICE_ORDER,
    payload: isRequest,
  }),

  toggleIsRequestGetCheckedOrder: (isRequest: boolean): BaseAction => ({
    type: OrderActionsTypes.TOGGLE_IS_REQUEST_GET_CHECKED_ORDER,
    payload: isRequest,
  }),

  saveOrderRequest: (data: any): BaseAction => ({
    type: OrderActionsTypes.SAVE_ORDER_REQUEST,
    payload: data,
  }),

  saveOrderTerminalRequest: (data: any): BaseAction => ({
    type: OrderActionsTypes.SAVE_ORDER_TERMINAL_REQUEST,
    payload: data,
  }),

  toggleIsRequestSaveOrder: (isRequest: boolean): BaseAction => ({
    type: OrderActionsTypes.TOGGLE_IS_REQUEST_SAVE_ORDER,
    payload: isRequest,
  }),

  toggleIsRequestSaveOrderTerminal: (isRequest: boolean): BaseAction => ({
    type: OrderActionsTypes.TOGGLE_IS_REQUEST_SAVE_ORDER_TERMINAL,
    payload: isRequest,
  }),

  payOrderForPaymentRequest: (data: any): BaseAction => ({
    type: OrderActionsTypes.PAY_ORDER_FOR_PAYMENT_REQUEST,
    payload: data,
  }),

  // cancelPayOrderForPaymentRequest: (): BaseAction => ({ //TODO:: UPDATE
  //   type: OrderActionsTypes.CANCEL_PAY_ORDER_FOR_PAYMENT_REQUEST,
  // }),

  toggleIsRequestPayOrderForPayment: (isRequest: boolean): BaseAction => ({
    type: OrderActionsTypes.TOGGLE_IS_REQUEST_PAY_ORDER_FOR_PAYMENT,
    payload: isRequest,
  }),

  setSelectedOrderRequest: (
    selectedOrder: OrderDTO,
    moveToOrderScreen: (orderId: string) => void,
  ): BaseAction => ({
    type: OrderActionsTypes.SET_SELECTED_ORDER_REQUEST,
    payload: {selectedOrder, moveToOrderScreen},
  }),

  setSelectedOrder: (order: OrderDTO): BaseAction => ({
    type: OrderActionsTypes.SET_SELECTED_ORDER,
    payload: order,
  }),

  clearPrepareOrder: (): BaseAction => ({
    type: OrderActionsTypes.CLEAR_PREPARE_ORDER,
  }),

  getCreatedOrdersRequest: (isRequestUpdate: boolean, isRequestGet: boolean): BaseAction => ({
    type: OrderActionsTypes.GET_CREATED_ORDERS_REQUEST,
    payload: {isRequestUpdate, isRequestGet},
  }),

  toggleIsRequestGetCreatedOrders: (isRequest: boolean): BaseAction => ({
    type: OrderActionsTypes.TOGGLE_IS_REQUEST_GET_CREATED_ORDERS,
    payload: isRequest,
  }),

  toggleIsRequestUpdateCreatedOrders: (isRequest: boolean): BaseAction => ({
    type: OrderActionsTypes.TOGGLE_IS_REQUEST_UPDATE_CREATED_ORDERS,
    payload: isRequest,
  }),

  setCreatedOrders: (createdOrders: OrderDTO[]): BaseAction => ({
    type: OrderActionsTypes.SET_CREATED_ORDERS,
    payload: createdOrders,
  }),

  toggleIsShowChooseOnlinePaymentModal: (isShow: boolean): BaseAction => ({
    type: OrderActionsTypes.TOGGLE_IS_SHOW_CHOOSE_ONLINE_PAYMENT_MODAL,
    payload: isShow,
  }),

  setPrepareOrder: (prepareOrder: PrepareOrder): BaseAction => ({
    type: OrderActionsTypes.SET_PREPARE_ORDER,
    payload: prepareOrder,
  }),

  updateSelectedOrderRequest: (orderId: string, isShowUpdateSpinner: boolean): BaseAction => ({
    type: OrderActionsTypes.UPDATE_SELECTED_ORDER_REQUEST,
    payload: {orderId, isShowUpdateSpinner},
  }),

  toggleIsRequestUpdateSelectedOrder: (isRequest: boolean): BaseAction => ({
    type: OrderActionsTypes.TOGGLE_IS_REQUEST_UPDATE_SELECTED_ORDER,
    payload: isRequest,
  }),

  setPrepareOrderRestaurantRequest: (restaurant: RestaurantDTO): BaseAction => ({
    type: OrderActionsTypes.SET_PREPARE_ORDER_RESTAURANT_REQUEST,
    payload: restaurant,
  }),

  setPrepareOrderDeliveryTypeRequest: (deliveryType: DeliveryTypeEnum): BaseAction => ({
    type: OrderActionsTypes.SET_PREPARE_ORDER_DELIVERY_TYPE_REQUEST,
    payload: deliveryType,
  }),

  changeSelectedOrder: (order: OrderDTO): BaseAction => ({
    type: OrderActionsTypes.CHANGE_SELECTED_ORDER,
    payload: order,
  }),

  setLastOrderStatus: (status: OrderStatusEnum): BaseAction => ({
    type: OrderActionsTypes.SET_LAST_ORDER_STATUS,
    payload: status,
  }),

  clearLastOrderStatus: (): BaseAction => ({
    type: OrderActionsTypes.CLEAR_LAST_ORDER_STATUS,
  }),

  setPrepareOrderField: (key: string, value: any, parentKey?: string): BaseAction => ({
    //TODO:: is update
    type: OrderActionsTypes.SET_PREPARE_ORDER_FIELD,
    payload: {key, value, parentKey},
  }),

  saveOrderSuccess: (): BaseAction => ({
    type: OrderActionsTypes.SAVE_ORDER_SUCCESS,
  }),

  repeatOrder: (orderId: string): BaseAction => ({
    type: OrderActionsTypes.REPEAT_ORDER,
    payload: {orderId},
  }),

  setAddMinutesOrder: (addMinutesOrder: AddMinutesOrderType): BaseAction => ({
    type: OrderActionsTypes.SET_ADD_MINUTES_ORDER,
    payload: addMinutesOrder,
  }),

  setAutoChoiceRestaurant: (autoChoiceRestaurant: AutoChoiceRestaurantType): BaseAction => ({
    type: OrderActionsTypes.SET_AUTO_CHOICE_RESTAURANT,
    payload: autoChoiceRestaurant,
  }),

  changeAutoChoiceRestaurantRequest: (
    autoChoiceRestaurant: AutoChoiceRestaurantType,
  ): BaseAction => ({
    type: OrderActionsTypes.CHANGE_AUTO_CHOICE_RESTAURANT_REQUEST,
    payload: autoChoiceRestaurant,
  }),

  setOrderInfo: (orderInfo: OrderDTO): BaseAction => ({
    type: OrderActionsTypes.SET_ORDER_INFO,
    payload: {...orderInfo},
  }),
}

export type OrderActionsType = typeof Actions

export function bindOrderActions(dispatch: any) {
  return bindActionCreators(Actions, dispatch)
}

export default function order(state: OrderState = INITIAL_STATE, action: BaseAction): OrderState {
  switch (action.type) {
    case OrderActionsTypes.SET_PREPARE_ORDER_FIELD: {
      const {key, value, parentKey} = action.payload

      if (parentKey) {
        return {
          ...state,
          prepareOrder: {
            ...state.prepareOrder,
            [parentKey]: {
              ...state.prepareOrder[parentKey],
              [key]: value,
            },
          },
        }
      }

      return {
        ...state,
        prepareOrder: {
          ...state.prepareOrder,
          [key]: value,
        },
      }
    }

    case OrderActionsTypes.TOGGLE_IS_SHOW_CHOOSE_ORDER_TIME_MODAL:
      {
        switch (action.payload.deliveryType) {
          case DeliveryTypeEnum.DELIVERY: {
            return {
              ...state,
              isShowChooseDeliveryOrderTimeModal: action.payload.isShow,
            }
          }
          case DeliveryTypeEnum.PICKUP: {
            return {
              ...state,
              isShowChoosePickupOrderTimeModal: action.payload.isShow,
            }
          }
        }
      }
      break

    case OrderActionsTypes.TOGGLE_IS_SHOW_CHOOSE_RESTAURANT_MODAL: {
      return {
        ...state,
        isShowChooseRestaurantModal: action.payload,
      }
    }

    case OrderActionsTypes.TOGGLE_IS_SHOW_DISCARDED_DELIVERY_TIME_MODAL: {
      return {
        ...state,
        isShowDiscardedDeliveryTimeModal: action.payload.isShow,
        discardedDeliveryTimeDeliveryType: action.payload.currentDeliveryTypeId,
      }
    }

    case OrderActionsTypes.SET_DELIVERY_INFO: {
      return {
        ...state,
        deliveryInfo: action.payload,
        isRequestUpdateDeliveryPriceOrder: false,
      }
    }

    case OrderActionsTypes.SET_CHECKED_ORDER: {
      return {
        ...state,
        checkedOrder: action.payload,
        isRequestGetCheckedOrder: false,
      }
    }

    case OrderActionsTypes.SET_PRICE_RESULT: {
      return {
        ...state,
        priceResult: action.payload,
      }
    }

    case OrderActionsTypes.UPDATE_DELIVERY_PRICE_REQUEST: {
      return {
        ...state,
        isRequestUpdateDeliveryPriceOrder: true,
      }
    }

    case OrderActionsTypes.GET_CHECKED_ORDER_REQUEST: {
      return {
        ...state,
        isRequestGetCheckedOrder: true,
      }
    }

    case OrderActionsTypes.TOGGLE_IS_REQUEST_UPDATE_DELIVERY_PRICE_ORDER: {
      return {
        ...state,
        isRequestUpdateDeliveryPriceOrder: action.payload,
      }
    }

    case OrderActionsTypes.TOGGLE_IS_REQUEST_GET_CHECKED_ORDER: {
      return {
        ...state,
        isRequestGetCheckedOrder: action.payload,
      }
    }

    case OrderActionsTypes.SAVE_ORDER_REQUEST: {
      return {
        ...state,
        isRequestSaveOrder: true,
      }
    }

    case OrderActionsTypes.SAVE_ORDER_TERMINAL_REQUEST: {
      return {
        ...state,
        isRequestSaveOrderTerminal: true,
      }
    }

    case OrderActionsTypes.TOGGLE_IS_REQUEST_SAVE_ORDER: {
      return {
        ...state,
        isRequestSaveOrder: action.payload,
      }
    }

    case OrderActionsTypes.TOGGLE_IS_REQUEST_SAVE_ORDER_TERMINAL: {
      return {
        ...state,
        isRequestSaveOrderTerminal: action.payload,
      }
    }

    case OrderActionsTypes.PAY_ORDER_FOR_PAYMENT_REQUEST: {
      return {
        ...state,
        isRequestPayOrderForPayment: true,
      }
    }

    case OrderActionsTypes.TOGGLE_IS_REQUEST_PAY_ORDER_FOR_PAYMENT: {
      return {
        ...state,
        isRequestPayOrderForPayment: action.payload,
      }
    }

    case OrderActionsTypes.SET_SELECTED_ORDER: {
      return {
        ...state,
        selectedOrder: action.payload,
        isRequestUpdateSelectedOrder: false,
      }
    }

    case OrderActionsTypes.SET_CREATED_ORDERS: {
      return {
        ...state,
        createdOrders: action.payload,
        isRequestGetCreatedOrders: false,
        isRequestUpdateCreatedOrders: false,
      }
    }

    case OrderActionsTypes.CLEAR_PREPARE_ORDER: {
      return {
        ...state,
        prepareOrder: INITIAL_STATE.prepareOrder,
        checkedOrder: null,
        deliveryInfo: null,
      }
    }

    case OrderActionsTypes.TOGGLE_IS_SHOW_CHOOSE_ONLINE_PAYMENT_MODAL: {
      return {
        ...state,
        isShowChooseOnlinePaymentTypeModal: action.payload,
      }
    }

    case OrderActionsTypes.SET_PREPARE_ORDER: {
      return {
        ...state,
        prepareOrder: action.payload,
      }
    }

    case OrderActionsTypes.TOGGLE_IS_REQUEST_GET_CREATED_ORDERS: {
      return {
        ...state,
        isRequestGetCreatedOrders: action.payload,
      }
    }

    case OrderActionsTypes.TOGGLE_IS_REQUEST_UPDATE_CREATED_ORDERS: {
      return {
        ...state,
        isRequestUpdateCreatedOrders: action.payload,
      }
    }

    case OrderActionsTypes.TOGGLE_IS_REQUEST_UPDATE_SELECTED_ORDER: {
      return {
        ...state,
        isRequestUpdateSelectedOrder: action.payload,
      }
    }

    case OrderActionsTypes.SET_LAST_ORDER_STATUS: {
      return {
        ...state,
        lastOrderStatus: action.payload,
      }
    }

    case OrderActionsTypes.SET_ADD_MINUTES_ORDER: {
      return {
        ...state,
        addMinutesOrder: action.payload,
      }
    }

    case OrderActionsTypes.SET_AUTO_CHOICE_RESTAURANT: {
      return {
        ...state,
        autoChoiceRestaurant: action.payload,
      }
    }

    case OrderActionsTypes.SET_ORDER_INFO: {
      return {
        ...state,
        orderInfo: action.payload,
      }
    }

    default: {
      return state
    }
  }
}
