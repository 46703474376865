import moment from 'moment-timezone'

const getAroundTime = (timezone: string) => {
  let minutes: number
  let hours: number
  let currentMinutes: number = parseInt(moment.tz(timezone).format('mm'))
  let currentHours: number = parseInt(moment.tz(timezone).format('HH'))

  if (currentMinutes < 15 || currentMinutes >= 45) {
    minutes = 0
    hours = currentMinutes < 15 ? currentHours : currentHours + 1
  } else {
    minutes = 30
    hours = currentHours
  }
  return moment.tz(timezone).hour(hours).minute(minutes).format('HH:mm')
}

const getTime = (date: Date) => {
  return date ? moment(date).format('HH:mm') : null
}

const getDate = (date: Date) => {
  return date ? moment(date).format('YYYY-MM-DD') : null
}

const concatDate = (date: string, time: string) => {
  return moment(date).add(time).format('YYYY-MM-DDTHH:mm')
}

const getPrepareOrderMaxDate = () => {
  return moment().add(1, 'month').format('YYYY-MM-DD')
}

const getPrepareOrderMinDate = () => {
  return moment().format('YYYY-MM-DD')
}

const getPrepareOrderMinTime = (date?: Date) => {
  let correctDate = date ? date : moment().format('YYYY-MM-DDTHH:mm')

  if (moment().format('YYYY-MM-DDTHH:mm') === moment(correctDate).format('YYYY-MM-DDTHH:mm')) {
    return moment(correctDate).add(1, 'hour').add(30, 'minutes').format('HH:mm')
  }
  return moment(correctDate).format('HH:mm')
}

const getTimeFromTime = (time: string) => {
  return moment(time, 'HH:mm:ss').format('HH:mm')
}

const isCorrectPrepareOrderDataTime = (date: Date | string) => {
  const currentDate: string = moment().format('YYYY-MM-DD')
  const currentTime: string = getPrepareOrderMinTime()
  const selectDate: string = getDate(date as Date)
  const selectTime: string = getTime(date as Date)
  if (currentDate < selectDate) {
    return true
  }
  if (currentDate === selectDate) {
    return currentTime < selectTime
  }
  return false
}

export {
  getAroundTime,
  isCorrectPrepareOrderDataTime,
  getTime,
  getDate,
  concatDate,
  getPrepareOrderMaxDate,
  getPrepareOrderMinDate,
  getPrepareOrderMinTime,
  getTimeFromTime,
}
