import React, {useMemo} from 'react'
import {Button, COLORS, Container} from 'grilnica-reactstrap'
import {MenuCategoryDTO, ProductMap} from 'grilnica-store-share'
import {isShowMenuCategory} from '../../../../utils/menu/isShowMenuCategory'
import {connectComponentWithoutActions} from '../../../../store/common'
import {State} from '../../../../store/ducks'
import {IsShowRestaurantByProductMap} from '../../../../types/restaurantProduct/IsShowRestaurantByProductMap'
import {MenuNavItem} from './MenuNavItem'
import {isEmptyObject} from 'grilnica-share'
import {ErrorLoadMenuNavComponent} from '../../common/ErrorLoadMenuNavComponent'
import {ErrorInitializeEnum} from '../../../../types/common/ErrorInitializeEnum'

interface MenuNavigationBigProps {
  categoryList: MenuCategoryDTO[]
  productMap: ProductMap
  isShowRestaurantByProductMap: IsShowRestaurantByProductMap
  id?: string
  errorInitialize?: ErrorInitializeEnum
  isTerminal: boolean
}

const MenuNavigationBig: React.FC<MenuNavigationBigProps> = ({
  categoryList,
  productMap,
  isShowRestaurantByProductMap,
  id,
  isTerminal,
  errorInitialize,
}): React.ReactElement => {
  const getCategoryList = useMemo((): MenuCategoryDTO[] => {
    let newCategories: MenuCategoryDTO[] = []
    if (!isEmptyObject(productMap)) {
      for (let category of categoryList) {
        if (
          !category.parentMenuCategoryId &&
          isShowMenuCategory(
            category,
            productMap,
            categoryList,
            isShowRestaurantByProductMap,
            isTerminal,
          )
        ) {
          newCategories.push(category)
        }
      }
    }
    return newCategories
  }, [categoryList, isShowRestaurantByProductMap, isTerminal, productMap])

  let renderMenuContent: React.ReactElement = useMemo(() => {
    const menuItems: React.ReactElement[] = getCategoryList.map((menuItem: MenuCategoryDTO) => {
      return (
        !menuItem.parentMenuCategoryId && (
          <MenuNavItem
            key={menuItem.menuCategoryId}
            id={menuItem.alias}
            to={`/menu/${menuItem.alias}`}
            name={menuItem.name}
            iconFileName={menuItem.iconFileName}
          />
        )
      )
    })

    if (errorInitialize) {
      return <ErrorLoadMenuNavComponent />
    }

    return <div className={'header-menu-content'}>{menuItems}</div>
  }, [errorInitialize, getCategoryList])

  return (
    <div className={'big-header-menu'} id={id}>
      <Container size={isTerminal ? 'terminal' : 'normal'}>{renderMenuContent}</Container>
    </div>
  )
}

export default connectComponentWithoutActions(
  (state: State) => ({
    categoryList: state.menu.categoryList,
    productMap: state.menu.productMap,
    isShowRestaurantByProductMap: state.restaurantProduct.isShowRestaurantByProductMap ?? {},
    isTerminal: state.terminal?.terminalAlias?.length > 0,
    errorInitialize: state.initialize.errorInitialize,
  }),
  MenuNavigationBig,
)
