import React from 'react'
import {RegionOfDeliveryDTO} from 'grilnica-store-share'
import {isEmpty} from 'grilnica-share-client'

interface DeliveryPriceItemProps {
  regionOfDelivery: RegionOfDeliveryDTO
}

const DeliveryPriceItem: React.FC<DeliveryPriceItemProps> = ({
  regionOfDelivery,
}): React.ReactElement => {
  return (
    <div className={'d-flex align-items-center mb-x6'}>
      <div
        className={'region-color mr-x2'}
        style={{backgroundColor: regionOfDelivery.backgroundColor}}
      />
      <p className={'region-delivery-price mb-0'}>
        {regionOfDelivery.name} регион&nbsp;
        {!isEmpty(regionOfDelivery.priceFreeDelivery) &&
          `(при заказе от ${regionOfDelivery.priceFreeDelivery} руб. доставка БЕСПЛАТНО)`}
        - <span>{regionOfDelivery.mainPrice} &#8381;</span>
      </p>
    </div>
  )
}

export {DeliveryPriceItem}
