import {apiPost} from '../../api'
import {CONSTANTS} from '../../../constants'
import {ProductDTO, RecommendationProductMap} from 'grilnica-store-share'

async function getRecommendationProductMapService(body: {
  products: ProductDTO[]
  cityId: string
}): Promise<RecommendationProductMap> {
  return await apiPost(CONSTANTS.apiStoreUrl + '/catalog-recommendations', body, {
    cityId: body.cityId,
  })
}

export {getRecommendationProductMapService}
