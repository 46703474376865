import React, {useMemo} from 'react'
import {Row} from 'grilnica-reactstrap'
import {ComboGroupDTO} from 'grilnica-store-share/src/combo/ComboGroupDTO'
import {SelectedComboProductMap} from 'grilnica-store-share/lib/product/SelectedComboProductMap'
import {ComboProductList} from './product/ComboProductList'
import {SelectedProductDTO, WeightMapDto} from 'grilnica-store-share'

interface ComboGroupBlockProps {
  comboGroupList: ComboGroupDTO[]
  selectedProducts?: SelectedComboProductMap
  weightMap: WeightMapDto
  toggleComboProduct: (
    comboGroupId: string,
    comboProductId: string,
    selectedProduct: SelectedProductDTO,
  ) => void
}

const ComboGroupBlock: React.FC<ComboGroupBlockProps> = ({
  comboGroupList,
  selectedProducts,
  weightMap,
  toggleComboProduct,
}): React.ReactElement => {
  const renderComboGroupList: React.ReactElement = useMemo(() => {
    if (comboGroupList && selectedProducts) {
      const groupsProduct: React.ReactElement[] = comboGroupList.map((group) => {
        return (
          <div className={'mb-x2'} key={group.comboGroupId}>
            <p className={'combo-group-name mb-x4'}>{group.name}</p>
            <Row>
              <ComboProductList
                selectedProductsCombo={selectedProducts[group.comboGroupId]}
                comboGroupId={group.comboGroupId}
                weightMap={weightMap}
                toggleComboProduct={toggleComboProduct}
              />
            </Row>
          </div>
        )
      })
      return <>{groupsProduct}</>
    } else {
      return null
    }
  }, [comboGroupList, selectedProducts, toggleComboProduct, weightMap])

  return <>{renderComboGroupList}</>
}

export {ComboGroupBlock}
