import React, {useMemo} from 'react'
import {Button, ButtonLink} from 'grilnica-reactstrap'

interface ProductCardFooterProps {
  price: number
  sendToBasket: () => void
  salePrice?: number
  href?: string
}

const ProductCardFooter: React.FC<ProductCardFooterProps> = ({
  price,
  sendToBasket,
  salePrice,
  href,
}): React.ReactElement => {
  const renderContent: React.ReactElement = useMemo(() => {
    return (
      <div className={'footer'}>
        {!salePrice ? (
          <p className={'product-price mb-0'}>{price + ' '}&#8381;</p>
        ) : (
          <div className={'d-flex align-items-center'}>
            <p className={'new-price mb-0 mr-x2'}>{salePrice + ' '}&#8381;</p>
            <p className={'old-price mb-0'}>{price + ' '}&#8381;</p>
          </div>
        )}

        {href ? (
          <ButtonLink color={'primary'} size={'sm'} isRoundedButton to={href}>
            в корзину
          </ButtonLink>
        ) : (
          <Button color={'primary'} size={'sm'} isRoundedButton onClick={sendToBasket}>
            в корзину
          </Button>
        )}
      </div>
    )
  }, [salePrice, price, href, sendToBasket])
  return <>{renderContent}</>
}

export {ProductCardFooter}
