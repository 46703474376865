import React, {useCallback, useMemo} from 'react'
import {ToggleFilter} from '../../../../../../components/button/ToggleFilter'
import {MenuCategoryDTO} from 'grilnica-store-share'

interface ProductListFilterProps {
  alias: string
  childAlias: string
  categories: MenuCategoryDTO[]
  categoryList: MenuCategoryDTO[]
}

const ProductListFilter: React.FC<ProductListFilterProps> = ({
  alias,
  childAlias,
  categories,
  categoryList,
}): React.ReactElement => {
  const getParentCategory = useCallback(
    (parentMenuCategoryId: string) => {
      return categoryList.find((item) => parentMenuCategoryId === item.menuCategoryId)
    },
    [categoryList],
  )

  const renderContent: React.ReactElement = useMemo(() => {
    let content: React.ReactElement[] = []
    if (categories && categories.length > 0) {
      content = categories.map((item, index) => {
        return (
          <ToggleFilter
            key={item.alias}
            toggleName={item.name}
            isActive={item.alias === childAlias}
            className={'mb-x6 ' + (index !== categories.length ? 'mr-x6' : '')}
            to={`/menu/${alias}/${item.alias}`}
          />
        )
      })
      const parentMenuCategory = getParentCategory(categories[0].parentMenuCategoryId)
      content.unshift(
        <ToggleFilter
          key={parentMenuCategory.alias}
          toggleName={'Все'}
          isActive={parentMenuCategory.alias === alias && !childAlias}
          className={'mb-x6 mr-x6 '}
          to={`/menu/${parentMenuCategory.alias}`}
        />,
      )
    }
    return <div className={'d-flex flex-wrap'}>{content}</div>
  }, [alias, categories, childAlias, getParentCategory])
  return <>{renderContent}</>
}

export {ProductListFilter}
