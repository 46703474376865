import React, {useCallback, useContext, useEffect, useMemo, useState} from 'react'
import {Button, Col, Container, Modal, ModalBody, ModalFooter, Row} from 'grilnica-reactstrap'
import {State} from '../../../../store/ducks'
import {CityDTO, PaymentTypeEnum} from 'grilnica-share'
import {OrderDeliveryAddress} from './components/address/OrderDeliveryAddress'
import {
  AddMinutesOrderType,
  AutoChoiceRestaurantType,
  CashboxPaymentType,
  ClientDTO,
  DeliveryTypeEnum,
  PaymentTypeMap,
  PrepareOrder,
  SelectedProductDTO,
} from 'grilnica-store-share'
import {FoundAddress} from '../../../../types/address/FoundAddress'
import {bindOrderActions, OrderActionsType} from '../../../../store/ducks/order'
import {
  searchAddressByAddressStrService,
  searchAddressesBySearchValueService,
} from '../../../../services/address'
import {DeliveryInfo} from '../../../../types/order/DeliveryInfo'
import {getPaymentTypeListUtils} from '../../../../utils/order/getPaymentTypeList'
import {FavouritesRestaurant} from '../../../../types/restaurant/FavouritesRestaurant'
import {OrderTake} from './components/whoTake/OrderTake'
import {LatLng} from '../../../../types/address/LatLng'
import {OrderFullPrice} from './components/price/OrderFullPrice'
import {OrderPaymentMethod} from './components/payment/OrderPaymentMethod'
import {HeaderOrderPage} from './components/HeaderOrderPage'
import {OrderComment} from './components/comment/OrderComment'
import {PlaceOrderBlock} from './components/placeOrderBlock/PlaceOrderBlock'
import {OrderTimeAccept} from './components/time/OrderTimeAccept'
import {PaymentDTO} from 'grilnica-store-share/lib/order/PaymentDTO'
import {UrlPrefixContext} from '../../components/App'
import {ymTarget} from '../../../../utils/metrica/ym/ymTarget'
import {connectComponent} from '../../../../store/common'
import {useDebounce} from '../../../../utils/hooks/useDebounce'
import {FindAddressType} from '../../../../types/address/FindAddressType'
import {ResultFindAddressType} from '../../../../types/address/ResultFindAddressType'
import {BasketActionsType, bindBasketActions} from '../../../../store/ducks/basket'
import {useHistory} from 'react-router-dom'
import {TELEGRAM_REDIRECT_URL} from '../../constants'

interface PlaceOrderProps {
  goBack: () => void
  city: CityDTO
  order: PrepareOrder
  client: ClientDTO
  selectedProducts: SelectedProductDTO[]
  orderActions: OrderActionsType
  basketActions: BasketActionsType
  priceFull: number
  saleFull: number
  deliverySale: number

  prepareOrder: PrepareOrder
  clientAddressActions: any
  isShowChooseOnlinePaymentTypeModal: boolean
  paymentActions: any
  isShowDiscardedDeliveryTimeModal: boolean
  discardedDeliveryTimeDeliveryType: DeliveryTypeEnum | undefined
  isRequestSaveOrder: boolean
  restaurantPaymentTypeMap: PaymentTypeMap
  deliveryInfo: DeliveryInfo
  favouritesRestaurants: FavouritesRestaurant[]
  payment: PaymentDTO
  addMinutesOrder: AddMinutesOrderType

  autoChoiceRestaurant: AutoChoiceRestaurantType
  isCheckActions: boolean
  isErrorUpdateCheckActions: boolean
}

const PlaceOrder: React.FC<PlaceOrderProps> = ({
  goBack,
  city,
  orderActions,
  prepareOrder,
  priceFull,
  saleFull,
  deliverySale,
  isRequestSaveOrder,
  restaurantPaymentTypeMap,
  deliveryInfo,
  favouritesRestaurants,
  payment,
  selectedProducts,
  addMinutesOrder,
  autoChoiceRestaurant,
  isShowDiscardedDeliveryTimeModal,
  discardedDeliveryTimeDeliveryType,
  basketActions,
  isCheckActions,
  isErrorUpdateCheckActions,
}): React.ReactElement => {
  const history = useHistory()
  const [foundAddresses, setFoundAddresses] = useState<FoundAddress[]>([])
  const [isUpdateAddress, setIsUpdateAddress] = useState<boolean>(!!prepareOrder.orderId)
  const [isUpdateTime, setIsUpdateTime] = useState<boolean>(false)
  const [isSendingRequest, setIsSendingRequests] = useState<boolean>(false)
  const [error, setError] = useState<string>(null)
  const urlPrefix: string = useContext(UrlPrefixContext)

  useEffect(() => {
    ymTarget('STRANICA_OFORMLENIA')
  }, [])

  useEffect(() => {
    if (
      (prepareOrder?.restaurant?.restaurantId &&
        prepareOrder?.deliveryType === DeliveryTypeEnum.PICKUP) ||
      (deliveryInfo?.restaurantId && prepareOrder?.deliveryType === DeliveryTypeEnum.DELIVERY)
    ) {
      basketActions.checkActions('update', true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [basketActions])

  const handleChangeFoundAddresses = useCallback((addresses: FoundAddress[]) => {
    setFoundAddresses(addresses)
  }, [])

  const setPrepareOrderField = useCallback(
    (key: string, value: any, parentKey?: string) => {
      setError(null)
      orderActions.setPrepareOrderField(key, value, parentKey)
      if (parentKey === 'prepareTime') {
        setIsUpdateTime(true)
      }
    },
    [orderActions],
  )

  const closeDiscardedDeliveryTimeModal = useCallback(() => {
    orderActions.toggleIsShowDiscardedDeliveryTimeModal(false)
  }, [orderActions])

  const toggleTabButton = useCallback(
    (deliveryType: DeliveryTypeEnum): void => {
      setError(null)
      orderActions.setPrepareOrderDeliveryTypeRequest(deliveryType)
    },
    [orderActions],
  )

  const updateDeliveryPrice = useCallback(() => {
    orderActions.updateDeliveryPrice()
  }, [orderActions])

  const refreshDeliveryPrice = useCallback(() => {
    updateDeliveryPrice()
  }, [updateDeliveryPrice])

  const refreshPrice = useCallback(() => {
    if (
      (prepareOrder?.restaurant?.restaurantId &&
        prepareOrder?.deliveryType === DeliveryTypeEnum.PICKUP) ||
      (deliveryInfo?.restaurantId && prepareOrder?.deliveryType === DeliveryTypeEnum.DELIVERY)
    ) {
      basketActions.checkActions('update', true)
    }
  }, [
    prepareOrder?.restaurant?.restaurantId,
    prepareOrder?.deliveryType,
    deliveryInfo?.restaurantId,
    basketActions,
  ])

  const getCoordinatesByAddress = useCallback(async () => {
    setIsUpdateAddress(false)
    const findAddress: any = await searchAddressByAddressStrService(
      prepareOrder.selectedAddress?.city +
        ' ' +
        prepareOrder.selectedAddress?.street +
        ' ' +
        prepareOrder.selectedAddress?.house,
      city?.cityId,
    )
    if (
      findAddress &&
      findAddress[0] &&
      findAddress[0].street &&
      findAddress[0].house &&
      findAddress[0].house_fias_id
    ) {
      const coordinates: LatLng = {
        latitude: parseFloat(findAddress[0].geo_lat),
        longitude: parseFloat(findAddress[0].geo_lon),
      }
      const streetType: string = findAddress[0].street_type_full
      const streetKladrId: string = findAddress[0].street_kladr_id
      const houseFiasId: string = findAddress[0].house_fias_id
      let clientAddress = {...prepareOrder.selectedAddress}
      clientAddress.latitude = coordinates.latitude
      clientAddress.longitude = coordinates.longitude
      clientAddress.streetType = streetType
      clientAddress.streetKladrId = streetKladrId
      clientAddress.houseFiasId = houseFiasId
      setPrepareOrderField('selectedAddress', clientAddress)
      if (prepareOrder.prepareTime?.orderTime && !prepareOrder.orderId) {
        setPrepareOrderField('prepareTime', {
          orderTime: null,
          isQuickly: false,
        })
        orderActions.toggleIsShowDiscardedDeliveryTimeModal(true, prepareOrder.deliveryType)
      } else {
        refreshDeliveryPrice()
      }
    } else {
      let clientAddress = {...prepareOrder.selectedAddress}
      clientAddress.latitude = null
      clientAddress.longitude = null
      clientAddress.houseFiasId = null
      setPrepareOrderField('selectedAddress', clientAddress)
      orderActions.setDeliveryInfo(null)
      basketActions.setDeliverySale(null)
      setError('Не удалось найти адрес')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    prepareOrder.selectedAddress,
    prepareOrder?.prepareTime?.orderTime,
    setPrepareOrderField,
    refreshDeliveryPrice,
    city?.cityId,
  ])

  const check = useCallback(() => {
    if (
      prepareOrder &&
      prepareOrder.selectedAddress &&
      prepareOrder.selectedAddress.house &&
      prepareOrder.selectedAddress.street &&
      prepareOrder.deliveryType === DeliveryTypeEnum.DELIVERY
    ) {
      getCoordinatesByAddress()
    } else {
      orderActions.setDeliveryInfo(null)
      basketActions.setDeliverySale(null)
      if (prepareOrder.deliveryType === DeliveryTypeEnum.PICKUP) {
        refreshDeliveryPrice()
      } else {
        setIsUpdateAddress(false)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    prepareOrder.selectedAddress?.house,
    prepareOrder.selectedAddress?.street,
    prepareOrder.selectedAddress?.latitude,
    prepareOrder.selectedAddress?.longitude,
    prepareOrder.deliveryType,
    refreshDeliveryPrice,
    getCoordinatesByAddress,
    city?.cityId,
  ])

  useEffect(() => {
    const intervalId: any = setInterval(() => {
      if (isUpdateAddress && prepareOrder.deliveryType === DeliveryTypeEnum.DELIVERY) {
        check()
      }
      if (
        isUpdateTime &&
        prepareOrder.deliveryType === DeliveryTypeEnum.DELIVERY &&
        prepareOrder?.selectedAddress?.house &&
        prepareOrder?.selectedAddress?.houseFiasId &&
        prepareOrder?.selectedAddress?.street &&
        (prepareOrder?.prepareTime?.isQuickly ||
          (!prepareOrder?.prepareTime?.isQuickly && prepareOrder?.prepareTime?.orderTime))
      ) {
        refreshDeliveryPrice()
        setIsUpdateTime(false)
      }
    }, 1000)
    return () => {
      clearInterval(intervalId)
    }
  }, [check, isUpdateAddress, isUpdateTime, deliveryInfo, prepareOrder, refreshDeliveryPrice])

  const toggleAutoChooseRestaurant = useCallback(() => {
    let newAutoChoiceRestaurant: AutoChoiceRestaurantType = {...autoChoiceRestaurant}
    newAutoChoiceRestaurant.isAutoChoice = !newAutoChoiceRestaurant.isAutoChoice
    orderActions.changeAutoChoiceRestaurantRequest(newAutoChoiceRestaurant)
  }, [autoChoiceRestaurant, orderActions])

  const setPrepareOrderRestaurant = useCallback(
    (restaurant) => {
      setError(null)
      orderActions.setPrepareOrderRestaurantRequest(restaurant)
      orderActions.toggleIsShowChooseRestaurantModal(false)
    },
    [orderActions],
  )

  const [findAddress, setFindAddress] = useState<FindAddressType>({
    fiasCityId: null,
    searchValue: null,
  })
  const [resultFindAddress, setResultFindAddress] = useState<ResultFindAddressType>(null)

  const debouncedSearchTerm = useDebounce(findAddress, 666)

  useEffect(() => {
    if (prepareOrder && prepareOrder.selectedAddress && prepareOrder.selectedAddress?.street) {
      if (city) {
        const newSearchValue = prepareOrder.selectedAddress?.street
        const newFiasCityId = city.fiasId
        if (
          findAddress.searchValue !== newSearchValue ||
          newFiasCityId !== findAddress.fiasCityId
        ) {
          const newFindAddresses = {
            searchValue: newSearchValue,
            fiasCityId: newFiasCityId,
          }
          setFindAddress(newFindAddresses)
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prepareOrder?.selectedAddress?.street, city, findAddress.searchValue, findAddress.fiasCityId])

  useEffect(() => {
    if (debouncedSearchTerm) {
      searchAddressesBySearchValueService(
        debouncedSearchTerm.searchValue,
        debouncedSearchTerm.fiasCityId,
        city?.cityId,
      ).then((results) => {
        setResultFindAddress(results)
      })
    } else {
      setResultFindAddress(null)
    }
  }, [city?.cityId, debouncedSearchTerm])

  const findAddresses = useCallback(
    async (addresses: ResultFindAddressType) => {
      // const addresses: ResultFindAddressType = resultFindAddress
      // await searchAddressesBySearchValueService(street, fiasId)

      if (addresses && Array.isArray(addresses.suggestions)) {
        const newFoundAddresses: FoundAddress[] = []

        for (let address of addresses.suggestions) {
          if (address.data.city && (address.data.street || address.data.house)) {
            let foundAddress: FoundAddress = {
              cityId: null,
              clientId: null,
              isDefault: null,
              city: address.data.city,
              streetType: address.data.street_type_full,
              streetKladrId: address.data.street_kladr_id,
              street:
                (address.data.settlement_with_type
                  ? address.data.settlement_with_type + ', '
                  : '') + address.data.street_with_type,
              house: address.data.house,
              houseFiasId: address.data.house_fias_id,
              building: null,
              value: address.value,
              coordinates: {
                latitude: parseFloat(address.data.geo_lat),
                longitude: parseFloat(address.data.geo_lon),
              },
              latitude: parseFloat(address.data.geo_lat),
              longitude: parseFloat(address.data.geo_lon),
            }
            newFoundAddresses.push(foundAddress)
          }
        }
        handleChangeFoundAddresses(newFoundAddresses)
      }
    },
    [handleChangeFoundAddresses],
  )

  useEffect(() => {
    //ходит в дадату только на изменения полей street и house
    findAddresses(resultFindAddress)
  }, [findAddresses, resultFindAddress])

  const isSubmitDisabled = useCallback(() => {
    if (isCheckActions) {
      setError('Идет обновление цены заказа')
      return true
    }
    if (!prepareOrder.name) {
      setError('Заполните поле имя')
      return true
    }
    if (prepareOrder.deliveryType === DeliveryTypeEnum.PICKUP) {
      if (!prepareOrder.restaurant) {
        setError('Выберите ресторан для самовывоза')
        return true
      }
    }
    if (prepareOrder.deliveryType === DeliveryTypeEnum.DELIVERY) {
      if (prepareOrder.selectedAddress) {
        if (!prepareOrder.selectedAddress.street) {
          setError('Заполните поле улица')
          return true
        }
        if (!prepareOrder.selectedAddress.house) {
          setError('Заполните поле дом')
          return true
        }
      }

      if (
        prepareOrder.selectedAddress &&
        !prepareOrder.selectedAddress.longitude &&
        !prepareOrder.selectedAddress.latitude &&
        !prepareOrder.selectedAddress.houseFiasId
      ) {
        if (isUpdateAddress) {
          setError('Идет определение адреса доставки')
          return true
        }
        setError('Не удалось найти адрес')
        return true
      }

      if (!deliveryInfo) {
        setError('К сожалению, мы не можем доставить к выбранному времени')
        return true
      }

      if (isUpdateAddress) {
        setError('Идет определение адреса доставки')
        return true
      }
    }
    if (!prepareOrder.prepareTime.isQuickly) {
      if (!prepareOrder.prepareTime.orderTime) {
        setError("Выберите время доставки или укажите 'Как можно быстрее'")
        return true
      }
    }
    if (selectedProducts?.length < 1) {
      setError('Невозможно оформить заказ без продуктов')
      return true
    }
    if (!prepareOrder.paymentType) {
      setError('Не задан способ оплаты')
      return true
    }
    if (error) {
      return true
    }
    return false
  }, [
    deliveryInfo,
    error,
    isUpdateAddress,
    prepareOrder.deliveryType,
    prepareOrder.name,
    prepareOrder.prepareTime.isQuickly,
    prepareOrder.prepareTime.orderTime,
    prepareOrder.restaurant,
    prepareOrder.selectedAddress,
    prepareOrder.paymentType,
    selectedProducts?.length,
    isCheckActions,
  ])

  const saveOrder = useCallback(() => {
    if (isSubmitDisabled()) {
    } else {
      orderActions.saveOrderRequest({
        setIsRedirect: () => {
          setIsSendingRequests(true)
        },
        phoneNumber: null,
      })
    }
  }, [isSubmitDisabled, orderActions])

  useEffect(() => {
    if (isRequestSaveOrder !== null && !isRequestSaveOrder && isSendingRequest) {
      if (prepareOrder.paymentType === PaymentTypeEnum.ONLINE) {
        if (payment && payment.formUrl) {
          window.location.replace(payment.formUrl)
        }
      } else {
        setTimeout(() => {
          window.open(TELEGRAM_REDIRECT_URL, '_blank')
        }, 1500)
        history.push('/profile')
      }
    }
  }, [isRequestSaveOrder, isSendingRequest, payment, prepareOrder.paymentType, urlPrefix])

  const getPaymentTypeList = useMemo(() => {
    if (restaurantPaymentTypeMap) {
      const deliveryType: DeliveryTypeEnum = prepareOrder?.deliveryType
      const restaurantId: string =
        deliveryType === DeliveryTypeEnum.DELIVERY
          ? deliveryInfo?.restaurantId
          : prepareOrder?.restaurant?.restaurantId

      const cashboxPaymentType: CashboxPaymentType = restaurantPaymentTypeMap[restaurantId]
      if (cashboxPaymentType) {
        return getPaymentTypeListUtils(deliveryType, cashboxPaymentType)
      } else {
        return null
      }
    } else {
      return null
    }
  }, [deliveryInfo, prepareOrder, restaurantPaymentTypeMap])

  const renderDiscardedDeliveryTimeModal = useMemo(() => {
    if (!isShowDiscardedDeliveryTimeModal) {
      return null
    } else {
      let text
      if (
        prepareOrder.deliveryType === DeliveryTypeEnum.DELIVERY &&
        prepareOrder.deliveryType !== discardedDeliveryTimeDeliveryType
      ) {
        text = 'Выбранное время самовывоза сброшено по умолчанию'
      } else if (
        prepareOrder.deliveryType === DeliveryTypeEnum.PICKUP &&
        prepareOrder.deliveryType === discardedDeliveryTimeDeliveryType
      ) {
        text = 'Выбранное время самовывоза сброшено по умолчанию'
      } else {
        text = 'Выбранное время доставки сброшено по умолчанию'
      }
      return (
        <Modal
          isOpen={isShowDiscardedDeliveryTimeModal}
          onClose={closeDiscardedDeliveryTimeModal}
          size={'xs'}>
          <ModalBody className={'mt-x4'}>{text}</ModalBody>
          <ModalFooter>
            <Button onClick={closeDiscardedDeliveryTimeModal} size={'sm'} isRoundedButton>
              Понятно
            </Button>
          </ModalFooter>
        </Modal>
      )
    }
  }, [
    isShowDiscardedDeliveryTimeModal,
    prepareOrder.deliveryType,
    discardedDeliveryTimeDeliveryType,
    closeDiscardedDeliveryTimeModal,
  ])

  const renderContent: React.ReactElement = useMemo(() => {
    if (!prepareOrder.phone) {
      return null
    }

    return (
      <Container size={'normal'} className={'mt-x10 mb-x10'}>
        <Row>
          <Col md={{size: 8, offset: 2}} sm={{size: 10, offset: 1}}>
            <div className={'card-place-order'}>
              <HeaderOrderPage
                goBack={goBack}
                deliveryType={prepareOrder.deliveryType}
                toggleTabButton={toggleTabButton}
              />
              <OrderTake
                prepareOrder={prepareOrder}
                setPrepareOrderField={setPrepareOrderField}
                error={error}
              />
              {prepareOrder.selectedAddress && (
                <OrderDeliveryAddress
                  city={city}
                  deliveryType={prepareOrder.deliveryType}
                  clientAddress={prepareOrder.selectedAddress}
                  foundAddresses={foundAddresses}
                  setPrepareOrderField={setPrepareOrderField}
                  favouritesRestaurants={favouritesRestaurants}
                  activeRestaurant={prepareOrder.restaurant}
                  setPrepareOrderRestaurant={setPrepareOrderRestaurant}
                  setIsUpdateAddress={setIsUpdateAddress}
                  isUpdateAddress={isUpdateAddress}
                  error={
                    //TODO:: Как нибудь убрать костыль
                    error === 'К сожалению, мы не можем доставить к выбранному времени'
                      ? undefined
                      : error
                  }
                  autoChoiceRestaurant={autoChoiceRestaurant}
                  toggleAutoChooseRestaurant={toggleAutoChooseRestaurant}
                  deliveryInfo={deliveryInfo}
                />
              )}

              {prepareOrder.deliveryType === DeliveryTypeEnum.DELIVERY && (
                <OrderComment
                  deliveryType={prepareOrder.deliveryType}
                  setPrepareOrderField={setPrepareOrderField}
                  commentary={prepareOrder.commentary}
                  countPersons={prepareOrder.countPersons}
                />
              )}

              <OrderTimeAccept
                deliveryType={prepareOrder.deliveryType}
                prepareTime={prepareOrder.prepareTime}
                isShowOrderTimeAccept={
                  (prepareOrder.deliveryType === DeliveryTypeEnum.PICKUP &&
                    !!prepareOrder.restaurant) ||
                  (prepareOrder.deliveryType === DeliveryTypeEnum.DELIVERY &&
                    !!prepareOrder?.selectedAddress?.house &&
                    !!prepareOrder?.selectedAddress?.houseFiasId &&
                    !!prepareOrder?.selectedAddress?.street)
                }
                setPrepareOrderField={setPrepareOrderField}
                restaurant={prepareOrder.restaurant}
                deliveryInfo={deliveryInfo}
                addMinutesOrder={addMinutesOrder}
                numberBlock={prepareOrder.deliveryType === DeliveryTypeEnum.PICKUP ? 3 : 4}
              />

              <OrderPaymentMethod
                prepareOrder={prepareOrder}
                paymentTypes={getPaymentTypeList}
                setPrepareOrderField={setPrepareOrderField}
                numberBlock={prepareOrder.deliveryType === DeliveryTypeEnum.PICKUP ? 4 : 5}
              />

              <OrderFullPrice
                deliveryPrice={deliveryInfo?.price}
                priceFull={priceFull + saleFull}
                priceResult={priceFull}
                deliverySale={deliverySale}
                deliveryType={prepareOrder.deliveryType}
                prepareTime={prepareOrder.prepareTime}
                isSeparateDeliveryPayment={deliveryInfo?.isSeparateDeliveryPayment}
                deliveryTimeInMinutes={deliveryInfo?.deliveryTime}
                isCheckActions={isCheckActions}
                isErrorUpdateCheckActions={isErrorUpdateCheckActions}
              />
              <PlaceOrderBlock
                saveOrder={saveOrder}
                refreshPrice={refreshPrice}
                isRequestSaveOrder={isRequestSaveOrder}
                isErrorUpdateCheckActions={isErrorUpdateCheckActions}
                error={error}
              />
              {renderDiscardedDeliveryTimeModal}
            </div>
          </Col>
        </Row>
      </Container>
    )
  }, [
    prepareOrder,
    goBack,
    toggleTabButton,
    setPrepareOrderField,
    error,
    city,
    foundAddresses,
    favouritesRestaurants,
    setPrepareOrderRestaurant,
    isUpdateAddress,
    autoChoiceRestaurant,
    toggleAutoChooseRestaurant,
    deliveryInfo,
    addMinutesOrder,
    getPaymentTypeList,
    priceFull,
    saleFull,
    deliverySale,
    saveOrder,
    isRequestSaveOrder,
    renderDiscardedDeliveryTimeModal,
    isCheckActions,
    isErrorUpdateCheckActions,
    refreshPrice,
  ])

  return <>{renderContent}</>
}

export default connectComponent(
  (state: State, props: any) => ({
    city: state.city.selectedCity,
    client: state.auth.client,
    selectedProducts: state.basket.selectedProducts,
    goBack: props.history.goBack,
    prepareOrder: state.order.prepareOrder,
    priceFull: state.basket.priceFull,
    saleFull: state.basket.saleFull,
    deliverySale: state.basket.deliverySale,
    isShowChooseOnlinePaymentTypeModal: state.order.isShowChooseOnlinePaymentTypeModal,
    isRequestSaveOrder: state.order.isRequestSaveOrder,
    isShowDiscardedDeliveryTimeModal: state.order.isShowDiscardedDeliveryTimeModal,
    discardedDeliveryTimeDeliveryType: state.order.discardedDeliveryTimeDeliveryType,
    addMinutesOrder: state.order.addMinutesOrder,
    restaurantPaymentTypeMap: state.restaurant.restaurantPaymentTypeMap,
    deliveryInfo: state.order.deliveryInfo,
    favouritesRestaurants: state.restaurant.favouritesRestaurants,
    payment: state.payment.payment,
    autoChoiceRestaurant: state.order.autoChoiceRestaurant,
    isCheckActions: state.basket.isUpdateCheckActions,
    isErrorUpdateCheckActions: state.basket.isErrorUpdateCheckActions,
  }),
  (dispatch) => ({
    orderActions: bindOrderActions(dispatch),
    basketActions: bindBasketActions(dispatch),
  }),
  PlaceOrder,
)
