import {ProductDTO, ProductMap} from 'grilnica-store-share'

const getProductById = (productId: string, productMap: ProductMap): ProductDTO => {
  let product: ProductDTO
  for (let menuCategoryId in productMap) {
    if (productMap.hasOwnProperty(menuCategoryId)) {
      const products: ProductDTO[] = productMap[menuCategoryId]
      const newProduct: ProductDTO = products.find(
        (item: ProductDTO) => item.productId === productId,
      )
      if (newProduct) {
        product = {...newProduct}
        break
      }
    }
  }
  return product
}

export {getProductById}
