import {OptionMap} from '../../../../../types/product/OptionMap'
import {OptionCategoryDTO} from 'grilnica-store-share'
import OptionProductMap from '../../../../../types/product/OptionProductMap'

function getOptionMap(optionCategories: OptionCategoryDTO[]): OptionMap {
  let optionMap: OptionMap = {}
  for (let optionCategory of optionCategories) {
    let optionProductMap: OptionProductMap = {}
    for (let option of optionCategory.options) {
      let optionCategoryIds: string[] = [optionCategory.optionCategoryId]
      if (optionMap[option.optionId]) {
        optionMap[option.optionId].optionCategoryIds = [
          ...optionMap[option.optionId].optionCategoryIds,
          ...optionCategoryIds,
        ]
        optionMap[option.optionId].price = option.price
      } else {
        optionMap[option.optionId] = {
          optionCategoryIds,
          price: option.price,
          name: option.name,
          isDeleteOptions: option.isDeleteOptions,
          productId: option.productId,
          option: option,
        }
        optionMap[option.optionId].optionCategoryIds = optionCategoryIds
      }
      if (option.productId) {
        if (optionProductMap[option.productId]) {
          optionProductMap[option.productId] = [
            ...optionProductMap[option.productId],
            {
              optionCategoryId: optionCategory.optionCategoryId,
              optionId: option.optionId,
            },
          ]
        } else {
          optionProductMap[option.productId] = [
            {
              optionCategoryId: optionCategory.optionCategoryId,
              optionId: option.optionId,
            },
          ]
        }
      }
    }
  }

  return optionMap
}

export {getOptionMap}
