import React from 'react'
import {DeliveryTypeEnum} from 'grilnica-store-share'
import {DeliveryInfo} from '../../../../../../types/order/DeliveryInfo'
import {Alert} from 'grilnica-reactstrap'
import {formatDeliveryTime} from 'grilnica-share/lib/utils'

interface DeliveryTimeAlertProps {
  deliveryType: DeliveryTypeEnum
  deliveryInfo: DeliveryInfo
}

const DeliveryTimeAlert: React.FC<DeliveryTimeAlertProps> = ({deliveryType, deliveryInfo}) => {
  if (deliveryType === DeliveryTypeEnum.DELIVERY && !!deliveryInfo) {
    return (
      <Alert
        message={'Доставим в течение ' + formatDeliveryTime(deliveryInfo.deliveryTime)}
        color={'warning'}
        className={'mb-x4'}
      />
    )
  } else {
    return null
  }
}

export {DeliveryTimeAlert}
