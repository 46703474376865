import {bindActionCreators} from 'redux'
import {BaseAction} from '../common/BaseAction'
import {RestaurantDTO} from 'grilnica-share'
import {PaymentTypeMap} from 'grilnica-store-share'
import {FavouritesRestaurant} from '../../types/restaurant/FavouritesRestaurant'

export interface RestaurantState {
  restaurants: RestaurantDTO[]
  favouritesRestaurants?: FavouritesRestaurant[]
  selectedRestaurantInMap?: RestaurantDTO
  searchRestaurantNameInMap?: string
  foundRestaurantList?: RestaurantDTO[]
  restaurantPaymentTypeMap: PaymentTypeMap
}

export enum RestaurantActionsTypes {
  LOAD_RESTAURANTS_REQUEST = 'restaurants/LOAD_RESTAURANTS_REQUEST',
  SET_RESTAURANTS_SUCCESS = 'restaurants/SET_RESTAURANTS_SUCCESS',
  SET_FAVOURITE_RESTAURANTS = 'restaurants/SET_FAVOURITES_RESTAURANTS',
  CLEAR_FAVOURITE_RESTAURANTS_REQUEST = 'restaurants/CLEAR_CLIENT_RESTAURANTS_REQUEST',
  ADD_CLIENT_RESTAURANTS_REQUEST = 'restaurants/ADD_CLIENT_RESTAURANTS_REQUEST ',
  ADD_FAVOURITE_RESTAURANT_REQUEST = 'restaurants/ADD_FAVOURITE_RESTAURANT_REQUEST',
  DELETE_FAVOURITE_RESTAURANT_REQUEST = 'restaurants/DELETE_FAVOURITE_RESTAURANT_REQUEST',
  SELECT_RESTAURANT_IN_MAP = 'restaurant/SELECT_RESTAURANT_IN_MAP',
  CHANGE_SEARCH_RESTAURANT_NAME_IN_MAP_REQUEST = 'restaurant/CHANGE_SEARCH_RESTAURANT_NAME_IN_MAP_REQUEST',
  SET_FOUND_RESTAURANT_LIST = 'restaurant/SET_FIND_RESTAURANT_LIST',
  CLEAR_RESTAURANT_MAP_STATE = 'restaurant/CLEAR_RESTAURANT_MAP_STATE',
  SET_SEARCH_RESTAURANT_NAME = 'restaurant/SET_SEARCH_RESTAURANT_NAME',
  GET_RESTAURANT_PAYMENT_TYPE_MAP_REQUEST = 'restaurant/GET_RESTAURANT_PAYMENT_TYPE_MAP_REQUEST',
  SET_RESTAURANT_PAYMENT_TYPE_MAP = 'restaurant/SET_RESTAURANT_PAYMENT_TYPE_MAP',
}

const INITIAL_STATE: RestaurantState = {
  restaurants: [],
  favouritesRestaurants: [],
  selectedRestaurantInMap: null,
  searchRestaurantNameInMap: null,
  foundRestaurantList: [],
  restaurantPaymentTypeMap: null,
}

export const Actions = {
  loadRestaurantRequest: (): BaseAction => ({
    type: RestaurantActionsTypes.LOAD_RESTAURANTS_REQUEST,
  }),

  setRestaurants: (restaurants: RestaurantDTO[]): BaseAction => ({
    type: RestaurantActionsTypes.SET_RESTAURANTS_SUCCESS,
    payload: restaurants,
  }),

  setFavouriteRestaurants: (favouritesRestaurants: FavouritesRestaurant[]): BaseAction => ({
    type: RestaurantActionsTypes.SET_FAVOURITE_RESTAURANTS,
    payload: favouritesRestaurants,
  }),

  clearFavouriteRestaurantsRequest: (): BaseAction => ({
    type: RestaurantActionsTypes.CLEAR_FAVOURITE_RESTAURANTS_REQUEST,
  }),

  addFavouriteRestaurantRequest: (restaurantId: string): BaseAction => ({
    type: RestaurantActionsTypes.ADD_FAVOURITE_RESTAURANT_REQUEST,
    payload: restaurantId,
  }),

  deleteFavouriteRestaurantRequest: (restaurantId: string): BaseAction => ({
    type: RestaurantActionsTypes.DELETE_FAVOURITE_RESTAURANT_REQUEST,
    payload: restaurantId,
  }),

  selectRestaurantInMap: (selectedRestaurantIndex: number): BaseAction => ({
    type: RestaurantActionsTypes.SELECT_RESTAURANT_IN_MAP,
    payload: selectedRestaurantIndex,
  }),

  changeSearchRestaurantNameInMapRequest: (restaurantName: string): BaseAction => ({
    type: RestaurantActionsTypes.CHANGE_SEARCH_RESTAURANT_NAME_IN_MAP_REQUEST,
    payload: restaurantName,
  }),

  setFoundRestaurantList: (restaurantList: RestaurantDTO[]): BaseAction => ({
    type: RestaurantActionsTypes.SET_FOUND_RESTAURANT_LIST,
    payload: restaurantList,
  }),

  setSearchRestaurantName: (restaurantName: string): BaseAction => ({
    type: RestaurantActionsTypes.SET_SEARCH_RESTAURANT_NAME,
    payload: restaurantName,
  }),

  clearRestaurantMapState: (): BaseAction => ({
    type: RestaurantActionsTypes.CLEAR_RESTAURANT_MAP_STATE,
  }),

  getRestaurantPaymentTypeMapRequest: (): BaseAction => ({
    type: RestaurantActionsTypes.GET_RESTAURANT_PAYMENT_TYPE_MAP_REQUEST,
  }),

  setRestaurantPaymentTypeMap: (restaurantPaymentTypeMap: PaymentTypeMap): BaseAction => ({
    type: RestaurantActionsTypes.SET_RESTAURANT_PAYMENT_TYPE_MAP,
    payload: restaurantPaymentTypeMap,
  }),
}

export function bindRestaurantActions(dispatch: any) {
  return bindActionCreators(Actions, dispatch)
}

export default function restaurant(
  state: RestaurantState = INITIAL_STATE,
  action: BaseAction,
): RestaurantState {
  switch (action.type) {
    case RestaurantActionsTypes.SET_RESTAURANTS_SUCCESS: {
      return {
        ...state,
        restaurants: action.payload,
      }
    }

    case RestaurantActionsTypes.SET_FAVOURITE_RESTAURANTS: {
      return {
        ...state,
        favouritesRestaurants: action.payload,
      }
    }

    case RestaurantActionsTypes.SELECT_RESTAURANT_IN_MAP: {
      return {
        ...state,
        selectedRestaurantInMap: state.foundRestaurantList[action.payload],
        searchRestaurantNameInMap: state.foundRestaurantList[action.payload].name,
      }
    }

    case RestaurantActionsTypes.CHANGE_SEARCH_RESTAURANT_NAME_IN_MAP_REQUEST: {
      return {
        ...state,
        searchRestaurantNameInMap: action.payload,
      }
    }

    case RestaurantActionsTypes.SET_FOUND_RESTAURANT_LIST: {
      return {
        ...state,
        foundRestaurantList: action.payload,
      }
    }

    case RestaurantActionsTypes.SET_SEARCH_RESTAURANT_NAME: {
      return {
        ...state,
        searchRestaurantNameInMap: action.payload,
      }
    }

    case RestaurantActionsTypes.CLEAR_RESTAURANT_MAP_STATE: {
      return {
        ...state,
        selectedRestaurantInMap: null,
        foundRestaurantList: [...state.restaurants],
        searchRestaurantNameInMap: null,
      }
    }

    case RestaurantActionsTypes.SET_RESTAURANT_PAYMENT_TYPE_MAP: {
      return {
        ...state,
        restaurantPaymentTypeMap: action.payload,
      }
    }

    default: {
      return {
        ...state,
      }
    }
  }
}
