import React, {useCallback, useContext, useEffect, useMemo, useState} from 'react'
import {CityDTO, filterByString, getItemByKey} from 'grilnica-share'
import {connectComponent} from '../../../store/common'
import {SubsystemInfo} from 'grilnica-share-client'
import {bindCityActions} from '../../../store/ducks/city'
import {ClientDTO, ContactDetailsMap, MenuCategoryDTO} from 'grilnica-store-share'
import {Header} from './components/Header'
import {SiteNavigation} from './components/SiteNavigation'
import {MenuModal} from '../menu/MenuModal'
import {BasketState} from '../../../store/ducks/basket'
import {UrlPrefixContext} from '../../site/components/App'
import {State} from '../../../store/ducks'
import {
  Button,
  ButtonIcon,
  Col,
  COLORS,
  Container,
  Icon,
  InputVertical,
  Modal,
  Row,
} from 'grilnica-reactstrap'
import {useIsClient} from '../../hooks/useIsClient'
import {closeSVG, magnifySVG} from '../svg/SystemIcons'
import {getSiteUrlByCity} from './utils/getSiteUrlByCity'

interface PageHeaderProps {
  subsystem: SubsystemInfo
  cityActions: any
  cityList: CityDTO[]
  selectedCity: CityDTO
  client: ClientDTO
  categoryList: MenuCategoryDTO[]
  onOpen: () => void
  basket: BasketState
  searchValue: string
  handleChangeSearchValue: (value: string) => void
  contactDetailsMap: ContactDetailsMap
  cityId: string
  isConfirm: boolean
}

const PageHeader: React.FC<PageHeaderProps> = ({
  subsystem,
  cityActions,
  cityList,
  selectedCity,
  client,
  basket,
  searchValue,
  handleChangeSearchValue,
  contactDetailsMap,
  cityId,
  isConfirm,
}): React.ReactElement => {
  const urlPrefix: string = useContext(UrlPrefixContext)
  const [searchCityValue, setSearchCityValue] = useState<string>('')
  const [filteredCityList, setFilteredCityList] = useState<CityDTO[]>(undefined)

  const [isConfirmCity, setIsConfirmCity] = useState<boolean>(null)
  const [cityIdByIp, setCityIdByIp] = useState<string>(null)

  useEffect(() => {
    if (cityList?.length > 0) {
      setFilteredCityList([...cityList])
      const newIsConfirmCity: boolean = isConfirm || false
      const newCityIdByIp: string = cityId || undefined
      setIsConfirmCity(newIsConfirmCity)
      setCityIdByIp(newCityIdByIp)
    }
  }, [cityId, cityList, isConfirm])

  const onSelectedCity = useCallback(
    (city: CityDTO, callback: () => void) => {
      cityActions.selectCityRequest(city, callback)
    },
    [cityActions],
  )

  const onSelectCityInCookies = useCallback(
    (cityInCookies: CityDTO, isConfirmInCookies: boolean, callback: () => void) => {
      cityActions.selectCityInCookiesRequest(cityInCookies, isConfirmInCookies, callback)
    },
    [cityActions],
  )

  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [isOpenSelectCityModal, setIsOpenSelectCityModal] = useState<boolean>(false)

  const [isClient] = useIsClient()

  const onCloseMenu = useCallback(() => {
    setIsOpen(false)
  }, [])

  const onOpenMenu = useCallback(() => {
    setIsOpen(true)
  }, [])

  const renderHintCity = useMemo(() => {
    if (isClient && cityList?.length > 0) {
      let cityByIp: CityDTO
      if (cityIdByIp) {
        cityByIp = getItemByKey('cityId', cityList || [], cityIdByIp)
      }
      if (!isConfirmCity && cityByIp) {
        return (
          <Container size={'normal'}>
            <div className={'hint-city'}>
              <p className={'hint-city-p'}>
                Ваш город – <span className={'font-weight-medium'}>{cityByIp?.name}</span>?
              </p>
              <div className={'hint-city-buttons'}>
                <Button
                  isRoundedButton
                  size={'sm'}
                  style={'outline'}
                  onClick={() => {
                    setIsConfirmCity(true)
                    onSelectCityInCookies(cityByIp, true, () => {
                      if (selectedCity?.cityId !== cityIdByIp) {
                        if (cityByIp) {
                          onSelectedCity(cityByIp, () => {
                            let href: string = getSiteUrlByCity(cityByIp)
                            window.location.replace(href)
                          })
                        }
                      }
                    })
                  }}
                  className={'mr-x4'}>
                  Да, верно
                </Button>
                <Button
                  isRoundedButton
                  size={'sm'}
                  style={'outline'}
                  onClick={() => {
                    setIsOpenSelectCityModal(true)
                  }}>
                  Другой
                </Button>
              </div>
            </div>
          </Container>
        )
      } else {
        return <></>
      }
    } else {
      return <></>
    }
  }, [
    cityIdByIp,
    cityList,
    isClient,
    isConfirmCity,
    onSelectCityInCookies,
    onSelectedCity,
    selectedCity?.cityId,
  ])

  const handleChangeSearchCityValue = useCallback(
    (value: string) => {
      setSearchCityValue(value)
      const newFilteredCityList = filterByString<CityDTO>(
        cityList || [],
        (city) => city.name,
        value as string,
      )
      setFilteredCityList([...newFilteredCityList])
    },
    [cityList],
  )

  const renderSelectCityModal = useMemo(() => {
    if (isClient && isOpenSelectCityModal) {
      const cityItems: React.ReactNode[] = []
      for (const filteredCity of filteredCityList) {
        cityItems.push(
          <Col xl={6} lg={6} md={6} sm={6} xs={12} key={filteredCity.cityId}>
            <p
              className={'select-city-modal-item'}
              onClick={() => {
                setIsConfirmCity(true)
                onSelectCityInCookies(filteredCity, true, () => {
                  onSelectedCity(filteredCity, () => {
                    let href: string = getSiteUrlByCity(filteredCity)
                    window.location.replace(href)
                  })
                })
              }}>
              {filteredCity.name}
            </p>
          </Col>,
        )
      }

      if (cityItems.length === 0) {
        cityItems.push(
          <Col xl={12} lg={12} md={12} sm={12} xs={12} key={'empty-search'}>
            <p className={'select-city-modal-empty-search'}>Ничего не найдено</p>
          </Col>,
        )
      }

      return (
        <Modal
          size={'xs'}
          classNameWrapper={'select-city-modal-wrapper'}
          className={'pl-x4 pt-x4 pr-x4 pb-0'}
          isOpen={isOpenSelectCityModal}
          onClose={() => {
            setIsOpenSelectCityModal(false)
          }}>
          <div className={'select-city-modal w-100'}>
            <div className={'select-city-modal-header'}>
              <h4 className={'select-city-modal-header-h'}>Ваш город</h4>
              <ButtonIcon
                iconPath={closeSVG}
                isHoverArea
                title={'Закрыть'}
                className={'ml-x4'}
                onClick={() => {
                  setIsOpenSelectCityModal(false)
                }}
              />
            </div>
            <div className={'select-city-modal-search mb-x4'}>
              <InputVertical
                id={'search-input'}
                size={'sm'}
                className={'mb-0'}
                isRounded
                name={'search-city'}
                type={'text'}
                value={searchCityValue}
                isRequired={false}
                onChange={handleChangeSearchCityValue}
                placeholder={'Поиск города'}
              />
              {searchCityValue?.length < 1 && (
                <Icon
                  path={magnifySVG}
                  color={COLORS.gray600}
                  size={0.7}
                  className={'search-magnify'}
                />
              )}
              {searchCityValue?.length >= 1 && (
                <ButtonIcon
                  size={'sm'}
                  iconPath={closeSVG}
                  title={'Очистить'}
                  className={'search-clear'}
                  onClick={() => {
                    handleChangeSearchCityValue('')
                  }}
                />
              )}
            </div>
            <Row>{cityItems}</Row>
          </div>
        </Modal>
      )
    }
  }, [
    filteredCityList,
    handleChangeSearchCityValue,
    isClient,
    isOpenSelectCityModal,
    onSelectCityInCookies,
    onSelectedCity,
    searchCityValue,
  ])

  const renderContent: React.ReactElement = useMemo(() => {
    if (subsystem) {
      return (
        <header className={'page-header'}>
          {renderHintCity}
          <SiteNavigation
            onOpenSelectCityModal={() => {
              setIsOpenSelectCityModal(true)
            }}
            client={client}
            selectedCity={selectedCity}
            urlPrefix={urlPrefix}
          />
          <Header
            onOpen={onOpenMenu}
            basket={basket} //todo
            city={selectedCity}
            contactDetailsMap={contactDetailsMap}
            searchValue={searchValue}
            handleChangeSearchValue={handleChangeSearchValue}
          />
          {isOpen && (
            <MenuModal
              onOpenSelectCityModal={() => {
                setIsOpenSelectCityModal(true)
              }}
              selectedCity={selectedCity}
              contactDetailsMap={contactDetailsMap}
              onClose={onCloseMenu}
              client={client}
              urlPrefix={urlPrefix}
            />
          )}
          {renderSelectCityModal}
        </header>
      )
    } else {
      return null
    }
  }, [
    basket,
    client,
    contactDetailsMap,
    handleChangeSearchValue,
    isOpen,
    onCloseMenu,
    onOpenMenu,
    renderHintCity,
    searchValue,
    selectedCity,
    subsystem,
    urlPrefix,
    renderSelectCityModal,
  ])

  return <React.Fragment>{renderContent}</React.Fragment>
}

export default connectComponent(
  (state: State) => ({
    client: state.auth.client,
    basket: state.basket,
    cityList: state.city.cityList,
    selectedCity: state.city.selectedCity,
    cityId: state.city?.cityId,
    isConfirm: state.city?.isConfirm,
    categoryList: state.menu.categoryList,
    contactDetailsMap: state.contactDetails.contactDetailsMap,
  }),
  (dispatch) => ({
    cityActions: bindCityActions(dispatch),
  }),
  PageHeader,
)
