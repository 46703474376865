import {ProductVariantDTO} from 'grilnica-store-share'

const getMinWeight = (productVariants: ProductVariantDTO[]): number => {
  if (!productVariants || !productVariants[0]) {
    return null
  }
  let minWeight = productVariants[0].weight
  for (let variant of productVariants) {
    if (minWeight > variant.weight) {
      minWeight = variant.weight
    }
  }
  return minWeight && minWeight !== 0 ? minWeight : null
}
export {getMinWeight}
