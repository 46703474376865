import React, {useMemo} from 'react'
import {Col, Container, Row} from 'grilnica-reactstrap'
import {Link} from 'react-router-dom'
import {getStaticUrl} from '../../../utils/static'

interface NotFoundPageProps {}

const NotFoundPage: React.FC<NotFoundPageProps> = ({}): React.ReactElement => {
  const renderContent: React.ReactElement = useMemo(() => {
    return (
      <Row className={'align-items-center'}>
        <Col md={{size: 3, offset: 2}}>
          <div>
            <p className={'not-found-page mb-x4'}>404</p>
            <p className={'page-not-exist'}>
              К сожалению запрошенная вами страница не существует перейдите на главную
              <Link to={'/'} title={'На главную'} className={'color-link-text'}>
                {' grilnica.ru'}
              </Link>
            </p>
          </div>
        </Col>
        <Col md={5}>
          <img
            src={getStaticUrl('/img/not-found-page.svg')}
            alt={'Ошибка'}
            title={'Ошибка'}
            className={'not-found-page-image'}
          />
        </Col>
      </Row>
    )
  }, [])

  return (
    <Container size={'normal'} className={'mt-x10 mb-x10'}>
      {renderContent}
    </Container>
  )
}

export {NotFoundPage}
