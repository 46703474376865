import React, {useCallback, useContext, useMemo} from 'react'
import {instagramSVG, logoAltaiCodersSVG, logoSVG, vkSVG, youtubeSVG} from '../../svg'
import FooterList, {FooterListDTO} from './FooterList'
import {Container} from 'grilnica-reactstrap'
import {getFullUrl} from '../../../../utils/url'
import {UrlPrefixContext} from '../../../site/components/App'
import {Link} from 'react-router-dom'
import {CityDTO, isEmptyObject} from 'grilnica-share'
import {connectComponent} from '../../../../store/common'
import {State} from '../../../../store/ducks'
import {getStaticUrl} from '../../../../utils/static'
import {ContactDetailsMap} from 'grilnica-store-share'
import {CONSTANTS} from '../../../../../constants'

interface FooterProps {
  isMinimalFooter?: boolean
  selectedCity?: CityDTO
  contactDetailsMap?: ContactDetailsMap
}

const Footer: React.FC<FooterProps> = ({
  isMinimalFooter,
  selectedCity,
  contactDetailsMap,
}): React.ReactElement => {
  const urlPrefix: string = useContext(UrlPrefixContext)

  const getRenderElementsContacts = useCallback((): React.ReactElement[] => {
    let renderElements: React.ReactElement[] = []
    renderElements.push(
      <li key={'support'}>
        <a href={getFullUrl('/qualitycontrol', urlPrefix)}>Контроль качества</a>
      </li>,
    )
    if (!isEmptyObject(contactDetailsMap)) {
      const footerPhoneNumber =
        selectedCity?.cityId && contactDetailsMap[selectedCity.cityId]?.footerPhoneNumber
          ? contactDetailsMap[selectedCity.cityId]?.footerPhoneNumber
          : contactDetailsMap?.default?.footerPhoneNumberDefault
      const additionalFooterPhoneNumber =
        selectedCity?.cityId && contactDetailsMap[selectedCity.cityId]?.additionalFooterPhoneNumber
          ? contactDetailsMap[selectedCity.cityId]?.additionalFooterPhoneNumber
          : contactDetailsMap?.default?.additionalFooterPhoneNumberDefault
      if (footerPhoneNumber) {
        renderElements.push(
          <li key={'phone-1'}>
            <a href={`tel:${footerPhoneNumber}`}>{footerPhoneNumber}</a>
          </li>,
        )
      }
      if (additionalFooterPhoneNumber) {
        renderElements.push(
          <li key={'phone-2'}>
            <a href={`tel:${additionalFooterPhoneNumber}`}>{additionalFooterPhoneNumber}</a>
          </li>,
        )
      }
    }
    return renderElements
  }, [contactDetailsMap, selectedCity?.cityId, urlPrefix])

  const getList = useCallback((): FooterListDTO[] => {
    let list: FooterListDTO[] = []
    //добавить блок Компания
    list.push({
      footerListId: 'grilnica',
      title: 'Компания',
      renderElements: [
        <li key={'about'}>
          <a href={getFullUrl('/about', urlPrefix)}>О нас</a>
        </li>,
        <li key={'restaurants'}>
          <a href={getFullUrl('/rest', urlPrefix)}>Рестораны</a>
        </li>,
      ],
    })
    //добавить блок Партнерам
    list.push({
      footerListId: 'partners',
      title: 'Партнерам',
      renderElements: [
        <li key={'franchise'}>
          <a href={'https://vkusnayafranshiza.ru/'}>Франшиза</a>
        </li>,
        <li key={'rent'}>
          <a href={getFullUrl('/about#rent', urlPrefix)}>Аренда</a>
        </li>,
        <li key={'supply'}>
          <a href={'/about#supply'}>Снабжение</a>
        </li>,
      ],
    })
    //добавить блок Карьера
    list.push({
      footerListId: 'career',
      title: 'Карьера',
      renderElements: [
        <li key={'shopper'}>
          <a href={'https://tp.grilnica.ru/'}>
            Стать тайным <br /> покупателем
          </a>
        </li>,
        <li key={'work'}>
          <a href={'https://career.grilnica.ru/'}>Работа в Грильнице</a>
        </li>,
      ],
    })
    //добавить блок contacts
    list.push({
      footerListId: 'contacts',
      title: 'Контакты',
      renderElements: getRenderElementsContacts(),
    })
    //добавить блок Документы
    list.push({
      footerListId: 'documents',
      title: 'Документы',
      renderElements: [
        <li key={'personalDataPolicy'}>
          <a href={CONSTANTS.cdnUrl + '/offer.pdf'}>Публичная оферта</a>
        </li>,
        <li key={'agreement'}>
          <a href={CONSTANTS.cdnUrl + '/agreement.pdf'}>
            Политика <br />
            конфиденциальности
          </a>
        </li>,
        <li key={'dataconsent'}>
          <a href={CONSTANTS.cdnUrl + '/dataconsent.pdf'}>Согласие на обработку ПД</a>
        </li>,
        <li key={'detailed-menu'}>
          <a href={CONSTANTS.cdnUrl + '/u-menu.pdf'}>Подробное меню</a>
        </li>,
      ],
    })
    return list
  }, [getRenderElementsContacts, urlPrefix])

  const renderSocialBlock: React.ReactElement = useMemo(() => {
    if (
      !!selectedCity &&
      (selectedCity?.youtubeUrl || selectedCity?.vkUrl || selectedCity?.instagramUrl)
    ) {
      return (
        <div className={'social'}>
          {selectedCity.youtubeUrl && (
            <a title={'Youtube'} href={selectedCity.youtubeUrl}>
              {youtubeSVG}
            </a>
          )}
          {selectedCity.instagramUrl && (
            <a title={'Instagram'} href={selectedCity.instagramUrl}>
              {instagramSVG}
            </a>
          )}
          {selectedCity.vkUrl && (
            <a title={'VK'} href={selectedCity.vkUrl}>
              {vkSVG}
            </a>
          )}
        </div>
      )
    } else {
      return null
    }
  }, [selectedCity])

  const renderContent: React.ReactElement = useMemo(() => {
    return (
      <React.Fragment>
        {!isMinimalFooter && (
          <Container size={'normal'} className={'pt-x6'}>
            <div className={'d-flex flex-column'}>
              <div className={'footer-content'}>
                <div className={'footer-logo-icon'}>
                  <Link className={'logo mr-secondary'} title={'Грильница'} to={'/'}>
                    {logoSVG}
                  </Link>
                  <p className={'footer-restaurant-description mt-x2 mb-0'}>
                    Грильница — сеть ресторанов <br /> быстрого питания. Доставляем <br />{' '}
                    качественную и вкусную еду, <br /> предлагая высокое качество <br /> за
                    приемлемую цену.
                  </p>
                </div>
                <FooterList list={getList()} />
                <div className={'footer-medal'}>
                  <img
                    alt={'Медаль лучший фаст-фуд'}
                    title={'Медаль лучший фаст-фуд'}
                    src={getStaticUrl('/img/medal-footer.svg')}
                    className={'my-x2'}
                  />
                  <p className={'footer-restaurant-description text-center mb-x4'}>
                    Лучший фаст-фуд <br /> ресторан России <br /> 2019
                  </p>
                </div>
              </div>
            </div>
          </Container>
        )}
        <div className={'footer-info-bg'}>
          <Container size={'normal'} className={'footer-info'}>
            <div className={'copyright'}>
              <p className={'mb-0'}>© 2010-{new Date().getFullYear()} Грильница</p>
            </div>
            {renderSocialBlock}
            <div className={'link-addition'}>
              {!isMinimalFooter && (
                <div className={'link-app'}>
                  <a className={''} href={'https://apps.apple.com/ru/app/grilnicaapp/id1536865948'}>
                    <img
                      alt={'Приложение Грильница для IOS'}
                      title={'Приложение Грильница для IOS'}
                      src={getStaticUrl('/img/app-store-download.svg')}
                    />
                  </a>
                  <a
                    className={''}
                    href={'https://play.google.com/store/apps/details?id=ru.grilnica.mobile'}>
                    <img
                      alt={'Приложение Грильница для Android'}
                      title={'Приложение Грильница для Android'}
                      src={getStaticUrl('/img/google-play-download.svg')}
                    />
                  </a>
                </div>
              )}
              <a
                className={'d-flex flex-row align-items-center'}
                href={'https://altaicoders.ru/'}
                title={'Сделано в AltaiCoders'}>
                <span className={'mr-x1'}>Сделано в</span>
                {logoAltaiCodersSVG}
              </a>
            </div>
          </Container>
        </div>
      </React.Fragment>
    )
  }, [getList, isMinimalFooter, renderSocialBlock])

  return <React.Fragment>{renderContent}</React.Fragment>
}

export default connectComponent(
  (state: State) => ({
    selectedCity: state.city.selectedCity,
    contactDetailsMap: state.contactDetails.contactDetailsMap,
  }),
  () => ({}),
  Footer,
)
