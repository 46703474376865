import {MENU_CATEGORY_ID_FOR_PREVIEW, MenuCategoryDTO} from 'grilnica-store-share'

/**
 * Получить специальную категорию меню для хранения продуктов
 * которых нет в меню но они должны быть в превью
 */
export function getMenuCategoryFromPreview(): MenuCategoryDTO {
  return {
    menuCategoryId: MENU_CATEGORY_ID_FOR_PREVIEW,
    parentMenuCategoryId: null,
    alias: 'process-card',
    name: 'Дополнительно',
    isHideInApp: true,
  }
}
