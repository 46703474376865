import React from 'react'
import FooterListItem from './FooterListItem'

export interface FooterListDTO {
  footerListId: string
  title: string
  renderElements: React.ReactElement[]
}

interface FooterListProps {
  list?: FooterListDTO[]
}

const FooterList: React.FC<FooterListProps> = ({list}): React.ReactElement => {
  let renderList: React.ReactElement[] = list.map((item) => {
    return (
      <FooterListItem
        key={item.footerListId}
        isCollapsedBlock={false}
        title={item.title}
        renderElements={item.renderElements}
      />
    )
  })
  let renderCollapsedBlockList: React.ReactElement[] = list.map((item) => {
    return (
      <FooterListItem
        isCollapsedBlock={true}
        key={item.footerListId}
        title={item.title}
        renderElements={item.renderElements}
      />
    )
  })
  return (
    <div className={'footer-site-nav'}>
      <div className={'collapsed-block-footer'}>{renderCollapsedBlockList}</div>
      <div className={'block-footer'}>{renderList}</div>
    </div>
  )
}

export default FooterList
