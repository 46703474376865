import React, {useMemo} from 'react'
import {Col} from 'grilnica-reactstrap'

interface BasketAlertBlockProps {
  isTerminal: boolean
}

const BasketAlertBlock: React.FC<BasketAlertBlockProps> = ({isTerminal}): React.ReactElement => {
  const renderContent: React.ReactElement = useMemo(() => {
    return (
      <Col md={{size: isTerminal ? 8 : 6, offset: isTerminal ? 2 : 3}} className={'mb-x8'}>
        <div className={'alert-basket text-center'}>
          <p className={'alert-name mb-x2'}>Обратите внимание!</p>
          <p className={'alert-description mb-0'}>Скидки по акциям не суммируются.</p>
        </div>
      </Col>
    )
  }, [isTerminal])
  return <React.Fragment>{renderContent}</React.Fragment>
}

export {BasketAlertBlock}
