import {CityDTO, RestaurantDTO} from 'grilnica-share'
import {select, call, put} from 'redux-saga/effects'
import {State} from '../ducks'
import {Actions as ErrorActions} from '../ducks/error'
import {Actions as RestaurantProduct} from '../ducks/restaurantProduct'
import {IsShowRestaurantByProductMap} from '../../types/restaurantProduct/IsShowRestaurantByProductMap'
import {RestaurantByProductMap} from 'grilnica-store-share'
import {getRestaurantByProductMapService} from '../../services/restaurantProduct'
import {Actions as InitializeActions} from '../ducks/initialize'
import {ErrorInitializeEnum} from '../../types/common/ErrorInitializeEnum'

function* getRestaurantByProduct() {
  try {
    yield call(loadRestaurantByProduct)
  } catch (error) {
    yield put(ErrorActions.setError(error))
    console.log('Error getRestaurantByProduct')
  }
}

function* backgroundUpdateRestaurantByProduct() {
  try {
    yield call(loadRestaurantByProduct)
  } catch (error) {
    console.log('Error background update restaurant by product', error)
  }
}

function* loadRestaurantByProduct() {
  const city: CityDTO = yield select((state: State) => state.city.selectedCity)
  if (city && city.cityId) {
    const restaurantByProductMap: RestaurantByProductMap = yield call(
      getRestaurantByProductMapService,
      city.cityId,
    )
    if (restaurantByProductMap) {
      yield put(RestaurantProduct.setRestaurantByProductMap(restaurantByProductMap))
      yield call(createIsShowRestaurantMap, restaurantByProductMap)
    }
  }
}

function* createIsShowRestaurantMap(restaurantByProductMap: RestaurantByProductMap) {
  const restaurants: RestaurantDTO[] = yield select((state: State) => state.restaurant.restaurants)
  if (restaurants) {
    let isShowRestaurantByProductMap: IsShowRestaurantByProductMap = {}
    for (let key in restaurantByProductMap) {
      isShowRestaurantByProductMap[key] = !!(
        restaurantByProductMap[key].length !== restaurants.length ||
        restaurantByProductMap[key].find(
          (item) =>
            (item.timeFrom && item.timeFrom !== '00:00:00') ||
            (item.timeTo && item.timeTo !== '24:00:00'),
        )
      )
    }
    yield put(RestaurantProduct.setIsShowRestaurantByProductMap(isShowRestaurantByProductMap))
  }
}

function* initializeRestaurantProduct() {
  let selectedCity: CityDTO = yield select((state: State) => state.city.selectedCity)
  if (selectedCity) {
    yield call(getRestaurantByProduct)
  } else {
    console.error('Not city initializeRestaurantProduct')
    yield put(InitializeActions.setErrorInitialize(ErrorInitializeEnum.ERROR_LOAD_SELECTED_CITY))
  }
}

export {getRestaurantByProduct, initializeRestaurantProduct, backgroundUpdateRestaurantByProduct}
