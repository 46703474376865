import React, {useMemo} from 'react'
import {OptionCategoryDTO} from 'grilnica-store-share'
import {IconSvgButton} from '../../../../../../../../../components/button/IconSvgButton'

interface IconOptionButtonsProps {
  optionCategory: OptionCategoryDTO
  onChangeOption: (isProductVariant: boolean, optionId: string) => void
  activeOptionCategoryId: string
}

const IconOptionButtons: React.FC<IconOptionButtonsProps> = ({
  optionCategory,
  onChangeOption,
  activeOptionCategoryId,
}): React.ReactElement => {
  const renderIconOptionButtons = useMemo(() => {
    if (optionCategory && optionCategory.isShowIcon && optionCategory.isProductVariantDefine) {
      return optionCategory.options.map((option) => (
        <IconSvgButton
          className={''}
          isActive={activeOptionCategoryId === option.optionId}
          iconFileName={option.iconFileName}
          onClick={() => {
            onChangeOption(option.isProductVariantDefine, option.optionId)
          }}
          key={option.optionId}
        />
      ))
    }
  }, [activeOptionCategoryId, onChangeOption, optionCategory])
  return <>{renderIconOptionButtons}</>
}

export {IconOptionButtons}
