import React, {useCallback} from 'react'
import {ProductDTO} from 'grilnica-store-share'
import {Link} from 'react-router-dom'
import {placeholderSVG} from '../../svg'
import {CardImage} from '../../../site/containers/restaurants/components/CardImage'

interface SearchPreviewItemProps {
  product: ProductDTO
  url: string
}

const SearchPreviewItem: React.FC<SearchPreviewItemProps> = ({
  product,
  url,
}): React.ReactElement => {
  const getPrice = useCallback(() => {
    if (product.price > 0) {
      return product.price
    }
    if (product.price === 0) {
      if (product.productVariants?.length > 0) {
        let minPrice: number = Infinity
        for (let productVariant of product.productVariants) {
          if (productVariant.price > 0) {
            if (productVariant.price < minPrice) {
              minPrice = productVariant.price
            }
          }
        }
        if (minPrice > 0 && minPrice !== Infinity) {
          return minPrice
        }
      }
    }
    return product.price ? product.price : 0
  }, [product.price, product.productVariants])

  return (
    <div className={'search-preview-item'}>
      <Link to={`${url}/${product.alias}`} className={'d-flex'}>
        <div>
          <CardImage
            imageFileName={product.imageFileName}
            alt={product.name}
            height={48}
            width={48}
            className={'mr-x2 preview-placeholder'}
            placeholder={<div className={'preview-item-img'}>{placeholderSVG}</div>}
          />
        </div>
        <div className={'d-flex flex-column'}>
          <p className={'name mb-x1'}>
            {product.name} / {product.menuCategoryName}
          </p>
          <p className={'description mb-x1'}>{product.description}</p>
          <p className={'price mb-0'}>{getPrice()} &#8381;</p>
        </div>
      </Link>
    </div>
  )
}

export {SearchPreviewItem}
