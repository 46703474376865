import React, {useMemo} from 'react'
import {Button, ButtonIcon, Col, InputVertical, Loading, Row} from 'grilnica-reactstrap'
import {PromoCard} from './PromoCard'
import {deleteSVG, pencilSVG, ticketSVG} from '../../../../components/svg/SystemIcons'
import {ActionDefinition} from '../../../../../store/action/types/ActionDefinition'

interface BasketPromoBlockProps {
  localCoupon: string
  onChangeLocalCoupon: (value: string) => void
  isOpenInputCoupon: boolean
  onOpenInputCoupon: () => void
  coupon: string
  onChangeCoupon: () => void
  onChangeSelectedDefinition: (id: string) => void
  definitions: ActionDefinition[]
  isUpdateCheckActions: boolean
  isSuccessPersonalCoupon: boolean
  selectedCouponDefinitionId: string
  removeCoupon: () => void
  isTerminal: boolean
}

const BasketPromoBlock: React.FC<BasketPromoBlockProps> = ({
  localCoupon,
  onChangeLocalCoupon,
  isOpenInputCoupon,
  onOpenInputCoupon,
  coupon,
  onChangeCoupon,
  onChangeSelectedDefinition,
  definitions,
  isUpdateCheckActions,
  selectedCouponDefinitionId,
  isSuccessPersonalCoupon,
  removeCoupon,
  isTerminal,
}): React.ReactElement => {
  const renderInputBlock: React.ReactElement = useMemo(() => {
    return (
      <div className={'enter-promo d-flex mb-x4 py-x2'}>
        <InputVertical
          name={'promoCode'}
          id={'promoCode'}
          type={'text'}
          value={localCoupon}
          isRequired={false}
          placeholder={'Введите промокод'}
          className={'w-50 mr-x4 mb-0'}
          isRounded
          onChange={(value: string) => {
            onChangeLocalCoupon(value)
          }}
        />
        <Button color={'primary'} isRoundedButton onClick={onChangeCoupon}>
          Добавить
        </Button>
      </div>
    )
  }, [localCoupon, onChangeCoupon, onChangeLocalCoupon])

  const renderButtonAddCouponBlock: React.ReactElement = useMemo(() => {
    return (
      <div className={'enter-promo d-flex py-x4 align-items-center justify-content-center mb-x4'}>
        <Button
          style={'text'}
          iconPosition={'left'}
          id={'button-enter-coupon'}
          size={'sm'}
          onClick={onOpenInputCoupon}
          color={'primary'}
          type={'button'}
          isRoundedButton
          iconPath={ticketSVG}>
          Ввести промокод
        </Button>
      </div>
    )
  }, [onOpenInputCoupon])

  const renderActiveCouponBlock: React.ReactElement = useMemo(() => {
    return (
      <div className={'enter-promo mb-x4 py-x2'}>
        <div className={'d-flex justify-content-between'}>
          <p className={'promo-code-caption mb-0'}>
            Промокод <span className={'promo-code'}>{coupon}</span>
          </p>
          <div className={'d-flex flex-row'}>
            <ButtonIcon
              iconPath={pencilSVG}
              isHoverArea
              title={'Редактировать'}
              className={'mr-secondary'}
              onClick={onOpenInputCoupon}
            />
            <ButtonIcon iconPath={deleteSVG} isHoverArea title={'Удалить'} onClick={removeCoupon} />
          </div>
        </div>

        {!!coupon && isSuccessPersonalCoupon !== undefined && !isSuccessPersonalCoupon && (
          <p className={'promo-code-invalid mb-0'}>Данный промокод недействителен</p>
        )}
      </div>
    )
  }, [coupon, onOpenInputCoupon, removeCoupon, isSuccessPersonalCoupon])

  const renderEnterPromoCodeBlock: React.ReactElement = useMemo(() => {
    if (isOpenInputCoupon) {
      return renderInputBlock
    } else if (coupon) {
      return renderActiveCouponBlock
    } else {
      return renderButtonAddCouponBlock
    }
  }, [
    coupon,
    isOpenInputCoupon,
    renderActiveCouponBlock,
    renderButtonAddCouponBlock,
    renderInputBlock,
  ])

  const renderActionsList: React.ReactElement = useMemo(() => {
    let count: number = 0
    for (let definition of definitions || []) {
      count += definition.isCoupon ? 1 : 0
    }

    if (count === 0) {
      if (isUpdateCheckActions) {
        return <Loading size={'m'} className={'mb-x8'} />
      }
      return null
    }

    const items: React.ReactElement[] = definitions.map((definition) => {
      return (
        definition.isCoupon && (
          <PromoCard
            key={definition.actionDefinitionId}
            isPersonalCoupon={definition.isPersonalCoupon}
            isActive={definition.actionDefinitionId === selectedCouponDefinitionId}
            fullSale={definition.fullSale}
            actionName={definition.actionName}
            promoCode={definition.isPersonalCoupon ? definition?.coupons[0] : undefined}
            onChangeSelectedDefinition={() => {
              onChangeSelectedDefinition(definition.actionDefinitionId)
            }}
          />
        )
      )
    })

    return (
      <React.Fragment>
        <p className={'caption-select-promo mb-x3'}>Выберите Акцию</p>
        <Row className={'promo-list'}>{items}</Row>
      </React.Fragment>
    )
  }, [definitions, isUpdateCheckActions, onChangeSelectedDefinition, selectedCouponDefinitionId])

  const renderContent: React.ReactElement = useMemo(() => {
    return (
      <Col md={{size: isTerminal ? 8 : 6, offset: isTerminal ? 2 : 3}}>
        {renderActionsList}
        {!isTerminal && renderEnterPromoCodeBlock}
      </Col>
    )
  }, [renderActionsList, renderEnterPromoCodeBlock, isTerminal])

  return <React.Fragment>{renderContent}</React.Fragment>
}

export {BasketPromoBlock}
