import React, {useMemo} from 'react'
import {SelectedOption} from '../../../../../../../../../../../../types/options/SelectedOption'

interface ComboProductCardDescriptionsProps {
  selectedOptions: SelectedOption[]
  productName: string
  weight: string
}

const ComboProductCardDescriptions: React.FC<ComboProductCardDescriptionsProps> = ({
  selectedOptions,
  productName,
  weight,
}): React.ReactElement => {
  const renderDescription = useMemo(() => {
    let infoItems: React.ReactNode[] = []
    let addOptions: React.ReactNode[] = []
    let deleteOptions: React.ReactNode[] = []
    let isAddWeight: boolean = false
    if (selectedOptions) {
      for (let selectedOption of selectedOptions) {
        if (selectedOption.isProductVariant) {
          const names: string[] = Object.keys(selectedOption.optionNames)
          infoItems.push(
            <p key={selectedOption.optionCategoryId + selectedOption.optionIds[0]}>
              {selectedOption.optionCategoryName + ': ' + names[0] + ' '}
            </p>,
          )
        } else {
          for (let optionName in selectedOption.optionNames) {
            if (selectedOption.optionNames[optionName]) {
              deleteOptions.push(
                <p className={'mb-0'} key={selectedOption.optionCategoryId + optionName}>
                  {'- ' + optionName}
                </p>,
              )
            } else {
              addOptions.push(
                <p className={'mb-0'} key={selectedOption.optionCategoryId + optionName}>
                  {'+ ' + optionName}
                </p>,
              )
            }
          }
        }
        if (!isAddWeight && weight) {
          infoItems.push(
            <p key={weight + 'weight'} className={'mb-x3'}>
              {weight}
            </p>,
          )
          isAddWeight = true
        }
      }
    }
    if (!isAddWeight && weight) {
      infoItems.push(
        <p key={weight + 'weight'} className={'mb-x3'}>
          {weight}
        </p>,
      )
    }

    return (
      <div className={'product-modifier mb-0'}>
        <div className={'basket-main-option mb-x3'}>{infoItems}</div>
        <div className={'add ' + (deleteOptions.length !== 0 ? 'mb-x1' : '')}>{addOptions}</div>
        <div className={'remove'}>{deleteOptions}</div>
      </div>
    )
  }, [selectedOptions, weight])

  const renderContent: React.ReactElement = useMemo(() => {
    return (
      <div className={'pl-x2 pr-primary d-flex flex-column mr-x3'}>
        <p className={'combo-product-name mb-x1'}>{productName}</p>
        {renderDescription}
      </div>
    )
  }, [productName, renderDescription])

  return <>{renderContent}</>
}

export {ComboProductCardDescriptions}
