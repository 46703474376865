import {put, delay, call} from 'redux-saga/effects'
import {Actions as ProductActions} from '../ducks/product'
import {ProductDTO, SelectedOption, SelectedProductDTO} from 'grilnica-store-share'
import PrepareProductEntity from '../../view/site/containers/menu/components/product/productList/productItem/product/PrepareProductEntity'

function* selectProduct(
  product: ProductDTO,
  optionCategoryId?: string,
  optionId?: string,
  selectedProduct?: SelectedProductDTO,
  moveToProductScreen?: () => Promise<void>,
) {
  const prepareProduct = new PrepareProductEntity(product)
  if (product.productVariants && product.productVariants.length !== 0) {
    prepareProduct.createProductVariantMap()
  }
  if (product.optionCategories) {
    for (let optionCategory of product.optionCategories) {
      prepareProduct.sortOptionCategoryByType(optionCategory)
      // условие при редактировании продукта из корзины
      if (selectedProduct && selectedProduct.selectedOptions.length !== 0) {
        let currentSelectedOption: SelectedOption = selectedProduct.selectedOptions.find(
          (selectedOption) => selectedOption.optionCategoryId === optionCategory.optionCategoryId,
        )
        if (currentSelectedOption) {
          for (let optionId of currentSelectedOption.optionIds) {
            prepareProduct.addSelectedOptionsInMaps(optionCategory, optionId)
          }
        } else {
          prepareProduct.addSelectedOptionsInMaps(optionCategory)
        }
      }
      //условие при открытии модалки нажатием на иконку размера или т.п
      else if (optionCategory.optionCategoryId === optionCategoryId) {
        prepareProduct.addSelectedOptionsInMaps(optionCategory, optionId)
      }
      //условие при стандартном открытии модалки или экрана товара
      else {
        prepareProduct.addSelectedOptionsInMaps(optionCategory)
      }
      prepareProduct.addOptionCategoryInMap(optionCategory)
      prepareProduct.createOptionMap(optionCategory)
    }
  }
  prepareProduct.addSelectedProductPriceAndProductVariant()
  let newSelectedProduct: SelectedProductDTO = prepareProduct.getSelectedProduct()
  if (selectedProduct) {
    newSelectedProduct.count = selectedProduct.count
    for (let selectedOption of selectedProduct.selectedOptions) {
      for (let optionId of selectedOption.optionIds) {
        prepareProduct.changeSelectedOption(selectedOption.optionCategoryId, optionId, true)
      }
    }
  }
  yield put(ProductActions.selectProduct(newSelectedProduct))
  if (moveToProductScreen) {
    yield call(moveToProductScreen)
    yield delay(350)
  }
  yield put(
    ProductActions.setSupportsMaps(
      {},
      prepareProduct.getOptionCategoryMap(),
      prepareProduct.getSelectedOptionsMap(),
      prepareProduct.getRestrictionsMap(),
      prepareProduct.getCountSelectedOptionsMap(),
      prepareProduct.getProductVariantMap(),
      prepareProduct.getOptionMap(),
      prepareProduct.getOptionProductMap(),
    ),
  )
}

export {selectProduct}
