import React from 'react'
import {OptionCategoryDTO} from 'grilnica-store-share'
import {IconOptionButtons} from './IconOptionButtons'

interface OptionCategoriesListProps {
  optionCategory: OptionCategoryDTO
  onChangeOption: (isProductVariant: boolean, optionId: string) => void
  activeOptionCategoryId: string
}

const OptionCategoriesList: React.FC<OptionCategoriesListProps> = ({
  optionCategory,
  onChangeOption,
  activeOptionCategoryId,
}): React.ReactElement => {
  return (
    <div className={'option-categories-list'}>
      <IconOptionButtons
        optionCategory={optionCategory}
        onChangeOption={onChangeOption}
        activeOptionCategoryId={activeOptionCategoryId}
      />
    </div>
  )
}

export {OptionCategoriesList}
