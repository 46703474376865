import React, {useMemo} from 'react'
import {ButtonIcon} from 'grilnica-reactstrap'
import {minusSVG, plusSVG} from '../../../../components/svg/SystemIcons'

interface ProductCountButtonsProps {
  addCountProduct: () => void
  removeCountProduct: () => void
  count: number
  maxCountInOrder: number
  isNotForSale: boolean
}

const ProductCountButtons: React.FC<ProductCountButtonsProps> = ({
  addCountProduct,
  removeCountProduct,
  count,
  maxCountInOrder,
  isNotForSale,
}): React.ReactElement => {
  const renderRemove: React.ReactElement = useMemo(() => {
    return (
      <ButtonIcon
        iconPath={minusSVG}
        isHoverArea
        title={'Убрать'}
        className={'mr-x4'}
        disabled={count === 0 || isNotForSale}
        onClick={removeCountProduct}
      />
    )
  }, [count, isNotForSale, removeCountProduct])

  const renderCount: React.ReactElement = useMemo(() => {
    return <span>{count}</span>
  }, [count])

  const renderAdd: React.ReactElement = useMemo(() => {
    return count < maxCountInOrder ? (
      <ButtonIcon
        className={'ml-x4'}
        iconPath={plusSVG}
        isHoverArea
        title={'Добавить'}
        disabled={count === 99 || isNotForSale}
        onClick={addCountProduct}
      />
    ) : (
      <div className={'empty-icon-basket ml-x4'} />
    )
  }, [addCountProduct, count, isNotForSale, maxCountInOrder])

  const renderContent: React.ReactElement = useMemo(() => {
    return (
      <div className={'d-flex align-items-center'}>
        {maxCountInOrder !== 1 && renderRemove}
        {maxCountInOrder !== 1 && renderCount}
        {maxCountInOrder !== 1 && renderAdd}
      </div>
    )
  }, [maxCountInOrder, renderAdd, renderCount, renderRemove])

  return <>{renderContent}</>
}

export {ProductCountButtons}
