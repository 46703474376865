import React, {useMemo} from 'react'
import {Button, Col, Modal, ModalBody, ModalFooter, Row} from 'grilnica-reactstrap'
import {CONSTANTS} from '../../../../../../../../../../../../constants'
import {PromoItems} from '../../../../../productList/productItem/components/card/PromoItems'
import {OptionCategoryDTO, RestrictionsDTO, SelectedProductDTO} from 'grilnica-store-share'
import {OptionType} from '../../../../../../../../../../../types/product/OptionType'
import {OptionCategoryItem} from '../../../product/OptionCategoryItem'
import {SupportsMaps} from '../../../../../../../../../../../types/product/SupportsMaps'
import {checkCircleOutlineSVG} from '../../../../../../../../../../components/svg/SystemIcons'
import {WeightBlock} from '../../../../../../../../../../components/product/WeightBlock' //TODO:: weight

interface ComboProductModalBodyProps {
  isOpenModal: boolean
  onCloseModal: () => void
  selectedProduct: SelectedProductDTO
  supportsMaps: SupportsMaps
  toggleOption: (
    optionCategoryId: string,
    optionId: string,
    isChecked?: boolean,
    isOptional?: boolean,
  ) => void
  saveComboProduct: () => void
  isComboProduct: boolean
  weight: string //TODO:: weight
}

const ComboProductModalBody: React.FC<ComboProductModalBodyProps> = ({
  isOpenModal,
  onCloseModal,
  selectedProduct,
  supportsMaps,
  toggleOption,
  saveComboProduct,
  isComboProduct,
  weight, //TODO:: weight
}): React.ReactElement => {
  const renderOptionsBlock = useMemo(() => {
    if (
      supportsMaps &&
      supportsMaps.optionCategoryTypeMap &&
      (supportsMaps.optionCategoryTypeMap[OptionType.WITH_ICON]?.length !== 0 ||
        supportsMaps.optionCategoryTypeMap[OptionType.MAIN_TAB]?.length !== 0 ||
        supportsMaps.optionCategoryTypeMap[OptionType.ADDITIONAL_TAB]?.length !== 0)
    ) {
      let optionCategories: OptionCategoryDTO[] = []
      supportsMaps.optionCategoryTypeMap[OptionType.WITH_ICON].forEach((categoryId: string) => {
        optionCategories.push(supportsMaps.optionCategoryMap[categoryId])
      })
      supportsMaps.optionCategoryTypeMap[OptionType.MAIN_TAB].forEach((categoryId: string) =>
        optionCategories.push(supportsMaps.optionCategoryMap[categoryId]),
      )
      supportsMaps.optionCategoryTypeMap[OptionType.ADDITIONAL_TAB].forEach(
        (categoryId: string) => {
          optionCategories.push(supportsMaps.optionCategoryMap[categoryId])
        },
      )

      const renderItems: React.ReactNode[] = optionCategories.map((item) => {
        const optionCategoryRestriction: RestrictionsDTO =
          supportsMaps.restrictionsMap[item.optionCategoryId]
        return (
          <OptionCategoryItem
            key={item.optionCategoryId}
            optionCategory={item}
            selectedOptionsMap={supportsMaps.selectedOptionsMap}
            isOptional={optionCategoryRestriction.min !== optionCategoryRestriction.max}
            restrictionsMap={supportsMaps.restrictionsMap}
            countSelectedOptionsMap={supportsMaps.countSelectedOptionsMap}
            toggleOption={toggleOption}
            isExtraOptionCategory={supportsMaps.extraOptionsMap[item.optionCategoryId]}
            isComboProduct={isComboProduct}
          />
        )
      })

      return <>{renderItems}</>
    }
  }, [isComboProduct, supportsMaps, toggleOption])

  return (
    <Modal
      isOpen={isOpenModal}
      onClose={onCloseModal}
      size={'lg'}
      className={'combo-modal px-0 pb-0 pt-x4'}>
      <ModalBody className={'combo-modal-body custom-scroll mb-x4'}>
        <Row className={'product-page'}>
          <Col md={4}>
            <div className={'image-container'}>
              <img
                src={CONSTANTS.cdnUrl + '/' + selectedProduct.product.imageFileName}
                width={'100%'}
                height={'auto'}
                alt={selectedProduct.product.name}
                title={selectedProduct.product.name}
              />
              {selectedProduct.product.labels && (
                <PromoItems labels={selectedProduct.product.labels} />
              )}
            </div>
          </Col>
          <Col md={6}>
            <p className={'btn-back-title ml-0 mb-x3'}>
              {selectedProduct.product.menuCategoryName}
            </p>
            <p className={'product-name'}>{selectedProduct.product.name}</p>
            <p className={'product-description'}>{selectedProduct.product.description}</p>
            <WeightBlock weight={weight} className={'text-gray-700 mb-x3'} />
            <div>{renderOptionsBlock}</div>
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter className={'combo-modal-footer'}>
        <Button
          color={'primary'}
          size={'sm'}
          isRoundedButton
          iconPath={checkCircleOutlineSVG}
          onClick={saveComboProduct}>
          Сохранить
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export {ComboProductModalBody}
