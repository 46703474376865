import React, {useMemo} from 'react'
import {CONSTANTS} from '../../../../../../../../../../../../constants'
import {ButtonIcon, Col, COLORS, Icon} from 'grilnica-reactstrap'
import {SelectedProductDTO, WeightMapDto} from 'grilnica-store-share'
import {ComboProductCardDescriptions} from './card/ComboProductCardDescriptions'
import {
  checkCircleOutlineSVG,
  pencilSVG,
} from '../../../../../../../../../../components/svg/SystemIcons'
import {getWeightWithOptions} from '../../../../../../../../../../../utils/menu/getWeight'

interface ComboProductCardProps {
  selectedProduct: SelectedProductDTO
  renderComboProductModal: React.ReactElement
  openEditProductModal: () => void
  changeSelectedComboProduct: () => void
  weightMap: WeightMapDto
}

const ComboProductCard: React.FC<ComboProductCardProps> = ({
  selectedProduct,
  renderComboProductModal,
  openEditProductModal,
  changeSelectedComboProduct,
  weightMap,
}): React.ReactElement => {
  const renderContent: React.ReactElement = useMemo(() => {
    let weight: string = null
    if (selectedProduct?.product) {
      weight = getWeightWithOptions(selectedProduct, weightMap, 'active')
    }
    return (
      <Col md={6} className={'mb-x4'}>
        <div
          className={'combo-item ' + (selectedProduct.isActive ? 'combo-item-active' : '')}
          onClick={changeSelectedComboProduct}>
          <div>
            <img
              className={'combo-item-img'}
              width={88}
              height={88}
              alt={selectedProduct.product.name}
              title={selectedProduct.product.name}
              src={CONSTANTS.cdnUrl + '/' + selectedProduct.product.imageFileName}
            />
          </div>
          <div className={'d-flex justify-content-between w-100 py-x2'}>
            <ComboProductCardDescriptions
              selectedOptions={selectedProduct.selectedOptions}
              productName={selectedProduct.product.name}
              weight={weight}
            />
          </div>
          {selectedProduct.isActive && (
            <Icon
              path={checkCircleOutlineSVG}
              color={COLORS.green}
              className={'combo-item-active-indicator'}
            />
          )}
          {selectedProduct.product.optionCategories && //TODO:: позже добавить кнопку просмотра информации о продукте комбо
            selectedProduct.product.optionCategories.length !== 0 && (
              <ButtonIcon
                iconPath={pencilSVG}
                isHoverArea
                title={'Редактировать'}
                className={'combo-item-edit-btn'}
                onClick={openEditProductModal}
              />
            )}
        </div>
        {renderComboProductModal}
      </Col>
    )
  }, [
    changeSelectedComboProduct,
    openEditProductModal,
    renderComboProductModal,
    selectedProduct.isActive,
    selectedProduct.product,
    selectedProduct.productVariantId,
    selectedProduct.selectedOptions,
    weightMap,
  ])
  return <>{renderContent}</>
}

export {ComboProductCard}
