import {call, put, select} from 'redux-saga/effects'
import {ContactDetailsActions} from '../duck'
import {Actions as ErrorActions} from '../../ducks/error'
import {LSKeys, LSMethods} from '../../../storage'
import {loadContactDetailsMapService} from '../../../services/contactDetails'
import {ContactDetailsMap} from 'grilnica-store-share'
import {State} from '../../ducks'
import {CityDTO, isEmptyObject} from 'grilnica-share'
import {ERROR_PHONE_NUMBER, PHONE_NUMBER} from '../constants'

function* initializeContactDetails() {
  console.log('*initializeContactDetails')
  try {
    yield call(loadContactDetails)
  } catch (error) {
    yield put(ErrorActions.setError(error))
  }
}

function getDefaultContactDetails(): ContactDetailsMap {
  return {
    default: {
      phoneNumber: PHONE_NUMBER,
      errorPhoneNumberDefault: ERROR_PHONE_NUMBER,
      footerPhoneNumberDefault: null,
      additionalFooterPhoneNumberDefault: null,
    },
  }
}

function* loadContactDetails() {
  try {
    let contactDetailsMap: ContactDetailsMap = yield call(
      LSMethods.getStorageData,
      LSKeys.CONTACT_DETAILS_MAP,
      getDefaultContactDetails(),
    )
    try {
      const contactDetailsMapServer = yield select(
        (state: State) => state.contactDetails.contactDetailsMap,
      )
      if (isEmptyObject(contactDetailsMapServer)) {
        const city: CityDTO = yield select((state: State) => state.city.selectedCity)
        const contactDetailsMapDB = yield call(loadContactDetailsMapService, city?.cityId)
        if (contactDetailsMapDB) {
          contactDetailsMap = contactDetailsMapDB
          yield call(LSMethods.setStorageData, LSKeys.CONTACT_DETAILS_MAP, contactDetailsMap)
        }
      } else {
        contactDetailsMap = contactDetailsMapServer
        yield call(LSMethods.setStorageData, LSKeys.CONTACT_DETAILS_MAP, contactDetailsMap)
      }
    } catch (e) {
      console.log('Error loadContactDetailsMapService', JSON.stringify(e))
    }
    yield put(ContactDetailsActions.setContactDetailsMap(contactDetailsMap))
  } catch (error) {
    yield put(ErrorActions.setError(error))
  }
}

export {initializeContactDetails}
