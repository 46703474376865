import React, {useMemo} from 'react'
import {reFormatPhone} from '../../../utils/UserUtils'
import {Alert, Button, ButtonLink, InputVertical, Loading} from 'grilnica-reactstrap'
import {ContactDetailsMap} from 'grilnica-store-share'
import {isEmpty} from 'grilnica-share'

interface PhoneConfirmBlockProps {
  currentOneTimeCode: string
  handleChangeProps: (key: string, value: string | boolean) => void
  sendPhoneNumber: () => void
  oneTimeCodeFailureMessage: string
  isSubmitCodeDisabled: boolean
  sendPhoneConfirmationToken: () => void
  oneTimeCodeTimer: number
  isSendingPhoneConfirmationTokenId: boolean
  phone: string
  phoneForCall: string | undefined
  onChangeConfirmAuthType: (type: 'main' | 'additional') => void
  codeSix: string | undefined
  confirmType: 'main' | 'additional'
  isShowInfoAlert: boolean
  contactDetailsMap: ContactDetailsMap
  isAdditionalAuth: boolean
}

const PhoneConfirmBlock: React.FC<PhoneConfirmBlockProps> = ({
  currentOneTimeCode,
  handleChangeProps,
  sendPhoneNumber,
  oneTimeCodeFailureMessage,
  isSubmitCodeDisabled,
  sendPhoneConfirmationToken,
  oneTimeCodeTimer,
  isSendingPhoneConfirmationTokenId,
  phone,
  phoneForCall,
  onChangeConfirmAuthType,
  codeSix,
  confirmType,
  isShowInfoAlert,
  contactDetailsMap,
  isAdditionalAuth,
}): React.ReactElement => {
  const renderDescription = useMemo(() => {
    if (['main', 'additional'].includes(confirmType)) {
      let renderPhone: string = reFormatPhone(phone)
      renderPhone = renderPhone.replace(/ /g, '\u00A0')
      return (
        <p className={'auth-caption mb-x6 pt-x4'}>
          {confirmType === 'main'
            ? `Мы озвучим вам код по телефону, ожидайте звонка на номер ${renderPhone}`
            : `Мы отправили код на ваш номер ${renderPhone}. Введите код из SMS.`}
        </p>
      )
    } else if (!isEmpty(phoneForCall)) {
      let renderPhone: string = reFormatPhone(phoneForCall)
      renderPhone = renderPhone.replace(/ /g, '\u00A0')
      return (
        <p className={'auth-caption mb-x6 pt-x4'}>
          Позвоните на&nbsp;номер
          <br /> <span className={'font-weight-medium'}>{renderPhone}</span> и введите код:
        </p>
      )
    }
  }, [phone, phoneForCall, confirmType])

  const renderInput = React.useMemo(() => {
    if (['main', 'additional'].includes(confirmType)) {
      return (
        <InputVertical
          id={'input-code'}
          name={'code'}
          type={'text'}
          label={'Введите полученный код:'}
          value={currentOneTimeCode}
          isRequired={true}
          isRounded
          invalid={!!oneTimeCodeFailureMessage}
          feedback={oneTimeCodeFailureMessage ? oneTimeCodeFailureMessage : undefined}
          onChange={(value: string) => {
            handleChangeProps('currentOneTimeCode', value)
          }}
        />
      )
    } else {
      const startCodeSix = (codeSix || '').substring(0, 3)
      const endCodeSix = (codeSix || '').substring(3)
      return (
        <div className={'d-flex flex-column align-items w-100 mb-x6'}>
          <p className={'auth-code-six mb-0'}>
            {startCodeSix}&nbsp;{endCodeSix}
          </p>
        </div>
      )
    }
  }, [confirmType, currentOneTimeCode, oneTimeCodeFailureMessage, handleChangeProps, codeSix])

  const renderRepeatOneTimeCode = useMemo(() => {
    if (confirmType === 'main') {
      return (
        <div>
          {isSendingPhoneConfirmationTokenId ? (
            <div className={'d-flex flex-column align-items-center w-100'}>
              <Loading size={'m'} className={'mb-x4'} />
            </div>
          ) : oneTimeCodeTimer ? (
            <p className={'request-code mb-x6'}>
              Запросить код еще раз через {oneTimeCodeTimer} секунд
            </p>
          ) : oneTimeCodeTimer < 0 || !oneTimeCodeTimer ? (
            <p className={'request-code mb-x6'}>Вы можете запросить код еще раз</p>
          ) : (
            <></>
          )}
        </div>
      )
    } else {
      return isSendingPhoneConfirmationTokenId ? (
        <div className={'d-flex flex-column align-items-center w-100'}>
          <Loading size={'m'} className={'mb-x4'} />
        </div>
      ) : (
        <></>
      )
    }
  }, [isSendingPhoneConfirmationTokenId, oneTimeCodeTimer, confirmType])

  const renderNextButton = useMemo(() => {
    if (['main', 'additional'].includes(confirmType)) {
      if (oneTimeCodeTimer) {
        return (
          <Button
            color={'primary'}
            isRoundedButton
            disabled={isSubmitCodeDisabled}
            className={'w-100'}
            onClick={() => {
              sendPhoneConfirmationToken()
            }}>
            Далее
          </Button>
        )
      } else if (oneTimeCodeTimer < 0 || !oneTimeCodeTimer) {
        return (
          <Button
            color={'primary'}
            isRoundedButton
            className={'w-100'}
            onClick={() => {
              sendPhoneNumber()
            }}>
            Запросить код
          </Button>
        )
      }
      return <></>
    } else {
      return (
        <div>
          <ButtonLink
            color={'primary'}
            isRoundedButton
            className={'w-100'}
            href={`tel:${phoneForCall}`}>
            Позвонить
          </ButtonLink>
        </div>
      )
    }
  }, [
    confirmType,
    oneTimeCodeTimer,
    isSubmitCodeDisabled,
    sendPhoneConfirmationToken,
    sendPhoneNumber,
    phoneForCall,
  ])

  const renderInfoAlert = useMemo(() => {
    if (confirmType === 'additional') {
      let errorPhoneNumber: string | undefined
      if (contactDetailsMap?.default) {
        errorPhoneNumber = contactDetailsMap.default.errorPhoneNumber
          ? contactDetailsMap.default.errorPhoneNumber
          : contactDetailsMap.default.errorPhoneNumberDefault

        if (errorPhoneNumber) {
          errorPhoneNumber = errorPhoneNumber.replace(/ /g, '\u00A0')
          errorPhoneNumber = errorPhoneNumber.replace(/-/g, '\u2011')
        }
      }
      return (
        <Alert
          color={'info'}
          message={`Если не удалось войти, повторите попытку позже или позвоните по номеру ${errorPhoneNumber}`}
          className={'mb-x6'}
        />
      )
    } else {
      return <></>
    }
  }, [confirmType, contactDetailsMap?.default])

  const renderAdditionalAuth = useMemo(() => {
    if (isAdditionalAuth) {
      return (
        <div className={'mt-x6'}>
          {confirmType === 'main' ? (
            <div className={'d-flex flex-column align-items-center'}>
              <p
                className={'auth-confirm-type color-link-text'}
                onClick={() => {
                  onChangeConfirmAuthType('additional')
                }}>
                Не поступил звонок?
              </p>
            </div>
          ) : (
            <div className={'d-flex flex-column align-items-center'}>
              <p
                className={'color-link-text auth-confirm-type'}
                onClick={() => {
                  onChangeConfirmAuthType('main')
                }}>
                Вернуться к другому способу входа
              </p>
            </div>
          )}
        </div>
      )
    } else {
      return <></>
    }
  }, [isAdditionalAuth, confirmType, onChangeConfirmAuthType])

  return (
    <div>
      {renderDescription}
      {renderInput}
      {renderRepeatOneTimeCode}
      {isShowInfoAlert && renderInfoAlert}
      {renderNextButton}
      {renderAdditionalAuth}
    </div>
  )
}

export {PhoneConfirmBlock}
