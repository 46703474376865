import React, {useMemo} from 'react'
import {ButtonLink, Col} from 'grilnica-reactstrap'
import {BasketProductListProps} from './BasketProductList'
import {SelectedProductDTO, WeightMapDto} from 'grilnica-store-share'
import {getStaticUrl} from '../../../../../utils/static'

interface BasketContentProps {
  addCountProduct: (index: number) => void
  onOpenModal: (index: number) => void
  removeCountProduct: (index: number) => void
  editSelectedProduct: (item: SelectedProductDTO, index: number) => void
  selectedProducts: SelectedProductDTO[]
  selectedCouponDefinitionId: string
  isUpdateCheckActions: boolean
  isTerminal: boolean
  weightMap: WeightMapDto
}

const BasketContent: React.FC<BasketContentProps> = ({
  addCountProduct,
  onOpenModal,
  removeCountProduct,
  selectedProducts,
  editSelectedProduct,
  selectedCouponDefinitionId,
  isUpdateCheckActions,
  isTerminal,
  weightMap,
}): React.ReactElement => {
  const renderEmptyBasketContent: React.ReactElement = useMemo(() => {
    return (
      <Col md={{size: isTerminal ? 8 : 6, offset: isTerminal ? 2 : 3}}>
        <div className={'d-flex flex-column align-items-center'}>
          <img
            src={getStaticUrl('/img/basket-img.svg')}
            alt={'Корзина пуста'}
            title={'Корзина пуста'}
            width={375}
            height={301}
            className={'mb-x6'}
          />
          <p className={'empty-basket text-center mb-x6'}>
            Корзина пуста! <br /> Добавьте вкусняшку из меню
          </p>
          <ButtonLink to={'/'} color={'primary'} isRoundedButton>
            Перейти в меню
          </ButtonLink>
        </div>
      </Col>
    )
  }, [isTerminal])

  const renderContent: React.ReactElement = useMemo(() => {
    if (selectedProducts.length > 0) {
      return (
        <Col md={{size: isTerminal ? 8 : 6, offset: isTerminal ? 2 : 3}}>
          <BasketProductListProps
            addCountProduct={addCountProduct}
            onOpenModal={onOpenModal}
            removeCountProduct={removeCountProduct}
            selectedProducts={selectedProducts}
            editSelectedProduct={editSelectedProduct}
            selectedCouponDefinitionId={selectedCouponDefinitionId}
            isUpdateCheckActions={isUpdateCheckActions}
            weightMap={weightMap}
          />
        </Col>
      )
    } else {
      return renderEmptyBasketContent
    }
  }, [
    selectedProducts,
    isTerminal,
    addCountProduct,
    onOpenModal,
    removeCountProduct,
    editSelectedProduct,
    selectedCouponDefinitionId,
    isUpdateCheckActions,
    renderEmptyBasketContent,
    weightMap,
  ])

  return <>{renderContent}</>
}

export {BasketContent}
