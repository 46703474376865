export enum PaymentModalStageEnum {
  WAITING_PAYMENT = 'WAITING_PAYMENT',
  SUCCESS_PAYMENT = 'SUCCESS_PAYMENT',
  ERROR_PAYMENT = 'ERROR_PAYMENT',
  ORDER_TAKES_LONG_TIME_TO_REGISTER = 'ORDER_TAKES_LONG_TIME_TO_REGISTER',
  RECEIPT_PRINTING = 'RECEIPT_PRINTING',
  ERROR_RECEIPT_PRINTING = 'ERROR_RECEIPT_PRINTING',
  ERROR_FISCALIZATION = 'ERROR_FISCALIZATION',
  ERROR_REGISTER_ORDER = 'ERROR_REGISTER_ORDER',
  PARTIAL_REFUND = 'PARTIAL_REFUND',
  SUCCESS_PARTIAL_REFUND = 'SUCCESS_PARTIAL_REFUND',
  SUCCESS_FULL_REFUND = 'SUCCESS_FULL_REFUND',
  ERROR_PARTIAL_REFUND = 'ERROR_PARTIAL_REFUND',
  ERROR_FULL_REFUND = 'ERROR_FULL_REFUND',
  ERROR_FULL_REFUND_ALTERNATIVE = 'ERROR_FULL_REFUND_ALTERNATIVE',
  ERROR_UNIVERSAL = 'ERROR_UNIVERSAL',
  THANK_YOU_FOR_ORDER_IN_MODAL = 'THANK_YOU_FOR_ORDER_IN_MODAL',

  ERROR = 'ERROR',
  SUCCESS = 'SUCCESS',
}
