import React, {useMemo} from 'react'
import {Col, COLORS, Icon} from 'grilnica-reactstrap'
import {checkCircleOutlineSVG} from '../../../../components/svg/SystemIcons'
import {getStaticUrl} from '../../../../../utils/static'

interface PromoCardProps {
  isPersonalCoupon: boolean
  isActive: boolean
  actionName?: string
  promoCode?: string
  fullSale: number
  onChangeSelectedDefinition: () => void
}

const PromoCard: React.FC<PromoCardProps> = ({
  isPersonalCoupon,
  isActive,
  actionName,
  promoCode,
  fullSale,
  onChangeSelectedDefinition,
}): React.ReactElement => {
  const renderContent: React.ReactElement = useMemo(() => {
    return (
      <Col xs={9} sm={5} md={6}>
        <div
          className={'promo-card ' + (isActive ? 'promo-card-active' : '')}
          onClick={onChangeSelectedDefinition}>
          <img
            alt={isPersonalCoupon ? 'Промокод' : 'Скидка'}
            src={getStaticUrl(
              isPersonalCoupon ? '/img/promo-basket-img.svg' : '/img/sale-basket-img.svg',
            )}
            title={isPersonalCoupon ? 'Промокод' : 'Скидка'}
            className={'mr-x1'}
          />
          <div className={'d-flex flex-column justify-content-between p-x2 pl-x1 mr-x1'}>
            <p className={'promo-name mb-x1'}>
              {isPersonalCoupon ? `Промокод: ${promoCode}` : `${actionName}`}
            </p>
            <p className={'sale-size mb-0'}>Скидка {fullSale} &#8381; </p>
          </div>
          {isActive && (
            <Icon
              path={checkCircleOutlineSVG}
              color={COLORS.green}
              className={'promo-active-indicator'}
            />
          )}
        </div>
      </Col>
    )
  }, [actionName, fullSale, isActive, isPersonalCoupon, onChangeSelectedDefinition, promoCode])

  return <React.Fragment>{renderContent}</React.Fragment>
}

export {PromoCard}
