import {
  ClientDTO,
  PhoneConfirmationTokenDTO,
  SendTokenGoalEnum,
  SendTokenMethodEnum,
} from 'grilnica-store-share'
import {BaseAction} from '../common/BaseAction'
import {bindActionCreators} from 'redux'

export interface AuthState {
  phoneConfirmationObject: PhoneConfirmationTokenDTO
  oneTimeCodeTimer: number
  isSendingPhoneConfirmationTokenId: boolean
  isCreatedOneTimeCodeListener: boolean
  oneTimeCodeFailureMessage: string
  phoneConfirmationFailureMessage: string
  client: ClientDTO
  newClient: ClientDTO
  redirectUrl: string
  isRequestCheckAuthBySix: boolean
  isSendingTerminalLoginByPhone: boolean
  terminalPhoneLoginFailureMessage: string | null
}

// eslint-disable-next-line no-shadow
export enum AuthActionsTypes {
  SET_PHONE_CONFIRMATION_OBJECT = 'auth/SET_PHONE_CONFIRMATION_OBJECT',
  SEND_PHONE_NUMBER_REQUEST = 'auth/SEND_PHONE_NUMBER_REQUEST',
  SET_ONE_TIME_CODE_TIMER = 'auth/SET_ONE_TIME_CODE_TIMER',
  SEND_PHONE_CONFIRMATION_TOKEN_ID_REQUEST = 'auth/SEND_PHONE_CONFIRMATION_TOKEN_ID_REQUEST',
  SEND_TERMINAL_PHONE_LOGIN_REQUEST = 'auth/SEND_TERMINAL_PHONE_LOGIN_REQUEST',
  SET_CLIENT = 'auth/SET_CLIENT',
  SET_ONE_TIME_CODE_FAILURE_MESSAGE = 'auth/SET_ONE_TIME_CODE_FAILURE_MESSAGE',
  SET_PHONE_CONFIRMATION_FAILURE_MESSAGE = 'auth/SET_PHONE_CONFIRMATION_FAILURE_MESSAGE',
  TOGGLE_SENDING_PHONE_CONFIRMATION_TOKEN_ID_SUCCESS = 'auth/TOGGLE_SENDING_PHONE_CONFIRMATION_TOKEN_ID_SUCCESS',
  TOGGLE_TERMINAL_PHONE_LOGIN_SENDING_REQUEST = 'auth/TOGGLE_TERMINAL_PHONE_LOGIN_SENDING_REQUEST',
  SET_TERMINAL_PHONE_LOGIN_FAILURE_MESSAGE = 'auth/SET_TERMINAL_PHONE_LOGIN_FAILURE_MESSAGE',
  TOGGLE_IS_CREATED_ONE_TIME_CODE_LISTENER = 'auth/TOGGLE_IS_CREATED_ONE_TIME_CODE_LISTENER',
  LOGOUT_REQUEST = 'auth/LOGOUT_REQUEST',
  LOGOUT_SUCCESS = 'auth/LOGOUT_SUCCESS',
  SET_NEW_CLIENT = 'auth/SET_NEW_CLIENT',
  SAVE_CLIENT_DATA_REQUEST = 'auth/SAVE_CLIENT_DATA_REQUEST',
  SET_REDIRECT_URL = 'auth/SET_REDIRECT_URL',
  CHECK_AUTH_BY_SIX_REQUEST = 'auth/CHECK_AUTH_BY_SIX_REQUEST',
  SET_REQUEST_AUTH_BY_SIX_CODE = 'auth/SET_REQUEST_AUTH_BY_SIX_CODE',
}

const INITIAL_STATE: AuthState = {
  phoneConfirmationObject: null,
  client: null,
  oneTimeCodeTimer: 0,
  isSendingPhoneConfirmationTokenId: false,
  isCreatedOneTimeCodeListener: false,
  oneTimeCodeFailureMessage: null,
  phoneConfirmationFailureMessage: undefined,
  newClient: null,
  redirectUrl: null,
  isRequestCheckAuthBySix: false,
  isSendingTerminalLoginByPhone: false,
  terminalPhoneLoginFailureMessage: null,
}

export const Actions = {
  setPhoneConfirmationObject: (phoneConfirmationObject: PhoneConfirmationTokenDTO): BaseAction => ({
    type: AuthActionsTypes.SET_PHONE_CONFIRMATION_OBJECT,
    payload: phoneConfirmationObject,
  }),

  sendPhoneNumberRequest: (
    phoneNumber: string,
    sendGoal: SendTokenGoalEnum,
    sendMethod: SendTokenMethodEnum | undefined,
  ): BaseAction => ({
    type: AuthActionsTypes.SEND_PHONE_NUMBER_REQUEST,
    payload: {phoneNumber, sendGoal, sendMethod},
  }),

  setPhoneConfirmationFailureMessage: (message: string): BaseAction => ({
    type: AuthActionsTypes.SET_PHONE_CONFIRMATION_FAILURE_MESSAGE,
    payload: message,
  }),

  setOneTimeCodeTimer: (oneTimeCodeTimer: number): BaseAction => ({
    type: AuthActionsTypes.SET_ONE_TIME_CODE_TIMER,
    payload: oneTimeCodeTimer,
  }),

  sendPhoneConfirmationTokenIdRequest: (
    oneTimeCode: string,
    moveToProfile: () => void,
  ): BaseAction => ({
    type: AuthActionsTypes.SEND_PHONE_CONFIRMATION_TOKEN_ID_REQUEST,
    payload: {oneTimeCode, moveToProfile},
  }),

  setClient: (client: ClientDTO): BaseAction => ({
    type: AuthActionsTypes.SET_CLIENT,
    payload: client,
  }),

  setOneTimeCodeFailureMessage: (message: string): BaseAction => ({
    type: AuthActionsTypes.SET_ONE_TIME_CODE_FAILURE_MESSAGE,
    payload: message,
  }),

  toggleSendingConfirmationTokenId: (isSending: boolean): BaseAction => ({
    type: AuthActionsTypes.TOGGLE_SENDING_PHONE_CONFIRMATION_TOKEN_ID_SUCCESS,
    payload: isSending,
  }),

  sendTerminalLoginByPhoneRequest: (phone: string, moveToProfile: () => void): BaseAction => ({
    type: AuthActionsTypes.SEND_TERMINAL_PHONE_LOGIN_REQUEST,
    payload: {phone, moveToProfile},
  }),

  toggleSendingTerminalLoginByPhone: (isSending: boolean): BaseAction => ({
    type: AuthActionsTypes.TOGGLE_TERMINAL_PHONE_LOGIN_SENDING_REQUEST,
    payload: isSending,
  }),

  setTerminalLoginByPhoneFailureMessage: (message: string): BaseAction => ({
    type: AuthActionsTypes.SET_TERMINAL_PHONE_LOGIN_FAILURE_MESSAGE,
    payload: message,
  }),

  toggleIsCreatedOneTimeCodeListener: (isCreated: boolean): BaseAction => ({
    type: AuthActionsTypes.TOGGLE_IS_CREATED_ONE_TIME_CODE_LISTENER,
    payload: isCreated,
  }),

  logOutRequest: (onRedirect?: () => void): BaseAction => ({
    type: AuthActionsTypes.LOGOUT_REQUEST,
    payload: {onRedirect},
  }),

  logOutSuccess: (): BaseAction => ({
    type: AuthActionsTypes.LOGOUT_SUCCESS,
  }),

  setNewClient: (newClient: ClientDTO): BaseAction => ({
    type: AuthActionsTypes.SET_NEW_CLIENT,
    payload: newClient,
  }),

  saveClientDataRequest: (newClient?: ClientDTO): BaseAction => ({
    type: AuthActionsTypes.SAVE_CLIENT_DATA_REQUEST,
    payload: newClient,
  }),

  setRedirectUrl: (url: string): BaseAction => ({
    type: AuthActionsTypes.SET_REDIRECT_URL,
    payload: url,
  }),

  checkAuthBySixCodeRequest: (moveToProfile: () => void): BaseAction => ({
    type: AuthActionsTypes.CHECK_AUTH_BY_SIX_REQUEST,
    payload: {moveToProfile},
  }),

  setRequestAuthBySixCode: (isRequest: boolean): BaseAction => ({
    type: AuthActionsTypes.SET_REQUEST_AUTH_BY_SIX_CODE,
    payload: isRequest,
  }),
}

export type AuthActionsT = typeof Actions

export function bindAuthActions(dispatch: any) {
  return bindActionCreators(Actions, dispatch)
}

export default function auth(state: AuthState = INITIAL_STATE, action: BaseAction): AuthState {
  switch (action.type) {
    case AuthActionsTypes.SET_PHONE_CONFIRMATION_OBJECT: {
      return {
        ...state,
        phoneConfirmationObject: action.payload,
      }
    }

    case AuthActionsTypes.SET_ONE_TIME_CODE_TIMER: {
      return {
        ...state,
        oneTimeCodeTimer: action.payload,
      }
    }

    case AuthActionsTypes.SET_CLIENT: {
      return {
        ...state,
        client: action.payload,
        newClient: action.payload,
        isSendingPhoneConfirmationTokenId: false,
      }
    }

    case AuthActionsTypes.TOGGLE_SENDING_PHONE_CONFIRMATION_TOKEN_ID_SUCCESS: {
      return {
        ...state,
        isSendingPhoneConfirmationTokenId: action.payload,
      }
    }

    case AuthActionsTypes.CHECK_AUTH_BY_SIX_REQUEST: {
      return {
        ...state,
        isRequestCheckAuthBySix: true,
      }
    }

    case AuthActionsTypes.SET_REQUEST_AUTH_BY_SIX_CODE: {
      return {
        ...state,
        isRequestCheckAuthBySix: action.payload,
      }
    }

    case AuthActionsTypes.TOGGLE_IS_CREATED_ONE_TIME_CODE_LISTENER: {
      return {
        ...state,
        isCreatedOneTimeCodeListener: action.payload,
      }
    }

    case AuthActionsTypes.SET_ONE_TIME_CODE_FAILURE_MESSAGE: {
      return {
        ...state,
        oneTimeCodeFailureMessage: action.payload,
      }
    }

    case AuthActionsTypes.SET_PHONE_CONFIRMATION_FAILURE_MESSAGE: {
      return {
        ...state,
        phoneConfirmationFailureMessage: action.payload,
      }
    }

    case AuthActionsTypes.TOGGLE_TERMINAL_PHONE_LOGIN_SENDING_REQUEST: {
      return {
        ...state,
        isSendingTerminalLoginByPhone: action.payload,
      }
    }

    case AuthActionsTypes.SET_TERMINAL_PHONE_LOGIN_FAILURE_MESSAGE: {
      return {
        ...state,
        terminalPhoneLoginFailureMessage: action.payload,
      }
    }

    case AuthActionsTypes.LOGOUT_SUCCESS: {
      return {
        ...INITIAL_STATE,
      }
    }

    case AuthActionsTypes.SET_NEW_CLIENT: {
      return {
        ...state,
        newClient: action.payload,
      }
    }

    case AuthActionsTypes.SET_REDIRECT_URL: {
      return {
        ...state,
        redirectUrl: action.payload,
      }
    }

    default: {
      return {
        ...state,
      }
    }
  }
}
