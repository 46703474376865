import React, {useMemo} from 'react'

interface WeightBlockProps {
  weight: string
  className?: string
}

const WeightBlock: React.FC<WeightBlockProps> = ({weight, className}): React.ReactElement => {
  const renderContent: React.ReactElement = useMemo(() => {
    let content: React.ReactElement = null
    if (weight) {
      content = <p className={'caption ' + (className ? className : '')}>{weight}</p>
    } else {
      content = <p className={'mb-0'} />
    }
    return content
  }, [className, weight])

  return <>{renderContent}</>
}

export {WeightBlock}
