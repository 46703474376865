import React, {useCallback, useContext, useEffect, useMemo, useState} from 'react'
import {connectComponent} from '../../../../store/common'
import {AuthActionsT, bindAuthActions} from '../../../../store/ducks/auth'
import {
  ContactDetailsMap,
  PhoneConfirmationTokenDTO,
  SendTokenGoalEnum,
  SendTokenMethodEnum,
} from 'grilnica-store-share'
import {onChangePhone} from '../../utils/UserUtils'
import {getFullUrl} from '../../../../utils/url'
import {UrlPrefixContext} from '../../components/App'
import {bindOrderActions} from '../../../../store/ducks/order'
import {SingUpComponent} from './components/SingUpComponent'
import {trimPhoneNumber} from '../../../../utils/common/trimPhoneNumber'
import {Redirect} from 'react-router-dom'
import {ymTarget} from '../../../../utils/metrica/ym/ymTarget'
import {gaTarget} from '../../../../utils/metrica/ga/gaTarget'
import {State} from '../../../../store/ducks'
import {CityDTO, isEmpty} from 'grilnica-share'

export type AuthType = 'account' | 'fast'

interface SingUpPageProps {
  authActions: AuthActionsT
  orderActions: any
  phoneConfirmationObject: PhoneConfirmationTokenDTO
  oneTimeCodeTimer: number
  isSendingPhoneConfirmationTokenId: boolean
  isRequestSaveOrder: boolean
  redirectUrl: string
  selectedCity: CityDTO

  isRequestCheckAuthBySix: boolean
  oneTimeCodeFailureMessage: string
  contactDetailsMap: ContactDetailsMap
}

const SingUpPage: React.FC<SingUpPageProps> = ({
  authActions,
  orderActions,
  phoneConfirmationObject,
  oneTimeCodeTimer,
  isSendingPhoneConfirmationTokenId,
  isRequestSaveOrder,
  redirectUrl,
  selectedCity,
  isRequestCheckAuthBySix,
  oneTimeCodeFailureMessage,
  contactDetailsMap,
}): React.ReactElement => {
  const [phoneNumber, setPhoneNumber] = useState<string>('+7')
  const [phoneNumberForSend, setPhoneNumberForSend] = useState<string>('+7')
  const [currentOneTimeCode, setCurrentOneTimeCode] = useState<string>(null)
  const [agreementSms, setAgreementSms] = useState<boolean>(true)
  const [agreementSmsForSend, setAgreementSmsForSend] = useState<boolean>(true)
  const [authType, setAuthType] = useState<'account' | 'fast'>(
    redirectUrl && selectedCity?.isFastBuyPage ? 'fast' : 'account',
  )
  const [redirect, setRedirect] = useState<string>(null)

  const [confirmType, setConfirmType] = useState<'main' | 'additional'>('main')
  const [currentTime, setCurrentTime] = useState<number>(new Date().getTime())
  const [isShowInfoAlert, setIsShowInfoAlert] = useState<boolean>(false)

  const isAdditionalAuth = !!phoneConfirmationObject?.phoneForCall

  const urlPrefix: string = useContext(UrlPrefixContext)

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    if (
      !!phoneConfirmationObject &&
      isAdditionalAuth &&
      confirmType === 'additional' &&
      !isRequestCheckAuthBySix
    ) {
      authActions.checkAuthBySixCodeRequest(() => {
        if (redirectUrl) {
          const newUrl = getFullUrl(redirectUrl, urlPrefix)
          authActions.setRedirectUrl(null)
          setRedirect(newUrl)
        } else {
          setRedirect(getFullUrl('/profile', urlPrefix))
        }
      })
    }
  }, [
    authActions,
    confirmType,
    isAdditionalAuth,
    isRequestCheckAuthBySix,
    phoneConfirmationObject,
    redirectUrl,
    urlPrefix,
  ])

  useEffect(() => {
    if (!!phoneConfirmationObject && isAdditionalAuth) {
      if (confirmType === 'additional') {
        const intervalId = setInterval(() => {
          const time = new Date().getTime() - 1.5 * 60 * 1000
          if (currentTime < time) {
            setIsShowInfoAlert(true)
          }
        }, 2500)
        return () => {
          clearInterval(intervalId)
        }
      }
    }
  }, [confirmType, currentTime, isAdditionalAuth, phoneConfirmationObject])

  const sendPhoneNumber = useCallback(
    (sendMethod?: SendTokenMethodEnum) => {
      authActions.sendPhoneNumberRequest(
        trimPhoneNumber(phoneNumber),
        SendTokenGoalEnum.REGISTER_IN_APP,
        sendMethod,
      )
    },
    [authActions, phoneNumber],
  )

  const sendOrder = useCallback(() => {
    orderActions.saveOrderRequest({
      setIsRedirect: () => {
        ymTarget('OPLATA')
        gaTarget('click-made-order', 'order')
        setRedirect('/quick-order-success')
        authActions.setRedirectUrl(null)
      },
      phoneNumber: trimPhoneNumber(phoneNumberForSend),
    })
  }, [authActions, orderActions, phoneNumberForSend])

  const sendPhoneConfirmationToken = useCallback(() => {
    authActions.sendPhoneConfirmationTokenIdRequest(currentOneTimeCode, () => {
      if (redirectUrl) {
        const newUrl = getFullUrl(redirectUrl, urlPrefix)
        authActions.setRedirectUrl(null)
        setRedirect(newUrl)
      } else {
        setRedirect(getFullUrl('/profile', urlPrefix))
      }
    })
  }, [authActions, currentOneTimeCode, redirectUrl, urlPrefix])

  const onChangeAuthType = useCallback((signInType: 'account' | 'fast') => {
    setAuthType(signInType)
  }, [])

  const clearFailureMessage = useCallback(() => {
    authActions.setOneTimeCodeFailureMessage(undefined)
  }, [authActions])

  const isSubmitCodeDisabled = useCallback(() => {
    return (
      isEmpty(currentOneTimeCode) ||
      currentOneTimeCode?.length < 4 ||
      isSendingPhoneConfirmationTokenId
    )
  }, [currentOneTimeCode, isSendingPhoneConfirmationTokenId])

  const handleChangeProps = useCallback(
    (key: string, value: string | boolean) => {
      if (key === 'phoneNumber') {
        setPhoneNumber(onChangePhone(phoneNumber, value as string))
      } else if (key === 'agreementSms') {
        setAgreementSms(value as boolean)
      } else if (key === 'currentOneTimeCode') {
        if ((value as string).length <= 4) {
          setCurrentOneTimeCode(value as string)
        }
        clearFailureMessage()
      }
    },
    [clearFailureMessage, phoneNumber],
  )

  const onChangeConfirmAuthType = useCallback(
    (type: 'main' | 'additional') => {
      setConfirmType(type)
      setIsShowInfoAlert(false)
      setCurrentTime(new Date().getTime())
      authActions.setOneTimeCodeFailureMessage(undefined)
      sendPhoneNumber(type === 'main' ? SendTokenMethodEnum.CALL : SendTokenMethodEnum.SMS)
    },
    [authActions, sendPhoneNumber],
  )

  const handleChangeForSendProps = useCallback(
    (key: string, value: string | boolean) => {
      if (key === 'phoneNumberForSend') {
        setPhoneNumberForSend(onChangePhone(phoneNumberForSend, value as string))
      } else if (key === 'agreementSmsForSend') {
        setAgreementSmsForSend(value as boolean)
      }
    },
    [phoneNumberForSend],
  )

  const renderContent: React.ReactElement = useMemo(() => {
    return (
      <React.Fragment>
        {redirect && <Redirect to={redirect} />}
        <SingUpComponent
          authType={authType}
          onChangeAuthType={onChangeAuthType}
          phoneNumber={phoneNumber}
          phoneNumberForSend={phoneNumberForSend}
          currentOneTimeCode={currentOneTimeCode}
          handleChangeProps={handleChangeProps}
          handleChangeForSendProps={handleChangeForSendProps}
          agreementSms={agreementSms}
          agreementSmsForSend={agreementSmsForSend}
          sendPhoneNumber={sendPhoneNumber}
          sendOrder={sendOrder}
          isSubmitCodeDisabled={isSubmitCodeDisabled()}
          sendPhoneConfirmationToken={sendPhoneConfirmationToken}
          oneTimeCodeTimer={oneTimeCodeTimer}
          phoneConfirmationObject={phoneConfirmationObject}
          isRequestSaveOrder={isRequestSaveOrder}
          isShowFastOrder={!phoneConfirmationObject && !!redirectUrl && selectedCity?.isFastBuyPage}
          oneTimeCodeFailureMessage={oneTimeCodeFailureMessage}
          isSendingPhoneConfirmationTokenId={isSendingPhoneConfirmationTokenId}
          phone={phoneConfirmationObject?.phone}
          phoneForCall={phoneConfirmationObject ? phoneConfirmationObject?.phoneForCall : undefined}
          codeSix={phoneConfirmationObject?.codeSix}
          onChangeConfirmAuthType={onChangeConfirmAuthType}
          confirmType={confirmType}
          isShowInfoAlert={isShowInfoAlert}
          contactDetailsMap={contactDetailsMap}
          isAdditionalAuth={isAdditionalAuth}
        />
      </React.Fragment>
    )
  }, [
    redirect,
    authType,
    onChangeAuthType,
    phoneNumber,
    phoneNumberForSend,
    currentOneTimeCode,
    handleChangeProps,
    handleChangeForSendProps,
    agreementSms,
    agreementSmsForSend,
    sendPhoneNumber,
    sendOrder,
    isSubmitCodeDisabled,
    sendPhoneConfirmationToken,
    oneTimeCodeTimer,
    phoneConfirmationObject,
    isRequestSaveOrder,
    redirectUrl,
    selectedCity?.isFastBuyPage,
    oneTimeCodeFailureMessage,
    isSendingPhoneConfirmationTokenId,
    onChangeConfirmAuthType,
    confirmType,
    isShowInfoAlert,
    contactDetailsMap,
    isAdditionalAuth,
  ])

  return <React.Fragment>{renderContent}</React.Fragment>
}

export default connectComponent(
  (state: State) => ({
    phoneConfirmationObject: state.auth.phoneConfirmationObject,
    isSendingPhoneConfirmationTokenId: state.auth.isSendingPhoneConfirmationTokenId,
    oneTimeCodeTimer: state.auth.oneTimeCodeTimer,
    redirectUrl: state.auth.redirectUrl,
    isRequestSaveOrder: state.order.isRequestSaveOrder,
    selectedCity: state.city.selectedCity,

    oneTimeCodeFailureMessage: state.auth.oneTimeCodeFailureMessage,
    contactDetailsMap: state.contactDetails?.contactDetailsMap,
    isRequestCheckAuthBySix: state.auth.isRequestCheckAuthBySix,
  }),
  (dispatch) => ({
    authActions: bindAuthActions(dispatch),
    orderActions: bindOrderActions(dispatch),
  }),
  SingUpPage,
)
