import {SelectedOptionsMap} from '../../../../../types/product/SelectedOptionsMap'
import {OptionCategoryMap} from '../../../../../types/product/OptionCategoryMap'
import {OptionMap} from '../../../../../types/product/OptionMap'
import {ProductDTO} from 'grilnica-store-share/lib/product/ProductDTO'
import {ProductVariantMap} from '../../../../../types/product/ProductVariantMap'
import {getProductVariantMap} from './getProductVariantMap'
import {getProductVariantOptions} from './getProductVariantOptions'
import {getSumPriceOptions} from './getSumPriceOptions'
import {getKeyByOptionIds} from '../../../../../view/site/containers/menu/components/product/productList/productItem/utils/getKeyByOptionIds'

function getSelectedProductPrice(
  selectedOptionsMap: SelectedOptionsMap,
  optionCategoryMap: OptionCategoryMap,
  optionMap: OptionMap,
  product: ProductDTO,
): number {
  let selectedProductPrice: number
  // let selectedProductVariantId: string

  let productVariantOptions: string[] = getProductVariantOptions(
    selectedOptionsMap,
    optionCategoryMap,
  )

  let productVariantMap: ProductVariantMap = getProductVariantMap(product.productVariants)
  if (Object.keys(productVariantMap).length !== 0) {
    let productVariantPrice: number =
      productVariantMap[getKeyByOptionIds(productVariantOptions)].price
    const productVariantId: string =
      productVariantMap[getKeyByOptionIds(productVariantOptions)].productVariantId
    selectedProductPrice = productVariantPrice
    // selectedProductVariantId = productVariantId //TODO:: чтобы счиатть для вариантов
  } else {
    selectedProductPrice = product.price
  }

  let sumPriceOptions: number = getSumPriceOptions(selectedOptionsMap, optionCategoryMap, optionMap)

  return selectedProductPrice + sumPriceOptions
}

export {getSelectedProductPrice}
