import React, {useMemo} from 'react'
import {Col, Divider, InputVertical, Row} from 'grilnica-reactstrap'
import {DeliveryTypeEnum} from 'grilnica-store-share'

interface OrderCommentProps {
  deliveryType: DeliveryTypeEnum
  commentary: string
  countPersons?: string
  setPrepareOrderField: (key: string, value: any, parentKey?: string) => void
}

const OrderComment: React.FC<OrderCommentProps> = ({
  commentary,
  setPrepareOrderField,
}): React.ReactElement => {
  const renderInputComment: React.ReactElement = useMemo(() => {
    return (
      <Col sm={12}>
        <InputVertical
          id={'input-comment'}
          name={'comment'}
          type={'text'}
          value={commentary}
          placeholder={'Например: сдача с 5000 рублей'}
          isRequired={false}
          isRounded
          size={'lg'}
          onChange={(value: any) => {
            setPrepareOrderField('commentary', value)
          }}
        />
      </Col>
    )
  }, [commentary, setPrepareOrderField])

  const renderOrderComment = useMemo(() => {
    return (
      <>
        <p className={'name-blocks'}>3. Комментарий курьеру</p>
        <Row>{renderInputComment}</Row>
        <Divider className={'mb-x6'} />
      </>
    )
  }, [renderInputComment])

  return <>{renderOrderComment}</>
}

export {OrderComment}
