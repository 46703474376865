import React, {useMemo} from 'react'
import {SelectedProductDTO} from 'grilnica-store-share/src/order/SelectedProductDTO'
import {ComboProductItem} from './ComboProductItem'
import {WeightMapDto} from 'grilnica-store-share'

interface ComboProductListProps {
  selectedProductsCombo: SelectedProductDTO[]
  comboGroupId: string
  weightMap: WeightMapDto
  toggleComboProduct: (
    comboGroupId: string,
    comboProductId: string,
    selectedProduct: SelectedProductDTO,
  ) => void
}

const ComboProductList: React.FC<ComboProductListProps> = ({
  selectedProductsCombo,
  comboGroupId,
  toggleComboProduct,
  weightMap,
}): React.ReactElement => {
  const renderContent: React.ReactElement = useMemo(() => {
    const renderItems: React.ReactElement[] = selectedProductsCombo.map(
      (selectedProduct, index) => {
        return (
          <ComboProductItem
            key={selectedProduct.product.productId + index}
            selectedProduct={selectedProduct}
            comboGroupId={comboGroupId}
            comboProductId={selectedProduct.product.productId}
            weightMap={weightMap}
            toggleComboProduct={toggleComboProduct}
          />
        )
      },
    )
    return <>{renderItems}</>
  }, [comboGroupId, selectedProductsCombo, toggleComboProduct, weightMap])
  return <>{renderContent}</>
}

export {ComboProductList}
