import {BaseAction} from '../common/BaseAction'
import {bindActionCreators} from 'redux'
import {MenuCategoryDTO, ProductMap, ProductDTO, WeightMapDto} from 'grilnica-store-share'
import {CatalogItemsTypeEnum} from '../../types/menu/CatalogItemsTypeEnum'

export interface MenuState {
  categoryList: MenuCategoryDTO[]
  productMap: ProductMap
  weightMap: WeightMapDto
  isOpenFastBuyModal?: boolean
  selectedCatalogItemsType?: CatalogItemsTypeEnum
  isOpenProductScreen?: boolean
  isChangeCatalogItems?: boolean
}

export enum MenuActionsTypes {
  LOAD_CATEGORY_LIST_REQUEST = 'menu/LOAD_CATEGORY_LIST_REQUEST',
  LOAD_CATEGORY_LIST_SUCCESS = 'menu/LOAD_CATEGORY_LIST_SUCCESS',
  LOAD_PRODUCT_MAP_REQUEST = 'menu/LOAD_PRODUCT_MAP_REQUEST',
  LOAD_PRODUCT_MAP_SUCCESS = 'menu/LOAD_PRODUCT_MAP_SUCCESS',
  OPEN_FAST_BUY_MODAL_REQUEST = 'menu/OPEN_FAST_BUY_MODAL_REQUEST',
  CLOSE_FAST_BUY_MODAL_REQUEST = 'menu/CLOSE_FAST_BUY_MODAL_REQUEST',
  TOGGLE_FAST_BUY_MODAL_SUCCESS = 'menu/TOGGLE_FAST_BUY_MODAL_SUCCESS',
  TOGGLE_CATALOG_ITEMS_TYPE_REQUEST = 'menu/TOGGLE_CATALOG_ITEMS_TYPE_REQUEST',
  TOGGLE_CATALOG_ITEMS_TYPE_SUCCESS = 'menu/TOGGLE_CATALOG_ITEMS_TYPE_SUCCESS',
  OPEN_PRODUCT_SCREEN_REQUEST = 'menu/OPEN_PRODUCT_SCREEN_REQUEST',
  OPEN_PRODUCT_SCREEN_SUCCESS = 'menu/OPEN_PRODUCT_SCREEN_SUCCESS',
  CLOSE_PRODUCT_SCREEN = 'menu/CLOSE_PRODUCT_SCREEN',
  SET_IS_CHANGE_CATALOG_ITEMS = 'menu/SET_IS_CHANGE_CATALOG_ITEMS',
  LOAD_WEIGHT_MAP_SUCCESS = 'menu/LOAD_WEIGHT_MAP_SUCCESS',
}

const INITIAL_STATE: MenuState = {
  categoryList: null,
  productMap: null,
  weightMap: null,
  isOpenFastBuyModal: false,
  selectedCatalogItemsType: null,
  isOpenProductScreen: false,
  isChangeCatalogItems: false,
}

export const Actions = {
  loadCategoryListRequest: (): BaseAction => ({
    type: MenuActionsTypes.LOAD_CATEGORY_LIST_REQUEST,
  }),

  loadCategoryListSuccess: (categoryList: MenuCategoryDTO[]): BaseAction => ({
    type: MenuActionsTypes.LOAD_CATEGORY_LIST_SUCCESS,
    payload: categoryList,
  }),

  loadProductMapRequest: (): BaseAction => ({
    type: MenuActionsTypes.LOAD_PRODUCT_MAP_REQUEST,
  }),

  loadProductMapSuccess: (productMap: ProductMap): BaseAction => ({
    type: MenuActionsTypes.LOAD_PRODUCT_MAP_SUCCESS,
    payload: productMap,
  }),

  loadWeightMapSuccess: (weightMap: WeightMapDto): BaseAction => ({
    type: MenuActionsTypes.LOAD_WEIGHT_MAP_SUCCESS,
    payload: weightMap,
  }),

  openFastBuyModalRequest: (
    product: ProductDTO,
    optionCategoryId: string,
    optionId: string,
  ): BaseAction => ({
    type: MenuActionsTypes.OPEN_FAST_BUY_MODAL_REQUEST,
    payload: {product, optionCategoryId, optionId},
  }),

  closeFastBuyModalRequest: (setDefaultIndex: () => void): BaseAction => ({
    type: MenuActionsTypes.CLOSE_FAST_BUY_MODAL_REQUEST,
    payload: setDefaultIndex,
  }),

  toggleFastBuyModal: (isOpen: boolean): BaseAction => ({
    type: MenuActionsTypes.TOGGLE_FAST_BUY_MODAL_SUCCESS,
    payload: isOpen,
  }),

  toggleCatalogItemsTypeRequest: (catalogItemsType: CatalogItemsTypeEnum): BaseAction => ({
    type: MenuActionsTypes.TOGGLE_CATALOG_ITEMS_TYPE_REQUEST,
    payload: catalogItemsType,
  }),

  toggleCatalogItemsTypeSuccess: (catalogItemsType: CatalogItemsTypeEnum): BaseAction => ({
    type: MenuActionsTypes.TOGGLE_CATALOG_ITEMS_TYPE_SUCCESS,
    payload: catalogItemsType,
  }),

  openProductScreenRequest: (
    product: ProductDTO,
    moveToProductScreen: () => Promise<void>,
  ): BaseAction => ({
    type: MenuActionsTypes.OPEN_PRODUCT_SCREEN_REQUEST,
    payload: {product, moveToProductScreen},
  }),

  openProductScreenSuccess: (): BaseAction => ({
    type: MenuActionsTypes.OPEN_PRODUCT_SCREEN_SUCCESS,
  }),

  closeProductScreen: (): BaseAction => ({
    type: MenuActionsTypes.CLOSE_PRODUCT_SCREEN,
  }),

  setIsChangeCatalogItems: (isChange: boolean): BaseAction => ({
    type: MenuActionsTypes.SET_IS_CHANGE_CATALOG_ITEMS,
    payload: isChange,
  }),
}

export function bindMenuActions(dispatch: any) {
  return bindActionCreators(Actions, dispatch)
}

export default function menu(state: MenuState = INITIAL_STATE, action: BaseAction): MenuState {
  switch (action.type) {
    case MenuActionsTypes.LOAD_CATEGORY_LIST_SUCCESS:
      return {
        ...state,
        categoryList: action.payload,
      }

    case MenuActionsTypes.LOAD_PRODUCT_MAP_SUCCESS:
      return {
        ...state,
        productMap: action.payload,
      }

    case MenuActionsTypes.LOAD_WEIGHT_MAP_SUCCESS:
      return {
        ...state,
        weightMap: action.payload,
      }

    case MenuActionsTypes.TOGGLE_FAST_BUY_MODAL_SUCCESS:
      return {
        ...state,
        isOpenFastBuyModal: action.payload,
      }

    case MenuActionsTypes.TOGGLE_CATALOG_ITEMS_TYPE_SUCCESS:
      return {
        ...state,
        selectedCatalogItemsType: action.payload,
      }
    case MenuActionsTypes.OPEN_PRODUCT_SCREEN_SUCCESS:
      return {
        ...state,
        isOpenProductScreen: true,
      }

    case MenuActionsTypes.CLOSE_PRODUCT_SCREEN:
      return {
        ...state,
        isOpenProductScreen: false,
      }

    case MenuActionsTypes.SET_IS_CHANGE_CATALOG_ITEMS: {
      return {
        ...state,
        isChangeCatalogItems: action.payload,
      }
    }

    default:
      return {
        ...state,
      }
  }
}
