import {CityDTO, RestaurantDTO} from 'grilnica-share'
import {call, put, select} from 'redux-saga/effects'
import {Actions as RestaurantActions} from '../ducks/restaurant'
import {Actions as ErrorActions} from '../ducks/error'
import {ClientDTO, PaymentTypeMap} from 'grilnica-store-share'
import {FavouritesRestaurant} from '../../types/restaurant/FavouritesRestaurant'
import {State} from '../ducks'
import {LSKeys, LSMethods} from '../../storage'
import {
  addClientRestaurantService,
  deleteClientRestaurantService,
  loadAllRestaurantListByCityIdService,
  loadRestaurantListByCityIdService,
  loadRestaurantPaymentTypeMapService,
} from '../../services/restaurant'
import {Actions as InitializeActions} from '../ducks/initialize'
import {ErrorInitializeEnum} from '../../types/common/ErrorInitializeEnum'

function* loadRestaurants() {
  yield call(loadAllRestaurants)
  yield call(loadClientRestaurants)
  yield call(loadRestaurantPaymentTypeMap)
}

function* loadAllRestaurants() {
  const city: CityDTO = yield call(LSMethods.getStorageData, LSKeys.SELECTED_CITY, null)
  try {
    if (city) {
      const restaurants: RestaurantDTO[] = yield call(
        loadAllRestaurantListByCityIdService,
        city.cityId,
      )
      yield call(setRestaurants, restaurants)
    }
  } catch (error) {
    console.log('Error load all restaurant ', error)
    yield put(ErrorActions.setError(error))
    const restaurants: RestaurantDTO[] = yield call(
      LSMethods.getStorageData,
      LSKeys.RESTAURANTS,
      [],
    )
    yield put(RestaurantActions.setRestaurants(restaurants))
    yield put(RestaurantActions.setFoundRestaurantList(restaurants))
  }
}

function* setRestaurants(restaurants: RestaurantDTO[]) {
  yield put(RestaurantActions.setRestaurants(restaurants))
  yield put(RestaurantActions.setFoundRestaurantList(restaurants))
  yield call(LSMethods.setStorageData, LSKeys.RESTAURANTS, restaurants)
}

function* loadRestaurantPaymentTypeMap() {
  try {
    const city: CityDTO = yield call(LSMethods.getStorageData, LSKeys.SELECTED_CITY, null)
    if (city) {
      const restaurantPaymentTypeMap: PaymentTypeMap = yield call(
        loadRestaurantPaymentTypeMapService,
        city.cityId,
      )
      yield call(setRestaurantPaymentTypeMap, restaurantPaymentTypeMap)
    }
  } catch (error) {
    console.log('Error load restaurant payment type map', error)
    yield put(ErrorActions.setError(error))
    const restaurantPaymentTypeMap: PaymentTypeMap = yield call(
      LSMethods.getStorageData,
      LSKeys.RESTAURANT_PAYMENT_TYPE_MAP,
      null,
    )
    yield put(RestaurantActions.setRestaurantPaymentTypeMap(restaurantPaymentTypeMap))
  }
}

function* setRestaurantPaymentTypeMap(restaurantPaymentTypeMap: PaymentTypeMap) {
  yield put(RestaurantActions.setRestaurantPaymentTypeMap(restaurantPaymentTypeMap))
  yield call(LSMethods.setStorageData, LSKeys.RESTAURANT_PAYMENT_TYPE_MAP, restaurantPaymentTypeMap)
}

function* backgroundUpdateRestaurants() {
  try {
    const city: CityDTO = yield call(LSMethods.getStorageData, LSKeys.SELECTED_CITY, null)
    if (city) {
      const restaurants: RestaurantDTO[] = yield call(
        loadAllRestaurantListByCityIdService,
        city.cityId,
      )
      yield call(setRestaurants, restaurants)

      const restaurantPaymentTypeMap: PaymentTypeMap = yield call(
        loadRestaurantPaymentTypeMapService,
        city.cityId,
      )
      yield call(setRestaurantPaymentTypeMap, restaurantPaymentTypeMap)
    }
  } catch (error) {
    console.log('Error background update restaurants', error)
    throw error
  }
}

function* loadClientRestaurants() {
  const client: ClientDTO = yield call(LSMethods.getStorageData, LSKeys.CLIENT, null)
  try {
    if (client) {
      const restaurants: RestaurantDTO[] = yield call(
        LSMethods.getStorageData,
        LSKeys.RESTAURANTS,
        [],
      )
      const selectedCity: CityDTO = yield select((state: State) => state.city.selectedCity)
      // const clientRestaurantsIds: ClientRestaurantIdDTO[] = yield call(loadClientRestaurantService)
      let favouritesRestaurants: FavouritesRestaurant[] = []
      // if (clientRestaurantsIds) {
      //   for (let restaurant of restaurants) {
      //     if (
      //       clientRestaurantsIds.findIndex(
      //         (item) => item.restaurantId === restaurant.restaurantId,
      //       ) !== -1
      //     ) {
      //       favouritesRestaurants.push({...restaurant, isFavourite: true})
      //     } else {
      //       favouritesRestaurants.push({...restaurant, isFavourite: false})
      //     }
      //   }
      // }
      if (selectedCity) {
        favouritesRestaurants = yield call(loadRestaurantListByCityIdService, selectedCity.cityId)
      }
      yield call(saveFavoritesRestaurants, favouritesRestaurants)
    }
  } catch (error) {
    yield put(ErrorActions.setError(error))
    const favouritesRestaurants: FavouritesRestaurant[] = yield call(
      LSMethods.getStorageData,
      LSKeys.FAVOURITES_RESTAURANTS,
      [],
    )
    yield put(RestaurantActions.setFavouriteRestaurants(favouritesRestaurants))
  }
}

function* clearClientRestaurants() {
  yield call(saveFavoritesRestaurants, [])
}

function* saveFavoritesRestaurants(favouritesRestaurants: FavouritesRestaurant[]) {
  yield call(sortFavouritesRestaurants, favouritesRestaurants)
  yield call(LSMethods.setStorageData, LSKeys.FAVOURITES_RESTAURANTS, favouritesRestaurants)
  yield put(RestaurantActions.setFavouriteRestaurants(favouritesRestaurants))
}

function* addFavouriteRestaurant({payload}: any) {
  const restaurantId: string = payload

  const city: CityDTO = yield select((state: State) => state.city.selectedCity)
  let favouriteRestaurants: FavouritesRestaurant[] = yield call(
    LSMethods.getStorageData,
    LSKeys.FAVOURITES_RESTAURANTS,
    [],
  )
  let restaurantIndex: number = favouriteRestaurants.findIndex(
    (item) => item.restaurantId === restaurantId,
  )
  favouriteRestaurants[restaurantIndex] = {
    ...favouriteRestaurants[restaurantIndex],
    isFavourite: true,
  }
  yield call(saveFavoritesRestaurants, favouriteRestaurants)
  try {
    yield call(addClientRestaurantService, restaurantId, city?.cityId)
  } catch (error) {
    //todo надо придумать как синхронизировать
    console.log(error)
  }
}

function* deleteFavouritesRestaurant({payload}: any) {
  const restaurantId: string = payload
  const city: CityDTO = yield select((state: State) => state.city.selectedCity)
  let favouriteRestaurants: FavouritesRestaurant[] = yield call(
    LSMethods.getStorageData,
    LSKeys.FAVOURITES_RESTAURANTS,
    [],
  )
  let favouriteRestaurantIndex: number = favouriteRestaurants.findIndex(
    (item) => item.restaurantId === restaurantId,
  )
  favouriteRestaurants[favouriteRestaurantIndex] = {
    ...favouriteRestaurants[favouriteRestaurantIndex],
    isFavourite: false,
  }
  yield call(saveFavoritesRestaurants, favouriteRestaurants)
  try {
    yield call(deleteClientRestaurantService, restaurantId, city?.cityId)
  } catch (error) {
    //todo надо придумать как синхронизировать
    console.log(error)
  }
}

function* initializeRestaurant() {
  let selectedCity: CityDTO = yield select((state: State) => state.city.selectedCity)
  if (selectedCity) {
    yield call(loadRestaurants)
  } else {
    console.error('Not city initializeRestaurant')
    yield put(InitializeActions.setErrorInitialize(ErrorInitializeEnum.ERROR_LOAD_SELECTED_CITY))
  }
}

function* sortFavouritesRestaurants(favouriteRestaurants: FavouritesRestaurant[]) {
  favouriteRestaurants.sort((a, b) => {
    if (!a.isFavourite && b.isFavourite) {
      return 1
    }
    if ((a.isFavourite && b.isFavourite) || (!a.isFavourite && !b.isFavourite)) {
      return 0
    }
    if (a.isFavourite && !b.isFavourite) {
      return -1
    }
  })
}

function* searchRestaurants({payload}: any) {
  const restaurantName: string = payload

  const restaurants: RestaurantDTO[] = yield select((state: State) => state.restaurant.restaurants)
  const foundRestaurantList: RestaurantDTO[] = restaurants.filter((item) =>
    item.name.toLowerCase().includes(restaurantName.toLowerCase()),
  )
  yield put(RestaurantActions.setFoundRestaurantList(foundRestaurantList))
}

export {
  loadRestaurants,
  initializeRestaurant,
  loadClientRestaurants,
  clearClientRestaurants,
  addFavouriteRestaurant,
  deleteFavouritesRestaurant,
  searchRestaurants,
  backgroundUpdateRestaurants,
}
