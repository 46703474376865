import React, {ReactNode, useMemo} from 'react'
import {RestaurantDTO} from 'grilnica-share'

interface RestaurantInformationProps {
  restaurant: RestaurantDTO
  className?: string
}

const RestaurantInformation: React.FC<RestaurantInformationProps> = ({
  restaurant,
  className,
}): React.ReactElement => {
  const getRestaurantWorkTime = useMemo(() => {
    if (restaurant && restaurant.periodOfWorkList) {
      const items: ReactNode[] = restaurant.periodOfWorkList.map((item, index) => (
        <div className={'d-flex info-item-content'} key={item.name + index}>
          <p className={'mb-0'}>{item.name}&nbsp;</p>
          <p className={'mb-0'}>
            {item.timeFrom.slice(0, 5)}-{item.timeTo.slice(0, 5)}
          </p>
        </div>
      ))
      return <div className={'mb-x4'}>{items}</div>
    }
  }, [restaurant])

  return (
    <div className={className ? className : ''}>
      <p className={'info-item-name mb-x2'}>Режим работы:</p>
      {getRestaurantWorkTime}
      <p className={'info-item-name mb-x2'}>Дополнительно:</p>
      <p className={'info-item-content mb-x4'}>{restaurant.description}</p>
      <p className={'info-item-name mb-x2'}>Кухня:</p>
      <p className={'info-item-content mb-x3'}>{restaurant.kitchenDescription}</p>
    </div>
  )
}

export {RestaurantInformation}
