import React from 'react'
import {COLORS} from 'grilnica-reactstrap'

interface OrderReadySVGProps {
  isActive: boolean
  size?: 's' | 'm'
  className?: string
}

const OrderReadySVG: React.FC<OrderReadySVGProps> = ({
  isActive,
  className,
  size,
}): React.ReactElement => {
  return (
    <svg
      className={className}
      width={size === 's' ? '24' : '48'}
      height={size === 's' ? '24' : '48'}
      viewBox="0 0 48 48"
      fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.7466 7.08991C16.4074 6.12572 8.05484 12.915 7.09064 22.2542C6.12645 31.5934 12.9157 39.946 22.2549 40.9101C31.5941 41.8743 39.9467 35.0851 40.9109 25.7459C41.0617 24.2846 41.0229 22.8489 40.8147 21.4626C40.7327 20.9164 41.109 20.4072 41.6552 20.3252C42.2013 20.2432 42.7106 20.6195 42.7926 21.1656C43.0255 22.7168 43.0686 24.3213 42.9003 25.9513C41.8227 36.3892 32.4875 43.9772 22.0495 42.8996C11.6116 41.8219 4.02359 32.4867 5.10122 22.0488C6.17885 11.6109 15.5141 4.02286 25.952 5.10049C27.4805 5.2583 28.9492 5.59352 30.3391 6.08404C30.8599 6.26783 31.1331 6.83903 30.9493 7.35983C30.7655 7.88063 30.1943 8.15383 29.6735 7.97004C28.4313 7.53163 27.117 7.2314 25.7466 7.08991ZM39.6765 10.7629C40.0836 11.1361 40.1111 11.7686 39.7379 12.1758L23.2379 30.1758C23.0577 30.3724 22.8063 30.4889 22.5397 30.4993C22.2731 30.5097 22.0135 30.4131 21.8184 30.2311L14.3184 23.2311C13.9147 22.8543 13.8929 22.2215 14.2697 21.8177C14.6465 21.414 15.2793 21.3921 15.6831 21.769L22.4454 28.0805L38.2636 10.8243C38.6368 10.4172 39.2694 10.3897 39.6765 10.7629Z"
        fill={isActive ? COLORS.green : COLORS.gray500}
      />
    </svg>
  )
}

export {OrderReadySVG}
