import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {ButtonIcon, COLORS, Icon} from 'grilnica-reactstrap'
import {LSKeys, LSMethods} from '../../storage'
import {closeSVG} from './svg/SystemIcons'
import {getStaticUrl} from '../../utils/static'

interface BannerAppsProps {
  className?: string
}

const BannerApps: React.FC<BannerAppsProps> = ({className}): React.ReactElement => {
  const [isShowBannerApp, setIsShowBannerApp] = useState<boolean>(false)

  const getShowBanner = useCallback((): void => {
    if (typeof window !== 'undefined') {
      const dateLastShow: number = LSMethods.getStorageData(LSKeys.DATE_LAST_SHOW_BANNER_APP, null)
      const currentDate: number = new Date().getTime()
      if (currentDate - dateLastShow >= 86400000) {
        setIsShowBannerApp(true)
      }
    }
  }, [])

  const setStorageData = useCallback(() => {
    setIsShowBannerApp(false)
    LSMethods.setStorageData(LSKeys.DATE_LAST_SHOW_BANNER_APP, new Date().getTime())
  }, [])

  useEffect(() => {
    const id: any = setTimeout(() => {
      getShowBanner()
    }, 5000)
    return () => clearTimeout(id)
  }, [getShowBanner])

  const renderBanner = useMemo(() => {
    return (
      <div className={isShowBannerApp ? 'w-100 d-flex justify-content-center' : 'd-none'}>
        <div className={'banner-app ' + (className ? className : '')}>
          <div className={'d-flex align-items-start mr-x4'}>
            <img
              src={getStaticUrl('/img/logo_mobile_app.svg')}
              alt={'Грильница'}
              title={'Грильница'}
              className={'mr-x4'}
            />
            <div>
              <p className={'try-mobile-app mb-x2'}>
                Попробуйте наше мобильное <br /> приложение
              </p>
              <div className={'d-flex align-items-center'}>
                <a
                  href={'https://apps.apple.com/ru/app/grilnicaapp/id1536865948'}
                  className={'mr-x4'}>
                  <img
                    alt={'Приложение Грильница для IOS'}
                    title={'Приложение Грильница для IOS'}
                    src={getStaticUrl('/img/app-store-download.svg')}
                  />
                </a>
                <a href={'https://play.google.com/store/apps/details?id=ru.grilnica.mobile'}>
                  <img
                    alt={'Приложение Грильница для Android'}
                    title={'Приложение Грильница для Android'}
                    src={getStaticUrl('/img/google-play-download.svg')}
                  />
                </a>
              </div>
            </div>
          </div>
          <ButtonIcon
            iconPath={closeSVG}
            title={'Закрыть'}
            isHoverArea
            onClick={() => {
              setStorageData()
            }}
          />
        </div>
      </div>
    )
  }, [className, isShowBannerApp, setStorageData])

  return <>{renderBanner}</>
}

export {BannerApps}
