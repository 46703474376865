import React, {useMemo} from 'react'
import {Button} from 'grilnica-reactstrap'
import {CONSTANTS} from '../../../../../../../constants'

interface OrderButtonSaveProps {
  saveOrder: () => void
  refreshPrice: () => void
  isErrorUpdateCheckActions: boolean
}

const OrderButtonSave: React.FC<OrderButtonSaveProps> = ({
  saveOrder,
  refreshPrice,
  isErrorUpdateCheckActions,
}): React.ReactElement => {
  const renderContent: React.ReactElement = useMemo(() => {
    return (
      <>
        <Button
          color={'primary'}
          isRoundedButton
          onClick={isErrorUpdateCheckActions ? refreshPrice : saveOrder}
          className={'w-100 mb-x2'}>
          {isErrorUpdateCheckActions ? 'Обновить' : 'Оформить'}
        </Button>
        <p className={'processing-personal-data text-center'}>
          Нажимая кнопку «Оформить», я выражаю{' '}
          <a className={'color-link-text'} href={CONSTANTS.cdnUrl + '/dataconsent.pdf'}>
            согласие
          </a>{' '}
          на{' '}
          <a className={'color-link-text'} href={CONSTANTS.cdnUrl + '/agreement.pdf'}>
            обработку моих персональных данных
          </a>{' '}
          и соглашаюсь с{' '}
          <a className={'color-link-text'} href={CONSTANTS.cdnUrl + '/offer.pdf'}>
            условиями публичной оферты
          </a>
          .
        </p>
      </>
    )
  }, [saveOrder, refreshPrice, isErrorUpdateCheckActions])

  return <>{renderContent}</>
}

export {OrderButtonSave}
