import React, {memo, useMemo} from 'react'
import {CityDTO, RestaurantDTO} from 'grilnica-share'
import {CONSTANTS} from '../../../../../constants'
import {
  ButtonIcon,
  ButtonLink,
  Col,
  Container,
  Loading,
  Row,
  Slider,
  SliderItem,
} from 'grilnica-reactstrap'
import {RestaurantInformation} from './components/RestaurantInformation'
import {RestaurantCard} from './components/RestaurantCard'
import {arrowLeftSVG} from '../../../components/svg/SystemIcons'
import {Map} from './Map'
import {AliasObjectMap} from '../../../../types/common/AliasObjectMap'

interface IRestaurantPageComponentProps {
  city: CityDTO
  restaurantAlias: string
  aliasRestaurantMap: AliasObjectMap
  restaurant: RestaurantDTO
  restaurantList: RestaurantDTO[]
  goBack: () => void
}

const RestaurantPageComponent: React.FC<IRestaurantPageComponentProps> = memo(
  ({
    city,
    restaurantAlias,
    aliasRestaurantMap,
    restaurant,
    restaurantList,
    goBack,
  }): React.ReactElement => {
    const renderImg = useMemo((): React.ReactElement => {
      if (restaurant) {
        let sliderItemsList: SliderItem[]
        if (restaurant?.restaurantImages && restaurant?.restaurantImages?.length > 0) {
          sliderItemsList = restaurant.restaurantImages.map((ri) => {
            return {
              altText: 'Изображение ресторана',
              caption: '',
              link: null,
              src: CONSTANTS.cdnUrl + '/' + ri.imageFileName,
            }
          })
        }
        if (sliderItemsList?.length > 0) {
          return (
            <Slider
              items={sliderItemsList}
              className={'w-100'}
              key={'restaurant-slider' + restaurant.restaurantId}
              id={'restaurant-slider' + restaurant.restaurantId}
              intervalMs={restaurant.sliderInterval * 1000}
            />
          )
        } else {
          return (
            <img
              width={'100%'}
              height={'100%'}
              alt={restaurant.name}
              title={restaurant.name}
              src={
                restaurant?.restaurantImages && restaurant?.restaurantImages?.length > 0
                  ? CONSTANTS.cdnUrl + '/' + restaurant.restaurantImages[0].imageFileName
                  : ''
              }
            />
          )
        }
      } else {
        return <></>
      }
    }, [restaurant])

    const renderRestaurantInfo = useMemo(() => {
      if (restaurant) {
        return (
          <Row>
            <Col md={6}>{renderImg}</Col>
            <Col md={5}>
              <RestaurantInformation restaurant={restaurant} className={'restaurant-info mb-x4'} />
              <div className={'mb-x6'}>
                <p className={'info-item-name mb-x2'}>Юридическая информация:</p>
                {restaurant.legalEntity && (
                  <p className={'info-item-content mb-0'}>{restaurant.legalEntity}</p>
                )}
                {restaurant.inn && <p className={'info-item-content mb-0'}>ИНН {restaurant.inn}</p>}
                {restaurant.ogrn && (
                  <p className={'info-item-content mb-0'}>ОГРНИП {restaurant.ogrn}</p>
                )}
              </div>
            </Col>
          </Row>
        )
      } else {
        return <Loading />
      }
    }, [renderImg, restaurant])

    const renderRestaurantCardList = useMemo(() => {
      if (restaurantList) {
        const restaurantId = aliasRestaurantMap[restaurantAlias]?.objectId
        let newList: RestaurantDTO[] = []
        for (let rest of restaurantList) {
          if (rest.cityId === city.cityId) {
            newList.push(rest)
          }
        }
        const restaurantIndex: number = newList.findIndex(
          (item) => item.restaurantId === restaurantId,
        )
        newList.splice(restaurantIndex, 1)
        const items: React.ReactNode[] = []
        let countRest
        if (newList.length > 3) {
          countRest = 3
        } else {
          countRest = newList.length
        }
        for (let i = 0; i < countRest; i++) {
          items.push(<RestaurantCard restaurant={newList[i]} key={newList[i].restaurantId} />)
        }
        return items
      }
    }, [aliasRestaurantMap, city?.cityId, restaurantAlias, restaurantList])

    return (
      <Container size={'normal'} className={'mt-x10 mb-x10'}>
        <section>
          {restaurant ? (
            <>
              <div className={'d-flex align-items-center mb-x8'}>
                <ButtonIcon
                  iconPath={arrowLeftSVG}
                  isHoverArea
                  title={'Назад'}
                  className={'mr-x4'}
                  onClick={() => {
                    goBack()
                  }}
                />
                <h1 className={'h1 mb-0'}>{restaurant.name}</h1>
              </div>
              {renderRestaurantInfo}
              <h2 className={'h2 text-center mb-x6 mt-x10'}>На карте</h2>
              {restaurant?.restaurantId && (
                <Map
                  className={'mb-x10'}
                  city={city}
                  restaurants={[restaurant]}
                  isRestaurantPage={true}
                />
              )}
              <h2 className={'h2 text-center mb-x6'}>Другие рестораны в городе {city.name}</h2>
              <Row>{restaurantList && renderRestaurantCardList}</Row>
              <div className={'text-right'}>
                <ButtonLink color={'primary'} style={'outline'} isRoundedButton to={'/rest'}>
                  Все рестораны
                </ButtonLink>
              </div>
            </>
          ) : (
            <Loading />
          )}
        </section>
      </Container>
    )
  },
)

export {RestaurantPageComponent}
