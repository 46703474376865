import {ProductMap, RestaurantByProductMap, SelectedProductDTO} from 'grilnica-store-share'
import {ProductByProductIdMap} from '../../../../../../../../../types/product/ProductByProductIdMap'
import {SupportsMaps} from '../../../../../../../../../types/product/SupportsMaps'
import React from 'react'

export interface ProductItemState {
  products: ProductMap
  productsByProductIdMap: ProductByProductIdMap
  supportsMaps: SupportsMaps
  selectedProduct: SelectedProductDTO
  restaurantByProductMap: RestaurantByProductMap
}

export const initialProductItemState: ProductItemState = {
  products: null,
  productsByProductIdMap: null,
  supportsMaps: {
    countSelectedOptionsMap: null,
    optionCategoryMap: null,
    optionCategoryTypeMap: null,
    optionMap: null,
    optionProductMap: null,
    productVariantMap: null,
    restrictionsMap: null,
    selectedOptionsMap: null,
    extraOptionsMap: {},
  },
  selectedProduct: null,
  restaurantByProductMap: null,
}

export enum ProductItemActionsTypes {
  SET_PRODUCTS_MAPS = 'SET_PRODUCTS_MAPS',
  SET_SUPPORTS_MAP = 'SET_SUPPORTS_MAP',
  SET_SELECTED_PRODUCT = 'SET_SELECTED_PRODUCT',
  CLEAR_SELECTED_PRODUCT = 'CLEAR_SELECTED_PRODUCT',
  TOGGLE_OPTION = 'TOGGLE_OPTION',
  SET_SELECTED_PRODUCT_COUNT = 'SET_SELECTED_PRODUCT_COUNT',
  SET_RESTAURANT_BY_PRODUCT_MAP = 'SET_RESTAURANT_BY_PRODUCT_MAP',
}

export interface ProductItemActions {
  type: ProductItemActionsTypes
  payload?: any
}

export const ProductItemReducer: React.Reducer<ProductItemState, ProductItemActions> = (
  state,
  action,
): ProductItemState => {
  switch (action.type) {
    case ProductItemActionsTypes.SET_PRODUCTS_MAPS: {
      return {
        ...state,
        products: action.payload.products,
        productsByProductIdMap: action.payload.productsByProductIdMap,
      }
    }

    case ProductItemActionsTypes.SET_SUPPORTS_MAP: {
      return {
        ...state,
        supportsMaps: action.payload,
      }
    }

    case ProductItemActionsTypes.SET_SELECTED_PRODUCT: {
      return {
        ...state,
        selectedProduct: action.payload,
      }
    }

    case ProductItemActionsTypes.CLEAR_SELECTED_PRODUCT: {
      return {
        ...state,
        supportsMaps: {...initialProductItemState.supportsMaps},
        selectedProduct: null,
      }
    }

    case ProductItemActionsTypes.TOGGLE_OPTION: {
      return {
        ...state,
        supportsMaps: {
          ...state.supportsMaps,
          selectedOptionsMap: action.payload.selectedOptionsMap,
          countSelectedOptionsMap: action.payload.countSelectedOptionsMap,
          restrictionsMap: action.payload.restrictionsMap
            ? action.payload.restrictionsMap
            : {...state.supportsMaps.restrictionsMap},
          extraOptionsMap: action.payload.extraOptionsMap
            ? action.payload.extraOptionsMap
            : {...state.supportsMaps.extraOptionsMap},
        },
      }
    }

    case ProductItemActionsTypes.SET_SELECTED_PRODUCT_COUNT: {
      if (action.payload > 0) {
        return {
          ...state,
          selectedProduct: {
            ...state.selectedProduct,
            count: action.payload,
          },
        }
      } else {
        return {
          ...state,
        }
      }
    }

    case ProductItemActionsTypes.SET_RESTAURANT_BY_PRODUCT_MAP: {
      return {
        ...state,
        restaurantByProductMap: action.payload,
      }
    }

    default: {
      return {
        ...state,
      }
    }
  }
}
