import React, {useMemo} from 'react'
import {Button, ButtonIcon, Col} from 'grilnica-reactstrap'
import {deleteSVG} from '../../../../components/svg/SystemIcons'
import {round} from 'grilnica-share'
import {SelectedProductDTO} from 'grilnica-store-share'

interface BasketButtonsProps {
  isTerminal: boolean
  selectedProducts: SelectedProductDTO[]
  priceFull: number
  saleFull: number
  toPlaceOrder: () => void
  clearBasket: () => void
  isUpdateCheckActions: boolean
}

const BasketButtons: React.FC<BasketButtonsProps> = ({
  isTerminal,
  selectedProducts,
  priceFull,
  saleFull,
  toPlaceOrder,
  clearBasket,
  isUpdateCheckActions,
}): React.ReactElement => {
  const renderContent: React.ReactElement = useMemo(() => {
    if (selectedProducts?.length > 0) {
      return (
        <Col md={{size: isTerminal ? 8 : 6, offset: isTerminal ? 2 : 3}} className={'mt-x8'}>
          <div className={'d-flex justify-content-between'}>
            <Button
              outline
              isRoundedButton
              className={'clear-basket-bun'}
              onClick={() => {
                clearBasket()
              }}>
              Очистить корзину
            </Button>
            <ButtonIcon
              iconPath={deleteSVG}
              isHoverArea
              size={'md'}
              title={'Очистить корзину'}
              className={'clear-basket-icon'}
              onClick={() => {
                clearBasket()
              }}
            />
            <Button
              onClick={toPlaceOrder}
              color={'primary'}
              isRoundedButton
              disabled={isUpdateCheckActions}>
              Оформить за {priceFull} &#8381;
              {saleFull ? (
                <span className={'sale-price ml-x2'}>
                  {' ' + round(priceFull + saleFull) + ' '}&#8381;
                </span>
              ) : null}
            </Button>
          </div>
        </Col>
      )
    } else {
      return null
    }
  }, [
    clearBasket,
    isTerminal,
    isUpdateCheckActions,
    priceFull,
    saleFull,
    selectedProducts?.length,
    toPlaceOrder,
  ])
  return <>{renderContent}</>
}

export {BasketButtons}
