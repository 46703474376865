import {BaseAction} from '../../common/BaseAction'
import {ContactDetailsState} from '../types/ContactDetailsState'
import {ContactDetailsActionsTypes} from './types'

const initialContactDetailsState: ContactDetailsState = {
  contactDetailsMap: null,
}

export default function contactDetails(
  state: ContactDetailsState = initialContactDetailsState,
  dispatch: BaseAction,
): ContactDetailsState {
  switch (dispatch.type) {
    case ContactDetailsActionsTypes.SET_CONTACT_DETAILS_MAP: {
      return {
        ...state,
        contactDetailsMap: dispatch.payload,
      }
    }

    default:
      return state
  }
}
