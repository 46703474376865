import {apiGet, apiPostFile} from '../../api'
import {CONSTANTS} from '../../../constants'

async function loadQualityControlLinkTelegramService(cityId: string): Promise<string> {
  return await apiGet(CONSTANTS.apiStoreUrl + '/quality-control/get-telegram-link', {
    cityId: cityId,
  })
}

async function loadQualityControlLinkWhatsAppService(cityId: string): Promise<string> {
  return await apiGet(CONSTANTS.apiStoreUrl + '/quality-control/get-whatsapp-link', {
    cityId: cityId,
  })
}

async function sendQualityControlService(
  url: any,
  formData: FormData,
  errorActions: any,
  cityId: string,
): Promise<any> {
  try {
    return await apiPostFile(url, formData, {
      cityId: cityId,
    })
  } catch (error) {
    errorActions.setError(error)
  }
}

export {
  sendQualityControlService,
  loadQualityControlLinkTelegramService,
  loadQualityControlLinkWhatsAppService,
}
