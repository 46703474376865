import React, {useCallback, useMemo} from 'react'
import {ResponseError} from 'grilnica-share'
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from 'grilnica-reactstrap'
import {bindErrorActions} from '../../../store/ducks/error'
import {connectComponent} from '../../../store/common'
import {reFormatPhone} from '../../site/utils/UserUtils'
import {
  ErrorObjectsMapBody,
  ErrorObjectsMapHeader,
  ErrorObjectsMap,
  A11yFromHelperErrorType,
} from 'grilnica-store-share'

interface ErrorModalProps {
  error: ResponseError
  errorActions: any
}

const ErrorModal: React.FC<ErrorModalProps> = ({error, errorActions}): React.ReactElement => {
  const closeModal = useCallback(() => {
    errorActions.clearError()
  }, [errorActions])

  const renderModalHeader = useCallback(
    (errorHeader: ErrorObjectsMapHeader): React.ReactElement => {
      let headerBlock: React.ReactElement = null
      if (errorHeader) {
        const classNameResult: string =
          (errorHeader.paddingBottom ? errorHeader.paddingBottom : '') +
          ' ' +
          (errorHeader.color ? errorHeader.color : 'text-gray-900')
        headerBlock = <ModalHeader className={classNameResult}>{errorHeader.text}</ModalHeader>
      }
      return headerBlock
    },
    [],
  )

  const renderTextList = useCallback((object: ErrorObjectsMapBody): React.ReactElement[] => {
    let textList: React.ReactElement[] = []
    if (Array.isArray(object.text)) {
      for (let i = 0; i < object.text?.length; i++) {
        const classNameTextResult: string =
          object.marginBottomText && i !== object.text?.length - 1
            ? object.marginBottomText
            : 'mb-0'
        textList.push(
          <p key={'textItem' + i} className={classNameTextResult}>
            {object.text[i]}
          </p>,
        )
      }
    } else {
      if (object.actionType === 'call') {
        textList.push(
          <a key={'textItem'} href={'tel:' + reFormatPhone(object.action)} className={'mb-0'}>
            {object.text}
          </a>,
        )
      } else {
        textList.push(
          <p key={'textItem'} className={'mb-0'}>
            {object.text}
          </p>,
        )
      }
    }
    return textList
  }, [])

  const renderModalBodyList = useCallback(
    (errorBody: ErrorObjectsMapBody[]) => {
      let bodyBlockItem: React.ReactNode[] = []

      for (let object of errorBody) {
        let renderText: React.ReactElement[] = renderTextList(object)
        const classNameBodyResult: string =
          (object.color ? object.color : 'text-gray-900') +
          ' ' +
          (object.weight ? object.weight : '') +
          ' ' +
          (object.marginBottom ? object.marginBottom : 'mb-x3')

        bodyBlockItem.push(
          <div key={'bodyItem' + object.sorting} className={classNameBodyResult}>
            {renderText}
          </div>,
        )
      }

      return bodyBlockItem
    },
    [renderTextList],
  )

  const renderModalBody = useCallback(
    (errorBody: ErrorObjectsMapBody[]) => {
      let bodyBlock: React.ReactNode = null
      if (errorBody?.length > 0) {
        errorBody.sort((a, b) => {
          if (a.sorting < b.sorting) {
            return -1
          }
          if (a.sorting > b.sorting) {
            return 1
          }
          return 0
        })
        let bodyBlockItem: React.ReactNode[] = renderModalBodyList(errorBody)
        if (bodyBlockItem.length > 0) {
          bodyBlock = <ModalBody className={'sec-subtitle1'}>{bodyBlockItem}</ModalBody>
        }
      }
      return bodyBlock
    },
    [renderModalBodyList],
  )

  const renderModalContent = useCallback(
    (errorObjectsMap: ErrorObjectsMap): React.ReactElement => {
      let headerBlock: React.ReactElement = renderModalHeader(errorObjectsMap.header)
      let bodyBlock: React.ReactNode = renderModalBody(errorObjectsMap.body)
      return (
        <>
          {headerBlock}
          {bodyBlock}
        </>
      )
    },
    [renderModalBody, renderModalHeader],
  )

  const renderContent: React.ReactElement = useMemo(() => {
    const objectsMap: A11yFromHelperErrorType = error?.objectsMap as A11yFromHelperErrorType
    if (objectsMap?.errorObjectsMap) {
      return (
        <Modal isOpen={!!error} onClose={closeModal} size={'xs'}>
          {renderModalContent(objectsMap.errorObjectsMap)}
          <ModalFooter>
            <Button color={'primary'} isRoundedButton onClick={closeModal}>
              Ок
            </Button>
          </ModalFooter>
        </Modal>
      )
    } else {
      return (
        <Modal isOpen={!!error} onClose={closeModal} size={'xs'}>
          <ModalHeader>Ошибка</ModalHeader>
          <ModalBody>{error ? error.errorMessage : null}</ModalBody>
          <ModalFooter>
            <Button color={'primary'} className={''} isRoundedButton onClick={closeModal}>
              Закрыть
            </Button>
          </ModalFooter>
        </Modal>
      )
    }
  }, [closeModal, error, renderModalContent])

  return <>{renderContent}</>
}

export default connectComponent(
  (state) => ({
    error: state.error.error,
  }),
  (dispatch) => ({
    errorActions: bindErrorActions(dispatch),
  }),
  ErrorModal,
)
