function getFullUrl(url: string, urlPrefix: string): string {
  if (new RegExp(/^https?:\/\//).test(url)) {
    return url
  } else {
    return (urlPrefix || '') + url
  }
}

function trimUrl(url: string): string {
  return url.replace(/\/$/, '')
}

// function getGlobalFullUrl(url: string): string {
//   if (new RegExp(/^https?:\/\//).test(url)) {
//     return url
//   } else {
//     return (URL_PREFIX || '') + url
//   }
// }

export {
  getFullUrl,
  trimUrl,
  // getGlobalFullUrl
}
