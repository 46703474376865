import React, {useMemo} from 'react'
import {ButtonIcon} from 'grilnica-reactstrap'
import {DeliveryTypeEnum} from 'grilnica-store-share'
import {TabOrderNav} from './nav/TabOrderNav'
import {arrowLeftSVG} from '../../../../components/svg/SystemIcons'

interface HeaderOrderPageProps {
  goBack: () => void
  deliveryType: DeliveryTypeEnum
  toggleTabButton: (deliveryType: DeliveryTypeEnum) => void
}

const HeaderOrderPage: React.FC<HeaderOrderPageProps> = ({
  goBack,
  deliveryType,
  toggleTabButton,
}): React.ReactElement => {
  const renderContent: React.ReactElement = useMemo(() => {
    return (
      <>
        <div className={'d-flex align-items-center justify-content-center mb-x4'}>
          <ButtonIcon
            title={'Назад'}
            className={'mr-x4'}
            isHoverArea
            iconPath={arrowLeftSVG}
            onClick={() => {
              goBack()
            }}
          />
          <h1 className={'h2 mb-0'}>Оформление заказа</h1>
        </div>
        <TabOrderNav deliveryType={deliveryType} toggleTabButton={toggleTabButton} />
      </>
    )
  }, [deliveryType, goBack, toggleTabButton])
  return <>{renderContent}</>
}

export {HeaderOrderPage}
