import {MenuCategoryDTO, ProductMap} from 'grilnica-store-share'
import {IsShowRestaurantByProductMap} from '../../types/restaurantProduct/IsShowRestaurantByProductMap'

export const isShowMenuCategory = (
  category: MenuCategoryDTO,
  productMap: ProductMap,
  categoryList: MenuCategoryDTO[],
  isShowRestaurantByProductMap: IsShowRestaurantByProductMap,
  isTerminal: boolean,
) => {
  if (category.isHideInApp) {
    return false
  }
  if (category.isActionCategory) {
    return true
  }

  if (productMap[category.menuCategoryId]) {
    return (
      getCountHideItem(
        productMap,
        category.menuCategoryId,
        isShowRestaurantByProductMap,
        isTerminal,
      ) !== productMap[category.menuCategoryId].length
    )
  } else {
    let countHideCategory: number = 0
    for (let childMenuCategory of categoryList) {
      if (childMenuCategory.parentMenuCategoryId === category.menuCategoryId) {
        if (
          getCountHideItem(
            productMap,
            childMenuCategory.menuCategoryId,
            isShowRestaurantByProductMap,
            isTerminal,
          ) === (productMap[childMenuCategory.menuCategoryId] || []).length ||
          childMenuCategory.isHideInApp
        ) {
          countHideCategory = countHideCategory + 1
        }
      }
    }
    return (
      countHideCategory !==
      categoryList.filter((item) => item.parentMenuCategoryId === category.menuCategoryId).length
    )
  }
}

const getCountHideItem = (
  productMap: ProductMap,
  menuCategoryId: string,
  isShowRestaurantByProductMap: IsShowRestaurantByProductMap,
  isTerminal: boolean,
): number => {
  let countHideItem: number = 0
  for (let product of productMap[menuCategoryId] || []) {
    if (
      (!product.price && !product.isShowWithZeroPrice) ||
      product.isHideInApp ||
      (isShowRestaurantByProductMap[product.productId] === undefined && !isTerminal)
    ) {
      countHideItem = countHideItem + 1
    }
  }
  return countHideItem
}
