import {ProductItemActionsTypes} from '../../ducks/ProductItemDuck'
import {SelectedOption} from '../../../../../../../../../../types/options/SelectedOption'
import {SelectedProductDTO} from 'grilnica-store-share'

const getIndexProduct = (
  selectedProduct: SelectedProductDTO[],
  comboProductId: string,
  productVariantId: string,
): number => {
  //если есть продукт вариант то сверяем еще и продукт вариант так как productId одинаковый
  if (productVariantId) {
    return selectedProduct.findIndex(
      (item) =>
        item.product.productId === comboProductId && item.productVariantId === productVariantId,
    )
  } else {
    return selectedProduct.findIndex((item) => item.product.productId === comboProductId)
  }
}

const getActiveIndexProduct = (selectedProduct: SelectedProductDTO[]): number => {
  return selectedProduct.findIndex((item) => item.isActive)
}

const toggleComboService = (
  comboGroupId: string,
  comboProductId: string,
  selectedProduct: SelectedProductDTO,
  selectedComboProduct: SelectedProductDTO,
  dispatch: any,
) => {
  const indexProduct: number = getIndexProduct(
    selectedComboProduct.selectedProducts[comboGroupId],
    comboProductId,
    selectedProduct.productVariantId,
  )
  const activeIndexProduct: number = getActiveIndexProduct(
    selectedComboProduct.selectedProducts[comboGroupId],
  )

  if (indexProduct !== activeIndexProduct) {
    selectedComboProduct.selectedProducts[comboGroupId][activeIndexProduct].isActive = false
  }

  let newSelectedProduct: SelectedProductDTO = selectedComboProduct
  newSelectedProduct.selectedProducts[comboGroupId][indexProduct] = {
    ...selectedProduct,
    isActive: true,
    iikoComboGroupId:
      newSelectedProduct.selectedProducts[comboGroupId][indexProduct].iikoComboGroupId,
    selectedOptions: selectedProduct.selectedOptions,
  }

  //подсчет цены комбо с опциями
  let comboPrice: number = newSelectedProduct.product.price

  for (let key in newSelectedProduct.selectedProducts) {
    for (let selectedProduct of newSelectedProduct.selectedProducts[key]) {
      if (selectedProduct.isActive) {
        for (let selectedOption of selectedProduct.selectedOptions) {
          let tempSelectedOption: SelectedOption = selectedOption
          if (tempSelectedOption.fullOptionsPrice) {
            comboPrice = comboPrice + tempSelectedOption.fullOptionsPrice
          }
        }
      }
    }
  }

  newSelectedProduct.price = comboPrice

  dispatch({
    type: ProductItemActionsTypes.SET_SELECTED_PRODUCT,
    payload: {...newSelectedProduct},
  })
}

export {toggleComboService, getIndexProduct, getActiveIndexProduct}
