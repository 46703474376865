import React, {useMemo} from 'react'
import {
  Button,
  Col,
  Collapse,
  COLORS,
  Icon,
  InputFileVertical,
  InputSelectVerticalUncontrolled,
  InputVertical,
  Loading,
  Row,
  TextArea,
} from 'grilnica-reactstrap'
import {RestaurantDTO} from 'grilnica-share'
import {QualityControl} from './QualityControlPage'
import {chevronDownSVG, chevronUpSVG} from '../../../components/svg/SystemIcons'
import {CONSTANTS} from '../../../../../constants'

interface QualityControlFormProps {
  restaurantList: RestaurantDTO[]
  qualityControl: QualityControl
  handleSubmit: () => Promise<void>
  handleChange: (key: string, value: any) => void
  handleChangeFiles: (value: any) => void
  toggleCollapse: () => void
  isOpenCollapse: boolean
  sendingRequestStatus: 'success' | 'waiting' | 'running'
}

const QualityControlForm: React.FC<QualityControlFormProps> = ({
  restaurantList,
  qualityControl,
  handleSubmit,
  handleChange,
  toggleCollapse,
  isOpenCollapse,
  handleChangeFiles,
  sendingRequestStatus,
}): React.ReactElement => {
  const renderContent: React.ReactElement = useMemo(() => {
    const {userName, comment, phoneNumber, email, restaurantId} = qualityControl
    return (
      <form
        className={'mb-x8 mx-x4'}
        onSubmit={async (event) => {
          event.preventDefault()
          await handleSubmit()
        }}>
        <Row>
          <Col lg={7} md={9} className={'feedback-site'}>
            <p className={'about-description mb-0'}>
              Напишите нам, и специалисты Отдела «Контроль Качества» обязательно рассмотрят Ваше
              обращение.
            </p>
            <p className={'about-description mb-x6'}>
              Заявки обрабатывают <b className={'subtitle1'}>с&nbsp;08:00 до&nbsp;02:00 ежедневно.</b>
            </p>
            <Row>
              <Col md={6}>
                <InputVertical
                  id={'input-user-name'}
                  name={'userName'}
                  type={'text'}
                  label={'Имя'}
                  value={userName}
                  placeholder={'Введите ваше имя'}
                  isRequired
                  isRounded
                  size={'lg'}
                  onChange={(value) => {
                    handleChange('userName', value)
                  }}
                />
              </Col>
              <Col md={6}>
                <InputSelectVerticalUncontrolled
                  isRequired={false}
                  isSearchable={false}
                  id={'input-restaurant'}
                  instanceId={'input-option-restaurant'}
                  name={'restaurant'}
                  data={restaurantList}
                  label={'Ресторан'}
                  placeholder={'Выберите ресторан'}
                  value={restaurantId ? restaurantId : null}
                  defaultValue={restaurantId ? restaurantId : null}
                  optionLabel={'name'}
                  optionValue={'restaurantId'}
                  onChange={(value) => {
                    handleChange('restaurantId', value)
                  }}
                  size={'lg'}
                  isRounded
                />
              </Col>
              <Col md={12}>
                <TextArea
                  id={'input-comment'}
                  name={'comment'}
                  label={'Замечание или предложение'}
                  value={comment}
                  required
                  isRounded
                  size={'lg'}
                  placeholder={'Напишите в свободной форме «замечание или предложение» по работе'}
                  className={'mb-x8'}
                  onChange={(value) => {
                    handleChange('comment', value)
                  }}
                />
              </Col>
              <Col>
                <div
                  className={'d-flex align-items-center mb-x6 cursor-pointer'}
                  onClick={toggleCollapse}>
                  <p className={'about-description mb-0 mr-x2'}>
                    Желаете получить обратную связь, заполните дополнительную информацию
                  </p>
                  <Icon
                    path={isOpenCollapse ? chevronUpSVG : chevronDownSVG}
                    color={COLORS.gray600}
                    className={'flex-shrink-0'}
                  />
                </div>
                <Collapse isOpen={isOpenCollapse}>
                  <Row className={'mb-x2'}>
                    <Col md={6}>
                      <InputVertical
                        id={'input-user-email'}
                        name={'userEmail'}
                        type={'text'}
                        label={'Email'}
                        value={email}
                        isRequired={isOpenCollapse}
                        isRounded
                        size={'lg'}
                        placeholder={'Email'}
                        onChange={(value) => {
                          handleChange('email', value)
                        }}
                      />
                    </Col>
                    <Col md={6}>
                      <InputVertical
                        id={'input-user-phone'}
                        name={'userPhone'}
                        type={'text'}
                        label={'Телефон'}
                        value={phoneNumber}
                        isRequired={isOpenCollapse}
                        isRounded
                        size={'lg'}
                        placeholder={'Введите номер'}
                        onChange={(value) => {
                          handleChange('phoneNumber', value)
                        }}
                      />
                    </Col>
                    {isOpenCollapse && (
                      <Col md={6}>
                        <InputFileVertical
                          id={'input-user-file'}
                          name={'userFile'}
                          label={'Добавить файл'}
                          isRequired={false}
                          multiple
                          onChange={handleChangeFiles}
                        />
                      </Col>
                    )}
                  </Row>
                </Collapse>
              </Col>
              <Col md={12}>
                <p className={'user-agreement mb-x8'}>
                  Нажимая кнопку «Отправить», я выражаю{' '}
                  <a className={'color-link-text'} href={CONSTANTS.cdnUrl + '/dataconsent.pdf'}>
                    согласие
                  </a>{' '}
                  на{' '}
                  <a className={'color-link-text'} href={CONSTANTS.cdnUrl + '/agreement.pdf'}>
                    обработку моих персональных данных
                  </a>{' '}
                  и соглашаюсь с{' '}
                  <a className={'color-link-text'} href={CONSTANTS.cdnUrl + '/offer.pdf'}>
                    условиями публичной оферты
                  </a>
                  .
                </p>
                {sendingRequestStatus === 'success' && (
                  <p className={'text-success'}>Письмо успешно отправлено</p>
                )}
                {sendingRequestStatus === 'running' && <Loading className={'mb-small'} />}
                <Button type={'submit'} color={'primary'} isRoundedButton>
                  Отправить
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </form>
    )
  }, [
    sendingRequestStatus,
    handleChange,
    handleChangeFiles,
    handleSubmit,
    isOpenCollapse,
    qualityControl,
    restaurantList,
    toggleCollapse,
  ])
  return <>{renderContent}</>
}

export {QualityControlForm}
