import React from 'react'
import {Link} from 'react-router-dom'

interface ToggleSaleProps {
  className?: string
  toggleName: string
  isActive: boolean
  onClick?: () => void
  to?: string
}

const ToggleFilter: React.FC<ToggleSaleProps> = ({
  className,
  toggleName,
  isActive,
  onClick,
  to,
}): React.ReactElement => {
  if (to) {
    return (
      <Link
        className={
          'gr-toggle-filter ' + (isActive ? 'active ' : ' ') + (className ? className : '')
        }
        to={to}>
        {toggleName}
      </Link>
    )
  } else {
    return (
      <div
        className={
          'gr-toggle-filter ' + (isActive ? 'active ' : ' ') + (className ? className : '')
        }
        onClick={onClick}>
        {toggleName}
      </div>
    )
  }
}

export {ToggleFilter}
