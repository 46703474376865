import {BaseAction} from '../../common/BaseAction'
import {bindActionCreators} from 'redux'
import {SocketActionsTypes} from './types'

export const Actions = {
  setIsSocketConnect: (isConnect: boolean): BaseAction => ({
    type: SocketActionsTypes.SET_IS_SOCKET_CONNECT,
    payload: isConnect,
  }),

  connectSocket: (): BaseAction => ({
    type: SocketActionsTypes.CONNECT_SOCKET,
  }),

  disconnectSocket: (): BaseAction => ({
    type: SocketActionsTypes.DISCONNECT_SOCKET,
  }),

  // event: (data: any): BaseAction => ({
  //   type: SocketActionsTypes.EVENT,
  //   payload: {data},
  // }),
}

export type SocketActionsType = typeof Actions

export function bindSocketActions(dispatch: any) {
  return bindActionCreators(Actions, dispatch)
}
