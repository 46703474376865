import {ResponseError} from 'grilnica-share'
import {select, put} from 'redux-saga/effects'
import {State} from '../ducks'
import {Actions as ErrorActions} from '../ducks/error'

function* setError({payload}: any) {
  const error: ResponseError = payload
  const isInitialized: boolean = yield select((state: State) => state.initialize.isInitialized)
  const openedModalId: string = yield select((state: State) => state.common.openedModalId)
  if (isInitialized && !openedModalId) {
    yield put(ErrorActions.setError(error))
  }
}

export {setError}
