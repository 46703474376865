import React, {Dispatch, useMemo} from 'react'
import {ButtonIconTerminal, Col, Row} from 'grilnica-reactstrap'
import {CONSTANTS} from '../../../../../../../../../constants'
import {PromoItems} from '../../productList/productItem/components/card/PromoItems'
import {ProductInfo} from './ProductInfo'
import {
  MenuCategoryDTO,
  ProductMap,
  RestaurantByProductDTO,
  SelectedProductDTO,
  WeightMapDto,
} from 'grilnica-store-share'
import {SupportsMaps} from '../../../../../../../../types/product/SupportsMaps'
import {ProductItemActions} from '../../productList/productItem/ducks/ProductItemDuck'
import {arrowLeftSVG} from '../../../../../../../components/svg/SystemIcons'
import {getWeightWithOptions} from '../../../../../../../../utils/menu/getWeight'

interface ProductPageContentProps {
  selectedProduct: SelectedProductDTO
  supportsMaps: SupportsMaps
  toggleOption: (
    optionCategoryId: string,
    optionId: string,
    isChecked?: boolean,
    isOptional?: boolean,
  ) => void
  goBack?: () => void
  sendToBasket: () => void
  isSave: boolean
  toggleComboProduct: (
    comboGroupId: string,
    comboProductId: string,
    selectedProduct: SelectedProductDTO,
  ) => void
  restaurants: RestaurantByProductDTO[]
  isTerminal?: boolean
  setProductState: Dispatch<ProductItemActions>
  productMap: ProductMap
  menuCategoryList: MenuCategoryDTO[]
  weightMap: WeightMapDto
}

const ProductPageContent: React.FC<ProductPageContentProps> = ({
  selectedProduct,
  supportsMaps,
  toggleOption,
  goBack,
  sendToBasket,
  isSave,
  restaurants,
  toggleComboProduct,
  isTerminal,
  setProductState,
  productMap,
  menuCategoryList,
  weightMap,
}): React.ReactElement => {
  const renderContent: React.ReactElement = useMemo(() => {
    let weight: string = null
    if (selectedProduct?.product) {
      weight = getWeightWithOptions(selectedProduct, weightMap, 'active')
    }

    return (
      // @ts-ignore
      <Row className={'product-page'} itemScope itemType={'https://schema.org/Product'}>
        <Col md={{size: 5}}>
          <div className={'image-container'}>
            <img
              itemProp={'image'}
              src={CONSTANTS.cdnUrl + '/' + selectedProduct.product.imageFileName}
              width={'100%'}
              height={'auto'}
              alt={selectedProduct.product.name}
              title={selectedProduct.product.name}
            />
            {isTerminal && (
              <ButtonIconTerminal
                iconPath={arrowLeftSVG}
                title={'Назад'}
                className={'back-button-icon-terminal'}
                onClick={() => {
                  goBack()
                }}
              />
            )}
            {selectedProduct.product.labels && (
              <PromoItems labels={selectedProduct.product.labels} />
            )}
          </div>
        </Col>
        <Col md={7}>
          <ProductInfo
            selectedProduct={selectedProduct}
            supportsMaps={supportsMaps}
            toggleOption={toggleOption}
            sendToBasket={sendToBasket}
            goBack={goBack}
            isSave={isSave}
            toggleComboProduct={toggleComboProduct}
            restaurants={restaurants}
            weight={weight}
            isTerminal={isTerminal}
            setProductState={setProductState}
            isForSale={!!selectedProduct?.product?.menuCategoryId}
            productMap={productMap}
            menuCategoryList={menuCategoryList}
            weightMap={weightMap}
          />
        </Col>
      </Row>
    )
  }, [
    selectedProduct,
    isTerminal,
    supportsMaps,
    toggleOption,
    sendToBasket,
    goBack,
    isSave,
    toggleComboProduct,
    restaurants,
    setProductState,
    productMap,
    menuCategoryList,
    weightMap,
  ])
  return <>{renderContent}</>
}

export {ProductPageContent}
