import {CONSTANTS} from '../../../constants'
import {apiGet, apiPost} from '../../api'
import {ClientDTO, SendTokenGoalEnum, SendTokenMethodEnum} from 'grilnica-store-share'
import {AuthorizationClientTokenDTO} from '../../types/auth/AuthorizationClientTokenDTO'

async function sendPhoneNumberService(
  phoneNumber: string,
  cityId: string,
  sendGoal: SendTokenGoalEnum,
  sendMethod: SendTokenMethodEnum | undefined,
) {
  const url: any = new URL(CONSTANTS.apiStoreUrl + '/client/phone-confirmation-token/create')
  url.searchParams.append('phone', phoneNumber)
  url.searchParams.append('sendGoal', sendGoal)
  if (sendMethod) {
    url.searchParams.append('sendMethod', sendMethod as string)
  }
  return await apiGet(url, {
    isBasicHeaders: true,
    cityId: cityId,
  })
}

async function terminalLoginByPhone(
  phone: string,
  cityId: string,
): Promise<AuthorizationClientTokenDTO> {
  let url: any = new URL(CONSTANTS.apiStoreUrl + '/terminal/client/authorize')
  return await apiPost(
    url,
    {phone},
    {
      isBasicHeaders: true,
      cityId,
    },
  )
}

async function checkAuthFourService(
  phoneNumber: string,
  codeSix: string,
  cityId: string,
): Promise<AuthorizationClientTokenDTO> {
  let url: any = new URL(CONSTANTS.apiStoreUrl + '/client/phone-confirmation-token/check-auth-four')
  url.searchParams.append('phone', phoneNumber)
  url.searchParams.append('codeFour', codeSix)
  return await apiGet(url, {
    isBasicHeaders: true,
    cityId: cityId,
  })
}

async function checkSixAuthService(
  phoneNumber: string,
  codeSix: string,
  cityId: string,
): Promise<any> {
  let url: any = new URL(CONSTANTS.apiStoreUrl + '/client/phone-confirmation-token/check-auth-six')
  url.searchParams.append('phone', phoneNumber)
  url.searchParams.append('codeSix', codeSix)
  return await apiGet(url, {
    isBasicHeaders: true,
    cityId: cityId,
  })
}

async function logOutService(cityId: string) {
  return await apiGet(CONSTANTS.apiStoreUrl + '/client/logout', {
    cityId: cityId,
  })
}

async function saveClientDataService(newClient: ClientDTO, cityId: string) {
  return await apiPost(CONSTANTS.apiStoreUrl + '/client/', newClient, {
    cityId: cityId,
  })
}

export {
  sendPhoneNumberService,
  terminalLoginByPhone,
  checkAuthFourService,
  checkSixAuthService,
  logOutService,
  saveClientDataService,
}
