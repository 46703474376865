import React, {useCallback, useEffect} from 'react'
import {CityDTO, RestaurantDTO} from 'grilnica-share'
import {RegionOfDeliveryDTO} from 'grilnica-store-share'
import {AreaRegionOfDeliveryDTO} from '../../../../types/address/AreaRegionOfDeliveryDTO'
import {FavouritesRestaurant} from '../../../../types/restaurant/FavouritesRestaurant'
import {getStaticUrl} from '../../../../utils/static'
import {isEmpty} from 'grilnica-share-client'
import {formatDeliveryTime} from 'grilnica-share/lib/utils'

interface MapProps {
  className?: string
  city: CityDTO
  restaurants?: RestaurantDTO[] | FavouritesRestaurant[]
  areaRegionOfDeliveryList?: AreaRegionOfDeliveryDTO[]
  onChangeSelectedAreaOfDelivery?: (areaRegionOfDelivery: AreaRegionOfDeliveryDTO) => void
  onClickMark?: (restaurant: RestaurantDTO | FavouritesRestaurant) => void
  isRestaurantPage: boolean //TODO:: Доработать чтобы работало корректно на всех страницах
}

const Map: React.FC<MapProps> = ({
  className,
  city,
  restaurants,
  areaRegionOfDeliveryList,
  onChangeSelectedAreaOfDelivery,
  onClickMark,
  isRestaurantPage,
}): React.ReactElement => {
  let map

  const createMap = useCallback((): void => {
    if (!map) {
      // @ts-ignore
      // eslint-disable-next-line no-undef,react-hooks/exhaustive-deps
      map = new ymaps.Map(
        'map',
        {
          center: city && [city.latitude, city.longitude],
          zoom: 11,
        },
        {},
      )
      map.controls.remove('searchControl')
      map.controls.remove('geolocationControl')
      map.controls.remove('routeButtonControl')
      map.controls.remove('trafficControl')
      map.controls.remove('typeSelector')
      map.controls.remove('fullscreenControl')
      map.controls.remove('rulerControl')
    }
  }, [map])

  const getBalloonContent = (regionOfDelivery: RegionOfDeliveryDTO) => {
    const renderInfo = (text: string, isLastChild: boolean = false) => {
      return `<p class='caption text-gray-900 ${isLastChild ? 'mb-0' : 'mb-x2'}'>${text}</p>`
    }
    const hasFreeDelivery = !isEmpty(regionOfDelivery.priceFreeDelivery)
    return `<div class='p-i-x3'>
        <p class='subtitle1 text-gray-900 mb-x2'>Цена доставки: ${
          regionOfDelivery.mainPrice
        } руб</p>
        ${renderInfo(`Доставка с: ${regionOfDelivery.restaurantName}`)}
        ${renderInfo(
          `Доставим в течение ${formatDeliveryTime(regionOfDelivery.deliveryTime)}`,
          !hasFreeDelivery,
        )}
        ${
          hasFreeDelivery
            ? renderInfo(`Бесплатная доставка от: ${regionOfDelivery.priceFreeDelivery} руб`, true)
            : ''
        }
      </div>`
  }

  const renderPolygon = useCallback(
    (areaOfDelivery: AreaRegionOfDeliveryDTO): any => {
      // @ts-ignore
      // eslint-disable-next-line no-undef
      let polygon = new ymaps.Polygon(
        [areaOfDelivery.coordinates],
        {balloonContent: getBalloonContent(areaOfDelivery.regionOfDelivery)},
        {
          editorDrawingCursor: 'crosshair',
          editorMaxPoints: 100,
          fillColor: areaOfDelivery.regionOfDelivery.backgroundColor
            ? areaOfDelivery.regionOfDelivery.backgroundColor
            : '#00FF00',
          fillOpacity: 0.5,
          strokeColor: areaOfDelivery.regionOfDelivery.backgroundColor
            ? areaOfDelivery.regionOfDelivery.backgroundColor
            : '#0000FF',
          strokeWidth: 5,
        },
      )
      polygon.events.add('click', () => {
        onChangeSelectedAreaOfDelivery(areaOfDelivery)
      })
      if (map?.geoObjects) {
        map.geoObjects.add(polygon)
      }
    },
    [map, onChangeSelectedAreaOfDelivery],
  )

  const getPeriodOfWorkRestaurant = (restaurant: RestaurantDTO) => {
    if (restaurant && restaurant.periodOfWorkList) {
      let items: string = ''
      for (let periodOfWork of restaurant.periodOfWorkList) {
        items =
          items +
          `<div>${periodOfWork.name}&nbsp;${periodOfWork.timeFrom.slice(
            0,
            5,
          )}-${periodOfWork.timeTo.slice(0, 5)}
          </div>`
      }
      return `<div>${items}</div>`
    }
  }

  const getBalloonContentRestaurants = useCallback((restaurant: RestaurantDTO) => {
    if (restaurant.periodOfWorkList) {
      return `<div>
        Адрес: ${restaurant.address}</br>
        Режим работы: ${getPeriodOfWorkRestaurant(restaurant)}
      </div>`
    } else {
      return `<div>
        Адрес: ${restaurant.address}</br>
      </div>`
    }
  }, [])

  const renderMarkers = useCallback(
    (restaurants: RestaurantDTO[] | FavouritesRestaurant[]) => {
      for (let item of restaurants) {
        const iconImageHref: string = `/img/map-marker-off.svg`
        const iconImageHrefActive: string = `/img/map-marker-on.svg`

        // @ts-ignore
        // eslint-disable-next-line no-undef
        const mark = new ymaps.Placemark(
          [item.latitude, item.longitude],
          {
            balloonContent: getBalloonContentRestaurants(item),
          },
          {
            hideIconOnBalloonOpen: false,
            hasBalloon: true,
            iconLayout: 'default#image',
            iconImageHref: getStaticUrl(iconImageHref),
            iconImageHrefActive: getStaticUrl(iconImageHrefActive),
            iconImageHrefHover: getStaticUrl(iconImageHrefActive),
            iconImageSize: [64, 64],
            iconImageOffset: [-32, -32],
          },
        )
        {
          onClickMark &&
            mark.events.add('click', () => {
              onClickMark(item)
            })
        }
        if (map?.geoObjects) {
          map.geoObjects.add(mark)
        }
      }
    },
    [getBalloonContentRestaurants, map?.geoObjects, onClickMark],
  )

  const clearMap = useCallback((): void => {
    if (map?.geoObjects) {
      map.geoObjects.removeAll()
    }
  }, [map])

  const renderAreas = useCallback((): void => {
    if (isRestaurantPage) {
      clearMap()
    }
    if (areaRegionOfDeliveryList) {
      for (let areaRegionOfDeliveryItem of areaRegionOfDeliveryList) {
        renderPolygon(areaRegionOfDeliveryItem)
      }
    }
    if (restaurants) {
      renderMarkers(restaurants)
    }
  }, [
    areaRegionOfDeliveryList,
    clearMap,
    isRestaurantPage,
    renderMarkers,
    renderPolygon,
    restaurants,
  ])

  const handleLoad = useCallback((): void => {
    // @ts-ignore
    // eslint-disable-next-line no-undef
    ymaps.ready(createMap())
  }, [createMap])

  useEffect(() => {
    setTimeout(() => {
      handleLoad()
      renderAreas()
    }, 1000)
  }, [handleLoad, renderAreas, restaurants])

  return (
    <div className={className ? className : ''}>
      <div id={'map'} className={'map-size'} />
    </div>
  )
}

export {Map}
