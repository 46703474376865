import {call, put, delay, select} from 'redux-saga/effects'
import {ClientDTO, PhoneConfirmationTokenDTO, SendTokenGoalEnum} from 'grilnica-store-share'
import {Actions as AuthActions} from '../ducks/auth'
import {Actions as ErrorActions} from '../ducks/error'
import {Actions as InitializeActions} from '../ducks/initialize'
import {sendPhoneNumberService} from '../../services'
import {State} from '../ducks'
import {AuthorizationClientTokenDTO} from '../../types/auth/AuthorizationClientTokenDTO'
import {LSKeys, LSMethods} from '../../storage'
import {
  checkAuthFourService,
  checkSixAuthService,
  logOutService,
  saveClientDataService,
  terminalLoginByPhone,
} from '../../services/auth'
import {deletePrepareOrder, initializeOrderAuthFields} from './order/order'
import {Actions as ProductActions} from '../ducks/product'
import {clearBasket} from './basket/basket'
import {encryptPhoneNumber, ResponseError} from 'grilnica-share'
import {CityDTO} from 'grilnica-share'
import {TerminalState} from '../terminal/types'

function* initializeAuth() {
  const client: ClientDTO = yield call(LSMethods.getStorageData, LSKeys.CLIENT, null)
  yield call(LSMethods.removeItem, LSKeys.TERMINAL_ALIAS)
  yield put(AuthActions.setClient(client))
}

function* saveClientData({payload}: any) {
  const newClient: ClientDTO = payload
  try {
    const city: CityDTO = yield select((state: State) => state.city.selectedCity)
    const client: ClientDTO = yield call(saveClientDataService, newClient, city?.cityId)
    yield put(AuthActions.setClient(client))
    yield call(LSMethods.setStorageData, LSKeys.CLIENT, client)
    yield call(toggleSaveClientPopover, 'Изменения сохранены')
    yield call(initializeOrderAuthFields)
  } catch (error) {
    yield put(ErrorActions.setError(error))
  }
}

function* toggleSaveClientPopover(text: string) {
  yield put(ProductActions.setProductPopoverText(null))
  yield put(ProductActions.setProductPopoverText(text))
  yield delay(2000)
  yield put(ProductActions.setProductPopoverText(null))
}

function* sendPhoneNumber({payload}: any) {
  let {phoneNumber, sendGoal, sendMethod} = payload
  phoneNumber = phoneNumber.replace(/\s/g, '')
  try {
    const city: CityDTO = yield select((state: State) => state.city.selectedCity)
    phoneNumber = phoneNumber.replace('+', '')
    const encryptPhone: string = encryptPhoneNumber(phoneNumber)
    const phoneConfirmationObject: PhoneConfirmationTokenDTO = yield call(
      sendPhoneNumberService,
      encryptPhone,
      city?.cityId,
      sendGoal,
      sendMethod,
    )
    yield put(AuthActions.setPhoneConfirmationObject(phoneConfirmationObject))
    yield put(AuthActions.setOneTimeCodeFailureMessage(undefined))
    if (sendGoal === SendTokenGoalEnum.LOGIN_IN_TERMINAL) {
      yield call(startTimer, 30)
    } else {
      yield call(startTimer)
    }
  } catch (error) {
    console.error(error)
    if (sendGoal === SendTokenGoalEnum.LOGIN_IN_TERMINAL) {
      yield put(AuthActions.setPhoneConfirmationFailureMessage(error.errorMessage))
    }
    yield put(ErrorActions.setError(error))
  }
}

function* startTimer(seconds: number = 60) {
  for (let i: number = seconds; i >= 0; i--) {
    yield put(AuthActions.setOneTimeCodeTimer(i))
    yield delay(1000)
  }
}

function* checkAuthBySixCode({payload}: any) {
  const moveToProfile = payload.moveToProfile
  const phoneConfirmationObject: PhoneConfirmationTokenDTO = yield select(
    (state: State) => state.auth.phoneConfirmationObject,
  )
  const cityId: string = yield select((state: State) => state.city.selectedCity.cityId)
  if (phoneConfirmationObject) {
    let auth: AuthorizationClientTokenDTO | undefined
    try {
      auth = yield call(
        checkSixAuthService,
        phoneConfirmationObject.phone as string,
        phoneConfirmationObject.codeSix as string,
        cityId,
      )
    } catch (e) {
      // console.log('checkAuthBySixCode', e)
      yield delay(5000)
    }
    if (auth?.client) {
      yield put(AuthActions.toggleSendingConfirmationTokenId(true))
      yield call(login, auth)
      yield put(AuthActions.toggleSendingConfirmationTokenId(false))
      yield put(AuthActions.setRequestAuthBySixCode(false))
      yield put(InitializeActions.initializeRequest(true))
      yield call(moveToProfile)
    } else {
      yield put(AuthActions.setRequestAuthBySixCode(false))
    }
  }
}

function* sendPhoneConfirmationTokenId({payload}: any) {
  yield put(AuthActions.toggleSendingConfirmationTokenId(true))
  yield put(AuthActions.setOneTimeCodeFailureMessage(undefined))
  const oneTimeCode = payload.oneTimeCode
  const moveToProfile = payload.moveToProfile
  const cityId: string = yield select((state: State) => state.city.selectedCity.cityId)
  const phoneConfirmationObject: PhoneConfirmationTokenDTO = yield select(
    (state: State) => state.auth.phoneConfirmationObject,
  )
  try {
    const authToken: AuthorizationClientTokenDTO = yield call(
      checkAuthFourService,
      phoneConfirmationObject.phone as string,
      oneTimeCode as string,
      cityId,
    )
    if (authToken) {
      yield call(login, authToken)
      const terminalState: TerminalState = yield select((state: State) => state.terminal)
      const terminalAlias: string = terminalState?.terminalAlias
      if (!terminalAlias) {
        yield put(InitializeActions.initializeRequest(true))
      }
      yield put(AuthActions.toggleSendingConfirmationTokenId(false))
      yield call(moveToProfile)
    } else {
      yield put(AuthActions.toggleSendingConfirmationTokenId(false))
    }
  } catch (e) {
    const error = e as ResponseError
    yield delay(700)
    yield put(AuthActions.setOneTimeCodeFailureMessage(error.errorMessage))
    yield put(AuthActions.toggleSendingConfirmationTokenId(false))
  }
}

function* sendTerminalLoginByPhone({payload}: any) {
  yield put(AuthActions.toggleSendingTerminalLoginByPhone(true))
  yield put(AuthActions.setTerminalLoginByPhoneFailureMessage(null))
  const phone = payload.phone
  const moveToProfile = payload.moveToProfile
  const cityId: string = yield select((state: State) => state.city.selectedCity.cityId)
  try {
    const authToken: AuthorizationClientTokenDTO = yield call(terminalLoginByPhone, phone, cityId)
    if (authToken) {
      yield call(login, authToken)
      const terminalState: TerminalState = yield select((state: State) => state.terminal)
      const terminalAlias: string = terminalState?.terminalAlias
      if (!terminalAlias) {
        yield put(InitializeActions.initializeRequest(true))
      }
      yield put(AuthActions.toggleSendingTerminalLoginByPhone(false))
      yield call(moveToProfile)
    } else {
      yield put(AuthActions.toggleSendingTerminalLoginByPhone(false))
    }
  } catch (e) {
    const error = e as ResponseError
    yield delay(700)
    yield put(AuthActions.setTerminalLoginByPhoneFailureMessage(error.errorMessage))
    yield put(AuthActions.toggleSendingTerminalLoginByPhone(false))
  }
}

function* login(authorizationClientToken: AuthorizationClientTokenDTO) {
  yield call(LSMethods.setStorageData, LSKeys.AUTHORIZATION_CLIENT_TOKEN, authorizationClientToken)
  yield put(AuthActions.setClient(authorizationClientToken.client))
  yield put(AuthActions.toggleIsCreatedOneTimeCodeListener(false))
  yield call(LSMethods.setStorageData, LSKeys.CLIENT, authorizationClientToken.client)
}

function* logout({payload}: any) {
  const onRedirect = payload.onRedirect
  yield call(LSMethods.removeItem, LSKeys.CLIENT)
  try {
    const city: CityDTO = yield select((state: State) => state.city.selectedCity)
    yield call(logOutService, city?.cityId)
    yield call(LSMethods.removeItem, LSKeys.AUTHORIZATION_CLIENT_TOKEN)
    yield call(clearBasket, {payload: false})
    yield call(deletePrepareOrder)

    yield put(AuthActions.logOutSuccess())
    onRedirect()
  } catch (error) {
    console.error(error)
    yield put(ErrorActions.setError(error))
  }
}

export {
  sendPhoneNumber,
  sendPhoneConfirmationTokenId,
  sendTerminalLoginByPhone,
  initializeAuth,
  logout,
  saveClientData,
  checkAuthBySixCode,
}
