import {connect, MapStateToPropsParam} from 'react-redux'
import React from 'react'
import {withRouter} from 'react-router-dom'

function connectComponent(
  mapStateToProps: MapStateToPropsParam<any, any, any>,
  actions: any,
  component: React.ComponentClass<any, any> | React.FC<any>,
): any {
  return withRouter(connect(mapStateToProps, actions, null, {pure: true})(component))
}

function connectComponentWithoutState(
  actions: any,
  component: React.ComponentClass<any, any> | React.FC<any>,
) {
  return connect(null, actions, null, {pure: true})(component)
}

function connectComponentWithoutActions(
  mapStateToProps: MapStateToPropsParam<any, any, any>,
  component: React.ComponentClass<any, any> | React.FC<any>,
) {
  return connect(mapStateToProps, null, null, {pure: true})(component)
}

export {connectComponent, connectComponentWithoutState, connectComponentWithoutActions}
