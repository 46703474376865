declare var TYPE_SITE_BUILD: string

export enum ECOMMERCE_ACTION_TYPE {
  DETAIL = 'detail', // просмотр полного описания (карточки) товара
  ADD = 'add', // добавление товара в корзину;
  REMOVE = 'remove', // удаление товара из корзины;
  PURCHASE = 'purchase', //покупка.
}

export interface EcommerceProductType {
  id: string //Обязательное поле для заполнения id или name
  name: string
  price: number
  brand?: string
  category?: string //Категории указываеются через "/", "Одежда/Мужская/Футболки"
  variant?: string
  quantity: number
  position?: number
  coupon?: string
}

export interface EcommerceActionFieldType {
  //Для <actionType>  — purchase
  id: string
  goal_id?: number //Номер цели
  revenue?: number // Полученный доход
  coupon?: string
}

function ecommerceTarget(
  actionType: ECOMMERCE_ACTION_TYPE,
  products: EcommerceProductType[],
  actionField?: EcommerceActionFieldType,
): void {
  if (TYPE_SITE_BUILD === 'production') {
    // @ts-ignore
    window.dataLayer = window.dataLayer || []
    const data: any = {
      ecommerce: {
        [actionType]: {
          products,
          actionField: actionType === ECOMMERCE_ACTION_TYPE.PURCHASE ? actionField : null,
        },
      },
    }
    // @ts-ignore
    window.dataLayer.push(data)
  }
}

export {ecommerceTarget}
