import {bindActionCreators} from 'redux'
import {BaseAction} from '../common/BaseAction'
import {ObjectUrlMap} from 'grilnica-store-share'
import {AliasObjectMap} from '../../types/common/AliasObjectMap'
import {HappyHourResponse} from '../action/types/HappyHourActionMap'

export interface CommonState {
  openedModalId?: 'ERROR_MODAL'
  aliasActionMap: AliasObjectMap
  aliasCityMap: AliasObjectMap
  aliasRestaurantMap: AliasObjectMap
  aliasProductCategoryMap: AliasObjectMap
  objectUrlMap: ObjectUrlMap
  happyHoursActions: HappyHourResponse
  isNotFound: boolean
  redirectUrl: string
}

export enum CommonActionsTypes {
  LOAD_ALIAS_ACTION_MAP = 'LOAD_ALIAS_ACTION_MAP',
  LOAD_ALIAS_ACTION_MAP_SUCCESS = 'LOAD_ALIAS_ACTION_MAP_SUCCESS',
  LOAD_ALIAS_CITY_MAP = 'LOAD_ALIAS_CITY_MAP',
  LOAD_ALIAS_CITY_MAP_SUCCESS = 'LOAD_ALIAS_CITY_MAP_SUCCESS',
  LOAD_ALIAS_RESTAURANT_MAP = 'LOAD_ALIAS_RESTAURANT_MAP',
  LOAD_ALIAS_RESTAURANT_MAP_SUCCESS = 'LOAD_ALIAS_RESTAURANT_MAP_SUCCESS',
  LOAD_ALIAS_PRODUCT_CATEGORY_MAP = 'LOAD_ALIAS_PRODUCT_CATEGORY_MAP',
  LOAD_ALIAS_PRODUCT_CATEGORY_MAP_SUCCESS = 'LOAD_ALIAS_PRODUCT_CATEGORY_MAP_SUCCESS',
  LOAD_OBJECT_URL_MAP = 'LOAD_OBJECT_URL_MAP',
  LOAD_OBJECT_URL_MAP_SUCCESS = 'LOAD_OBJECT_URL_MAP_SUCCESS',
  LOAD_HAPPY_HOURS_ACTIONS_MAP = 'LOAD_HAPPY_HOURS_ACTIONS_MAP',
}

const INITIAL_STATE: CommonState = {
  openedModalId: null,
  objectUrlMap: null,
  aliasActionMap: null,
  aliasCityMap: null,
  aliasRestaurantMap: null,
  aliasProductCategoryMap: null,
  happyHoursActions: null,
  isNotFound: undefined,
  redirectUrl: undefined,
}

export const Actions = {
  loadObjectUrlMap: (): BaseAction => ({
    type: CommonActionsTypes.LOAD_OBJECT_URL_MAP,
  }),
  loadObjectUrlMapSuccess: (objectUrlMap: ObjectUrlMap): BaseAction => ({
    type: CommonActionsTypes.LOAD_OBJECT_URL_MAP_SUCCESS,
    payload: objectUrlMap,
  }),
  loadAliasActionMap: (): BaseAction => ({
    type: CommonActionsTypes.LOAD_ALIAS_ACTION_MAP,
  }),
  loadAliasActionMapSuccess: (aliasActionMap: AliasObjectMap): BaseAction => ({
    type: CommonActionsTypes.LOAD_ALIAS_ACTION_MAP_SUCCESS,
    payload: aliasActionMap,
  }),
  loadAliasCityMap: (): BaseAction => ({
    type: CommonActionsTypes.LOAD_ALIAS_CITY_MAP,
  }),
  loadAliasCityMapSuccess: (aliasCityMap: AliasObjectMap): BaseAction => ({
    type: CommonActionsTypes.LOAD_ALIAS_CITY_MAP_SUCCESS,
    payload: aliasCityMap,
  }),
  loadAliasRestaurantMap: (): BaseAction => ({
    type: CommonActionsTypes.LOAD_ALIAS_RESTAURANT_MAP,
  }),
  loadAliasRestaurantMapSuccess: (aliasRestaurantMap: AliasObjectMap): BaseAction => ({
    type: CommonActionsTypes.LOAD_ALIAS_RESTAURANT_MAP_SUCCESS,
    payload: aliasRestaurantMap,
  }),
  loadAliasProductCategoryMap: (): BaseAction => ({
    type: CommonActionsTypes.LOAD_ALIAS_PRODUCT_CATEGORY_MAP,
  }),
  loadAliasProductCategoryMapSuccess: (aliasProductCategoryMap: AliasObjectMap): BaseAction => ({
    type: CommonActionsTypes.LOAD_ALIAS_PRODUCT_CATEGORY_MAP_SUCCESS,
    payload: aliasProductCategoryMap,
  }),
}

export function bindCommonActions(dispatch: any) {
  return bindActionCreators(Actions, dispatch)
}

export default function common(
  state: CommonState = INITIAL_STATE,
  action: BaseAction,
): CommonState {
  switch (action.type) {
    case CommonActionsTypes.LOAD_ALIAS_ACTION_MAP_SUCCESS:
      return {
        ...state,
        aliasActionMap: action.payload,
      }

    case CommonActionsTypes.LOAD_ALIAS_CITY_MAP_SUCCESS:
      return {
        ...state,
        aliasCityMap: action.payload,
      }

    case CommonActionsTypes.LOAD_ALIAS_RESTAURANT_MAP_SUCCESS:
      return {
        ...state,
        aliasRestaurantMap: action.payload,
      }

    case CommonActionsTypes.LOAD_ALIAS_PRODUCT_CATEGORY_MAP_SUCCESS:
      return {
        ...state,
        aliasProductCategoryMap: action.payload,
      }

    case CommonActionsTypes.LOAD_OBJECT_URL_MAP_SUCCESS:
      return {
        ...state,
        objectUrlMap: action.payload,
      }

    default: {
      return {
        ...state,
      }
    }
  }
}
