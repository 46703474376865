declare var TYPE_SITE_BUILD: string
declare var SELECTED_CITY_ALIAS: string
declare var Y_COUNTER: number

function ymTarget(targetName: string): void {
  try {
    if (TYPE_SITE_BUILD === 'production') {
      let targetNameByCity = targetName
      if (SELECTED_CITY_ALIAS !== 'biysk') {
        if (targetName === 'OPLATA') {
          targetNameByCity = 'OFORMLEN'
        } else if (targetName === 'ZVONOK') {
          targetNameByCity = 'click_phone'
        } else if (targetName === 'OTKRILI_KORZINU') {
          targetNameByCity = targetName
        } else {
          targetNameByCity = null
        }
      }

      let codeByCity = Y_COUNTER
      if (codeByCity && targetNameByCity) {
        // @ts-ignore
        // eslint-disable-next-line no-undef
        ym(codeByCity, 'reachGoal', targetNameByCity)
      }
    }
  } catch (e) {
    console.error(e)
  }
}

export {ymTarget}
