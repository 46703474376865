import React, {ReactNode, useMemo} from 'react'
import {CONSTANTS} from '../../../../../../../../../../../constants'
import {PromoItems} from './PromoItems'
import {LabelDTO, MenuCategoryDTO, ProductMap, WeightMapDto} from 'grilnica-store-share'
import {Link} from 'react-router-dom'
import {ButtonIcon} from 'grilnica-reactstrap'
import {
  carInfoSVG,
  carOffSVG,
  mapMarkerAlertSVG,
} from '../../../../../../../../../components/svg/SystemIcons'
import {WeightBlock} from '../../../../../../../../../components/product/WeightBlock'
import {LazyImage} from '../../../../../../../../../components/lazyImage/LazyImage'
import {ProductPreviewList} from '../preview/ProductPreviewList'
import {ProcessCardItemDTO} from 'grilnica-share'

interface ProductCardBodyProps {
  imageFileName: string
  name: string
  description: string
  weight: string
  href: string
  labels: LabelDTO[]

  isProductRestaurant: boolean
  onOpenProductRestaurant: () => void
  isDeliveryOnly: boolean
  onOpenDeliveryOnly: () => void
  isDeliveryDeny: boolean
  onOpenDeliveryDeny: () => void

  isTerminal: boolean
  processProductItems: ProcessCardItemDTO[]
  productMap: ProductMap
  menuCategoryList: MenuCategoryDTO[]

  renderPromo: ReactNode
  isSaleModal: boolean
  weightMap: WeightMapDto
}

const ProductCardBody: React.FC<ProductCardBodyProps> = ({
  imageFileName,
  name,
  description,
  href,
  labels,
  isProductRestaurant,
  onOpenProductRestaurant,
  isDeliveryOnly,
  onOpenDeliveryOnly,
  isDeliveryDeny,
  onOpenDeliveryDeny,
  weight,
  isTerminal,
  processProductItems,
  productMap,
  menuCategoryList,
  renderPromo,
  isSaleModal,
  weightMap,
}): React.ReactElement => {
  const renderContent: React.ReactElement = useMemo(() => {
    return (
      <div className={'d-flex flex-column'}>
        <Link to={href} className={'image-container ' + (isSaleModal ? 'is-sale-modal' : '')}>
          <LazyImage
            src={CONSTANTS.cdnUrl + '/' + imageFileName}
            typePlaceholder={'product'}
            alt={name}
            title={name}
          />
          <PromoItems labels={labels} />
          {renderPromo}
        </Link>
        <Link to={href} className={'content'}>
          <h3 className={'product-name'}>{name}</h3>
        </Link>
        <div className={'mb-x2 d-flex align-items-center justify-content-between px-x4 w-100'}>
          <WeightBlock weight={weight} className={'text-gray-600 mb-0'} />
          {!isTerminal && (
            <div className={'d-flex'}>
              {isProductRestaurant && (
                <ButtonIcon
                  iconPath={mapMarkerAlertSVG}
                  isHoverArea
                  title={'Доступность'}
                  onClick={onOpenProductRestaurant}
                />
              )}
              {isDeliveryOnly && (
                <ButtonIcon
                  iconPath={carInfoSVG}
                  isHoverArea
                  title={'Информация о доставке'}
                  className={'ml-x4'}
                  onClick={onOpenDeliveryOnly}
                />
              )}
              {isDeliveryDeny && (
                <ButtonIcon
                  iconPath={carOffSVG}
                  isHoverArea
                  title={'Информация о доставке'}
                  className={'ml-x4'}
                  onClick={onOpenDeliveryDeny}
                />
              )}

              {/*//понадобится когда будет информация о калорийности*/}

              {/*<ButtonIcon*/}
              {/*  icon={<Icon path={informationOutlineSVG} />}*/}
              {/*  title={''}*/}
              {/*  hoverIconColor={'area'}*/}
              {/*  size={'xl'}*/}
              {/*  className={'ml-x4'}*/}
              {/*  // onClick={}*/}
              {/*/>*/}
            </div>
          )}
        </div>
        <div className={'content pt-0'}>
          <p className={'description mb-small ' + (isSaleModal ? 'is-sale-modal' : '')}>
            {description}
          </p>
        </div>
        <ProductPreviewList
          processProductItems={processProductItems}
          productMap={productMap}
          menuCategoryList={menuCategoryList}
          weightMap={weightMap}
        />
      </div>
    )
  }, [
    href,
    isSaleModal,
    imageFileName,
    name,
    labels,
    renderPromo,
    isTerminal,
    weight,
    isProductRestaurant,
    onOpenProductRestaurant,
    isDeliveryOnly,
    onOpenDeliveryOnly,
    isDeliveryDeny,
    onOpenDeliveryDeny,
    description,
    processProductItems,
    productMap,
    menuCategoryList,
    weightMap,
  ])
  return <>{renderContent}</>
}

export {ProductCardBody}
