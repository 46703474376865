import React, {useMemo} from 'react'
import {BasketItem} from './BasketItem'
import {Loading} from 'grilnica-reactstrap'
import {SelectedProductDTO, WeightMapDto} from 'grilnica-store-share'

interface BasketProductListProps {
  addCountProduct: (index: number) => void
  onOpenModal: (index: number) => void
  removeCountProduct: (index: number) => void
  selectedProducts: SelectedProductDTO[]
  selectedCouponDefinitionId: string
  isUpdateCheckActions: boolean
  editSelectedProduct: (item: SelectedProductDTO, index: number) => void
  weightMap: WeightMapDto
}

const BasketProductListProps: React.FC<BasketProductListProps> = ({
  addCountProduct,
  onOpenModal,
  removeCountProduct,
  selectedProducts,
  editSelectedProduct,
  isUpdateCheckActions,
  selectedCouponDefinitionId,
  weightMap,
}): React.ReactElement => {
  const renderContent: React.ReactElement[] | React.ReactElement = useMemo(() => {
    if (selectedProducts) {
      const items: React.ReactElement[] = selectedProducts.map((item, index) => (
        <BasketItem
          selectedProduct={item}
          deleteProduct={() => {
            onOpenModal(index)
          }}
          addCountProduct={() => {
            addCountProduct(index)
          }}
          removeCountProduct={() => {
            removeCountProduct(index)
          }}
          editSelectedProduct={() => {
            editSelectedProduct(item, index)
          }}
          selectedCouponDefinitionId={selectedCouponDefinitionId}
          isUpdateCheckActions={isUpdateCheckActions}
          isLastItem={index === (selectedProducts || []).length - 1}
          weightMap={weightMap}
          key={'product' + index + item?.product?.productId}
        />
      ))
      return items
    } else {
      return <Loading />
    }
  }, [
    isUpdateCheckActions,
    selectedCouponDefinitionId,
    editSelectedProduct,
    addCountProduct,
    onOpenModal,
    removeCountProduct,
    selectedProducts,
    weightMap,
  ])
  return <>{renderContent}</>
}

export {BasketProductListProps}
