import {PaymentModalActionsTypes} from './types'
import {BaseAction} from '../../common/BaseAction'
import {PaymentModalState} from '../types/PaymentModalState'

const initialPaymentModalState: PaymentModalState = {
  isOpen: false,
  currentStage: null,
  isClearTimer: false,
  isRedirectToSuccess: false,
  isRedirectToHome: false,
  isRedirectToError: false,
  repairError: undefined,
}

export default function paymentModal(
  state: PaymentModalState = initialPaymentModalState,
  dispatch: BaseAction,
): PaymentModalState {
  switch (dispatch.type) {
    case PaymentModalActionsTypes.SET_IS_OPEN: {
      return {
        ...state,
        isOpen: dispatch.payload,
      }
    }

    case PaymentModalActionsTypes.SET_CURRENT_STAGE: {
      return {
        ...state,
        currentStage: dispatch.payload,
      }
    }

    case PaymentModalActionsTypes.SET_IS_CLEAR_TIMER: {
      return {
        ...state,
        isClearTimer: dispatch.payload,
      }
    }

    case PaymentModalActionsTypes.SET_IS_REDIRECT_TO_SUCCESS: {
      return {
        ...state,
        isRedirectToSuccess: dispatch.payload,
      }
    }

    case PaymentModalActionsTypes.SET_IS_REDIRECT_TO_HOME: {
      return {
        ...state,
        isRedirectToHome: dispatch.payload,
      }
    }

    case PaymentModalActionsTypes.SET_IS_REDIRECT_TO_ERROR: {
      return {
        ...state,
        isRedirectToError: dispatch.payload,
      }
    }

    case PaymentModalActionsTypes.SET_REPAIR_ERROR: {
      return {
        ...state,
        repairError: dispatch.payload,
      }
    }

    // case PaymentModalActionsTypes.CLEAR_DATA_AND_CLOSE: {
    //   return {
    //     ...initialPaymentModalState,
    //   }
    // }

    default: {
      return {
        ...state,
      }
    }
  }
}
