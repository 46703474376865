import React, {Dispatch, useCallback, useMemo, useState} from 'react'
import {Alert, Button, ButtonIcon, Divider, Modal, ModalFooter} from 'grilnica-reactstrap'
import {SupportsMaps} from '../../../../../../../../types/product/SupportsMaps'
import {
  MenuCategoryDTO,
  MenuEntityTypeEnum,
  ProductMap,
  ProductVariantDTO,
  RestaurantByProductDTO,
  SelectedProductDTO,
  WeightMapDto,
} from 'grilnica-store-share'
import {
  ProductItemActions,
  ProductItemActionsTypes,
} from '../../productList/productItem/ducks/ProductItemDuck'
import {ProductOptionsBlock} from './product/ProductOptionsBlock'
import {ComboGroupBlock} from './combo/ComboGroupBlock'
import {ModalProductRestaurantContent} from '../../productList/productItem/components/card/ModalProductRestaurantContent'
import {
  arrowLeftSVG,
  basketSVG,
  carInfoSVG,
  carOffSVG,
  checkCircleOutlineSVG,
  mapMarkerAlertSVG,
} from '../../../../../../../components/svg/SystemIcons'
import {ProductCountButtons} from '../../../../../basket/components/ProductCountButtons'
import {WeightBlock} from '../../../../../../../components/product/WeightBlock'
import {ProductPreviewBlock} from './productPreview/ProductPreviewBlock' //TODO:: weight

interface ProductInfoProps {
  selectedProduct: SelectedProductDTO
  supportsMaps: SupportsMaps
  toggleOption: (
    optionCategoryId: string,
    optionId: string,
    isChecked?: boolean,
    isOptional?: boolean,
  ) => void
  goBack?: () => void
  sendToBasket: () => void
  isSave: boolean
  toggleComboProduct: (
    comboGroupId: string,
    comboProductId: string,
    selectedProduct: SelectedProductDTO,
  ) => void
  restaurants: RestaurantByProductDTO[]
  weight: string //TODO:: weight
  isTerminal: boolean
  setProductState: Dispatch<ProductItemActions>
  isForSale?: boolean
  productMap: ProductMap
  menuCategoryList: MenuCategoryDTO[]
  weightMap: WeightMapDto
}

const ProductInfo: React.FC<ProductInfoProps> = ({
  selectedProduct,
  supportsMaps,
  toggleOption,
  goBack,
  sendToBasket,
  isSave,
  toggleComboProduct,
  restaurants,
  weight, //TODO:: weight
  isTerminal,
  setProductState,
  isForSale,
  productMap,
  menuCategoryList,
  weightMap,
}): React.ReactElement => {
  const [isOpenDeliveryDeny, setIsOpenDeliveryDeny] = useState<boolean>(false)
  const [isOpenDeliveryOnly, setIsOpenDeliveryOnly] = useState<boolean>(false)
  const [isOpenProductRestaurant, setIsOpenProductRestaurant] = useState<boolean>(false)

  const setCountProduct = useCallback(
    (count) => {
      setProductState({
        type: ProductItemActionsTypes.SET_SELECTED_PRODUCT_COUNT,
        payload: count,
      })
    },
    [setProductState],
  )

  const renderAccessRestaurantModal = useMemo(() => {
    if (restaurants) {
      return (
        <Modal
          size={'xs'}
          className={' p-x4'}
          isOpen={isOpenProductRestaurant}
          onClose={() => {
            setIsOpenProductRestaurant(false)
          }}>
          <ModalProductRestaurantContent restaurants={restaurants} />
          <ModalFooter className={'p-0'}>
            <Button
              color={'primary'}
              size={'sm'}
              style={'text'}
              isRoundedButton
              onClick={() => {
                setIsOpenProductRestaurant(false)
              }}>
              ОК
            </Button>
          </ModalFooter>
        </Modal>
      )
    }
  }, [isOpenProductRestaurant, restaurants])

  const renderDeliveryDenyModal = useMemo(() => {
    return (
      <Modal
        size={'xs'}
        className={' p-x4'}
        isOpen={isOpenDeliveryDeny}
        onClose={() => {
          setIsOpenDeliveryDeny(false)
        }}>
        <div className={'mb-x4'}>
          <span>Данный товар недоступен для доставки, но вы можете забрать его самостоятельно</span>
        </div>

        <div className={'d-flex justify-content-end'}>
          <Button
            color={'primary'}
            size={'sm'}
            style={'text'}
            isRoundedButton
            onClick={() => {
              setIsOpenDeliveryDeny(false)
            }}>
            ОК
          </Button>
        </div>
      </Modal>
    )
  }, [isOpenDeliveryDeny])

  const renderDeliveryOnlyModal = useMemo(() => {
    return (
      <Modal
        size={'xs'}
        className={' p-x4'}
        isOpen={isOpenDeliveryOnly}
        onClose={() => {
          setIsOpenDeliveryOnly(false)
        }}>
        <div className={'mb-x4'}>
          <span>Эксклюзивно для доставки. Данный товар недоступен для самовывоза.</span>
        </div>
        <div className={'d-flex justify-content-end'}>
          <Button
            color={'primary'}
            size={'sm'}
            style={'text'}
            isRoundedButton
            onClick={() => {
              setIsOpenDeliveryOnly(false)
            }}>
            ОК
          </Button>
        </div>
      </Modal>
    )
  }, [isOpenDeliveryOnly])

  const renderDescription: React.ReactElement = useMemo(() => {
    const {name, description, menuCategoryName} = selectedProduct.product
    return (
      <>
        <div className={'go-all-product d-flex align-items-center mb-x3'}>
          {!isTerminal && (
            <ButtonIcon
              isHoverArea
              iconPath={arrowLeftSVG}
              title={'Назад'}
              className={'mr-x3'}
              onClick={() => {
                goBack()
              }}
            />
          )}
          {isForSale ? (
            <p className={'btn-back-title'}>{menuCategoryName} </p>
          ) : (
            <h1 itemProp={'name'} className={'product-name mb-0'}>
              {name}
            </h1>
          )}
        </div>
        {isForSale && (
          <h1 itemProp={'name'} className={'product-name mb-x2'}>
            {name}
          </h1>
        )}
        <p itemProp={'description'} className={'product-description'}>
          {description}
        </p>
        <WeightBlock weight={weight} className={'text-gray-700 mb-x3'} />
      </>
    )
  }, [goBack, isForSale, isTerminal, selectedProduct.product, weight])

  const renderIcons: React.ReactElement = useMemo(() => {
    return (
      <div className={'d-flex mb-x6'}>
        <ButtonIcon
          iconPath={mapMarkerAlertSVG}
          isHoverArea
          title={'Доступность'}
          className={'mr-x6'}
          onClick={() => {
            setIsOpenProductRestaurant(true)
          }}
        />
        {selectedProduct.product.isDeliveryDeny && (
          <ButtonIcon
            iconPath={carOffSVG}
            isHoverArea
            title={'Доставка'}
            onClick={() => {
              setIsOpenDeliveryDeny(true)
            }}
          />
        )}
        {selectedProduct.product.isDeliveryOnly && (
          <ButtonIcon
            iconPath={carInfoSVG}
            isHoverArea
            title={'Доставка'}
            onClick={() => {
              setIsOpenDeliveryOnly(true)
            }}
          />
        )}
      </div>
    )
  }, [selectedProduct.product.isDeliveryDeny, selectedProduct.product.isDeliveryOnly])

  const renderFooter: React.ReactElement = useMemo(() => {
    const {count, price, product} = selectedProduct
    let salePrice: number = null
    if (product.productVariants?.length > 0) {
      let productVariant: ProductVariantDTO = null
      for (let itemPV of product.productVariants) {
        if (itemPV.productVariantId === selectedProduct.productVariantId) {
          productVariant = itemPV
          break
        }
      }
      salePrice = productVariant?.salePrice
        ? price - (productVariant.price - productVariant.salePrice)
        : null
    } else {
      salePrice = product.salePrice ? price - (product.price - product.salePrice) : null
    }

    return (
      <div className={'d-flex justify-content-between'}>
        <ProductCountButtons
          addCountProduct={() => {
            setCountProduct(count + 1)
          }}
          maxCountInOrder={selectedProduct.maxCountInOrder}
          removeCountProduct={() => {
            setCountProduct(count - 1)
          }}
          isNotForSale={!isForSale}
          count={selectedProduct.count}
        />
        <div className={'d-flex flex-row align-items-center'}>
          {salePrice && (
            <p className={'sale-price-through mb-0 mr-x2'}>{count * price + ' '}&#8381;</p>
          )}
          <Button
            itemProp={'offers'}
            itemScope
            itemType={'http://schema.org/Offer'}
            color={salePrice ? 'sale' : 'primary'}
            size={isTerminal ? 'md' : 'sm'}
            iconPath={isSave ? checkCircleOutlineSVG : basketSVG}
            iconPosition={'left'}
            isRoundedButton
            disabled={Object.keys(supportsMaps.extraOptionsMap || {}).length !== 0 || !isForSale}
            onClick={sendToBasket}>
            <span
              itemProp={'price'}
              // @ts-ignore
              content={count * (salePrice ? salePrice : price)}>
              {count * (salePrice ? salePrice : price)}
              <span
                itemProp={'priceCurrency'}
                // @ts-ignore
                content={'RUB'}
                className={'ml-x1'}>
                &#8381;
              </span>
            </span>
          </Button>
        </div>
      </div>
    )
  }, [
    isForSale,
    isSave,
    isTerminal,
    selectedProduct,
    sendToBasket,
    setCountProduct,
    supportsMaps.extraOptionsMap,
  ])

  const renderContent: React.ReactElement = useMemo(() => {
    return (
      <div className={'product-info'}>
        {renderDescription}
        {!isTerminal && isForSale && renderIcons}
        <div>
          {selectedProduct.product.type === MenuEntityTypeEnum.PRODUCT ? (
            <ProductOptionsBlock
              supportsMaps={supportsMaps}
              toggleOption={toggleOption}
              isNotForSale={!isForSale}
            />
          ) : (
            <ComboGroupBlock
              comboGroupList={selectedProduct.product.comboGroupList}
              toggleComboProduct={toggleComboProduct}
              selectedProducts={selectedProduct.selectedProducts}
              weightMap={weightMap}
            />
          )}
        </div>
        {selectedProduct.product.isShowPreview && (
          <ProductPreviewBlock
            processProductItems={selectedProduct.product.processProductItems}
            productMap={productMap}
            menuCategoryList={menuCategoryList}
            weightMap={weightMap}
          />
        )}
        {!isForSale && (
          <Alert
            message={'Данный товар не доступен для розничной продажи'}
            color={'danger'}
            iconPosition={'start'}
            className={'mb-x6'}
          />
        )}
        <Divider className={'mb-primary'} />
        {renderFooter}
        {!isTerminal && isForSale && renderAccessRestaurantModal}
        {!isTerminal && isForSale && renderDeliveryDenyModal}
        {!isTerminal && isForSale && renderDeliveryOnlyModal}
      </div>
    )
  }, [
    renderDescription,
    isTerminal,
    isForSale,
    renderIcons,
    selectedProduct.product.type,
    selectedProduct.product.comboGroupList,
    selectedProduct.product.isShowPreview,
    selectedProduct.product.processProductItems,
    selectedProduct.selectedProducts,
    supportsMaps,
    toggleOption,
    toggleComboProduct,
    productMap,
    menuCategoryList,
    renderFooter,
    renderAccessRestaurantModal,
    renderDeliveryDenyModal,
    renderDeliveryOnlyModal,
    weightMap,
  ])
  return <>{renderContent}</>
}

export {ProductInfo}
