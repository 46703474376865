import {bindActionCreators} from 'redux'
import {BaseAction} from '../common/BaseAction'
import {ActionDTO, MenuCategoryDTO, ProductDTO, RestaurantByProductMap} from 'grilnica-store-share'
import {AreaRegionOfDeliveryDTO} from '../../types/address/AreaRegionOfDeliveryDTO'
import {RestaurantDTO} from 'grilnica-share'
import {ErrorInitializeEnum} from '../../types/common/ErrorInitializeEnum'

export interface InitializeState {
  isInitialized?: boolean
  urlPrefix?: string
  errorInitialize?: ErrorInitializeEnum

  productList?: ProductDTO[]
  restaurantByProductMap?: RestaurantByProductMap
  newCategoryList?: MenuCategoryDTO[]
  categories?: MenuCategoryDTO[]
  productItemMapByProduct?: any
  areaOfDeliveryList?: AreaRegionOfDeliveryDTO[]
  salesList?: ActionDTO[]
  sale?: ActionDTO
  restaurantList?: RestaurantDTO[]
  restaurant?: RestaurantDTO

  isInitializedTerminal?: boolean
}

export enum InitializeActionsTypes {
  INITIALIZE_REQUEST = 'initialize/INITIALIZE_REQUEST',
  TOGGLE_INITIALIZE = 'initialize/INITIALIZE_SUCCESS',
  START_BACKGROUND_UPDATE_REQUEST = 'initialize/START_BACKGROUND_UPDATE_REQUEST',
  SET_ERROR_INITIALIZE = 'initialize/SET_ERROR_INITIALIZE',

  INITIALIZE_TERMINAL_REQUEST = 'initialize/INITIALIZE_TERMINAL_REQUEST',
  TOGGLE_INITIALIZE_TERMINAL = 'initialize/TOGGLE_INITIALIZE_TERMINAL',
}

const INITIAL_STATE: InitializeState = {
  isInitialized: false,
}

export const Actions = {
  initializeRequest: (isAfterAuth?: boolean): BaseAction => ({
    type: InitializeActionsTypes.INITIALIZE_REQUEST,
    payload: {isAfterAuth},
  }),

  toggleInitialize: (isInitialize: boolean): BaseAction => ({
    type: InitializeActionsTypes.TOGGLE_INITIALIZE,
    payload: isInitialize,
  }),

  initializeTerminalRequest: (): BaseAction => ({
    type: InitializeActionsTypes.INITIALIZE_TERMINAL_REQUEST,
  }),

  toggleInitializeTerminal: (isInitializeTerminal: boolean): BaseAction => ({
    type: InitializeActionsTypes.TOGGLE_INITIALIZE_TERMINAL,
    payload: isInitializeTerminal,
  }),

  startBackgroundUpdate: (): BaseAction => ({
    type: InitializeActionsTypes.START_BACKGROUND_UPDATE_REQUEST,
  }),

  setErrorInitialize: (errorInitialize: ErrorInitializeEnum): BaseAction => ({
    type: InitializeActionsTypes.SET_ERROR_INITIALIZE,
    payload: errorInitialize,
  }),
}

export function bindInitializeActions(dispatch: any) {
  return bindActionCreators(Actions, dispatch)
}

export default function initialize(
  state: InitializeState = INITIAL_STATE,
  action: BaseAction,
): InitializeState {
  switch (action.type) {
    case InitializeActionsTypes.TOGGLE_INITIALIZE:
      return {
        ...state,
        isInitialized: action.payload,
      }

    case InitializeActionsTypes.TOGGLE_INITIALIZE_TERMINAL:
      return {
        ...state,
        isInitializedTerminal: action.payload,
      }

    case InitializeActionsTypes.SET_ERROR_INITIALIZE:
      return {
        ...state,
        errorInitialize: action.payload,
      }

    default:
      return state
  }
}
