import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {Container} from 'grilnica-reactstrap'
import {CommunicationMethodCard} from './CommunicationMethodCard'
import {instagramSVG, vkSVG, youtubeSVG} from '../../../components/svg'
import {CONSTANTS} from '../../../../../constants'
import {CityDTO, RestaurantDTO} from 'grilnica-share'
import {loadRestaurantListByCityIdService} from '../../../../services/restaurant'
import {connectComponent} from '../../../../store/common'
import {State} from '../../../../store/ducks'
import {QualityControlForm} from './QualityControlForm'
import {bindErrorActions} from '../../../../store/ducks/error'
import {
  loadQualityControlLinkTelegramService,
  loadQualityControlLinkWhatsAppService,
  sendQualityControlService,
} from '../../../../services/qualityControl'

export interface QualityControl {
  userName?: string
  comment?: string
  restaurantId?: string
  cityId?: string
  email?: string
  phoneNumber?: string
}

interface QualityControlPageProps {
  selectedCity: CityDTO
  initRestaurantList: RestaurantDTO[]
  errorActions: any
}

const QualityControlPage: React.FC<QualityControlPageProps> = ({
  selectedCity,
  initRestaurantList,
  errorActions,
}): React.ReactElement => {
  const initQualityControl = useCallback((): QualityControl => {
    return {
      userName: null,
      comment: null,
      restaurantId: null,
      cityId: selectedCity.cityId,
      email: null,
      phoneNumber: null,
    }
  }, [selectedCity])
  const [isOpenCollapse, setIsOpenCollapse] = useState<boolean>(false)
  const [sendingRequestStatus, setSendingRequestStatus] = useState<
    'success' | 'waiting' | 'running'
  >('waiting')
  const [restaurantList, setRestaurantList] = useState<RestaurantDTO[]>(initRestaurantList)
  const [files, setFiles] = useState<File[]>(null)
  const [qualityControl, setQualityControl] = useState<QualityControl>(initQualityControl)
  const [isActiveCommunicationCard, setIsActiveCommunicationCard] = useState<
    'site' | 'whatsApp' | 'mail' | 'telegram'
  >('site')

  const [qualityControlLinkTelegram, setQualityControlLinkTelegram] = useState<string | undefined>(
    undefined,
  )
  const [qualityControlLinkWhatsApp, setQualityControlLinkWhatsApp] = useState<string | undefined>(
    undefined,
  )

  const loadRestaurantList = useCallback(async () => {
    const restaurantListDB: RestaurantDTO[] = await loadRestaurantListByCityIdService(
      selectedCity.cityId,
    )
    setRestaurantList(restaurantListDB)
  }, [selectedCity.cityId])

  const loadQualityControlLinkTelegram = useCallback(async () => {
    const qualityControlLinkTelegramDB: string = await loadQualityControlLinkTelegramService(
      selectedCity.cityId,
    )
    setQualityControlLinkTelegram(qualityControlLinkTelegramDB)
  }, [selectedCity.cityId])

  const loadQualityControlLinkWhatsApp = useCallback(async () => {
    const qualityControlLinkWhatsAppDB: string = await loadQualityControlLinkWhatsAppService(
      selectedCity.cityId,
    )
    setQualityControlLinkWhatsApp(qualityControlLinkWhatsAppDB)
  }, [selectedCity.cityId])

  useEffect(() => {
    loadRestaurantList()
    loadQualityControlLinkTelegram()
    loadQualityControlLinkWhatsApp()
  }, [loadRestaurantList, loadQualityControlLinkTelegram, loadQualityControlLinkWhatsApp])

  const toggleCollapse = useCallback(() => {
    setIsOpenCollapse(!isOpenCollapse)
  }, [isOpenCollapse])

  const handleChangeFiles = useCallback((value: any) => {
    let length: number = value.length
    let fileList: File[] = []
    for (let i = 0; i < length; i++) {
      fileList.push(value[i])
    }
    setFiles(fileList)
  }, [])

  const handleChange = useCallback(
    (key: string, value: any) => {
      let newQualityControl = {...qualityControl}
      newQualityControl[key] = value
      setQualityControl(newQualityControl)
      setSendingRequestStatus('waiting')
    },
    [qualityControl, setQualityControl],
  )

  const handleSubmit = useCallback(async () => {
    setSendingRequestStatus('running')
    let formData = new FormData()
    if (files) {
      for (let file of files) {
        formData.append('file', file)
      }
    }

    let url: any = new URL(CONSTANTS.apiStoreUrl + '/quality-control/send-mail')
    url.searchParams.append('cityId', qualityControl.cityId)
    qualityControl.userName && url.searchParams.append('userName', qualityControl.userName)
    qualityControl.comment && url.searchParams.append('comment', qualityControl.comment)
    qualityControl.restaurantId &&
      url.searchParams.append('restaurantId', qualityControl.restaurantId)
    qualityControl.email && url.searchParams.append('email', qualityControl.email)
    qualityControl.phoneNumber && url.searchParams.append('phoneNumber', qualityControl.phoneNumber)

    const json = await sendQualityControlService(url, formData, errorActions, selectedCity?.cityId)
    if (json?.message === 'success') {
      setSendingRequestStatus('success')
      setQualityControl(initQualityControl)
    } else {
      setSendingRequestStatus('waiting')
    }
  }, [
    files,
    qualityControl.cityId,
    qualityControl.userName,
    qualityControl.comment,
    qualityControl.restaurantId,
    qualityControl.email,
    qualityControl.phoneNumber,
    errorActions,
    selectedCity?.cityId,
    initQualityControl,
  ])

  const renderChooseCommunicationMethod = useMemo(() => {
    return (
      <React.Fragment>
        <h2 className={'h2 mb-x6'}>Выберите удобный для вас способ связи</h2>
        <div className={'mb-x8 d-flex'}>
          <CommunicationMethodCard
            isActive={isActiveCommunicationCard === 'site'}
            text={'Связаться через сайт'}
            icon={'site'}
            onClick={async () => {
              setIsActiveCommunicationCard('site')
            }}
          />
          {qualityControlLinkWhatsApp && (
            <CommunicationMethodCard
              isActive={isActiveCommunicationCard === 'whatsApp'}
              text={'WhatsApp'}
              icon={'whatsApp'}
              onClick={() => {
                setIsActiveCommunicationCard('whatsApp')
                window.open(qualityControlLinkWhatsApp)
              }}
            />
          )}
          {/*<CommunicationMethodCard*/}
          {/*  isActive={isActiveCommunicationCard === 'mail'}*/}
          {/*  text={'Написать на почту'}*/}
          {/*  icon={'mail'}*/}
          {/*  onClick={() => {*/}
          {/*    setIsActiveCommunicationCard('mail')*/}
          {/*    window.location.assign('mailto:obs@grilnica.ru')*/}
          {/*  }}*/}
          {/*/>*/}
          {qualityControlLinkTelegram && (
            <CommunicationMethodCard
              isActive={isActiveCommunicationCard === 'telegram'}
              text={'Телеграмм'}
              icon={'telegram'}
              onClick={() => {
                setIsActiveCommunicationCard('telegram')
                window.open(qualityControlLinkTelegram)
              }}
            />
          )}
        </div>
      </React.Fragment>
    )
  }, [isActiveCommunicationCard, qualityControlLinkTelegram, qualityControlLinkWhatsApp])

  const renderSocialBlock: React.ReactElement = useMemo(() => {
    if (
      !!selectedCity &&
      (selectedCity?.youtubeUrl || selectedCity?.vkUrl || selectedCity?.instagramUrl)
    ) {
      return (
        <div className={'quality-social'}>
          {selectedCity.youtubeUrl && (
            <a className={'mr-x4'} title={'Youtube'} href={selectedCity.youtubeUrl}>
              {youtubeSVG}
            </a>
          )}
          {selectedCity.instagramUrl && (
            <a href={selectedCity.instagramUrl} title={'Instagram'} className={'mr-x4'}>
              {instagramSVG}
            </a>
          )}
          {selectedCity.vkUrl && (
            <a href={selectedCity.vkUrl} title={'VK'}>
              {vkSVG}
            </a>
          )}
        </div>
      )
    } else {
      return null
    }
  }, [selectedCity])

  const renderContent: React.ReactElement = useMemo(() => {
    return (
      <Container size={'normal'} className={'mt-x10 mb-x10'}>
        <section>
          <h1 className={'h1 text-center mb-x4'}>Контроль качества</h1>
          <p className={'about-description mb-x8'}>
            Уважаемые клиенты, если у вас есть предложения и замечания по нашей работе, или вы не
            удовлетворены качеством нашего сервиса, вы можете передать информацию непосредственно
            менеджеру отдела контроля качества, используя форму ниже.
          </p>
          {renderChooseCommunicationMethod}
          {isActiveCommunicationCard === 'site' && (
            <QualityControlForm
              restaurantList={restaurantList}
              qualityControl={qualityControl}
              handleSubmit={handleSubmit}
              handleChange={handleChange}
              handleChangeFiles={handleChangeFiles}
              toggleCollapse={toggleCollapse}
              isOpenCollapse={isOpenCollapse}
              sendingRequestStatus={sendingRequestStatus}
            />
          )}

          <div className={'about-description'}>
            <p className={'mb-x4'}>
              Для оперативного решения проблем просим вас позвонить в службу контроля качества по
              телефону{' '}
              <a className={'color-link-text'} href={'tel:8 (800) 100-82-90'}>
                8 (800) 100-82-90
              </a>
            </p>
            <p className={'mb-x4'}>
              В целях повышения уровня контроля качества, а также детального и объективного разбора
              обращений, все претензии к продукции, требующие обмена или возврата денег, принимаются
              при условии возврата не менее 50% порции проблемного продукта. А также при выявлении
              недочетов в блюде просьба, произвести фото или видео фиксацию проблемного блюда. В
              случае подтверждения заявленной проблемы вам на выбор будет произведен возврат
              средств, либо замена продукта. Мы всегда готовы пойти навстречу клиенту.
            </p>
            <p className={'mb-x4'}>Надеемся на ваше понимание.</p>
            <p className={'mb-x4'}>
              В наших группах вы можете узнать больше информации о нас и о нашей продукции.
            </p>
            {renderSocialBlock}
          </div>
        </section>
      </Container>
    )
  }, [
    renderChooseCommunicationMethod,
    isActiveCommunicationCard,
    restaurantList,
    qualityControl,
    handleSubmit,
    handleChange,
    handleChangeFiles,
    toggleCollapse,
    isOpenCollapse,
    sendingRequestStatus,
    renderSocialBlock,
  ])

  return <React.Fragment>{renderContent}</React.Fragment>
}
export default connectComponent(
  (state: State) => ({
    selectedCity: state.city.selectedCity,
    initRestaurantList: state.initialize.restaurantList,
  }),
  (dispatch) => ({
    errorActions: bindErrorActions(dispatch),
  }),
  QualityControlPage,
)
