import React, {useMemo} from 'react'
import {Col, Divider, Loading, Row} from 'grilnica-reactstrap'
import {ActionDTO} from 'grilnica-store-share'
import {ProductDTO} from 'grilnica-store-share/lib/product/ProductDTO'
import {SalePageInfo} from './SalePageInfo'
import {SalePageImage} from './SalePageImage'
import SalePageProductList from './SalePageProductList'
import {exportVariantSVG} from '../../../../../components/svg/SystemIcons'

interface SalePageContentProps {
  sale: ActionDTO
  productList: ProductDTO[]
  goBack: () => void
}

const SalePageContent: React.FC<SalePageContentProps> = ({
  sale,
  productList,
  goBack,
}): React.ReactElement => {
  const renderContent: React.ReactElement = useMemo(() => {
    return (
      <section>
        {sale ? (
          <Row>
            <Col xl={6} lg={6} md={5} sm={12} xs={12}>
              <SalePageImage imageFileName={sale.infoImageFilenameLight} name={sale.name} />
            </Col>
            <Col xl={6} lg={6} md={7} sm={12} xs={12}>
              <SalePageInfo sale={sale} goBack={goBack} />
              {productList && (
                <SalePageProductList
                  colSize={{
                    xl: 6,
                    lg: 6,
                    md: 6,
                    sm: 6,
                    xs: 12,
                  }}
                  productList={productList}
                />
              )}
              <Divider className={'mb-x8'} />
              {/*Понадобится немного позже*/}
              {/*<div className={'d-flex justify-content-end'}>*/}
              {/*  <Button*/}
              {/*    color={'primary'}*/}
              {/*    style={'outline'}*/}
              {/*    size={'sm'}*/}
              {/*    iconPath={exportVariantSVG}*/}
              {/*    iconPosition={'right'}*/}
              {/*    isRoundedButton>*/}
              {/*    Поделиться*/}
              {/*  </Button>*/}
              {/*</div>*/}
            </Col>
          </Row>
        ) : (
          <Loading />
        )}
      </section>
    )
  }, [goBack, productList, sale])
  return <>{renderContent}</>
}

export {SalePageContent}
