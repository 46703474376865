import {apiGet} from '../../api'
import {CONSTANTS} from '../../../constants'

async function loadObjectUrlMapService(cityId: string, errorActions?: any) {
  try {
    const url: any = new URL(CONSTANTS.apiStoreUrl + '/site/objectUrl/url-map')
    url.searchParams.append('cityId', cityId)
    return await apiGet(url, {
      cityId: cityId,
    })
  } catch (error) {
    if (errorActions) {
      errorActions.setError(error)
    } else {
      console.error('loadObjectUrlMapService error')
      throw error
    }
  }
}

export {loadObjectUrlMapService}
