import {OptionCategoryDTO, ProductMap} from 'grilnica-store-share'
import {OrderProductDTO} from 'grilnica-store-share/lib/order/OrderProductDTO'
import {ProductDTO} from 'grilnica-store-share/lib/product/ProductDTO'
import {getProductById} from '../../../../../utils/order/getProductById'
import {OptionCategoryMap} from '../../../../../types/product/OptionCategoryMap'
import {OptionMap} from '../../../../../types/product/OptionMap'
import {SelectedOptionsMap} from '../../../../../types/product/SelectedOptionsMap'
import {getOptionMap} from './getOptionMap'
import {getOptionCategoryMap} from './getOptionCategoryMap'
import {getSelectedOptionsMap} from './getSelectedOptionsMap'
import {getSelectedOptions} from './getSelectedOptions'
import {getSelectedProductPrice} from './getSelectedProductPrice'
import {getSumPriceOptions} from './getSumPriceOptions'
import {ResponseError} from 'grilnica-share'
import {SelectedProductDTO} from '../../../../../types/product/SelectedProductDTO'
import {getFullPriceSelectedProduct} from '../../../basket/utils/getFullPriceSelectedProduct'
import v4 from 'uuid/v4'

function getSelectedProduct(product: OrderProductDTO, productMap: ProductMap): SelectedProductDTO {
  let selectedProduct: SelectedProductDTO = {
    product: null,
    productId: product.productId,
    count: product.count,
    price: product.price,
    productVariantId: product.productVariantId,
    selectedProductId: v4(),
  }

  //получить продукт
  let productDB: ProductDTO = getProductById(product.productId, productMap)
  selectedProduct.product = productDB

  if (!productDB) {
    throw new ResponseError(409, 'Невозможно повторить заказ')
  }

  if (product.count > productDB.maxCountInOrder) {
    let errorMessage: string = `Не удалось добавить. Для "${product.productName}" превышен лимит в корзине.`
    throw new ResponseError(409, errorMessage)
  }
  selectedProduct.maxCountInOrder = productDB.maxCountInOrder

  let optionIds: string[] = []
  if (product.options) {
    optionIds = product.options.map((option) => option.optionId)
  } else if (product.optionIds) {
    optionIds = product.optionIds
  }

  //получить вспомогательную информацию по опциям продукта
  const optionCategories: OptionCategoryDTO[] = productDB.optionCategories || []
  const optionCategoryMap: OptionCategoryMap = getOptionCategoryMap(optionCategories)
  const optionMap: OptionMap = getOptionMap(optionCategories)
  const selectedOptionsMap: SelectedOptionsMap = getSelectedOptionsMap(optionCategories, optionIds)

  // получить выбранные опции для продукта
  selectedProduct.selectedOptions = getSelectedOptions(
    selectedOptionsMap,
    optionCategoryMap,
    optionMap,
  )

  // обновить цену опций
  selectedProduct.allOptionPrice = getSumPriceOptions(
    selectedOptionsMap,
    optionCategoryMap,
    optionMap,
  )

  // обновить цену
  selectedProduct.price = getSelectedProductPrice(
    selectedOptionsMap,
    optionCategoryMap,
    optionMap,
    productDB,
  )
  //получить полную цену для превью корзины
  selectedProduct.fullPrice = getFullPriceSelectedProduct(selectedProduct)

  return selectedProduct
}

export {getSelectedProduct}
