import React from 'react'
import {CONSTANTS} from '../../../../../../constants'
import {Img} from 'react-image'
import {getStaticUrl} from '../../../../../utils/static'

interface RestaurantCardImageProps {
  imageFileName: string
  className?: string
  width?: number
  height?: number
  alt?: string
  placeholder?: React.ReactElement
  id?: string
}

const CardImage: React.FC<RestaurantCardImageProps> = ({
  imageFileName,
  className,
  height,
  width,
  alt,
  placeholder,
  id,
}): React.ReactElement => {
  return (
    <Img
      id={id}
      src={CONSTANTS.cdnUrl + '/' + imageFileName}
      width={width ? width : '100%'}
      height={height ? height : 'auto'}
      alt={alt}
      title={alt}
      className={' flex-grow-0 card-image ' + (className ? className : '')}
      unloader={
        placeholder ? (
          placeholder
        ) : (
          <img
            src={getStaticUrl('/img/placeholder.svg')}
            className={' flex-grow-0 card-image ' + (className ? className : '')}
            alt={alt}
            title={alt}
            width={width ? width : '100%'}
            height={height ? height : 'auto'}
          />
        )
      }
    />
  )
}

export {CardImage}
