import {call, fork, put, select} from 'redux-saga/effects'
import {RecommendationsProductActions} from '../duck'
import {SelectedProductDTO} from '../../../types/product/SelectedProductDTO'
import {State} from '../../ducks'
import {CityDTO} from 'grilnica-share'
import {
  MenuEntityTypeEnum,
  ProductDTO,
  ProductMap,
  RecommendationProductMap,
} from 'grilnica-store-share'
import {Actions as ErrorActions} from '../../ducks/error'
import {getRecommendationProductMapService} from '../../../services/recommendationsProduct'
import {
  addEcommerceProduct,
  checkActions,
  setSelectedProducts,
  toggleProductAddPopover,
} from '../../sagas/basket/basket'
import {ymTarget} from '../../../utils/metrica/ym/ymTarget'
import {deletePrepareOrder} from '../../sagas/order/order'
import {getSelectedProductByProduct} from '../../sagas/order/utils/product/getSelectedProductByProduct'

function* getRecommendationProductMap(selectedProducts: SelectedProductDTO[], cityId: string) {
  try {
    let products: ProductDTO[] = []
    for (let selectedProduct of selectedProducts) {
      switch (selectedProduct.product.type) {
        case MenuEntityTypeEnum.PRODUCT:
          {
            products.push({
              ...selectedProduct.product,
            })
          }
          break
      }
    }
    const response: RecommendationProductMap = yield call(getRecommendationProductMapService, {
      products,
      cityId,
    })
    return response
  } catch (error) {
    console.log('Error getRecommendationsProductMap')
    yield put(ErrorActions.setError(error))
    throw error
  }
}

function* checkRecommendationsProducts() {
  const selectedProducts: SelectedProductDTO[] = yield select(
    (state: State) => state.basket.selectedProducts,
  )
  const city: CityDTO = yield select((state: State) => state.city.selectedCity)

  if (selectedProducts?.length > 0) {
    yield put(RecommendationsProductActions.setIsRequest(true))

    try {
      const recommendationProductMap: RecommendationProductMap = yield call(
        getRecommendationProductMap,
        selectedProducts,
        city.cityId,
      )

      yield put(
        RecommendationsProductActions.setRecommendationsProductMap({...recommendationProductMap}),
      )
    } catch (error) {
      yield put(ErrorActions.setError(error))
      console.log('Error recommendationsProduct/checkRecommendationsProducts', error)
    } finally {
      yield put(RecommendationsProductActions.setIsRequest(false))
    }
  }
}

function* addRecommendationProductInBasket({payload}: any) {
  const product: ProductDTO = payload.product
  const productMap: ProductMap = yield select((state: State) => state.menu.productMap)
  const oldSelectedProducts: SelectedProductDTO[] = yield select(
    (state: State) => state.basket.selectedProducts,
  )

  let selectedProducts: SelectedProductDTO[] = []
  let newSelectedProducts: SelectedProductDTO = yield call(
    getSelectedProductByProduct,
    product,
    productMap,
  )

  selectedProducts.push(newSelectedProducts)

  //отправить в корзину
  yield call(deletePrepareOrder)
  yield call(ymTarget, 'DOBAVILI_V_KORZINU')
  yield call(addEcommerceProduct, [...selectedProducts])
  yield call(setSelectedProducts, [...oldSelectedProducts, ...selectedProducts])

  yield fork(toggleProductAddPopover, 'Товар добавлен в корзину')
  yield fork(checkRecommendationsProducts)
  yield fork(checkActions, {payload: {}})
}

export {checkRecommendationsProducts, addRecommendationProductInBasket}
