import React, {Component} from 'react'
import {Redirect, Route} from 'react-router-dom'
import {LSKeys, LSMethods} from '../../../storage'

interface Props {
  component: any

  [key: string]: any
}

class AuthorizedRoute extends Component<Props> {
  render() {
    const {component} = this.props
    let rest = {...this.props}
    delete rest.component
    return (
      <Route
        {...rest}
        render={(props) => {
          return LSMethods.getStorageData(LSKeys.AUTHORIZATION_CLIENT_TOKEN) ? (
            typeof component === 'function' ? (
              component(rest)
            ) : (
              <Component {...props} />
            )
          ) : (
            <Redirect to={'/login'} />
          )
        }}
      />
    )
  }
}

export default AuthorizedRoute
