import {SelectedProductDTO} from '../../../../types/product/SelectedProductDTO'

const getFullPriceSelectedProduct = (selectedProduct: SelectedProductDTO): number => {
  let fullPrice: number = selectedProduct.price
  if (selectedProduct.product.productVariants?.length > 0) {
    for (let itemPV of selectedProduct.product.productVariants) {
      if (itemPV.productVariantId === selectedProduct.productVariantId) {
        if (itemPV.salePrice) {
          fullPrice = itemPV.salePrice + (selectedProduct.price - itemPV.price)
        }
      }
    }
  } else {
    if (selectedProduct.product.salePrice) {
      fullPrice =
        selectedProduct.product.salePrice + (selectedProduct.price - selectedProduct.product.price)
    }
  }
  return fullPrice
}

export {getFullPriceSelectedProduct}
