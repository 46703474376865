import {ProductMap} from 'grilnica-store-share'
import {ProductDTO} from 'grilnica-store-share/lib/product/ProductDTO'
import {getProductById} from '../../../../../utils/order/getProductById'
import {OrderProductDTO} from 'grilnica-store-share/lib/order/OrderProductDTO'
import {SelectedComboProductMap} from 'grilnica-store-share/src/product/SelectedComboProductMap'
import {getSelectedComboProductMap} from './getSelectedComboProductMap'
import {getSelectedComboProductPrice} from './getSelectedComboProductPrice'
import {ResponseError} from 'grilnica-share'
import {getFullPriceSelectedProduct} from '../../../basket/utils/getFullPriceSelectedProduct'
import {SelectedProductDTO} from '../../../../../types/product/SelectedProductDTO'
import v4 from 'uuid/v4'

function getSelectedComboProduct(
  product: OrderProductDTO,
  productMap: ProductMap,
): SelectedProductDTO {
  let selectedComboProduct: SelectedProductDTO = {
    productId: null,
    product: null,
    count: product.count,
    price: product.price,
    selectedProductId: v4(),
  }

  //получить продукт комбо
  let productComboDB: ProductDTO = getProductById(product.productId, productMap)
  selectedComboProduct.product = productComboDB

  if (!productComboDB) {
    throw new ResponseError(409, 'Невозможно повторить заказ')
  }

  if (product.count > productComboDB.maxCountInOrder) {
    let errorMessage: string = `Не удалось добавить. Для "${product.productName}" превышен лимит в корзине.`
    throw new ResponseError(409, errorMessage)
  }
  selectedComboProduct.maxCountInOrder = productComboDB.maxCountInOrder

  //получить выбранные товары для комбо
  let selectedProductsMap: SelectedComboProductMap = getSelectedComboProductMap(productMap, product)
  selectedComboProduct.selectedProducts = selectedProductsMap

  // обновить цену
  selectedComboProduct.price =
    productComboDB.price + getSelectedComboProductPrice(selectedProductsMap)

  //получить полную цену для превью корзины
  selectedComboProduct.fullPrice = getFullPriceSelectedProduct(selectedComboProduct)

  return selectedComboProduct
}

export {getSelectedComboProduct}
