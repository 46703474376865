import React, {useContext, useMemo} from 'react'
import {Button, Container} from 'grilnica-reactstrap'
import {errorActions} from 'grilnica-share-client'
import {getFullUrl} from '../../../../../utils/url'
import {connectComponent} from '../../../../../store/common'
import {UrlPrefixContext} from '../../../components/App'

interface PrePaymentFailurePageProps {
  prepaymentOrderId: string
}

const PrePaymentFailurePage: React.FC<PrePaymentFailurePageProps> = ({
  prepaymentOrderId,
}): React.ReactElement => {
  const urlPrefix: string = useContext(UrlPrefixContext)
  const renderContent = useMemo(() => {
    return (
      <Container size={'normal'} className={'mt-x10 mb-x10'}>
        <div className={'d-flex justify-content-center align-items-center'}>
          <div
            className={'d-flex flex-column justify-content-center align-items-center payment-card'}>
            <p className={'mb-secondary'}>Ошибка оплаты</p>
            <Button
              className={'btn-size'}
              isRoundedButton
              color={'primary'}
              onClick={() => {
                window.location.href = getFullUrl('/payment/' + prepaymentOrderId, urlPrefix)
              }}>
              Попробовать снова
            </Button>
          </div>
        </div>
      </Container>
    )
  }, [prepaymentOrderId, urlPrefix])

  return <>{renderContent}</>
}

export default connectComponent(
  (state: any, props: any) => ({
    prepaymentOrderId: props.match.params.prepaymentOrderId,
  }),
  (dispatch: any) => ({
    errorActions: errorActions(dispatch),
  }),
  PrePaymentFailurePage,
)
