import React from 'react'
import {COLORS} from 'grilnica-reactstrap'

interface OrderCancelSVGProps {
  isActive: boolean
  size?: 's' | 'm'
  className?: string
}

const OrderCancelSVG: React.FC<OrderCancelSVGProps> = ({
  isActive,
  size,
  className,
}): React.ReactElement => {
  return (
    <svg
      className={className}
      width={size === 's' ? '24' : '48'}
      height={size === 's' ? '24' : '48'}
      viewBox="0 0 48 48"
      fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.10116 22.0494C6.17849 11.6114 15.5135 4.0231 25.9514 5.10043C29.8353 5.50129 33.3272 7.04676 36.1234 9.36739C36.5484 9.7201 36.6069 10.3505 36.2542 10.7755C35.9015 11.2005 35.2711 11.2591 34.8461 10.9064C32.3452 8.83086 29.2238 7.4488 25.7461 7.08986C16.4069 6.12594 8.05452 12.9155 7.09059 22.2547C6.12667 31.5939 12.9162 39.9463 22.2554 40.9102C31.5946 41.8741 39.947 35.0846 40.9109 25.7454C41.1996 22.9485 40.7934 20.2429 39.8329 17.7975C39.631 17.2835 39.884 16.703 40.3981 16.5011C40.9121 16.2992 41.4925 16.5523 41.6944 17.0663C42.769 19.8022 43.2226 22.8285 42.9004 25.9507C41.823 36.3887 32.488 43.977 22.0501 42.8996C11.6121 41.8223 4.02383 32.4873 5.10116 22.0494Z"
        fill={isActive ? COLORS.red : COLORS.gray500}
      />
      <path
        d="M17.6393 32.7696C17.215 33.1217 16.5856 33.0639 16.2325 32.6402C15.879 32.2159 15.9363 31.5854 16.3606 31.2318L16.3617 31.2309L16.3631 31.2297L16.3667 31.2267L16.3774 31.218L16.4117 31.1903C16.4405 31.1674 16.4807 31.1358 16.532 31.0968C16.6344 31.0188 16.7813 30.9109 16.9687 30.7832C17.3428 30.5281 17.8816 30.1916 18.5535 29.8556C19.8905 29.1871 21.7964 28.5 24.0008 28.5C26.2052 28.5 28.111 29.1871 29.448 29.8556C30.1199 30.1916 30.6587 30.5281 31.0328 30.7832C31.2202 30.9109 31.3671 31.0188 31.4696 31.0968C31.5208 31.1358 31.5611 31.1674 31.5898 31.1903L31.6242 31.218L31.6409 31.2318C32.0652 31.5854 32.1225 32.2159 31.769 32.6402C31.4155 33.0643 30.7853 33.1218 30.361 32.7686L30.3424 32.7536C30.3252 32.7399 30.2971 32.7178 30.2585 32.6884C30.1813 32.6297 30.0626 32.5423 29.9062 32.4356C29.5928 32.222 29.1316 31.9335 28.5535 31.6445C28.2637 31.4996 27.9472 31.3558 27.6074 31.2216C26.5835 30.8174 25.3489 30.5 24.0008 30.5C22.6453 30.5 21.4047 30.8208 20.3776 31.2282C20.0441 31.3605 19.7331 31.5019 19.448 31.6445C18.8699 31.9335 18.4087 32.222 18.0953 32.4356C17.9389 32.5423 17.8202 32.6297 17.743 32.6884C17.7044 32.7178 17.6763 32.7399 17.6591 32.7536L17.6416 32.7677L17.6393 32.7696Z"
        fill={isActive ? COLORS.red : COLORS.gray500}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.0008 16C18.1053 16 19.0008 16.8955 19.0008 18V19C19.0008 20.1046 18.1053 21 17.0008 21C15.8962 21 15.0008 20.1046 15.0008 19V18C15.0008 16.8955 15.8962 16 17.0008 16ZM30.0008 16C31.1053 16 32.0008 16.8955 32.0008 18V19C32.0008 20.1046 31.1053 21 30.0008 21C28.8962 21 28.0008 20.1046 28.0008 19V18C28.0008 16.8955 28.8962 16 30.0008 16Z"
        fill={isActive ? COLORS.red : COLORS.gray500}
      />
    </svg>
  )
}

export {OrderCancelSVG}
