import React, {useMemo} from 'react'
import {ReactSVG} from 'react-svg'
import {CONSTANTS} from '../../../../../../../../../../../constants'
import {LabelDTO} from 'grilnica-store-share'

interface PromoItemsProps {
  labels: LabelDTO[]
}

const PromoItems: React.FC<PromoItemsProps> = ({labels}): React.ReactElement => {
  const renderContent: React.ReactElement = useMemo(() => {
    const items: React.ReactNode = labels?.map((label) => (
      <ReactSVG
        className={'promo-item'}
        title={label.name}
        src={CONSTANTS.cdnUrl + '/' + label.iconFileName}
        key={label.labelId}
      />
    ))
    return <div className={'promo-list'}>{items}</div>
  }, [labels])
  return <>{renderContent}</>
}

export {PromoItems}
