import {SelectedProductDTO} from 'grilnica-store-share'
import {SelectedOptionsMap} from '../../../../../../../../../../types/product/SelectedOptionsMap'
import {OptionMap} from '../../../../../../../../../../types/product/OptionMap'
import {OptionCategoryMap} from '../../../../../../../../../../types/product/OptionCategoryMap'
import {RestrictionsMap} from '../../../../../../../../../../types/product/RestrictionsMap'
import {SelectedOption} from '../../../../../../../../../../types/options/SelectedOption'
import {SelectedOptionNamesMap} from '../../../../../../../../../../types/options/SelectedOptionNamesMap'

const getSelectedProduct = (
  product: SelectedProductDTO,
  selectedOptionsMap: SelectedOptionsMap,
  optionMap: OptionMap,
  optionCategoryMap: OptionCategoryMap,
  restrictionsMap: RestrictionsMap,
) => {
  const selectedOptions: SelectedOption[] = []
  const allSelectedOptionsIds: string[] = []
  const allOptionProductIds: string[] = []
  for (let optionCategoryId in selectedOptionsMap) {
    let selectedOptionIds: string[] = []
    let selectedOptionNames: SelectedOptionNamesMap = {}
    let fullOptionsPrice: number = 0

    for (let optionId in selectedOptionsMap[optionCategoryId]) {
      if (
        selectedOptionsMap[optionCategoryId][optionId] &&
        allSelectedOptionsIds.findIndex((item) => item === optionId) === -1
      ) {
        if (optionMap[optionId].productId) {
          if (
            allOptionProductIds.findIndex((item) => item === optionMap[optionId].productId) === -1
          ) {
            selectedOptionIds.push(optionId)
            selectedOptionNames[optionMap[optionId].name] = optionMap[optionId].isDeleteOptions
            allSelectedOptionsIds.push(optionId)
            allOptionProductIds.push(optionMap[optionId].productId)
          }
        } else {
          selectedOptionIds.push(optionId)
          selectedOptionNames[optionMap[optionId].name] = optionMap[optionId].isDeleteOptions
          allSelectedOptionsIds.push(optionId)
        }
        if (optionMap[optionId].price) {
          fullOptionsPrice = fullOptionsPrice + optionMap[optionId].price
        }
      }
    }

    if (selectedOptionIds.length !== 0) {
      let isProductVariant: boolean = optionCategoryMap[optionCategoryId].isProductVariantDefine

      let optionCategoryName: string = optionCategoryMap[optionCategoryId].name

      let isOptional: boolean =
        restrictionsMap[optionCategoryId].min !== restrictionsMap[optionCategoryId].max

      const selectedOption: SelectedOption = {
        optionCategoryId,
        isProductVariant,
        optionIds: selectedOptionIds,
        optionNames: selectedOptionNames,
        optionCategoryName,
        isOptional,
        fullOptionsPrice,
      }
      selectedOptions.push(selectedOption)
    }
  }

  return {
    ...product,
    selectedOptions,
  }
}

export {getSelectedProduct}
