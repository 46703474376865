import React, {useMemo} from 'react'
import {Alert, ButtonLink, CustomPopover, Divider} from 'grilnica-reactstrap'
import {LazyImage} from '../../../../../../../../../components/lazyImage/LazyImage'
import {CONSTANTS} from '../../../../../../../../../../../constants'
import {Link} from 'react-router-dom'
import {ProductDTO} from 'grilnica-store-share'

interface ProductPreviewProps {
  product: ProductDTO
  weight: string
  priceBlock: React.ReactElement
  productLink: string
  isNotForSale: boolean
}

const ProductPreview: React.FC<ProductPreviewProps> = ({
  product,
  weight,
  priceBlock,
  productLink,
  isNotForSale,
  children,
}): React.ReactElement => {
  const renderImage: React.ReactElement = useMemo(() => {
    return (
      <Link to={productLink} className={'pp-img'}>
        <LazyImage
          src={CONSTANTS.cdnUrl + '/' + product.imageFileName}
          typePlaceholder={'product'}
          alt={product.name}
          title={product.name}
        />
      </Link>
    )
  }, [product.imageFileName, product.name, productLink])

  const renderInfo: React.ReactElement = useMemo(() => {
    return (
      <div className={'d-flex flex-column'}>
        <Link to={productLink} className={'subtitle1 text-gray-800 mb-x3'}>
          {product.name}
        </Link>
        <Link
          to={productLink}
          className={'sec-text-body-2 text-gray-700 ' + (weight || isNotForSale ? 'mb-x4' : '')}>
          {product.description}
        </Link>
        {weight && (
          <p className={'caption text-gray-600 ' + (isNotForSale ? 'mb-x4' : '')}>{weight}</p>
        )}
        {isNotForSale && (
          <Alert
            message={'Данный товар не доступен для розничной продажи'}
            color={'danger'}
            iconPosition={'start'}
          />
        )}
      </div>
    )
  }, [isNotForSale, product.description, product.name, productLink, weight])

  const renderFooter: React.ReactElement = useMemo(() => {
    return (
      <div className={''}>
        <Divider className={'my-x4'} />
        <div className={'pp-footer'}>
          <div className={'pp-price mb-0'}>{priceBlock}</div>
          <ButtonLink
            size={'sm'}
            style={'outline'}
            isRoundedButton
            color={'primary'}
            to={productLink}>
            Подробнее
          </ButtonLink>
        </div>
      </div>
    )
  }, [priceBlock, productLink])

  const renderProductInfo: React.ReactElement = useMemo(() => {
    return (
      <div className={'pp-info'}>
        <div className={'pp-info-content'}>
          {renderImage}
          {renderInfo}
        </div>
        {renderFooter}
      </div>
    )
  }, [renderFooter, renderImage, renderInfo])

  const renderContent: React.ReactElement = useMemo(() => {
    return (
      <CustomPopover
        place={'bottom'}
        isShowCloseButton={true}
        classNameMap={{
          container: 'product-preview',
          closeIcon: 'pp-close-icon',
          popover: 'pp-popover',
          popoverBodyContent: 'mr-0',
        }}
        content={renderProductInfo}>
        {children}
      </CustomPopover>
    )
  }, [children, renderProductInfo])

  return <>{renderContent}</>
}

export {ProductPreview}
