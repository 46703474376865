import React, {useMemo} from 'react'
import {Button, Col, Datepicker, InputSelectVertical, InputVertical, Row} from 'grilnica-reactstrap'
import {getDate} from '../../../utils/TimeUtils'
import {ClientDTO} from 'grilnica-store-share'

interface EditProfileBlockProps {
  handleSubmit: () => Promise<void>
  handleChangeClient: (key: string, value: any) => void
  client: ClientDTO
  isUpdate: boolean
  isExistsBirthDate: boolean
  onCancel: () => void
}

const EditProfileBlock: React.FC<EditProfileBlockProps> = ({
  handleSubmit,
  handleChangeClient,
  client,
  isUpdate,
  onCancel,
  isExistsBirthDate,
}): React.ReactElement => {
  const renderContent: React.ReactElement = useMemo(() => {
    return (
      <div className={'profile-card profile-my-data'}>
        <div>
          <h2 className={'h2 mb-x8 text-center'}>Мои данные</h2>
          <Row>
            <Col md={6}>
              <InputVertical
                id={'input-client-first-name'}
                name={'clientFirstName'}
                type={'text'}
                label={'Имя'}
                value={client.firstName}
                isRequired={false}
                placeholder={'Введите имя'}
                isRounded
                size={'lg'}
                onChange={(value: any) => {
                  handleChangeClient('firstName', value)
                }}
              />
            </Col>
            <Col md={6}>
              <InputVertical
                id={'input-client-last-name'}
                name={'clientLastName'}
                type={'text'}
                label={'Фамилия'}
                value={client.lastName}
                isRequired={false}
                placeholder={'Введите фамилию'}
                isRounded
                size={'lg'}
                onChange={(value: any) => {
                  handleChangeClient('lastName', value)
                }}
              />
            </Col>
            <Col md={6}>
              <InputVertical
                id={'input-client-phone'}
                name={'clientPhone'}
                type={'text'}
                label={'Телефон'}
                value={client.phone}
                isRequired={false}
                isRounded
                size={'lg'}
                disabled
                onChange={() => {}}
              />
            </Col>
            <Col md={6}>
              <InputVertical
                id={'input-client-email'}
                name={'clientEmail'}
                type={'text'}
                label={'Email'}
                value={client.email}
                isRequired={false}
                placeholder={'Введите свой email'}
                isRounded
                size={'lg'}
                onChange={(value: any) => {
                  handleChangeClient('email', value)
                }}
              />
            </Col>
            <Col md={6}>
              <Datepicker
                id={'input-client-birth-date'}
                name={'clientBirthDate'}
                value={getDate(client.birthDate as Date)}
                label={'Дата рождения'}
                placeholder={'Выберите дату'}
                isRounded
                disabled={isExistsBirthDate}
                helpText={
                  <p className={'mb-0 pt-x1'}>
                    Дата рождения устанавливается один раз и изменить ее нельзя
                  </p>
                }
                className={'client-birth-date'}
                onChange={(value: any) => {
                  handleChangeClient('birthDate', getDate(value))
                }}
              />
            </Col>
            <Col md={6}>
              <InputSelectVertical
                id={'input-client-sex'}
                key={'sex_' + client.sex}
                instanceId={'input-option-client-sex'}
                options={[
                  {value: 'MALE', label: 'Мужской'},
                  {value: 'FEMALE', label: 'Женский'},
                  {value: 'UNSPECIFIED', label: 'Предпочитаю не указывать'},
                ]}
                defaultValue={[
                  {value: 'MALE', label: 'Мужской'},
                  {value: 'FEMALE', label: 'Женский'},
                  {value: 'UNSPECIFIED', label: 'Предпочитаю не указывать'},
                ].find((item) => {
                  if (item.value === client.sex) {
                    return item
                  }
                })}
                label={'Пол'}
                name={'clientSex'}
                isRequired={false}
                placeholder={'Выберите пол'}
                isRounded
                isSearchable={false}
                size={'lg'}
                className={'mb-x8'}
                onChange={(value: any) => {
                  handleChangeClient('sex', value.value)
                }}
              />
            </Col>
          </Row>
        </div>
        <div className={'profile-edit-btn'}>
          {isUpdate && (
            <Button
              color={'primary'}
              style={'outline'}
              isRoundedButton
              className={'mr-x4'}
              onClick={onCancel}>
              Отмена
            </Button>
          )}
          <Button
            type={'submit'}
            color={'primary'}
            isRoundedButton
            disabled={!isUpdate}
            onClick={() => {
              handleSubmit()
            }}>
            Сохранить
          </Button>
        </div>
      </div>
    )
  }, [
    client.firstName,
    client.lastName,
    client.phone,
    client.email,
    client.birthDate,
    client.sex,
    isExistsBirthDate,
    isUpdate,
    onCancel,
    handleChangeClient,
    handleSubmit,
  ])
  return <>{renderContent}</>
}

export {EditProfileBlock}
