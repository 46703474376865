import React, {useMemo, useState} from 'react'
import {
  Col,
  COLORS,
  CustomCheckbox,
  Divider,
  Icon,
  InputListVertical,
  InputVertical,
  Row,
} from 'grilnica-reactstrap'
import {Map} from '../../../restaurants/Map'
import {CityDTO, RestaurantDTO} from 'grilnica-share'
import {AutoChoiceRestaurantType, ClientAddressDTO, DeliveryTypeEnum} from 'grilnica-store-share'
import {FoundAddress} from '../../../../../../types/address/FoundAddress'
import {FavouritesRestaurant} from '../../../../../../types/restaurant/FavouritesRestaurant'
import {InputSelectFavouritesRestaurant} from './InputSelectFavouritesRestaurant'
import {DeliveryInfo} from '../../../../../../types/order/DeliveryInfo'
import {DeliveryTimeAlert} from '../time/DeliveryTimeAlert'
import {mapMarkerSVG} from '../../../../../components/svg/SystemIcons'

interface OrderDeliveryAddressProps {
  city: CityDTO
  deliveryType: DeliveryTypeEnum
  setPrepareOrderField: (key: string, value: any, parentKey?: string) => void
  foundAddresses: FoundAddress[]
  clientAddress: ClientAddressDTO
  favouritesRestaurants: FavouritesRestaurant[]
  activeRestaurant: RestaurantDTO
  setPrepareOrderRestaurant: (restaurant: RestaurantDTO) => void
  setIsUpdateAddress: (value: boolean) => void
  isUpdateAddress: boolean
  error: string

  autoChoiceRestaurant: AutoChoiceRestaurantType
  toggleAutoChooseRestaurant: () => void
  deliveryInfo: DeliveryInfo
}

const OrderDeliveryAddress: React.FC<OrderDeliveryAddressProps> = ({
  city,
  deliveryType,
  setPrepareOrderField,
  foundAddresses,
  clientAddress,
  favouritesRestaurants,
  activeRestaurant,
  setPrepareOrderRestaurant,
  setIsUpdateAddress,
  isUpdateAddress,
  error,
  autoChoiceRestaurant,
  toggleAutoChooseRestaurant,
  deliveryInfo,
}): React.ReactElement => {
  const [chooseAddress, setChooseAddress] = useState<'map' | 'fill'>('map')

  const renderMap = useMemo(() => {
    return (
      <>
        <div className={'d-flex mb-x4'}>
          <CustomCheckbox
            type={'radio'}
            id={'specifyMap'}
            label={'На карте'}
            checked={chooseAddress === 'map'}
            onChange={() => {
              setChooseAddress('map')
            }}
            color={'red'}
            className={'mr-x4'}
          />
          <CustomCheckbox
            type={'radio'}
            id={'fill'}
            label={'Вручную'}
            checked={chooseAddress === 'fill'}
            onChange={() => {
              setChooseAddress('fill')
            }}
            color={'red'}
          />
        </div>
        <Map
          onClickMark={(restaurant) => {
            setPrepareOrderRestaurant(restaurant)
          }}
          city={city}
          className={'mb-x4 ' + (chooseAddress === 'fill' ? 'd-none' : '')}
          restaurants={favouritesRestaurants}
          isRestaurantPage={false}
        />
      </>
    )
  }, [chooseAddress, city, favouritesRestaurants, setPrepareOrderRestaurant])

  const renderStreetInput = useMemo(() => {
    const items: React.ReactNode[] = foundAddresses.map((item, index) => (
      <p className={'mb-0'} key={item.value + index}>
        {item.street + (item.house ? ' ' + item.house : '')}
      </p>
    ))
    return (
      <InputListVertical
        id={'input-street'}
        size={'lg'}
        name={'street'}
        type={'text'}
        label={'Улица'}
        placeholder={'Введите название'}
        autoComplete={'off'}
        invalid={!isUpdateAddress && !!error}
        value={clientAddress.street}
        isRequired
        isRounded
        onChange={(value) => {
          setPrepareOrderField('street', value, 'selectedAddress')
          setPrepareOrderField('longitude', null, 'selectedAddress')
          setPrepareOrderField('latitude', null, 'selectedAddress')
          setIsUpdateAddress(true)
        }}
        listItems={items}
        onClickItem={(index) => {
          clientAddress.street = foundAddresses[index].street
          clientAddress.streetKladrId = foundAddresses[index].streetKladrId
          clientAddress.streetType = foundAddresses[index].streetType
          //TODO:: houseFiasId ?????
          clientAddress.house = foundAddresses[index].house
            ? foundAddresses[index].house
            : clientAddress.house
          clientAddress.longitude = foundAddresses[index].longitude
          clientAddress.latitude = foundAddresses[index].latitude
          setPrepareOrderField('selectedAddress', clientAddress)
          setIsUpdateAddress(true)
        }}
      />
    )
    // clientAddress.street чтобы рендорилось только при смене улицы
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error, isUpdateAddress, foundAddresses, clientAddress.street, setPrepareOrderField])

  const renderInputHouse: React.ReactElement = useMemo(() => {
    return (
      <InputVertical
        id={'input-house'}
        name={'house'}
        type={'text'}
        label={'Дом'}
        value={clientAddress.house}
        invalid={!isUpdateAddress && !!error}
        isRequired
        isRounded
        size={'lg'}
        onChange={(value) => {
          if (value.length <= 10) {
            setPrepareOrderField('house', value, 'selectedAddress')
            setPrepareOrderField('longitude', null, 'selectedAddress')
            setPrepareOrderField('latitude', null, 'selectedAddress')
            setIsUpdateAddress(true)
          }
        }}
      />
    )
  }, [clientAddress.house, isUpdateAddress, error, setPrepareOrderField, setIsUpdateAddress])

  const renderInputFlat: React.ReactElement = useMemo(() => {
    return (
      <InputVertical
        id={'input-flat'}
        name={'flat'}
        type={'text'}
        label={'Квартира'}
        value={clientAddress.flat}
        isRequired={false}
        disabled={!!clientAddress.office}
        isRounded
        size={'lg'}
        onChange={(value) => {
          if (value.length <= 10) {
            setPrepareOrderField('flat', value, 'selectedAddress')
          }
        }}
      />
    )
  }, [clientAddress.flat, clientAddress.office, setPrepareOrderField])

  const renderInputOffice: React.ReactElement = useMemo(() => {
    return (
      <InputVertical
        id={'input-office'}
        name={'office'}
        type={'text'}
        label={'Офис'}
        value={clientAddress.office}
        isRequired={false}
        disabled={!!clientAddress.flat}
        isRounded
        size={'lg'}
        onChange={(value) => {
          if (value.length <= 10) {
            setPrepareOrderField('office', value, 'selectedAddress')
          }
        }}
      />
    )
  }, [clientAddress.flat, clientAddress.office, setPrepareOrderField])

  const renderInputFloor: React.ReactElement = useMemo(() => {
    return (
      <InputVertical
        id={'input-floor'}
        name={'floor'}
        type={'text'}
        label={'Этаж'}
        value={clientAddress.floor}
        isRequired={false}
        isRounded
        size={'lg'}
        onChange={(value) => {
          if (value.length <= 10) {
            setPrepareOrderField('floor', value, 'selectedAddress')
          }
        }}
      />
    )
  }, [clientAddress.floor, setPrepareOrderField])

  const renderInputEntrance: React.ReactElement = useMemo(() => {
    return (
      <InputVertical
        id={'input-entrance'}
        name={'entrance'}
        type={'text'}
        label={'Подъезд'}
        value={clientAddress.entrance}
        isRequired={false}
        isRounded
        size={'lg'}
        onChange={(value) => {
          if (value.length <= 10) {
            setPrepareOrderField('entrance', value, 'selectedAddress')
          }
        }}
      />
    )
  }, [clientAddress.entrance, setPrepareOrderField])

  const renderInputDoorPhone: React.ReactElement = useMemo(() => {
    return (
      <InputVertical
        id={'input-door-phone'}
        name={'doorPhone'}
        type={'text'}
        label={'Домофон'}
        value={clientAddress.doorPhone}
        isRequired={false}
        isRounded
        size={'lg'}
        onChange={(value) => {
          if (value.length <= 10) {
            setPrepareOrderField('doorPhone', value, 'selectedAddress')
          }
        }}
      />
    )
  }, [clientAddress.doorPhone, setPrepareOrderField])

  const renderInputAdditionalInfo: React.ReactElement = useMemo(() => {
    return (
      <InputVertical
        name={'input-additional-info'}
        type={'text'}
        value={clientAddress.additionalInfo}
        isRequired={false}
        onChange={(value) => {
          setPrepareOrderField('additionalInfo', value, 'selectedAddress')
        }}
        isRounded
        size={'lg'}
        label={'Комментарий к адресу'}
        placeholder={'Например: домофон не работает'}
      />
    )
  }, [clientAddress?.additionalInfo, setPrepareOrderField])

  const renderDeliveryTimeInfo = useMemo(() => {
    if (clientAddress.street && clientAddress.house) {
      return <DeliveryTimeAlert deliveryType={deliveryType} deliveryInfo={deliveryInfo} />
    } else {
      return null
    }
  }, [deliveryType, deliveryInfo, clientAddress.street, clientAddress.house])

  const renderDeliveryAddress = useMemo(() => {
    return (
      <>
        {city && (
          <Col xl={12} lg={12} md={12} sm={12} xs={12}>
            <div className={'d-flex flex-row align-items-center mb-x4'}>
              <div className={'d-flex mr-x1'}>
                <Icon path={mapMarkerSVG} color={COLORS.gray800} />
              </div>
              <span className={'subtitle1 text-gray-800'}>{city.name}</span>
            </div>
          </Col>
        )}
        <Col sm={6}>{renderStreetInput}</Col>
        <Col sm={3} xs={6}>
          {renderInputHouse}
        </Col>
        <Col sm={3} xs={6}>
          {renderInputFlat}
        </Col>
        <Col sm={3} xs={6}>
          {renderInputOffice}
        </Col>
        <Col sm={3} xs={6}>
          {renderInputFloor}
        </Col>
        <Col sm={3} xs={6}>
          {renderInputEntrance}
        </Col>
        <Col sm={3} xs={6}>
          {renderInputDoorPhone}
        </Col>
        <Col xs={12}>{renderDeliveryTimeInfo}</Col>
        <Col xl={12} lg={12} md={12} sm={12} xs={12}>
          {renderInputAdditionalInfo}
        </Col>
      </>
    )
  }, [
    renderInputAdditionalInfo,
    renderInputDoorPhone,
    renderInputEntrance,
    renderInputFlat,
    renderInputFloor,
    renderInputHouse,
    renderInputOffice,
    renderStreetInput,
    renderDeliveryTimeInfo,
    city,
  ])

  const renderPickupAddress = useMemo(() => {
    return (
      <>
        <Col sm={6}>
          <InputSelectFavouritesRestaurant
            activeRestaurant={activeRestaurant}
            favouritesRestaurants={favouritesRestaurants}
            setPrepareOrderRestaurant={setPrepareOrderRestaurant}
          />
          {activeRestaurant && (
            <CustomCheckbox
              id={'isAutoChoice'}
              label={'Запомнить выбор'}
              checked={!!autoChoiceRestaurant.isAutoChoice}
              onChange={() => {
                toggleAutoChooseRestaurant()
              }}
              className={'mb-x6'}
              color={'red'}
            />
          )}
        </Col>
      </>
    )
  }, [
    activeRestaurant,
    favouritesRestaurants,
    setPrepareOrderRestaurant,
    autoChoiceRestaurant.isAutoChoice,
    toggleAutoChooseRestaurant,
  ])

  return (
    <>
      <p className={'name-blocks'}>
        2. {deliveryType === DeliveryTypeEnum.DELIVERY ? 'Укажите адрес доставки' : 'Ресторан'}
      </p>
      {!(deliveryType === DeliveryTypeEnum.DELIVERY) && renderMap}
      <Row>
        {deliveryType === DeliveryTypeEnum.DELIVERY ? renderDeliveryAddress : renderPickupAddress}
      </Row>
      <Divider className={'mb-x6'} />
    </>
  )
}

export {OrderDeliveryAddress}
