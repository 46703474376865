import {SelectedOptionsMap} from '../../../../../types/product/SelectedOptionsMap'
import {OptionCategoryMap} from '../../../../../types/product/OptionCategoryMap'
import {OptionMap} from '../../../../../types/product/OptionMap'
import {getSelectedOptionsIds} from './getSelectedOptionsIds'

function getSumPriceOptions(
  selectedOptionsMap: SelectedOptionsMap,
  optionCategoryMap: OptionCategoryMap,
  optionMap: OptionMap,
): number {
  let sumPriceOptions: number = 0

  let selectedOptions: string[] = getSelectedOptionsIds(selectedOptionsMap)

  for (let selectedOption of selectedOptions) {
    sumPriceOptions = sumPriceOptions + optionMap[selectedOption].price
  }

  return sumPriceOptions
}

export {getSumPriceOptions}
