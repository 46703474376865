import {SelectedComboProductMap} from 'grilnica-store-share/lib/product/SelectedComboProductMap'
import {ProductMap, SelectedOrderComboProductsMap, SelectedProductDTO} from 'grilnica-store-share'
import {OrderProductDTO} from 'grilnica-store-share/lib/order/OrderProductDTO'
import {getSelectedProduct} from '../product/getSelectedProduct'

function getSelectedComboProductMap(
  productMap: ProductMap,
  orderProductCombo: OrderProductDTO,
): SelectedComboProductMap {
  let selectedComboProductMap: SelectedComboProductMap = {}
  let allOptionPrice: number = 0

  let selectedComboOrderProductsMap: SelectedOrderComboProductsMap =
    orderProductCombo.selectedComboOrderProducts

  for (let comboGroupId in selectedComboOrderProductsMap) {
    if (selectedComboOrderProductsMap.hasOwnProperty(comboGroupId)) {
      let newComboSelectedProduct: SelectedProductDTO = getSelectedProduct(
        selectedComboOrderProductsMap[comboGroupId],
        productMap,
      )

      // @ts-ignore
      allOptionPrice = allOptionPrice + newComboSelectedProduct.allOptionPrice

      newComboSelectedProduct.isActive = selectedComboOrderProductsMap[comboGroupId]
        .productVariantId
        ? newComboSelectedProduct.productVariantId ===
          selectedComboOrderProductsMap[comboGroupId].productVariantId
        : true
      newComboSelectedProduct.iikoComboGroupId =
        selectedComboOrderProductsMap[comboGroupId].iikoComboGroupId

      selectedComboProductMap[comboGroupId] = [newComboSelectedProduct]
    }
  }

  return selectedComboProductMap
}

export {getSelectedComboProductMap}
