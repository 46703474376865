import {SelectedOption} from 'grilnica-store-share/src/order/SelectedOption'
import {SelectedOptionsMap} from '../../../../../types/product/SelectedOptionsMap'
import {OptionCategoryMap} from '../../../../../types/product/OptionCategoryMap'
import {OptionMap} from '../../../../../types/product/OptionMap'
import {SelectedOptionNamesMap} from '../../../../../types/options/SelectedOptionNamesMap'

function getSelectedOptions(
  selectedOptionsMap: SelectedOptionsMap,
  optionCategoryMap: OptionCategoryMap,
  optionMap: OptionMap,
): SelectedOption[] {
  let selectedOptions: SelectedOption[] = []
  const allSelectedOptionsIds: string[] = []
  const allOptionProductIds: string[] = []
  for (let optionCategoryId in selectedOptionsMap) {
    let selectedOptionIds: string[] = []
    let selectedOptionNames: SelectedOptionNamesMap = {}

    for (let optionId in selectedOptionsMap[optionCategoryId]) {
      if (
        selectedOptionsMap[optionCategoryId][optionId] &&
        allSelectedOptionsIds.findIndex((item) => item === optionId) === -1
      ) {
        if (optionMap[optionId].productId) {
          if (
            allOptionProductIds.findIndex((item) => item === optionMap[optionId].productId) === -1
          ) {
            selectedOptionIds.push(optionId)
            selectedOptionNames[optionMap[optionId].name] = optionMap[optionId].isDeleteOptions
            allSelectedOptionsIds.push(optionId)
            allOptionProductIds.push(optionMap[optionId].productId)
          }
        } else {
          selectedOptionIds.push(optionId)
          selectedOptionNames[optionMap[optionId].name] = optionMap[optionId].isDeleteOptions
          allSelectedOptionsIds.push(optionId)
        }
      }
    }

    if (selectedOptionIds.length !== 0) {
      let isProductVariant: boolean = optionCategoryMap[optionCategoryId].isProductVariantDefine

      let optionCategoryName: string = optionCategoryMap[optionCategoryId].name

      const selectedOption: SelectedOption = {
        optionCategoryId,
        isProductVariant,
        optionIds: selectedOptionIds,
        optionNames: selectedOptionNames,
        optionCategoryName,
      }
      selectedOptions.push(selectedOption)
    }
  }

  return selectedOptions
}

export {getSelectedOptions}
