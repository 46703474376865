import React, {useMemo} from 'react'
import {ProcessCardItemDTO} from 'grilnica-share'
import {MenuCategoryDTO, ProductMap, WeightMapDto} from 'grilnica-store-share'
import {ProductPreviewCardList} from './ProductPreviewCardList'

interface ProductPreviewBlockProps {
  processProductItems: ProcessCardItemDTO[]
  productMap: ProductMap
  menuCategoryList: MenuCategoryDTO[]
  weightMap: WeightMapDto
}

const ProductPreviewBlock: React.FC<ProductPreviewBlockProps> = ({
  processProductItems,
  productMap,
  menuCategoryList,
  weightMap,
}): React.ReactElement => {
  const renderContent: React.ReactElement = useMemo(() => {
    if (processProductItems?.length > 0) {
      return (
        <div className={'mb-x2'}>
          <p className={'subtitle1 text-gray-800 mb-x4'}>В составе</p>
          <ProductPreviewCardList
            processProductItems={processProductItems}
            productMap={productMap}
            menuCategoryList={menuCategoryList}
            weightMap={weightMap}
          />
        </div>
      )
    } else {
      return null
    }
  }, [menuCategoryList, processProductItems, productMap, weightMap])

  return <>{renderContent}</>
}

export {ProductPreviewBlock}
