import {ProductDTO, SelectedOption, SelectedProductDTO} from 'grilnica-store-share'
import {ProductItemActionsTypes} from '../../ducks/ProductItemDuck'
import PrepareProductEntity from '../../product/PrepareProductEntity'
import {getSelectedProduct} from '../combo/getSelectedProduct'

const selectProductService = async (
  product: ProductDTO,
  dispatch: any,
  selectedProduct?: SelectedProductDTO,
) => {
  //
  const prepareProduct = new PrepareProductEntity(
    selectedProduct ? selectedProduct.product : product,
  )

  if (product.productVariants && product.productVariants?.length !== 0) {
    prepareProduct.createProductVariantMap()
  }

  if (product.optionCategories) {
    for (let optionCategory of product.optionCategories) {
      prepareProduct.sortOptionCategoryByType(optionCategory)
      // условие при редактировании продукта из корзины
      if (selectedProduct && selectedProduct.selectedOptions.length !== 0) {
        let currentSelectedOption: SelectedOption = selectedProduct.selectedOptions.find(
          (selectedOption) => selectedOption.optionCategoryId === optionCategory.optionCategoryId,
        )
        if (currentSelectedOption) {
          for (let optionId of currentSelectedOption.optionIds) {
            prepareProduct.addSelectedOptionsInMaps(optionCategory, optionId, true)
          }
        } else {
          prepareProduct.addSelectedOptionsInMaps(optionCategory, null, true)
        }
      } else {
        prepareProduct.addSelectedOptionsInMaps(optionCategory)
      }
      prepareProduct.addOptionCategoryInMap(optionCategory)
      prepareProduct.createOptionMap(optionCategory)
    }
  }
  prepareProduct.addSelectedProductPriceAndProductVariant()

  let newSelectedProduct: SelectedProductDTO = prepareProduct.getSelectedProduct()
  if (selectedProduct) {
    newSelectedProduct.count = selectedProduct.count
    newSelectedProduct.maxCountInOrder = selectedProduct.maxCountInOrder
    newSelectedProduct.isActive = selectedProduct.isActive //for combo
    newSelectedProduct.iikoComboGroupId = selectedProduct.iikoComboGroupId //for combo
    newSelectedProduct.selectedOptions = selectedProduct.selectedOptions
    for (let selectedOption of selectedProduct.selectedOptions) {
      for (let optionId of selectedOption.optionIds) {
        prepareProduct.changeSelectedOption(selectedOption.optionCategoryId, optionId, true)
      }
    }
  } else {
    newSelectedProduct = getSelectedProduct(
      prepareProduct.getSelectedProduct(),
      prepareProduct.getSelectedOptionsMap(),
      prepareProduct.getOptionMap(),
      prepareProduct.getOptionCategoryMap(),
      prepareProduct.getRestrictionsMap(),
    )
  }

  dispatch({
    type: ProductItemActionsTypes.SET_SUPPORTS_MAP,
    payload: {
      countSelectedOptionsMap: prepareProduct.getCountSelectedOptionsMap(),
      optionCategoryMap: prepareProduct.getOptionCategoryMap(),
      optionCategoryTypeMap: prepareProduct.getOptionCategoryTypeMap(),
      optionMap: prepareProduct.getOptionMap(),
      optionProductMap: prepareProduct.getOptionProductMap(),
      productVariantMap: prepareProduct.getProductVariantMap(),
      restrictionsMap: prepareProduct.getRestrictionsMap(),
      selectedOptionsMap: {...prepareProduct.getSelectedOptionsMap()},
      extraOptionsMap: {},
    },
  })

  dispatch({
    type: ProductItemActionsTypes.SET_SELECTED_PRODUCT,
    payload: newSelectedProduct,
  })
}

export {selectProductService}
