import {BaseAction} from '../../common/BaseAction'
import {bindActionCreators} from 'redux'
import {TerminalActionsTypes} from './types'
import {TerminalConfigDTO} from 'grilnica-share'

export const Actions = {
  setIsCheckForInactivity: (isCheckForInactivity: boolean): BaseAction => ({
    type: TerminalActionsTypes.SET_IS_CHECK_FOR_INACTIVITY,
    payload: isCheckForInactivity,
  }),
  setTerminalConfig: (terminalConfig: TerminalConfigDTO): BaseAction => ({
    type: TerminalActionsTypes.SET_TERMINAL_CONFIG,
    payload: terminalConfig,
  }),
  controllerSocketRedirectToHome: (data: any): BaseAction => ({
    type: TerminalActionsTypes.CONTROLLER_SOCKET_REDIRECT_TO_HOME,
    payload: {data},
  }),
  controllerSocketRedirectToRepair: (data: any): BaseAction => ({
    type: TerminalActionsTypes.CONTROLLER_SOCKET_REDIRECT_TO_REPAIR,
    payload: {data},
  }),
  setIsRedirectToHomeWithReloadPage: (is: boolean): BaseAction => ({
    type: TerminalActionsTypes.SET_IS_REDIRECT_TO_HOME_WITH_RELOAD_PAGE,
    payload: is,
  }),
  setIsRedirectToRepairWithReloadPage: (is: boolean): BaseAction => ({
    type: TerminalActionsTypes.SET_IS_REDIRECT_TO_REPAIR_WITH_RELOAD_PAGE,
    payload: is,
  }),
}

export type TerminalActionsType = typeof Actions

export function bindTerminalActions(dispatch: any) {
  return bindActionCreators(Actions, dispatch)
}
