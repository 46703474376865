import {call, delay, fork, put, select} from 'redux-saga/effects'
import {
  ContactDetailsMap,
  OptionCategoryDTO,
  OptionDTO,
  ProductDTO,
  ProductVariantDTO,
} from 'grilnica-store-share'
import {SelectedOptionsMap} from '../../../types/product/SelectedOptionsMap'
import {State} from '../../ducks'
import {OptionCategoryMap} from '../../../types/product/OptionCategoryMap'
import {OptionMap} from '../../../types/product/OptionMap'
import {Actions as BasketActions} from '../../ducks/basket'
import {Actions as ProductActions} from '../../ducks/product'
import {Actions as MenuActions} from '../../ducks/menu'
import {LSKeys, LSMethods} from '../../../storage'
import {deletePrepareOrder} from '../order/order'
import {selectProduct} from '../product'
import {SelectedOption} from '../../../types/options/SelectedOption'
import {SelectedOptionNamesMap} from '../../../types/options/SelectedOptionNamesMap'
import {Actions as ErrorActions} from '../../ducks/error'
import {
  clearAvailableActions,
  getAvailableActions,
  getAvailableActionsByRestaurant,
} from '../../action/saga/generators'
import {SaleResponse} from '../../action/types/SaleResponse'
import {CityDTO, ResponseError} from 'grilnica-share'
import {getPriceAndSaleFull} from '../../../utils/order/getPriceAndSaleFull'
import v4 from 'uuid/v4'
import {
  ECOMMERCE_ACTION_TYPE,
  EcommerceProductType,
  ecommerceTarget,
} from '../../../utils/metrica/ym/ecommerceTarget'
import {ymTarget} from '../../../utils/metrica/ym/ymTarget'
import {getFullPriceSelectedProduct} from './utils/getFullPriceSelectedProduct'
import {SelectedProductDTO} from '../../../types/product/SelectedProductDTO'
import {ProductItemState} from '../../../view/site/containers/menu/components/product/productList/productItem/ducks/ProductItemDuck'
import {checkRecommendationsProducts} from '../../recommendationsProduct/saga/generators'
import {TerminalState} from '../../terminal/types'

function* addProductWithOptionsInBasket({payload}: any) {
  yield call(deletePrepareOrder)

  const isModal: boolean = false
  const productItemState: ProductItemState = payload.productItemState
  const setDefaultIndex: () => void = payload?.setDefaultIndex
  const selectedProduct: SelectedProductDTO = productItemState.selectedProduct

  const selectedOptionsMap: SelectedOptionsMap = productItemState.supportsMaps.selectedOptionsMap
  const optionCategoryMap: OptionCategoryMap = productItemState.supportsMaps.optionCategoryMap
  const optionMap: OptionMap = productItemState.supportsMaps.optionMap
  const editProductIndex: number = yield select((state: State) => state.basket.editProductIndex)

  const selectedProducts: SelectedProductDTO[] = yield select(
    (state: State) => state.basket.selectedProducts,
  )

  const terminalState: TerminalState = yield select((state: State) => state.terminal)
  const terminalAlias: string = terminalState?.terminalAlias
  if (!terminalAlias) {
    if (editProductIndex !== null) {
      yield call(removeEcommerceProduct, [selectedProducts[editProductIndex]])
      yield call(addEcommerceProduct, [selectedProduct])
    } else {
      yield call(addEcommerceProduct, [selectedProduct])
      yield call(ymTarget, 'DOBAVILI_V_KORZINU')
    }
  }

  const selectedOptions: SelectedOption[] = []
  const allSelectedOptionsIds: string[] = []
  const allOptionProductIds: string[] = []
  for (let optionCategoryId in selectedOptionsMap) {
    let selectedOptionIds: string[] = []
    let selectedOptionNames: SelectedOptionNamesMap = {}

    for (let optionId in selectedOptionsMap[optionCategoryId]) {
      if (
        selectedOptionsMap[optionCategoryId][optionId] &&
        allSelectedOptionsIds.findIndex((item) => item === optionId) === -1
      ) {
        if (optionMap[optionId].productId) {
          if (
            allOptionProductIds.findIndex((item) => item === optionMap[optionId].productId) === -1
          ) {
            selectedOptionIds.push(optionId)
            selectedOptionNames[optionMap[optionId].name] = optionMap[optionId].isDeleteOptions
            allSelectedOptionsIds.push(optionId)
            allOptionProductIds.push(optionMap[optionId].productId)
          }
        } else {
          selectedOptionIds.push(optionId)
          selectedOptionNames[optionMap[optionId].name] = optionMap[optionId].isDeleteOptions
          allSelectedOptionsIds.push(optionId)
        }
      }
    }

    if (selectedOptionIds.length !== 0) {
      let isProductVariant: boolean = optionCategoryMap[optionCategoryId].isProductVariantDefine

      let optionCategoryName: string = optionCategoryMap[optionCategoryId].name

      const selectedOption: SelectedOption = {
        optionCategoryId,
        isProductVariant,
        optionIds: selectedOptionIds,
        optionNames: selectedOptionNames,
        optionCategoryName,
      }
      selectedOptions.push(selectedOption)
    }
  }
  let fullPrice = getFullPriceSelectedProduct(selectedProduct)

  let newSelectedProduct: SelectedProductDTO = {
    ...selectedProduct,
    selectedOptions,
    fullPrice,
  }

  let newSelectedProducts: SelectedProductDTO[] = [...selectedProducts]

  if (editProductIndex !== null) {
    newSelectedProducts[editProductIndex] = newSelectedProduct
  } else {
    newSelectedProduct.selectedProductId = v4()
    newSelectedProducts.push(newSelectedProduct)
  }

  yield call(setSelectedProducts, newSelectedProducts)
  if (isModal) {
    yield put(MenuActions.closeFastBuyModalRequest(setDefaultIndex))
    yield put(ProductActions.clearProduct())
  }
  if (editProductIndex !== null) {
    yield call(toggleProductAddPopover, 'Изменения сохранены')
  } else {
    yield call(toggleProductAddPopover, 'Товар добавлен в корзину')
  }
}

function* setSelectedProducts(selectedProducts: SelectedProductDTO[]) {
  yield put(BasketActions.setSelectedProducts(selectedProducts))
  const {priceFull, saleFull} = getPriceAndSaleFull(selectedProducts)
  yield put(BasketActions.setPriceFull(priceFull))
  yield put(BasketActions.setSaleFull(saleFull))
  yield call(LSMethods.setStorageData, LSKeys.SELECTED_PRODUCTS, selectedProducts)
}

function* changeCountProductInBasket({payload}: any) {
  yield call(deletePrepareOrder)
  const isCheckActions: boolean = payload.isCheckActions

  const productIndex: number = payload.productIndex
  const count: number = payload.count
  const selectedProducts: SelectedProductDTO[] = yield select(
    (state: State) => state.basket.selectedProducts,
  )
  const newSelectedProducts = [...selectedProducts]

  if (isCanAddCount(count)) {
    const oldCount: number = newSelectedProducts[productIndex].count
    const terminalState: TerminalState = yield select((state: State) => state.terminal)
    const terminalAlias: string = terminalState?.terminalAlias
    if (!terminalAlias) {
      let ecommerceSelectedProduct: SelectedProductDTO = newSelectedProducts[productIndex]
      ecommerceSelectedProduct.count = 1
      if (oldCount < count) {
        yield call(addEcommerceProduct, [ecommerceSelectedProduct])
      } else {
        yield call(removeEcommerceProduct, [ecommerceSelectedProduct])
      }
    }
    newSelectedProducts[productIndex].count = count
    yield call(setSelectedProducts, newSelectedProducts)
    isCheckActions && (yield fork(checkActions, {payload: {isByRestaurant: !!terminalAlias}}))
  }
}

function isCanAddCount(currentCount: number): boolean {
  return currentCount > 0 && currentCount < 100
}

function* addProductWithoutOptionsInBasket({payload}: any) {
  yield call(deletePrepareOrder)

  const newSelectedProduct: SelectedProductDTO = payload

  const selectedProducts: SelectedProductDTO[] = yield select(
    (state: State) => state.basket.selectedProducts,
  )
  const newSelectedProducts: SelectedProductDTO[] = [...selectedProducts]
  let isAddCount: boolean = false
  let isErrorAddCount: boolean = false
  for (let selectedProduct of newSelectedProducts) {
    if (selectedProduct.product.productId === newSelectedProduct.product.productId) {
      let count: number = selectedProduct.count + newSelectedProduct.count
      if (count > selectedProduct.maxCountInOrder) {
        isErrorAddCount = true
        break
      }
      if (isCanAddCount(count)) {
        selectedProduct.count = count
      }
      isAddCount = true
    }
  }
  newSelectedProduct.selectedProductId = v4()
  newSelectedProduct.fullPrice = getFullPriceSelectedProduct(newSelectedProduct)

  if (!isAddCount) {
    newSelectedProducts.push(newSelectedProduct)
  }

  if (isErrorAddCount) {
    const text: string = 'Это максимум для выбранного товара'
    yield call(toggleProductAddPopover, text)
  } else {
    const terminalState: TerminalState = yield select((state: State) => state.terminal)
    const terminalAlias: string = terminalState?.terminalAlias
    if (!terminalAlias) {
      yield call(addEcommerceProduct, [newSelectedProduct])
      yield call(ymTarget, 'DOBAVILI_V_KORZINU')
    }
    yield call(setSelectedProducts, newSelectedProducts)
    yield call(toggleProductAddPopover, 'Товар добавлен в корзину')
  }
}

function* addProductWithOneOptionsInBasket({payload}: any) {
  yield call(deletePrepareOrder)

  const product: ProductDTO = payload.product
  const optionCategoryId: string = payload.optionCategoryId
  const optionId: string = payload.optionId

  const optionCategory: OptionCategoryDTO = product?.optionCategories?.find(
    (item) => item.optionCategoryId === optionCategoryId,
  )

  const option: OptionDTO = optionCategory?.options?.find((item) => item.optionId === optionId)

  const optionName: SelectedOptionNamesMap = {}
  optionName[option.name] = true

  let productVariant: ProductVariantDTO = null

  if (product.productVariants) {
    productVariant = product.productVariants.find((item) => item?.optionIds[0] === optionId)
  }

  const selectedOption: SelectedOption = {
    optionIds: [option.optionId],
    isProductVariant: optionCategory.isProductVariantDefine,
    optionCategoryId: optionCategory.optionCategoryId,
    optionCategoryName: optionCategory.name,
    optionNames: optionName,
  }

  const newSelectedProduct: SelectedProductDTO = {
    product: product,
    productId: product.productId,
    productVariantId: productVariant ? productVariant.productVariantId : null,
    selectedOptions: [selectedOption],
    price: productVariant ? productVariant.price : product.price + option.price,
    count: 1,
    selectedProductId: v4(),
    maxCountInOrder: product.maxCountInOrder,
    fullPrice: product.salePrice ? product.salePrice : product.price,
  }

  const selectedProducts: SelectedProductDTO[] = yield select(
    (state: State) => state.basket.selectedProducts,
  )

  const newSelectedProducts: SelectedProductDTO[] = [...selectedProducts]

  let isAddCount: boolean = false
  let isErrorAddCount: boolean = false
  for (let selectedProduct of newSelectedProducts) {
    if (
      selectedProduct.product.productId === newSelectedProduct.product.productId &&
      selectedProduct.productVariantId === newSelectedProduct.productVariantId
    ) {
      let count: number = selectedProduct.count + newSelectedProduct.count
      if (count > selectedProduct.maxCountInOrder) {
        isErrorAddCount = true
        break
      }
      if (isCanAddCount(count)) {
        selectedProduct.count = count
      }
      isAddCount = true
    }
  }

  if (!isAddCount) {
    newSelectedProducts.push(newSelectedProduct)
  }
  if (isErrorAddCount) {
    const text: string = 'Это максимум для выбранного товара'
    yield call(toggleProductAddPopover, text)
  } else {
    const terminalState: TerminalState = yield select((state: State) => state.terminal)
    const terminalAlias: string = terminalState?.terminalAlias
    if (!terminalAlias) {
      yield call(addEcommerceProduct, [newSelectedProduct])
      yield call(ymTarget, 'DOBAVILI_V_KORZINU')
    }
    yield call(setSelectedProducts, newSelectedProducts)
    yield call(toggleProductAddPopover, 'Товар добавлен в корзину')
  }
}

function* deleteProductFromBasket({payload}: any) {
  yield call(deletePrepareOrder)
  const isCheckActions: boolean = payload.isCheckActions

  const selectedProducts: SelectedProductDTO[] = yield select(
    (state: State) => state.basket.selectedProducts,
  )

  const deleteProductIndex: number = payload.index

  const newSelectedProducts: SelectedProductDTO[] = [...selectedProducts]
  newSelectedProducts.splice(deleteProductIndex, 1)

  const terminalState: TerminalState = yield select((state: State) => state.terminal)
  const terminalAlias: string = terminalState?.terminalAlias
  if (!terminalAlias) {
    yield call(removeEcommerceProduct, [selectedProducts[deleteProductIndex]])
  }

  yield call(setSelectedProducts, newSelectedProducts)

  if (newSelectedProducts?.length === 0) {
    yield call(clearAvailableActions)
    yield put(BasketActions.setCoupon(''))
  } else {
    isCheckActions && (yield fork(checkActions, {payload: {isByRestaurant: !!terminalAlias}}))
    isCheckActions && (yield fork(checkRecommendationsProducts))
  }
}

function* openProductScreenForEdit({payload}: any) {
  const selectedProduct: SelectedProductDTO = payload.selectedProduct
  const editProductIndex: number = payload.editProductIndex
  yield put(BasketActions.setEditProductIndex(editProductIndex))
  yield call(selectProduct, selectedProduct.product, null, null, selectedProduct)
}

function* toggleProductAddPopover(text: string) {
  yield put(ProductActions.setProductPopoverText(null))
  yield put(ProductActions.setProductPopoverText(text))
  yield delay(2000)
  yield put(ProductActions.setProductPopoverText(null))
}

function* initializeBasket() {
  const selectedProducts: SelectedProductDTO[] = yield call(
    LSMethods.getStorageData,
    LSKeys.SELECTED_PRODUCTS,
    [],
  )

  if (selectedProducts.length !== 0) {
    yield put(BasketActions.setSelectedProducts(selectedProducts))
    const {priceFull, saleFull} = getPriceAndSaleFull(selectedProducts)
    yield put(BasketActions.setPriceFull(priceFull))
    yield put(BasketActions.setSaleFull(saleFull))
  }
}

function* clearBasket({payload}: any) {
  const isSaveOrder: boolean = payload
  const selectedProducts: SelectedProductDTO[] = yield select(
    (state: State) => state.basket.selectedProducts,
  )
  if (!isSaveOrder) {
    const terminalState: TerminalState = yield select((state: State) => state.terminal)
    const terminalAlias: string = terminalState?.terminalAlias
    if (!terminalAlias) {
      yield call(removeEcommerceProduct, [...selectedProducts])
    }
  }
  yield call(LSMethods.setStorageData, LSKeys.SELECTED_PRODUCTS, [])
  yield call(deletePrepareOrder)
  yield put(BasketActions.setPriceFull(0))
  yield put(BasketActions.setSaleFull(0))
  yield put(BasketActions.clearBasketSuccess())
  yield put(BasketActions.setCoupon(''))
  yield call(clearAvailableActions)
}

function* addComboProductInBasket({payload}: any) {
  yield call(deletePrepareOrder)

  const selectedProduct: SelectedProductDTO = payload

  const editProductIndex: number = yield select((state: State) => state.basket.editProductIndex)

  let isAddNewProduct: boolean = false

  for (let key in selectedProduct.selectedProducts) {
    const newSelectedComboProducts: SelectedProductDTO[] = []
    if (selectedProduct.selectedProducts[key].length > 1) {
      isAddNewProduct = true
    }
    for (let selectedComboProduct of selectedProduct.selectedProducts[key]) {
      if (selectedComboProduct.isActive) {
        newSelectedComboProducts.push(selectedComboProduct)
      }
      if (selectedComboProduct.product?.optionCategories?.length > 0) {
        isAddNewProduct = true
      }
    }
    selectedProduct.selectedProducts[key] = newSelectedComboProducts
  }

  const selectedProducts: SelectedProductDTO[] = yield select(
    (state: State) => state.basket.selectedProducts,
  )

  const terminalState: TerminalState = yield select((state: State) => state.terminal)
  const terminalAlias: string = terminalState?.terminalAlias
  if (!terminalAlias) {
    if (editProductIndex !== null) {
      yield call(removeEcommerceProduct, [selectedProducts[editProductIndex]])
      yield call(addEcommerceProduct, [selectedProduct])
    } else {
      yield call(addEcommerceProduct, [selectedProduct])
      yield call(ymTarget, 'DOBAVILI_V_KORZINU')
    }
  }

  const newSelectedProducts: SelectedProductDTO[] = [...selectedProducts]

  selectedProduct.fullPrice = getFullPriceSelectedProduct(selectedProduct)
  if (editProductIndex !== null) {
    newSelectedProducts[editProductIndex] = {...selectedProduct, selectedOptions: []}
  } else if (isAddNewProduct) {
    newSelectedProducts.push({...selectedProduct, selectedOptions: []})
  } else {
    const indexCombo: number = newSelectedProducts.findIndex(
      (item) => item.product.productId === selectedProduct.product.productId,
    )
    if (indexCombo !== -1) {
      const count: number = newSelectedProducts[indexCombo].count + 1
      if (isCanAddCount(count)) {
        newSelectedProducts[indexCombo] = {
          ...newSelectedProducts[indexCombo],
          count,
        }
      }
    } else {
      newSelectedProducts.push({...selectedProduct, selectedOptions: []})
    }
  }

  yield call(setSelectedProducts, newSelectedProducts)
  yield call(
    toggleProductAddPopover,
    editProductIndex !== null ? 'Изменения сохранены' : 'Товар добавлен в корзину',
  )
}

function* checkActions({payload}: any) {
  const terminalState: TerminalState = yield select((state: State) => state.terminal)
  const terminalAlias: string = terminalState?.terminalAlias
  let newSelectedActionCouponId: string = payload.newSelectedActionCouponId
  const isByRestaurant: string = payload.isByRestaurant

  const selectedProducts: SelectedProductDTO[] = yield select(
    (state: State) => state.basket.selectedProducts,
  )
  const city: CityDTO = yield select((state: State) => state.city.selectedCity)

  if (selectedProducts.length !== 0) {
    yield put(BasketActions.setIsUpdateCheckAction(true))

    try {
      const selectedCouponDefinitionId: string = newSelectedActionCouponId
        ? newSelectedActionCouponId
        : yield select((state: State) => state.action.selectedCouponDefinitionId)
      let response: SaleResponse
      if (isByRestaurant) {
        response = yield call(getAvailableActionsByRestaurant, selectedCouponDefinitionId)
      } else {
        response = yield call(
          getAvailableActions,
          selectedProducts,
          city.cityId,
          selectedCouponDefinitionId,
        )
      }

      const newSelectedProducts: SelectedProductDTO[] = [...selectedProducts]

      for (let sp of newSelectedProducts) {
        for (let p of response.products) {
          if (sp.selectedProductId === p.selectedProductId) {
            sp.sales = p.sales
          }
        }
      }
      yield put(BasketActions.setSelectedProducts(newSelectedProducts))
      yield put(BasketActions.setPriceFull(response.fullPrice))
      yield put(BasketActions.setSaleFull(response.fullSale))
      yield put(BasketActions.setDeliverySale(response.deliverySale))
      yield put(BasketActions.setIsSuccessPersonalCoupon(response.isSuccessPersonalCoupon))
      yield put(BasketActions.setIsErrorUpdateCheckAction(false))
    } catch (e) {
      const contactDetails: ContactDetailsMap = yield select(
        (state: State) => state.contactDetails.contactDetailsMap,
      )
      let phoneNumber: string = null
      if (contactDetails[city.cityId]?.errorPhoneNumber) {
        phoneNumber = contactDetails[city.cityId].errorPhoneNumber
      } else {
        phoneNumber = contactDetails.default.errorPhoneNumberDefault
      }
      let error: ResponseError = null
      if (isByRestaurant) {
        error = {
          status: 503,
          errorMessage: `Не удалось определить стоимость заказа. Обновите страницу или обратитесь в службу поддержки по номеру ${phoneNumber}`,
        }
        yield put(BasketActions.setIsErrorUpdateCheckAction(true))
      } else {
        error = {
          status: 503,
          errorMessage: `Не удалось определить стоимость. Обновите страницу или обратитесь в службу поддержки по номеру ${phoneNumber}`,
        }
      }
      yield put(ErrorActions.setError(error))
      if (!terminalAlias && !isByRestaurant) {
        yield put(BasketActions.setPriceFull(undefined))
        yield put(BasketActions.setSaleFull(undefined))
      }
      console.log('Error basket/checkActions', e)
    } finally {
      yield put(BasketActions.setIsUpdateCheckAction(false))
    }
  }
}

function* changePriceFull(selectedActionCouponId?: string) {
  const selectedProducts: SelectedProductDTO[] = yield select(
    (state: State) => state.basket.selectedProducts,
  )
  yield put(
    BasketActions.setPriceFull(
      getPriceAndSaleFull(selectedProducts, selectedActionCouponId).priceFull,
    ),
  )
}

function* changeSaleFull(selectedActionCouponId?: string) {
  const selectedProducts: SelectedProductDTO[] = yield select(
    (state: State) => state.basket.selectedProducts,
  )
  yield put(
    BasketActions.setSaleFull(
      getPriceAndSaleFull(selectedProducts, selectedActionCouponId).saleFull,
    ),
  )
}

function* getPersonalCouponSale() {
  yield call(checkActions, {payload: {type: 'load', newSelectedActionCouponId: 'PERSONAL_COUPON'}})
}

function* addEcommerceProduct(selectedProducts: SelectedProductDTO[]) {
  let products: EcommerceProductType[] = []
  for (let selectedProduct of selectedProducts || []) {
    products.push({
      id: selectedProduct.product.productId,
      name: selectedProduct.product.name,
      price: selectedProduct.price,
      quantity: selectedProduct.count,
    })
  }
  ecommerceTarget(ECOMMERCE_ACTION_TYPE.ADD, products)
}

function* removeEcommerceProduct(selectedProducts: SelectedProductDTO[]) {
  let products: EcommerceProductType[] = []
  for (let selectedProduct of selectedProducts || []) {
    if (selectedProduct.product?.productId) {
      products.push({
        id: selectedProduct.product.productId,
        name: selectedProduct.product.name,
        price: selectedProduct.price,
        quantity: selectedProduct.count,
      })
    }
  }
  ecommerceTarget(ECOMMERCE_ACTION_TYPE.REMOVE, products)
}

export {
  addProductWithOptionsInBasket,
  changeCountProductInBasket,
  addProductWithoutOptionsInBasket,
  deleteProductFromBasket,
  openProductScreenForEdit,
  initializeBasket,
  clearBasket,
  addProductWithOneOptionsInBasket,
  addComboProductInBasket,
  setSelectedProducts,
  toggleProductAddPopover,
  checkActions,
  changePriceFull,
  changeSaleFull,
  getPersonalCouponSale,
  addEcommerceProduct,
}
