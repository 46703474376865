import {MenuCategoryDTO, ProductDTO} from 'grilnica-store-share'

export const getUrl = (product: ProductDTO, categoryList: MenuCategoryDTO[]): string => {
  let alias: string = null
  let childAlias: string = null
  for (let category of categoryList) {
    if (category.menuCategoryId === product.menuCategoryId) {
      alias = category.alias
      if (category.parentMenuCategoryId) {
        for (let item of categoryList) {
          if (category.parentMenuCategoryId === item.menuCategoryId) {
            childAlias = alias
            alias = item.alias
            break
          }
        }
      }
      break
    }
  }

  return '/menu/' + alias + (childAlias ? '/' + childAlias : '')
}
