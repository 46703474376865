import {DeliveryInfoRequestDTO} from 'grilnica-store-share'
import {LatLng} from '../../types/address/LatLng'
import {apiGet, apiPost} from '../../api'
import {CONSTANTS} from '../../../constants'

async function getAddressByCoordinatesService(coordinates: LatLng, cityId: string) {
  const data: any = {
    lat: coordinates.latitude,
    lon: coordinates.longitude,
    radius_meters: 20,
  }

  return await apiPost(CONSTANTS.apiStoreUrl + '/geo-coding/get-address-by-coordinates', data, {
    isKey: true,
    cityId: cityId,
  })
}

async function searchAddressesBySearchValueService(searchValue: string, fiasId: string, cityId) {
  const data: any = {
    query: searchValue,
    count: 20,
    locations: [
      {
        city_fias_id: fiasId,
      },
    ],
  }
  return await apiPost(
    CONSTANTS.apiStoreUrl + '/geo-coding/search-addresses-by-search-value',
    data,
    {
      isKey: true,
      cityId: cityId,
    },
  )
}

async function loadAreaOfDeliveryListService(cityId: string) {
  return await apiGet(CONSTANTS.apiStoreUrl + '/delivery/area-of-delivery/find-by-city/' + cityId, {
    cityId: cityId,
  })
}

async function searchAddressByAddressStrService(address: string, cityId: string) {
  const url: any = new URL(CONSTANTS.apiStoreUrl + '/geo-coding/search-address-by-address-str')
  url.searchParams.append('address', address)
  return await apiGet(url, {
    isBasicHeaders: false,
    isKey: true,
    cityId: cityId,
  })
}

async function getDeliveryPriceService(deliveryInfoRequest: DeliveryInfoRequestDTO) {
  return await apiPost(CONSTANTS.apiStoreUrl + '/delivery/info', deliveryInfoRequest, {
    cityId: deliveryInfoRequest.cityId,
  })
}

export {
  getAddressByCoordinatesService,
  searchAddressesBySearchValueService,
  loadAreaOfDeliveryListService,
  searchAddressByAddressStrService,
  getDeliveryPriceService,
}
