import {ResponseError, TokenDTO} from 'grilnica-share'
import axios, {AxiosRequestConfig} from 'axios'
import {Buffer} from 'buffer'
import {LSKeys, LSMethods} from '../storage'
import {CONSTANTS} from '../../constants'
import {getPastTime, getTimeNow} from '../utils/common/TimeUtils'
import {SourceTypeEnum} from 'grilnica-store-share'
import {RestConfigType} from './RestConfigType'

const ERROR_MESSAGE_DEFAULT = 'Произошла неизвестная ошибка. Обратитесь в службу поддержки.'

const __DEV__ = process.env.NODE_ENV === 'development'

declare var LOG_TYPE: 'fullMs' | 'errorMs'
declare var REQUEST_TIMEOUT: number

function getAuthorizationHeaderBasic() {
  const login: string = CONSTANTS.subsystemLogin
  const password: string = CONSTANTS.subsystemPassword
  return 'Basic ' + Buffer.from(login + ':' + password).toString('base64')
}

function getToken(): TokenDTO {
  return LSMethods.getStorageData(LSKeys.AUTHORIZATION_CLIENT_TOKEN, null)
}

function getTerminalAlias(): string {
  const terminalAlias: string = LSMethods.getStorageData(LSKeys.TERMINAL_ALIAS, null)
  return terminalAlias ?? null
}

function getAuthorizationHeader(terminalAlias: string) {
  let headerMap: any = {}
  if (terminalAlias) {
    headerMap['Authorization'] = `Terminal ${terminalAlias}`
  }
  let token: TokenDTO = getToken()
  if (token) {
    headerMap['authorizationClient'] = 'Bearer ' + token.accessToken
  }
  return headerMap
}

function getHeaders(isKey: boolean, terminalAlias: string) {
  return Object.assign(
    isKey
      ? {
          'Content-Type': 'application/json',
          // KEY: !__DEV__ ? '123' : 'hsfdFsl2d', //hsfdFsl2d, //TODO:: ДЛЯ ТЕСТОВ, с беком на тест или прод
          KEY: __DEV__ ? '123' : 'hsfdFsl2d', //hsfdFsl2d, //TODO:: вынести ключ
        }
      : {
          'Content-Type': 'application/json',
        },
    getAuthorizationHeader(terminalAlias),
  )
}

async function handleRequest(
  url: string,
  options: AxiosRequestConfig,
  isKey: boolean,
  cityId: string,
): Promise<any> {
  let time = getTimeNow()
  try {
    const terminalAlias: string = getTerminalAlias()
    const headers = Object.assign(getHeaders(isKey, terminalAlias), options.headers || {})
    const config: AxiosRequestConfig = {
      url: url,
      ...options,
      withCredentials: true,
      headers: {
        ...headers,
        type: terminalAlias ? SourceTypeEnum.TERMINAL : SourceTypeEnum.SITE,
        version: '1.8',
        cityId: cityId,
        'Cache-Control': 'no-cache',
      },
      validateStatus: function (status) {
        return true
      },
    }
    const response = await axios.request(config)
    if (response.status >= 200 && response.status < 300) {
      if (LOG_TYPE === 'fullMs') {
        console.log(`${url} = ${getPastTime(time)}ms`)
      }
      return response.data
    } else {
      let error: ResponseError = new ResponseError(
        response.status,
        response.data?.errorMessage,
        response.data?.objectsMap,
        response.data?.code,
        response.data?.alias,
      )
      if (!error.errorMessage) {
        error.errorMessage = ERROR_MESSAGE_DEFAULT
      }
      throw error
    }
  } catch (e) {
    // console.log('e=', JSON.stringify(e))
    // if (e.message === 'Request aborted') { //Возникает в FireFox
    //   return
    // }
    let err
    if (e.status && e.errorMessage) {
      err = e
    } else {
      if (LOG_TYPE === 'errorMs' || LOG_TYPE === 'fullMs') {
        console.log(`${url} = ${getPastTime(time)}ms`)
      }
      err = new ResponseError(500, ERROR_MESSAGE_DEFAULT)
    }
    if (LOG_TYPE === 'errorMs' || LOG_TYPE === 'fullMs') {
      console.log(`${url} = ${getPastTime(time)}ms`)
    }

    throw err
  }
}

async function apiGet(url: string, restConfig: RestConfigType): Promise<any> {
  const {
    options = null,
    isBasicHeaders = false,
    isKey = false,
    timeout = null,
    headers = null,
    cityId,
  } = restConfig
  let urlObject = new URL(url)
  if (options) {
    for (let param in options) {
      urlObject.searchParams.append(param, options[param])
    }
  }

  const config: AxiosRequestConfig = {
    method: 'GET',
    headers: isBasicHeaders ? {authorizationClient: getAuthorizationHeaderBasic()} : headers || {},
    timeout: timeout || REQUEST_TIMEOUT,
  }

  return await handleRequest(urlObject.toString(), config, isKey, cityId)
}

async function apiPost(url: string, data: any, restConfig: RestConfigType): Promise<any> {
  const {options = null, isKey = false, timeout = null, cityId} = restConfig
  let urlObject = new URL(url)
  if (options) {
    for (let param in options) {
      urlObject.searchParams.append(param, options[param])
    }
  }

  const config: AxiosRequestConfig = {
    method: 'POST',
    data: JSON.stringify(data),
    timeout: timeout || REQUEST_TIMEOUT,
  }

  return handleRequest(urlObject.toString(), config, isKey, cityId)
}

async function apiPostFile(url: string, formData: any, restConfig: RestConfigType): Promise<any> {
  const {options = null, timeout = null, cityId} = restConfig
  const config: AxiosRequestConfig = {
    method: 'POST',
    data: formData,
    timeout: timeout || REQUEST_TIMEOUT,
    ...options,
  }
  return handleRequest(url, config, null, cityId)
}

export {apiGet, apiPost, apiPostFile}
