declare var TYPE_SITE_BUILD: string
declare var SELECTED_CITY_ALIAS: string
declare var Y_COUNTER: number

function gaTarget(actionName: string, categoryName: string): void {
  try {
    if (TYPE_SITE_BUILD === 'production') {
      let actionNameByCity: string = actionName
      let categoryNameByCity: string = categoryName

      if (SELECTED_CITY_ALIAS !== 'biysk') {
        if (actionName === 'click-made-order') {
          actionNameByCity = 'OFORMLEN'
          categoryNameByCity = 'korzina'
        } else if (actionName === 'click-call') {
          actionNameByCity = 'trubka'
          categoryNameByCity = 'call'
        } else if (actionName === 'click-open-basket') {
          actionNameByCity = 'click'
          categoryNameByCity = 'cart'
        } else {
          actionNameByCity = null
          categoryNameByCity = null
        }
      }
      if (Y_COUNTER && actionNameByCity && categoryNameByCity) {
        // @ts-ignore
        // eslint-disable-next-line no-undef
      }
    }
  } catch (e) {
    console.log(e)
  }
}

export {gaTarget}
