import React, {useMemo} from 'react'
import {mailSVG, siteSVG, telegramSVG, whatsAppSVG} from './CommunicationIcon'

interface CommunicationMethodCardProps {
  className?: string
  text: string
  icon: 'site' | 'whatsApp' | 'mail' | 'telegram'
  isActive: boolean
  onClick: () => void
}

const CommunicationMethodCard: React.FC<CommunicationMethodCardProps> = ({
  className,
  text,
  icon,
  isActive,
  onClick,
}): React.ReactElement => {
  const getIcon = useMemo(() => {
    switch (icon) {
      case 'site':
        return siteSVG
      case 'whatsApp':
        return whatsAppSVG
      case 'mail':
        return mailSVG
      case 'telegram':
        return telegramSVG
    }
  }, [icon])

  return (
    <div
      className={
        'd-flex flex-column align-items-center cursor-pointer communication-card ' +
        (isActive ? 'active' : ' ') +
        (className ? className : '')
      }
      onClick={onClick}>
      <div className={'mb-x2 text-center communication-icon'}>{getIcon}</div>
      <p className={'text mb-0'}>{text}</p>
    </div>
  )
}

export {CommunicationMethodCard}
