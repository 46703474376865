import {DeliveryTypeEnum, PrepareOrderTime} from 'grilnica-store-share'
import moment from 'moment-timezone'
import {RestaurantDTO} from 'grilnica-share'
import {RestaurantPeriodOfWorkDTO} from 'grilnica-share/src/restaurant/RestaurantPeriodOfWorkDTO'
import {RestaurantTime} from './types/RestaurantTime'

export class PrepareOrderTimeEntity {
  private time: Date
  private maxTime: Date
  private minTime: Date
  private maxDate: Date
  private minDate: Date
  private countDaysForDelivery: number
  private prepareTime: PrepareOrderTime
  private restaurant: RestaurantDTO
  private deliveryType: DeliveryTypeEnum
  private deliveryTime: number
  private timeCooking: number
  private excludedTimes: Date[]
  private infoOfQuickly: string
  private setPrepareOrderField: (key: string, value: any, parentKey?: string) => void
  private timeInterval: number

  constructor(
    prepareTime: PrepareOrderTime,
    restaurant: RestaurantDTO,
    deliveryType: DeliveryTypeEnum,
    countDaysForDelivery: number,
    deliveryTime: number,
    timeCooking: number,
    setPrepareOrderField: (key: string, value: any, parentKey?: string) => void,
    timeInterval: number,
  ) {
    this.prepareTime = prepareTime
    this.restaurant = restaurant
    this.deliveryType = deliveryType
    this.countDaysForDelivery = countDaysForDelivery
    this.deliveryTime = deliveryTime
    this.timeCooking = timeCooking
    this.infoOfQuickly = null
    this.timeInterval = timeInterval
    this.setPrepareOrderField = setPrepareOrderField
    this.createMaxDate()
    this.createMinDate()
    this.createMinTime()
    this.createMaxTime()
    this.checkInfoOfQuickly()
  }

  private createMaxDate(): void {
    this.maxDate = this.countDaysForDelivery
      ? moment().add(this.countDaysForDelivery, 'days').toDate()
      : moment().add(1, 'month').toDate()
  }

  private createMinDate(): void {
    const currentHour: number = moment().hours()
    const currentDate: number = moment().date()
    if (this.deliveryType === DeliveryTypeEnum.DELIVERY) {
      //проверка на дату доставки при вечернем заказе
      if (
        this.getRoundTime(
          moment()
            .add(this.timeCooking + this.deliveryTime, 'minutes')
            .toDate(),
        ).getDate() ===
        currentDate + 1
      ) {
        this.minDate = moment().add(1, 'day').toDate()
      } else {
        this.minDate = moment().toDate()
      }
      // если самовывоз
    } else {
      if (currentHour === 23) {
        this.minDate = moment().add(1, 'day').toDate()
      } else if (this.restaurant) {
        const timeFrom: RestaurantTime = this.getRestaurantTime('timeFrom', moment().isoWeekday())

        const timeTo: RestaurantTime = this.getRestaurantTime('timeTo', moment().isoWeekday())

        if (
          moment(
            this.getRoundTime(
              moment()
                .add(this.timeCooking + 10, 'minutes')
                .toDate(),
            ),
          ).isSameOrAfter(moment().hours(timeTo.hour).minutes(timeTo.minutes)) &&
          timeTo.hour > timeFrom.hour
        ) {
          this.minDate = moment().add(1, 'day').toDate()
        } else {
          this.minDate = moment().toDate()
        }
      } else {
        this.minDate = moment().toDate()
      }
    }
  }

  private createMinTime(): void {
    if (this.deliveryType === DeliveryTypeEnum.DELIVERY) {
      if (this.isCurrentDate(this.prepareTime.orderTime as Date)) {
        this.minTime = moment()
          .add(this.timeCooking + this.deliveryTime, 'minutes')
          .toDate()
      } else if (this.isTransitionNextDay(this.prepareTime.orderTime as Date)) {
        this.minTime = moment()
          .add(this.timeCooking + this.deliveryTime, 'minutes')
          .toDate()
      } else {
        this.minTime = moment().hours(0).minutes(0).toDate()
      }
      // если самовывоз
    } else if (this.restaurant) {
      const timeFrom: RestaurantTime = this.getRestaurantTime('timeFrom')
      const timeTo: RestaurantTime = this.getRestaurantTime('timeTo')

      this.minTime = this.calculateTime(this.prepareTime.orderTime as Date, timeFrom, timeTo)
    } else {
      this.minTime = moment().hours(0).minutes(0).toDate()
    }
    this.minTime = this.getRoundTime(this.minTime)
  }

  private createMaxTime(): void {
    if (this.deliveryType === DeliveryTypeEnum.PICKUP && this.restaurant) {
      const timeFrom: RestaurantTime = this.getRestaurantTime('timeFrom')
      const timeTo: RestaurantTime = this.getRestaurantTime('timeTo')

      if (this.isAroundClockRestaurant() || (timeTo.hour > 0 && timeTo.hour < timeFrom.hour)) {
        this.maxTime = moment().hours(23).minutes(59).second(59).toDate()
      } else {
        this.maxTime = moment()
          .hours(timeTo.hour)
          .minutes(timeTo.minutes)
          .subtract(this.timeCooking, 'minutes')
          .toDate()
      }
    } else {
      this.maxTime = moment().hours(23).minutes(30).toDate()
    }
  }

  private createTime(selectedTime: Date): void {
    if (this.deliveryType === DeliveryTypeEnum.DELIVERY) {
      if (this.isCurrentDate(selectedTime)) {
        this.time = moment()
          .add(this.timeCooking + this.deliveryTime, 'minutes')
          .toDate()
      } else if (this.isTransitionNextDay(selectedTime)) {
        this.time = moment()
          .add(this.timeCooking + this.deliveryTime, 'minutes')
          .toDate()
      } else {
        this.time = moment(selectedTime).toDate()
      }
      //если самовывоз
    } else if (this.restaurant) {
      const timeFrom: RestaurantTime = this.getRestaurantTime(
        'timeFrom',
        moment(selectedTime).isoWeekday(),
      )
      const timeTo: RestaurantTime = this.getRestaurantTime(
        'timeTo',
        moment(selectedTime).isoWeekday(),
      )

      this.time = this.calculateTime(selectedTime, timeFrom, timeTo)
    } else {
      this.time = moment().hours(0).minutes(0).toDate()
    }
    this.time = this.getRoundTime(this.time)
  }

  private calculateTime(
    selectedTime: Date,
    timeFrom: RestaurantTime,
    timeTo: RestaurantTime,
  ): Date {
    let time: Date

    const currentHour: number = moment().hours()
    const currentMin: number = moment().minutes()

    const prevDayWeekday: number = moment(selectedTime).isoWeekday() - 1

    const prevTimeTo: RestaurantTime = this.getRestaurantTime(
      'timeTo',
      moment(selectedTime).isoWeekday() - 1,
    )
    if (selectedTime) {
      // если конец рабочего времени переходит на следующий день (ресторан не круглосуточный)
      if (this.isEndWorkToNextDayRestaurant(prevDayWeekday)) {
        if (this.isCurrentDate(selectedTime)) {
          // если текущее время ДО времени открытия ресторана
          if (moment().isBefore(moment().hours(timeFrom.hour).minutes(timeFrom.minutes))) {
            // если текущее время ПОСЛЕ времени закрытия ресторана
            if (
              moment().isAfter(
                moment()
                  .hours(prevTimeTo.hour)
                  .minutes(prevTimeTo.minutes)
                  .subtract(this.timeCooking, 'minutes'),
              )
            ) {
              time = moment(selectedTime)
                .hours(timeFrom.hour)
                .minutes(timeFrom.minutes)
                .add(this.timeCooking, 'minutes')
                .toDate()
            } else {
              time = moment(selectedTime).add(this.timeCooking, 'minutes').toDate()
            }
          } else {
            time = moment(selectedTime)
              .hours(currentHour)
              .minutes(currentMin)
              .add(this.timeCooking, 'minutes')
              .toDate()
          }
        } else if (this.isTransitionNextDay(selectedTime)) {
          time = moment().add(this.timeCooking, 'minutes').toDate()
        } else {
          time = moment(selectedTime).hours(0).minutes(0).toDate()
        }
      } else {
        if (this.isCurrentDate(selectedTime)) {
          //провека времени до открытия ресторана
          if (
            moment(moment().toDate()).isSameOrBefore(
              moment().hours(timeFrom.hour).minutes(timeFrom.minutes),
            )
          ) {
            time = moment(selectedTime)
              .hours(timeFrom.hour)
              .minutes(timeFrom.minutes)
              .add(this.timeCooking, 'minutes')
              .toDate()
          } else {
            time = moment(selectedTime)
              .hours(currentHour)
              .minutes(currentMin)
              .add(this.timeCooking, 'minutes')
              .toDate()
          }
        } else {
          // если ресторан круглосуточный
          if (this.isAroundClockRestaurant()) {
            // переход на следующий день у круглосуточного ресторана
            if (this.isTransitionNextDay(selectedTime)) {
              time = moment().add(this.timeCooking, 'minutes').toDate()
            } else {
              time = moment(selectedTime).hours(timeFrom.hour).minutes(timeFrom.minutes).toDate()
            }
          } else if (this.isTransitionNextDay(selectedTime)) {
            if (
              moment(selectedTime).add(currentMin, 'minutes').hours() < timeTo.hour &&
              timeTo.hour < timeFrom.hour
            ) {
              time = moment().add(this.timeCooking, 'minutes').toDate()
            } else {
              time = moment(selectedTime)
                .hours(timeFrom.hour)
                .minutes(timeFrom.minutes)
                .add(this.timeCooking, 'minutes')
                .toDate()
            }
          } else {
            time = moment(selectedTime)
              .hours(timeFrom.hour)
              .minutes(timeFrom.minutes)
              .add(this.timeCooking, 'minutes')
              .toDate()
          }
        }
      }
    } else {
      time = moment().hours(0).minutes(0).toDate()
    }
    return time
  }

  private checkInfoOfQuickly(): void {
    if (this.deliveryType === DeliveryTypeEnum.PICKUP && this.restaurant) {
      if (this.isCurrentDate(moment().toDate())) {
        const timeFrom: RestaurantTime = this.getRestaurantTime('timeFrom')
        const timeTo: RestaurantTime = this.getRestaurantTime('timeTo')

        // если конец рабочего времени ресторана переходит на следующий день
        if (this.isEndWorkToNextDayRestaurant(moment().isoWeekday())) {
          if (
            moment(moment().toDate()).isAfter(moment().hours(timeTo.hour).minutes(timeTo.minutes))
          ) {
            if (
              moment(moment().toDate()).isBefore(
                moment().hours(timeFrom.hour).minutes(timeFrom.minutes),
              )
            ) {
              this.infoOfQuickly = `Ресторан закрыт до ${timeFrom.hour}:${
                timeFrom.minutes === 0 ? '00' : timeFrom.minutes
              }. Вы сможете получить заказ после открытия.`
            } else {
              this.infoOfQuickly = null
            }
          } else {
            this.infoOfQuickly = null
          }
        } else if (
          moment(moment().toDate()).isBefore(
            moment().hours(timeFrom.hour).minutes(timeFrom.minutes),
          ) ||
          moment(moment().toDate()).isAfter(moment().hours(timeTo.hour).minutes(timeTo.minutes))
        ) {
          this.infoOfQuickly = `Ресторан закрыт до ${timeFrom.hour}:${
            timeFrom.minutes === 0 ? '00' : timeFrom.minutes
          }. Вы сможете получить заказ после открытия.`
        } else {
          this.infoOfQuickly = null
        }
      }
    }
  }

  private getRoundTime(selectedTime: Date): Date {
    const currentHour: number = moment(selectedTime).hours()
    const currentMin: number = moment(selectedTime).minutes()
    let newMinute: number = 0
    newMinute = Math.ceil(currentMin / this.timeInterval) * this.timeInterval
    if (newMinute === 60) {
      selectedTime = moment(selectedTime)
        .minutes(0)
        .hours(currentHour + 1)
        .toDate()
    } else {
      selectedTime = moment(selectedTime).minutes(newMinute).toDate()
    }

    return selectedTime
  }

  private isCurrentDate(selectedTime: Date): boolean {
    return moment().isSame(selectedTime, 'day')
  }

  private isTransitionNextDay(selectedTime: Date): boolean {
    const currentDate: number = moment().date()

    return (
      moment()
        .add(this.deliveryTime + this.timeCooking, 'minutes')
        .date() ===
        currentDate + 1 && moment(selectedTime).date() === currentDate + 1
    )
  }

  private getRestaurantTime(type: 'timeTo' | 'timeFrom', weekDay?: number): RestaurantTime {
    let currentWeekDay: number = weekDay
      ? weekDay
      : this.prepareTime.orderTime
      ? moment(this.prepareTime.orderTime).isoWeekday()
      : moment().isoWeekday()

    const periodOfWork: RestaurantPeriodOfWorkDTO = this.restaurant.periodOfWorkList?.find((item) =>
      item.weekdays.includes(currentWeekDay),
    )
    if (type === 'timeFrom') {
      return {
        hour: moment().hours(0).minutes(0).add(periodOfWork.timeFrom, 'hours').hours(),
        minutes: moment().hours(0).minutes(0).add(periodOfWork.timeFrom, 'minutes').minutes(),
      }
    } else {
      const timeToHour: number = moment()
        .hours(0)
        .minutes(0)
        .add(periodOfWork.timeTo, 'hours')
        .hours()
      const timeToMinutes: number = moment()
        .hours(0)
        .minutes(0)
        .add(periodOfWork.timeTo, 'minutes')
        .minutes()
      return {
        hour: timeToHour === 0 && timeToMinutes === 0 ? 24 : timeToHour,
        minutes: timeToMinutes,
      }
    }
  }

  private isAroundClockRestaurant(): boolean {
    // круглосуточный ресторан
    const timeFrom: RestaurantTime = this.getRestaurantTime('timeFrom')
    const timeTo: RestaurantTime = this.getRestaurantTime('timeTo')
    return (
      timeFrom.hour === 0 &&
      timeFrom.minutes === 0 &&
      (timeTo.hour === 0 || timeTo.hour === 24) &&
      timeTo.minutes === 0
    )
  }

  private isEndWorkToNextDayRestaurant(weekday: number): boolean {
    // ресторан не круглосуточный, но время завершения работы переходит на следующий день
    const timeFrom: RestaurantTime = this.getRestaurantTime('timeFrom', weekday)
    const timeTo: RestaurantTime = this.getRestaurantTime('timeTo', weekday)

    return (
      timeFrom.hour > timeTo.hour ||
      (timeFrom.hour === timeTo.hour && timeFrom.minutes > timeTo.minutes)
    )
  }

  public changeTime(selectedTime: Date): void {
    if (moment(this.prepareTime.orderTime).date() !== moment(selectedTime).date()) {
      this.createTime(selectedTime)
    } else {
      this.time = moment(selectedTime).toDate()
    }
    console.log(JSON.stringify(this.time))
    this.time = this.getRoundTime(this.time)
  }

  public getFilterTime(time: Date): boolean {
    if (
      this.deliveryType === DeliveryTypeEnum.PICKUP &&
      this.restaurant &&
      !this.isAroundClockRestaurant()
    ) {
      const timeFrom: RestaurantTime = this.getRestaurantTime('timeFrom')

      const prevDayWeekday: number = moment(time).isoWeekday() - 1

      const prevDaytimeTo: RestaurantTime = this.getRestaurantTime('timeTo', prevDayWeekday)

      if (this.isEndWorkToNextDayRestaurant(prevDayWeekday)) {
        return !moment(time).isBetween(
          moment(time)
            .hours(prevDaytimeTo.hour)
            .minutes(prevDaytimeTo.minutes)
            .subtract(this.timeCooking, 'minutes'),
          moment(time)
            .hours(timeFrom.hour)
            .minutes(timeFrom.minutes)
            .add(this.timeCooking, 'minutes'),
        )
      }
    }
    return true
  }

  public getMaxDate(): Date {
    return this.maxDate
  }

  public getMinDate(): Date {
    return this.minDate
  }

  public getTime(isAddMinutes): string {
    return moment(this.time)
      .add(isAddMinutes ? this.timeInterval : 0, 'minutes')
      .format('YYYY-MM-DDTHH:mm')
  }

  public getMaxTime(): Date {
    return this.maxTime
  }

  public getMinTime(): Date {
    return this.minTime
  }

  public getExcludedTimes(): Date[] {
    return this.excludedTimes
  }

  public getInfoOfQuickly(): string {
    return this.infoOfQuickly
  }
}
