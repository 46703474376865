import React from 'react'
import {CONSTANTS} from '../../../constants'
import {Route, Switch} from 'react-router-dom'
import {SubsystemInfo} from 'grilnica-share-client'
import App from './components/App'
import {createAppStore, isServer} from '../../store'
import MainLayout from './layouts/MainLayout'
import {ErrorInitializeEnum} from '../../types/common/ErrorInitializeEnum'
import {ErrorLoadMenuComponent} from '../components/common/ErrorLoadMenuComponent'

const SUBSYSTEM: SubsystemInfo = {
  subsystemId: CONSTANTS.subsystemId,
  name: CONSTANTS.subsystemName,
  imageUrl: null,
}
declare var __STORE__: any

const store = createAppStore(__STORE__)

const Spa: React.FC = ({}): React.ReactElement => {
  let urlPrefix: string = ''
  let isQrAppPage: boolean = false
  let isTestSite: boolean = false
  let content: React.ReactElement
  if (!isServer) {
    let errorInitialize: ErrorInitializeEnum = store.getState().initialize.errorInitialize
    if (errorInitialize && errorInitialize === ErrorInitializeEnum.ERROR_LOAD_SELECTED_CITY) {
      content = <ErrorLoadMenuComponent className={'py-x10'} />
    } else {
      content = (
        <Switch>
          <Route path={'/'} component={MainLayout} />
        </Switch>
      )

      const urlParts: string[] = window.location.href.split('/')
      const qrAppAlias = urlParts[3]

      if (urlParts[2]) {
        isTestSite = (urlParts[2] || '').includes('gr-dev.altaicoders.ru')
      }

      if (!urlPrefix && qrAppAlias === 'qrapp') {
        isQrAppPage = true
      } else if (urlPrefix && qrAppAlias === 'qrapp') {
        isQrAppPage = true
      }
    }
  }
  return (
    <App
      store={store}
      subsystem={SUBSYSTEM}
      urlPrefix={urlPrefix}
      isQrApp={isQrAppPage}
      isTestSite={isTestSite}>
      {content}
    </App>
  )
}

export default Spa
