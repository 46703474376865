import React from 'react'
import {Route, Switch} from 'react-router-dom'
import PrePaymentPage from '../prePayment/PrePaymentPage'
import {PrePaymentSuccessPage} from '../prePayment/PrePaymentSuccessPage'
import PrePaymentFailurePage from '../prePayment/PrePaymentFailurePage'
import PaymentSuccessPage from '../payment/PaymentSuccessPage'
import PaymentFailurePage from '../payment/PaymentFailurePage'
import {NotFoundPage} from '../../../../components/common/NotFoundPage'
import PaymentCheckPage from '../payment/PaymentCheckPage'

const PaymentLayout: React.FC = (): React.ReactElement => {
  const defaultRoute = '/payment'
  return (
    <Switch>
      <Route exact path={defaultRoute + '/payment-success'} component={PaymentSuccessPage} />
      <Route exact path={defaultRoute + '/payment-failure'} component={PaymentFailurePage} />
      <Route exact path={defaultRoute + '/payment-check'} component={PaymentCheckPage} />
      <Route
        exact
        path={defaultRoute + '/success/:prepaymentOrderId'}
        component={PrePaymentSuccessPage}
      />
      <Route
        exact
        path={defaultRoute + '/failure/:prepaymentOrderId'}
        component={PrePaymentFailurePage}
      />
      <Route exact path={defaultRoute + '/:prepaymentOrderId'} component={PrePaymentPage} />
      <Route path={'/*'} component={NotFoundPage} />
    </Switch>
  )
}

export default PaymentLayout
