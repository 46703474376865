import React, {useMemo} from 'react'
import {Alert, ButtonIcon} from 'grilnica-reactstrap'
import {ActionDTO} from 'grilnica-store-share'
import {arrowLeftSVG} from '../../../../../components/svg/SystemIcons'
import {HtmlView} from 'grilnica-ckeditor'

interface SalePageInfoProps {
  sale: ActionDTO
  goBack: () => void
}

const SalePageInfo: React.FC<SalePageInfoProps> = ({sale, goBack}): React.ReactElement => {
  const renderContent: React.ReactElement = useMemo(() => {
    return (
      <>
        <div className={'go-all-sale'}>
          <ButtonIcon
            iconPath={arrowLeftSVG}
            isHoverArea
            title={'Назад'}
            className={'mr-x4'}
            onClick={() => {
              goBack()
            }}
          />
          <p className={'caption-sale mb-0'}>Акции</p>
        </div>
        <p className={'sale-name'}>{sale.name}</p>
        <div className={'sale-description mb-x6'}>
          <HtmlView html={sale.description} />
        </div>
        {sale.coupons?.length > 0 && (
          <div className={' mb-x6'}>
            <Alert
              message={'Обратите внимание, скидки по акциям не суммируются'}
              color={'warning'}
            />
          </div>
        )}
      </>
    )
  }, [goBack, sale.coupons?.length, sale.description, sale.name])

  return <>{renderContent}</>
}

export {SalePageInfo}
