import {OptionCategoryMap} from '../../../../../types/product/OptionCategoryMap'
import {OptionCategoryDTO} from 'grilnica-store-share'

function getOptionCategoryMap(optionCategories: OptionCategoryDTO[]): OptionCategoryMap {
  let optionCategoryMap: OptionCategoryMap = {}
  for (const optionCategory of optionCategories) {
    optionCategoryMap[optionCategory.optionCategoryId] = optionCategory
  }
  return optionCategoryMap
}

export {getOptionCategoryMap}
