import {Actions as CommonActions} from '../ducks/common'
import {Actions as ErrorActions} from '../ducks/error'
import {Actions as InitializeActions} from '../ducks/initialize'
import {put, call, select} from 'redux-saga/effects'
import {LSKeys, LSMethods} from '../../storage'
import {ActionDTO, MenuCategoryDTO, ObjectUrlMap, ProductMap} from 'grilnica-store-share'
import {loadObjectUrlMapService} from '../../services/common'
import {AliasObjectMap} from '../../types/common/AliasObjectMap'
import {CityDTO, RestaurantDTO} from 'grilnica-share'
import {State} from '../ducks'
import {loadActionsListService} from '../../services/action'
import {ErrorInitializeEnum} from '../../types/common/ErrorInitializeEnum'
import {getMenuCategoryFromPreview} from '../../utils/menu'

function* initializeObjectUrlMap() {
  let selectedCity: CityDTO = yield select((state: State) => state.city.selectedCity)
  if (selectedCity) {
    try {
      yield call(loadObjectUrlMap)
    } catch (error) {
      yield put(ErrorActions.setError(error))
    }
  } else {
    console.error('Not city initializeObjectUrlMap')
  }
}

function* loadObjectUrlMap() {
  try {
    yield call(getObjectUrlMap)
  } catch (error) {
    yield put(ErrorActions.setError(error))
    const objectUrlMap: ObjectUrlMap = yield call(
      LSMethods.getStorageData,
      LSKeys.OBJECT_URL_MAP,
      [],
    )

    yield call(LSMethods.setStorageData, LSKeys.OBJECT_URL_MAP, objectUrlMap)
    yield put(CommonActions.loadObjectUrlMapSuccess(objectUrlMap))
  }
}

function* getObjectUrlMap() {
  try {
    const selectedCity: CityDTO = yield select((state: State) => state.city.selectedCity)
    const objectUrlMap: ObjectUrlMap = yield call(loadObjectUrlMapService, selectedCity.cityId)
    yield call(setObjectUrlMap, objectUrlMap)
  } catch (error) {
    yield put(ErrorActions.setError(error))
    const objectUrlMap: ObjectUrlMap = yield call(
      LSMethods.getStorageData,
      LSKeys.OBJECT_URL_MAP,
      null,
    )
    yield put(CommonActions.loadObjectUrlMapSuccess(objectUrlMap))
  }
}

function* setObjectUrlMap(objectUrlMap: ObjectUrlMap) {
  yield put(CommonActions.loadObjectUrlMapSuccess(objectUrlMap))
  yield call(LSMethods.setStorageData, LSKeys.OBJECT_URL_MAP, objectUrlMap)
}

async function getAliasProductCategoryMap(
  categoryList: MenuCategoryDTO[],
  productMap: ProductMap,
): Promise<AliasObjectMap> {
  let aliasObjectMap: AliasObjectMap = {}
  const newCategoryList = categoryList || []
  newCategoryList.push(getMenuCategoryFromPreview())
  for (let category of newCategoryList) {
    aliasObjectMap[category.alias] = {objectId: category.menuCategoryId}
    const productList = productMap[category.menuCategoryId]
      ? productMap[category.menuCategoryId]
      : []
    for (let product of productList) {
      aliasObjectMap[category.alias][product.alias] = product.productId
    }
  }
  return aliasObjectMap
}

async function getAliasObjectByKeyMap(
  objectList: ActionDTO[] | CityDTO[] | RestaurantDTO[],
  objectKey: string,
): Promise<AliasObjectMap> {
  let aliasObjectMap: AliasObjectMap = {}

  for (let object of objectList) {
    aliasObjectMap[object.alias] = {objectId: object[objectKey]}
  }
  return aliasObjectMap
}

function* initializeAliasObjectMap() {
  let selectedCity: CityDTO = yield select((state: State) => state.city.selectedCity)
  let cityList: CityDTO[] = (yield select((state: State) => state.city.cityList)) || []
  let categoryList: MenuCategoryDTO[] =
    (yield select((state: State) => state.menu.categoryList)) || []
  let productMap: ProductMap = (yield select((state: State) => state.menu.productMap)) || {}
  let restaurants: RestaurantDTO[] = yield select((state: State) => state.restaurant.restaurants)
  let actions: ActionDTO[] = []
  if (selectedCity) {
    actions = yield call(loadActionsListService, selectedCity.cityId)
    if (cityList && categoryList && productMap && restaurants && actions) {
      try {
        yield call(loadAliasActionMap, actions)
        yield call(loadAliasCityMap, cityList)
        yield call(loadAliasRestaurantMap, restaurants)
        yield call(loadAliasProductCategoryMap, categoryList, productMap)
      } catch (error) {
        yield put(ErrorActions.setError(error))
      }
    } else {
      console.error('Ошибка загрузки данных в методе: initializeAliasObjectMap')
      yield put(InitializeActions.setErrorInitialize(ErrorInitializeEnum.ERROR_LOAD_PRODUCT_MAP))
      //TODO:: make
    }
  } else {
    console.error('Not city initializeAliasObjectMap')
    yield put(InitializeActions.setErrorInitialize(ErrorInitializeEnum.ERROR_LOAD_SELECTED_CITY))
  }
}

function* loadAliasActionMap(actions: ActionDTO[]) {
  try {
    const aliasObjectMap: AliasObjectMap = yield call(getAliasObjectByKeyMap, actions, 'actionId')
    yield call(getAliasObjectMap, aliasObjectMap, 'action')
  } catch (error) {
    yield put(ErrorActions.setError(error))
    const aliasActionMap: AliasObjectMap = yield call(
      LSMethods.getStorageData,
      LSKeys.ALIAS_ACTION_MAP,
      [],
    )

    yield call(LSMethods.setStorageData, LSKeys.ALIAS_ACTION_MAP, aliasActionMap)
    yield put(CommonActions.loadAliasActionMapSuccess(aliasActionMap))
  }
}

function* loadAliasCityMap(cityList: CityDTO[]) {
  try {
    const aliasObjectMap: AliasObjectMap = yield call(getAliasObjectByKeyMap, cityList, 'cityId')
    yield call(getAliasObjectMap, aliasObjectMap, 'city')
  } catch (error) {
    yield put(ErrorActions.setError(error))
    const aliasCityMap: AliasObjectMap = yield call(
      LSMethods.getStorageData,
      LSKeys.ALIAS_CITY_MAP,
      [],
    )

    yield call(LSMethods.setStorageData, LSKeys.ALIAS_CITY_MAP, aliasCityMap)
    yield put(CommonActions.loadAliasCityMapSuccess(aliasCityMap))
  }
}

function* loadAliasRestaurantMap(restaurants: RestaurantDTO[]) {
  try {
    const aliasObjectMap: AliasObjectMap = yield call(
      getAliasObjectByKeyMap,
      restaurants,
      'restaurantId',
    )
    yield call(getAliasObjectMap, aliasObjectMap, 'restaurant')
  } catch (error) {
    yield put(ErrorActions.setError(error))
    const aliasRestaurantMap: AliasObjectMap = yield call(
      LSMethods.getStorageData,
      LSKeys.ALIAS_RESTAURANT_MAP,
      [],
    )

    yield call(LSMethods.setStorageData, LSKeys.ALIAS_RESTAURANT_MAP, aliasRestaurantMap)
    yield put(CommonActions.loadAliasRestaurantMapSuccess(aliasRestaurantMap))
  }
}

function* loadAliasProductCategoryMap(categoryList: MenuCategoryDTO[], productMap: ProductMap) {
  try {
    const aliasObjectMap: AliasObjectMap = yield call(
      getAliasProductCategoryMap,
      categoryList,
      productMap,
    )
    yield call(getAliasObjectMap, aliasObjectMap, 'productCategory')
  } catch (error) {
    yield put(ErrorActions.setError(error))
    const aliasProductCategoryMap: AliasObjectMap = yield call(
      LSMethods.getStorageData,
      LSKeys.ALIAS_PRODUCT_CATEGORY_MAP,
      [],
    )

    yield call(LSMethods.setStorageData, LSKeys.ALIAS_PRODUCT_CATEGORY_MAP, aliasProductCategoryMap)
    yield put(CommonActions.loadAliasProductCategoryMapSuccess(aliasProductCategoryMap))
  }
}

function* getAliasObjectMap(
  aliasObjectMap: AliasObjectMap,
  type: 'action' | 'city' | 'restaurant' | 'productCategory',
) {
  try {
    yield call(setAliasObjectMap, aliasObjectMap, type)
  } catch (error) {
    yield put(ErrorActions.setError(error))
    switch (type) {
      case 'action':
        {
          const aliasActionMap: AliasObjectMap = yield call(
            LSMethods.getStorageData,
            LSKeys.ALIAS_ACTION_MAP,
            null,
          )
          yield put(CommonActions.loadAliasActionMapSuccess(aliasActionMap))
        }
        break
      case 'city':
        {
          const aliasCityMap: AliasObjectMap = yield call(
            LSMethods.getStorageData,
            LSKeys.ALIAS_CITY_MAP,
            null,
          )
          yield put(CommonActions.loadAliasCityMapSuccess(aliasCityMap))
        }
        break
      case 'restaurant':
        {
          const aliasRestaurantMap: AliasObjectMap = yield call(
            LSMethods.getStorageData,
            LSKeys.ALIAS_RESTAURANT_MAP,
            null,
          )
          yield put(CommonActions.loadAliasRestaurantMapSuccess(aliasRestaurantMap))
        }
        break
      case 'productCategory':
        {
          const aliasProductCategoryMap: AliasObjectMap = yield call(
            LSMethods.getStorageData,
            LSKeys.ALIAS_PRODUCT_CATEGORY_MAP,
            null,
          )
          yield put(CommonActions.loadAliasProductCategoryMapSuccess(aliasProductCategoryMap))
        }
        break
    }
  }
}

function* setAliasObjectMap(
  aliasObjectMap: AliasObjectMap,
  type: 'action' | 'city' | 'restaurant' | 'productCategory',
) {
  switch (type) {
    case 'action':
      {
        yield put(CommonActions.loadAliasActionMapSuccess(aliasObjectMap))
        yield call(LSMethods.setStorageData, LSKeys.ALIAS_ACTION_MAP, aliasObjectMap)
      }
      break
    case 'city':
      {
        yield put(CommonActions.loadAliasCityMapSuccess(aliasObjectMap))
        yield call(LSMethods.setStorageData, LSKeys.ALIAS_CITY_MAP, aliasObjectMap)
      }
      break
    case 'restaurant':
      {
        yield put(CommonActions.loadAliasRestaurantMapSuccess(aliasObjectMap))
        yield call(LSMethods.setStorageData, LSKeys.ALIAS_RESTAURANT_MAP, aliasObjectMap)
      }
      break
    case 'productCategory':
      {
        yield put(CommonActions.loadAliasProductCategoryMapSuccess(aliasObjectMap))
        yield call(LSMethods.setStorageData, LSKeys.ALIAS_PRODUCT_CATEGORY_MAP, aliasObjectMap)
      }
      break
  }
}

export {
  initializeObjectUrlMap,
  loadObjectUrlMap,
  initializeAliasObjectMap,
  getAliasProductCategoryMap,
  getAliasObjectByKeyMap,
}
