import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {CityDTO, RestaurantDTO} from 'grilnica-share'
import {loadRestaurantListService, loadRestaurantService} from '../../../../services/restaurant'
import {State} from '../../../../store/ducks'
import {connectComponent} from '../../../../store/common'
import {AliasObjectMap} from '../../../../types/common/AliasObjectMap'
import {NotFoundPage} from '../../../components/common/NotFoundPage'
import {useHistory} from 'react-router-dom'
import {RestaurantPageComponent} from './RestaurantPageComponent'

interface RestaurantPageProps {
  city: CityDTO
  initRestaurant: RestaurantDTO
  initRestaurantList: RestaurantDTO[]
  restaurantAlias: string
  aliasRestaurantMap: AliasObjectMap
}

const RestaurantPage: React.FC<RestaurantPageProps> = ({
  restaurantAlias,
  city,
  initRestaurant,
  initRestaurantList,
  aliasRestaurantMap,
}): React.ReactElement => {
  const history = useHistory()
  const [restaurant, setRestaurant] = useState<RestaurantDTO>(initRestaurant)
  const [restaurantList, setRestaurantList] = useState<RestaurantDTO[]>(initRestaurantList)

  const loadRestaurant = useCallback(async () => {
    const restaurantId = aliasRestaurantMap[restaurantAlias]?.objectId
    if (restaurantId) {
      const newRestaurant: RestaurantDTO = await loadRestaurantService(
        restaurantId,
        null,
        city?.cityId,
      )
      setRestaurant(newRestaurant)
      const newRestaurantList: RestaurantDTO[] = await loadRestaurantListService(city?.cityId)
      setRestaurantList(newRestaurantList)
    }
  }, [aliasRestaurantMap, city?.cityId, restaurantAlias])

  useEffect(() => {
    loadRestaurant()
  }, [loadRestaurant])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [restaurantAlias])

  const renderContent = useMemo(() => {
    return (
      <RestaurantPageComponent
        city={city}
        restaurantAlias={restaurantAlias}
        aliasRestaurantMap={aliasRestaurantMap}
        restaurant={restaurant}
        restaurantList={restaurantList}
        goBack={() => {
          history.goBack()
        }}
      />
    )
  }, [aliasRestaurantMap, city, history, restaurant, restaurantAlias, restaurantList])

  if (!aliasRestaurantMap[restaurantAlias]) {
    return <NotFoundPage />
  }

  return <>{renderContent}</>
}

export default connectComponent(
  (state: State, props) => ({
    restaurantAlias: props.match.params.alias,
    city: state.city.selectedCity,
    initRestaurant: state.initialize.restaurant,
    initRestaurantList: state.initialize.restaurantList,
    aliasRestaurantMap: state.common.aliasRestaurantMap,
  }),
  () => ({}),
  RestaurantPage,
)
