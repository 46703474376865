import {bindActionCreators} from 'redux'
import {BaseAction} from '../../common/BaseAction'
import {RecommendationsProductActionsTypes} from './types'
import {ProductDTO, RecommendationProductMap} from 'grilnica-store-share'

export const Actions = {
  setRecommendationsProductMap: (
    recommendationProductMap: RecommendationProductMap,
  ): BaseAction => ({
    type: RecommendationsProductActionsTypes.SET_RECOMMENDATION_PRODUCT_MAP,
    payload: recommendationProductMap,
  }),

  check: (): BaseAction => ({
    type: RecommendationsProductActionsTypes.CHECK_RECOMMENDATIONS_PRODUCTS_REQUEST,
  }),

  addRecommendationProductInBasket: (product: ProductDTO): BaseAction => ({
    type: RecommendationsProductActionsTypes.ADD_RECOMMENDATION_PRODUCT_IN_BASKET,
    payload: {product},
  }),

  setIsRequest: (isRequest: boolean): BaseAction => ({
    type: RecommendationsProductActionsTypes.SET_IS_REQUEST,
    payload: isRequest,
  }),
}

export type RecommendationsProductActionsType = typeof Actions

export function bindRecommendationsProductActions(dispatch: any) {
  return bindActionCreators(Actions, dispatch)
}
