export enum ActionActionsType {
  LOAD_ACTIONS_REQUEST = 'action/LOAD_ACTIONS_REQUEST',
  SET_ACTIONS = 'action/SET_ACTIONS',
  SELECT_ACTION_REQUEST = 'action/SELECT_ACTION_REQUEST',
  SET_SELECTED_ACTION = 'action/SET_SELECTED_ACTION',
  SET_IS_REQUEST_LOAD_ACTIONS = 'action/SET_IS_REQUEST_GET_ACTIONS',
  UPDATE_ACTIONS_REQUEST = 'actions/UPDATE_ACTIONS_REQUEST ',
  SET_IS_REQUEST_UPDATE_ACTIONS = 'actions/SET_IS_REQUEST_UPDATE_ACTIONS',
  SET_AVAILABLE_DEFINITIONS = 'actions/SET_AVAILABLE_DEFINITIONS',
  SET_SELECTED_COUPON_DEFINITION_ID_REQUEST = 'actions/SET_SELECTED_COUPON_DEFINITION_ID_REQUEST',
  SET_SELECTED_COUPON_DEFINITION_ID = 'actions/SET_SELECTED_COUPON_DEFINITION_ID',
}
