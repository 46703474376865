import {SupportsMaps} from '../../../../../../../../../../types/product/SupportsMaps'
import {SelectedProductDTO} from 'grilnica-store-share'
import {SelectedOptionsMap} from '../../../../../../../../../../types/product/SelectedOptionsMap'
import {CountSelectedOptionsMap} from '../../../../../../../../../../types/product/CountSelectedOptionsMap'
import {RestrictionsMap} from '../../../../../../../../../../types/product/RestrictionsMap'
import {ExtraOptionsMap} from '../../../../../../../../../../types/product/ExtraOptionsMap'
import {ProductItemActionsTypes} from '../../ducks/ProductItemDuck'
import Product from '../../product/Product'
import {getSelectedProduct} from '../combo/getSelectedProduct'

const toggleOptionService = (
  optionCategoryId: string,
  optionId: string,
  isChecked: boolean,
  isOptional: boolean,
  supportsMaps: SupportsMaps,
  selectedProduct: SelectedProductDTO,
  dispatch: any,
) => {
  let newSelectedOptionsMap: SelectedOptionsMap = {...supportsMaps.selectedOptionsMap}

  const newCountSelectedOptionsMap: CountSelectedOptionsMap = {
    ...supportsMaps.countSelectedOptionsMap,
  }

  const product = new Product(
    selectedProduct,
    newSelectedOptionsMap,
    supportsMaps.optionCategoryMap,
    supportsMaps.productVariantMap,
    newCountSelectedOptionsMap,
    supportsMaps.optionMap,
    supportsMaps.optionProductMap,
  )

  product.toggleOption(optionCategoryId, optionId, isChecked, isOptional)

  let restrictionsMap: RestrictionsMap = null
  let extraOptionsMap: ExtraOptionsMap = null
  if (supportsMaps.productVariantMap) {
    restrictionsMap = product.getRestrictionsMap()
    extraOptionsMap = product.getExtraOptionsMap()
  }
  dispatch({
    type: ProductItemActionsTypes.TOGGLE_OPTION,
    payload: {
      selectedOptionsMap: product.getSelectedOptionsMap(),
      countSelectedOptionsMap: product.getCountSelectedOptionsMap(),
      restrictionsMap: restrictionsMap,
      extraOptionsMap: extraOptionsMap,
    },
  })

  product.addSelectedProductPriceAndProductVariant()
  const newSelectedProduct: SelectedProductDTO = getSelectedProduct(
    selectedProduct,
    product.getSelectedOptionsMap(),
    supportsMaps.optionMap,
    supportsMaps.optionCategoryMap,
    supportsMaps.restrictionsMap,
  )

  dispatch({
    type: ProductItemActionsTypes.SET_SELECTED_PRODUCT,
    payload: newSelectedProduct,
  })
}

export {toggleOptionService}
