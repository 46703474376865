import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {ButtonIcon, COLORS, Icon, InputVertical} from 'grilnica-reactstrap'
import SearchPreview from '../../preview/SearchPreview'
import {Redirect} from 'react-router-dom'
import {closeSVG, magnifySVG} from '../../svg/SystemIcons'

interface SearchInputHeaderProps {
  className?: string
  isSearchOpenAlways?: boolean
  handleChangeSearchValue: (value: string) => void
  searchValue: string
}

const SearchInputHeader: React.FC<SearchInputHeaderProps> = ({
  className,
  isSearchOpenAlways,
  handleChangeSearchValue,
  searchValue,
}): React.ReactElement => {
  const [isShowPreview, setIsShowPreview] = useState<boolean>(true)
  const [redirectToSearchPage, setRedirectToSearchPage] = useState<boolean>(false)
  const [isShowIconSearch, setIsShowIconSearch] = useState<boolean>(true)
  const [isHideSearch, setIsHideSearch] = useState<boolean>(true)

  useEffect(() => {
    setRedirectToSearchPage(false)
  }, [redirectToSearchPage])

  const hideSearch = useCallback(() => {
    setTimeout(() => {
      setIsHideSearch(true)
      handleChangeSearchValue('')
    }, 150)
  }, [handleChangeSearchValue])

  const showSearchInput = useMemo(() => {
    return (
      <div className={'d-flex align-items-center'}>
        <form
          className={
            'd-flex align-items-center ' +
            (isSearchOpenAlways ? '' : isHideSearch ? 'hide-search' : '')
          }
          onSubmit={(event) => {
            event.preventDefault()
            setRedirectToSearchPage(true)
          }}>
          <div
            className={
              isSearchOpenAlways
                ? 'open-search-header mb-0'
                : 'search-header mb-0 ' + (isShowIconSearch ? 'close-search-header ' : '')
            }>
            <InputVertical
              id={'search-input'}
              size={'sm'}
              className={'mb-0'}
              isRounded
              name={'search'}
              type={'text'}
              onBlur={() => {
                setTimeout(() => {
                  setIsShowPreview(false)
                }, 150)
              }}
              onFocus={() => {
                setIsShowPreview(true)
              }}
              value={searchValue}
              isRequired={false}
              onChange={handleChangeSearchValue}
              placeholder={'найти блюдо'}
            />
            {isSearchOpenAlways && searchValue?.length < 1 && (
              <Icon
                path={magnifySVG}
                color={COLORS.gray600}
                size={0.7}
                className={'search-magnify'}
              />
            )}
            {isSearchOpenAlways && searchValue?.length >= 1 && (
              <ButtonIcon
                size={'sm'}
                iconPath={closeSVG}
                title={'Очистить'}
                className={'search-clear'}
                onClick={() => {
                  handleChangeSearchValue('')
                }}
              />
            )}
          </div>
          {!isSearchOpenAlways && (
            <ButtonIcon
              iconPath={closeSVG}
              title={'Закрыть'}
              isHoverArea
              hoverIconColor={'white'}
              color={COLORS.white}
              size={'sm'}
              className={isShowIconSearch ? 'button-close' : 'mx-x4'}
              onClick={() => {
                hideSearch()
                setIsShowIconSearch(true)
                handleChangeSearchValue('')
              }}
            />
          )}
        </form>

        {isShowIconSearch && !isSearchOpenAlways && (
          <ButtonIcon
            iconPath={magnifySVG}
            color={COLORS.white}
            hoverIconColor={'white'}
            title={'Поиск'}
            isHoverArea
            size={'sm'}
            className={'mr-x4'}
            onClick={() => {
              setIsHideSearch(false)
              setIsShowIconSearch(false)
            }}
          />
        )}
      </div>
    )
  }, [
    handleChangeSearchValue,
    hideSearch,
    isHideSearch,
    isSearchOpenAlways,
    isShowIconSearch,
    searchValue,
  ])

  const renderContent: React.ReactElement = useMemo(() => {
    return (
      <div className={'dish-search ' + (className ? className : '')}>
        {showSearchInput}
        {((isShowPreview && isSearchOpenAlways) || (!isShowIconSearch && !isSearchOpenAlways)) && (
          <SearchPreview
            searchValue={searchValue}
            className={isSearchOpenAlways ? 'always-open-preview' : 'search-preview'}
            isShowPreview={isShowPreview}
          />
        )}
        {redirectToSearchPage && (
          <Redirect to={'/search' + (searchValue ? '/' + searchValue.toLowerCase() : '')} />
        )}
      </div>
    )
  }, [
    className,
    isSearchOpenAlways,
    isShowIconSearch,
    isShowPreview,
    redirectToSearchPage,
    searchValue,
    showSearchInput,
  ])

  return <React.Fragment>{renderContent}</React.Fragment>
}

export {SearchInputHeader}
