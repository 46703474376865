import {call, delay, put, fork} from 'redux-saga/effects'
import {Actions as ErrorActions} from '../ducks/error'
import {Actions as InitializeActions} from '../ducks/initialize'
import {initializeCity, initializeCityTerminal} from './city'
import {initializeAuth} from './auth'
import {initializeBackgroundUpdateMenu, initializeMenu, initializeMenuTerminal} from './menu'
import {initializeBasket} from './basket/basket'
import {initializeOrder} from './order/order'
import {initializeRestaurant} from './restaurant'
import {isServer} from '../index'
import {initializeAliasObjectMap, initializeObjectUrlMap} from './common'
import {initializeRestaurantProduct} from './restaurantProduct'
import {connectSocket, initializeSocket} from '../socket/saga/generators'
import {initializeTerminal} from '../terminal/saga/generators'
import {initializeContactDetails} from '../contactDetails/saga/generators'

function* initialize({payload}: any) {
  // console.log('*initialize')
  const isAfterAuth = payload.isAfterAuth
  try {
    if (!isServer) {
      yield call(initializeAuth)
      yield call(initializeCity)
      yield call(initializeMenu)
      yield call(initializeRestaurant)
      yield call(initializeRestaurantProduct)
      yield call(initializeObjectUrlMap)
      yield call(initializeAliasObjectMap)
      yield call(initializeContactDetails)
      if (!isAfterAuth) {
        yield call(initializeBasket)
      }
      yield call(initializeOrder, true)
    }
    yield call(toggleInitialize)
  } catch (error) {
    yield put(ErrorActions.setError(error))
  }
}

function* initializeT() {
  // console.log('*initializeT')
  try {
    yield call(initializeTerminal)
    yield call(initializeCityTerminal)
    yield call(initializeMenuTerminal)
    yield call(initializeAliasObjectMap) //TODO:: убрать акции, рестораны
    yield call(initializeContactDetails)
    yield call(initializeBasket)
    yield delay(250)
    yield call(toggleInitializeTerminal)
    //сокеты
    yield delay(500)
    yield fork(initializeSocket)
    yield call(connectSocket)
    yield call(initializeBackgroundUpdateMenu)
  } catch (error) {
    yield put(ErrorActions.setError(error))
  }
}

function* toggleInitialize() {
  // console.log('*toggleInitialize')
  yield put(InitializeActions.toggleInitialize(true))
}

function* toggleInitializeTerminal() {
  // console.log('*toggleInitializeTerminal')
  yield put(InitializeActions.toggleInitializeTerminal(true))
}

export {initialize, initializeT}
