import React, {useCallback, useEffect, useState} from 'react'
import {BannerCookies} from 'grilnica-reactstrap'
import {LSKeys, LSMethods} from '../../../storage'

interface CookieMessageProps {}

const CookieMessage: React.FC<CookieMessageProps> = ({}): React.ReactElement => {
  const [isConfirmCookie, setIsConfirmCookie] = useState<boolean>(false)

  const getConfirmCookie = useCallback((): boolean => {
    if (typeof window !== 'undefined') {
      return !LSMethods.getStorageData(LSKeys.IS_CONFIRM_COOKIE, null)
    } else {
      return true
    }
  }, [])

  const setStorageData = useCallback(() => {
    setIsConfirmCookie(false)
    LSMethods.setStorageData(LSKeys.IS_CONFIRM_COOKIE, 'isConfirmCookie')
  }, [])

  useEffect(() => {
    const id = setTimeout(() => {
      setIsConfirmCookie(getConfirmCookie())
    }, 2000)
    return () => clearTimeout(id)
  }, [getConfirmCookie])

  return (
    <BannerCookies
      className={isConfirmCookie ? 'cookie-message' : 'd-none'}
      onClick={setStorageData}>
      <p className={'mb-0'}>
        Мы используем информацию, зарегистрированную в файлах «cookies», в частности, в рекламных и
        статистических целях, а также для того, чтобы адаптировать наши сайты к индивидуальным
        потребностям пользователей.
      </p>
    </BannerCookies>
  )
}

export {CookieMessage}
