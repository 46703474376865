import React, {useEffect} from 'react'
import {Route, Switch, useLocation} from 'react-router-dom'
import AuthLayout from '../containers/auth/layout/AuthLayout'
import PaymentLayout from '../containers/payment/layout/PaymentLayout'
import {AboutCompanyPage} from '../containers/aboutCompany/AboutCompanyPage'
import QualityControlPage from '../containers/qualityControl/QualityControlPage'
import RestaurantListPage from '../containers/restaurants/RestaurantListPage'
import RestaurantPage from '../containers/restaurants/RestaurantPage'
import {MenuLayout} from '../containers/menu/layout/MenuLayout'
import ProfilePage from '../containers/profile/ProfilePage'
import SaleListPage from '../containers/sales/SaleListPage'
import SalePage from '../containers/sales/salePage/SalePage'
import DeliveryPage from '../containers/delivery/DeliveryPage'
import BasketPage from '../containers/basket/BasketPage'
import PlaceOrder from '../containers/order/PlaceOrder'
import SearchPage from '../containers/search/SearchPage'
import OrderHistoryPage from '../containers/profile/OrderHistoryPage'
import AuthorizedRoute from '../../components/auth/AuthorizedRoute'
import {NotFoundPage} from '../../components/common/NotFoundPage'
import {QuickOrderSuccessPage} from '../containers/order/QuickOrderSuccessPage'
import {AppLinks} from '../../components/appLinks/AppLinks'
import {connectComponent} from '../../../store/common'
import {State} from '../../../store/ducks'
import {getObjectUrlSeoContent} from '../server/seo/getObjectUrlSeoContent'
import {ObjectUrlMap} from 'grilnica-store-share'

interface MainLayoutProps {
  objectUrlMap: ObjectUrlMap
  selectedCityName: string
}

const MainLayout: React.FC<MainLayoutProps> = ({
  objectUrlMap,
  selectedCityName,
}): React.ReactElement => {
  const location = useLocation()

  useEffect(() => {
    if (location) {
      let title =
        getObjectUrlSeoContent(location?.pathname, objectUrlMap, selectedCityName, 'title') ||
        'Грильница — сеть быстрого питания'
      if (title !== document.title) {
        document.title = title
      }
    }
  }, [objectUrlMap, location?.pathname, location, selectedCityName])

  return (
    <div className={'d-flex flex-column flex-grow-1'}>
      <Switch>
        <Route path={'/login'} component={AuthLayout} />
        <Route path={'/payment'} component={PaymentLayout} />
        <Route exact path={'/about'} component={AboutCompanyPage} />
        <Route exact path={'/qualitycontrol'} component={QualityControlPage} />
        <Route exact path={'/shipping'} component={DeliveryPage} />
        <Route exact path={'/actions'} component={SaleListPage} />
        <Route exact path={'/actions/:alias'} component={SalePage} />
        <Route exact path={'/rest'} component={RestaurantListPage} />
        <Route exact path={'/rest/:alias'} component={RestaurantPage} />
        <AuthorizedRoute exact path={'/profile/:clientId?'} component={ProfilePage} />
        <Route exact path={'/basket'} component={BasketPage} />
        <AuthorizedRoute exact path={'/order-history/:orderId?'} component={OrderHistoryPage} />
        <AuthorizedRoute exact path={'/place-order'} component={PlaceOrder} />
        <Route path={'/quick-order-success'} component={QuickOrderSuccessPage} />
        <Route exact path={'/search/:searchValue?'} component={SearchPage} />
        <Route exact path={'/not-found'} component={NotFoundPage} />
        <Route exact path={'/qrapp'} component={AppLinks} />
        <Route path={'/'} component={MenuLayout} />
      </Switch>
    </div>
  )
}

export default connectComponent(
  (state: State) => ({
    objectUrlMap: state.common.objectUrlMap,
    selectedCityName: state.city?.selectedCity?.name,
  }),
  () => ({}),
  MainLayout,
)
