import {CONSTANTS} from '../../../constants'
import {CityDTO} from 'grilnica-share'
import {apiGet} from '../../api'

async function loadCityListService(errorActions: any, cityId: string): Promise<CityDTO[]> {
  try {
    return await apiGet(CONSTANTS.apiStoreUrl + '/city', {
      cityId: cityId,
    })
  } catch (error) {
    console.error('Error loadCityListService error', error)
  }
}

async function setCitySetCookieService(
  errorActions: any,
  cityId: string,
  isConfirm: boolean,
): Promise<CityDTO[]> {
  try {
    const url = new URL(CONSTANTS.apiStoreUrl + '/city/set-cookie')
    url.searchParams.append('cityId', cityId)
    url.searchParams.append('isConfirm', isConfirm.toString())
    return await apiGet(url.toString(), {
      cityId: cityId,
    })
  } catch (error) {
    console.error('Error setCitySetCookieService error', error)
  }
}

async function loadCityByIpService(cityId: string): Promise<CityDTO[]> {
  try {
    return await apiGet(CONSTANTS.apiStoreUrl + '/city/find-by-ip', {
      cityId: cityId,
    })
  } catch (error) {
    console.error('Error loadCityByIpService error')
  }
}

async function loadCityService(cityId: string): Promise<CityDTO> {
  return await apiGet(CONSTANTS.apiStoreUrl + '/city/' + cityId, {
    cityId: cityId,
  })
}

export {loadCityListService, loadCityByIpService, loadCityService, setCitySetCookieService}
