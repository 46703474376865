import {apiGet} from '../../api'
import {CONSTANTS} from '../../../constants'
import {RestaurantDTO} from 'grilnica-share'

async function loadRestaurantListService(cityId: string): Promise<RestaurantDTO[]> {
  return await apiGet(CONSTANTS.apiStoreUrl + '/restaurant/', {
    cityId: cityId,
  })
}

const loadRestaurantListByCityIdService = async (cityId: string, errorActions?: any) => {
  try {
    return await apiGet(CONSTANTS.apiStoreUrl + '/restaurant/find-by-city/' + cityId, {
      cityId: cityId,
    })
  } catch (error) {
    if (errorActions) {
      errorActions.setError(error)
    } else {
      console.error('loadRestaurantListByCityIdService error')
      throw error
    }
  }
}

const loadAllRestaurantListByCityIdService = async (cityId: string, errorActions?: any) => {
  try {
    const restaurants: RestaurantDTO[] = await loadRestaurantListService(cityId)
    let restaurantsByCity: RestaurantDTO[] = []
    for (let restaurant of restaurants) {
      if (restaurant.cityId === cityId) {
        restaurantsByCity.push(restaurant)
      }
    }
    return restaurantsByCity
  } catch (error) {
    if (errorActions) {
      errorActions.setError(error)
    } else {
      console.error('loadAllRestaurantListByCityIdService error')
      throw error
    }
  }
}

async function loadClientRestaurantService(cityId: string) {
  return await apiGet(CONSTANTS.apiStoreUrl + '/client/client-restaurant/find-by-client', {
    cityId: cityId,
  })
}

async function loadRestaurantService(
  restaurantId: string,
  errorActions: any,
  cityId: string,
): Promise<RestaurantDTO> {
  try {
    return await apiGet(CONSTANTS.apiStoreUrl + '/restaurant/' + restaurantId, {
      cityId: cityId,
    })
  } catch (error) {
    if (errorActions) {
      errorActions.setError(error)
    } else {
      console.error('loadRestaurantService error')
      throw error
    }
  }
}

async function loadRestaurantPaymentTypeMapService(cityId: string, errorActions?: any) {
  try {
    return await apiGet(CONSTANTS.apiStoreUrl + '/restaurant/find-payment-type-map/' + cityId, {
      cityId: cityId,
    })
  } catch (error) {
    if (errorActions) {
      errorActions.setError(error)
    } else {
      console.error('loadRestaurantPaymentTypeMapService error')
      throw error
    }
  }
}

async function addClientRestaurantService(restaurantId: string, cityId: string) {
  return await apiGet(CONSTANTS.apiStoreUrl + '/client/client-restaurant/create/' + restaurantId, {
    cityId: cityId,
  })
}

async function deleteClientRestaurantService(restaurantId: string, cityId: string) {
  return await apiGet(CONSTANTS.apiStoreUrl + '/client/client-restaurant/delete/' + restaurantId, {
    cityId: cityId,
  })
}

export {
  loadRestaurantListService,
  loadRestaurantService,
  loadRestaurantListByCityIdService,
  loadRestaurantPaymentTypeMapService,
  loadClientRestaurantService,
  addClientRestaurantService,
  deleteClientRestaurantService,
  loadAllRestaurantListByCityIdService,
}
