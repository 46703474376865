import React, {useMemo} from 'react'
import {PageNav, PageNavItem} from 'grilnica-reactstrap'
import {DeliveryTypeEnum} from 'grilnica-store-share'

interface TabOrderNavProps {
  deliveryType: DeliveryTypeEnum
  toggleTabButton: (deliveryType: DeliveryTypeEnum) => void
}

const TabOrderNav: React.FC<TabOrderNavProps> = ({
  deliveryType,
  toggleTabButton,
}): React.ReactElement => {
  const renderContent: React.ReactElement = useMemo(() => {
    return (
      <PageNav id={'-order'} isNoBorderTop className={'tab-order-nav'}>
        <PageNavItem
          name={'Доставка'}
          active={deliveryType === DeliveryTypeEnum.DELIVERY}
          className={'w-50 d-flex justify-content-center'}
          onClick={() => {
            if (deliveryType !== DeliveryTypeEnum.DELIVERY) {
              toggleTabButton(DeliveryTypeEnum.DELIVERY)
            }
          }}
        />
        <PageNavItem
          name={'Самовывоз'}
          active={deliveryType === DeliveryTypeEnum.PICKUP}
          className={'w-50 d-flex justify-content-center'}
          onClick={() => {
            if (deliveryType !== DeliveryTypeEnum.PICKUP) {
              toggleTabButton(DeliveryTypeEnum.PICKUP)
            }
          }}
        />
      </PageNav>
    )
  }, [deliveryType, toggleTabButton])
  return <>{renderContent}</>
}

export {TabOrderNav}
