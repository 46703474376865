import {call, put, select} from 'redux-saga/effects'
import {State} from '../../ducks'
import {Actions as ErrorActions} from '../../ducks/error'
import {TerminalActions} from '../duck'
import {loadTerminalConfigService} from '../../../services/terminal'
import {LSKeys, LSMethods} from '../../../storage'
import {CityDTO, TerminalConfigDTO} from 'grilnica-share'
import {TerminalState} from '../types'
import {SocketData} from '../../paymentModal/saga/generators'

function* initializeTerminal() {
  console.log('*initializeTerminal')
  try {
    const terminalState: TerminalState = yield select((state: State) => state.terminal)
    const terminalAlias: string = terminalState?.terminalAlias
    yield call(LSMethods.removeItem, LSKeys.TERMINAL_ALIAS)
    yield call(LSMethods.removeItem, LSKeys.CLIENT)
    yield call(LSMethods.removeItem, LSKeys.AUTHORIZATION_CLIENT_TOKEN)
    yield call(LSMethods.setStorageData, LSKeys.TERMINAL_ALIAS, terminalAlias)
    yield call(loadTerminalConfig)
  } catch (error) {
    yield put(ErrorActions.setError(error))
  }
}

function* loadTerminalConfig() {
  try {
    const city: CityDTO = yield select((state: State) => state.city.selectedCity)
    let terminalConfig: TerminalConfigDTO = yield call(loadTerminalConfigService, city?.cityId)
    yield put(TerminalActions.setTerminalConfig(terminalConfig))
  } catch (error) {
    yield put(ErrorActions.setError(error))
  }
}

function* redirectToHomeBySocket({payload}: any) {
  const data: SocketData = payload.data
  // console.log('redirectToHomeBySocket', data)
  yield put(TerminalActions.setIsRedirectToHomeWithReloadPage(true))
}

function* redirectToRepairBySocket({payload}: any) {
  const data: SocketData = payload.data
  // console.log('redirectToRepairBySocket', data)
  yield put(TerminalActions.setIsRedirectToRepairWithReloadPage(true))
}

export {initializeTerminal, redirectToHomeBySocket, redirectToRepairBySocket}
