import {PrepareOrder} from 'grilnica-store-share'
import {select, call, put} from 'redux-saga/effects'
import {State} from '../ducks'
import {Actions as ErrorActions} from '../ducks/error'
import {Actions as OrderActions} from '../ducks/order'
import {Actions as PaymentActions} from '../ducks/payment'
import {PaymentDTO} from 'grilnica-store-share/lib/order/PaymentDTO'
import {
  cancelPaymentOnlineCardService,
  checkPaymentStatusService,
  createPaymentOnlineCardService,
} from '../../services/payment'
import {OrderDTO} from 'grilnica-store-share/lib/order/OrderDTO'
import {getOrder, saveOrderSuccess} from './order/order'
import {CityDTO, PaymentStatusDto} from 'grilnica-share'

function* createPaymentOnlineCard() {
  const city: CityDTO = yield select((state: State) => state.city.selectedCity)
  const prepareOrder: PrepareOrder = yield select((state: State) => state.order.prepareOrder)
  try {
    const payment: PaymentDTO = yield call(
      createPaymentOnlineCardService,
      prepareOrder.orderId,
      city?.cityId,
    )
    yield put(PaymentActions.setPayment(payment))
    yield put(OrderActions.toggleIsRequestSaveOrder(false))
  } catch (e) {
    yield put(PaymentActions.toggleIsRequestPaymentOnlineCard(false))
    yield put(ErrorActions.setError(e))
  }
}

function* checkPaymentStatus({payload}: any) {
  const paymentId = payload.paymentId
  const city: CityDTO = yield select((state: State) => state.city.selectedCity)
  try {
    const paymentStatus: PaymentStatusDto = yield call(
      checkPaymentStatusService,
      paymentId,
      city.cityId,
    )
    yield put(PaymentActions.setPaymentStatus(paymentStatus))
  } catch (e) {
    yield put(ErrorActions.setError(e))
  }
}

function* cancelPaymentOnlineCard() {
  const city: CityDTO = yield select((state: State) => state.city.selectedCity)
  const payment: PaymentDTO = yield select((state: State) => state.payment.payment)
  try {
    yield call(cancelPaymentOnlineCardService, payment.sberOrderId, city?.cityId)
    yield put(PaymentActions.setPayment(null))
  } catch (e) {
    console.log('cancel payment online card error', e)
    yield put(ErrorActions.setError(e))
  }
}

function* setIsSuccessPayment({payload}: any) {
  const orderId: string = payload.orderId
  try {
    const order: OrderDTO = yield call(getOrder, orderId)
    yield call(saveOrderSuccess, order)
    yield put(PaymentActions.clearPaymentState())
  } catch (e) {
    yield call(saveOrderSuccess, null)
    yield put(PaymentActions.clearPaymentState())
    console.log('set is success payment', e)
  }
}

export {createPaymentOnlineCard, cancelPaymentOnlineCard, setIsSuccessPayment, checkPaymentStatus}
