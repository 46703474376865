import React, {useContext} from 'react'
import {Button, Container} from 'grilnica-reactstrap'
import {getFullUrl} from '../../../../../utils/url'
import {UrlPrefixContext} from '../../../components/App'

const PrePaymentSuccessPage: React.FC<{}> = ({}): React.ReactElement => {
  const urlPrefix: string = useContext(UrlPrefixContext)
  return (
    <Container size={'normal'} className={'mt-x10 mb-x10'}>
      <div className={'d-flex justify-content-center align-items-center'}>
        <div
          className={'d-flex flex-column justify-content-center align-items-center payment-card'}>
          <p className={'mb-secondary'}>Оплата прошла успешно!</p>
          <Button
            className={' btn-size '}
            isRoundedButton
            size={'sm'}
            color={'primary'}
            onClick={() => {
              window.location.href = getFullUrl('/', urlPrefix)
            }}>
            На главную
          </Button>
        </div>
      </div>
    </Container>
  )
}

export {PrePaymentSuccessPage}
