import React, {useMemo} from 'react'
import {Button} from 'grilnica-reactstrap'

interface ErrorLoadMenuNavComponentProps {
  className?: string
}

const ErrorLoadMenuNavComponent: React.FC<ErrorLoadMenuNavComponentProps> = ({
  className,
}): React.ReactElement => {
  const renderContent: React.ReactElement = useMemo(() => {
    return (
      <div
        className={
          'd-flex flex-row align-items-center justify-content-center w-100 ' +
          (className ? className : '')
        }>
        <span className={'text-body-2 text-white text-center mr-x4'}>
          не удалось загрузить меню
        </span>
        <Button
          color={'white'}
          size={'sm'}
          style={'outline'}
          isRoundedButton
          onClick={() => {
            window.location.reload()
          }}>
          Обновить
        </Button>
      </div>
    )
  }, [className])

  return <>{renderContent}</>
}

export {ErrorLoadMenuNavComponent}
