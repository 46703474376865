import React, {ReactNode, useCallback, useMemo, useState} from 'react'
import {BasketPreviewItem} from './components/BasketPreviewItem'
import {Button, Loading} from 'grilnica-reactstrap'
import {SelectedProductDTO} from 'grilnica-store-share'
import {bindBasketActions} from '../../../store/ducks/basket'
import {State} from '../../../store/ducks'
import {Redirect} from 'react-router-dom'
import {connectComponent} from '../../../store/common'

interface BasketPreviewProps {
  selectedProducts: SelectedProductDTO[]
  priceFull: number
  basketActions: any
  className?: string
  onClose: () => void
}

const BasketPreview: React.FC<BasketPreviewProps> = ({
  selectedProducts,
  priceFull,
  basketActions,
  className,
  onClose,
}): React.ReactElement => {
  const [redirectUrl, setRedirectUrl] = useState<string>(null)

  const changeRedirectUrl = useCallback(
    (url: string) => {
      setRedirectUrl(url)
      onClose()
      setTimeout(() => {
        setRedirectUrl(null)
      }, 100)
    },
    [onClose],
  )

  const deleteProductFromBasket = useCallback(
    (index: number) => {
      basketActions.deleteProductFromBasketRequest(index)
    },
    [basketActions],
  )

  const removeCountProduct = useCallback(
    (index: number) => {
      if (selectedProducts[index].count > 1) {
        basketActions.changeCountSelectedProductInBasketRequest(
          index,
          selectedProducts[index].count - 1,
        )
      }
    },
    [basketActions, selectedProducts],
  )

  const addCountProduct = useCallback(
    (index: number) => {
      if (selectedProducts[index].count < 100) {
        basketActions.changeCountSelectedProductInBasketRequest(
          index,
          selectedProducts[index].count + 1,
        )
      }
    },
    [basketActions, selectedProducts],
  )

  const renderProductList = useMemo(() => {
    if (selectedProducts) {
      const items: ReactNode[] = selectedProducts.map((item, index) => (
        <BasketPreviewItem
          selectedProduct={item}
          removeCountProduct={() => {
            removeCountProduct(index)
          }}
          addCountProduct={() => {
            addCountProduct(index)
          }}
          deleteProduct={() => {
            deleteProductFromBasket(index)
          }}
          key={item.product.productId + index}
        />
      ))
      return items
    } else {
      return <Loading />
    }
  }, [addCountProduct, deleteProductFromBasket, removeCountProduct, selectedProducts])

  const renderContent: React.ReactElement = useMemo(() => {
    return (
      <div className={'preview ' + (className ? className : '')}>
        <div className={'dish-list custom-scroll'}>
          {selectedProducts.length > 0 ? (
            renderProductList
          ) : (
            <p className={'empty-basket mb-0 text-center px-x4 pt-x4'}>
              Корзина пуста! <br /> Добавьте вкусняшку из меню
            </p>
          )}
        </div>
        <div className={'d-flex align-items-center justify-content-between mt-x4 px-x4 pb-x4'}>
          <p className={'order-sum mb-0'}>
            Сумма заказа: <span>{priceFull} &#8381;</span>
          </p>
          {selectedProducts.length > 0 ? (
            <Button
              onClick={() => {
                changeRedirectUrl('/basket')
              }}
              color={'primary'}
              isRoundedButton
              size={'sm'}
              className={'move-to-basket'}>
              Перейти в корзину
            </Button>
          ) : (
            <Button
              onClick={() => {
                changeRedirectUrl('/')
              }}
              color={'primary'}
              isRoundedButton
              size={'sm'}>
              Перейти в меню
            </Button>
          )}
        </div>
        {redirectUrl && <Redirect push to={redirectUrl} />}
      </div>
    )
  }, [
    changeRedirectUrl,
    className,
    priceFull,
    redirectUrl,
    renderProductList,
    selectedProducts.length,
  ])

  return <>{renderContent}</>
}

export default connectComponent(
  (state: State) => ({
    selectedProducts: state.basket.selectedProducts,
    priceFull: state.basket.priceFull,
  }),
  (dispatch) => ({
    basketActions: bindBasketActions(dispatch),
  }),
  BasketPreview,
)
