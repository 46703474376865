import {apiGet} from '../../api'
import {CONSTANTS} from '../../../constants'

const loadSliderListByCityIdService = async (cityId: string, errorActions: any) => {
  try {
    return await apiGet(CONSTANTS.apiStoreUrl + '/site/slider/find-by-city/' + cityId, {
      cityId: cityId,
    })
  } catch (error) {
    errorActions.setError(error)
  }
}

export {loadSliderListByCityIdService}
