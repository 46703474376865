import React, {useCallback, useEffect, useMemo} from 'react'
import {RecommendationsBlockComponent} from './RecommendationsBlockComponent'
import {connectComponent} from '../../../../../../store/common'
import {State} from '../../../../../../store/ducks'
import {bindBasketActions} from '../../../../../../store/ducks/basket'
import {bindActionActions} from '../../../../../../store/action/duck'
import {bindRecommendationsProductActions} from '../../../../../../store/recommendationsProduct/duck'
import {RecommendationsProductActionsType} from '../../../../../../store/recommendationsProduct/duck/actions'
import {Loading} from 'grilnica-reactstrap'
import {isEmptyObject} from 'grilnica-share'
import {ProductDTO, RecommendationProductMap, WeightMapDto} from 'grilnica-store-share'

interface RecommendationsBlockContainerProps {
  recommendationsProductActions: RecommendationsProductActionsType
  basketActions: any
  actionActions: any

  isRequest: boolean
  recommendationProductMap: RecommendationProductMap
  weightMap: WeightMapDto
}

const RecommendationsBlockContainer: React.FC<RecommendationsBlockContainerProps> = ({
  isRequest,
  recommendationsProductActions,
  recommendationProductMap,
  weightMap,
}): React.ReactElement => {
  const check = useCallback(() => {
    recommendationsProductActions.check()
  }, [recommendationsProductActions])

  useEffect(() => {
    check()
  }, [check])

  const addRecommendationProductInBasket = useCallback(
    (product: ProductDTO) => {
      recommendationsProductActions.addRecommendationProductInBasket(product)
    },
    [recommendationsProductActions],
  )

  const renderContent: React.ReactElement = useMemo(() => {
    if (isRequest) {
      return <Loading size={'m'} className={'mt-x8 mb-0'} />
    }
    if (!isEmptyObject(recommendationProductMap)) {
      return (
        <RecommendationsBlockComponent
          recommendationProductMap={recommendationProductMap}
          addRecommendationProductInBasket={addRecommendationProductInBasket}
          weightMap={weightMap}
        />
      )
    } else {
      return null
    }
  }, [isRequest, recommendationProductMap, addRecommendationProductInBasket, weightMap])

  return <>{renderContent}</>
}

export default connectComponent(
  (state: State) => ({
    isRequest: state.recommendationsProduct.isRequest,
    recommendationProductMap: state.recommendationsProduct?.recommendationProductMap,
    weightMap: state.menu.weightMap,
  }),
  (dispatch) => ({
    basketActions: bindBasketActions(dispatch),
    actionActions: bindActionActions(dispatch),
    recommendationsProductActions: bindRecommendationsProductActions(dispatch),
  }),
  RecommendationsBlockContainer,
)
