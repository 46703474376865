import {SelectedOption} from 'grilnica-store-share'

const getActiveOptionCategoryId = (
  selectedOptions: SelectedOption[],
  optionCategoryId: string,
): string => {
  if (selectedOptions) {
    for (let selectedOption of selectedOptions || []) {
      if (selectedOption.optionCategoryId === optionCategoryId) {
        return selectedOption.optionIds[0]
      }
    }
  }
}
export {getActiveOptionCategoryId}
