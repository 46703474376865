import React, {useMemo} from 'react'
import {OptionType} from '../../../../../../../../../types/product/OptionType'
import {OptionCategoryDTO, RestrictionsDTO} from 'grilnica-store-share'
import {OptionCategoryItem} from './OptionCategoryItem'
import {SupportsMaps} from '../../../../../../../../../types/product/SupportsMaps'

interface ProductOptionsBlockProps {
  supportsMaps: SupportsMaps
  toggleOption: (
    optionCategoryId: string,
    optionId: string,
    isChecked?: boolean,
    isOptional?: boolean,
  ) => void
  isNotForSale?: boolean
}

const ProductOptionsBlock: React.FC<ProductOptionsBlockProps> = ({
  supportsMaps,
  toggleOption,
  isNotForSale,
}): React.ReactElement => {
  const renderContent: React.ReactElement = useMemo(() => {
    if (
      supportsMaps &&
      supportsMaps.optionCategoryTypeMap &&
      (supportsMaps.optionCategoryTypeMap[OptionType.WITH_ICON]?.length !== 0 ||
        supportsMaps.optionCategoryTypeMap[OptionType.MAIN_TAB]?.length !== 0 ||
        supportsMaps.optionCategoryTypeMap[OptionType.ADDITIONAL_TAB]?.length !== 0)
    ) {
      let optionCategories: OptionCategoryDTO[] = []
      supportsMaps.optionCategoryTypeMap[OptionType.WITH_ICON].forEach((categoryId: string) => {
        optionCategories.push(supportsMaps.optionCategoryMap[categoryId])
      })
      supportsMaps.optionCategoryTypeMap[OptionType.MAIN_TAB].forEach((categoryId: string) =>
        optionCategories.push(supportsMaps.optionCategoryMap[categoryId]),
      )
      supportsMaps.optionCategoryTypeMap[OptionType.ADDITIONAL_TAB].forEach(
        (categoryId: string) => {
          optionCategories.push(supportsMaps.optionCategoryMap[categoryId])
        },
      )

      const renderItems: React.ReactNode[] = optionCategories.map((item) => {
        const optionCategoryRestriction: RestrictionsDTO =
          supportsMaps.restrictionsMap[item.optionCategoryId]
        return (
          <OptionCategoryItem
            key={item.optionCategoryId}
            optionCategory={item}
            selectedOptionsMap={supportsMaps.selectedOptionsMap}
            isOptional={optionCategoryRestriction.min !== optionCategoryRestriction.max}
            restrictionsMap={supportsMaps.restrictionsMap}
            countSelectedOptionsMap={supportsMaps.countSelectedOptionsMap}
            toggleOption={toggleOption}
            isExtraOptionCategory={supportsMaps.extraOptionsMap[item.optionCategoryId]}
            isNotForSale={isNotForSale}
          />
        )
      })

      return <>{renderItems}</>
    }
  }, [isNotForSale, supportsMaps, toggleOption])

  return <>{renderContent}</>
}

export {ProductOptionsBlock}
