import React, {useMemo} from 'react'
import {ProductWeekOrDayButton} from './ProductWeekOrDayButton'
import {MenuCategoryDTO, ProductMap, RestaurantByProductMap} from 'grilnica-store-share'
import {HappyHourAction} from '../../../../../store/action/types/HappyHourActionMap'

interface ProductWeekOrDayComponentProps {
  restaurantByProductMap: RestaurantByProductMap
  productMap: ProductMap
  categoryList: MenuCategoryDTO[]
  happyHourDay: HappyHourAction
  happyHourWeek: HappyHourAction
}

const ProductWeekOrDayComponent: React.FC<ProductWeekOrDayComponentProps> = ({
  productMap,
  restaurantByProductMap,
  categoryList,
  happyHourDay,
  happyHourWeek,
}): React.ReactElement => {
  const renderContent: React.ReactElement = useMemo(() => {
    return (
      <>
        <ProductWeekOrDayButton
          productMap={productMap}
          restaurantByProductMap={restaurantByProductMap}
          categoryList={categoryList}
          happyHourDay={happyHourDay}
          happyHourWeek={happyHourWeek}
        />
      </>
    )
  }, [categoryList, happyHourDay, happyHourWeek, productMap, restaurantByProductMap])
  return <>{renderContent}</>
}

export {ProductWeekOrDayComponent}
