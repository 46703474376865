import React, {useMemo} from 'react'
import {ButtonLink, Container} from 'grilnica-reactstrap'
import {getStaticUrl} from '../../../../utils/static'

interface QuickOrderSuccessPageProps {}

const QuickOrderSuccessPage: React.FC<QuickOrderSuccessPageProps> = ({}): React.ReactElement => {
  const renderContent: React.ReactElement = useMemo(() => {
    return (
      <Container size={'normal'} className={'mt-x10 mb-x10'}>
        <div className={'payment'}>
          <h1 className={'h1 mb-x6'}>Заказ успешно оформлен!</h1>
          <img
            src={getStaticUrl('/img/basket-img.svg')}
            alt={'Заказ успешно оформлен'}
            title={'Заказ успешно оформлен'}
            width={'100%'}
            height={'auto'}
            className={'mb-x6'}
          />
          <p className={'descriptions'}>Ожидайте звонка оператора</p>
          <ButtonLink to={'/'} color={'primary'} className={'btn-size'} isRoundedButton>
            На главную
          </ButtonLink>
        </div>
      </Container>
    )
  }, [])
  return <>{renderContent}</>
}

export {QuickOrderSuccessPage}
