import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {ButtonIcon, COLORS} from 'grilnica-reactstrap'
import {closeSVG} from '../../../../components/svg/SystemIcons'
import {LSKeys, LSMethods} from '../../../../../storage'
import {ProductWeekOrDayCard} from './ProductWeekOrDayCard'
import {HappyHourProducts} from './ProductWeekOrDayContainer'
import {MenuCategoryDTO, ProductMap, RestaurantByProductMap} from 'grilnica-store-share'
import {HappyHourAction} from '../../../../../store/action/types/HappyHourActionMap'
import moment from 'moment'

interface ProductWeekOrDayButtonProps {
  restaurantByProductMap: RestaurantByProductMap
  productMap: ProductMap
  categoryList: MenuCategoryDTO[]
  happyHourDay: HappyHourAction
  happyHourWeek: HappyHourAction
}

const ProductWeekOrDayButton: React.FC<ProductWeekOrDayButtonProps> = ({
  restaurantByProductMap,
  productMap,
  categoryList,
  happyHourDay,
  happyHourWeek,
}): React.ReactElement => {
  const [isShowProductWeek, setIsShowProductWeek] = useState<boolean>(false)
  const [isShowProductDay, setIsShowProductDay] = useState<boolean>(false)
  const [isShowModal, setIsShowModal] = useState<boolean>(false)

  const [isCompleteCloseModalDay, setIsCompleteCloseModalDay] = useState<boolean>(false)
  const [isCompleteCloseModalWeek, setIsCompleteCloseModalWeek] = useState<boolean>(false)
  const [countClickModalDay, setCountClickModalDay] = useState<number>(0)
  const [countClickModalWeek, setCountClickModalWeek] = useState<number>(0)

  const handleClickOutside = useCallback((e) => {
    const path = e.path || (e.composedPath && e.composedPath())
    const saleProduct = document.getElementsByClassName('product-week-or-day')[0]
    if (path && saleProduct && !path.includes(saleProduct)) {
      setIsShowModal(false)
    }
  }, [])

  useEffect(() => {
    document.addEventListener('click', handleClickOutside)
    return () => {
      document.removeEventListener('click', handleClickOutside)
    }
  }, [handleClickOutside])

  const [currentDay] = useState(moment().day())

  const getProducts = useMemo((): HappyHourProducts => {
    const products: HappyHourProducts = {productOfDay: null, productOfWeek: null}
    for (let key in productMap) {
      for (let p of productMap[key]) {
        if (happyHourWeek && p.productId === happyHourWeek.productId) {
          products.productOfWeek = p
        }
        if (happyHourDay && p.productId === happyHourDay.productId) {
          products.productOfDay = p
        }
      }
    }
    return products
  }, [happyHourDay, happyHourWeek, productMap])

  const renderToggleButton = useCallback(
    (isWeek: boolean) => {
      const COUNT_OF_CLICK = 2
      let isShowCloseButton = false

      if (
        (isWeek && countClickModalWeek >= COUNT_OF_CLICK) ||
        (!isWeek && countClickModalDay >= COUNT_OF_CLICK)
      ) {
        isShowCloseButton = true
      }

      return (
        <>
          <p className={' mb-0'}>
            {!isWeek ? happyHourDay?.happyHourName : happyHourWeek?.happyHourName}
          </p>
          {isShowCloseButton && (
            <>
              <ButtonIcon
                iconPath={closeSVG}
                color={COLORS.white}
                title={'Закрыть'}
                className={'ml-x2 button-close-big'}
                size={'md'}
                isHoverArea
                hoverIconColor={'black'}
                onClick={(e) => {
                  e.stopPropagation()
                  if (isWeek) {
                    setIsCompleteCloseModalWeek(true)
                  } else {
                    setIsCompleteCloseModalDay(true)
                  }
                  setIsShowModal(false)
                  LSMethods.setStorageData(LSKeys.TIME_CLOSE_SALE_MODAL, new Date())
                }}
              />
              <ButtonIcon
                iconPath={closeSVG}
                color={COLORS.white}
                title={'Закрыть'}
                className={'ml-x2 button-close-small'}
                size={'sm'}
                isHoverArea
                hoverIconColor={'black'}
                onClick={(e) => {
                  e.stopPropagation()
                  if (isWeek) {
                    setIsCompleteCloseModalWeek(true)
                  } else {
                    setIsCompleteCloseModalDay(true)
                  }
                  setIsShowModal(false)
                  LSMethods.setStorageData(LSKeys.TIME_CLOSE_SALE_MODAL, new Date())
                }}
              />
            </>
          )}
        </>
      )
    },
    [
      countClickModalDay,
      countClickModalWeek,
      happyHourDay?.happyHourName,
      happyHourWeek?.happyHourName,
    ],
  )

  const renderContent = useMemo(() => {
    const isOpen: boolean =
      isShowModal &&
      ((isShowProductWeek && !isCompleteCloseModalWeek) ||
        (isShowProductDay && !isCompleteCloseModalDay))
    return (
      <div className={`product-week-or-day ${isOpen ? 'open' : ''}`}>
        <div className={'pwod-button-block ' + (isOpen ? 'open' : '')}>
          {happyHourWeek && getProducts.productOfWeek && (
            <div
              className={
                'pwod-button week ' +
                (isShowModal && isShowProductWeek ? 'button-hide' : 'button-side') +
                ((isShowModal && isShowProductDay) || isCompleteCloseModalWeek ? ' d-none' : '')
              }
              onClick={() => {
                if (isShowModal) {
                  setIsShowModal(false)
                } else {
                  setIsShowProductDay(false)
                  setIsShowProductWeek(true)
                  setIsShowModal(true)
                }
                setCountClickModalWeek(countClickModalWeek + 1)
              }}>
              {renderToggleButton(true)}
            </div>
          )}
          {happyHourDay && getProducts.productOfDay && (
            <div
              className={
                'pwod-button day ' +
                (isShowModal && isShowProductDay ? 'button-hide' : 'button-side') +
                ((isShowModal && isShowProductWeek) || isCompleteCloseModalDay ? ' d-none' : '')
              }
              onClick={() => {
                if (isShowModal) {
                  setIsShowModal(false)
                } else {
                  setIsShowProductWeek(false)
                  setIsShowProductDay(true)
                  setIsShowModal(true)
                }
                setCountClickModalDay(countClickModalDay + 1)
              }}>
              {renderToggleButton(false)}
            </div>
          )}
        </div>
        <ProductWeekOrDayCard
          isProductWeek={isShowProductWeek}
          product={
            isShowProductWeek
              ? getProducts.productOfWeek
              : isShowProductDay
              ? getProducts.productOfDay
              : null
          }
          currentDay={currentDay}
          productMap={productMap}
          categoryList={categoryList}
          restaurantByProductMap={restaurantByProductMap}
        />
      </div>
    )
  }, [
    countClickModalWeek,
    countClickModalDay,
    categoryList,
    currentDay,
    getProducts.productOfDay,
    getProducts.productOfWeek,
    happyHourDay,
    happyHourWeek,
    isCompleteCloseModalDay,
    isCompleteCloseModalWeek,
    isShowModal,
    isShowProductDay,
    isShowProductWeek,
    productMap,
    renderToggleButton,
    restaurantByProductMap,
  ])

  return <>{renderContent}</>
}

export {ProductWeekOrDayButton}
