import {SelectedProductDTO} from '../../types/product/SelectedProductDTO'
import {ActionSale} from 'grilnica-store-share/lib/action/ActionSale'

const getPriceAndSaleFull = (
  selectedProducts: SelectedProductDTO[],
  selectedActionCouponId?: string,
): {priceFull: number; saleFull: number} => {
  let priceFull: number = 0
  let saleFull: number = 0

  for (let p of selectedProducts) {
    let saleByProduct: number = 0
    if (p.sales) {
      for (let key in p.sales) {
        let s: number = p.sales[key].sale

        if (p.sales[key].isCoupon) {
          if (selectedActionCouponId && key === selectedActionCouponId) {
            saleByProduct = saleByProduct + s
          }
        } else {
          saleByProduct = saleByProduct + s
        }
      }

      for (let key in p.sales) {
        if (key === selectedActionCouponId && p.sales[key].isPersonalCoupon) {
          const values: ActionSale[] = Object.values(p.sales)
          for (let i = 0; i < values.length; i++) {
            if (!values[i].isCoupon) {
              values[i].sale = (values[i].sale / p.count) * (p.count - 1)
            }
          }
        }
      }
    }
    priceFull = priceFull + (p.sales ? p.price : p.fullPrice) * p.count - saleByProduct
    saleFull = saleFull + (p.sales ? saleByProduct : p.price - p.fullPrice)
  }

  return {priceFull, saleFull}
}

export {getPriceAndSaleFull}
