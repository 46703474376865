export enum PaymentModalActionsTypes {
  CONTROLLER = 'pModal/CONTROLLER',
  SET_IS_OPEN = 'pModal/SET_IS_OPEN',
  SET_CURRENT_STAGE = 'pModal/SET_CURRENT_STAGE',
  SET_IS_CLEAR_TIMER = 'pModal/SET_IS_CLEAR_TIMER',
  SET_IS_REDIRECT_TO_SUCCESS = 'pModal/SET_IS_REDIRECT_TO_SUCCESS',
  SET_IS_REDIRECT_TO_HOME = 'pModal/SET_IS_REDIRECT_TO_HOME',
  SET_IS_REDIRECT_TO_ERROR = 'pModal/SET_IS_REDIRECT_TO_ERROR',
  SET_REPAIR_ERROR = 'pModal/SET_REPAIR_ERROR',
}
