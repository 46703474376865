export enum TerminalActionsTypes {
  SET_TERMINAL = 't/SET_TERMINAL',
  SET_TERMINAL_ALIAS = 't/SET_TERMINAL_ALIAS',
  SET_IS_CHECK_FOR_INACTIVITY = 't/SET_IS_CHECK_FOR_INACTIVITY',
  SET_TERMINAL_CONFIG = 't/SET_TERMINAL_CONFIG',
  CONTROLLER_SOCKET_REDIRECT_TO_HOME = 't/CONTROLLER_SOCKET_REDIRECT_TO_HOME',
  CONTROLLER_SOCKET_REDIRECT_TO_REPAIR = 't/CONTROLLER_SOCKET_REDIRECT_TO_REPAIR',
  SET_IS_REDIRECT_TO_HOME_WITH_RELOAD_PAGE = 't/SET_IS_REDIRECT_TO_HOME_WITH_RELOAD_PAGE',
  SET_IS_REDIRECT_TO_REPAIR_WITH_RELOAD_PAGE = 't/SET_IS_REDIRECT_TO_REPAIR_WITH_RELOAD_PAGE',
}
