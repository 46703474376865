import {BaseAction} from '../../common/BaseAction'
import {TerminalActionsTypes} from './types'
import {TerminalState} from '../types'

const initialTerminalState: TerminalState = {
  isCheckForInactivity: false,
  terminalAlias: null,
  terminal: null,
  isRedirectToRepairWithReloadPage: false,
  isRedirectToHomeWithReloadPage: false,
}

export default function terminal(
  state: TerminalState = initialTerminalState,
  dispatch: BaseAction,
): TerminalState {
  switch (dispatch.type) {
    case TerminalActionsTypes.SET_IS_CHECK_FOR_INACTIVITY: {
      return {
        ...state,
        isCheckForInactivity: dispatch.payload,
      }
    }

    case TerminalActionsTypes.SET_TERMINAL: {
      return {
        ...state,
        terminal: dispatch.payload,
      }
    }

    case TerminalActionsTypes.SET_TERMINAL_ALIAS: {
      return {
        ...state,
        terminalAlias: dispatch.payload,
      }
    }

    case TerminalActionsTypes.SET_TERMINAL_CONFIG: {
      return {
        ...state,
        terminalConfig: dispatch.payload,
      }
    }

    case TerminalActionsTypes.SET_IS_REDIRECT_TO_HOME_WITH_RELOAD_PAGE: {
      return {
        ...state,
        isRedirectToHomeWithReloadPage: dispatch.payload,
      }
    }

    case TerminalActionsTypes.SET_IS_REDIRECT_TO_REPAIR_WITH_RELOAD_PAGE: {
      return {
        ...state,
        isRedirectToRepairWithReloadPage: dispatch.payload,
      }
    }

    default:
      return state
  }
}
