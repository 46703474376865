import {formatIncompletePhoneNumber} from 'libphonenumber-js'

function trimPhone(phone: string): string {
  if (phone === '+7') {
    return null
  } else {
    return phone
  }
}

function reFormatPhone(phoneExists: string) {
  let newPhone: string = phoneExists
  if (!phoneExists) {
    return null
  }
  if (phoneExists.substring(0, 1) === '8') {
    newPhone = '+7' + phoneExists.substring(1)
  }
  return formatIncompletePhoneNumber(newPhone, 'RU')
}

function onChangePhone(phoneExists: string, phoneNew: string) {
  if (
    (!phoneExists || phoneExists.substring(0, 2) === '+7') &&
    phoneNew.substr(0, 2) === '+7' &&
    phoneNew.length <= 16
  ) {
    return formatIncompletePhoneNumber(phoneNew, 'RU')
  } else {
    return phoneExists
  }
}

export {trimPhone, onChangePhone, reFormatPhone}
