import React, {useMemo} from 'react'
import {RecommendationsProducts} from './RecommendationsProducts'
import {ProductDTO, RecommendationProductMap, WeightMapDto} from 'grilnica-store-share'

interface RecommendationsBlockComponentProps {
  recommendationProductMap: RecommendationProductMap
  addRecommendationProductInBasket: (product: ProductDTO) => void
  weightMap: WeightMapDto
}

const RecommendationsBlockComponent: React.FC<RecommendationsBlockComponentProps> = ({
  recommendationProductMap,
  addRecommendationProductInBasket,
  weightMap,
}): React.ReactElement => {
  const renderContent: React.ReactElement = useMemo(() => {
    let items: React.ReactElement[] = []
    for (let groupId in recommendationProductMap) {
      if (recommendationProductMap[groupId]) {
        const {products, catalogRecommendationsGroup} = recommendationProductMap[groupId]
        if (products?.length > 0) {
          items.push(
            <RecommendationsProducts
              key={catalogRecommendationsGroup.catalogRecommendationGroupId}
              id={catalogRecommendationsGroup.catalogRecommendationGroupId}
              products={products}
              catalogRecommendationsGroup={catalogRecommendationsGroup}
              addRecommendationProductInBasket={addRecommendationProductInBasket}
              weightMap={weightMap}
            />,
          )
        }
      }
    }
    return <>{items}</>
  }, [recommendationProductMap, addRecommendationProductInBasket, weightMap])

  return <>{renderContent}</>
}

export {RecommendationsBlockComponent}
