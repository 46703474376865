import {call, delay, put, select} from 'redux-saga/effects'
import {
  AutoChoiceRestaurantType,
  CashboxPaymentType,
  CheckDiscountResultDTO,
  ClientAddressDTO,
  ClientDTO,
  DeliveryTypeEnum,
  MenuEntityTypeEnum,
  OrderStatusEnum,
  OrderTypeEnum,
  PaymentTypeMap,
  PrepareOrder,
  PrepareOrderTime,
  ProductMap,
  SelectedOrderComboProductsMap,
  SelectedProductDTO,
} from 'grilnica-store-share'
import {LSKeys, LSMethods} from '../../../storage'
import {Actions as OrderActions} from '../../ducks/order'
import {Actions as ErrorActions} from '../../ducks/error'
import {State} from '../../ducks'
import {CityDTO, PaymentTypeEnum, RestaurantDTO} from 'grilnica-share'
import {getFullAddress} from '../../../utils/address'
import {OrderDTO} from 'grilnica-store-share/lib/order/OrderDTO'
import {
  checkOrderService,
  getAddMinutesOrderService,
  getCreatedOrdersService,
  getOrderService,
  payOrderForPaymentService,
  saveOrderService,
} from '../../../services/order'
import {OrderProductDTO} from 'grilnica-store-share/lib/order/OrderProductDTO'
import {DeliveryInfo} from '../../../types/order/DeliveryInfo'
import {getPaymentTypeListUtils} from '../../../utils/order/getPaymentTypeList'
import {getDeliveryPrice} from '../address'
import {
  addEcommerceProduct,
  checkActions,
  clearBasket,
  setSelectedProducts,
  toggleProductAddPopover,
} from '../basket/basket'
import {createPaymentOnlineCard} from '../payment'
import {getSelectedProduct} from './utils/product/getSelectedProduct'
import {getSelectedComboProduct} from './utils/comboProduct/getSelectedComboProduct'
import {
  ECOMMERCE_ACTION_TYPE,
  EcommerceActionFieldType,
  EcommerceProductType,
  ecommerceTarget,
} from '../../../utils/metrica/ym/ecommerceTarget'
import {ymTarget} from '../../../utils/metrica/ym/ymTarget'
import {gaTarget} from '../../../utils/metrica/ga/gaTarget'
import {SaveOrderEnum} from '../../../types/order/SaveOrderEnum'
import {TerminalState} from '../../terminal/types'
import {TerminalActions} from '../../terminal/duck'
import {PaymentModalActions} from '../../paymentModal/duck'
import {PaymentModalStageEnum} from '../../paymentModal/types'
import moment from 'moment-timezone'
import {TIME_INTERVAL} from '../../../view/site/containers/order/components/time/constants'
import {Actions as BasketActions} from '../../ducks/basket'

function* initializeOrder(isInitializeAdditionInfo: boolean) {
  // console.log('*initializeOrder')
  const terminalState: TerminalState = yield select((state: State) => state.terminal)
  const terminalAlias: string = terminalState?.terminalAlias
  const prepareOrder: PrepareOrder = yield call(
    LSMethods.getStorageData,
    LSKeys.PREPARE_ORDER,
    null,
  )
  if (prepareOrder && prepareOrder.orderId && !terminalAlias) {
    yield put(OrderActions.setPrepareOrder(prepareOrder))
  } else {
    yield call(initializeOrderAddressFields)
    if (terminalAlias) {
      yield call(initializeOrderRestaurant)
    } else {
      yield call(initializeOrderAuthFields)
    }
  }
  if (isInitializeAdditionInfo) {
    yield call(initializeAdditionalInfo)
  }
}

function* initializeAdditionalInfo() {
  try {
    const city: CityDTO = yield select((state: State) => state.city.selectedCity)
    const addMinutesOrder = yield call(getAddMinutesOrderService, city?.cityId)
    if (addMinutesOrder) {
      yield put(OrderActions.setAddMinutesOrder(addMinutesOrder))
    }
    const client: ClientDTO = yield select((state: State) => state.auth.client)
    if (client) {
      try {
        const createdOrders: OrderDTO[] = yield call(getCreatedOrdersService, city?.cityId)
        let autoChoiceRestaurant: AutoChoiceRestaurantType = {
          restaurantId: null,
          isAutoChoice: false,
        }
        const isAutoChoice: boolean = yield call(
          LSMethods.getStorageData,
          LSKeys.IS_AUTO_CHOICE_RESTAURANT,
          null,
        )
        let lastPickupOrder: OrderDTO = null
        for (let createdOrder of createdOrders || []) {
          if (createdOrder.deliveryType === DeliveryTypeEnum.PICKUP) {
            lastPickupOrder = {...createdOrder}
            break
          }
        }
        if (lastPickupOrder) {
          const selectedCity: CityDTO = yield select((state: State) => state.city.selectedCity)
          if (
            selectedCity &&
            lastPickupOrder.cityId === selectedCity.cityId &&
            lastPickupOrder.deliveryType === DeliveryTypeEnum.PICKUP
          ) {
            autoChoiceRestaurant.restaurantId = lastPickupOrder.restaurantId
            if (isAutoChoice) {
              autoChoiceRestaurant.isAutoChoice = isAutoChoice
            }
          }
        }
        yield put(OrderActions.setAutoChoiceRestaurant(autoChoiceRestaurant))
      } catch (e) {
        console.log(e)
      }
    }
  } catch (error) {
    console.info('initializeAdditionalInfo error: ', error)
  }
}

function* changeAutoChoiceRestaurant({payload}: any) {
  const autoChoiceRestaurant: AutoChoiceRestaurantType = payload
  yield call(
    LSMethods.setStorageData,
    LSKeys.IS_AUTO_CHOICE_RESTAURANT,
    autoChoiceRestaurant.isAutoChoice,
  )
  yield put(OrderActions.setAutoChoiceRestaurant(autoChoiceRestaurant))
}

function* initializeOrderAuthFields() {
  let client: ClientDTO = yield select((state: State) => state.auth.client)
  if (client) {
    yield put(OrderActions.setPrepareOrderField('phone', client.phone))
    if (client.firstName || client.lastName) {
      yield put(
        OrderActions.setPrepareOrderField(
          'name',
          client.firstName + (client.lastName ? ' ' + client.lastName : ''),
        ),
      )
    }
    yield put(OrderActions.setPrepareOrderField('paymentType', PaymentTypeEnum.CASH))
  }
}

function* initializeOrderAddressFields() {
  const clientAddresses: ClientAddressDTO[] = yield call(
    LSMethods.getStorageData,
    LSKeys.CLIENT_ADDRESSES,
    [],
  )

  const city: CityDTO = yield select((state: State) => state.city.selectedCity)
  if (city) {
    yield put(OrderActions.setPrepareOrderField('city', city))
    yield put(OrderActions.setPrepareOrderField('cityId', city.cityId))
  }

  if (clientAddresses.length !== 0) {
    let defaultClientAddress: ClientAddressDTO = clientAddresses.find((item) => item.isDefault)
    if (defaultClientAddress) {
    }
  } else {
    let newClientAddress: ClientAddressDTO = {
      building: null,
      streetKladrId: null,
      street: null,
      streetType: null,
      house: null,
      houseFiasId: null,
      clientId: null,
      city: city ? city.name : null,
      cityId: city ? city.cityId : null,
      isDefault: null,
      latitude: null,
      longitude: null,
    }
    yield call(setOrderAddressFields, newClientAddress)
  }
}

function* initializeOrderRestaurant() {
  const terminalState: TerminalState = yield select((state: State) => state.terminal)
  if (terminalState) {
    yield put(OrderActions.setPrepareOrderField('restaurant', terminalState))
  }
  yield put(OrderActions.setPrepareOrderField('deliveryType', DeliveryTypeEnum.PICKUP))
}

function* initializeCreatedOrders() {
  const client: ClientDTO = yield select((state: State) => state.auth.client)
  if (client) {
    try {
      yield call(getCreatedOrders, false)
    } catch (e) {
      console.log(e)
    }
  }
}

function* setDeliveryPrice(latitude?: number, longitude?: number, prepareTime?: PrepareOrderTime) {
  const priceFull: number = yield select((state: State) => state.basket.priceFull)

  const newPrepareTime = getPrepareTimeForApi(
    {
      deliveryType: DeliveryTypeEnum.DELIVERY,
    } as any,
    prepareTime,
  )

  const deliveryInfo: DeliveryInfo = yield call(
    getDeliveryPrice,
    latitude,
    longitude,
    newPrepareTime.orderTime as Date,
    priceFull,
  )
  yield put(OrderActions.setDeliveryInfo(deliveryInfo))
  yield put(BasketActions.setDeliverySale(undefined))

  if (deliveryInfo) {
    yield call(setDefaultPaymentType, deliveryInfo?.restaurantId, DeliveryTypeEnum.DELIVERY)
    if (deliveryInfo && deliveryInfo?.restaurantId) {
      // console.log('Проверка для доставки 1')
      yield call(checkActions, {
        payload: {type: null, newSelectedActionCouponId: null, isByRestaurant: true},
      })
    }
  }
}

function* setPrepareOrderSelectedAddress({payload}: any) {
  const clientAddress: ClientAddressDTO = payload
  const prepareOrder: PrepareOrder = yield select((state: State) => state.order.prepareOrder)
  if (clientAddress.latitude && clientAddress.longitude) {
    yield call(
      setDeliveryPrice,
      clientAddress.latitude,
      clientAddress.longitude,
      prepareOrder.prepareTime,
    )
  } else {
    yield put(BasketActions.setDeliverySale(undefined))
  }
  yield call(setOrderAddressFields, clientAddress)
}

function* setOrderAddressFields(clientAddress: ClientAddressDTO) {
  yield put(OrderActions.setPrepareOrderField('selectedAddressText', getFullAddress(clientAddress)))
  yield put(OrderActions.setPrepareOrderField('selectedAddress', clientAddress))
  yield put(
    OrderActions.setPrepareOrderField('prepareTime', {
      orderTime: null,
      isQuickly: true,
    }),
  )
}

function* setPrepareOrderPrepareTime({payload}: any) {
  const prepareTime: PrepareOrderTime = payload
  const prepareOrder: PrepareOrder = yield select((state: State) => state.order.prepareOrder)

  yield put(OrderActions.setPrepareOrderField('prepareTime', prepareTime))
  if (prepareOrder.deliveryType === DeliveryTypeEnum.DELIVERY) {
    if (
      prepareOrder?.selectedAddress &&
      (prepareTime?.isQuickly || (!prepareTime?.isQuickly && !!prepareTime?.orderTime))
    ) {
      yield call(
        setDeliveryPrice,
        prepareOrder.selectedAddress.latitude,
        prepareOrder.selectedAddress.longitude,
        prepareTime,
      )
    }
  }
}

function* clearDeliveryTime() {
  const prepareOrder: PrepareOrder = yield select((state: State) => state.order.prepareOrder)
  if (prepareOrder.prepareTime?.orderTime) {
    yield put(OrderActions.setPrepareOrderField('orderTime', null, 'prepareTime'))
    yield put(OrderActions.toggleIsShowDiscardedDeliveryTimeModal(true, prepareOrder.deliveryType))
  }
}

function* setPrepareOrderRestaurant({payload}: any) {
  const restaurant: RestaurantDTO = payload
  yield put(OrderActions.setPrepareOrderField('restaurant', restaurant))
  yield clearDeliveryTime()
  yield call(setDefaultPaymentType, restaurant.restaurantId, DeliveryTypeEnum.PICKUP)
  if (restaurant && restaurant.restaurantId) {
    // console.log('Проверка для самовывоза 1')
    yield call(checkActions, {
      payload: {type: null, newSelectedActionCouponId: null, isByRestaurant: true},
    })
  }
}

function* setPrepareOrderDeliveryType({payload}: any) {
  const deliveryType: DeliveryTypeEnum = payload
  yield clearDeliveryTime()
  yield put(OrderActions.setPrepareOrderField('deliveryType', deliveryType))
  if (deliveryType === DeliveryTypeEnum.DELIVERY) {
    yield call(updateDeliveryPrice)
  } else {
    const autoChoiceRestaurant: AutoChoiceRestaurantType = yield select(
      (state: State) => state.order.autoChoiceRestaurant,
    )
    if (autoChoiceRestaurant.isAutoChoice && autoChoiceRestaurant.restaurantId) {
      const restaurants: RestaurantDTO[] = yield select(
        (state: State) => state.restaurant.restaurants,
      )
      if (restaurants.length > 0) {
        let restaurant: RestaurantDTO = null
        for (let itemR of restaurants) {
          if (itemR.restaurantId === autoChoiceRestaurant.restaurantId) {
            restaurant = itemR
            break
          }
        }
        if (restaurant) {
          yield put(OrderActions.setPrepareOrderField('restaurant', restaurant))
        }
      }
    }

    const prepareOrder: PrepareOrder = yield select((state: State) => state.order.prepareOrder)
    if (prepareOrder?.restaurant?.restaurantId) {
      // console.log('Проверка для самовывоз 2')
      yield call(checkActions, {
        payload: {type: null, newSelectedActionCouponId: null, isByRestaurant: true},
      })
    } else {
      yield put(BasketActions.setDeliverySale(undefined))
    }
    yield call(
      setDefaultPaymentType,
      prepareOrder?.restaurant?.restaurantId,
      DeliveryTypeEnum.PICKUP,
    )
  }
  // yield call(getCheckOrder)
}

function* setDefaultPaymentType(restaurantId: string, deliveryType: DeliveryTypeEnum): any {
  const restaurantPaymentTypeMap: PaymentTypeMap = yield select(
    (state: State) => state.restaurant.restaurantPaymentTypeMap,
  )

  if (restaurantPaymentTypeMap) {
    const cashboxPaymentType: CashboxPaymentType = restaurantPaymentTypeMap[restaurantId]

    if (cashboxPaymentType) {
      const prepareOrder: PrepareOrder = yield select((state: State) => state.order.prepareOrder)

      let paymentTypeList: PaymentTypeEnum[] = yield call(
        getPaymentTypeListUtils,
        deliveryType,
        cashboxPaymentType,
      )
      if (paymentTypeList) {
        if (prepareOrder.paymentType && paymentTypeList.includes(prepareOrder.paymentType)) {
          yield put(OrderActions.setPrepareOrderField('paymentType', prepareOrder.paymentType))
        } else {
          const paymentType: PaymentTypeEnum = paymentTypeList.find(
            (item: PaymentTypeEnum) =>
              item === PaymentTypeEnum.CASH ||
              item === PaymentTypeEnum.CARD ||
              item === PaymentTypeEnum.ONLINE,
          )
          yield put(OrderActions.setPrepareOrderField('paymentType', paymentType))
        }
      } else {
        yield put(OrderActions.setPrepareOrderField('paymentType', null))
        yield put(
          ErrorActions.setError(
            {
              status: 406,
              errorMessage:
                'Для касс выбранного ресторана не заданы способы оплаты. Обратитесь в службу поддержки.',
            },
            'Не заданы способы оплаты',
          ),
        )
      }
    } else {
      yield put(OrderActions.setPrepareOrderField('paymentType', null))
      if (restaurantId) {
        yield put(
          ErrorActions.setError(
            {
              status: 406,
              errorMessage:
                'Для касс выбранного ресторана не заданы способы оплаты. Обратитесь в службу поддержки.',
            },
            'Не заданы способы оплаты',
          ),
        )
      }
    }
  } else {
    yield put(OrderActions.setPrepareOrderField('paymentType', null))
    yield put(
      ErrorActions.setError(
        {
          status: 406,
          errorMessage: 'Проверьте интернет соединение или обратитесь в службу поддержки.',
        },
        'Ошибка при загрузки способов оплаты',
      ),
    )
  }
}

function* updateDeliveryPrice() {
  const prepareOrder: PrepareOrder = yield select((state: State) => state.order.prepareOrder)
  if (
    prepareOrder.deliveryType === DeliveryTypeEnum.DELIVERY &&
    prepareOrder.selectedAddress &&
    prepareOrder.selectedAddress.street &&
    prepareOrder.selectedAddress.house &&
    prepareOrder.selectedAddress.houseFiasId &&
    prepareOrder.selectedAddress.latitude &&
    prepareOrder.selectedAddress.longitude
  ) {
    yield call(
      setDeliveryPrice,
      prepareOrder.selectedAddress.latitude,
      prepareOrder.selectedAddress.longitude,
      prepareOrder.prepareTime,
    )
  } else {
    yield put(OrderActions.toggleIsRequestUpdateDeliveryPriceOrder(false))
    yield put(OrderActions.setDeliveryInfo(null))
    yield put(BasketActions.setDeliverySale(undefined))
  }
}

function* getOrderProduct(selectedProduct: SelectedProductDTO) {
  const optionIds: string[] = []
  for (let selectedOption of selectedProduct.selectedOptions) {
    optionIds.push(...selectedOption.optionIds)
  }
  const newProduct: OrderProductDTO = {
    selectedProductId: selectedProduct.selectedProductId,
    productId: selectedProduct.product.productId,
    count: selectedProduct.count,
    productVariantId: selectedProduct.productVariantId,
    price: selectedProduct.price,
    optionIds: optionIds,
    productName: selectedProduct.product.name,
    type: selectedProduct.product.type,
    iikoComboGroupId: selectedProduct.iikoComboGroupId,
  }
  return newProduct
}

function getPrepareTimeForApi(
  prepareOrder: PrepareOrder,
  newPrepareTime?: PrepareOrderTime,
): PrepareOrderTime {
  let prepareOrderTime = newPrepareTime || prepareOrder?.prepareTime
  let prepareTime: PrepareOrderTime
  if (prepareOrderTime) {
    prepareTime = {
      ...prepareOrderTime,
    }
  }

  if (
    ((prepareOrder?.deliveryType === DeliveryTypeEnum.DELIVERY &&
      !prepareOrderTime?.isQuickly &&
      prepareOrderTime?.orderTime) ||
      newPrepareTime) &&
    prepareTime?.orderTime
  ) {
    prepareTime.orderTime = moment(prepareTime.orderTime)
      .subtract(TIME_INTERVAL, 'minutes')
      .format('YYYY-MM-DDTHH:mm:ssZ') as any
  }
  return prepareTime
}

function* createNewOrder(saveOrderType: SaveOrderEnum, phoneNumber?: string) {
  const selectedCity: CityDTO = yield select((state: State) => state.city.selectedCity)
  const terminalState: TerminalState = yield select((state: State) => state.terminal)
  let prepareOrder: PrepareOrder
  switch (saveOrderType) {
    case SaveOrderEnum.FAST:
      prepareOrder = {
        prepareTime: {orderTime: null, isQuickly: true},
        priceFull: null,
        orderId: null,
        cityId: selectedCity.cityId,
        commentary: '',
        countPersons: null,
        name: null,
        deliveryType: null,
        isCallBack: true,
        paymentType: null,
        phone: phoneNumber,
        additionPhone: null,
        selectedAddress: {
          building: null,
          streetKladrId: null,
          street: null,
          streetType: null,
          house: null,
          houseFiasId: null,
          clientId: null,
          city: selectedCity ? selectedCity.name : null,
          cityId: selectedCity ? selectedCity.cityId : null,
          isDefault: null,
          latitude: null,
          longitude: null,
        },
      }
      break
    case SaveOrderEnum.NORMAL:
      prepareOrder = yield select((state: State) => state.order.prepareOrder)
      prepareOrder = {...prepareOrder}
      prepareOrder.prepareTime = getPrepareTimeForApi(prepareOrder)
      break
    case SaveOrderEnum.TERMINAL:
      const {terminalConfig, terminal} = terminalState
      prepareOrder = {
        prepareTime: {orderTime: null, isQuickly: true},
        priceFull: null,
        orderId: null,
        cityId: selectedCity.cityId,
        commentary: '',
        countPersons: null,
        name: `Терминал: ${terminal.alias}`,
        deliveryType: DeliveryTypeEnum.PICKUP,
        isCallBack: false,
        paymentType: PaymentTypeEnum.PIN_PAD,
        phone: terminalConfig.phoneNumberForCreatingOrders,
        additionPhone: null,
        selectedAddress: {
          building: null,
          streetKladrId: null,
          street: null,
          streetType: null,
          house: null,
          houseFiasId: null,
          clientId: null,
          city: selectedCity ? selectedCity.name : null,
          cityId: selectedCity ? selectedCity.cityId : null,
          isDefault: null,
          latitude: null,
          longitude: null,
        },
        restaurant: terminal.restaurant,
      }
      break
  }

  const selectedProducts: SelectedProductDTO[] = yield select(
    (state: State) => state.basket.selectedProducts,
  )
  const priceFull: number = yield select((state: State) => state.basket.priceFull)
  const newProducts: OrderProductDTO[] = []

  for (let selectedProduct of selectedProducts) {
    switch (selectedProduct.product.type) {
      case MenuEntityTypeEnum.PRODUCT:
        {
          newProducts.push(yield call(getOrderProduct, selectedProduct))
        }
        break
      case MenuEntityTypeEnum.COMBO:
        {
          let selectedComboOrderProducts: SelectedOrderComboProductsMap = {}
          for (let key in selectedProduct.selectedProducts) {
            selectedComboOrderProducts[key] = yield call(
              getOrderProduct,
              selectedProduct.selectedProducts[key][0],
            )
          }
          const newProduct: OrderProductDTO = {
            productId: selectedProduct.product.productId,
            count: selectedProduct.count,
            productVariantId: selectedProduct.productVariantId,
            price: selectedProduct.price,
            optionIds: [],
            productName: selectedProduct.product.name,
            selectedComboOrderProducts: selectedComboOrderProducts,
            type: selectedProduct.product.type,
          }

          newProducts.push({...newProduct})
        }
        break
    }
  }

  const commentary: string =
    (prepareOrder.commentary ? prepareOrder.commentary : '') +
    (prepareOrder.commentary && prepareOrder.additionPhone ? '\n' : '') +
    (prepareOrder.additionPhone ? `Дополнительный телефон: ${prepareOrder.additionPhone}` : '')

  const selectedCouponDefinitionId: string = yield select(
    (state: State) => state.action.selectedCouponDefinitionId,
  )

  const coupon: string = yield select((state: State) => state.basket.coupon)

  if (!prepareOrder?.prepareTime?.isQuickly && prepareOrder?.prepareTime?.orderTime) {
    prepareOrder.prepareTime.orderTime = moment(prepareOrder.prepareTime.orderTime).format(
      'YYYY-MM-DDTHH:mm:ssZ',
    ) as any
  }

  const newOrder: OrderDTO = {
    orderId: prepareOrder.orderId ? prepareOrder.orderId : null,
    address: prepareOrder.selectedAddress,
    cityId: prepareOrder.cityId,
    commentary: commentary,
    countPersons: parseFloat(prepareOrder.countPersons),
    customer: prepareOrder.name ? prepareOrder.name : null,
    deliveryType: prepareOrder.deliveryType,
    isCallBack: prepareOrder.isCallBack,
    paymentType: prepareOrder.paymentType,
    phone: prepareOrder.phone,
    priceFull: priceFull,
    products: newProducts,
    prepareTime: prepareOrder.prepareTime,
    additionPhone: prepareOrder.additionPhone,
    restaurantId: prepareOrder.restaurant?.restaurantId,
    couponDefinitionId: selectedCouponDefinitionId,
    personalCoupon: selectedCouponDefinitionId === 'PERSONAL_COUPON' && coupon ? coupon : '',
  }
  switch (saveOrderType) {
    case SaveOrderEnum.FAST:
      newOrder.orderTypeId = OrderTypeEnum.FAST
      break
    case SaveOrderEnum.NORMAL:
      newOrder.orderTypeId = null
      break
    case SaveOrderEnum.TERMINAL:
      newOrder.orderTypeId = OrderTypeEnum.TERMINAL
      break
  }

  return newOrder
}

function* getCheckOrder(personalCoupon?: string) {
  //not use
  const newOrder: OrderDTO = yield call(createNewOrder, SaveOrderEnum.NORMAL)

  if (personalCoupon) {
    newOrder.personalCoupon = personalCoupon
    newOrder.couponDefinitionId = 'PERSONAL_COUPON'
  }

  try {
    const checkedOrder: CheckDiscountResultDTO = yield call(checkOrderService, newOrder)

    return checkedOrder
  } catch (error) {
    error.message = 'Произошла ошибка получения цены.'
    yield put(ErrorActions.setError(error, 'Ошибка получения цены'))
  }
}

function* saveOrder({payload}: any) {
  const setIsRedirect = payload.setIsRedirect
  const isQuickOrder: boolean = !!payload.phoneNumber
  let newOrder: OrderDTO = yield call(
    createNewOrder,
    isQuickOrder ? SaveOrderEnum.FAST : SaveOrderEnum.NORMAL,
    payload.phoneNumber,
  )
  try {
    const order: OrderDTO = yield call(saveOrderService, newOrder, isQuickOrder)
    yield delay(1000)
    yield put(OrderActions.setPrepareOrderField('orderId', order.orderId))
    yield put(OrderActions.setPrepareOrderField('externalOrderId', order.externalOrderId))
    const prepareOrder: PrepareOrder = yield select((state: State) => state.order.prepareOrder)
    yield call(LSMethods.setStorageData, LSKeys.PREPARE_ORDER, prepareOrder)
    yield put(OrderActions.setPriceResult(order.priceResult))

    if (order.paymentType === PaymentTypeEnum.ONLINE) {
      yield put(OrderActions.setPrepareOrderField('orderId', order.orderId))
      yield call(createPaymentOnlineCard)
    } else {
      yield call(saveOrderSuccess, order)
    }

    if (order) {
      yield call(ymTarget, 'OPLATA') //: Заказ создан
      yield call(gaTarget, 'click-made-order', 'order') //: Заказ оформлен
      yield call(purchaseEcommerceTarget, order) //: Покупка через ecommerce
    }

    setIsRedirect()
  } catch (error) {
    console.log(error)
    // if (error.status === 601) {
    //   yield put(ErrorActions.setError(error, 'Ошибка сохранения заказа'))
    // } else {
    yield put(ErrorActions.setError(error, 'Ошибка сохранения заказа'))
    // }
    yield put(OrderActions.toggleIsRequestSaveOrder(false))
  }
}

function* saveOrderTerminal({payload}: any) {
  yield put(OrderActions.toggleIsRequestSaveOrderTerminal(true))
  const callBack: () => void = payload.callBack

  let newOrder: OrderDTO = yield call(createNewOrder, SaveOrderEnum.TERMINAL)
  try {
    yield delay(2000)
    const order: OrderDTO = yield call(saveOrderService, newOrder)
    yield put(OrderActions.toggleIsRequestSaveOrderTerminal(false))
    yield put(TerminalActions.setIsCheckForInactivity(false))
    yield put(PaymentModalActions.setCurrentStage(PaymentModalStageEnum.WAITING_PAYMENT))
    yield put(PaymentModalActions.setIsOpen(true))
    yield delay(1000)
    // console.log(`orderId=${order.orderId}`)
    // console.log(`paymentTerminalId=${order.paymentTerminalId}`)
    // console.log(`terminalId=${order.terminalId}`)
    yield put(OrderActions.setOrderInfo(order))
    yield put(OrderActions.setPrepareOrderField('orderId', order.orderId))
    yield put(OrderActions.setPrepareOrderField('externalOrderId', order.externalOrderId))
    const prepareOrder: PrepareOrder = yield select((state: State) => state.order.prepareOrder)
    yield call(LSMethods.setStorageData, LSKeys.PREPARE_ORDER, prepareOrder)
    yield put(OrderActions.setPriceResult(order.priceResult))

    callBack()
  } catch (error) {
    yield put(OrderActions.toggleIsRequestSaveOrderTerminal(false))
    yield put(ErrorActions.setError(error, 'Ошибка сохранения заказа на терминале'))
  }
}

//TODO:: IN 2024-03-05
function* payOrderForPayment({payload}: any) {
  yield put(OrderActions.toggleIsRequestPayOrderForPayment(true))
  const {order} = payload
  const selectedCity: CityDTO = yield select((state: State) => state.city.selectedCity)
  console.log(order)
  console.log(order)
  try {
    yield delay(1500)
    yield call(payOrderForPaymentService, order.orderId, selectedCity.cityId)
    yield put(OrderActions.setOrderInfo(order))
    yield put(OrderActions.toggleIsRequestPayOrderForPayment(false))
    yield put(TerminalActions.setIsCheckForInactivity(false))
    yield put(PaymentModalActions.setCurrentStage(PaymentModalStageEnum.WAITING_PAYMENT))
    yield put(PaymentModalActions.setIsOpen(true))
    yield delay(1000)
  } catch (error) {
    yield put(OrderActions.toggleIsRequestPayOrderForPayment(false))
    yield put(ErrorActions.setError(error, 'Ошибка оплаты заказа(заказ для оплаты) на терминале'))
  }
}

// function* cancelPayOrderForPayment({payload}: any) {//TODO:: UPDATE
//   yield put(OrderActions.toggleIsRequestPayOrderForPayment(false))
//   yield put(OrderActions.setOrderInfo(undefined))
//   yield put(TerminalActions.setIsCheckForInactivity(true))
//   yield put(PaymentModalActions.setCurrentStage(undefined))
//   yield put(PaymentModalActions.setIsOpen(false))
//   yield call(clearBasket, {payload: true})
// }

function* purchaseEcommerceTarget(order: OrderDTO) {
  let products: EcommerceProductType[] = []
  for (let product of order.products || []) {
    products.push({
      id: product.productId,
      name: product.productName,
      price: product.price,
      quantity: product.count,
    })
  }
  const actionField: EcommerceActionFieldType = {
    id: order.orderId,
    goal_id: 46701040,
  }
  ecommerceTarget(ECOMMERCE_ACTION_TYPE.PURCHASE, products, actionField)
}

function* saveOrderSuccess(order: OrderDTO) {
  yield call(clearBasket, {payload: true})
  // LSMethods.setStorageData(LSKeys.IS_OPEN_QUICK_ORDER_MODAL, true)
  yield put(OrderActions.toggleIsRequestSaveOrder(false))
}

function* deletePrepareOrder() {
  yield put(OrderActions.clearPrepareOrder())
  yield call(LSMethods.setStorageData, LSKeys.PREPARE_ORDER, null)
  yield call(initializeOrder, false)
}

function* getCreatedOrders({payload}: any) {
  const client: ClientDTO[] = yield call(LSMethods.getStorageData, LSKeys.CLIENT, null)
  if (client) {
    if (payload) {
      const isRequestUpdate: boolean = payload?.isRequestUpdate
      const isRequestGet: boolean = payload?.isRequestGet
      yield put(OrderActions.toggleIsRequestGetCreatedOrders(isRequestGet))
      yield put(OrderActions.toggleIsRequestUpdateCreatedOrders(isRequestUpdate))
    }

    try {
      const city: CityDTO = yield select((state: State) => state.city.selectedCity)
      const createdOrders: OrderDTO[] = yield call(getCreatedOrdersService, city?.cityId)
      yield call(setCreatedOrders, createdOrders)
    } catch (error) {
      yield put(ErrorActions.setError(error))
      const createdOrder: OrderDTO[] = yield call(
        LSMethods.getStorageData,
        LSKeys.CREATED_ORDERS,
        [],
      )
      yield put(OrderActions.setCreatedOrders(createdOrder))
      yield put(OrderActions.toggleIsRequestGetCheckedOrder(false))
      yield put(OrderActions.toggleIsRequestUpdateCreatedOrders(false))
    }
  }
}

function* setCreatedOrders(createdOrders: OrderDTO[]) {
  yield put(OrderActions.setCreatedOrders(createdOrders))
  yield call(LSMethods.setStorageData, LSKeys.CREATED_ORDERS, createdOrders)
}

function* setSelectedOrder({payload}: any) {
  const selectedOrder: OrderDTO = payload.selectedOrder
  yield put(OrderActions.setSelectedOrder(selectedOrder))
}

function* changeSelectedOrder({payload}: any) {
  const order: OrderDTO = payload

  const selectedOrder: OrderDTO = yield select((state: State) => state.order.selectedOrder)

  if (selectedOrder && selectedOrder.orderId === order.orderId) {
    yield put(OrderActions.setSelectedOrder(order))
  }

  const city: CityDTO = yield select((state: State) => state.city.selectedCity)
  const createdOrders: OrderDTO[] = yield call(getCreatedOrdersService, city?.cityId)
  yield call(setCreatedOrders, createdOrders)

  yield call(setLastOrderStatus, order.status)
}

function* getOrder(orderId: string) {
  try {
    const city: CityDTO = yield select((state: State) => state.city.selectedCity)
    return yield call(getOrderService, orderId, city?.cityId)
  } catch (e) {
    yield put(ErrorActions.setError(e))
    console.log('Error getOrder', e)
    throw e
  }
}

function* setLastOrderStatus(lastOrderStatus: OrderStatusEnum) {
  yield put(OrderActions.setLastOrderStatus(lastOrderStatus))
  yield call(LSMethods.setStorageData, LSKeys.LAST_ORDER_STATUS, lastOrderStatus)
}

function* clearLastOrderStatus() {
  yield call(setLastOrderStatus, null)
}

function* repeatOrder({payload}: any) {
  try {
    const city: CityDTO = yield select((state: State) => state.city.selectedCity)
    const order: OrderDTO = yield call(getOrderService, payload.orderId, city?.cityId)
    const productMap: ProductMap = yield select((state: State) => state.menu.productMap)
    const oldSelectedProducts: SelectedProductDTO[] = yield select(
      (state: State) => state.basket.selectedProducts,
    )
    const products: OrderProductDTO[] = order.products

    let selectedProducts: SelectedProductDTO[] = []
    for (let i = 0; i < products.length; i++) {
      let newSelectedProducts: SelectedProductDTO

      if (products[i].type === MenuEntityTypeEnum.COMBO) {
        //получить продукт тип COMBO
        newSelectedProducts = yield call(getSelectedComboProduct, products[i], productMap)
      } else {
        //получить продукт тип PRODUCT
        newSelectedProducts = yield call(getSelectedProduct, products[i], productMap)
      }

      selectedProducts.push(newSelectedProducts)
    }

    //отправить в корзину
    yield call(deletePrepareOrder)
    yield call(ymTarget, 'DOBAVILI_V_KORZINU')
    yield call(addEcommerceProduct, [...selectedProducts])
    yield call(setSelectedProducts, [...oldSelectedProducts, ...selectedProducts])
    yield call(toggleProductAddPopover, 'Товары добавлены в корзину')
  } catch (error) {
    yield put(ErrorActions.setError(error))
    console.log('Error repeatOrder', error)
  }
}

export {
  initializeOrder,
  initializeOrderAuthFields,
  initializeOrderAddressFields,
  initializeOrderRestaurant,
  initializeCreatedOrders,
  setOrderAddressFields,
  getCheckOrder,
  setPrepareOrderPrepareTime,
  setPrepareOrderSelectedAddress,
  updateDeliveryPrice,
  saveOrder,
  saveOrderTerminal,
  setSelectedOrder,
  getCreatedOrders,
  saveOrderSuccess,
  getOrder,
  deletePrepareOrder,
  setPrepareOrderRestaurant,
  setPrepareOrderDeliveryType,
  setCreatedOrders,
  changeSelectedOrder,
  clearLastOrderStatus,
  repeatOrder,
  createNewOrder,
  changeAutoChoiceRestaurant,
  payOrderForPayment,
  // cancelPayOrderForPayment,//TODO:: UPDATE
}
