import React, {useMemo} from 'react'
import {connectComponent} from '../../../../../../../store/common'
import ProductListPage from '../ProductListPage'
import ProductPage from '../productPage/ProductPage'
import {State} from '../../../../../../../store/ducks'
import {MenuCategoryDTO} from 'grilnica-store-share'
import {InitializeState} from '../../../../../../../store/ducks/initialize'

interface ProductLayoutProps {
  childAlias: string
  initialize: InitializeState
  categoryList: MenuCategoryDTO[]
}

const ProductLayout: React.FC<ProductLayoutProps> = ({
  childAlias,
  initialize,
  categoryList,
}): React.ReactElement => {
  const renderContent: React.ReactElement = useMemo(() => {
    let isProductAlias: boolean = true
    const newCategoryList: MenuCategoryDTO[] = initialize.newCategoryList
      ? initialize.newCategoryList
      : categoryList
    for (let newCategory of newCategoryList) {
      if (newCategory.alias === childAlias && newCategory.parentMenuCategoryId) {
        isProductAlias = false
      }
    }

    if (isProductAlias) {
      return <ProductPage />
    } else {
      return <ProductListPage />
    }
  }, [initialize.newCategoryList, categoryList, childAlias])
  return <>{renderContent}</>
}

export default connectComponent(
  (state: State, props: any) => ({
    alias: props.match.params.alias,
    childAlias: props.match.params.childAlias,
    productMap: state.menu.productMap,
    categoryList: state.menu.categoryList,
    selectedCity: state.city.selectedCity,
    initialize: state.initialize,
  }),
  () => ({}),
  ProductLayout,
)
