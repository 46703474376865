import React, {useMemo} from 'react'
import {DialogModal} from '../../../../components/modal/DialogModal'

interface BasketModalProps {
  isOpenModal: boolean
  onCloseModal: () => void
  bodyText: string
  onClick: () => void
}

const BasketModal: React.FC<BasketModalProps> = ({
  isOpenModal,
  onCloseModal,
  bodyText,
  onClick,
}): React.ReactElement => {
  const renderContent: React.ReactElement = useMemo(() => {
    return (
      <DialogModal
        isOpen={isOpenModal}
        bodyText={bodyText}
        onClose={onCloseModal}
        onClick={onClick}
      />
    )
  }, [isOpenModal, bodyText, onClick, onCloseModal])
  return <>{renderContent}</>
}

export {BasketModal}
