import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {Container, Row} from 'grilnica-reactstrap'
import {State} from '../../../../store/ducks'
import {
  ClientDTO,
  MenuCategoryDTO,
  PrepareOrder,
  SelectedProductDTO,
  WeightMapDto,
} from 'grilnica-store-share'
import {BasketActionsType, bindBasketActions} from '../../../../store/ducks/basket'
import {BasketModal} from './components/BasketModal'
import {BasketContent} from './components/BasketContent'
import {Redirect} from 'react-router-dom'
import {connectComponent} from '../../../../store/common'
import {bindAuthActions} from '../../../../store/ducks/auth'
import {BasketAlertBlock} from './components/BasketAlertBlock'
import ActionBlock from './components/ActionBlock'
import {getUrl} from '../../utils/search/getUrl'
import {ymTarget} from '../../../../utils/metrica/ym/ymTarget'
import {gaTarget} from '../../../../utils/metrica/ga/gaTarget'
import RecommendationsBlockContainer from './components/recommendations/RecommendationsBlockContainer'
import {BasketButtons} from './components/BasketButtons'

interface BasketPageProps {
  selectedProducts: SelectedProductDTO[]
  priceFull: number
  client: ClientDTO
  basketActions: BasketActionsType
  authActions: any
  categoryList: MenuCategoryDTO[]

  selectedCouponDefinitionId: string

  isUpdateCheckActions: boolean
  isOpenCouponModal: boolean
  saleFull: number
  prepareOrder: PrepareOrder
  weightMap: WeightMapDto
}

const BasketPage: React.FC<BasketPageProps> = ({
  selectedProducts,
  priceFull,
  client,
  basketActions,
  categoryList,
  authActions,
  saleFull,
  isUpdateCheckActions,
  selectedCouponDefinitionId,
  prepareOrder,
  weightMap,
}): React.ReactElement => {
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false)
  const [indexProduct, setIndexProduct] = useState<number>(null)
  const [redirectUrl, setRedirectUrl] = useState<string>(null)

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    ymTarget('OTKRILI_KORZINU')
    gaTarget('click-open-basket', 'basket')
  }, [])

  const onCloseModal = useCallback(() => {
    setIndexProduct(null)
    setIsOpenModal(false)
  }, [])

  const onOpenModal = useCallback((index: number) => {
    setIndexProduct(index)
    setIsOpenModal(true)
  }, [])

  const deleteProductFromBasket = useCallback(
    (index: number) => {
      basketActions.deleteProductFromBasketRequest(index, true)
    },
    [basketActions],
  )

  const clearBasket = useCallback(() => {
    basketActions.clearBasketRequest()
  }, [basketActions])

  const editSelectedProduct = useCallback(
    (selectedProduct: SelectedProductDTO, index: number) => {
      basketActions.setEditProductIndex(index)
      setRedirectUrl(
        getUrl(selectedProduct.product, categoryList) +
          '/' +
          selectedProduct.product.alias +
          '/edit/' +
          index,
      )
    },
    [basketActions, categoryList],
  )

  const removeCountProduct = useCallback(
    (index: number) => {
      if (selectedProducts[index].count > 1) {
        basketActions.changeCountSelectedProductInBasketRequest(
          index,
          selectedProducts[index].count - 1,
          true,
        )
      } else {
        {
          onOpenModal(index)
        }
      }
    },
    [basketActions, onOpenModal, selectedProducts],
  )

  const addCountProduct = useCallback(
    (index: number) => {
      if (selectedProducts[index].count < 100) {
        basketActions.changeCountSelectedProductInBasketRequest(
          index,
          selectedProducts[index].count + 1,
          true,
        )
      }
    },
    [basketActions, selectedProducts],
  )

  const toPlaceOrder = useCallback(() => {
    if (client) {
      setRedirectUrl('/place-order')
    } else {
      authActions.setRedirectUrl('/place-order')
      setRedirectUrl('/login')
    }
  }, [authActions, client])

  const renderContent: React.ReactElement = useMemo(() => {
    return (
      <Container size={'normal'} className={'mt-x10 mb-x10'}>
        <section>
          <h1 className={'text-center mb-x6 h1'}>Корзина</h1>
          <Row>
            <BasketAlertBlock isTerminal={false} />
            {selectedProducts?.length > 0 && prepareOrder?.cityId ? <ActionBlock /> : null}
            <BasketContent
              isTerminal={false}
              addCountProduct={(index: number) => {
                addCountProduct(index)
              }}
              onOpenModal={(index: number) => onOpenModal(index)}
              removeCountProduct={(index: number) => removeCountProduct(index)}
              editSelectedProduct={editSelectedProduct}
              selectedProducts={selectedProducts}
              selectedCouponDefinitionId={selectedCouponDefinitionId}
              isUpdateCheckActions={isUpdateCheckActions}
              weightMap={weightMap}
            />
            {selectedProducts?.length > 0 && <RecommendationsBlockContainer />}
            <BasketButtons
              isTerminal={false}
              selectedProducts={selectedProducts}
              priceFull={priceFull}
              saleFull={saleFull > 0 ? saleFull : null}
              toPlaceOrder={toPlaceOrder}
              isUpdateCheckActions={isUpdateCheckActions}
              clearBasket={() => {
                onOpenModal(null)
              }}
            />
          </Row>
          <BasketModal
            isOpenModal={isOpenModal}
            bodyText={
              indexProduct !== null
                ? 'Вы действительно хотите удалить элемент?'
                : 'Вы действительно хотите очистить корзину?'
            }
            onCloseModal={onCloseModal}
            onClick={() => {
              if (indexProduct !== null) {
                deleteProductFromBasket(indexProduct)
                onCloseModal()
              } else {
                clearBasket()
                onCloseModal()
              }
            }}
          />
        </section>
        {redirectUrl && <Redirect push to={redirectUrl} />}
      </Container>
    )
  }, [
    selectedProducts,
    prepareOrder?.cityId,
    editSelectedProduct,
    priceFull,
    saleFull,
    toPlaceOrder,
    selectedCouponDefinitionId,
    isUpdateCheckActions,
    isOpenModal,
    indexProduct,
    onCloseModal,
    redirectUrl,
    addCountProduct,
    onOpenModal,
    removeCountProduct,
    deleteProductFromBasket,
    clearBasket,
    weightMap,
  ])
  return <>{renderContent}</>
}

export default connectComponent(
  (state: State) => ({
    selectedProducts: state.basket.selectedProducts,
    categoryList: state.menu.categoryList,
    priceFull: state.basket.priceFull,
    saleFull: state.basket.saleFull,
    isUpdateCheckActions: state.basket.isUpdateCheckActions,
    client: state.auth.client,
    selectedCouponDefinitionId: state.action.selectedCouponDefinitionId,
    prepareOrder: state.order.prepareOrder,
    weightMap: state.menu.weightMap,
  }),
  (dispatch) => ({
    basketActions: bindBasketActions(dispatch),
    authActions: bindAuthActions(dispatch),
  }),
  BasketPage,
)
