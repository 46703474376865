import React from 'react'
import {Route, Switch} from 'react-router-dom'
import MenuPage from '../MenuPage'
import ProductListPage from '../components/product/ProductListPage'
import ProductPage from '../components/product/productPage/ProductPage'
import ProductLayout from '../components/product/layout/ProductLayout'
import {NotFoundPage} from '../../../../components/common/NotFoundPage'

interface MenuLayoutProps {}

const MenuLayout: React.FC<MenuLayoutProps> = ({}): React.ReactElement => {
  return (
    <div className={'d-flex flex-column flex-grow-1'}>
      <Switch>
        <Route exact path={'/'} component={MenuPage} />
        <Route
          exact
          path={'/menu/:alias/:childAlias/:productAlias/edit/:index'}
          component={ProductPage}
        />
        <Route exact path={'/menu/:alias/:childAlias/:productAlias'} component={ProductPage} />
        <Route path={'/menu/:alias/:productAlias/edit/:index'} component={ProductPage} />
        <Route exact path={'/menu/:alias/:childAlias'} component={ProductLayout} />
        <Route exact path={'/menu/:alias'} component={ProductListPage} />
        <Route path={'/*'} component={NotFoundPage} />
      </Switch>
    </div>
  )
}

export {MenuLayout}
