import React from 'react'
import {BrowserRouter, StaticRouter} from 'react-router-dom'
import {SubsystemInfo} from 'grilnica-share-client'
import PageBody from '../../components/body/PageBody'
import PageFooter from '../../components/footer/PageFooter'
import ErrorModal from '../../components/error/ErrorModal'
import InitializeApp from '../containers/initialize/InitializeApp'
import Footer from '../../components/footer/components/Footer'
import {CookieMessage} from '../../components/cookie/CookieMessage'
import ToastComponent from '../../components/toast/ToastComponent'
import {isServer} from '../../../store'
import ReduxApp from '../../../store/ReduxApp'
import {BannerApps} from '../../components/BannerApps'
import {HeaderMenu} from '../../components/header/components/HeaderMenu'
import ProductWeekOrDayContainer from './modal/productWeekOrDay/ProductWeekOrDayContainer'
import {SiteTestBanner} from '../../components/header/components/SiteTestBanner'
import {useIsClient} from '../../hooks/useIsClient'

interface AppProps {
  store: any
  subsystem: SubsystemInfo
  urlPrefix: string
  locationSSR?: string
  context?: any
  isQrApp: boolean
  isTestSite: boolean
}

export const UrlPrefixContext = React.createContext('')

const App: React.FC<AppProps> = ({
  store,
  urlPrefix,
  children,
  locationSSR,
  context,
  isQrApp,
  isTestSite,
}): React.ReactElement => {
  const [isClient] = useIsClient()

  const renderApp = () => {
    let renderContent: React.ReactElement
    if (isQrApp) {
      renderContent = (
        <React.Fragment>
          <PageBody>{children}</PageBody>
          <PageFooter>
            <Footer isMinimalFooter />
          </PageFooter>
        </React.Fragment>
      )
    } else {
      renderContent = (
        <React.Fragment>
          {isTestSite && <SiteTestBanner />}
          <HeaderMenu />
          {isClient && <ErrorModal />}
          <PageBody>{children}</PageBody>
          <ProductWeekOrDayContainer />
          {isClient && <CookieMessage />}
          <BannerApps />
          <ToastComponent />
          <PageFooter>
            <Footer />
          </PageFooter>
        </React.Fragment>
      )
    }
    return (
      <InitializeApp>
        <UrlPrefixContext.Provider value={urlPrefix}>
          <div>{renderContent}</div>
        </UrlPrefixContext.Provider>
      </InitializeApp>
    )
  }

  if (isServer) {
    return (
      <ReduxApp store={store}>
        <StaticRouter basename={urlPrefix} location={locationSSR} context={context}>
          {renderApp()}
        </StaticRouter>
      </ReduxApp>
    )
  } else {
    return (
      <ReduxApp store={store}>
        <BrowserRouter basename={urlPrefix}>{renderApp()}</BrowserRouter>
      </ReduxApp>
    )
  }
}

export default App
