import React, {useMemo} from 'react'
import {RestaurantByProductDTO} from 'grilnica-store-share'

interface ModalProductRestaurantContentProps {
  restaurants: RestaurantByProductDTO[]
}

const ModalProductRestaurantContent: React.FC<ModalProductRestaurantContentProps> = ({
  restaurants,
}): React.ReactElement => {
  const renderContent: React.ReactElement = useMemo(() => {
    return (
      <div className={'product-restaurant-modal'}>
        <h4 className={'header'}>Доступно по адресу:</h4>
        <div className={'product-restaurant-list'}>
          {restaurants.map((restaurant) => {
            return (
              <div
                className={'product-restaurant-item d-flex flex-column'}
                key={restaurant.restaurantId}>
                <span className={restaurant.timeFrom && restaurant.timeTo ? 'address ' : ''}>
                  {restaurant.address}
                </span>
                <span className={'time mt-x1'}>
                  {' ' +
                    (restaurant.timeIntervalsString
                      ? restaurant.timeIntervalsString
                      : 'Только для доставки')}
                </span>
              </div>
            )
          })}
        </div>
      </div>
    )
  }, [restaurants])
  return <>{renderContent}</>
}

export {ModalProductRestaurantContent}
