enum KEYS {
  APP_STORAGE_KEY = '@GRILNICA_SITE',

  AUTHORIZATION_CLIENT_TOKEN = 'AUTHORIZATION_CLIENT_TOKEN',

  CITY_LIST = 'CITY_LIST',
  SELECTED_CITY = 'SELECTED_CITY',

  SELECTED_CATALOG_ITEMS_TYPE = 'SELECTED_CATALOG_ITEMS_TYPE',
  CATEGORY_LIST = 'CATEGORY_LIST',
  PRODUCT_MAP = 'PRODUCT_MAP',
  WEIGHT_MAP = 'WEIGHT_MAP',

  CLIENT = 'CLIENT',

  SELECTED_PRODUCTS = 'SELECTED_PRODUCTS',

  CLIENT_ADDRESSES = 'CLIENT_ADDRESSES',

  RESTAURANTS = 'RESTAURANTS',
  FAVOURITES_RESTAURANTS = 'FAVOURITES_RESTAURANTS',
  RESTAURANT_PAYMENT_TYPE_MAP = 'RESTAURANT_PAYMENT_TYPE_MAP',

  AREA_OF_DELIVERY_LIST = 'AREA_OF_DELIVERY_LIST',

  CREATED_ORDERS = 'CREATED_ORDERS',
  LAST_ORDER_STATUS = 'LAST_ORDER_STATUS',

  PREPARE_ORDER = 'PREPARE_ORDER',

  IS_CONFIRM_COOKIE = 'IS_CONFIRM_COOKIE',

  DATE_LAST_SHOW_BANNER_APP = 'DATE_LAST_SHOW_BANNER_APP',

  ALIAS_ACTION_MAP = 'ALIAS_ACTION_MAP',
  ALIAS_CITY_MAP = 'ALIAS_CITY_MAP',
  ALIAS_RESTAURANT_MAP = 'ALIAS_RESTAURANT_MAP',
  ALIAS_PRODUCT_CATEGORY_MAP = 'ALIAS_PRODUCT_CATEGORY_MAP',
  OBJECT_URL_MAP = 'OBJECT_URL_MAP',

  TIME_CLOSE_SALE_MODAL = 'TIME_CLOSE_SALE_MODAL',

  //terminal
  TERMINAL_ALIAS = 'TERMINAL_ALIAS',

  //для автоматического выбора ресторана при самовывозе
  IS_AUTO_CHOICE_RESTAURANT = 'IS_AUTO_CHOICE_RESTAURANT',

  //contact
  CONTACT_DETAILS_MAP = 'CONTACT_DETAILS_MAP',
}

export {KEYS}
