import React, {useEffect} from 'react'
import {connectComponent} from '../../../../../store/common'
import {bindPaymentActions} from '../../../../../store/ducks/payment'
import {PaymentStatusDto} from 'grilnica-share'
import {Container} from 'grilnica-reactstrap'
import {useHistory} from 'react-router-dom'
import {getStaticUrl} from '../../../../../utils/static'

interface PaymentCheckPageProps {
  paymentStatus: PaymentStatusDto | null
  paymentId: string
  orderId: string
  paymentActions: any
}

const PaymentCheckPage: React.FC<PaymentCheckPageProps> = ({
  paymentStatus,
  paymentId,
  orderId,
  paymentActions,
}) => {
  const history = useHistory()
  useEffect(() => {
    if (paymentId) {
      paymentActions.checkPaymentStatusRequest(paymentId)
    }
  }, [paymentActions, paymentId])

  useEffect(() => {
    if (paymentStatus) {
      if (paymentStatus.isSuccess) {
        history.push(`/payment/payment-success?orderId=${orderId}`)
      } else if (paymentStatus.isFailure) {
        history.push(`/payment/payment-failure?orderId=${orderId}`)
      } else {
        const interval = setInterval(() => {
          paymentActions.checkPaymentStatusRequest(paymentId)
        }, 3000)
        return () => {
          clearInterval(interval)
        }
      }
    }
  }, [orderId, paymentId, paymentStatus, history, paymentActions])

  return (
    <Container size={'normal'} className={'mt-x10 mb-x10'}>
      <div className={'payment'}>
        <h1 className={'h1 mb-x6'}>Проверка платежа</h1>
        <img
          src={getStaticUrl('/img/payment-success.svg')}
          alt={'Оплата успешна'}
          title={'Оплата успешна'}
          width={'100%'}
          height={'auto'}
          className={'mb-x6'}
        />
        <p className={'descriptions'}>Пожалуйста, подождите. Платеж в обработке...</p>
      </div>
    </Container>
  )
}

export default connectComponent(
  (state, props) => ({
    orderId: new URLSearchParams(props.location.search).get('orderId'),
    paymentId: new URLSearchParams(props.location.search).get('paymentId'),
    paymentStatus: state.payment.paymentStatus,
  }),
  (dispatch) => ({
    paymentActions: bindPaymentActions(dispatch),
  }),
  PaymentCheckPage,
)
