import React from 'react'
import {COLORS} from 'grilnica-reactstrap'

interface OrderDeliverySVGProps {
  isActive: boolean
  size?: 's' | 'm'
  className?: string
}

const OrderDeliverySVG: React.FC<OrderDeliverySVGProps> = ({
  isActive,
  className,
  size,
}): React.ReactElement => {
  return (
    <svg
      className={className}
      width={size === 's' ? '24' : '48'}
      height={size === 's' ? '24' : '48'}
      viewBox="0 0 48 48"
      fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M28.4674 12C28.4674 11.4477 28.9151 11 29.4674 11H33.0007C35.2099 11 37.0007 12.7909 37.0007 15V20C37.0007 20.2156 36.931 20.4255 36.802 20.5983L29.3353 30.5983C29.1466 30.8511 28.8496 31 28.5341 31H20.0007V31.3327C20.0007 34.0941 17.7622 36.3327 15.0007 36.3327C12.2393 36.3327 10.0007 34.0941 10.0007 31.3327V31H8.00073C7.44845 31 7.00073 30.5523 7.00073 30V26C7.00073 22.134 10.1347 19 14.0007 19H21.0674C21.6197 19 22.0674 19.4477 22.0674 20V29H28.0327L35.0007 19.6679V15C35.0007 13.8954 34.1053 13 33.0007 13H29.4674C28.9151 13 28.4674 12.5523 28.4674 12ZM20.0674 29V21H14.0007C11.2393 21 9.00073 23.2386 9.00073 26V29H20.0674ZM12.0007 31V31.3327C12.0007 32.9896 13.3439 34.3327 15.0007 34.3327C16.6576 34.3327 18.0007 32.9896 18.0007 31.3327V31H12.0007ZM13.0007 16C13.0007 15.4477 13.4484 15 14.0007 15H20.0007C20.553 15 21.0007 15.4477 21.0007 16C21.0007 16.5523 20.553 17 20.0007 17H14.0007C13.4484 17 13.0007 16.5523 13.0007 16ZM32.0007 31C32.0007 28.2386 34.2393 26 37.0007 26C39.7622 26 42.0007 28.2386 42.0007 31C42.0007 33.7614 39.7622 36 37.0007 36C34.2393 36 32.0007 33.7614 32.0007 31ZM37.0007 28C35.3439 28 34.0007 29.3431 34.0007 31C34.0007 32.6569 35.3439 34 37.0007 34C38.6576 34 40.0007 32.6569 40.0007 31C40.0007 29.3431 38.6576 28 37.0007 28Z"
        fill={isActive ? COLORS.purple : COLORS.gray500}
      />
    </svg>
  )
}

export {OrderDeliverySVG}
