import {combineReducers} from 'redux'
import city, {CityState} from './city'
import auth, {AuthState} from './auth'
import error, {ErrorState} from './error'
import common, {CommonState} from './common'
import initialize, {InitializeState} from './initialize'
import menu, {MenuState} from './menu'
import basket, {BasketState} from './basket'
import product, {ProductState} from './product'
import order, {OrderState} from './order'
import address, {AddressState} from './address'
import restaurant, {RestaurantState} from './restaurant'
import payment, {PaymentState} from './payment'
import restaurantProduct, {RestaurantProductState} from './restaurantProduct'
import {ActionState} from '../action/types'
import {action} from '../action/duck'
import {TerminalState} from '../terminal/types'
import {terminal} from '../terminal/duck'
import {socket} from '../socket/duck'
import {SocketState} from '../socket/types'
import {paymentModal} from '../paymentModal/duck'
import {recommendationsProduct} from '../recommendationsProduct/duck'
import {RecommendationsProductState} from '../recommendationsProduct/types/RecommendationsProductState'
import {ContactDetailsState} from '../contactDetails/types/ContactDetailsState'
import {contactDetails} from '../contactDetails/duck'
import {PaymentModalState} from '../paymentModal/types/PaymentModalState'

export interface State {
  city?: CityState
  auth?: AuthState
  address?: AddressState
  action?: ActionState
  restaurant?: RestaurantState
  error?: ErrorState
  menu?: MenuState
  product?: ProductState
  basket?: BasketState
  order?: OrderState
  common?: CommonState
  initialize?: InitializeState
  payment?: PaymentState
  restaurantProduct?: RestaurantProductState
  terminal?: TerminalState
  socket?: SocketState
  paymentModal?: PaymentModalState
  recommendationsProduct?: RecommendationsProductState
  contactDetails?: ContactDetailsState
}

const rootReducer = combineReducers({
  city,
  auth,
  address,
  action,
  restaurant,
  error,
  menu,
  product,
  basket,
  order,
  common,
  initialize,
  payment,
  restaurantProduct,
  terminal,
  socket,
  paymentModal,
  recommendationsProduct,
  contactDetails,
})

export default rootReducer
