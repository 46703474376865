import React from 'react'
import {ButtonLink, Col, Slider} from 'grilnica-reactstrap'
import {RestaurantDTO} from 'grilnica-share'
import {RestaurantInformation} from './RestaurantInformation'
import {Link} from 'react-router-dom'
import {LazyImage} from '../../../../components/lazyImage/LazyImage'
import {CONSTANTS} from '../../../../../../constants'
import {getStaticUrl} from '../../../../../utils/static'
import {SliderItem} from 'grilnica-reactstrap/src/components/slider/Slider'

interface RestaurantCardProps {
  restaurant: RestaurantDTO
}

const RestaurantCard: React.FC<RestaurantCardProps> = ({restaurant}): React.ReactElement => {
  const renderImg = (): React.ReactElement => {
    if (restaurant.restaurantImages && restaurant.restaurantImages?.length > 1) {
      const sliderItems: SliderItem[] = restaurant.restaurantImages.map((r) => {
        return {
          altText: 'Изображение ресторана',
          caption: '',
          link: '/rest/' + restaurant.alias,
          src: CONSTANTS.cdnUrl + '/' + r.imageFileName,
        }
      })
      return (
        <Slider
          className={'w-100'}
          id={'slider6' + restaurant.restaurantId}
          items={sliderItems}
          intervalMs={restaurant.cardSliderInterval * 1000}
          isHoverPlay
        />
      )
    } else {
      return (
        <Link to={'/rest/' + restaurant.alias}>
          <LazyImage
            src={
              restaurant.restaurantImages && restaurant.restaurantImages[0]
                ? CONSTANTS.cdnUrl + '/' + restaurant.restaurantImages[0].imageFileName
                : getStaticUrl(`/img/placeholder-restaurant.svg`)
            }
            typePlaceholder={'restaurant'}
            alt={restaurant.name}
            title={restaurant.name}
          />
        </Link>
      )
    }
  }

  return (
    <Col xl={3} md={4} sm={6} xs={12} className={'mb-x6'}>
      <div className={'restaurant-card overflow-hidden'}>
        <div className={'card-image'}>
          {renderImg()}
          <div className={'p-x4 pb-0'}>
            <p className={'restaurant-address mb-x4'}>{restaurant.name}</p>
            <RestaurantInformation restaurant={restaurant} />
          </div>
        </div>

        <div className={'d-flex align-items-center justify-content-end p-x4 pt-0'}>
          <ButtonLink
            to={'/rest/' + restaurant.alias}
            size={'sm'}
            isRoundedButton
            color={'primary'}>
            Подробнее
          </ButtonLink>
        </div>
      </div>
    </Col>
  )
}

export {RestaurantCard}
