import {CONSTANTS} from '../../../constants'
import {apiGet, apiPost} from '../../api'
import {OrderDTO} from 'grilnica-store-share/lib/order/OrderDTO'
import {AddMinutesOrderType} from 'grilnica-store-share'
import {payOrderForPayment} from '../../store/sagas/order/order'

async function checkOrderService(newOrder: OrderDTO) {
  return await apiPost(CONSTANTS.apiStoreUrl + '/order/check', newOrder, {
    isKey: true,
    timeout: 600000,
    cityId: newOrder.cityId,
  })
}

async function saveOrderService(newOrder: OrderDTO, isQuickOrder?: boolean) {
  return await apiPost(
    CONSTANTS.apiStoreUrl + '/order/' + (isQuickOrder ? isQuickOrder : ''),
    newOrder,
    {
      timeout: 600000,
      cityId: newOrder.cityId,
    },
  )
}

async function payOrderForPaymentService(orderId: string, cityId: string) {
  return await apiPost(
    CONSTANTS.apiStoreUrl + '/order/terminal/start-payment/' + orderId,
    undefined,
    {
      timeout: 600000,
      cityId: cityId,
    },
  )
}

async function getCreatedOrdersService(cityId: string) {
  return await apiGet(CONSTANTS.apiStoreUrl + '/order/find-by-client', {
    cityId: cityId,
  })
}

async function getClientOrdersForPaymentService(cityId: string) {
  return await apiGet(CONSTANTS.apiStoreUrl + '/order/find-for-payment', {
    cityId: cityId,
  })
}

async function getOrderService(orderId: string, cityId: string) {
  return await apiGet(CONSTANTS.apiStoreUrl + '/order/find-by-id/' + orderId, {
    cityId: cityId,
  })
}

async function getAddMinutesOrderService(cityId: string): Promise<AddMinutesOrderType> {
  return await apiGet(CONSTANTS.apiStoreUrl + '/order/add-minutes', {
    cityId: cityId,
  })
}

export {
  getCreatedOrdersService,
  checkOrderService,
  saveOrderService,
  getOrderService,
  getAddMinutesOrderService,
  getClientOrdersForPaymentService,
  payOrderForPaymentService,
}
