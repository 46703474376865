import {MenuCategoryDTO} from 'grilnica-store-share'
import {getMenuCategoryFromPreview} from '../index'

/**
 * Получить ссылку для продукты превью
 * @param productAlias
 * @param currentMenuCategoryId
 * @param menuCategoryList
 */
export function getProductLink(
  productAlias: string,
  currentMenuCategoryId: string,
  menuCategoryList: MenuCategoryDTO[],
): string {
  menuCategoryList.push(getMenuCategoryFromPreview())

  let menuCategory: MenuCategoryDTO = null
  let parentMenuCategory: MenuCategoryDTO = null
  for (let itemM of menuCategoryList) {
    if (itemM.menuCategoryId === currentMenuCategoryId) {
      menuCategory = itemM
      break
    }
  }
  if (menuCategory?.parentMenuCategoryId) {
    for (let itemM of menuCategoryList) {
      if (itemM.menuCategoryId === menuCategory.parentMenuCategoryId) {
        parentMenuCategory = itemM
        break
      }
    }
  }

  let link: string = '/menu/'

  if (parentMenuCategory) {
    link += parentMenuCategory.alias + '/'
  }

  link += menuCategory.alias + '/' + productAlias

  return link
}
