import React, {useMemo} from 'react'
import {ButtonIcon, Divider, Loading} from 'grilnica-reactstrap'
import {MenuEntityTypeEnum, SelectedProductDTO, WeightMapDto} from 'grilnica-store-share'
import {CONSTANTS} from '../../../../../../constants'
import {deleteSVG, pencilSVG} from '../../../../components/svg/SystemIcons'
import {ProductCountButtons} from './ProductCountButtons'
import {round} from 'grilnica-share'
import {getWeightWithOptions} from '../../../../../utils/menu/getWeight'

interface BasketItemProps {
  selectedProduct: SelectedProductDTO
  deleteProduct: () => void
  addCountProduct: () => void
  removeCountProduct: () => void
  editSelectedProduct: () => void
  selectedCouponDefinitionId: string
  isUpdateCheckActions: boolean
  isLastItem: boolean
  weightMap: WeightMapDto
}

const BasketItem: React.FC<BasketItemProps> = ({
  selectedProduct,
  deleteProduct,
  addCountProduct,
  removeCountProduct,
  editSelectedProduct,
  selectedCouponDefinitionId,
  isUpdateCheckActions,
  isLastItem,
  weightMap,
}): React.ReactElement => {
  const renderDescription = useMemo(() => {
    let infoItems: React.ReactNode[] = []
    let addOptions: React.ReactNode[] = []
    let deleteOptions: React.ReactNode[] = []
    let weight: string = null
    if (selectedProduct?.product) {
      weight = getWeightWithOptions(selectedProduct, weightMap, 'active')
    }

    if (selectedProduct.product.type === MenuEntityTypeEnum.PRODUCT) {
      for (let selectedOption of selectedProduct.selectedOptions) {
        if (selectedOption.isProductVariant) {
          const names: string[] = Object.keys(selectedOption.optionNames)
          infoItems.push(
            <p
              key={selectedOption.optionCategoryId + selectedOption.optionIds[0]}
              className={weight ? 'mb-x1' : 'mb-x3'}>
              {selectedOption.optionCategoryName + ': ' + names[0] + ' '}
            </p>,
          )
          if (weight) {
            infoItems.push(
              <p key={selectedProduct.productVariantId + 'weight'} className={'mb-x3'}>
                {weight}
              </p>,
            )
          }
        } else {
          for (let optionName in selectedOption.optionNames) {
            if (selectedOption.optionNames[optionName]) {
              deleteOptions.push(
                <p className={'mb-0'} key={selectedOption.optionCategoryId + optionName}>
                  {'- ' + optionName}
                </p>,
              )
            } else {
              addOptions.push(
                <p className={'mb-0'} key={selectedOption.optionCategoryId + optionName}>
                  {'+ ' + optionName}
                </p>,
              )
            }
          }
        }
      }
    }

    if (selectedProduct.product.type === MenuEntityTypeEnum.COMBO) {
      let keyPrefix: number = 0
      for (let comboGroupId in selectedProduct.selectedProducts) {
        const selectedComboProductList: SelectedProductDTO[] =
          selectedProduct.selectedProducts[comboGroupId]
        for (let selectedComboProduct of selectedComboProductList) {
          if (selectedComboProduct.isActive) {
            infoItems.push(
              <p key={selectedComboProduct.product.productId + keyPrefix} className={'mb-0'}>
                {selectedComboProduct.product.name}
              </p>,
            )
          }
        }
        keyPrefix++
      }
    }

    if (!selectedProduct?.product?.productVariants && weight) {
      infoItems.push(
        <p key={selectedProduct.productVariantId + 'weight'} className={'mt-x1'}>
          {weight}
        </p>,
      )
    }

    return (
      <div className={'product-modifier mb-x6'}>
        {infoItems.length !== 0 && <div className={'basket-main-option mb-x3'}>{infoItems}</div>}
        <div className={'add ' + (deleteOptions.length !== 0 ? 'mb-x1' : '')}>{addOptions}</div>
        {deleteOptions.length !== 0 && <div className={'remove'}>{deleteOptions}</div>}
      </div>
    )
  }, [selectedProduct, weightMap])

  const getSale = useMemo(() => {
    let sale: number = 0
    for (let key in selectedProduct.sales) {
      let s: number = selectedProduct.sales[key].sale
      if (selectedProduct.sales[key].isCoupon) {
        if (key === selectedCouponDefinitionId) {
          sale = sale + s
        }
      } else {
        sale = sale + s
      }
    }
    return sale
  }, [selectedCouponDefinitionId, selectedProduct.sales])

  const renderPriceBlock = useMemo(() => {
    const fullPrice: number = round(selectedProduct.price * selectedProduct.count)
    const fullSale: number = round(getSale)
    let renderBlock: React.ReactElement
    if (!isUpdateCheckActions) {
      renderBlock = (
        <div className={'price-block'}>
          <span className={'price mr-x2'}>
            {round(fullPrice - fullSale).toString() + ' '}&#8381;
          </span>
          {fullSale !== 0 && <span className={'old-price'}>{' ' + fullPrice + ' '}&#8381;</span>}
        </div>
      )
    } else {
      renderBlock = <Loading size={'s'} />
    }
    return <div className={'basket-product mb-0'}>{renderBlock}</div>
  }, [getSale, selectedProduct.count, selectedProduct.price, isUpdateCheckActions])

  const renderContent: React.ReactElement = useMemo(() => {
    return (
      <React.Fragment>
        <div className={'d-flex'}>
          <div>
            <img
              src={CONSTANTS.cdnUrl + '/' + selectedProduct.product.imageFileName}
              alt={selectedProduct.product.name}
              title={selectedProduct.product.name}
              className={'product-image'}
            />
          </div>
          <div className={'d-flex flex-column justify-content-between w-100 mb-x4'}>
            <div>
              <div className={'d-flex align-items-start justify-content-between mb-x1'}>
                <p className={'basket-product mb-0'}>{selectedProduct.product.name}</p>
                <div className={'d-flex align-items-center'}>
                  {(selectedProduct.product?.optionCategories?.length > 0 ||
                    selectedProduct.product.type === MenuEntityTypeEnum.COMBO) && (
                    <ButtonIcon
                      iconPath={pencilSVG}
                      isHoverArea
                      title={'Редактировать'}
                      className={'mr-x6'}
                      onClick={editSelectedProduct}
                    />
                  )}
                  <ButtonIcon
                    iconPath={deleteSVG}
                    isHoverArea
                    title={'Удалить'}
                    onClick={deleteProduct}
                  />
                </div>
              </div>
              {renderDescription}
            </div>

            <div className={'d-flex align-items-center justify-content-between'}>
              {renderPriceBlock}
              <ProductCountButtons
                addCountProduct={addCountProduct}
                maxCountInOrder={selectedProduct.maxCountInOrder}
                removeCountProduct={removeCountProduct}
                isNotForSale={false}
                count={selectedProduct.count}
              />
            </div>
          </div>
        </div>
        <Divider className={isLastItem ? 'mb-0 ' : 'mb-x4 '} />
      </React.Fragment>
    )
  }, [
    addCountProduct,
    deleteProduct,
    editSelectedProduct,
    removeCountProduct,
    renderDescription,
    renderPriceBlock,
    selectedProduct.count,
    selectedProduct.maxCountInOrder,
    selectedProduct.product.imageFileName,
    selectedProduct.product.name,
    selectedProduct.product?.optionCategories?.length,
    selectedProduct.product.type,
    isLastItem,
  ])

  return <React.Fragment>{renderContent}</React.Fragment>
}

export {BasketItem}
