import {apiGet} from '../../api'
import {CONSTANTS} from '../../../constants'
import {ContactDetailsMap} from 'grilnica-store-share'

async function loadContactDetailsMapService(cityId: string): Promise<ContactDetailsMap> {
  try {
    return await apiGet(CONSTANTS.apiStoreUrl + '/contact-details/map', {
      cityId: cityId,
    })
  } catch (error) {
    console.error('Error loadContactDetailsService error')
  }
}

export {loadContactDetailsMapService}
