import React, {useMemo} from 'react'
import {OrderStatusEnum} from 'grilnica-store-share'
import {OrderAcceptedSVG} from './orderStatusIcon/OrderAcceptedSVG'
import {OrderCookingSVG} from './orderStatusIcon/OrderCookingSVG'
import {OrderReadySVG} from './orderStatusIcon/OrderReadySVG'
import {OrderCancelSVG} from './orderStatusIcon/OrderCancelSVG'
import {OrderDeliverySVG} from './orderStatusIcon/OrderDeliverySVG'
import {OrderIssuedSVG} from './orderStatusIcon/OrderIssuedSVG'

interface OrderStatusIconProps {
  orderStatus: OrderStatusEnum
  className?: string
}

const OrderStatusIcon: React.FC<OrderStatusIconProps> = ({
  orderStatus,
  className,
}): React.ReactElement => {
  const getStatus = useMemo(() => {
    switch (orderStatus) {
      case OrderStatusEnum.DRAFT:
      case OrderStatusEnum.NEW:
        return <OrderAcceptedSVG isActive />
      case OrderStatusEnum.COOKING:
        return <OrderCookingSVG isActive />
      case OrderStatusEnum.READY:
        return <OrderReadySVG isActive />
      case OrderStatusEnum.CANCELLED:
        return <OrderCancelSVG isActive />
      case OrderStatusEnum.ON_WAY:
        return <OrderDeliverySVG isActive />
      case OrderStatusEnum.DELIVERED:
      case OrderStatusEnum.ISSUED:
        return <OrderIssuedSVG isActive />
    }
  }, [orderStatus])

  return <div className={className}>{getStatus}</div>
}

export {OrderStatusIcon}
