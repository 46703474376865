import {call, put, select} from 'redux-saga/effects'
import {State} from '../../ducks'
import {ClientDTO, PrepareOrder} from 'grilnica-store-share'
import {OrderDTO} from 'grilnica-store-share/lib/order/OrderDTO'
import {ResponseError} from 'grilnica-share'
import {PaymentModalStageEnum} from '../types'
import {PaymentModalActions} from '../duck'
import {Actions as OrderActions} from '../../ducks/order'
import {PaymentModalState} from '../types/PaymentModalState'

export enum PaymentTerminalStageEnum {
  NEW = 'NEW', // Новый
  PAYMENT = 'PAYMENT', // Оплачивается
  PAID = 'PAID', // Оплачен
  FISCALIZATION = 'FISCALIZATION', // Фискализируется чек
  FISCALIZED = 'FISCALIZED', // Чек зафискализирован
  RECEIPT_PRINTING = 'RECEIPT_PRINTING', // Чек печатается
  RECEIPT_PRINTED = 'RECEIPT_PRINTED', // Чек напечатан
}

export interface PaymentTerminalDTO {
  paymentTerminalId: string
  orderId: string
  summa: number
  stage: PaymentTerminalStageEnum
  error?: ResponseError
}

export interface SocketData {
  order: OrderDTO
  paymentTerminal: {
    paymentTerminalId: string
    orderId: string
    summa: number
    stage: PaymentTerminalStageEnum
  }
  error: ResponseError
}

function* addRepairError(newError: ResponseError) {
  const repairError: ResponseError = yield select((state: State) => state.paymentModal.repairError)
  if (!repairError || !repairError?.code || !repairError?.errorMessage) {
    yield put(PaymentModalActions.setRepairError(newError))
  }
}

function* controller({payload}: any) {
  const data: SocketData = payload.data

  // console.log('PaymentModalController', data)
  const prepareOrder: PrepareOrder = yield select((state: State) => state.order.prepareOrder)
  const orderInfo: OrderDTO = yield select((state: State) => state.order.orderInfo)
  const paymentModalState: PaymentModalState = yield select((state: State) => state.paymentModal)
  const client: ClientDTO = yield select((state: State) => state.auth?.client)
  //актуален ли заказ ли заказ
  if (paymentModalState.isOpen) {
    if (data.order.orderId === prepareOrder.orderId || data.order.orderId === orderInfo?.orderId) {
      const paymentTerminal: PaymentTerminalDTO = data.paymentTerminal
      const order: OrderDTO = data.order
      const error: ResponseError = data.error
      const currentStage: PaymentModalStageEnum = paymentModalState.currentStage
      // если ошибка
      if (error?.code) {
        switch (error.code) {
          case 'esi-1':
            // ошибка сохранения в айку
            if (
              currentStage === PaymentModalStageEnum.SUCCESS_PAYMENT ||
              currentStage === PaymentModalStageEnum.ORDER_TAKES_LONG_TIME_TO_REGISTER
            ) {
              const nextStage: PaymentModalStageEnum = PaymentModalStageEnum.ERROR_REGISTER_ORDER
              yield put(OrderActions.setOrderInfo(order))
              yield put(PaymentModalActions.setCurrentStage(nextStage))
              yield put(PaymentModalActions.setIsClearTimer(true))
            }

            break
          case 'erp-1':
            //ошибка печати чека
            if (currentStage === PaymentModalStageEnum.RECEIPT_PRINTING) {
              const nextStage: PaymentModalStageEnum = PaymentModalStageEnum.ERROR_RECEIPT_PRINTING
              yield put(OrderActions.setOrderInfo(order))
              yield put(PaymentModalActions.setCurrentStage(nextStage))
              yield put(PaymentModalActions.setIsClearTimer(true))
            }
            break
          case 'rs-1':
            //ошибка Успешно вернулил деньги
            if (currentStage === PaymentModalStageEnum.ERROR_REGISTER_ORDER) {
              const nextStage: PaymentModalStageEnum = PaymentModalStageEnum.SUCCESS_FULL_REFUND
              yield put(OrderActions.setOrderInfo(order))
              yield put(PaymentModalActions.setCurrentStage(nextStage))
              yield put(PaymentModalActions.setIsClearTimer(true))
            }
            break
          case 'eu-1':
            //ошибка универсильная
            yield call(addRepairError, error)
            if (order?.isPaid) {
              const nextStage: PaymentModalStageEnum = PaymentModalStageEnum.ERROR_UNIVERSAL
              yield put(OrderActions.setOrderInfo(order))
              yield put(PaymentModalActions.setCurrentStage(nextStage))
              yield put(PaymentModalActions.setIsClearTimer(true))
            } else {
              yield put(PaymentModalActions.setIsRedirectToError(true))
            }
            break
          case 'cp-1':
            //ошибка отмена оплаты
            if (currentStage === PaymentModalStageEnum.WAITING_PAYMENT) {
              const nextStage: PaymentModalStageEnum = PaymentModalStageEnum.ERROR_PAYMENT
              yield put(OrderActions.setOrderInfo(order))
              yield put(PaymentModalActions.setCurrentStage(nextStage))
              yield put(PaymentModalActions.setIsClearTimer(true))
            }
            break
          case 'cp-2':
            //ошибка отмена возврата
            if (currentStage === PaymentModalStageEnum.ERROR_REGISTER_ORDER) {
              yield call(addRepairError, error)
              const nextStage: PaymentModalStageEnum = PaymentModalStageEnum.ERROR_FULL_REFUND
              yield put(OrderActions.setOrderInfo(order))
              yield put(PaymentModalActions.setCurrentStage(nextStage))
              yield put(PaymentModalActions.setIsClearTimer(true))
            }
            break
          case 'co-1':
            //ошибка отмена оформления
            if (
              currentStage === PaymentModalStageEnum.WAITING_PAYMENT ||
              currentStage === PaymentModalStageEnum.ERROR_PAYMENT
            ) {
              yield put(PaymentModalActions.setIsRedirectToHome(true))
              yield put(PaymentModalActions.setIsOpen(false))
              yield put(PaymentModalActions.setCurrentStage(null))
            }
            break
          case 'enpf-1':
            //ошибка нет бумаги для фискализации
            if (currentStage === PaymentModalStageEnum.RECEIPT_PRINTING) {
              const nextStage: PaymentModalStageEnum = PaymentModalStageEnum.ERROR_RECEIPT_PRINTING
              yield put(OrderActions.setOrderInfo(order))
              yield put(PaymentModalActions.setCurrentStage(nextStage))
              yield put(PaymentModalActions.setIsClearTimer(true))
            }
            break
          case 'euf-1':
            //ошибки вызванные на этапе фискализации
            if (currentStage) {
              yield call(addRepairError, error)
              const nextStage: PaymentModalStageEnum = PaymentModalStageEnum.ERROR_FISCALIZATION
              yield put(OrderActions.setOrderInfo(order))
              yield put(PaymentModalActions.setCurrentStage(nextStage))
              yield put(PaymentModalActions.setIsClearTimer(true))
            }
            break
          default:
          //redirect на страницу not work
        }
      } else {
        const paymentTerminalStage: PaymentTerminalStageEnum = paymentTerminal.stage
        // обычные этапы
        switch (paymentTerminalStage) {
          case PaymentTerminalStageEnum.NEW:
            break
          case PaymentTerminalStageEnum.PAYMENT:
            break
          case PaymentTerminalStageEnum.PAID:
            if (currentStage === PaymentModalStageEnum.WAITING_PAYMENT) {
              const nextStage: PaymentModalStageEnum = PaymentModalStageEnum.SUCCESS_PAYMENT
              yield put(OrderActions.setOrderInfo(order))
              yield put(PaymentModalActions.setCurrentStage(nextStage))
              yield put(PaymentModalActions.setIsClearTimer(true))
            }
            break
          case PaymentTerminalStageEnum.FISCALIZATION:
            break
          case PaymentTerminalStageEnum.FISCALIZED:
            break
          case PaymentTerminalStageEnum.RECEIPT_PRINTING:
            if (
              currentStage === PaymentModalStageEnum.SUCCESS_PAYMENT ||
              currentStage === PaymentModalStageEnum.ORDER_TAKES_LONG_TIME_TO_REGISTER
            ) {
              const nextStage: PaymentModalStageEnum = PaymentModalStageEnum.RECEIPT_PRINTING
              yield put(OrderActions.setOrderInfo(order))
              yield put(PaymentModalActions.setCurrentStage(nextStage))
              yield put(PaymentModalActions.setIsClearTimer(true))
            }
            break
          case PaymentTerminalStageEnum.RECEIPT_PRINTED:
            if (currentStage === PaymentModalStageEnum.RECEIPT_PRINTING) {
              if (client) {
                const nextStage: PaymentModalStageEnum =
                  PaymentModalStageEnum.THANK_YOU_FOR_ORDER_IN_MODAL
                yield put(OrderActions.setOrderInfo(order))
                yield put(PaymentModalActions.setCurrentStage(nextStage))
                yield put(PaymentModalActions.setIsClearTimer(true))
              } else {
                yield put(OrderActions.setOrderInfo(order))
                yield put(PaymentModalActions.setIsRedirectToSuccess(true))
                yield put(PaymentModalActions.setIsOpen(false))
                yield put(PaymentModalActions.setCurrentStage(null))
              }
            }
            break
        }
      }
    }
  }
}

export {controller}
