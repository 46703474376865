import React from 'react'
import {CollapseBlock} from 'grilnica-reactstrap'

interface FooterListItemProps {
  renderElements?: React.ReactElement[]
  title: string
  isCollapsedBlock: boolean
}

const FooterListItem: React.FC<FooterListItemProps> = ({
  renderElements,
  title,
  isCollapsedBlock,
}): React.ReactElement => {
  return (
    <div className={'footer-list'}>
      {isCollapsedBlock ? (
        <CollapseBlock
          className={'collapsed-block-footer mb-0'}
          name={<h3 className={'mb-0'}>{title}</h3>}
          initiallyOpen={false}>
          <ul>{renderElements}</ul>
        </CollapseBlock>
      ) : (
        <div>
          <h3 className={'mb-x6'}>{title}</h3>
          <ul>{renderElements}</ul>
        </div>
      )}
    </div>
  )
}

export default FooterListItem
