import React, {useState} from 'react'
import moment from 'moment'
import {useInterval} from 'grilnica-share-client'
import {getCountString} from 'grilnica-share'

interface SaleTimerProps {
  className?: string
  isProductWeek: boolean
  endDate: Date
  currentDay: number
}

const SaleTimer: React.FC<SaleTimerProps> = ({
  className,
  endDate,
  isProductWeek,
  currentDay,
}): React.ReactElement => {
  const [time, setTime] = useState<any>(moment())

  useInterval(() => {
    const countDays = moment(endDate).diff(time, 'days')
    if (moment().day() === currentDay || (isProductWeek && countDays !== 0)) {
      setTime(moment())
    }
  }, 1000)

  let diffDays: number = 0 // оставшееся количество дней
  if (isProductWeek && endDate) {
    diffDays = moment(endDate).add(1, 'day').diff(time, 'days')
  }

  const end = moment().endOf('day') // конец дня
  const timeLeft = moment.utc(end.diff(time)) // оставшееся время до конца дня

  let timeSaleEnd

  if (moment().day() === currentDay || (isProductWeek && diffDays !== 0)) {
    timeSaleEnd = timeLeft.format('HH:mm:ss')
  } else {
    timeSaleEnd = '00:00:00'
  }

  return (
    <div className={'sale-timer ' + (className ? className : '')}>
      <p className={'caption-sale-end mb-x1'}>До конца акции:</p>
      <p className={'sale-time mb-0'}>
        {diffDays ? (
          <span className={'mr-x1'}>
            {diffDays + ' ' + getCountString(diffDays, 'день', 'дня', 'дней')}
          </span>
        ) : null}
        <span>{timeSaleEnd}</span>
      </p>
    </div>
  )
}

export {SaleTimer}
