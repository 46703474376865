import {apiGet, apiPost} from '../../api'
import {CONSTANTS} from '../../../constants'
import {OrderDTO} from 'grilnica-store-share/lib/order/OrderDTO'
import {SaleResponse} from '../../store/action/types/SaleResponse'
import {ActionDTO} from 'grilnica-store-share'
import {HappyHourResponse} from '../../store/action/types/HappyHourActionMap'

async function loadActionsListService(cityId: string, errorActions?: any): Promise<ActionDTO[]> {
  try {
    return await apiGet(CONSTANTS.apiStoreUrl + '/action/find-current/' + cityId, {
      cityId: cityId,
    })
  } catch (error) {
    if (errorActions) {
      errorActions.setError(error)
    } else {
      console.error('loadSalesListService error')
      throw error
    }
  }
}

async function loadActionService(actionId: string, cityId: string): Promise<ActionDTO> {
  return await apiGet(CONSTANTS.apiStoreUrl + '/action/' + actionId, {
    options: {cityId},
    cityId: cityId,
  })
}

async function getAvailableActionsService(order: OrderDTO): Promise<SaleResponse> {
  return await apiPost(CONSTANTS.apiStoreUrl + '/action/find-available/', order, {
    cityId: order.cityId,
  })
}

async function getAvailableActionsByRestaurantService(order: OrderDTO): Promise<SaleResponse> {
  return await apiPost(CONSTANTS.apiStoreUrl + '/action/find-available-by-restaurant/', order, {
    cityId: order.cityId,
  })
}

async function loadHappyHourService(cityId: string): Promise<HappyHourResponse> {
  return await apiGet(CONSTANTS.apiStoreUrl + '/action/happy-hours/', {
    cityId: cityId,
  })
}

export {
  loadActionsListService,
  loadActionService,
  getAvailableActionsService,
  getAvailableActionsByRestaurantService,
  loadHappyHourService,
}
