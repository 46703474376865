import React, {useMemo, useState} from 'react'
import {Button, Col, CustomCheckbox, Divider, InputVertical, Row} from 'grilnica-reactstrap'
import {PrepareOrder} from 'grilnica-store-share'
import {onChangePhone} from '../../../../utils/UserUtils'
import {plusSVG} from '../../../../../components/svg/SystemIcons'

interface OrderTakeProps {
  prepareOrder: PrepareOrder
  setPrepareOrderField: (key: string, value: any, parentKey?: string) => void
  error: string
}

const OrderTake: React.FC<OrderTakeProps> = ({
  prepareOrder,
  setPrepareOrderField,
  error,
}): React.ReactElement => {
  const [isShowAdditionalPhone, setIsShowAdditionalPhone] = useState<boolean>(false)

  const renderWhoTake = useMemo(() => {
    return (
      <>
        <p className={'name-blocks'}>1. Кто заберет?</p>
        <Row>
          <Col sm={6}>
            <InputVertical
              id={'input-first-name'}
              name={'firstName'}
              type={'text'}
              label={'Имя'}
              invalid={!!error && !prepareOrder.name}
              value={prepareOrder.name}
              placeholder={'Введите имя'}
              isRequired
              isRounded
              size={'lg'}
              onChange={(value: any) => {
                setPrepareOrderField('name', value)
              }}
            />
          </Col>
          <Col sm={6}>
            <InputVertical
              id={'input-phone'}
              name={'phone'}
              type={'text'}
              label={'Телефон'}
              value={onChangePhone(prepareOrder.phone, prepareOrder.phone)}
              isRequired={false}
              isRounded
              disabled
              size={'lg'}
              className={'client-phone'}
              onChange={() => {}}
            />
            <Button
              color={'primary'}
              style={'text'}
              size={'sm'}
              iconPath={plusSVG}
              isRoundedButton
              className={'add-client-phone ' + (isShowAdditionalPhone ? 'd-none' : '')}
              onClick={() => {
                setIsShowAdditionalPhone(true)
                setPrepareOrderField('additionPhone', '+7')
              }}>
              Добавить
            </Button>
          </Col>
          <Col sm={6} className={isShowAdditionalPhone ? 'd-block' : 'd-none'}>
            <InputVertical
              id={'input-additional-phone'}
              name={'additional-phone'}
              type={'text'}
              label={'Дополнительный'}
              value={prepareOrder.additionPhone}
              placeholder={'+7'}
              isRequired={false}
              isRounded
              size={'lg'}
              className={'additional-phone'}
              onChange={(value: any) => {
                if (value.length <= 16) {
                  const newValue = onChangePhone(prepareOrder.additionPhone, value)
                  setPrepareOrderField('additionPhone', newValue)
                }
              }}
            />
            <Button
              color={'primary'}
              style={'text'}
              size={'sm'}
              isRoundedButton
              className={'add-client-phone'}
              onClick={() => {
                setIsShowAdditionalPhone(false)
                setPrepareOrderField('additionPhone', null)
              }}>
              Отменить
            </Button>
          </Col>
          <Col sm={12} className={'mb-x6'}>
            <CustomCheckbox
              id={'callMe'}
              label={'Перезвонить мне'}
              checked={prepareOrder.isCallBack}
              onChange={(value: any) => {
                setPrepareOrderField('isCallBack', value)
              }}
              color={'red'}
            />
          </Col>
        </Row>
        <Divider className={'mb-x6'} />
      </>
    )
  }, [
    setPrepareOrderField,
    isShowAdditionalPhone,
    prepareOrder.additionPhone,
    prepareOrder.isCallBack,
    prepareOrder.name,
    prepareOrder.phone,
    error,
  ])

  return <>{renderWhoTake}</>
}

export {OrderTake}
