import {OptionCategoryDTO} from 'grilnica-store-share'

function getDefaultOptionIds(optionCategories: OptionCategoryDTO[]): string[] {
  let optionIds: string[] = []
  if (optionCategories || []) {
    for (let optionCategory of optionCategories) {
      for (let option of optionCategory.options || []) {
        if (option.restrictions.isDefault) {
          optionIds.push(option.optionId)
        }
      }
    }
  }
  return optionIds
}

export {getDefaultOptionIds}
