import React, {useMemo} from 'react'
import {
  Button,
  ButtonLink,
  Container,
  DropdownToggleType,
  GRDropdown,
  GRDropdownItem,
  GRDropdownMenu,
  GRDropdownToggle,
} from 'grilnica-reactstrap'
import {CityDTO} from 'grilnica-share'
import {ClientDTO} from 'grilnica-store-share'
import {Link} from 'react-router-dom'
import {getFullUrl} from '../../../../utils/url'
import {onChangePhone} from '../../../site/utils/UserUtils'
import {chevronDownSVG, logoutSVG, mapMarkerSVG} from '../../svg/SystemIcons'

interface SiteNavigationProps {
  selectedCity: CityDTO
  onOpenSelectCityModal: () => void
  client: ClientDTO
  urlPrefix: string
}

const SiteNavigation: React.FC<SiteNavigationProps> = ({
  selectedCity,
  client,
  onOpenSelectCityModal,
  urlPrefix,
}): React.ReactElement => {
  const renderSelectedCity: React.ReactElement = useMemo(() => {
    if (selectedCity) {
      return (
        <div className={'mr-x4'}>
          <Button
            isRoundedButton
            size={'sm'}
            iconPath={mapMarkerSVG}
            iconPosition={'left'}
            style={'text'}
            onClick={() => {
              onOpenSelectCityModal()
            }}>
            {selectedCity.name}
          </Button>
        </div>
      )
    } else {
      return null
    }
  }, [onOpenSelectCityModal, selectedCity])

  const renderContent: React.ReactElement = useMemo(() => {
    return (
      <div className={'nav-bg'}>
        <Container size={'normal'} className={'nav-site'}>
          <div className={'d-flex flex-row align-items-center'}>{renderSelectedCity}</div>
          <div className={'nav-site-container'}>
            <nav>
              <ul className={'mb-0'} dir={'hr'}>
                <li>
                  <Link to={'/actions'}>Акции</Link>
                </li>
                <li>
                  <Link to={'/rest'}>Рестораны</Link>
                </li>
                <li>
                  <Link to={'/shipping'}>Доставка</Link>
                </li>
                <li>
                  <Link to={'/qualitycontrol'}>Контроль качества</Link>
                </li>
                <li>
                  {/*<Link to={'/about'}>О компании</Link>*/}
                  <a href={'https://career.grilnica.ru/'}>Карьера</a>
                </li>
                <li>
                  <a href={'https://vkusnayafranshiza.ru/'}>Франшиза</a>
                </li>
                <li>
                  <GRDropdown id={'navDropdown'} direction={'auto'}>
                    <GRDropdownToggle
                      type={DropdownToggleType.textButton}
                      iconPath={chevronDownSVG}
                      isRoundedButton
                      textButtonStyle={'text'}
                      textButtonColor={'secondary'}
                      iconPosition={'right'}
                      textButton={'Еще'}
                      iconTitle={'Еще'}
                    />
                    <GRDropdownMenu>
                      {/*<GRDropdownItem text={'О компании'} link={getFullUrl('/about', urlPrefix)} />*/}
                      <GRDropdownItem text={'Рестораны'} link={getFullUrl('/rest', urlPrefix)} />
                      <GRDropdownItem text={'Доставка'} link={getFullUrl('/shipping', urlPrefix)} />
                      <GRDropdownItem
                        text={'Контроль качества'}
                        link={getFullUrl('/qualitycontrol', urlPrefix)}
                      />
                      <GRDropdownItem text={'Карьера'} link={'https://career.grilnica.ru/'} />
                      <GRDropdownItem text={'Франшиза'} link={'https://vkusnayafranshiza.ru/'} />
                    </GRDropdownMenu>
                  </GRDropdown>
                </li>
              </ul>
            </nav>
            {selectedCity ? (
              client ? (
                <div className={'d-flex flex-row ml-secondary'}>
                  <Link to={'/profile'} title={'Профиль'} className={'header-profile-link'}>
                    {client.firstName
                      ? client.firstName
                      : onChangePhone(client.phone, client.phone)}
                  </Link>
                </div>
              ) : (
                <ButtonLink
                  isRoundedButton
                  size={'sm'}
                  to={'/login'}
                  iconPosition={'left'}
                  iconPath={logoutSVG}
                  style={'text'}
                  className={'d-flex align-items-center ml-secondary'}>
                  Войти
                </ButtonLink>
              )
            ) : null}
          </div>
        </Container>
      </div>
    )
  }, [client, renderSelectedCity, selectedCity, urlPrefix])

  return <React.Fragment>{renderContent}</React.Fragment>
}

export {SiteNavigation}
