import {all, takeLatest} from 'redux-saga/effects'
import {loadCityList, selectCity, selectCityInCookies} from './city'
import {CityActionsTypes} from '../ducks/city'
import {
  sendPhoneConfirmationTokenId,
  sendPhoneNumber,
  logout,
  saveClientData,
  checkAuthBySixCode,
  sendTerminalLoginByPhone,
} from './auth'
import {AuthActionsTypes} from '../ducks/auth'
import {setError} from './error'
import {ErrorActionsTypes} from '../ducks/error'
import {initialize, initializeT} from './initialize'
import {InitializeActionsTypes} from '../ducks/initialize'
import {
  addComboProductInBasket,
  addProductWithOneOptionsInBasket,
  addProductWithOptionsInBasket,
  addProductWithoutOptionsInBasket,
  changeCountProductInBasket,
  checkActions,
  clearBasket,
  deleteProductFromBasket,
  getPersonalCouponSale,
  openProductScreenForEdit,
} from './basket/basket'
import {OrderActionsTypes} from '../ducks/order'
import {
  // cancelPayOrderForPayment,
  changeAutoChoiceRestaurant,
  changeSelectedOrder,
  clearLastOrderStatus,
  getCheckOrder,
  getCreatedOrders,
  payOrderForPayment,
  repeatOrder,
  saveOrder,
  saveOrderTerminal,
  setPrepareOrderDeliveryType,
  setPrepareOrderPrepareTime,
  setPrepareOrderRestaurant,
  setPrepareOrderSelectedAddress,
  setSelectedOrder,
  updateDeliveryPrice,
} from './order/order'
import {
  backgroundLoadAreaRegionOfDeliveryList,
  changeCoordinates,
  changeSearchValue,
  clearSelectedAddressAndCoordinates,
  getDeliveryPriceInMap,
  immediateLoadAreaRegionOfDeliveryList,
  selectAddressFromFoundList,
} from './address'
import {AddressActionsTypes} from '../ducks/address'
import {RestaurantActionsTypes} from '../ducks/restaurant'
import {
  addFavouriteRestaurant,
  clearClientRestaurants,
  deleteFavouritesRestaurant,
  loadRestaurants,
  searchRestaurants,
} from './restaurant'
import {PaymentActionsTypes} from '../ducks/payment'
import {
  cancelPaymentOnlineCard,
  checkPaymentStatus,
  createPaymentOnlineCard,
  setIsSuccessPayment,
} from './payment'
import {BasketActionsTypes} from '../ducks/basket'
import {ActionActionsType} from '../action/duck'
import {changeSelectedActionCoupon, selectAction} from '../action/saga/generators'
import {controller} from '../paymentModal/saga/generators'
import {PaymentModalActionsTypes} from '../paymentModal/duck'
import {RecommendationsProductActionsTypes} from '../recommendationsProduct/duck'
import {
  addRecommendationProductInBasket,
  checkRecommendationsProducts,
} from '../recommendationsProduct/saga/generators'
import {TerminalActionsTypes} from '../terminal/duck'
import {redirectToHomeBySocket, redirectToRepairBySocket} from '../terminal/saga/generators'

function* rootSaga() {
  return yield all([
    //InitializeActionsTypes
    takeLatest(InitializeActionsTypes.INITIALIZE_REQUEST, initialize),
    takeLatest(InitializeActionsTypes.INITIALIZE_TERMINAL_REQUEST, initializeT),

    //CityActionsTypes
    takeLatest(CityActionsTypes.SELECT_CITY_REQUEST, selectCity),
    takeLatest(CityActionsTypes.SELECT_CITY_IN_COOKIES_REQUEST, selectCityInCookies),
    takeLatest(CityActionsTypes.LOAD_CITY_LIST_REQUEST, loadCityList),

    takeLatest(AuthActionsTypes.SEND_PHONE_NUMBER_REQUEST, sendPhoneNumber),
    takeLatest(
      AuthActionsTypes.SEND_PHONE_CONFIRMATION_TOKEN_ID_REQUEST,
      sendPhoneConfirmationTokenId,
    ),
    takeLatest(AuthActionsTypes.SEND_TERMINAL_PHONE_LOGIN_REQUEST, sendTerminalLoginByPhone),
    takeLatest(AuthActionsTypes.LOGOUT_REQUEST, logout),
    takeLatest(AuthActionsTypes.CHECK_AUTH_BY_SIX_REQUEST, checkAuthBySixCode),

    //RestaurantActionsTypes
    takeLatest(RestaurantActionsTypes.LOAD_RESTAURANTS_REQUEST, loadRestaurants),
    takeLatest(RestaurantActionsTypes.CLEAR_FAVOURITE_RESTAURANTS_REQUEST, clearClientRestaurants),
    takeLatest(RestaurantActionsTypes.ADD_FAVOURITE_RESTAURANT_REQUEST, addFavouriteRestaurant),
    takeLatest(
      RestaurantActionsTypes.DELETE_FAVOURITE_RESTAURANT_REQUEST,
      deleteFavouritesRestaurant,
    ),
    takeLatest(
      RestaurantActionsTypes.CHANGE_SEARCH_RESTAURANT_NAME_IN_MAP_REQUEST,
      searchRestaurants,
    ),

    //AddressActionsTypes
    takeLatest(AddressActionsTypes.CHANGE_COORDINATES_REQUEST, changeCoordinates),
    takeLatest(
      AddressActionsTypes.CLEAR_SELECTED_ADDRESS_AND_COORDINATES_REQUEST,
      clearSelectedAddressAndCoordinates,
    ),
    takeLatest(AddressActionsTypes.CHANGE_SEARCH_VALUE_REQUEST, changeSearchValue),
    takeLatest(
      AddressActionsTypes.SELECT_ADDRESS_FROM_FOUND_ADDRESSES_LIST_REQUEST,
      selectAddressFromFoundList,
    ),
    takeLatest(
      AddressActionsTypes.IMMEDIATE_LOAD_AREA_REGION_OF_DELIVERY_LIST_REQUEST,
      immediateLoadAreaRegionOfDeliveryList,
    ),
    takeLatest(
      AddressActionsTypes.BACKGROUND_LOAD_AREA_REGION_OF_DELIVERY_LIST_REQUEST,
      backgroundLoadAreaRegionOfDeliveryList,
    ),
    takeLatest(AddressActionsTypes.GET_DELIVERY_PRICE_IN_MAP_REQUEST, getDeliveryPriceInMap),

    //BasketActionsTypes
    takeLatest(
      BasketActionsTypes.CHANGE_COUNT_SELECTED_PRODUCT_IN_BASKET_REQUEST,
      changeCountProductInBasket,
    ),

    takeLatest(
      BasketActionsTypes.ADD_PRODUCT_WITH_OPTIONS_IN_BASKET_REQUEST,
      addProductWithOptionsInBasket,
    ),

    takeLatest(
      BasketActionsTypes.ADD_PRODUCT_WITHOUT_OPTIONS_IN_BASKET_REQUEST,
      addProductWithoutOptionsInBasket,
    ),
    takeLatest(BasketActionsTypes.DELETE_PRODUCT_FROM_BASKET_REQUEST, deleteProductFromBasket),
    takeLatest(BasketActionsTypes.OPEN_PRODUCT_SCREEN_FOR_EDIT_REQUEST, openProductScreenForEdit),
    takeLatest(BasketActionsTypes.CLEAR_BASKET_REQUEST, clearBasket),
    takeLatest(
      BasketActionsTypes.ADD_PRODUCT_WITH_ONE_OPTION_IN_BASKET_REQUEST,
      addProductWithOneOptionsInBasket,
    ),
    takeLatest(BasketActionsTypes.ADD_COMBO_IN_BASKET_REQUEST, addComboProductInBasket),

    takeLatest(BasketActionsTypes.CHECK_ACTIONS_REQUEST, checkActions),
    takeLatest(BasketActionsTypes.SET_PERSONAL_COUPON, getPersonalCouponSale),

    //AuthActionsTypes
    takeLatest(AuthActionsTypes.SAVE_CLIENT_DATA_REQUEST, saveClientData),

    //OrderActionsTypes
    takeLatest(
      OrderActionsTypes.SET_PREPARE_ORDER_PREPARE_TIME_REQUEST,
      setPrepareOrderPrepareTime,
    ),
    takeLatest(
      OrderActionsTypes.SET_PREPARE_ORDER_SELECTED_ADDRESS_REQUEST,
      setPrepareOrderSelectedAddress,
    ),
    takeLatest(OrderActionsTypes.GET_CHECKED_ORDER_REQUEST, getCheckOrder),
    takeLatest(OrderActionsTypes.UPDATE_DELIVERY_PRICE_REQUEST, updateDeliveryPrice),
    takeLatest(OrderActionsTypes.SAVE_ORDER_REQUEST, saveOrder),
    takeLatest(OrderActionsTypes.SAVE_ORDER_TERMINAL_REQUEST, saveOrderTerminal),
    takeLatest(OrderActionsTypes.PAY_ORDER_FOR_PAYMENT_REQUEST, payOrderForPayment),
    // takeLatest(OrderActionsTypes.CANCEL_PAY_ORDER_FOR_PAYMENT_REQUEST, cancelPayOrderForPayment),
    takeLatest(OrderActionsTypes.SET_SELECTED_ORDER_REQUEST, setSelectedOrder),

    takeLatest(OrderActionsTypes.GET_CREATED_ORDERS_REQUEST, getCreatedOrders),
    takeLatest(OrderActionsTypes.SET_PREPARE_ORDER_RESTAURANT_REQUEST, setPrepareOrderRestaurant),
    takeLatest(
      OrderActionsTypes.SET_PREPARE_ORDER_DELIVERY_TYPE_REQUEST,
      setPrepareOrderDeliveryType,
    ),
    takeLatest(OrderActionsTypes.CHANGE_SELECTED_ORDER, changeSelectedOrder),
    takeLatest(OrderActionsTypes.CLEAR_LAST_ORDER_STATUS, clearLastOrderStatus),
    takeLatest(OrderActionsTypes.REPEAT_ORDER, repeatOrder),
    takeLatest(OrderActionsTypes.CHANGE_AUTO_CHOICE_RESTAURANT_REQUEST, changeAutoChoiceRestaurant),

    //PaymentActionsTypes
    takeLatest(PaymentActionsTypes.CREATE_PAYMENT_ONLINE_CARD_REQUEST, createPaymentOnlineCard),
    takeLatest(PaymentActionsTypes.CANCEL_PAYMENT_ONLINE_CARD_REQUEST, cancelPaymentOnlineCard),
    takeLatest(PaymentActionsTypes.SET_IS_SUCCESS_PAYMENT_REQUEST, setIsSuccessPayment),
    takeLatest(PaymentActionsTypes.CHECK_PAYMENT_STATUS_REQUEST, checkPaymentStatus),

    //ActionActionsType
    takeLatest(ActionActionsType.SELECT_ACTION_REQUEST, selectAction),
    // takeLatest(ActionActionsType.LOAD_ACTIONS_REQUEST, loadActions),
    // takeLatest(ActionActionsType.UPDATE_ACTIONS_REQUEST, updateActions),
    takeLatest(
      ActionActionsType.SET_SELECTED_COUPON_DEFINITION_ID_REQUEST,
      changeSelectedActionCoupon,
    ),

    //ErrorActionsTypes
    takeLatest(ErrorActionsTypes.SET_ERROR_REQUEST, setError),

    //PaymentModalActionsTypes
    takeLatest(PaymentModalActionsTypes.CONTROLLER, controller),
    //TerminalActionsTypes
    takeLatest(TerminalActionsTypes.CONTROLLER_SOCKET_REDIRECT_TO_HOME, redirectToHomeBySocket),
    takeLatest(TerminalActionsTypes.CONTROLLER_SOCKET_REDIRECT_TO_REPAIR, redirectToRepairBySocket),

    //RecommendationsProductActionsTypes
    takeLatest(
      RecommendationsProductActionsTypes.CHECK_RECOMMENDATIONS_PRODUCTS_REQUEST,
      checkRecommendationsProducts,
    ),
    takeLatest(
      RecommendationsProductActionsTypes.ADD_RECOMMENDATION_PRODUCT_IN_BASKET,
      addRecommendationProductInBasket,
    ),
  ])
}

export default rootSaga
