import {bindActionCreators} from 'redux'
import {BaseAction} from '../common/BaseAction'
import {PaymentDTO} from 'grilnica-store-share/lib/order/PaymentDTO'
import {PaymentStatusDto} from 'grilnica-share'

export interface PaymentState {
  isRequestPaymentOnlineCard: boolean
  payment: PaymentDTO
  isShowSuccessPaymentModal: boolean
  isShowFailurePaymentModal: boolean
  isRequestSetIsSuccessPayment: boolean
  paymentStatus: PaymentStatusDto | null
}

export enum PaymentActionsTypes {
  CREATE_PAYMENT_ONLINE_CARD_REQUEST = 'payment/PAYMENT_ONLINE_CARD_REQUEST',
  CHECK_PAYMENT_STATUS_REQUEST = 'payment/CHECK_PAYMENT_STATUS_REQUEST',
  TOGGLE_IS_REQUEST_PAYMENT_ONLINE_CARD = 'payment/TOGGLE_IS_REQUEST_PAYMENT_ONLINE_CARD',
  SET_PAYMENT = 'payment/SET_PAYMENT',
  SET_PAYMENT_STATUS = 'payment/SET_PAYMENT_STATUS',
  CANCEL_PAYMENT_ONLINE_CARD_REQUEST = 'payment/CANCEL_PAYMENT_ONLINE_CARD_REQUEST',
  SET_IS_SUCCESS_PAYMENT_REQUEST = 'payment/SET_IS_SUCCESS_PAYMENT',
  TOGGLE_IS_SHOW_FAILURE_PAYMENT_MODAL = 'payment/TOGGLE_IS_SHOW_FAILURE_PAYMENT_MODAL',
  TOGGLE_IS_SHOW_SUCCESS_PAYMENT_MODAL = 'payment/TOGGLE_IS_SHOW_SUCCESS_PAYMENT_MODAL',
  TOGGLE_IS_REQUEST_SET_IS_SUCCESS_PAYMENT = 'payment/TOGGLE_IS_REQUEST_SET_IS_SUCCESS_PAYMENT',
  CLEAR_PAYMENT_STATE = 'payment/CLEAR_PAYMENT_STATE',
}

const INITIAL_STATE: PaymentState = {
  isRequestPaymentOnlineCard: false,
  payment: null,
  isShowSuccessPaymentModal: false,
  isShowFailurePaymentModal: false,
  isRequestSetIsSuccessPayment: false,
  paymentStatus: null,
}

export const Actions = {
  createPaymentOnlineCardRequest: (): BaseAction => ({
    type: PaymentActionsTypes.CREATE_PAYMENT_ONLINE_CARD_REQUEST,
  }),

  checkPaymentStatusRequest: (paymentId: string): BaseAction => ({
    type: PaymentActionsTypes.CHECK_PAYMENT_STATUS_REQUEST,
    payload: {paymentId},
  }),

  toggleIsRequestPaymentOnlineCard: (isRequest: boolean): BaseAction => ({
    type: PaymentActionsTypes.TOGGLE_IS_REQUEST_PAYMENT_ONLINE_CARD,
    payload: isRequest,
  }),

  setPayment: (payment: PaymentDTO): BaseAction => ({
    type: PaymentActionsTypes.SET_PAYMENT,
    payload: payment,
  }),

  setPaymentStatus: (paymentStatus: PaymentStatusDto): BaseAction => ({
    type: PaymentActionsTypes.SET_PAYMENT_STATUS,
    payload: paymentStatus,
  }),

  cancelPaymentOnlineCardRequest: (): BaseAction => ({
    type: PaymentActionsTypes.CANCEL_PAYMENT_ONLINE_CARD_REQUEST,
  }),

  setIsSuccessPaymentRequest: (orderId: string): BaseAction => ({
    type: PaymentActionsTypes.SET_IS_SUCCESS_PAYMENT_REQUEST,
    payload: {orderId},
  }),

  toggleIsShowFailurePaymentModal: (isShow: boolean): BaseAction => ({
    type: PaymentActionsTypes.TOGGLE_IS_SHOW_FAILURE_PAYMENT_MODAL,
    payload: isShow,
  }),

  toggleIsShowSuccessPaymentModal: (isShow: boolean): BaseAction => ({
    type: PaymentActionsTypes.TOGGLE_IS_SHOW_SUCCESS_PAYMENT_MODAL,
    payload: isShow,
  }),

  toggleIsRequestSetIsSuccessPayment: (isRequest: boolean): BaseAction => ({
    type: PaymentActionsTypes.TOGGLE_IS_REQUEST_SET_IS_SUCCESS_PAYMENT,
    payload: isRequest,
  }),

  clearPaymentState: (): BaseAction => ({
    type: PaymentActionsTypes.CLEAR_PAYMENT_STATE,
  }),
}

export function bindPaymentActions(dispatch: any) {
  return bindActionCreators(Actions, dispatch)
}

export default function Payment(
  state: PaymentState = INITIAL_STATE,
  action: BaseAction,
): PaymentState {
  switch (action.type) {
    case PaymentActionsTypes.TOGGLE_IS_REQUEST_PAYMENT_ONLINE_CARD: {
      return {
        ...state,
        isRequestPaymentOnlineCard: action.payload,
      }
    }

    case PaymentActionsTypes.CREATE_PAYMENT_ONLINE_CARD_REQUEST: {
      return {
        ...state,
        isRequestPaymentOnlineCard: true,
      }
    }

    case PaymentActionsTypes.CHECK_PAYMENT_STATUS_REQUEST: {
      return {
        ...state,
        paymentStatus: null,
      }
    }

    case PaymentActionsTypes.SET_PAYMENT_STATUS: {
      return {
        ...state,
        paymentStatus: action.payload,
      }
    }

    case PaymentActionsTypes.SET_PAYMENT: {
      return {
        ...state,
        payment: action.payload,
        isRequestPaymentOnlineCard: false,
      }
    }

    case PaymentActionsTypes.SET_IS_SUCCESS_PAYMENT_REQUEST: {
      return {
        ...state,
        isShowSuccessPaymentModal: action.payload.isShowModal,
        isRequestSetIsSuccessPayment: true,
      }
    }

    case PaymentActionsTypes.TOGGLE_IS_SHOW_FAILURE_PAYMENT_MODAL: {
      return {
        ...state,
        isShowFailurePaymentModal: action.payload,
      }
    }

    case PaymentActionsTypes.TOGGLE_IS_SHOW_SUCCESS_PAYMENT_MODAL: {
      return {
        ...state,
        isShowSuccessPaymentModal: action.payload,
      }
    }

    case PaymentActionsTypes.TOGGLE_IS_REQUEST_SET_IS_SUCCESS_PAYMENT: {
      return {
        ...state,
        isRequestSetIsSuccessPayment: action.payload,
      }
    }

    case PaymentActionsTypes.CLEAR_PAYMENT_STATE: {
      return {
        ...INITIAL_STATE,
        isShowFailurePaymentModal: state.isShowFailurePaymentModal,
        isShowSuccessPaymentModal: state.isShowSuccessPaymentModal,
      }
    }

    default: {
      return {
        ...state,
      }
    }
  }
}
