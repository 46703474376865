import React, {useMemo} from 'react'
import {Button, Modal, ModalBody, ModalFooter} from 'grilnica-reactstrap'

interface DialogModalProps {
  isOpen: boolean
  bodyText: string
  onClose: () => void | Promise<void>
  onClick: () => void | Promise<void>
}

const DialogModal: React.FC<DialogModalProps> = ({
  isOpen,
  bodyText,
  onClose,
  onClick,
}): React.ReactElement => {
  const renderContent: React.ReactElement = useMemo(() => {
    return (
      <Modal isOpen={isOpen} size={'xs'} onClose={onClose}>
        <ModalBody className={'pt-secondary'}>{bodyText}</ModalBody>
        <ModalFooter>
          <Button
            isRoundedButton
            className={'mr-secondary'}
            style={'outline'}
            size={'sm'}
            onClick={onClose}>
            Отмена
          </Button>
          <Button color={'primary'} isRoundedButton size={'sm'} onClick={onClick}>
            Да
          </Button>
        </ModalFooter>
      </Modal>
    )
  }, [bodyText, isOpen, onClick, onClose])
  return <>{renderContent}</>
}

export {DialogModal}
