import React, {useMemo} from 'react'
import {ButtonLink, Container} from 'grilnica-reactstrap'
import {bindPaymentActions} from '../../../../../store/ducks/payment'
import {connectComponent} from '../../../../../store/common'
import {getStaticUrl} from '../../../../../utils/static'

interface PaymentFailurePageProps {
  orderId: string
}

const PaymentFailurePage: React.FC<PaymentFailurePageProps> = ({orderId}): React.ReactElement => {
  const renderContent: React.ReactElement = useMemo(() => {
    return (
      <Container size={'normal'} className={'mt-x10 mb-x10'}>
        <div className={'payment'}>
          <h1 className={'h1 mb-x6'}>Что то пошло не так…</h1>
          <img
            src={getStaticUrl('/img/payment-error.svg')}
            alt={'Оплата не прошла'}
            title={'Оплата не прошла'}
            width={'100%'}
            height={'auto'}
            className={'mb-x6'}
          />
          <p className={'descriptions'}>Оплата не прошла, попробуйте оплатить снова</p>
          <ButtonLink
            to={'/place-order/' + orderId}
            color={'primary'}
            className={'btn-size'}
            isRoundedButton
            style={'outline'}>
            Попробовать снова
          </ButtonLink>
        </div>
      </Container>
    )
  }, [orderId])
  return <>{renderContent}</>
}

export default connectComponent(
  (state: any, props: any) => ({
    orderId: new URLSearchParams(props.location.search).get('orderId'),
  }),
  (dispatch: any) => ({
    paymentActions: bindPaymentActions(dispatch),
  }),
  PaymentFailurePage,
)
