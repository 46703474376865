import React from 'react'
import {Link} from 'react-router-dom'
import {OrderDTO} from 'grilnica-store-share/lib/order/OrderDTO'
import moment from 'moment-timezone'
import {OrderStatusIcon} from './OrderStatusIcon'
import {placeholderSVG} from '../../../../components/svg'
import {CardImage} from '../../restaurants/components/CardImage'
import {OrderStatusNameEnum} from 'grilnica-store-share'
import {isEmpty} from 'grilnica-share'

interface OrderItemProfileProps {
  order: OrderDTO
  className?: string
}

const OrderItemProfile: React.FC<OrderItemProfileProps> = ({
  order,
  className,
}): React.ReactElement => {
  return (
    <Link to={'/order-history/' + order.orderId}>
      <div className={'profile-order-item ' + (className ? className : '')}>
        <div className={'d-flex'}>
          <CardImage
            alt={'Изображение заказа'}
            height={68}
            width={68}
            className={'mr-i-x5 history-order-placeholder'}
            placeholder={<div className={'profile-order-item-img'}>{placeholderSVG}</div>}
            imageFileName={order.imageFileName ? order.imageFileName : ''}
          />
          <div className={'align-items-center'}>
            <p className={'order-info mb-i-x1'}>
              {order.number && 'Заказ'} <span>{order.number}</span>
            </p>
            <p className={'profile-order-time mb-i-x1'}>{OrderStatusNameEnum[order.status]}</p>
            <p className={'profile-order-time mb-i-x1'}>
              {moment(order.statusDate).format('D.MM.YYYY в HH:mm')}
            </p>
            <p className={'order-info mb-0'}>
              {!isEmpty(order.priceResult) ? order.priceResult : order.priceFull} &#8381;
            </p>
          </div>
        </div>
        <OrderStatusIcon orderStatus={order.status} />
      </div>
    </Link>
  )
}

export {OrderItemProfile}
