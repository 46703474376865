import React, {ReactNode, useCallback, useEffect, useMemo, useState} from 'react'
import {Col, Container, InputVertical, Row} from 'grilnica-reactstrap'
import {State} from '../../../../store/ducks'
import {MenuCategoryDTO, ProductDTO, ProductMap, RestaurantByProductMap} from 'grilnica-store-share'
import ProductItem from '../menu/components/product/productList/productItem/ProductItem'
import {CityDTO, isEmptyObject} from 'grilnica-share'
import {getFoundedProducts} from '../../utils/search/getFoundedProducts'
import {getUrl} from '../../utils/search/getUrl'
import {connectComponent} from '../../../../store/common'
import {isShowMenuCategory} from '../../../../utils/menu/isShowMenuCategory'
import {getRestaurantByProductMapService} from '../../../../services/restaurantProduct'
import {IsShowRestaurantByProductMap} from '../../../../types/restaurantProduct/IsShowRestaurantByProductMap'

interface SearchPageProps {
  className?: string
  productMap: ProductMap
  selectedCity: CityDTO
  categoryList: MenuCategoryDTO[]
  searchValue?: string
  isShowRestaurantByProductMap: IsShowRestaurantByProductMap
  isTerminal: boolean
}

const SearchPage: React.FC<SearchPageProps> = ({
  productMap,
  selectedCity,
  categoryList,
  searchValue,
  isShowRestaurantByProductMap,
  isTerminal,
}): React.ReactElement => {
  const [searchProductValue, setSearchProductValue] = useState<string>(searchValue)
  const [restaurantByProductMap, setRestaurantByProductMap] = useState<RestaurantByProductMap>(null)

  const getCategoryList = useMemo((): MenuCategoryDTO[] => {
    let newCategories: MenuCategoryDTO[] = []
    if (!isEmptyObject(productMap)) {
      for (let category of categoryList) {
        if (
          isShowMenuCategory(
            category,
            productMap,
            categoryList,
            isShowRestaurantByProductMap,
            isTerminal,
          )
        ) {
          newCategories.push(category)
        }
      }
    }
    return newCategories
  }, [categoryList, isShowRestaurantByProductMap, productMap, isTerminal])

  const getRestaurantByProduct = useCallback(async () => {
    const {cityId} = selectedCity
    if (cityId) {
      let newRestaurantByProductMap: RestaurantByProductMap
      newRestaurantByProductMap = await getRestaurantByProductMapService(cityId)
      setRestaurantByProductMap(newRestaurantByProductMap)
    }
  }, [selectedCity])

  useEffect(() => {
    setSearchProductValue(searchValue)
  }, [searchValue])

  useEffect(() => {
    getRestaurantByProduct()
  }, [getRestaurantByProduct])

  const renderProductCardList = useMemo(() => {
    const allFoundProducts: ProductDTO[] = []
    if (searchProductValue) {
      allFoundProducts.push(
        ...getFoundedProducts(productMap, searchProductValue, isShowRestaurantByProductMap),
      )
    }

    const items: ReactNode[] = allFoundProducts.map((item) => {
      return (
        <ProductItem
          product={item}
          key={item.productId + item.menuCategoryId}
          productMap={productMap}
          restaurantByProductMap={restaurantByProductMap}
          menuCategoryList={categoryList}
          url={getUrl(item, getCategoryList)}
        />
      )
    })

    return (
      <>
        <p className={'about-description mb-x6'}>Найдено товаров {allFoundProducts.length}</p>
        {items.length !== 0 && <Row className={'product-list'}>{items}</Row>}
      </>
    )
  }, [
    getCategoryList,
    isShowRestaurantByProductMap,
    productMap,
    restaurantByProductMap,
    searchProductValue,
  ])

  const renderContent: React.ReactElement = useMemo(() => {
    return (
      <section>
        <h1 className={'h1 mb-x6'}>Поиск</h1>
        <Row>
          <Col lg={3} md={4} sm={6}>
            <InputVertical
              id={'input-global-search'}
              name={'search'}
              type={'text'}
              label={''}
              value={searchProductValue?.toLowerCase()}
              placeholder={'найти'}
              isRequired={false}
              isRounded
              size={'sm'}
              onChange={(value: string) => {
                setSearchProductValue(value)
              }}
            />
          </Col>
        </Row>
        {renderProductCardList}
      </section>
    )
  }, [renderProductCardList, searchProductValue])

  return (
    <Container size={'normal'} className={'mt-x10 mb-x10'}>
      {renderContent}
    </Container>
  )
}

export default connectComponent(
  (state: State, props: any) => ({
    productMap: state.menu.productMap,
    searchValue: props.match.params.searchValue,
    selectedCity: state.city.selectedCity,
    categoryList: state.menu.categoryList,
    isShowRestaurantByProductMap: state.restaurantProduct.isShowRestaurantByProductMap ?? {},
    isTerminal: state.terminal?.terminalAlias?.length > 0,
  }),
  () => ({}),
  SearchPage,
)
