import React, {ReactNode, useMemo} from 'react'
import {SearchPreviewItem} from './components/SearchPreviewItem'
import {State} from '../../../store/ducks'
import {MenuCategoryDTO, ProductDTO, ProductMap} from 'grilnica-store-share'
import {getFoundedProducts} from '../../site/utils/search/getFoundedProducts'
import {getUrl} from '../../site/utils/search/getUrl'
import {connectComponent} from '../../../store/common'
import {isShowMenuCategory} from '../../../utils/menu/isShowMenuCategory'
import {IsShowRestaurantByProductMap} from '../../../types/restaurantProduct/IsShowRestaurantByProductMap'
import {isEmptyObject} from 'grilnica-share'

interface SearchPreviewProps {
  searchValue: string
  className?: string
  productMap: ProductMap
  categoryList: MenuCategoryDTO[]
  isShowPreview: boolean
  isShowRestaurantByProductMap: IsShowRestaurantByProductMap
  isTerminal: boolean
}

const SearchPreview: React.FC<SearchPreviewProps> = ({
  className,
  searchValue,
  productMap,
  categoryList,
  isShowPreview,
  isShowRestaurantByProductMap,
  isTerminal,
}): React.ReactElement => {
  const getCategoryList = useMemo((): MenuCategoryDTO[] => {
    let newCategories: MenuCategoryDTO[] = []
    if (!isEmptyObject(productMap)) {
      for (let category of categoryList) {
        if (
          isShowMenuCategory(
            category,
            productMap,
            categoryList,
            isShowRestaurantByProductMap,
            isTerminal,
          )
        ) {
          newCategories.push(category)
        }
      }
    }
    return newCategories
  }, [categoryList, isShowRestaurantByProductMap, productMap, isTerminal])

  const renderFoundProducts = useMemo(() => {
    const allFoundsProduct: ProductDTO[] = []
    if (searchValue) {
      allFoundsProduct.push(
        ...getFoundedProducts(productMap, searchValue, isShowRestaurantByProductMap),
      )
    }

    const items: ReactNode[] = allFoundsProduct.map((item, index) => {
      return (
        <SearchPreviewItem
          product={item}
          url={getUrl(item, getCategoryList)}
          key={item.productId + '_' + index}
        />
      )
    })
    if (items.length !== 0) {
      return items
    } else {
      return <p className={'search-query mb-0 p-x4'}>По вашему запросу ничего не найдено</p>
    }
  }, [getCategoryList, isShowRestaurantByProductMap, productMap, searchValue])

  if (searchValue?.length >= 2 && isShowPreview) {
    return (
      <div className={'preview ' + (className ? className : '')}>
        <div className={'search-list custom-scroll'}>{renderFoundProducts}</div>
      </div>
    )
  } else {
    return null
  }
}

export default connectComponent(
  (state: State, props: any) => ({
    productMap: state.menu.productMap,
    categoryList: state.menu.categoryList,
    isShowRestaurantByProductMap: state.restaurantProduct.isShowRestaurantByProductMap ?? {},
    isTerminal: state.terminal?.terminalAlias?.length > 0,
  }),
  () => ({}),
  SearchPreview,
)
