import {ClientAddressDTO} from 'grilnica-store-share'

const getFullAddress = (clientAddress: ClientAddressDTO) => {
  return (
    (clientAddress.city ? 'г. ' + clientAddress.city : '') +
    (clientAddress.street ? ', ' + clientAddress.street : '') +
    (clientAddress.house ? ', д. ' + clientAddress.house : '') +
    (clientAddress.flat ? ', кв. ' + clientAddress.flat : '') +
    (clientAddress.office ? ', оф. ' + clientAddress.office : '') +
    (clientAddress.floor ? ', этаж ' + clientAddress.floor : '') +
    (clientAddress.entrance ? ', подъезд ' + clientAddress.entrance : '') +
    (clientAddress.doorPhone ? ', домофон ' + clientAddress.doorPhone : '')
  )
}

export {getFullAddress}
