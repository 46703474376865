import {CityDTO} from 'grilnica-share'
import {BaseAction} from '../common/BaseAction'
import {bindActionCreators} from 'redux'

export interface CityState {
  cityList: CityDTO[]
  selectedCity: CityDTO
  isOpenSelectCityModal?: boolean

  cityId?: string
  isConfirm?: boolean
}

// eslint-disable-next-line no-shadow
export enum CityActionsTypes {
  LOAD_CITY_LIST_REQUEST = 'city/LOAD_CITY_REQUEST',
  LOAD_CITY_LIST_SUCCESS = 'city/LOAD_CITY_LIST_SUCCESS',
  LOAD_CITY_LIST_FAILED = 'city/LOAD_CITY_LIST_FAILED',
  SELECT_CITY_REQUEST = 'city/SELECT_CITY_REQUEST',
  SELECT_CITY_IN_COOKIES_REQUEST = 'city/SELECT_CITY_IN_COOKIES_REQUEST',
  SELECT_CITY_SUCCESS = 'city/SELECT_CITY_SUCCESS',
  SET_CITY_IN_COOKIES = 'city/SET_CITY_IN_COOKIES',
}

const INITIAL_STATE: CityState = {
  cityList: null,
  selectedCity: null,
  isOpenSelectCityModal: false,
  cityId: null,
  isConfirm: null,
}

export const Actions = {
  loadCityListRequest: (): BaseAction => ({
    type: CityActionsTypes.LOAD_CITY_LIST_REQUEST,
  }),
  loadCityListSuccess: (cityList: CityDTO[]): BaseAction => ({
    type: CityActionsTypes.LOAD_CITY_LIST_SUCCESS,
    payload: cityList,
  }),
  loadCityListFailed: (): BaseAction => ({
    type: CityActionsTypes.LOAD_CITY_LIST_FAILED,
  }),
  selectCityRequest: (selectedCity: CityDTO, callback: () => void): BaseAction => ({
    type: CityActionsTypes.SELECT_CITY_REQUEST,
    payload: {selectedCity, callback},
  }),

  selectCityInCookiesRequest: (
    city: CityDTO,
    isConfirm: boolean,
    callback: () => void,
  ): BaseAction => ({
    type: CityActionsTypes.SELECT_CITY_IN_COOKIES_REQUEST,
    payload: {city, isConfirm, callback},
  }),

  selectCitySuccess: (selectedCity: CityDTO): BaseAction => ({
    type: CityActionsTypes.SELECT_CITY_SUCCESS,
    payload: selectedCity,
  }),

  setCityInCookies: (cityId: string, isConfirm: boolean): BaseAction => ({
    type: CityActionsTypes.SET_CITY_IN_COOKIES,
    payload: {
      cityId: cityId,
      isConfirm: isConfirm,
    },
  }),
}

export function bindCityActions(dispatch: any) {
  return bindActionCreators(Actions, dispatch)
}

export default function city(state: CityState = INITIAL_STATE, action: BaseAction): CityState {
  switch (action.type) {
    case CityActionsTypes.LOAD_CITY_LIST_REQUEST:
      return {
        ...state,
      }
    case CityActionsTypes.LOAD_CITY_LIST_SUCCESS:
      return {
        ...state,
        cityList: action.payload,
      }
    case CityActionsTypes.LOAD_CITY_LIST_FAILED:
      return {
        ...state,
      }

    case CityActionsTypes.SELECT_CITY_SUCCESS:
      return {
        ...state,
        isOpenSelectCityModal: false,
        selectedCity: action.payload,
      }

    case CityActionsTypes.SET_CITY_IN_COOKIES:
      return {
        ...state,
        cityId: action.payload.cityId,
        isConfirm: action.payload.isConfirm,
      }

    default: {
      return {
        ...state,
      }
    }
  }
}
