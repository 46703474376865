import React, {ReactNode, useCallback, useEffect, useMemo, useState} from 'react'
import {Map} from './Map'
import {RestaurantCard} from './components/RestaurantCard'
import {Container, Loading, Row} from 'grilnica-reactstrap'
import {CityDTO, RestaurantDTO} from 'grilnica-share'
import {loadAllRestaurantListByCityIdService} from '../../../../services/restaurant'
import {State} from '../../../../store/ducks'
import {connectComponent} from '../../../../store/common'

interface RestaurantListPageProps {
  city: CityDTO
  initRestaurantList: RestaurantDTO[]
}

const RestaurantListPage: React.FC<RestaurantListPageProps> = ({
  city,
  initRestaurantList,
}): React.ReactElement => {
  const [restaurantList, setRestaurantList] = useState<RestaurantDTO[]>(initRestaurantList)

  const loadRestaurantList = useCallback(async () => {
    const restaurantList: RestaurantDTO[] = await loadAllRestaurantListByCityIdService(city.cityId)
    setRestaurantList(restaurantList)
  }, [city.cityId])

  useEffect(() => {
    loadRestaurantList()
  }, [loadRestaurantList])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const renderRestaurantlist = useMemo(() => {
    if (restaurantList) {
      const items: ReactNode[] = restaurantList.map((item) => (
        <RestaurantCard restaurant={item} key={item.restaurantId} />
      ))
      return items
    } else {
      return <Loading />
    }
  }, [restaurantList])

  const renderContent: React.ReactElement = useMemo(() => {
    return (
      <section>
        <h1 className={'h1 text-center mb-x10'}>Рестораны</h1>
        <h2 className={'h2 mb-x6'}>На карте</h2>
        <Map
          className={'mb-x10'}
          city={city}
          restaurants={restaurantList}
          isRestaurantPage={true}
        />
        <h2 className={'h2 text-center mb-x6'}>Список</h2>
        <div className={'d-flex justify-content-center w-100 mb-x6'}>
          <div className={'restaurant-alert p-x4'}>
            <p className={'alert-header mb-x2'}>Обратите внимание!</p>
            <p className={'alert-text mb-0'}>
              В ряде наших ресторанов отсутствуют некоторые позиции меню. Подробнее на странице
              интересующего ресторана.
            </p>
          </div>
        </div>
        <Row>{renderRestaurantlist}</Row>
      </section>
    )
  }, [city, renderRestaurantlist, restaurantList])

  return (
    <Container size={'normal'} className={'mt-x10 mb-x10'}>
      {renderContent}
    </Container>
  )
}

export default connectComponent(
  (state: State) => ({
    city: state.city.selectedCity,
    initRestaurantList: state.initialize.restaurantList,
  }),
  () => ({}),
  RestaurantListPage,
)
