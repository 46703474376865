import Product from './Product'
import {OptionCategoryDTO, ProductDTO} from 'grilnica-store-share'
import {getKeyByOptionIds} from '../utils/getKeyByOptionIds'
import {OptionType} from '../../../../../../../../../types/product/OptionType'
import {CheckedOptionsMap} from '../../../../../../../../../types/product/CheckedOptionsMap'
import {OptionCategoryMap} from '../../../../../../../../../types/product/OptionCategoryMap'
import {SelectedOptionsMap} from '../../../../../../../../../types/product/SelectedOptionsMap'
import {CountSelectedOptionsMap} from '../../../../../../../../../types/product/CountSelectedOptionsMap'
import {ProductVariantMap} from '../../../../../../../../../types/product/ProductVariantMap'
import {OptionMap} from '../../../../../../../../../types/product/OptionMap'
import {OptionCategoryTypeMap} from '../../../../../../../../../types/product/OptionCategoryTypeMap'
import {v4 as uuidv4} from 'uuid'

class PrepareProductEntity extends Product {
  private _optionCategoryIdsWithIcon: string[]
  private _optionCategoryIdsMainOptional: string[]
  private _optionCategoryIdsAdditionalOptional: string[]

  constructor(product: ProductDTO) {
    super(
      {
        product: product,
        selectedOptions: [],
        count: 1,
        productId: null,
        selectedProductId: uuidv4(),
        maxCountInOrder: product.maxCountInOrder,
      },
      {},
      {},
      null,
      {},
      {},
      {},
    )
    this._optionCategoryIdsWithIcon = []
    this._optionCategoryIdsMainOptional = []
    this._optionCategoryIdsAdditionalOptional = []
  }

  public sortOptionCategoryByType(optionCategory: OptionCategoryDTO): void {
    if (optionCategory.isShowIcon) {
      this.addOptionCategoryIdsByType(OptionType.WITH_ICON, optionCategory.optionCategoryId)
    } else if (optionCategory.isShowInMainTab) {
      this.addOptionCategoryIdsByType(OptionType.MAIN_TAB, optionCategory.optionCategoryId)
    } else {
      this.addOptionCategoryIdsByType(OptionType.ADDITIONAL_TAB, optionCategory.optionCategoryId)
    }
  }

  public getOptionCategoryTypeMap(): OptionCategoryTypeMap {
    let optionCategoryTypeMap: OptionCategoryTypeMap = {}
    optionCategoryTypeMap[OptionType.WITH_ICON] = this._optionCategoryIdsWithIcon
    optionCategoryTypeMap[OptionType.MAIN_TAB] = this._optionCategoryIdsMainOptional
    optionCategoryTypeMap[OptionType.ADDITIONAL_TAB] = this._optionCategoryIdsAdditionalOptional

    return optionCategoryTypeMap
  }

  public addSelectedOptionsInMaps(
    optionCategory: OptionCategoryDTO,
    optionId?: string,
    isEditable?: boolean,
  ): void {
    let countSelectedOptions: number = 0
    let checkedOptions: CheckedOptionsMap = this._selectedOptionsMap[
      optionCategory.optionCategoryId
    ]
      ? this._selectedOptionsMap[optionCategory.optionCategoryId]
      : {}
    if (optionId) {
      for (let option of optionCategory.options || []) {
        if (option.optionId === optionId) {
          checkedOptions[optionId] = true
        }
        if (checkedOptions[option.optionId]) {
          checkedOptions[option.optionId] = true
          countSelectedOptions++
        }
        if (!checkedOptions[option.optionId]) {
          checkedOptions[option.optionId] = false
        }
      }
    } else {
      for (let option of optionCategory.options || []) {
        if (option.restrictions.isDefault && !isEditable) {
          checkedOptions[option.optionId] = true
          countSelectedOptions++
        } else {
          checkedOptions[option.optionId] = false
        }
      }
    }

    this._countSelectedOptionsMap[optionCategory.optionCategoryId] = countSelectedOptions

    this._selectedOptionsMap[optionCategory.optionCategoryId] = checkedOptions
  }

  public addOptionCategoryInMap(optionCategory: OptionCategoryDTO): void {
    this._optionCategoryMap[optionCategory.optionCategoryId] = optionCategory
  }

  public createProductVariantMap(): void {
    this.initializeProductVariantMap()
    for (let productVariant of this._selectedProduct.product.productVariants) {
      // eslint-disable-next-line no-undef
      const joinOptionId: string = getKeyByOptionIds(productVariant.optionIds)
      this._productVariantMap[joinOptionId] = productVariant
    }
  }

  public createOptionMap(optionCategory: OptionCategoryDTO): void {
    for (let option of optionCategory.options || []) {
      let optionCategoryIds: string[] = [optionCategory.optionCategoryId]
      if (this._optionMap[option.optionId]) {
        this._optionMap[option.optionId].optionCategoryIds = [
          ...this._optionMap[option.optionId].optionCategoryIds,
          ...optionCategoryIds,
        ]
        this._optionMap[option.optionId].price = option.price
      } else {
        this._optionMap[option.optionId] = {
          optionCategoryIds,
          price: option.price,
          name: option.name,
          isDeleteOptions: option.isDeleteOptions,
          productId: option.productId,
          option: option,
        }
        this._optionMap[option.optionId].optionCategoryIds = optionCategoryIds
      }
      if (option.productId) {
        if (this._optionProductMap[option.productId]) {
          this._optionProductMap[option.productId] = [
            ...this._optionProductMap[option.productId],
            {
              optionCategoryId: optionCategory.optionCategoryId,
              optionId: option.optionId,
            },
          ]
        } else {
          this._optionProductMap[option.productId] = [
            {
              optionCategoryId: optionCategory.optionCategoryId,
              optionId: option.optionId,
            },
          ]
        }
      }
    }
  }

  public getOptionCategoryMap(): OptionCategoryMap {
    return this._optionCategoryMap
  }

  public getSelectedOptionsMap(): SelectedOptionsMap {
    return this._selectedOptionsMap
  }

  public getCountSelectedOptionsMap(): CountSelectedOptionsMap {
    return this._countSelectedOptionsMap
  }

  public getProductVariantMap(): ProductVariantMap {
    return this._productVariantMap
  }

  public getOptionMap(): OptionMap {
    return this._optionMap
  }

  private initializeProductVariantMap() {
    this._productVariantMap = {}
  }

  private addOptionCategoryIdsByType(optionType: OptionType, optionCategoryId: string): void {
    switch (optionType) {
      case OptionType.WITH_ICON:
        this._optionCategoryIdsWithIcon.push(optionCategoryId)
        break
      case OptionType.MAIN_TAB:
        this._optionCategoryIdsMainOptional.push(optionCategoryId)
        break
      case OptionType.ADDITIONAL_TAB:
        this._optionCategoryIdsAdditionalOptional.push(optionCategoryId)
        break
    }
  }

  public getOptionProductMap() {
    return this._optionProductMap
  }
}

export default PrepareProductEntity
