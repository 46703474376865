import React, {useCallback, useEffect, useState} from 'react'
import {Container} from 'grilnica-reactstrap'
import {ActionDTO} from 'grilnica-store-share'
import {State} from '../../../../store/ducks'
import {SaleList} from './components/SaleList'
import {connectComponent} from '../../../../store/common'
import {loadActionsListService} from '../../../../services/action'

interface SalePageProps {
  cityId: string
  initSalesList: ActionDTO[]
}

const SaleListPage: React.FC<SalePageProps> = ({cityId, initSalesList}): React.ReactElement => {
  const [saleList, setSaleList] = useState<ActionDTO[]>(initSalesList)

  const loadSalesList = useCallback(async () => {
    const salesList: ActionDTO[] = await loadActionsListService(cityId)
    setSaleList(salesList)
  }, [cityId])

  useEffect(() => {
    loadSalesList()
  }, [loadSalesList])

  return (
    <Container size={'normal'} className={'mt-x10 mb-x10'}>
      <section>
        <h1 className={'h1 mb-x4 text-center'}>Акции</h1>
        <div className={'about-description mb-x6 text-center'}>
          Мы всегда рады предложить интересные акции и выгодные предложения! <br />
          Обратите внимание! Скидки по акциям не суммируются.
        </div>
        <SaleList saleList={saleList} />
      </section>
    </Container>
  )
}

export default connectComponent(
  (state: State) => ({
    cityId: state.city.selectedCity.cityId,
    initSalesList: state.initialize.salesList,
  }),
  () => ({}),
  SaleListPage,
)
