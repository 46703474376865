import React, {useMemo} from 'react'

interface FeedbackBlockProps {
  error: string
}

const FeedbackBlock: React.FC<FeedbackBlockProps> = ({error}): React.ReactElement => {
  const renderContent: React.ReactElement = useMemo(() => {
    return <p className={'feedback-block'}>{error}</p>
  }, [error])
  return <>{renderContent}</>
}

export {FeedbackBlock}
