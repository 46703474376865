import React from 'react'
import {COLORS} from 'grilnica-reactstrap'

interface OrderIssuedSVGProps {
  isActive: boolean
  size?: 's' | 'm'
  className?: string
}

const OrderIssuedSVG: React.FC<OrderIssuedSVGProps> = ({
  isActive,
  className,
  size,
}): React.ReactElement => {
  return (
    <svg
      className={className}
      width={size === 's' ? '24' : '48'}
      height={size === 's' ? '24' : '48'}
      viewBox="0 0 48 48"
      fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.10116 22.0494C6.17849 11.6114 15.5135 4.0231 25.9514 5.10043C29.8353 5.50129 33.3272 7.04676 36.1234 9.36739C36.5484 9.7201 36.6069 10.3505 36.2542 10.7755C35.9015 11.2005 35.2711 11.2591 34.8461 10.9064C32.3452 8.83086 29.2238 7.4488 25.7461 7.08986C16.4069 6.12594 8.05452 12.9155 7.09059 22.2547C6.12667 31.5939 12.9162 39.9463 22.2554 40.9102C31.5946 41.8741 39.947 35.0846 40.9109 25.7454C41.1996 22.9485 40.7934 20.2429 39.8329 17.7975C39.631 17.2834 39.884 16.703 40.3981 16.5011C40.9121 16.2992 41.4925 16.5523 41.6944 17.0663C42.769 19.8022 43.2226 22.8285 42.9004 25.9507C41.823 36.3887 32.488 43.977 22.0501 42.8996C11.6121 41.8223 4.02383 32.4873 5.10116 22.0494Z"
        fill={isActive ? COLORS.green : COLORS.gray500}
      />
      <path
        d="M18.0953 30.0644C17.9389 29.9578 17.8202 29.8704 17.743 29.8116L17.6401 29.7311L17.6393 29.7305C17.2151 29.3785 16.5856 29.4362 16.2325 29.8598C15.879 30.2841 15.9363 30.9147 16.3606 31.2682C16.5542 31.4295 16.7606 31.575 16.9687 31.7169C17.3428 31.972 17.8816 32.3085 18.5535 32.6445C19.8905 33.3129 21.7964 34 24.0008 34C26.2051 34 28.111 33.3129 29.448 32.6445C30.1199 32.3085 30.6587 31.972 31.0328 31.7169C31.2202 31.5891 31.3671 31.4813 31.4696 31.4033C31.5208 31.3643 31.5611 31.3327 31.5898 31.3098L31.6242 31.2821L31.6348 31.2734L31.6384 31.2703L31.6398 31.2692L31.6409 31.2682C32.0652 30.9147 32.1225 30.2841 31.769 29.8598C31.3471 29.3536 30.7247 29.4569 30.2585 29.8116C30.1813 29.8704 30.0626 29.9578 29.9062 30.0644C29.5928 30.2781 29.1316 30.5666 28.5535 30.8556C27.3905 31.4371 25.7964 32 24.0008 32C22.2051 32 20.611 31.4371 19.448 30.8556C18.8699 30.5666 18.4087 30.2781 18.0953 30.0644Z"
        fill={isActive ? COLORS.green : COLORS.gray500}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.0007 15C18.1053 15 19.0007 15.8954 19.0007 17V20C19.0007 21.1046 18.1053 22 17.0007 22C15.8962 22 15.0007 21.1046 15.0007 20V17C15.0007 15.8954 15.8962 15 17.0007 15ZM30.0007 15C31.1053 15 32.0007 15.8954 32.0007 17V20C32.0007 21.1046 31.1053 22 30.0007 22C28.8962 22 28.0007 21.1046 28.0007 20V17C28.0007 15.8954 28.8962 15 30.0007 15Z"
        fill={isActive ? COLORS.green : COLORS.gray500}
      />
    </svg>
  )
}

export {OrderIssuedSVG}
