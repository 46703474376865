import React, {useMemo} from 'react'
import { Button, Loading } from "grilnica-reactstrap";

interface PrePaymentComponentProps {
  prepaymentOrder: PrepaymentOrderDTO
  formUrl: string
  onReCreatePayment: () => Promise<void>
  isShowButton: boolean
}

export interface PrepaymentOrderDTO {
  prepaymentOrderId?: string
  restaurantId: string
  systemNumber: string
  phone: string
  priceFull: number
  iikoNumber?: string
  commentary?: string
  createDate?: Date
  modifyDate?: Date
  restaurantAddress?: string
  isSuccessPayment?: boolean
  sberOrderId?: string
}

const PrePaymentComponent: React.FC<PrePaymentComponentProps> = ({
  prepaymentOrder,
  formUrl,
  onReCreatePayment,
  isShowButton,
}): React.ReactElement => {
  const renderContent = useMemo(() => {
    if (prepaymentOrder) {
      if (prepaymentOrder.isSuccessPayment) {
        return (
          <div className={'d-flex justify-content-center align-items-center'}>
            <div className={'d-flex flex-column justify-content-center align-items-center'}>
              <p>Оплата по данному заказу уже произведена</p>
            </div>
          </div>
        )
      } else if (formUrl) {
        return (
          <div className={'d-flex justify-content-center align-items-center'}>
            <div
              className={
                'd-flex flex-column justify-content-center align-items-center payment-card'
              }>
              <h3 className={'mb-small'}>Оплата заказа</h3>
              <p className={'mb-secondary'}>{prepaymentOrder.phone}</p>
              <span className={'mb-secondary'}>{prepaymentOrder.priceFull + ' '}&#8381;</span>
              {!isShowButton ? (
                <Button
                  className={'btn-size'}
                  isRoundedButton
                  color={'primary'}
                  onClick={() => {
                    window.location.href = formUrl
                  }}>
                  Оплатить
                </Button>
              ) : (
                <Button
                  className={'btn-size'}
                  isRoundedButton
                  color={'primary'}
                  onClick={() => {
                    window.location.reload()
                  }}>
                  Обновить страницу
                </Button>
              )}
            </div>
          </div>
        )
      } else {
        return <Loading />
      }
    } else {
      return <Loading />
    }
  }, [formUrl, prepaymentOrder, isShowButton])

  return <>{renderContent}</>
}

export {PrePaymentComponent}
