import React, {useMemo} from 'react'
import {OrderProductDTO} from 'grilnica-store-share/lib/order/OrderProductDTO'
import {CardImage} from '../../restaurants/components/CardImage'
import {placeholderSVG} from '../../../../components/svg'

interface OrderHistoryItemProps {
  orderProduct: OrderProductDTO
}

const OrderHistoryItem: React.FC<OrderHistoryItemProps> = ({orderProduct}): React.ReactElement => {
  const renderDescription = useMemo(() => {
    let infoItems: React.ReactNode[] = []
    let addOptions: React.ReactNode[] = []
    let deleteOptions: React.ReactNode[] = []
    if (orderProduct.options) {
      for (let option of orderProduct.options) {
        if (option.isProductVariantDefine) {
          infoItems.push(
            <p key={orderProduct.productId + option.optionId}>
              {option.optionCategoryName + ': ' + option.name}
            </p>,
          )
        } else if (option.isDeleteOptions) {
          deleteOptions.push(<p key={option.optionId + option.name}>{'- ' + option.name}</p>)
        } else {
          addOptions.push(<p key={option.optionId + option.name}>{'+ ' + option.name}</p>)
        }
      }
    }
    let keyPrefix: number = 0
    for (let key in orderProduct.selectedComboOrderProducts) {
      const product: OrderProductDTO = orderProduct.selectedComboOrderProducts[key]
      infoItems.push(
        <p className={'mb-x2'} key={key + keyPrefix}>
          {product.productName}
        </p>,
      )
      keyPrefix++
    }
    return (
      <div className={'product-modifier mb-x6'}>
        <div className={'basket-main-option mb-x3'}>{infoItems}</div>
        <div className={'add ' + (deleteOptions.length !== 0 ? 'mb-x1' : '')}>{addOptions}</div>
        <div className={'remove'}>{deleteOptions}</div>
      </div>
    )
  }, [orderProduct.options, orderProduct.productId, orderProduct.selectedComboOrderProducts])

  return (
    <div className={'d-flex order-history-item'}>
      <CardImage
        alt={'Изображение заказа'}
        height={128}
        width={128}
        className={'mr-x6'}
        placeholder={<div className={'order-history-item-img'}>{placeholderSVG}</div>}
        imageFileName={orderProduct.imageFileName ? orderProduct.imageFileName : ''}
      />
      <div className={'d-flex flex-column justify-content-between w-100'}>
        <div>
          <p className={'basket-product mb-x1'}>{orderProduct.productName}</p>
          {renderDescription}
        </div>

        <div className={'d-flex align-items-center justify-content-between basket-product'}>
          <p className={'mb-0'}>{orderProduct.count * orderProduct.price} &#8381;</p>
          <p className={'mb-0'}>{orderProduct.count} шт</p>
        </div>
      </div>
    </div>
  )
}

export {OrderHistoryItem}
