import React, {useMemo, useState} from 'react'
import {SelectedProductDTO, WeightMapDto} from 'grilnica-store-share'
import {ComboProductModal} from './editProductModal/ComboProductModal'
import {ComboProductCard} from './components/ComboProductCard'

interface ComboProductItemProps {
  selectedProduct: SelectedProductDTO
  comboGroupId: string
  comboProductId: string
  weightMap: WeightMapDto
  toggleComboProduct: (
    comboGroupId: string,
    comboProductId: string,
    selectedProduct: SelectedProductDTO,
  ) => void
}

const ComboProductItem: React.FC<ComboProductItemProps> = ({
  selectedProduct,
  comboGroupId,
  comboProductId,
  toggleComboProduct,
  weightMap,
}): React.ReactElement => {
  const [isOpenModalComboProduct, setIsOpenModalComboProduct] = useState<boolean>(false)

  const renderContent: React.ReactElement = useMemo(() => {
    if (selectedProduct?.product) {
      return (
        <ComboProductCard
          selectedProduct={selectedProduct}
          openEditProductModal={() => {
            setIsOpenModalComboProduct(true)
          }}
          changeSelectedComboProduct={() => {
            toggleComboProduct(comboGroupId, comboProductId, selectedProduct)
          }}
          renderComboProductModal={
            isOpenModalComboProduct ? (
              <ComboProductModal
                toggleComboProduct={toggleComboProduct}
                comboGroupId={comboGroupId}
                comboProductId={comboProductId}
                weightMap={weightMap}
                selectedProduct={selectedProduct}
                isOpenModal={isOpenModalComboProduct}
                onCloseModal={() => {
                  setIsOpenModalComboProduct(false)
                }}
              />
            ) : null
          }
          weightMap={weightMap}
        />
      )
    } else {
      return null
    }
  }, [
    comboGroupId,
    comboProductId,
    isOpenModalComboProduct,
    selectedProduct,
    toggleComboProduct,
    weightMap,
  ])

  return <>{renderContent}</>
}

export {ComboProductItem}
