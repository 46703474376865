import {CONSTANTS} from '../../../constants'
import {apiGet, apiPost} from '../../api'
import {TerminalDTO, TerminalConfigDTO} from 'grilnica-share'
import {TerminalRepairLogDto} from 'grilnica-store-share'

async function loadTerminalService(cityId: string): Promise<TerminalDTO> {
  return await apiGet(CONSTANTS.apiStoreUrl + '/terminal', {
    cityId: cityId,
  })
}

async function loadTerminalAuthorizationService(
  alias: string,
  cityId: string,
): Promise<TerminalDTO> {
  return await apiGet(CONSTANTS.apiStoreUrl + '/terminal', {
    headers: {
      Authorization: `Terminal ${alias}`,
    },
    cityId: cityId,
  })
}

async function loadTerminalConfigService(cityId: string): Promise<TerminalConfigDTO> {
  return await apiGet(CONSTANTS.apiStoreUrl + '/terminal/config', {
    cityId: cityId,
  })
}

async function rePaymentService(data: any, cityId: string): Promise<void> {
  return await apiPost(CONSTANTS.apiStoreUrl + '/terminal/re-payment', data, {
    cityId: cityId,
  })
}

async function reRefundService(data: any, cityId: string): Promise<void> {
  return await apiPost(CONSTANTS.apiStoreUrl + '/terminal/re-refund', data, {
    cityId: cityId,
  })
}

async function reReceiptService(data: any, cityId: string): Promise<void> {
  return await apiPost(CONSTANTS.apiStoreUrl + '/terminal/re-receipt', data, {
    cityId: cityId,
  })
}

async function cancelOrderFromTerminalService(orderId: string, cityId: string): Promise<void> {
  return await apiGet(CONSTANTS.apiStoreUrl + '/terminal/cancel-order/' + orderId, {
    cityId: cityId,
  })
}

async function terminalRepairStartService(
  data: TerminalRepairLogDto,
  cityId: string,
): Promise<void> {
  return await apiPost(CONSTANTS.apiStoreUrl + '/terminal/terminal-repair-log/start', data, {
    cityId: cityId,
  })
}

async function terminalRepairEndService(data: TerminalRepairLogDto, cityId: string): Promise<void> {
  return await apiPost(CONSTANTS.apiStoreUrl + '/terminal/terminal-repair-log/end', data, {
    cityId: cityId,
  })
}

export {
  loadTerminalService,
  loadTerminalConfigService,
  loadTerminalAuthorizationService,
  rePaymentService,
  reRefundService,
  reReceiptService,
  cancelOrderFromTerminalService,
  terminalRepairStartService,
  terminalRepairEndService,
}
