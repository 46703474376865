import React, {useCallback, useMemo} from 'react'
import {InputSelectOption, InputSelectVertical} from 'grilnica-reactstrap'
import {FavouritesRestaurant} from '../../../../../../types/restaurant/FavouritesRestaurant'
import {RestaurantDTO} from 'grilnica-share'

interface InputSelectFavouritesRestaurantProps {
  favouritesRestaurants: FavouritesRestaurant[]
  activeRestaurant: RestaurantDTO
  setPrepareOrderRestaurant: (restaurant: RestaurantDTO) => void
}

const InputSelectFavouritesRestaurant: React.FC<InputSelectFavouritesRestaurantProps> = ({
  favouritesRestaurants,
  activeRestaurant,
  setPrepareOrderRestaurant,
}): React.ReactElement => {
  const getRestaurantById = useCallback(
    (restaurantId): RestaurantDTO => {
      for (let item of favouritesRestaurants) {
        if (restaurantId === item.restaurantId) {
          return item
        }
      }
      return null
    },
    [favouritesRestaurants],
  )

  const renderContent: React.ReactElement = useMemo(() => {
    const options: InputSelectOption[] = favouritesRestaurants.map((item) => {
      return {
        label: item.name,
        value: item.restaurantId,
      }
    })
    return (
      <InputSelectVertical
        id={'input-restaurant'}
        instanceId={'input-option-restaurant'}
        options={options}
        label={'Ресторан'}
        name={'restaurant'}
        value={
          activeRestaurant && {
            label: activeRestaurant.name,
            value: activeRestaurant.restaurantId,
          }
        }
        defaultValue={
          activeRestaurant && {
            label: activeRestaurant.name,
            value: activeRestaurant.restaurantId,
          }
        }
        placeholder={'Выберите ресторан'}
        className={'mb-primary'}
        isRequired
        isSearchable={false}
        isRounded
        size={'lg'}
        onChange={(value) => {
          if (value.value !== activeRestaurant?.restaurantId) {
            setPrepareOrderRestaurant(getRestaurantById(value.value))
          }
        }}
      />
    )
  }, [activeRestaurant, favouritesRestaurants, getRestaurantById, setPrepareOrderRestaurant])
  return <>{renderContent}</>
}

export {InputSelectFavouritesRestaurant}
