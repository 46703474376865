import {CityDTO} from 'grilnica-share'
import {Actions as CityActions} from '../ducks/city'
import {Actions as ErrorActions} from '../ducks/error'
import {Actions as InitializeActions} from '../ducks/initialize'
import {call, put, select} from 'redux-saga/effects'
import {loadCityListService} from '../../services'
import {LSKeys, LSMethods} from '../../storage'
import {State} from '../ducks'
import {ErrorInitializeEnum} from '../../types/common/ErrorInitializeEnum'
import {clearBasket} from './basket/basket'
import {TerminalState} from '../terminal/types'
import {loadCityByIpService, setCitySetCookieService} from '../../services/city'
import {isServer} from '../index'

function* initializeCity() {
  const serverSelectedCity: CityDTO = yield select((state: State) => state.city.selectedCity)
  const terminalState: TerminalState = yield select((state: State) => state.terminal)
  const terminalAlias: string = terminalState?.terminalAlias
  const selectedCity: CityDTO = yield call(LSMethods.getStorageData, LSKeys.SELECTED_CITY, null)

  if (serverSelectedCity?.cityId !== selectedCity?.cityId && !terminalAlias) {
    yield call(clearBasket, {payload: false})
  }
  if (
    serverSelectedCity?.cityId &&
    (!selectedCity || selectedCity?.cityId !== serverSelectedCity?.cityId)
  ) {
    yield call(LSMethods.setStorageData, LSKeys.SELECTED_CITY, serverSelectedCity)
  }

  if (serverSelectedCity) {
    try {
      yield put(CityActions.selectCitySuccess(serverSelectedCity))
      yield call(loadCityList)
    } catch (error) {
      yield put(ErrorActions.setError(error))
    }
  } else {
    console.error('Not city initializeCity')
  }
}

function* initializeCityTerminal() {
  const serverSelectedCity: CityDTO = yield select((state: State) => state.city.selectedCity)
  const selectedCity: CityDTO = yield call(LSMethods.getStorageData, LSKeys.SELECTED_CITY, null)
  if (
    serverSelectedCity?.cityId &&
    (!selectedCity || selectedCity?.cityId !== serverSelectedCity?.cityId)
  ) {
    yield call(LSMethods.setStorageData, LSKeys.SELECTED_CITY, serverSelectedCity)
  }
  yield put(CityActions.selectCitySuccess(serverSelectedCity))
}

function* loadCityList() {
  try {
    const city: CityDTO = yield select((state: State) => state.city.selectedCity)
    yield call(getCityList, city?.cityId)
  } catch (error) {
    yield put(ErrorActions.setError(error))
  }
}

function* getCityList(cityId: string) {
  try {
    const isClient: boolean = !isServer
    let cityList: CityDTO[] = yield call(loadCityListService, null, cityId)
    if (!cityList) {
      yield put(InitializeActions.setErrorInitialize(ErrorInitializeEnum.ERROR_LOAD_SELECTED_CITY))
      cityList = []
    }
    const cityIdInCookies: string = yield select((state: State) => state.city.cityId)
    const isConfirmInCookies: string = yield select((state: State) => state.city.isConfirm)

    if (isClient && !cityIdInCookies && !isConfirmInCookies) {
      let cityByIp: CityDTO = yield call(loadCityByIpService, cityId)
      if (!cityByIp) {
        const nskFiasId: string = '8dea00e3-9aab-4d8e-887c-ef2aaa546456'
        cityByIp = cityList.find((c) => c.fiasId === nskFiasId)
      }
      yield put(CityActions.setCityInCookies(cityByIp.cityId, false))
    }

    if (isClient && isConfirmInCookies && cityIdInCookies && cityIdInCookies !== cityId) {
      yield call(setCitySetCookieService, null, cityId, true)
      yield put(CityActions.setCityInCookies(cityId, true))
    }

    yield call(setCityList, cityList)
  } catch (error) {
    const cityList: CityDTO[] = yield call(LSMethods.getStorageData, LSKeys.CITY_LIST, null)
    if (cityList) {
      yield put(CityActions.loadCityListSuccess(cityList))
    } else {
      throw error
    }
  }
}

function* setCityList(cityList: CityDTO[]) {
  const selectedCity: CityDTO = yield call(LSMethods.getStorageData, LSKeys.SELECTED_CITY, null)
  if (cityList.length !== 0) {
    if (!selectedCity) {
      yield put(CityActions.selectCitySuccess(cityList[0]))
      yield call(LSMethods.setStorageData, LSKeys.SELECTED_CITY, cityList[0]) //TODO:: нужно модалку на выбор города изначального а то не грузится
    } else {
      const newSelectedCity = cityList.find((item) => item.cityId === selectedCity.cityId)
      yield put(CityActions.selectCitySuccess(newSelectedCity))
      yield call(LSMethods.setStorageData, LSKeys.SELECTED_CITY, newSelectedCity)
    }
  }
  yield put(CityActions.loadCityListSuccess(cityList))
  yield call(LSMethods.setStorageData, LSKeys.CITY_LIST, cityList)
}

function* selectCity({payload}: any) {
  const city: CityDTO = payload.selectedCity
  const callback: () => void = payload.callback
  yield call(LSMethods.setStorageData, LSKeys.SELECTED_CITY, city)
  // yield put(InitializeActions.toggleInitialize(true))
  yield put(CityActions.selectCitySuccess(city))
  yield call(clearBasket, {payload: false})
  yield call(callback)
}

function* selectCityInCookies({payload}: any) {
  const city: CityDTO = payload.city
  const isConfirm: boolean = payload.isConfirm
  const callback: () => void = payload.callback
  yield call(setCitySetCookieService, null, city.cityId, isConfirm)
  yield call(callback)
}

export {loadCityList, initializeCity, initializeCityTerminal, selectCity, selectCityInCookies}
