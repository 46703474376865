import {SelectedProductDTO} from '../../types/product/SelectedProductDTO'

const getCountItemBasket = (selectedProducts: SelectedProductDTO[]): number => {
  let count: number = 0
  if (selectedProducts) {
    for (let item of selectedProducts) {
      count += item.count ? item.count : 0
    }
  }
  return count
}

export {getCountItemBasket}
