import React from 'react'
import {Container} from 'grilnica-reactstrap'
import {getStaticUrl} from '../../../../utils/static'

interface AboutCompanyPageProps {}

const AboutCompanyPage: React.FC<AboutCompanyPageProps> = ({}): React.ReactElement => {
  const renderBlockInformationAboutUs = () => {
    return (
      <>
        <h1 className={'h1 mb-x6 text-center'}>О нас</h1>
        <div className={'about-description mb-x8'}>
          <p className={'mb-x4'}>«Мы предлагаем высокое качество за приемлемую цену»</p>
          <p className={'mb-x4'}>
            «Грильница» — сеть ресторанов быстрого питания. Мы заботимся о вас, используя только
            продукты высокого качества. К примеру, для приготовления блюд на гриле мы берем только
            охлажденное мясо, в состав хот-догов включаем немецкую сардельку, а тесто для пиццы наши
            повара делают вручную.
          </p>
          <p className={'mb-x4'}>
            Кстати о поварах. Все они — мастера своего дела, профессионалы, которые всегда стараются
            превзойти самих себя.
          </p>
          <p className={'mb-x4'}> «Как ни считай — выгодно»</p>
          <p>
            Мы предлагаем своим любимым клиентам не только прийти к нам в гости, но и отведать
            качественную еду прямо не выходя из дома и офиса. У нас широкий ассортимент блюд из
            продуктов высокого качества за разумную цену, при этом вы оплачиваете розничную
            стоимость и стоимость доставки.
          </p>
        </div>
        <div className={'d-flex align-items-center mb-x8'}>
          <img
            alt={'Лучший фаст-фуд ресторан России 2019'}
            title={'Лучший фаст-фуд ресторан России 2019'}
            src={getStaticUrl('/img/medal.svg')}
            className={'mr-x4'}
          />
          <span className={'d-flex flex-column'}>
            Лучший фаст-фуд <br />
            ресторан России 2019
          </span>
        </div>
      </>
    )
  }

  const renderBlockBankDetails = () => {
    return (
      <div className={'mb-x8'}>
        <h2 className={'h2 text-center mb-x6'}>Реквизиты</h2>
        <dl className={'sec-subtitle1 text-gray-900'}>
          <dd>АКЦИОНЕРНОЕ ОБЩЕСТВО «ВКУСНАЯ ФРАНШИЗА»</dd>
          <br />
          <dd>
            Юридический адрес: 630099, Новосибирская область, г Новосибирск, Журинская ул, д. 14
          </dd>
          <br />
          <dd>Телефон: +7 (3833) 631-793</dd>
          <dd>Телефон: +7 (3852) 999-503</dd>
          <dd>E-mail: fr@grilnica.ru</dd>
          <br />
          <dd>ИНН/КПП: 2221243799/540601001</dd>
          <dd>ОГРН: 1192225013958 от 16 апреля 2019 г.</dd>
          <dd>Расчётный счёт: 40702810260090005096</dd>
          <dd>Корреспондентский счёт: 30101810250040000883</dd>
          <dd>БИК банка: 045004883</dd>
          <dd>Банк: Сибирский филиал АО Банк Интеза</dd>
        </dl>
      </div>
    )
  }

  const renderBlockRent = () => {
    return (
      <div className={'mb-x8'} id={'rent'}>
        <h2 className={'h2 text-center mb-x6'}>Аренда</h2>
        <p className={'about-description mb-0'}>
          Рассмотрим Ваши предложения по коммерческой недвижимости:
          <br />
          • Площадь - 70-300 м2
          <br />
          • Первый этаж, Первая линия
          <br />
          • Центральные части городов, Районные торговые центры, отдельно стоящие коммерческие объекты
          <br />
          • Аренда/покупка земельных участков
          <br />
          • Аренда/покупка объектов коммерческой недвижимости
          <br />
          Отправляйте Ваши предложения на real@grilnica.ru
        </p>
      </div>
    )
  }

  const renderBlockSupply = () => {
    return (
      <div className={'mb-x8'} id={'supply'}>
        <h2 className={'h2 text-center mb-x6'}>Снабжение</h2>
        <p className={'about-description mb-0'}>
          Предложения о сотрудничестве можно направлять в отдел материально-технического
          обеспечения, используя следующие контакты:
          <br />
          E-mail: mto@grilnica.ru
        </p>
      </div>
    )
  }

  const renderBlockVacancy = () => {
    return (
      <>
        <h2 className={'h2 text-center mb-x6'}>Вакансии</h2>
        <p className={'about-description mb-0'}>
          Подробнее о работе в Грильнице вы можете посмотреть&nbsp;
          <a className={'color-link-text'} href={'https://career.grilnica.ru/'}>
            здесь
          </a>
        </p>
      </>
    )
  }

  return (
    <Container size={'normal'} className={'mt-x10 mb-x10'}>
      <section>
        {renderBlockInformationAboutUs()}
        {renderBlockBankDetails()}
        {renderBlockRent()}
        {renderBlockSupply()}
        {renderBlockVacancy()}
      </section>
    </Container>
  )
}

export {AboutCompanyPage}
