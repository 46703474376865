import {BaseAction} from '../../common/BaseAction'
import {bindActionCreators} from 'redux'
import {ActionActionsType} from './types'
import {ActionDTO} from 'grilnica-store-share'
import {ActionDefinitionsMap} from '../types/ActionDefinitionsMap'
import {ActionConditionsMap} from '../types/ActionConditionsMap'
import {ActionDefinition} from '../types/ActionDefinition'

export const Actions = {
  loadActionsRequest: (): BaseAction => ({
    type: ActionActionsType.LOAD_ACTIONS_REQUEST,
  }),

  setActions: (
    actions: ActionDTO[],
    definitionsMap: ActionDefinitionsMap,
    conditionsMap: ActionConditionsMap,
  ): BaseAction => ({
    type: ActionActionsType.SET_ACTIONS,
    payload: {actions, definitionsMap, conditionsMap},
  }),

  selectActionRequest: (actionId: string, moveToActionScreen: () => void): BaseAction => ({
    type: ActionActionsType.SELECT_ACTION_REQUEST,
    payload: {actionId, moveToActionScreen},
  }),

  setSelectedAction: (selectedAction: ActionDTO): BaseAction => ({
    type: ActionActionsType.SET_SELECTED_ACTION,
    payload: selectedAction,
  }),

  clearSelectedAction: (): BaseAction => ({
    type: ActionActionsType.SET_SELECTED_ACTION,
    payload: null,
  }),

  updateActionsRequest: (): BaseAction => ({
    type: ActionActionsType.UPDATE_ACTIONS_REQUEST,
  }),

  setIsRequestLoadAction: (isRequest: boolean): BaseAction => ({
    type: ActionActionsType.SET_IS_REQUEST_LOAD_ACTIONS,
    payload: isRequest,
  }),

  setIsRequestUpdateActions: (isUpdateActions: boolean): BaseAction => ({
    type: ActionActionsType.SET_IS_REQUEST_UPDATE_ACTIONS,
    payload: isUpdateActions,
  }),

  setAvailableDefinitions: (definitions: ActionDefinition[]): BaseAction => ({
    type: ActionActionsType.SET_AVAILABLE_DEFINITIONS,
    payload: definitions,
  }),

  setSelectedCouponDefinitionIdRequest: (id: string): BaseAction => ({
    type: ActionActionsType.SET_SELECTED_COUPON_DEFINITION_ID_REQUEST,
    payload: id,
  }),

  setSelectedCouponDefinitionId: (id: string): BaseAction => ({
    type: ActionActionsType.SET_SELECTED_COUPON_DEFINITION_ID,
    payload: id,
  }),
}

export function bindActionActions(dispatch: any) {
  return bindActionCreators(Actions, dispatch)
}
