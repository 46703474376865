import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react'
import MenuNavigationBig from './MenuNavigationBig'
import MenuNavigation from './MenuNavigation'
import PageHeader from '../PageHeader'

interface HeaderMenuProps {}

const HeaderMenu: React.FC<HeaderMenuProps> = ({}): React.ReactElement => {
  const [searchValue, setSearchValue] = useState<string>('')
  const [heightHeader, setHeightHeader] = useState<number>(0)
  const [widthScreen, setWidthScreen] = useState<number>(0)
  const heightRef = useRef(null)

  const handleChangeSearchValue = useCallback(
    (value: string) => {
      setSearchValue(value)
    },
    [setSearchValue],
  )

  useEffect(() => {
    setHeightHeader(heightRef.current.clientHeight)
  }, [])

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setWidthScreen(document.body.clientWidth)
    }
  }, [])

  if (typeof window !== 'undefined') {
    window.onscroll = () => {
      if (document.body.clientWidth > 984) {
        if (
          document.body.scrollTop > heightHeader - 16 || //TODO::  disabled banner -16
          document.documentElement.scrollTop > heightHeader - 16 //TODO::  disabled banner - 16
        ) {
          document.getElementById('header-menu-nav').className = 'header-menu-nav'
        } else {
          document.getElementById('header-menu-nav').className = 'hidden-menu'
        }
      }
    }
  }

  const renderContent: React.ReactElement = useMemo(() => {
    return (
      <>
        <div id={'header-block'} ref={heightRef}>
          <PageHeader
            subsystem={'subsystem'}
            searchValue={searchValue}
            handleChangeSearchValue={handleChangeSearchValue}
          />
          <MenuNavigationBig id={'header-menu-big'} />
        </div>
        <MenuNavigation
          className={widthScreen >= 984 ? 'd-none' : 'show-small-screen'}
          searchValue={searchValue}
          handleChangeSearchValue={handleChangeSearchValue}
        />
      </>
    )
  }, [handleChangeSearchValue, searchValue, widthScreen])

  return <React.Fragment>{renderContent}</React.Fragment>
}

export {HeaderMenu}
