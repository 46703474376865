import {put, call, select} from 'redux-saga/effects'
import {Actions as AddressActions} from '../ducks/address'
import {Actions as ErrorActions} from '../ducks/error'
import {CityDTO, GRILNICA_COMPANY_ID, ResponseError} from 'grilnica-share'
import {State} from '../ducks'
import {AddressInMap} from '../../types/address/AddressInMap'
import {
  AreaOfDeliveryDTO,
  ClientAddressDTO,
  DeliveryInfoDTO,
  DeliveryInfoRequestDTO,
} from 'grilnica-store-share'
import {DeliveryInfo} from '../../types/order/DeliveryInfo'
import {LatLng} from '../../types/address/LatLng'
import {
  getAddressByCoordinatesService,
  getDeliveryPriceService,
  loadAreaOfDeliveryListService,
  searchAddressByAddressStrService,
  searchAddressesBySearchValueService,
} from '../../services/address'
import {LSKeys, LSMethods} from '../../storage'

function* initializeAddress() {
  yield call(immediateLoadAreaRegionOfDeliveryList)
}

function* changeCoordinates({payload}: any) {
  const coordinates: LatLng = payload

  yield put(AddressActions.setCoordinates(coordinates))
  try {
    const address: any = yield call(addressDetection, coordinates)

    let newSelectedAddressInDeliveryMap: AddressInMap = {
      streetKladrId: null,
      streetType: null,
      street: null,
      house: null,
      houseFiasId: null,
      building: null,
      isDefault: null,
      cityId: null,
      city: null,
      clientId: null,
      latitude: coordinates.latitude,
      longitude: coordinates.longitude,
    }

    let newAddressSearchValue: string = null
    const city: CityDTO = yield select((state: State) => state.city.selectedCity)
    if (
      address &&
      address.suggestions.length !== 0 &&
      address.suggestions[0].data?.city_fias_id === city.fiasId
    ) {
      newSelectedAddressInDeliveryMap.city = address.suggestions[0].data.city
      newSelectedAddressInDeliveryMap.streetKladrId = address.suggestions[0].data.street_kladr_id
      newSelectedAddressInDeliveryMap.streetType = address.suggestions[0].data.street_type_full
      newSelectedAddressInDeliveryMap.street = address.suggestions[0].data.street
      newSelectedAddressInDeliveryMap.house = address.suggestions[0].data.house
      newSelectedAddressInDeliveryMap.houseFiasId = address.suggestions[0].data.house_fias_id
      newSelectedAddressInDeliveryMap.value = address.suggestions[0].value
      newAddressSearchValue = address.suggestions[0].value
    }

    yield put(AddressActions.setSelectedAddressInDeliveryMap(newSelectedAddressInDeliveryMap))
    yield put(AddressActions.setAddressSearchValue(newAddressSearchValue))
  } catch (e) {
    yield put(ErrorActions.setErrorRequest(e))
  }
}

function* addressDetection(coordinates: LatLng) {
  const city: CityDTO = yield select((state: State) => state.city.selectedCity)
  const detectionCoordinates: LatLng[] = [
    coordinates,
    {
      latitude: coordinates.latitude + 0.0001,
      longitude: coordinates.longitude + 0.0001,
    },
    {
      latitude: coordinates.latitude + 0.0001,
      longitude: coordinates.longitude - 0.0001,
    },
    {
      latitude: coordinates.latitude - 0.0001,
      longitude: coordinates.longitude - 0.0001,
    },
    {
      latitude: coordinates.latitude - 0.0001,
      longitude: coordinates.longitude + 0.0001,
    },
  ]
  for (let coordinates of detectionCoordinates) {
    const address: any = yield call(getAddressByCoordinatesService, coordinates, city?.cityId)
    if (
      address &&
      address.suggestions.length !== 0 &&
      address.suggestions[0].data?.city_fias_id === city.fiasId
    ) {
      return address
    }
    return null
  }
}

function* clearSelectedAddressAndCoordinates() {
  yield put(AddressActions.clearSelectedAddressAndCoordinatesSuccess())
}

function* changeSearchValue({payload}: any) {
  const searchValue: string = payload
  yield put(AddressActions.setAddressSearchValue(searchValue))
  yield call(searchAddressesBySearchValue, searchValue)
}

function* searchAddressesBySearchValue(searchValue: string) {
  const selectedCity: CityDTO = yield select((state: State) => state.city.selectedCity)

  try {
    let addresses: any = yield call(
      searchAddressesBySearchValueService,
      searchValue,
      selectedCity.fiasId,
      selectedCity?.cityId,
    )

    const newFoundAddresses: AddressInMap[] = []
    for (let address of addresses.suggestions) {
      if (address.data.city && (address.data.street || address.data.house)) {
        let foundAddress: AddressInMap = {
          cityId: null,
          clientId: null,
          isDefault: null,
          city: address.data.city,
          streetType: address.data.street_type_full,
          streetKladrId: address.data.street_kladr_id,
          street:
            (address.data.settlement_with_type ? address.data.settlement_with_type + ', ' : '') +
            address.data.street_with_type,
          house: address.data.house,
          houseFiasId: address.data.house_fias_id,
          building: address.data.block,
          value: address.value,
          coordinates: {
            latitude: parseFloat(address.data.geo_lat),
            longitude: parseFloat(address.data.geo_lon),
          },
          latitude: parseFloat(address.data.geo_lat),
          longitude: parseFloat(address.data.geo_lon),
        }
        newFoundAddresses.push(foundAddress)
      }
    }
    yield put(AddressActions.setFoundAddresses(newFoundAddresses))
  } catch (e) {
    yield put(ErrorActions.setErrorRequest(e))
  }
}

function* selectAddressFromFoundList({payload}: any) {
  const addressIndex: number = payload
  const foundAddresses: AddressInMap[] = yield select(
    (state: State) => state.address.foundAddresses,
  )
  const newFoundAddresses: AddressInMap[] = [...foundAddresses]
  const newAddress: AddressInMap = newFoundAddresses[addressIndex]

  yield put(AddressActions.setAddressSearchValue(newAddress.value))
  yield put(AddressActions.setSelectedAddressInDeliveryMap(newAddress))
  yield put(AddressActions.setCoordinates(newAddress.coordinates))
}

function* loadAreaRegionOfDeliveryList() {
  try {
    const city: CityDTO = yield select((state: State) => state.city.selectedCity)

    if (city) {
      const areaOfDeliveryList: AreaOfDeliveryDTO[] = yield call(
        loadAreaOfDeliveryListService,
        city.cityId,
      )
      yield call(LSMethods.setStorageData, LSKeys.AREA_OF_DELIVERY_LIST, areaOfDeliveryList)

      yield put(AddressActions.loadAreaRegionOfDeliverySuccess(areaOfDeliveryList))
    }
  } catch (e) {
    yield put(ErrorActions.setError(e))
  }
}

function* immediateLoadAreaRegionOfDeliveryList() {
  try {
    yield call(loadAreaRegionOfDeliveryList)
  } catch (e) {
    yield put(ErrorActions.setError(e))
  }
}

function* backgroundLoadAreaRegionOfDeliveryList() {
  try {
    const city: CityDTO = yield select((state: State) => state.city.selectedCity)
    if (city) {
      const areaOfDeliveryList: AreaOfDeliveryDTO[] = yield call(
        loadAreaOfDeliveryListService,
        city.cityId,
      )

      const oldAreaOfDeliveryList: AreaOfDeliveryDTO = yield call(
        LSMethods.getStorageData,
        LSKeys.AREA_OF_DELIVERY_LIST,
        [],
      )

      if (JSON.stringify(areaOfDeliveryList) !== JSON.stringify(oldAreaOfDeliveryList)) {
        yield call(LSMethods.setStorageData, LSKeys.AREA_OF_DELIVERY_LIST, areaOfDeliveryList)

        yield put(AddressActions.loadAreaRegionOfDeliverySuccess(areaOfDeliveryList))
      }
    }
  } catch (e) {
    console.log(e.message + ' backgroundLoadAreaRegionOfDeliveryList')
  }
}

function* getAddressInfoByAddress(address: ClientAddressDTO) {
  try {
    const city: CityDTO = yield select((state: State) => state.city.selectedCity)
    const searchStrAddress: string = address.city + ' ' + address.street + ' ' + address.house

    const findAddress: any = yield call(
      searchAddressByAddressStrService,
      searchStrAddress,
      city?.cityId,
    )
    if (findAddress[0].street && findAddress[0].house && findAddress[0].house_fias_id) {
      const coordinates: LatLng = {
        latitude: parseFloat(findAddress[0].geo_lat),
        longitude: parseFloat(findAddress[0].geo_lon),
      }
      const streetType: string = findAddress[0].street_type_full
      const streetKladrId: string = findAddress[0].street_kladr_id
      return {coordinates, streetType, streetKladrId}
    } else {
      throw new ResponseError(
        404,
        'Адрес не найден. Проверьте введенные значения или выберите адрес на карте',
      )
    }
  } catch (e) {
    throw e
  }
}

function* getDeliveryPriceInMap() {
  const coordinates: LatLng = yield select((state: State) => state.address.coordinates)
  const deliveryPrice: DeliveryInfo = yield call(
    getDeliveryPrice,
    coordinates.latitude,
    coordinates.longitude,
  )
  yield put(AddressActions.setDeliveryPriceInMap(deliveryPrice?.price))
}

function* getDeliveryPrice(
  latitude: number,
  longitude: number,
  expectedDeliveryTime?: Date,
  priceFull?: number,
) {
  const city: CityDTO = yield select((state: State) => state.city.selectedCity)

  const deliveryInfoRequest: DeliveryInfoRequestDTO = {
    cityId: city.cityId,
    expectedDeliveryTime,
    latitude,
    longitude,
    companyId: GRILNICA_COMPANY_ID,
    orderCreatedDate: null,
  }

  try {
    const deliveryInfo: DeliveryInfoDTO = yield call(getDeliveryPriceService, deliveryInfoRequest)
    let newDeliveryInfo: DeliveryInfo = null
    if (
      priceFull &&
      deliveryInfo?.priceFreeDelivery &&
      priceFull >= deliveryInfo?.priceFreeDelivery
    ) {
      newDeliveryInfo = {
        price: deliveryInfo?.mainPrice, //Цена на доставку не становится 0, приходит дополнительное поле скидки на доставку
        restaurantId: deliveryInfo?.restaurantId,
        deliveryTime: deliveryInfo?.deliveryTime,
        isSeparateDeliveryPayment: deliveryInfo?.isSeparateDeliveryPayment,
      }
    } else {
      newDeliveryInfo = {
        price: deliveryInfo?.mainPrice,
        restaurantId: deliveryInfo?.restaurantId,
        deliveryTime: deliveryInfo?.deliveryTime,
        isSeparateDeliveryPayment: deliveryInfo?.isSeparateDeliveryPayment,
      }
    }
    return newDeliveryInfo
  } catch (e) {
    yield put(ErrorActions.setError(e, 'Ошибка определения цены'))
    return null
  }
}

export {
  changeCoordinates,
  clearSelectedAddressAndCoordinates,
  searchAddressesBySearchValue,
  changeSearchValue,
  selectAddressFromFoundList,
  immediateLoadAreaRegionOfDeliveryList,
  backgroundLoadAreaRegionOfDeliveryList,
  getAddressInfoByAddress,
  getDeliveryPriceInMap,
  getDeliveryPrice,
  initializeAddress,
}
