import React from 'react'
import {Col} from 'grilnica-reactstrap'
import {ActionDTO} from 'grilnica-store-share'
import {Link} from 'react-router-dom'
import {CONSTANTS} from '../../../../../../constants'
import {exportVariantSVG} from '../../../../components/svg/SystemIcons'

interface SaleCardProps {
  sale: ActionDTO
  onChangePost?: () => void
}

const SaleCard: React.FC<SaleCardProps> = ({onChangePost, sale}): React.ReactElement => {
  return (
    <Col md={4} sm={6}>
      <Link to={'/actions/' + sale.alias}>
        <div className={'sale-card mb-x6'}>
          {/*{isServer ? (*/}
          {/*  <img*/}
          {/*    src={getFullUrl('/img/placeholder.svg')}*/}
          {/*    alt={'Фото акции ' + sale.name}*/}
          {/*    width={'100%'}*/}
          {/*    height={'auto'}*/}
          {/*  />*/}
          {/*) : (*/}
          {/*<CardImage*/}
          {/*  key={'action-' + sale.name}*/}
          {/*  id={'action-' + sale.name}*/}
          {/*  alt={'Фото акции ' + sale.name}*/}
          {/*  imageFileName={sale.listImageFilenameLight}*/}
          {/*  placeholder={*/}
          <img
            key={'action-' + sale.name} //TODO:: оставить подгрузку или просто img
            id={'action-' + sale.name}
            // src={getFullUrl('/img/placeholder.svg')}
            src={CONSTANTS.cdnUrl + '/' + sale.infoImageFilenameLight}
            alt={sale.name}
            title={sale.name}
            width={'100%'}
            height={'auto'}
          />
          {/*  }*/}
          {/*/>*/}
          {/*)}*/}

          <div className={'d-flex align-items-center justify-content-between p-x4'}>
            <p className={'card-sale-name mb-0'}>{sale.name}</p>
            {/*Понадобится немного позже*/}
            {/*<ButtonIcon*/}
            {/*  icon={<Icon path={exportVariantSVG} color={COLORS.gray600} />}*/}
            {/*  title={'Поделиться'}*/}
            {/*  onClick={onChangePost}*/}
            {/*/>*/}
          </div>
        </div>
      </Link>
    </Col>
  )
}

export {SaleCard}
