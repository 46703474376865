import {ProductVariantDTO} from 'grilnica-store-share'

const getActiveWeight = (
  productVariants: ProductVariantDTO[],
  productVariantId: string,
): number => {
  for (let productVariant of productVariants || []) {
    if (productVariant.productVariantId === productVariantId) {
      return productVariant.weight && productVariant.weight !== 0 ? productVariant.weight : null
    }
  }
}
export {getActiveWeight}
