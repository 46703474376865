import React, {useCallback, useEffect} from 'react'
import {Button} from 'grilnica-reactstrap'

interface CardModalProps {
  onClose: () => void | Promise<void>
}

const CardModal: React.FC<CardModalProps> = ({onClose, children}): React.ReactElement => {
  useEffect(() => {}, [])

  const onClickContent = useCallback((evt: any) => {
    evt.stopPropagation()
  }, [])

  const onScroll = useCallback((evt: any) => {
    evt.stopPropagation()
  }, [])

  return (
    <div className={'card-modal '} onClick={onClose}>
      <div className={'card-modal-content '} onClick={onClickContent} onScroll={onScroll}>
        <div className={'custom-scroll'}>{children}</div>
        <div className={'card-modal-footer '}>
          <Button color={'primary'} size={'sm'} style={'text'} isRoundedButton onClick={onClose}>
            ОК
          </Button>
        </div>
      </div>
    </div>
  )
}

export {CardModal}
