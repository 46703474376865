import {apiGet} from '../../api'
import {CONSTANTS} from '../../../constants'
import {RestaurantByProductMap} from 'grilnica-store-share'

async function getRestaurantByProductMapService(
  cityId: string,
  errorActions?: any,
): Promise<RestaurantByProductMap> {
  try {
    return await apiGet(CONSTANTS.apiStoreUrl + '/restaurant-product/hierarchy/' + cityId, {
      cityId: cityId,
    })
  } catch (error) {
    if (errorActions) {
      errorActions.setError(error)
    } else {
      console.error('Error getRestaurantByProductMapService error')
      throw error
    }
  }
}

export {getRestaurantByProductMapService}
