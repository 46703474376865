import {ObjectUrlMap} from 'grilnica-store-share'

function getObjectUrlSeoContent(
  url: string,
  objectUrlMap: ObjectUrlMap,
  cityName: string,
  field:
    | 'title'
    | 'description'
    | 'keywords'
    | 'ogTitle'
    | 'ogDescription'
    | 'ogImage'
    | 'canonicalUrl',
): string | undefined {
  if (url === '') {
    url = '/'
  }
  if (objectUrlMap && objectUrlMap[url] && objectUrlMap[url].objectUrlSeo) {
    let title: string = objectUrlMap[url].objectUrlSeo[field]
    if (title?.length > 0) {
      title = title.replace(/{=cityName}/gi, cityName)
    }
    return title
  } else {
    return undefined
  }
}

export {getObjectUrlSeoContent}
