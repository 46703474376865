import {useEffect, useState} from 'react'

function useDebounce<T>(value: T, wait = 300): T {
  const [debounceValue, setDebounceValue] = useState<T>(value)

  useEffect(() => {
    const timer = setTimeout(() => {
      setDebounceValue(value)
    }, wait)
    return (): void => clearTimeout(timer)
  }, [value, wait])

  return debounceValue
}

export {useDebounce}
