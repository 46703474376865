import React from 'react'
import {Col, Container, Row} from 'grilnica-reactstrap'
import {getStaticUrl} from '../../../utils/static'

interface AppLinksProps {}

const AppLinks: React.FC<AppLinksProps> = ({}): React.ReactElement => {
  return (
    <Container size={'normal'} className={'mb-x4 mt-x8'}>
      <Row className={'align-items-center justify-content-center'}>
        <Col
          xs={12}
          md={4}
          lg={{size: 3, offset: 1}}
          xl={{size: 3, offset: 2}}
          className={'d-flex flex-column'}>
          <a className={'app-description mb-x8'} href={'/'}>
            Открой для себя лучший опыт заказа фастфуда с нашим мобильным приложением
          </a>
          <p className={'access-stores mb-i-x7'}>Скачайте бесплатно в магазинах</p>
          <div className={'d-flex justify-content-center'}>
            <a href={'https://apps.apple.com/ru/app/grilnicaapp/id1536865948'}>
              <img
                alt={'Приложение Грильница для IOS'}
                title={'Приложение Грильница для IOS'}
                src={getStaticUrl('/img/app-store-download.svg')}
                width={120}
                height={40}
                className={'mr-x4'}
              />
            </a>
            <a href={'https://play.google.com/store/apps/details?id=ru.grilnica.mobile'}>
              <img
                alt={'Приложение Грильница для Android'}
                title={'Приложение Грильница для Android'}
                src={getStaticUrl('/img/google-play-download.svg')}
                width={135}
                height={40}
              />
            </a>
          </div>
        </Col>
        <Col
          xs={12}
          md={{size: 5, offset: 1}}
          lg={{size: 5, offset: 0}}
          className={'logo-app-container'}>
          <img
            src={getStaticUrl('/img/logo-links-app.svg')}
            alt={'Приложение "Грильница"'}
            title={'Приложение "Грильница"'}
            className={'logo-links-app'}
          />
        </Col>
      </Row>
    </Container>
  )
}

export {AppLinks}
