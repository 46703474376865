import React from 'react'
import {BreadcrumbDTO, Breadcrumbs, Container, Divider} from 'grilnica-reactstrap'
import {MenuCategoryDTO, ProductDTO, ProductMap} from 'grilnica-store-share'
import {AliasObjectMap} from '../../../../../../../types/common/AliasObjectMap'
import {getItemByKey} from 'grilnica-share'

interface IMenuBreadcrumbsProps {
  alias: string
  childAlias: string
  productAlias: string
  aliasProductCategoryMap: AliasObjectMap
  productMap: ProductMap
  categoryList: MenuCategoryDTO[]
}

const MenuBreadcrumbs: React.FC<IMenuBreadcrumbsProps> = ({
  alias,
  childAlias,
  productAlias,
  aliasProductCategoryMap,
  categoryList,
  productMap,
}): React.ReactElement => {
  const items: BreadcrumbDTO[] = [
    {
      breadcrumbId: '0',
      name: 'Меню',
      link: '/',
    },
  ]
  if (alias && aliasProductCategoryMap[alias]) {
    let menuCategoryByChildAlias
    const menuCategoryByAlias = getItemByKey<MenuCategoryDTO>(
      'menuCategoryId',
      categoryList,
      aliasProductCategoryMap[alias].objectId,
    )
    if (menuCategoryByAlias) {
      items.push({
        breadcrumbId: menuCategoryByAlias.menuCategoryId,
        name: menuCategoryByAlias.name,
        link: `/menu/${menuCategoryByAlias.alias}`,
      })
    }
    if (childAlias && aliasProductCategoryMap[childAlias]) {
      menuCategoryByChildAlias = getItemByKey(
        'menuCategoryId',
        categoryList,
        aliasProductCategoryMap[childAlias].objectId,
      )
      if (menuCategoryByChildAlias) {
        items.push({
          breadcrumbId: menuCategoryByChildAlias.menuCategoryId,
          name: menuCategoryByChildAlias.name,
          link: `/menu/${menuCategoryByAlias.alias}/${menuCategoryByChildAlias.alias}`,
        })
      }
    }

    if (productAlias) {
      const products: ProductDTO[] =
        childAlias && menuCategoryByChildAlias
          ? productMap[menuCategoryByChildAlias.menuCategoryId]
          : productMap[menuCategoryByAlias.menuCategoryId]
      if (
        products &&
        products.length > 0 &&
        aliasProductCategoryMap[childAlias ? childAlias : alias][productAlias]
      ) {
        const productId = aliasProductCategoryMap[childAlias ? childAlias : alias][productAlias]
        const product: ProductDTO = getItemByKey('productId', products, productId)
        if (product) {
          items.push({
            breadcrumbId: product.productId,
            name: product.name,
            link: `/menu/${menuCategoryByAlias.alias}${
              menuCategoryByChildAlias ? '/' + menuCategoryByChildAlias.alias : ''
            }/${product.alias}`,
          })
        }
      }
    }
  }
  return (
    <div className={'d-flex flex-column product-breadcrumbs-wrapper'}>
      <Container size={'normal'} className={'product-breadcrumbs-container'}>
        <Breadcrumbs links={items} className={'product-breadcrumbs'} isLastActive />
        <Divider className={'w-100'} />
      </Container>
    </div>
  )
}

export {MenuBreadcrumbs}
