import React, {useCallback, useEffect, useMemo, useReducer, useState} from 'react'
import {connectComponent} from '../../../../../../../store/common'
import {Container, Loading} from 'grilnica-reactstrap'
import {ProductDTO} from 'grilnica-store-share/lib/product/ProductDTO'
import {
  initialProductItemState,
  ProductItemActions,
  ProductItemReducer,
  ProductItemState,
} from '../productList/productItem/ducks/ProductItemDuck'
import {toggleOptionService} from '../productList/productItem/services/product/toggleOptionService'
import {selectProductService} from '../productList/productItem/services/product/selectProductService'
import {
  MenuCategoryDTO,
  MenuEntityTypeEnum,
  ProductMap,
  RestaurantByProductMap,
  SelectedProductDTO,
  WeightMapDto,
} from 'grilnica-store-share'
import {bindBasketActions} from '../../../../../../../store/ducks/basket'
import {ProductPageContent} from './components/ProductPageContent'
import {State} from '../../../../../../../store/ducks'
import {Redirect} from 'react-router-dom'
import {selectComboService} from '../productList/productItem/services/combo/selectComboService'
import {toggleComboService} from '../productList/productItem/services/combo/toggleComboService'
import {bindProductActions} from '../../../../../../../store/ducks/product'
import {AliasObjectMap} from '../../../../../../../types/common/AliasObjectMap'
import {
  ECOMMERCE_ACTION_TYPE,
  EcommerceProductType,
  ecommerceTarget,
} from '../../../../../../../utils/metrica/ym/ecommerceTarget'
import {isEmptyObject} from 'grilnica-share'
import {ErrorLoadMenuComponent} from '../../../../../../components/common/ErrorLoadMenuComponent'
import {ErrorInitializeEnum} from '../../../../../../../types/common/ErrorInitializeEnum'
import {NotFoundPage} from '../../../../../../components/common/NotFoundPage'
import {MenuBreadcrumbs} from '../components/MenuBreadcrumbs'

interface ProductPageProps {
  productAlias: string
  childAlias: string
  alias: string
  productMap: ProductMap
  basketActions: any
  productActions: any
  goBack: () => void
  editProductIndex: number
  editSelectedProduct: SelectedProductDTO
  initialize?: any
  aliasProductCategoryMap?: AliasObjectMap
  restaurantByProductMap?: RestaurantByProductMap
  errorInitialize: ErrorInitializeEnum
  categoryList: MenuCategoryDTO[]
  isTerminal: boolean
  weightMap: WeightMapDto
}

const ProductPage: React.FC<ProductPageProps> = ({
  productAlias,
  childAlias,
  alias,
  productMap,
  basketActions,
  productActions,
  goBack,
  editProductIndex,
  editSelectedProduct,
  initialize,
  aliasProductCategoryMap,
  restaurantByProductMap,
  errorInitialize,
  categoryList,
  isTerminal,
  weightMap,
}): React.ReactElement => {
  const [productState, setProductState] = useReducer<
    React.Reducer<ProductItemState, ProductItemActions>
  >(
    ProductItemReducer,
    initialize &&
      initialize.productItemMapByProduct &&
      Object.keys(initialize.productItemMapByProduct).length !== 0 &&
      initialize.productItemMapByProduct[
        aliasProductCategoryMap[childAlias ? childAlias : alias][productAlias]
      ]
      ? initialize.productItemMapByProduct[
          aliasProductCategoryMap[childAlias ? childAlias : alias][productAlias]
        ]
      : initialProductItemState,
  )
  const [redirectUrl, setRedirectUrl] = useState<string>(null)

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [productAlias])

  const toggleOption = useCallback(
    (optionCategoryId: string, optionId: string, isChecked: boolean, isOptional: boolean) => {
      toggleOptionService(
        optionCategoryId,
        optionId,
        isChecked,
        isOptional,
        productState.supportsMaps,
        productState.selectedProduct,
        setProductState,
      )
    },
    [productState.selectedProduct, productState.supportsMaps, setProductState],
  )

  const toggleComboProduct = useCallback(
    (comboGroupId: string, comboProductId: string, selectedProduct: SelectedProductDTO) => {
      toggleComboService(
        comboGroupId,
        comboProductId,
        selectedProduct,
        productState.selectedProduct,
        setProductState,
      )
    },
    [productState.selectedProduct, setProductState],
  )

  const getProduct = useCallback(async () => {
    let newProductDB: ProductDTO = null
    if (editSelectedProduct) {
      newProductDB = editSelectedProduct.product
    } else {
      for (let menuCategoryId in productMap) {
        for (let productDB of productMap[menuCategoryId]) {
          if (
            productDB.productId ===
            aliasProductCategoryMap[childAlias ? childAlias : alias][productAlias]
          ) {
            newProductDB = productDB
            break
          }
        }
      }
    }
    if (newProductDB.type === MenuEntityTypeEnum.COMBO) {
      await selectComboService(newProductDB, productMap, setProductState, editSelectedProduct)
    } else {
      await selectProductService(newProductDB, setProductState, editSelectedProduct)
    }
  }, [alias, aliasProductCategoryMap, childAlias, editSelectedProduct, productAlias, productMap])

  useEffect(() => {
    if (!isEmptyObject(productMap)) {
      let products: EcommerceProductType[] = []
      for (let menuCategoryId in productMap) {
        for (let productDB of productMap[menuCategoryId]) {
          if (
            aliasProductCategoryMap[childAlias ? childAlias : alias] &&
            productDB.productId ===
              aliasProductCategoryMap[childAlias ? childAlias : alias][productAlias]
          ) {
            products.push({
              id: productDB.productId,
              name: productDB.name,
              price: productDB.price,
              quantity: 1,
            })
            break
          }
        }
      }

      ecommerceTarget(ECOMMERCE_ACTION_TYPE.DETAIL, products)
    }
  }, [])

  useEffect(() => {
    if (!isEmptyObject(productMap) && !isEmptyObject(aliasProductCategoryMap)) {
      getProduct()
    }
  }, [getProduct, productMap, aliasProductCategoryMap])

  useEffect(() => {
    if (productState.supportsMaps) {
      if (Object.keys(productState.supportsMaps.extraOptionsMap || {}).length !== 0) {
        productActions.setProductPopoverText('Вы выбрали слишком много опций')
        setTimeout(() => {
          productActions.setProductPopoverText(null)
        }, 2000)
      }
    }
  }, [productState.supportsMaps, productActions])

  const sendToBasket = useCallback(() => {
    if (productState.selectedProduct.product.type === MenuEntityTypeEnum.COMBO) {
      const newSelectedProduct: SelectedProductDTO = JSON.parse(
        JSON.stringify(productState.selectedProduct),
      )
      basketActions.addComboInBasketRequest(newSelectedProduct)
    } else {
      if (productState.selectedProduct && productState.selectedProduct.product.optionCategories) {
        const newProductState: ProductItemState = JSON.parse(JSON.stringify(productState))
        basketActions.addProductWithOptionsInBasketRequest(newProductState)
      } else {
        const newSelectedProduct: SelectedProductDTO = JSON.parse(
          JSON.stringify(productState.selectedProduct),
        )
        basketActions.addProductWithoutOptionsInBasketRequest(newSelectedProduct)
      }
    }
    if (editProductIndex) {
      // setTimout не убирать, иначе будет пиздец - не обновится цена и модификаторы в корзине
      setTimeout(() => {
        basketActions.setEditProductIndex(null)
        setRedirectUrl('/basket')
      }, 1000)
    }
  }, [basketActions, editProductIndex, productState])

  const renderContent: React.ReactElement = useMemo(() => {
    if (errorInitialize) {
      return <ErrorLoadMenuComponent className={'w-100 my-x10'} />
    }

    if (!productState.selectedProduct) {
      return <Loading className={'w-100 my-x10'} />
    }

    return (
      <Container size={'normal'} className={'mt-x10 mb-x10'}>
        <ProductPageContent
          selectedProduct={productState.selectedProduct}
          supportsMaps={productState.supportsMaps}
          toggleOption={toggleOption}
          toggleComboProduct={toggleComboProduct}
          sendToBasket={sendToBasket}
          goBack={goBack}
          isSave={!!editSelectedProduct}
          restaurants={
            restaurantByProductMap &&
            aliasProductCategoryMap[childAlias ? childAlias : alias] &&
            aliasProductCategoryMap[childAlias ? childAlias : alias][productAlias] &&
            restaurantByProductMap[
              aliasProductCategoryMap[childAlias ? childAlias : alias][productAlias]
            ]
          }
          isTerminal={isTerminal}
          setProductState={setProductState}
          productMap={productMap}
          menuCategoryList={categoryList}
          weightMap={weightMap}
        />
        {redirectUrl && <Redirect to={redirectUrl} />}
      </Container>
    )
  }, [
    errorInitialize,
    productState.selectedProduct,
    productState.supportsMaps,
    toggleOption,
    toggleComboProduct,
    sendToBasket,
    goBack,
    editSelectedProduct,
    restaurantByProductMap,
    aliasProductCategoryMap,
    childAlias,
    alias,
    productAlias,
    isTerminal,
    productMap,
    categoryList,
    redirectUrl,
    weightMap,
  ])

  if (!aliasProductCategoryMap[childAlias ? childAlias : alias][productAlias]) {
    return <NotFoundPage />
  }

  return (
    <div className={'d-flex flex-column justify-content-between flex-grow-1'}>
      {renderContent}
      <MenuBreadcrumbs
        alias={alias}
        childAlias={childAlias}
        productAlias={productAlias}
        aliasProductCategoryMap={aliasProductCategoryMap}
        categoryList={categoryList}
        productMap={productMap}
      />
    </div>
  )
}

export default connectComponent(
  (state: State, props: any) => ({
    productAlias: props.match.params.productAlias
      ? props.match.params.productAlias
      : props.match.params.childAlias,
    childAlias: props.match.params.productAlias ? props.match.params.childAlias : null,
    alias: props.match.params.alias,
    productMap: state.menu.productMap,
    goBack: props.history.goBack,
    editProductIndex: props.match.params.index,
    editSelectedProduct: props.match.params.index
      ? state.basket.selectedProducts
        ? state.basket.selectedProducts[props.match.params.index]
        : null
      : null,
    initialize: state.initialize,
    aliasProductCategoryMap: state.common.aliasProductCategoryMap,
    restaurantByProductMap: state.restaurantProduct.restaurantByProductMap,
    errorInitialize: state.initialize.errorInitialize,
    isTerminal: state.terminal?.terminalAlias?.length > 0,
    categoryList: state.menu.categoryList,
    weightMap: state.menu.weightMap,
  }),
  (dispatch) => ({
    basketActions: bindBasketActions(dispatch),
    productActions: bindProductActions(dispatch),
  }),
  ProductPage,
)
