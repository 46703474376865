import React, {useMemo} from 'react'
import {ProcessCardItemDTO} from 'grilnica-share'
import {MenuCategoryDTO, ProductDTO, ProductMap, WeightMapDto} from 'grilnica-store-share'
import {getProductById} from '../../../../../../../../../utils/order/getProductById'
import {ProductPreviewCardItem} from './ProductPreviewCardItem'
import {
  getProductLink,
  getProductPriceBlock,
  getMinDefaultWeight,
} from '../../../../../../../../../utils/menu'
import {Row} from 'grilnica-reactstrap'

interface ProductPreviewCardListProps {
  processProductItems: ProcessCardItemDTO[]
  productMap: ProductMap
  menuCategoryList: MenuCategoryDTO[]
  weightMap: WeightMapDto
}

const ProductPreviewCardList: React.FC<ProductPreviewCardListProps> = ({
  processProductItems,
  productMap,
  menuCategoryList,
  weightMap,
}): React.ReactElement => {
  const renderContent: React.ReactElement = useMemo(() => {
    const renderItems: React.ReactElement[] = []
    for (let processProductItem of processProductItems || []) {
      const product: ProductDTO = getProductById(processProductItem.productId, productMap)
      if (product) {
        renderItems.push(
          <ProductPreviewCardItem
            key={processProductItem.productId}
            product={product}
            priceBlock={getProductPriceBlock(product)}
            productLink={getProductLink(
              product.alias,
              product.menuCategoryId || processProductItem.menuCategoryId,
              menuCategoryList || [],
            )}
            weight={getMinDefaultWeight(product, weightMap)}
            isForSale={!!product.menuCategoryId}
          />,
        )
      }
    }
    if (renderItems.length > 0) {
      return <Row>{renderItems}</Row>
    }
    return null
  }, [menuCategoryList, processProductItems, productMap, weightMap])

  return <>{renderContent}</>
}

export {ProductPreviewCardList}
