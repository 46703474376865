import {ResponseError} from 'grilnica-share'
import {BaseAction} from '../common/BaseAction'
import {bindActionCreators} from 'redux'

export interface ErrorState {
  error: ResponseError
  errorTime: number
}

export enum ErrorActionsTypes {
  SET_ERROR_REQUEST = 'error/SET_ERROR_REQUEST',
  SET_ERROR = 'error/SET_ERROR',
  CLEAR_ERROR = 'error/CLEAR_ERROR',
}

const INITIAL_STATE: ErrorState = {
  error: null,
  errorTime: null,
}

export const Actions = {
  setError: (error: ResponseError, errorModalHeader?: string): BaseAction => ({
    type: ErrorActionsTypes.SET_ERROR,
    payload: {error, errorModalHeader},
  }),

  setErrorRequest: (error: ResponseError): BaseAction => ({
    type: ErrorActionsTypes.SET_ERROR_REQUEST,
    payload: error,
  }),

  clearError: (): BaseAction => ({
    type: ErrorActionsTypes.CLEAR_ERROR,
  }),
}

export function bindErrorActions(dispatch: any) {
  return bindActionCreators(Actions, dispatch)
}

export default function error(state: ErrorState = INITIAL_STATE, action: BaseAction) {
  switch (action.type) {
    case ErrorActionsTypes.SET_ERROR:
      return {
        ...state,
        error: action.payload.error,
        errorTime: new Date().getTime(),
      }
    case ErrorActionsTypes.CLEAR_ERROR:
      return {
        ...state,
        error: null,
      }
    default:
      return state
  }
}
