import {ProductVariantMap} from '../../../../../types/product/ProductVariantMap'
import {ProductVariantDTO} from 'grilnica-store-share/src/product/ProductVariantDTO'
import {getKeyByOptionIds} from '../../../../../view/site/containers/menu/components/product/productList/productItem/utils/getKeyByOptionIds'

function getProductVariantMap(productVariants: ProductVariantDTO[]): ProductVariantMap {
  let productVariantMap: ProductVariantMap = {}
  for (let productVariant of productVariants || []) {
    // eslint-disable-next-line no-undef
    const joinOptionId: string = getKeyByOptionIds(productVariant.optionIds)
    productVariantMap[joinOptionId] = productVariant
  }
  return productVariantMap
}

export {getProductVariantMap}
