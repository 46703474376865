import React, {useMemo} from 'react'
import {
  Col,
  CustomCheckbox,
  Datepicker,
  Divider,
  InputSelectOption,
  InputSelectVertical,
  Row,
} from 'grilnica-reactstrap'
import {AddMinutesOrderType, DeliveryTypeEnum, PrepareOrderTime} from 'grilnica-store-share'
import {getIntervalTime, RestaurantDTO} from 'grilnica-share'
import {PrepareOrderTimeEntity} from '../../../../../../utils/order/PrepareOrderTimeEntity'
import {DeliveryInfo} from '../../../../../../types/order/DeliveryInfo'
import {DeliveryTimeAlert} from './DeliveryTimeAlert'
import moment, {Moment} from 'moment'
import {COUNT_DAYS_FOR_DELIVERY, TIME_COOKING, TIME_INTERVAL} from './constants'

function getTime(dateTime: Moment): Moment {
  return moment({h: dateTime.hours(), m: dateTime.minutes()})
}

interface OrderTimeAcceptProps {
  deliveryType: DeliveryTypeEnum
  prepareTime: PrepareOrderTime
  isShowOrderTimeAccept: boolean
  setPrepareOrderField: (key: string, value: any, parentKey?: string) => void
  restaurant: RestaurantDTO
  deliveryInfo: DeliveryInfo
  addMinutesOrder: AddMinutesOrderType
  numberBlock: number
}

const createTimeOptions = (orderDate): InputSelectOption[] => {
  const options: InputSelectOption[] = []
  const start = moment('00:00', 'HH:mm')
  do {
    start.add(TIME_INTERVAL, 'minutes')
    let first = moment(start)
    first.subtract(TIME_INTERVAL, 'minutes')
    let end = moment(start)
    end.add(TIME_INTERVAL, 'minutes')
    options.push({
      value: orderDate + 'T' + start.format('HH:mm'),
      label: getIntervalTime(start.format('HH:mm'), TIME_INTERVAL, TIME_INTERVAL) as string,
    })
  } while (start.format('HH:mm').toString() !== '23:45')

  return options
}

const OrderTimeAccept: React.FC<OrderTimeAcceptProps> = ({
  deliveryType,
  prepareTime,
  isShowOrderTimeAccept,
  setPrepareOrderField,
  restaurant,
  deliveryInfo,
  addMinutesOrder,
  numberBlock,
}): React.ReactElement => {
  const getPrepareTimeEntity = useMemo((): PrepareOrderTimeEntity => {
    const newDeliveryTime: number =
      deliveryType === DeliveryTypeEnum.PICKUP
        ? addMinutesOrder.pickupMinutes
        : deliveryInfo?.deliveryTime
        ? deliveryInfo.deliveryTime
        : addMinutesOrder.deliveryMinutes
    return new PrepareOrderTimeEntity(
      prepareTime,
      restaurant,
      deliveryType,
      COUNT_DAYS_FOR_DELIVERY,
      newDeliveryTime - TIME_COOKING,
      TIME_COOKING,
      setPrepareOrderField,
      TIME_INTERVAL,
    )
  }, [addMinutesOrder, deliveryInfo, deliveryType, prepareTime, restaurant, setPrepareOrderField])

  const renderDate: React.ReactElement = useMemo(() => {
    return (
      <Col sm={4}>
        <Datepicker
          id={'orderDate'}
          name={'orderDate'}
          value={prepareTime.orderTime}
          minDate={getPrepareTimeEntity.getMinDate()}
          maxDate={getPrepareTimeEntity.getMaxDate()}
          isRounded
          placeholder={'Выберите дату'}
          onChange={(value) => {
            getPrepareTimeEntity.changeTime(value)
            setPrepareOrderField(
              'orderTime',
              getPrepareTimeEntity.getTime(deliveryType === DeliveryTypeEnum.DELIVERY),
              'prepareTime',
            )
          }}
          onKeyDown={(e) => {
            e.preventDefault()
          }}
          isDisabledKeyboard={true}
        />
      </Col>
    )
  }, [prepareTime.orderTime, getPrepareTimeEntity, setPrepareOrderField, deliveryType])

  const renderTime: React.ReactElement = useMemo(() => {
    if (deliveryType === DeliveryTypeEnum.PICKUP) {
      return (
        <Col sm={4}>
          <Datepicker
            id={'orderTime'}
            name={'orderTime'}
            value={prepareTime.orderTime}
            isRounded
            placeholder={'Выберите время'}
            disabled={!prepareTime.orderTime}
            dateFormat={'HH:mm'}
            showTimeSelect
            showTimeSelectOnly
            timeIntervals={TIME_INTERVAL}
            filterTime={(time) => {
              return getPrepareTimeEntity.getFilterTime(time)
            }}
            excludeTimes={getPrepareTimeEntity.getExcludedTimes()}
            minTime={getPrepareTimeEntity.getMinTime()}
            maxTime={getPrepareTimeEntity.getMaxTime()}
            onChange={(value) => {
              getPrepareTimeEntity.changeTime(value)
              setPrepareOrderField('orderTime', getPrepareTimeEntity.getTime(false), 'prepareTime')
            }}
            onKeyDown={(e) => {
              e.preventDefault()
            }}
            isDisabledKeyboard={true}
          />
        </Col>
      )
    }
    if (deliveryType === DeliveryTypeEnum.DELIVERY) {
      const options = prepareTime.orderTime
        ? createTimeOptions(moment(prepareTime.orderTime).format('YYYY-MM-DD'))
        : []
      return (
        <Col sm={4}>
          <InputSelectVertical
            id={'order-time'}
            instanceId={'order-time-id'}
            options={options}
            name={'orderTime'}
            value={options.find((item) => item.value === prepareTime.orderTime)}
            defaultValue={options.find((item) => item.value === prepareTime.orderTime)}
            isOptionDisabled={(option: InputSelectOption) => {
              let minTime = getTime(moment(getPrepareTimeEntity.getMinTime()))
              if (minTime.format('HH:mm').toString() !== '00:00') {
                minTime.add(TIME_INTERVAL, 'minutes')
              }
              const maxTime = getTime(moment('00:00', 'HH:mm').subtract(TIME_INTERVAL, 'minutes'))
              const oTime = getTime(moment(option.value))
              const isBetween = oTime.isSameOrBefore(maxTime) && oTime.isSameOrAfter(minTime)
              return !isBetween
            }}
            placeholder={'Выберите время'}
            isDisabled={!prepareTime.orderTime}
            isSearchable={false}
            isRequired={false}
            isRounded
            size={'lg'}
            onChange={(value) => {
              getPrepareTimeEntity.changeTime(value.value)
              setPrepareOrderField('orderTime', getPrepareTimeEntity.getTime(false), 'prepareTime')
            }}
          />
        </Col>
      )
    } else {
      return null
    }
  }, [deliveryType, prepareTime.orderTime, getPrepareTimeEntity, setPrepareOrderField])

  const renderIsQuickly: React.ReactElement = useMemo(() => {
    return (
      <>
        <CustomCheckbox
          id={'deliveryFaster'}
          label={'Как можно скорее'}
          checked={prepareTime.isQuickly}
          onChange={(value: any) => {
            setPrepareOrderField('isQuickly', value, 'prepareTime')
            if (value === true) {
              setPrepareOrderField('orderTime', null, 'prepareTime')
            }
          }}
          color={'red'}
          className={'mb-x4'}
        />
        {prepareTime.isQuickly && getPrepareTimeEntity.getInfoOfQuickly() && (
          <p className={'restaurant-close mb-x6 mt-n-x3'}>
            {getPrepareTimeEntity.getInfoOfQuickly()}
          </p>
        )}
      </>
    )
  }, [getPrepareTimeEntity, prepareTime.isQuickly, setPrepareOrderField])

  const renderDeliveryTime = useMemo(() => {
    if (isShowOrderTimeAccept) {
      return (
        <>
          <p className={'name-blocks'}>
            {`${numberBlock}. ${
              deliveryType === DeliveryTypeEnum.DELIVERY
                ? 'Когда доставить?'
                : 'Когда хотите получить?'
            }`}
          </p>
          {renderIsQuickly}
          {!prepareTime.isQuickly && (
            <Row>
              {renderDate}
              {renderTime}
            </Row>
          )}
          {prepareTime?.isQuickly && (
            <DeliveryTimeAlert deliveryType={deliveryType} deliveryInfo={deliveryInfo} />
          )}
          <Divider className={'mb-x6'} />
        </>
      )
    }
  }, [
    isShowOrderTimeAccept,
    deliveryInfo,
    deliveryType,
    numberBlock,
    prepareTime?.isQuickly,
    renderDate,
    renderIsQuickly,
    renderTime,
  ])

  return <>{renderDeliveryTime}</>
}

export {OrderTimeAccept}
