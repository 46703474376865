import {OptionCategoryDTO, ProductMap} from 'grilnica-store-share'
import {ProductDTO} from 'grilnica-store-share/lib/product/ProductDTO'
import {getProductById} from '../../../../../utils/order/getProductById'
import {OptionCategoryMap} from '../../../../../types/product/OptionCategoryMap'
import {OptionMap} from '../../../../../types/product/OptionMap'
import {SelectedOptionsMap} from '../../../../../types/product/SelectedOptionsMap'
import {getOptionMap} from './getOptionMap'
import {getOptionCategoryMap} from './getOptionCategoryMap'
import {getSelectedOptionsMap} from './getSelectedOptionsMap'
import {getSelectedOptions} from './getSelectedOptions'
import {getSelectedProductPrice} from './getSelectedProductPrice'
import {getSumPriceOptions} from './getSumPriceOptions'
import {ResponseError} from 'grilnica-share'
import {SelectedProductDTO} from '../../../../../types/product/SelectedProductDTO'
import {getFullPriceSelectedProduct} from '../../../basket/utils/getFullPriceSelectedProduct'
import v4 from 'uuid/v4'
import {getDefaultOptionIds} from './getDefaultOptionIds'

function getSelectedProductByProduct(
  product: ProductDTO,
  productMap: ProductMap,
): SelectedProductDTO {
  let selectedProduct: SelectedProductDTO = {
    product: null,
    productId: product.productId,
    count: 1,
    price: product.price,
    productVariantId: null,
    selectedProductId: v4(),
  }

  //получить продукт
  let productDB: ProductDTO = getProductById(product.productId, productMap)
  selectedProduct.product = productDB

  if (!productDB) {
    throw new ResponseError(409, 'Невозможно добавить')
  }

  // if (product.count > productDB.maxCountInOrder) {
  //   let errorMessage: string = `Не удалось добавить. Для "${product.name}" превышен лимит в корзине.`
  //   throw new ResponseError(409, errorMessage)
  // }
  selectedProduct.maxCountInOrder = productDB.maxCountInOrder

  //получить вспомогательную информацию по опциям продукта
  const optionCategories: OptionCategoryDTO[] = productDB.optionCategories || []
  const optionCategoryMap: OptionCategoryMap = getOptionCategoryMap(optionCategories)
  const optionMap: OptionMap = getOptionMap(optionCategories)
  let optionIds: string[] = getDefaultOptionIds(optionCategories)
  const selectedOptionsMap: SelectedOptionsMap = getSelectedOptionsMap(optionCategories, optionIds)

  // получить выбранные опции для продукта
  selectedProduct.selectedOptions = getSelectedOptions(
    selectedOptionsMap,
    optionCategoryMap,
    optionMap,
  )

  // обновить цену опций
  selectedProduct.allOptionPrice = getSumPriceOptions(
    selectedOptionsMap,
    optionCategoryMap,
    optionMap,
  )

  // обновить цену
  selectedProduct.price = getSelectedProductPrice(
    selectedOptionsMap,
    optionCategoryMap,
    optionMap,
    productDB,
  )
  //получить полную цену для превью корзины
  selectedProduct.fullPrice = getFullPriceSelectedProduct(selectedProduct)

  return selectedProduct
}

export {getSelectedProductByProduct}
