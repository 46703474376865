import React, {useCallback, useEffect, useState} from 'react'
import {ActionDTO, ProductMap} from 'grilnica-store-share'
import {Container} from 'grilnica-reactstrap'
import {ProductDTO} from 'grilnica-store-share/lib/product/ProductDTO'
import {State} from '../../../../../store/ducks'
import {SalePageContent} from './components/SalePageContent'
import {connectComponent} from '../../../../../store/common'
import {AliasObjectMap} from '../../../../../types/common/AliasObjectMap'
import {IsShowRestaurantByProductMap} from '../../../../../types/restaurantProduct/IsShowRestaurantByProductMap'
import {loadActionService} from '../../../../../services/action'
import {NotFoundPage} from '../../../../components/common/NotFoundPage'

interface SalePageProps {
  actionAlias: string
  productMap: ProductMap
  history?: any
  initSale?: ActionDTO
  aliasActionMap: AliasObjectMap
  isShowRestaurantByProductMap: IsShowRestaurantByProductMap
  selectedCityId: string
}

const SalePage: React.FC<SalePageProps> = ({
  actionAlias,
  history,
  productMap,
  initSale,
  aliasActionMap,
  isShowRestaurantByProductMap,
  selectedCityId,
}): React.ReactElement => {
  const [sale, setSale] = useState<ActionDTO>(initSale)
  const [productList, setProductList] = useState<ProductDTO[]>()

  const goBack = () => {
    history.goBack()
  }

  const loadSale = useCallback(async () => {
    const sale: ActionDTO = await loadActionService(
      aliasActionMap[actionAlias].objectId,
      selectedCityId,
    )
    setSale(sale)
  }, [actionAlias, aliasActionMap, selectedCityId])

  const loadProduct = useCallback(() => {
    let newProductList: ProductDTO[] = []
    for (let product of sale.products || []) {
      for (let item of productMap[product.menuCategoryId] || []) {
        if (
          isShowRestaurantByProductMap[product.productId] !== undefined &&
          item.productId === product.productId &&
          (item.price || item.salePrice || item.isShowWithZeroPrice) &&
          !item.isHideInApp
        ) {
          newProductList.push(item)
        }
      }
    }
    setProductList(newProductList)
  }, [isShowRestaurantByProductMap, productMap, sale?.products])

  useEffect(() => {
    if (sale && sale.products) {
      loadProduct()
    }
  }, [loadProduct, sale])

  useEffect(() => {
    loadSale()
  }, [loadSale])

  if (!aliasActionMap[actionAlias]) {
    return <NotFoundPage />
  }

  return (
    <Container size={'normal'} className={'mt-x10 mb-x10'}>
      <SalePageContent sale={sale} productList={productList} goBack={goBack} />
    </Container>
  )
}

export default connectComponent(
  (state: State, props: any) => ({
    actionAlias: props.match.params.alias,
    productMap: state.menu.productMap,
    initSale: state.initialize.sale,
    aliasActionMap: state.common.aliasActionMap,
    isShowRestaurantByProductMap: state.restaurantProduct.isShowRestaurantByProductMap,
    selectedCityId: state.city.selectedCity?.cityId,
  }),
  () => ({}),
  SalePage,
)
