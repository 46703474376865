import React from 'react'
import {COLORS} from 'grilnica-reactstrap'

interface OrderAcceptedSVGProps {
  isActive: boolean
  size?: 's' | 'm'
  className?: string
}

const OrderAcceptedSVG: React.FC<OrderAcceptedSVGProps> = ({
  isActive,
  size,
  className,
}): React.ReactElement => {
  return (
    <svg
      className={className}
      width={size === 's' ? '24' : '48'}
      height={size === 's' ? '24' : '48'}
      viewBox="0 0 48 48"
      fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.2507 5.59864C20.7698 5.20767 22.3614 5 24 5C34.4934 5 43 13.5066 43 24C43 34.4934 34.4934 43 24 43C13.5066 43 5 34.4934 5 24C5 22.4633 5.18262 20.968 5.52781 19.5351C5.65715 18.9981 6.19727 18.6677 6.73419 18.7971C7.27112 18.9264 7.60153 19.4665 7.47219 20.0035C7.16368 21.2842 7 22.6223 7 24C7 33.3888 14.6112 41 24 41C33.3888 41 41 33.3888 41 24C41 14.6112 33.3888 7 24 7C22.5309 7 21.1068 7.18611 19.7493 7.53552C19.2144 7.67318 18.6692 7.35119 18.5316 6.81634C18.3939 6.28148 18.7159 5.7363 19.2507 5.59864ZM14.1377 8.90676C14.4653 9.35137 14.3705 9.97739 13.9259 10.305C13.4899 10.6263 13.0698 10.9679 12.6669 11.3285C11.7451 12.1536 10.9138 13.0777 10.1899 14.084C9.8674 14.5323 9.24251 14.6343 8.79417 14.3118C8.34584 13.9893 8.24385 13.3644 8.56637 12.916C9.3751 11.7918 10.3036 10.7597 11.3331 9.83823C11.783 9.43551 12.2523 9.0539 12.7394 8.69497C13.184 8.36734 13.81 8.46216 14.1377 8.90676ZM23.5 13C24.0523 13 24.5 13.4477 24.5 14V24.4234L33.501 29.6346C33.979 29.9113 34.1421 30.5231 33.8654 31.001C33.5887 31.479 32.9769 31.6421 32.499 31.3654L22.999 25.8654C22.6901 25.6866 22.5 25.3568 22.5 25V14C22.5 13.4477 22.9477 13 23.5 13Z"
        fill={isActive ? COLORS.pink : COLORS.gray500}
      />
    </svg>
  )
}

export {OrderAcceptedSVG}
