import {SelectedOptionsMap} from '../../../../../types/product/SelectedOptionsMap'
import {OptionCategoryDTO} from 'grilnica-store-share'
import {CountSelectedOptionsMap} from '../../../../../types/product/CountSelectedOptionsMap'
import {CheckedOptionsMap} from '../../../../../types/product/CheckedOptionsMap'

function getSelectedOptionsMap(
  optionCategories: OptionCategoryDTO[],
  optionIds?: string[],
): SelectedOptionsMap {
  let selectedOptionsMap: SelectedOptionsMap = {}
  const countSelectedOptionsMap: CountSelectedOptionsMap = {}

  for (let optionCategory of optionCategories) {
    for (let optionId of optionIds) {
      let countSelectedOptions: number = 0
      let checkedOptions: CheckedOptionsMap = selectedOptionsMap[optionCategory.optionCategoryId]
        ? selectedOptionsMap[optionCategory.optionCategoryId]
        : {}
      if (optionId) {
        for (let optionFromOptionCategory of optionCategory.options) {
          if (optionFromOptionCategory.optionId === optionId) {
            checkedOptions[optionId] = true
          }
          if (checkedOptions[optionFromOptionCategory.optionId]) {
            checkedOptions[optionFromOptionCategory.optionId] = true
            countSelectedOptions++
          }
          if (!checkedOptions[optionFromOptionCategory.optionId]) {
            checkedOptions[optionFromOptionCategory.optionId] = false
          }
        }
      } else {
        for (let optionFromOptionCategory of optionCategory.options) {
          checkedOptions[optionFromOptionCategory.optionId] = false
        }
      }

      countSelectedOptionsMap[optionCategory.optionCategoryId] = countSelectedOptions

      selectedOptionsMap[optionCategory.optionCategoryId] = checkedOptions
    }
  }

  return selectedOptionsMap
}

export {getSelectedOptionsMap}
