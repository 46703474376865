import {PaymentTypeEnum} from 'grilnica-share'
import {CashboxPaymentType, DeliveryTypeEnum} from 'grilnica-store-share'
import {CashboxTypeEnum} from 'grilnica-share/lib/cashbox/CashboxTypeEnum'

export const getPaymentTypeListUtils = (
  deliveryType: DeliveryTypeEnum,
  cashboxPaymentType: CashboxPaymentType,
) => {
  let paymentTypeList: PaymentTypeEnum[] = null
  if (cashboxPaymentType) {
    if (deliveryType === DeliveryTypeEnum.DELIVERY) {
      if (cashboxPaymentType[CashboxTypeEnum.DELIVERY]) {
        return cashboxPaymentType[CashboxTypeEnum.DELIVERY]
      } else if (
        cashboxPaymentType[CashboxTypeEnum.DELIVERY] === undefined &&
        cashboxPaymentType[CashboxTypeEnum.SHOP]
      ) {
        return cashboxPaymentType[CashboxTypeEnum.SHOP]
      } else {
        return null
      }
    } else {
      if (cashboxPaymentType[CashboxTypeEnum.SHOP]) {
        return cashboxPaymentType[CashboxTypeEnum.SHOP]
      } else if (
        cashboxPaymentType[CashboxTypeEnum.SHOP] === undefined &&
        cashboxPaymentType[CashboxTypeEnum.DELIVERY]
      ) {
        return cashboxPaymentType[CashboxTypeEnum.DELIVERY]
      } else {
        return null
      }
    }
  }
  return paymentTypeList
}
