import React, {useMemo} from 'react'
import {Col} from 'grilnica-reactstrap'
import {MenuCategoryDTO} from 'grilnica-store-share'
import {CONSTANTS} from '../../../../../../../constants'
import {Link} from 'react-router-dom'
import {CityDTO} from 'grilnica-share'
import {LazyImage} from '../../../../../components/lazyImage/LazyImage'

interface CatalogListItemProps {
  menu: MenuCategoryDTO
  selectedCity: CityDTO
}

const CatalogListItem: React.FC<CatalogListItemProps> = ({
  menu,
  selectedCity,
}): React.ReactElement => {
  const renderContent: React.ReactElement = useMemo(() => {
    return (
      <Col xl={3} lg={3} md={4} sm={4} xs={12}>
        <Link className={'catalog-list-item '} to={'/menu/' + menu.alias}>
          <LazyImage
            src={CONSTANTS.cdnUrl + '/' + menu.imageFileName}
            typePlaceholder={'catalog-category'}
            alt={menu.name + ' купить в Грильнице, ' + selectedCity?.name} //TODO:: SEO UPDATE
            title={menu.name + ' купить в Грильнице, ' + selectedCity?.name} //TODO:: SEO UPDATE
          />
          <p className={'sign'}>{menu.name}</p>
        </Link>
      </Col>
    )
  }, [menu.alias, menu.imageFileName, menu.name, selectedCity?.name])
  return <>{renderContent}</>
}

export {CatalogListItem}
