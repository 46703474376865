import React, {useMemo} from 'react'
import {CONSTANTS} from '../../../../../../../constants'

interface SalePageImageProps {
  imageFileName: string
  name: string
}

const SalePageImage: React.FC<SalePageImageProps> = ({imageFileName, name}): React.ReactElement => {
  const renderContent: React.ReactElement = useMemo(() => {
    return (
      <img
        src={CONSTANTS.cdnUrl + '/' + imageFileName}
        width={'100%'}
        height={'auto'}
        alt={name}
        title={name}
      />
    )
  }, [name, imageFileName])
  return <>{renderContent}</>
}

export {SalePageImage}
