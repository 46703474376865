import {SocketActionsTypes} from './types'
import {SocketState} from '../types'
import {BaseAction} from '../../common/BaseAction'

const initialSocketState: SocketState = {
  isSocketConnect: false,
}

export default function socket(
  state: SocketState = initialSocketState,
  dispatch: BaseAction,
): SocketState {
  switch (dispatch.type) {
    case SocketActionsTypes.SET_IS_SOCKET_CONNECT: {
      return {
        ...state,
        isSocketConnect: dispatch.payload,
      }
    }

    default: {
      return {
        ...state,
      }
    }
  }
}
