import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {Badge, Button, Icon} from 'grilnica-reactstrap'
import BasketPreview from '../../preview/BasketPreview'
import {basketSVG} from '../../svg/SystemIcons'

interface ButtonBasketDeskProps {
  priceFull: number
  count: number
  className?: string
  id: string
  style?: 'inversion'
}

const ButtonBasketDesk: React.FC<ButtonBasketDeskProps> = ({
  priceFull,
  count,
  className,
  id,
  style,
}): React.ReactElement => {
  const [isShowPreview, setIsShowPreview] = useState<boolean>(false)

  const handleClickOutside = useCallback(
    (e) => {
      const previewBlock = document.getElementsByClassName('basket-preview-' + id)[0]
      if (e.path && !e.path.includes(previewBlock)) {
        const basketButton = document.getElementsByClassName('basket-btn ' + id)[0]
        if (!e.path.includes(basketButton)) {
          setIsShowPreview(false)
        }
      }
    },
    [id],
  )

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, false)

    return () => {
      document.removeEventListener('click', handleClickOutside, false)
    }
  }, [handleClickOutside])

  const renderContent: React.ReactElement = useMemo(() => {
    return (
      <div className={'basket-btn-desk ' + (className ? className : '')}>
        <Button
          className={'basket-btn ' + id}
          color={'primary'}
          size={'sm'}
          style={style}
          isRoundedButton
          onClick={() => {
            setIsShowPreview(!isShowPreview)
          }}>
          <div className={'header-basket-button d-flex flex-row align-items-center'}>
            <Icon path={basketSVG} className={''} />
            {count ? (
              <Badge
                className={'badge-n '}
                isBorder={false}
                size={'xs'}
                color={style === 'inversion' ? 'primary' : 'sale'}
                countAlert={count}
              />
            ) : null}
            <span>{priceFull > 0 ? <>{priceFull + ' '}&#8381;</> : 'Корзина'}</span>
          </div>
        </Button>
        {/*<Button*/}
        {/*  className={'basket-btn ' + id}*/}
        {/*  color={'primary'}*/}
        {/*  size={'sm'}*/}
        {/*  isRoundedButton*/}
        {/*  iconPath={basketSVG}*/}
        {/*  iconPosition={'left'}*/}
        {/*  onClick={() => {*/}
        {/*    setIsShowPreview(!isShowPreview)*/}
        {/*  }}>*/}
        {/*  {priceFull > 0 ? <>{priceFull + ' '}&#8381;</> : 'Корзина'}*/}
        {/*</Button>*/}
        <BasketPreview
          className={
            isShowPreview
              ? id === 'navigation'
                ? 'basket-preview-navigation'
                : 'basket-preview-general'
              : 'd-none'
          }
          onClose={() => {
            setIsShowPreview(false)
          }}
        />
      </div>
    )
  }, [className, id, style, count, priceFull, isShowPreview])

  return <>{renderContent}</>
}

export {ButtonBasketDesk}
