import React, {useEffect, useMemo} from 'react'
import {CatalogList} from './components/catalog/CatalogList'
import {connectComponentWithoutActions} from '../../../../store/common'
import {MenuCategoryDTO, ProductMap} from 'grilnica-store-share'
import {Container} from 'grilnica-reactstrap'
import {isShowMenuCategory} from '../../../../utils/menu/isShowMenuCategory'
import SiteSlider from './components/slider/SiteSlider'
import {State} from '../../../../store/ducks'
import {CityDTO, isEmptyObject} from 'grilnica-share'
import {IsShowRestaurantByProductMap} from '../../../../types/restaurantProduct/IsShowRestaurantByProductMap'
import {ErrorLoadMenuComponent} from '../../../components/common/ErrorLoadMenuComponent'
import {ErrorInitializeEnum} from '../../../../types/common/ErrorInitializeEnum'

interface MenuPageProps {
  categoryList: MenuCategoryDTO[]
  productMap: ProductMap
  selectedCity: CityDTO
  isShowRestaurantByProductMap: IsShowRestaurantByProductMap
  errorInitialize: ErrorInitializeEnum
  isTerminal: boolean
}

const MenuPage: React.FC<MenuPageProps> = ({
  categoryList,
  productMap,
  selectedCity,
  isShowRestaurantByProductMap,
  errorInitialize,
  isTerminal,
}): React.ReactElement => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const getCategoryList = useMemo((): MenuCategoryDTO[] => {
    let newCategories: MenuCategoryDTO[] = []
    if (!isEmptyObject(productMap)) {
      for (let category of categoryList) {
        if (
          !category.parentMenuCategoryId &&
          isShowMenuCategory(
            category,
            productMap,
            categoryList,
            isShowRestaurantByProductMap,
            isTerminal,
          )
        ) {
          newCategories.push(category)
        }
      }
    }
    return newCategories
  }, [categoryList, productMap, isShowRestaurantByProductMap, isTerminal])

  const renderContent: React.ReactElement = useMemo(() => {
    return (
      <section className={'menu-page'}>
        <SiteSlider />
        <Container size={'normal'} className={'mt-x10 mb-x10'}>
          <h1 className={'h1 text-center mb-primary'}>Наше меню</h1>
          <article className={'menu-content'}>
            {!errorInitialize ? (
              <CatalogList categoryList={getCategoryList} selectedCity={selectedCity} />
            ) : (
              <ErrorLoadMenuComponent />
            )}
          </article>
        </Container>
      </section>
    )
  }, [errorInitialize, getCategoryList, selectedCity])

  return <>{renderContent}</>
}

export default connectComponentWithoutActions(
  (state: State) => ({
    categoryList: state.menu.categoryList || [],
    productMap: state.menu.productMap,
    selectedCity: state.city.selectedCity,
    isShowRestaurantByProductMap: state.restaurantProduct.isShowRestaurantByProductMap ?? {},
    isTerminal: state.terminal?.terminalAlias?.length > 0,
    errorInitialize: state.initialize.errorInitialize,
  }),
  MenuPage,
)
