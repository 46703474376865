import {BaseAction} from '../../common/BaseAction'
import {bindActionCreators} from 'redux'
import {PaymentModalActionsTypes} from './types'
import {PaymentModalStageEnum} from '../types'
import {ResponseError} from 'grilnica-share'

export const Actions = {
  controller: (data: any): BaseAction => ({
    type: PaymentModalActionsTypes.CONTROLLER,
    payload: {data},
  }),

  setIsOpen: (isOpen: boolean): BaseAction => ({
    type: PaymentModalActionsTypes.SET_IS_OPEN,
    payload: isOpen,
  }),

  setCurrentStage: (stage: PaymentModalStageEnum): BaseAction => ({
    type: PaymentModalActionsTypes.SET_CURRENT_STAGE,
    payload: stage,
  }),

  setIsClearTimer: (isClear: boolean): BaseAction => ({
    type: PaymentModalActionsTypes.SET_IS_CLEAR_TIMER,
    payload: isClear,
  }),

  setIsRedirectToSuccess: (isRedirect: boolean): BaseAction => ({
    type: PaymentModalActionsTypes.SET_IS_REDIRECT_TO_SUCCESS,
    payload: isRedirect,
  }),

  setIsRedirectToHome: (isRedirect: boolean): BaseAction => ({
    type: PaymentModalActionsTypes.SET_IS_REDIRECT_TO_HOME,
    payload: isRedirect,
  }),

  setIsRedirectToError: (isRedirect: boolean): BaseAction => ({
    type: PaymentModalActionsTypes.SET_IS_REDIRECT_TO_ERROR,
    payload: isRedirect,
  }),

  setRepairError: (error: ResponseError): BaseAction => ({
    type: PaymentModalActionsTypes.SET_REPAIR_ERROR,
    payload: error,
  }),
}

export type PaymentModalActionsType = typeof Actions

export function bindPaymentModalActions(dispatch: any) {
  return bindActionCreators(Actions, dispatch)
}
