import {PaymentModalStage} from './PaymentModalStage'
import {PaymentModalStageEnum} from './index'

const PAYMENT_MODAL_STAGE_MAP: PaymentModalStage = {
  WAITING_PAYMENT: [
    PaymentModalStageEnum.ERROR_PAYMENT,
    PaymentModalStageEnum.SUCCESS_PAYMENT,
    PaymentModalStageEnum.ERROR,
  ],
  SUCCESS_PAYMENT: [
    PaymentModalStageEnum.ORDER_TAKES_LONG_TIME_TO_REGISTER,
    PaymentModalStageEnum.RECEIPT_PRINTING,
    PaymentModalStageEnum.PARTIAL_REFUND,
    PaymentModalStageEnum.ERROR_UNIVERSAL,
  ],
  ERROR_PAYMENT: [PaymentModalStageEnum.WAITING_PAYMENT],
  ORDER_TAKES_LONG_TIME_TO_REGISTER: [
    PaymentModalStageEnum.RECEIPT_PRINTING,
    PaymentModalStageEnum.ERROR_REGISTER_ORDER,
    PaymentModalStageEnum.ERROR,
  ],
  RECEIPT_PRINTING: [PaymentModalStageEnum.SUCCESS, PaymentModalStageEnum.ERROR_RECEIPT_PRINTING],
  ERROR_RECEIPT_PRINTING: [PaymentModalStageEnum.RECEIPT_PRINTING, PaymentModalStageEnum.ERROR],
  ERROR_REGISTER_ORDER: [
    PaymentModalStageEnum.SUCCESS_FULL_REFUND,
    PaymentModalStageEnum.ERROR_FULL_REFUND,
    PaymentModalStageEnum.ERROR_FULL_REFUND_ALTERNATIVE,
  ],
  PARTIAL_REFUND: [
    PaymentModalStageEnum.SUCCESS_PARTIAL_REFUND,
    PaymentModalStageEnum.ERROR_PARTIAL_REFUND,
  ],
  SUCCESS_PARTIAL_REFUND: [PaymentModalStageEnum.RECEIPT_PRINTING],
  SUCCESS_FULL_REFUND: [PaymentModalStageEnum.ERROR],
  ERROR_PARTIAL_REFUND: [PaymentModalStageEnum.ERROR],
  ERROR_FULL_REFUND: [PaymentModalStageEnum.ERROR],
  ERROR_FULL_REFUND_ALTERNATIVE: [PaymentModalStageEnum.ERROR],
  ERROR_UNIVERSAL: [PaymentModalStageEnum.ERROR],
  ERROR: [],
  SUCCESS: [],
}

export {PAYMENT_MODAL_STAGE_MAP}
