import React, {useMemo} from 'react'
import {PaymentOrderCashIcon} from './PaymentOrderCashIcon'
import {PaymentOrderCardIcon} from './PaymentOrderCardIcon'
import {PaymentOrderOnlineIcon} from './PaymentOrderOnlineIcon'
import {PaymentTypeEnum, PaymentTypeNameEnum} from 'grilnica-share'
import {PrepareOrder} from 'grilnica-store-share'
import {Divider} from 'grilnica-reactstrap'

interface OrderPaymentMethodProps {
  prepareOrder: PrepareOrder
  setPrepareOrderField: (key: string, value: any, parentKey?: string) => void
  paymentTypes: PaymentTypeEnum[]
  numberBlock: number
}

const OrderPaymentMethod: React.FC<OrderPaymentMethodProps> = ({
  prepareOrder,
  setPrepareOrderField,
  paymentTypes,
  numberBlock,
}): React.ReactElement => {
  const renderPaymentMethod = useMemo(() => {
    if (paymentTypes) {
      let renderItems: React.ReactElement[] = paymentTypes.map((item) => {
        switch (item) {
          case PaymentTypeEnum.CASH: {
            return (
              <div className={'payment-type'} key={'payment-type-' + item}>
                <PaymentOrderCashIcon
                  isActive={prepareOrder.paymentType === item}
                  onClick={() => {
                    setPrepareOrderField('paymentType', item)
                  }}
                />
                <p
                  className={
                    'payment-type-name ' + (prepareOrder.paymentType === item ? 'active' : '')
                  }>
                  {PaymentTypeNameEnum[item]}
                </p>
              </div>
            )
          }
          case PaymentTypeEnum.CARD: {
            return (
              <div className={'payment-type'} key={'payment-type-' + item}>
                <PaymentOrderCardIcon
                  isActive={prepareOrder.paymentType === item}
                  onClick={() => {
                    setPrepareOrderField('paymentType', item)
                  }}
                />
                <p
                  className={
                    'payment-type-name ' + (prepareOrder.paymentType === item ? 'active' : '')
                  }>
                  {PaymentTypeNameEnum[item]}
                </p>
              </div>
            )
          }
          case PaymentTypeEnum.ONLINE: {
            return (
              <div className={'payment-type'} key={'payment-type-' + item}>
                <PaymentOrderOnlineIcon
                  isActive={prepareOrder.paymentType === item}
                  onClick={() => {
                    setPrepareOrderField('paymentType', item)
                  }}
                />
                <p
                  className={
                    'payment-type-name ' + (prepareOrder.paymentType === item ? 'active' : '')
                  }>
                  {PaymentTypeNameEnum[item]}
                </p>
              </div>
            )
          }
        }
      })
      return (
        <>
          <p className={'name-blocks'}>{`${numberBlock}. Способ оплаты`}</p>
          <div className={'d-flex flex-wrap'}>{renderItems}</div>
          <Divider className={'mb-x6'} />
        </>
      )
    }
  }, [numberBlock, paymentTypes, prepareOrder.paymentType, setPrepareOrderField])

  return <>{renderPaymentMethod}</>
}

export {OrderPaymentMethod}
