import {KEYS} from './keys'
import {isServer} from '../store'

function setStorageData(storageKey: string, value: any): void {
  if (!isServer) {
    try {
      localStorage.setItem(`${KEYS.APP_STORAGE_KEY}:${storageKey}`, JSON.stringify(value))
    } catch (e) {
      console.log(e)
    }
  }
}

function getStorageData(storageKey: string, defaultValue?: any): any {
  if (!isServer) {
    try {
      const value: any = localStorage.getItem(`${KEYS.APP_STORAGE_KEY}:${storageKey}`)
      if (value) {
        return JSON.parse(value)
      } else {
        return defaultValue ? defaultValue : null
      }
    } catch (e) {
      console.log(e)
    }
  } else {
    return null
  }
}

function clearData(): void {
  try {
    localStorage.clear()
  } catch (e) {
    console.log(e)
  }
}

function removeItem(storageKey: string): void {
  try {
    localStorage.removeItem(`${KEYS.APP_STORAGE_KEY}:${storageKey}`)
  } catch (e) {
    console.log(e)
  }
}

export {setStorageData, getStorageData, clearData, removeItem}
