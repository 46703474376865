import React, {useCallback, useMemo, useState} from 'react'
import {ProductDTO} from 'grilnica-store-share'
import {ButtonIcon, Col} from 'grilnica-reactstrap'
import {CONSTANTS} from '../../../../../../../../../../constants'
import {informationOutlineSVG} from '../../../../../../../../components/svg/SystemIcons'
import {LazyImage} from '../../../../../../../../components/lazyImage/LazyImage'
import {ProductPreviewModal} from './ProductPreviewModal'

interface ProductPreviewCardItemProps {
  product: ProductDTO
  priceBlock: React.ReactElement
  productLink: string
  weight: string
  isForSale: boolean
}

const ProductPreviewCardItem: React.FC<ProductPreviewCardItemProps> = ({
  product,
  priceBlock,
  productLink,
  weight,
  isForSale,
}): React.ReactElement => {
  const [isOpen, setIsOpen] = useState<boolean>(false)

  const onOpen = useCallback(() => {
    setIsOpen(true)
  }, [])

  const onClose = useCallback(() => {
    setIsOpen(false)
  }, [])

  const renderImage = useMemo(() => {
    return (
      <div className={'ppci-img'}>
        <LazyImage
          src={CONSTANTS.cdnUrl + '/' + product.imageFileName}
          typePlaceholder={'product'}
          alt={product.name}
          title={product.name}
        />
      </div>
    )
  }, [product.imageFileName, product.name])

  const renderInfo = useMemo(() => {
    return (
      <div className={'d-flex justify-content-between w-100 py-x2'}>
        <div className={'d-flex flex-column pl-x2 pr-x6 mr-x3'}>
          <p className={'text-body-2 font-weight-medium ' + (weight ? 'mb-x1' : '')}>
            {product.name}
          </p>
          {weight && <p className={'caption-2 text-gray-700 mb-0'}>{weight}</p>}
        </div>
      </div>
    )
  }, [product.name, weight])

  const renderContent: React.ReactElement = useMemo(() => {
    return (
      <Col md={6} className={'mb-x4'}>
        <div className={'product-preview-card-item'}>
          {renderImage}
          {renderInfo}
          <ButtonIcon
            iconPath={informationOutlineSVG}
            isHoverArea
            title={'Подробнее'}
            className={'ppci-button'}
            onClick={onOpen}
          />
        </div>
        {isOpen && (
          <ProductPreviewModal
            product={product}
            weight={weight}
            priceBlock={priceBlock}
            productLink={productLink}
            isNotForSale={!isForSale}
            onClose={onClose}
            isOpen={isOpen}
          />
        )}
      </Col>
    )
  }, [
    isForSale,
    isOpen,
    onClose,
    onOpen,
    priceBlock,
    product,
    productLink,
    renderImage,
    renderInfo,
    weight,
  ])

  return <>{renderContent}</>
}

export {ProductPreviewCardItem}
