import React, {useCallback, useMemo} from 'react'
import {
  Button,
  Col,
  Container,
  CustomCheckbox,
  InputVertical,
  LoadingModal,
  Row,
} from 'grilnica-reactstrap'
import {TabAuthNav} from './TabAuthNav'
import {AuthType} from '../SingUpPage'
import {ContactDetailsMap, PhoneConfirmationTokenDTO} from 'grilnica-store-share'
import {CONSTANTS} from '../../../../../../constants'
import {PhoneConfirmBlock} from './PhoneConfirmBlock'

interface SingUpComponentProps {
  authType: AuthType
  onChangeAuthType: (signInType: 'account' | 'fast') => void
  phoneNumber: string
  currentOneTimeCode: string
  handleChangeProps: (key: string, value: string | boolean) => void
  agreementSms: boolean
  sendPhoneNumber: () => void
  sendOrder: () => void
  oneTimeCodeFailureMessage: string
  isSubmitCodeDisabled: boolean
  sendPhoneConfirmationToken: () => void
  oneTimeCodeTimer: number
  phoneConfirmationObject: PhoneConfirmationTokenDTO
  isRequestSaveOrder: boolean
  phoneNumberForSend: string
  handleChangeForSendProps: (key: string, value: string | boolean) => void
  agreementSmsForSend: boolean
  isShowFastOrder: boolean
  isSendingPhoneConfirmationTokenId: boolean
  phone: string
  phoneForCall: string | undefined
  onChangeConfirmAuthType: (type: 'main' | 'additional') => void
  codeSix: string | undefined
  confirmType: 'main' | 'additional'
  isShowInfoAlert: boolean
  contactDetailsMap: ContactDetailsMap
  isAdditionalAuth: boolean
}

const SingUpComponent: React.FC<SingUpComponentProps> = ({
  authType,
  onChangeAuthType,
  phoneNumber,
  handleChangeProps,
  agreementSms,
  sendPhoneNumber,
  sendOrder,
  oneTimeCodeFailureMessage,
  isSubmitCodeDisabled,
  sendPhoneConfirmationToken,
  oneTimeCodeTimer,
  currentOneTimeCode,
  phoneConfirmationObject,
  isRequestSaveOrder,
  phoneNumberForSend,
  handleChangeForSendProps,
  agreementSmsForSend,
  isShowFastOrder,
  isSendingPhoneConfirmationTokenId,
  phone,
  phoneForCall,
  onChangeConfirmAuthType,
  codeSix,
  confirmType,
  isShowInfoAlert,
  contactDetailsMap,
  isAdditionalAuth,
}): React.ReactElement => {
  const renderHeader: React.ReactElement = useMemo(() => {
    return (
      <React.Fragment>
        <h1 className={'h1 text-center mb-x2'}>
          {authType === 'account' || phoneConfirmationObject ? 'Войти' : 'Быстрый заказ'}
        </h1>

        {isShowFastOrder && (
          <TabAuthNav signInType={authType} onChangeAuthType={onChangeAuthType} />
        )}
      </React.Fragment>
    )
  }, [authType, onChangeAuthType, phoneConfirmationObject, isShowFastOrder])

  const getLoginBlock = useCallback(
    (type: 'account' | 'fast') => {
      return (
        <div>
          <p className={'auth-caption mb-x8 pt-x2'}>
            {type === 'account'
              ? 'Введите номер телефона, чтобы продолжить оформление заказа'
              : 'Введите номер телефона, с вами свяжется оператор в ближайшее время'}
          </p>
          {type === 'account' ? (
            <InputVertical
              id={'input-phone-number'}
              name={'phoneNumber'}
              type={'text'}
              label={'Введите номер вашего телефона'}
              placeholder={'+7'}
              value={phoneNumber}
              isRequired={true}
              isRounded
              onChange={(value: string) => {
                handleChangeProps('phoneNumber', value)
              }}
            />
          ) : (
            <InputVertical
              id={'input-phone-number-for-send'}
              name={'phoneNumberForSend'}
              type={'text'}
              label={'Введите номер вашего телефона'}
              placeholder={'+7'}
              value={phoneNumberForSend}
              isRequired={true}
              isRounded
              onChange={(value: string) => {
                handleChangeForSendProps('phoneNumberForSend', value)
              }}
            />
          )}
          {type === 'account' ? (
            <Button
              color={'primary'}
              isRoundedButton
              disabled={!agreementSms || phoneNumber.length < 16}
              className={'w-100 mb-x8'}
              onClick={() => {
                sendPhoneNumber()
              }}>
              Далее
            </Button>
          ) : (
            <Button
              isRoundedButton
              className={'w-100 mb-x8'}
              color={'primary'}
              disabled={phoneNumberForSend.length < 16}
              onClick={() => {
                sendOrder()
              }}>
              Завершить оформление
            </Button>
          )}
          {type === 'account' ? (
            <CustomCheckbox
              id={'agreementSms'}
              label={'Согласие на получение sms'}
              checked={agreementSms}
              className={'mb-x8 text-center'}
              onChange={() => {
                handleChangeProps('agreementSms', !agreementSms)
              }}
              color={'red'}
            />
          ) : (
            <CustomCheckbox
              id={'agreementSmsForSend'}
              label={'Согласие на получение sms'}
              checked={agreementSmsForSend}
              className={'mb-x8 text-center'}
              onChange={() => {
                handleChangeForSendProps('agreementSmsForSend', !agreementSmsForSend)
              }}
              color={'red'}
            />
          )}
          <p className={'text-center mb-0 consent-condition'}>
            Нажимая кнопку «{type === 'account' ? 'Далее' : 'Завершить оформление'}», я выражаю{' '}
            <a className={'color-link-text'} href={CONSTANTS.cdnUrl + '/dataconsent.pdf'}>
              согласие
            </a>{' '}
            на{' '}
            <a className={'color-link-text'} href={CONSTANTS.cdnUrl + '/agreement.pdf'}>
              обработку моих персональных данных
            </a>{' '}
            и соглашаюсь с{' '}
            <a className={'color-link-text'} href={CONSTANTS.cdnUrl + '/offer.pdf'}>
              условиями публичной оферты
            </a>
            .
          </p>
        </div>
      )
    },
    [
      agreementSms,
      agreementSmsForSend,
      handleChangeForSendProps,
      handleChangeProps,
      phoneNumber,
      phoneNumberForSend,
      sendOrder,
      sendPhoneNumber,
    ],
  )

  const renderPhoneConfirmBlock: React.ReactElement = useMemo(() => {
    return (
      <PhoneConfirmBlock
        currentOneTimeCode={currentOneTimeCode}
        handleChangeProps={handleChangeProps}
        sendPhoneNumber={sendPhoneNumber}
        oneTimeCodeFailureMessage={oneTimeCodeFailureMessage}
        isSubmitCodeDisabled={isSubmitCodeDisabled}
        sendPhoneConfirmationToken={sendPhoneConfirmationToken}
        oneTimeCodeTimer={oneTimeCodeTimer}
        isSendingPhoneConfirmationTokenId={isSendingPhoneConfirmationTokenId}
        phone={phone}
        phoneForCall={phoneForCall}
        onChangeConfirmAuthType={onChangeConfirmAuthType}
        codeSix={codeSix}
        confirmType={confirmType}
        isShowInfoAlert={isShowInfoAlert}
        contactDetailsMap={contactDetailsMap}
        isAdditionalAuth={isAdditionalAuth}
      />
    )
  }, [
    currentOneTimeCode,
    handleChangeProps,
    sendPhoneNumber,
    oneTimeCodeFailureMessage,
    isSubmitCodeDisabled,
    sendPhoneConfirmationToken,
    oneTimeCodeTimer,
    isSendingPhoneConfirmationTokenId,
    phone,
    phoneForCall,
    onChangeConfirmAuthType,
    codeSix,
    confirmType,
    isShowInfoAlert,
    contactDetailsMap,
    isAdditionalAuth,
  ])

  const renderContent: React.ReactElement = useMemo(() => {
    return (
      <Container size={'normal'} className={'mt-x10'}>
        <Row className={(isShowFastOrder ? 'mobile-block ' : ' ') + 'mb-x4'}>
          <Col
            lg={{size: 4, offset: 4}}
            md={{size: 6, offset: 3}}
            sm={{size: 8, offset: 2}}
            xs={{size: 10, offset: 1}}
            className={'auth-card p-x4'}>
            {renderHeader}
            {!phoneConfirmationObject && getLoginBlock(authType)}
            {phoneConfirmationObject && renderPhoneConfirmBlock}
          </Col>
        </Row>
        {isShowFastOrder && (
          <div className={'web-block'}>
            <Col lg={4} md={4} className={'auth-card p-x4'}>
              <h2 className={'h1 text-center mb-x2'}>Войти</h2>
              {!phoneConfirmationObject && getLoginBlock('account')}
              {phoneConfirmationObject && renderPhoneConfirmBlock}
            </Col>
            <div className={'block-divider'}>
              <div className={'line-divider'} />
              <p>или</p>
              <div className={'line-divider'} />
            </div>
            <Col lg={4} md={4} className={'auth-card p-x4'}>
              <h2 className={'h1 text-center mb-x2'}>Быстрый заказ</h2>
              <div>
                <p className={'auth-caption mb-x8 pt-x2'}>
                  Введите номер телефона, с вами свяжется оператор в ближайшее время
                </p>
                <InputVertical
                  id={'input-phone-number-for-send-web'}
                  name={'phoneNumberForSend'}
                  type={'text'}
                  label={'Введите номер вашего телефона'}
                  placeholder={'+7'}
                  value={phoneNumberForSend}
                  isRequired={true}
                  isRounded
                  onChange={(value: string) => {
                    handleChangeForSendProps('phoneNumberForSend', value)
                  }}
                />
                <Button
                  isRoundedButton
                  className={'w-100 mb-x8'}
                  color={'primary'}
                  disabled={phoneNumberForSend.length < 16}
                  onClick={() => {
                    sendOrder()
                  }}>
                  Завершить оформление
                </Button>
                <CustomCheckbox
                  id={'agreementSmsForSendWeb'}
                  label={'Согласие на получение sms'}
                  checked={agreementSmsForSend}
                  className={'mb-x8 text-center'}
                  onChange={() => {
                    handleChangeForSendProps('agreementSmsForSend', !agreementSmsForSend)
                  }}
                  color={'red'}
                />
                <p className={'text-center mb-0 consent-condition'}>
                  Нажимая кнопку «Завершить оформление», я выражаю согласие на обработку моих{' '}
                  <a className={'color-link-text'} href={CONSTANTS.cdnUrl + '/agreement.pdf'}>
                    персональных данных
                  </a>{' '}
                  и соглашаюсь с{' '}
                  <a className={'color-link-text'} href={CONSTANTS.cdnUrl + '/offer.pdf'}>
                    условиями публичной оферты
                  </a>
                </p>
              </div>
            </Col>
          </div>
        )}
        {isRequestSaveOrder && (
          <LoadingModal isOpen={isRequestSaveOrder} loadingTitle={'Регистрируем ваш заказ'} />
        )}
      </Container>
    )
  }, [
    phoneConfirmationObject,
    renderHeader,
    getLoginBlock,
    authType,
    renderPhoneConfirmBlock,
    phoneNumberForSend,
    agreementSmsForSend,
    isRequestSaveOrder,
    handleChangeForSendProps,
    sendOrder,
    isShowFastOrder,
  ])

  return <React.Fragment>{renderContent}</React.Fragment>
}

export {SingUpComponent}
