import React, {useEffect, useMemo, useState} from 'react'
import {connectComponent} from '../../../../../../store/common'
import {
  MenuCategoryDTO,
  ObjectUrlDTO,
  ProductMap,
  RestaurantByProductMap,
} from 'grilnica-store-share'
import {HtmlView} from 'grilnica-ckeditor'
import {ProductList} from './productList/ProductList'
import {ButtonIcon, Col, Collapse, Container, Loading, Row} from 'grilnica-reactstrap'
import {ProductDTO} from 'grilnica-store-share/lib/product/ProductDTO'
import {ProductListFilter} from './productList/ProductListFilter'
import {categoryInfoSVG} from '../../../../../components/svg'
import {isShowMenuCategory} from '../../../../../../utils/menu/isShowMenuCategory'
import {InitializeState} from '../../../../../../store/ducks/initialize'
import {IsShowRestaurantByProductMap} from '../../../../../../types/restaurantProduct/IsShowRestaurantByProductMap'
import {State} from '../../../../../../store/ducks'
import {chevronDownSVG, chevronUpSVG} from '../../../../../components/svg/SystemIcons'
import {CityDTO, isEmptyObject} from 'grilnica-share'
import {trimUrl} from '../../../../../../utils/url'
import {NotFoundPage} from '../../../../../components/common/NotFoundPage'
import {AliasObjectMap} from '../../../../../../types/common/AliasObjectMap'
import {MenuBreadcrumbs} from './components/MenuBreadcrumbs'

interface ProductListPageProps {
  alias: string
  childAlias: string
  productMap: ProductMap
  categoryList: MenuCategoryDTO[]
  restaurantByProductMap: RestaurantByProductMap
  initialize: InitializeState
  isShowRestaurantByProductMap: IsShowRestaurantByProductMap
  objectUrlMap: ObjectUrlDTO
  selectedCity: CityDTO
  isTerminal: boolean
  aliasProductCategoryMap: AliasObjectMap
}

const ProductListPage: React.FC<ProductListPageProps> = ({
  alias,
  childAlias,
  productMap,
  categoryList,
  restaurantByProductMap,
  initialize,
  isShowRestaurantByProductMap,
  objectUrlMap,
  selectedCity,
  isTerminal,
  aliasProductCategoryMap,
}): React.ReactElement => {
  const [isOpenCollapse, setIsOpenCollapse] = useState<boolean>(false)
  const [productList, setProductList] = useState<ProductDTO[]>(initialize.productList)

  const [newCategoryList, setNewCategoryList] = useState<MenuCategoryDTO[]>(
    initialize.newCategoryList,
  )
  const [categories, setCategories] = useState<MenuCategoryDTO[]>(initialize.categories)

  useEffect(() => {
    //TODO:: не работает нормально
    window.scrollTo(0, 0)
  }, [alias])

  const getCategoryList = useMemo((): MenuCategoryDTO[] => {
    let newCategories: MenuCategoryDTO[] = []
    if (!isEmptyObject(productMap)) {
      for (let category of categoryList) {
        if (
          isShowMenuCategory(
            category,
            productMap,
            categoryList,
            isShowRestaurantByProductMap,
            isTerminal,
          )
        ) {
          newCategories.push(category)
        }
      }
    }
    return newCategories
  }, [categoryList, isShowRestaurantByProductMap, productMap, isTerminal])

  useEffect(() => {
    setNewCategoryList(getCategoryList)
  }, [])

  useEffect(() => {
    setCategories(null)
    if (newCategoryList) {
      let activeCategory: MenuCategoryDTO = null
      for (let item of newCategoryList) {
        if (item.alias === (childAlias ? childAlias : alias)) {
          activeCategory = item
          break
        }
      }
      if (!productMap[activeCategory?.menuCategoryId]) {
        let newProductList: ProductDTO[] = []
        let newCategories: MenuCategoryDTO[] = []
        for (let item of newCategoryList) {
          if (item.parentMenuCategoryId === activeCategory?.menuCategoryId) {
            if (productMap[item.menuCategoryId]) {
              for (let itemP of productMap[item.menuCategoryId]) {
                if (
                  newProductList.findIndex((product) => product.productId === itemP.productId) ===
                  -1
                ) {
                  newProductList.push(...(productMap[item.menuCategoryId] || []))
                }
              }
            }
            newCategories.push(item)
          }
        }
        setProductList([...newProductList])
        setCategories(newCategories)
      } else {
        let newProductList: ProductDTO[] = productMap[activeCategory?.menuCategoryId]
        setProductList([...newProductList])
        let newCategories: MenuCategoryDTO[] = []
        if (childAlias) {
          for (let item of newCategoryList) {
            if (item.parentMenuCategoryId === activeCategory.parentMenuCategoryId) {
              newCategories.push(item)
            }
          }
        }
        for (let item of newCategoryList) {
          if (item.parentMenuCategoryId === activeCategory?.menuCategoryId) {
            newCategories.push(item)
          }
        }
        setCategories(newCategories)
      }
    }
  }, [alias, newCategoryList, childAlias, productMap])

  const renderInfo: React.ReactElement = useMemo(() => {
    let info: React.ReactNode = null
    if (objectUrlMap?.objectUrlSeo?.title) {
      const {title, content} = objectUrlMap.objectUrlSeo
      let newTitle: string = title
      if (newTitle?.length > 0) {
        while (newTitle.indexOf('{=cityName}') !== -1) {
          newTitle = newTitle.replace('{=cityName}', selectedCity.name)
        }
      }
      let newContent: string = content
      if (newContent?.length > 0) {
        while (newContent.indexOf('{=cityName}') !== -1) {
          newContent = newContent.replace('{=cityName}', selectedCity.name)
        }
      }
      info = (
        <div className={'seo-content pt-x4'}>
          <Row className={'mb-x6'}>
            <Col md={{size: 8, offset: 2}}>
              <h1 className={'h1 seo-content-h1 mb-x4 '}>{newTitle}</h1>
              {newContent && (
                <Collapse isOpen={isOpenCollapse} isUnmount>
                  <HtmlView html={newContent} />
                </Collapse>
              )}
              {newContent && (
                <div
                  className={'collapse-open-block mt-x2'}
                  onClick={() => {
                    setIsOpenCollapse(!isOpenCollapse)
                  }}>
                  <ButtonIcon
                    iconPath={isOpenCollapse ? chevronUpSVG : chevronDownSVG}
                    title={isOpenCollapse ? 'Свернуть' : 'Развернуть'}
                  />
                </div>
              )}
            </Col>
          </Row>
        </div>
      )
    }
    return (
      <div>
        {info}
        <Row>
          <Col md={{size: 8, offset: 2}}>
            <div className={'category-info'}>
              {categoryInfoSVG}
              <p className={'mb-0'}>
                Фото продукции может отличаться от рекламного изображения товара.
                <br />
                Внимание! Уважаемые клиенты, при исключении одного или нескольких ингредиентов из
                блюда, вес готовой продукции будет отличаться от указанной на сайте
                <br />
                Ассортимент блюд каждого отдельного ресторана сети Грильница может не
                соответствовать ассортименту, представленному на сайте.
                <br />
                Актуальную информацию вы можете уточнить у операторов или кассиров.
                <br />
                <span className={'description'}>
                  Все блюда Грильницы рекомендуется употребить сразу. Срок хранения блюд ограничен.
                </span>
              </p>
            </div>
          </Col>
        </Row>
      </div>
    )
  }, [isOpenCollapse, objectUrlMap?.objectUrlSeo, selectedCity])

  const renderContent: React.ReactElement = useMemo(() => {
    let title: string = null
    if (newCategoryList) {
      for (let category of newCategoryList) {
        if (category.alias === alias) {
          title = category.name
          break
        }
      }
    }
    let isActionsCategory: boolean = false
    if (childAlias) {
      if (childAlias.replace(alias, '') === '-actions') {
        isActionsCategory = true
      }
    }
    return productList ? (
      <div>
        {objectUrlMap?.objectUrlSeo?.title ? (
          <p className={'title-category'}>{title}</p>
        ) : (
          <h1 className={'title-category'}>{title}</h1>
        )}
        {categories?.length > 0 && (
          <ProductListFilter
            alias={alias}
            childAlias={childAlias}
            categories={categories}
            categoryList={newCategoryList}
          />
        )}
        <ProductList
          restaurantByProductMap={restaurantByProductMap}
          productList={productList}
          url={'/menu/' + alias + (childAlias && !isActionsCategory ? '/' + childAlias : '')}
          productMap={productMap}
          newCategoryList={newCategoryList}
          menuAlias={childAlias && !isActionsCategory ? childAlias : alias}
          isShowRestaurantByProductMap={isShowRestaurantByProductMap}
          isTerminal={isTerminal}
        />
        {renderInfo}
      </div>
    ) : (
      <Loading />
    )
  }, [
    newCategoryList,
    productList,
    objectUrlMap?.objectUrlSeo?.title,
    categories,
    alias,
    childAlias,
    restaurantByProductMap,
    productMap,
    isShowRestaurantByProductMap,
    isTerminal,
    renderInfo,
  ])

  if (!aliasProductCategoryMap[childAlias ? childAlias : alias]) {
    return <NotFoundPage />
  }

  return (
    <div className={'d-flex flex-column justify-content-between flex-grow-1'}>
      <Container size={'normal'} className={'d-flex flex-column mt-x10 mb-x10'}>
        {renderContent}
      </Container>
      <MenuBreadcrumbs
        alias={alias}
        childAlias={childAlias}
        productAlias={undefined}
        aliasProductCategoryMap={aliasProductCategoryMap}
        categoryList={categoryList}
        productMap={productMap}
      />
    </div>
  )
}

export default connectComponent(
  (state: State, props: any) => ({
    alias: props.match.params.alias,
    childAlias: props.match.params.childAlias,
    productMap: state.menu.productMap,
    categoryList: state.menu.categoryList,
    isShowRestaurantByProductMap: state.restaurantProduct.isShowRestaurantByProductMap ?? {},
    isTerminal: state.terminal?.terminalAlias?.length > 0,
    initialize: state.initialize,
    restaurantByProductMap: state.restaurantProduct.restaurantByProductMap,
    objectUrlMap: !isEmptyObject(state.common.objectUrlMap)
      ? state.common.objectUrlMap[trimUrl(props.match.url)]
      : {},
    selectedCity: state.city.selectedCity,
    aliasProductCategoryMap: state.common.aliasProductCategoryMap,
  }),
  () => ({}),
  ProductListPage,
)
