import React, {ReactNode, useCallback, useEffect, useMemo, useState} from 'react'
import {Button, ButtonIcon, Col, Container, Divider, Loading, Row} from 'grilnica-reactstrap'
import {OrderStatusIcon} from './components/OrderStatusIcon'
import {OrderDTO} from 'grilnica-store-share/lib/order/OrderDTO'
import {getOrderService} from '../../../../services/order'
import {DeliveryTypeEnum, OrderStatusNameEnum} from 'grilnica-store-share'
import moment from 'moment-timezone'
import {getFullAddress} from '../../utils/AddressUtils'
import {
  CityDTO,
  formatDateTime,
  getIntervalTime,
  isEmpty,
  PaymentTypeEnum,
  PaymentTypeNameEnum,
  round,
  transformVariable,
} from 'grilnica-share'
import {OrderHistoryItem} from './components/OrderHistoryItem'
import {onChangePhone} from '../../utils/UserUtils'
import {connectComponent} from '../../../../store/common'
import {bindOrderActions} from '../../../../store/ducks/order'
import {arrowLeftSVG} from '../../../components/svg/SystemIcons'
import {State} from '../../../../store/ducks'
import {formatDeliveryTime} from 'grilnica-share/lib/utils'

interface OrderHistoryPageProps {
  orderId: string
  history?: any
  orderActions?: any
  selectedCity: CityDTO
}

const OrderHistoryPage: React.FC<OrderHistoryPageProps> = ({
  history,
  orderId,
  orderActions,
  selectedCity,
}): React.ReactElement => {
  const [order, setOrder] = useState<OrderDTO>(null)

  const loadOrder = useCallback(async () => {
    const order: OrderDTO = await getOrderService(orderId, selectedCity?.cityId)
    setOrder(order)
  }, [orderId, selectedCity?.cityId])

  useEffect(() => {
    loadOrder()
  }, [loadOrder])

  const goBack = () => {
    history.goBack()
  }

  const repeatOrder = useCallback(() => {
    orderActions.repeatOrder(orderId)
  }, [orderActions, orderId])

  const renderOrderStatus = useMemo(() => {
    if (order?.status) {
      return (
        <div className={'order-status-block py-x4 mb-x4'}>
          <OrderStatusIcon orderStatus={order.status} className={'mr-i-x5'} />
          <div>
            <p className={'order-status mb-x1'}>
              Статус заказа: <span>{OrderStatusNameEnum[order.status]}</span>
            </p>
            <p className={'profile-order-time mb-0'}>
              {moment(order.statusDate).format('DD.MM.YYYY в HH:mm')}
            </p>
          </div>
        </div>
      )
    }
  }, [order])

  const renderOrderPayment = useMemo(() => {
    if (order?.paymentType === PaymentTypeEnum.ONLINE) {
      return <p className={'info info-mobile'}>{order?.isPaid ? 'Оплачено' : 'В обработке'}</p>
    } else {
      return <p className={'info info-mobile'}>Оплата при получении</p>
    }
  }, [order?.isPaid, order?.paymentType])

  const getDeliveryTimeText = useMemo(() => {
    if (order) {
      moment.locale('ru')
      const orderTime: Date = order?.prepareTime.orderTime as Date
      if (order?.prepareTime.isQuickly) {
        return order.deliveryTimeInMinutes
          ? 'В течение ' + formatDeliveryTime(order.deliveryTimeInMinutes)
          : 'Как можно скорее'
      } else {
        let day: string[] = moment(orderTime).format('LL').split(' ')
        if (order.deliveryType === DeliveryTypeEnum.DELIVERY) {
          return (
            day[0] +
            ' ' +
            day[1] +
            ' ' +
            getIntervalTime(moment(orderTime).format('LT'), 0, 30, 'с --:-- до --:--')
          )
        } else {
          return day[0] + ' ' + day[1] + ' в ' + moment(orderTime).format('LT')
        }
      }
    }
  }, [order])

  const renderOrderInfo = useMemo(() => {
    if (order) {
      return (
        <div className={'info-block'}>
          <p className={'caption-order-info mb-x6'}>Информация о заказе</p>
          <Row>
            <Col lg={3} md={4} sm={6}>
              <div className={'order-info-block'}>
                <p className={'info-name'}>Имя:</p>
                <p className={'info'}>{order.customer}</p>
              </div>
            </Col>
            <Col lg={3} md={4} sm={6}>
              <div className={'order-info-block'}>
                <p className={'info-name'}>Телефон:</p>
                <p className={'info'}>{onChangePhone(order.phone, order.phone)}</p>
              </div>
            </Col>
            <Col lg={3} md={4} sm={6}>
              <div className={'order-info-block'}>
                <p className={'info-name'}>
                  {order.deliveryType === DeliveryTypeEnum.DELIVERY
                    ? 'Адрес доставки:'
                    : 'Ресторан:'}
                </p>
                <p className={'info'}>
                  {transformVariable(
                    order.deliveryType === DeliveryTypeEnum.DELIVERY
                      ? getFullAddress(order.address)
                      : order.restaurantName,
                  )}
                </p>
              </div>
            </Col>
            <Col lg={3} md={4} sm={6}>
              <div className={'order-info-block'}>
                <p className={'info-name'}>Тип оплаты:</p>
                <p className={'info'}>
                  {transformVariable(PaymentTypeNameEnum[order.paymentType])}
                </p>
              </div>
            </Col>
            <Col lg={3} md={4} sm={6}>
              <div className={'order-info-block'}>
                <p className={'info-name info-mobile'}>Оплата:</p>
                {renderOrderPayment}
              </div>
            </Col>
            {order.iikoSaveTime && (
              <Col lg={3} md={4} sm={6}>
                <div className={'order-info-block'}>
                  <p className={'info-name'}>Время оформления:</p>
                  <p className={'info info-mobile'}>{formatDateTime(order.iikoSaveTime)}</p>
                </div>
              </Col>
            )}
            <Col lg={3} md={4} sm={6}>
              <div className={'order-info-block'}>
                <p className={'info-name'}>Ожидайте заказ:</p>
                <p className={'info info-mobile'}>{getDeliveryTimeText}</p>
              </div>
            </Col>
            {order.commentary && (
              <Col lg={3} md={4} sm={6}>
                <div className={'order-info-block'}>
                  <p className={'info-name'}>Комментарий:</p>
                  <p className={'info mb-x2'}>{order.commentary}</p>
                </div>
              </Col>
            )}
          </Row>
        </div>
      )
    }
  }, [getDeliveryTimeText, order, renderOrderPayment])

  const renderProductItem = useMemo(() => {
    if (order && order.products) {
      const items: ReactNode[] = order.products.map((item) => (
        <OrderHistoryItem orderProduct={item} key={item.productVariantId} />
      ))
      return items
    } else {
      return <Loading />
    }
  }, [order])

  const renderPriceWithoutSale: React.ReactElement = useMemo(() => {
    if (order) {
      const price: number = round(
        order.priceFull - (!isEmpty(order.priceResult) ? order.priceResult : order.priceFull),
      )
      if (!!price || !!order.deliverySale) {
        let priceFull =
          order.priceFull -
          transformVariable(order.deliveryPrice, 0) +
          transformVariable(order.deliverySale, 0)
        return (
          <div className={'price-block text-gray-900'}>
            <p className={'price-name'}>Без скидки:</p>
            <p className={'price'}>{priceFull} &#8381;</p>
          </div>
        )
      } else {
        return null
      }
    } else {
      return null
    }
  }, [order])

  const renderDeliverySale: React.ReactElement = useMemo(() => {
    if (order) {
      const price: number = order.deliverySale
      if (!!price) {
        return (
          <div className={'price-block text-gray-900'}>
            <p className={'price-name'}>Бонус за доставку {price}:</p>
            <p className={'price'}>-{round(price)} &#8381;</p>
          </div>
        )
      } else {
        return null
      }
    } else {
      return null
    }
  }, [order])

  const renderPriceSale: React.ReactElement = useMemo(() => {
    if (order) {
      const price: number = round(
        order.priceFull - (!isEmpty(order.priceResult) ? order.priceResult : order.priceFull),
      )
      if (!!price) {
        return (
          <div className={'price-block text-gray-900'}>
            <p className={'price-name'}>Скидки по акциям:</p>
            <p className={'price'}>-{round(price)} &#8381;</p>
          </div>
        )
      } else {
        return null
      }
    } else {
      return null
    }
  }, [order])

  const renderOrderPrice: React.ReactElement = useMemo(() => {
    if (order?.deliveryType === DeliveryTypeEnum.DELIVERY) {
      const totalPrice = !isEmpty(order.priceResult) ? order.priceResult : order.priceFull
      return (
        <div className={'price-block text-gray-900 mb-0'}>
          <p className={'price-name'}>Сумма заказа:</p>
          <p className={'price'}>
            {totalPrice - transformVariable(order.deliveryPrice, 0)} &#8381;
          </p>
        </div>
      )
    } else {
      return null
    }
  }, [order])

  const renderDeliveryPrice: React.ReactElement = useMemo(() => {
    if (order?.deliveryType === DeliveryTypeEnum.DELIVERY) {
      const isSeparateDeliveryPayment = order.isSeparateDeliveryPayment && order.deliveryPrice
      return (
        <>
          <Divider isDashed className={'my-x4'} />
          <div className={'price-block text-gray-900 mb-0'}>
            <div className={'d-flex flex-column'}>
              <p className={'price-name'}>Стоимость доставки:</p>
              {isSeparateDeliveryPayment ? (
                <p className={'separate-delivery-block caption mb-0'}>
                  (оплачивается курьеру отдельно)
                </p>
              ) : null}
            </div>
            {order.deliveryPrice || order.deliveryPrice === 0 ? (
              <p className={'price'}>{order.deliveryPrice} &#8381;</p>
            ) : (
              <p className={'price'}>-</p>
            )}
          </div>
          <Divider isDashed className={'mt-x4'} />
        </>
      )
    }
  }, [order?.deliveryPrice, order?.deliveryType, order?.isSeparateDeliveryPayment])

  const renderPriceResult: React.ReactElement = useMemo(() => {
    if (order) {
      const totalPrice = !isEmpty(order.priceResult) ? order.priceResult : order.priceFull
      return (
        <div className={'price-block sum-to-pay mb-0 mt-x4'}>
          <p className={'price-name'}>Итого:</p>
          <p className={'price'}>{round(totalPrice)} &#8381;</p>
        </div>
      )
    } else {
      return null
    }
  }, [order])

  const renderPriceSum = useMemo(() => {
    if (order) {
      return (
        <div className={'mt-x2'}>
          {renderPriceWithoutSale}
          {renderDeliverySale}
          {renderPriceSale}
          {renderOrderPrice}
          {renderDeliveryPrice}
          {renderPriceResult}
        </div>
      )
    }
  }, [
    order,
    renderDeliveryPrice,
    renderDeliverySale,
    renderOrderPrice,
    renderPriceResult,
    renderPriceSale,
    renderPriceWithoutSale,
  ])

  const renderRepeatOrder: React.ReactElement = useMemo(() => {
    return (
      <div className={'d-flex justify-content-center mt-x4'}>
        <Button color={'primary'} isRoundedButton className={'repeat-order'} onClick={repeatOrder}>
          Добавить в корзину
        </Button>
      </div>
    )
  }, [repeatOrder])

  if (!order) {
    //TODO:: testing
    return null
  }

  return (
    <Container size={'normal'} className={'mt-x10 mb-x10'}>
      <Col md={{size: 8, offset: 2}} className={'order-history-card'}>
        <div className={'d-flex align-items-center justify-content-center mb-x4'}>
          <ButtonIcon
            iconPath={arrowLeftSVG}
            isHoverArea
            title={'Назад'}
            className={'mr-x4'}
            onClick={() => {
              goBack()
            }}
          />
          <h1 className={'h2 mb-0'}>Заказ {order?.number}</h1>
        </div>
        <Divider />

        {renderOrderStatus}
        {renderOrderInfo}
        {renderProductItem}
        {renderPriceSum}
        {renderRepeatOrder}
      </Col>
    </Container>
  )
}

export default connectComponent(
  (state: State, props) => ({
    orderId: props.match.params.orderId,
    selectedCity: state.city.selectedCity,
  }),
  (dispatch) => ({
    orderActions: bindOrderActions(dispatch),
  }),
  OrderHistoryPage,
)
