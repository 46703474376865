import {bindActionCreators} from 'redux'
import {BaseAction} from '../common/BaseAction'
import {AreaOfDeliveryDTO, ClientAddressDTO, RegionOfDeliveryDTO} from 'grilnica-store-share'
import {AreaRegionOfDeliveryDTO} from '../../types/address/AreaRegionOfDeliveryDTO'
import {LatLng} from '../../types/address/LatLng'
import {AddressInMap} from '../../types/address/AddressInMap'

export interface AddressState {
  coordinates: LatLng
  selectedAddressInDeliveryMap: AddressInMap
  addressSearchValue: string
  foundAddresses: AddressInMap[]
  areaRegionOfDeliveryList: AreaRegionOfDeliveryDTO[]
  selectedRegionOfDeliveryInDeliveryMap: RegionOfDeliveryDTO
  deliveryPriceInMap: number
}

export enum AddressActionsTypes {
  CHANGE_COORDINATES_REQUEST = 'address/CHANGE_COORDINATES_REQUEST',
  SET_COORDINATES = 'address/SET_COORDINATES',
  SET_SELECTED_ADDRESS_IN_DELIVERY_MAP = 'address/SET_SELECTED_ADDRESS_IN_DELIVERY_MAP',
  CLEAR_SELECTED_ADDRESS_AND_COORDINATES_REQUEST = 'address/CLEAR_SELECTED_ADDRESS_AND_COORDINATES_REQUEST',
  CLEAR_SELECTED_ADDRESS_AND_COORDINATES_SUCCESS = 'address/CLEAR_SELECTED_ADDRESS_AND_COORDINATES_SUCCESS',
  SET_ADDRESS_SEARCH_VALUE = 'address/SET_ADDRESS_SEARCH_VALUE',
  CHANGE_SEARCH_VALUE_REQUEST = 'address/CHANGE_SEARCH_VALUE_REQUEST',
  SET_FOUND_ADDRESSES = 'address/SET_FOUND_ADDRESSES',
  SELECT_ADDRESS_FROM_FOUND_ADDRESSES_LIST_REQUEST = 'address/SELECT_ADDRESS_FROM_FOUND_ADDRESSES_LIST_REQUEST',
  IMMEDIATE_LOAD_AREA_REGION_OF_DELIVERY_LIST_REQUEST = 'address/IMMEDIATE_LOAD_AREA_REGION_OF_DELIVERY_LIST_REQUEST',
  BACKGROUND_LOAD_AREA_REGION_OF_DELIVERY_LIST_REQUEST = 'address/BACKGROUND_LOAD_AREA_REGION_OF_DELIVERY_LIST_REQUEST',
  IMMEDIATE_LOAD_AREA_REGION_OF_DELIVERY_LIST_SUCCESS = 'address/IMMEDIATE_LOAD_AREA_REGION_OF_DELIVERY_LIST_SUCCESS',
  SET_SELECTED_REGION_OF_DELIVERY_IN_DELIVERY_MAP = 'address/SET_SELECTED_REGION_OF_DELIVERY_IN_DELIVERY_MAP',
  GET_DELIVERY_PRICE_IN_MAP_REQUEST = 'address/GET_DELIVERY_PRICE_IN_MAP_REQUEST',
  SET_DELIVERY_PRICE_IN_MAP = 'address/SET_DELIVERY_PRICE_IN_MAP',
}

const INITIAL_STATE: AddressState = {
  coordinates: {latitude: 53.336209, longitude: 83.75931},
  selectedAddressInDeliveryMap: {
    city: null,
    cityId: null,
    isDefault: null,
    streetType: null,
    streetKladrId: null,
    street: null,
    house: null,
    houseFiasId: null,
    clientId: null,
    latitude: null,
    longitude: null,
    building: null,
  },
  addressSearchValue: null,
  foundAddresses: [],
  areaRegionOfDeliveryList: [],
  selectedRegionOfDeliveryInDeliveryMap: null,
  deliveryPriceInMap: null,
}

export const Actions = {
  changeCoordinatesRequest: (coordinates: LatLng): BaseAction => ({
    type: AddressActionsTypes.CHANGE_COORDINATES_REQUEST,
    payload: coordinates,
  }),

  setCoordinates: (coordinates: LatLng): BaseAction => ({
    type: AddressActionsTypes.SET_COORDINATES,
    payload: coordinates,
  }),

  setSelectedAddressInDeliveryMap: (selectedAddress: ClientAddressDTO): BaseAction => ({
    type: AddressActionsTypes.SET_SELECTED_ADDRESS_IN_DELIVERY_MAP,
    payload: selectedAddress,
  }),

  clearSelectedAddressAndCoordinatesRequest: (): BaseAction => ({
    type: AddressActionsTypes.CLEAR_SELECTED_ADDRESS_AND_COORDINATES_REQUEST,
  }),

  clearSelectedAddressAndCoordinatesSuccess: (): BaseAction => ({
    type: AddressActionsTypes.CLEAR_SELECTED_ADDRESS_AND_COORDINATES_SUCCESS,
  }),

  changeSearchValueRequest: (searchValue: string): BaseAction => ({
    type: AddressActionsTypes.CHANGE_SEARCH_VALUE_REQUEST,
    payload: searchValue,
  }),

  setAddressSearchValue: (addressSearchValue: string): BaseAction => ({
    type: AddressActionsTypes.SET_ADDRESS_SEARCH_VALUE,
    payload: addressSearchValue,
  }),

  setFoundAddresses: (foundAddresses: ClientAddressDTO[]): BaseAction => ({
    type: AddressActionsTypes.SET_FOUND_ADDRESSES,
    payload: foundAddresses,
  }),

  selectAddressFromFoundAddressesListRequest: (addressIndex: number): BaseAction => ({
    type: AddressActionsTypes.SELECT_ADDRESS_FROM_FOUND_ADDRESSES_LIST_REQUEST,
    payload: addressIndex,
  }),

  immediateLoadAreaRegionOfDeliveryListRequest: (): BaseAction => ({
    type: AddressActionsTypes.IMMEDIATE_LOAD_AREA_REGION_OF_DELIVERY_LIST_REQUEST,
  }),

  backgroundLoadAreaRegionOfDeliveryListRequest: (): BaseAction => ({
    type: AddressActionsTypes.BACKGROUND_LOAD_AREA_REGION_OF_DELIVERY_LIST_REQUEST,
  }),

  loadAreaRegionOfDeliverySuccess: (areaOfDeliveryList: AreaOfDeliveryDTO[]): BaseAction => ({
    type: AddressActionsTypes.IMMEDIATE_LOAD_AREA_REGION_OF_DELIVERY_LIST_SUCCESS,
    payload: areaOfDeliveryList,
  }),

  setSelectedRegionOfDeliveryInDeliveryMap: (
    regionOfDelivery: RegionOfDeliveryDTO,
  ): BaseAction => ({
    type: AddressActionsTypes.SET_SELECTED_REGION_OF_DELIVERY_IN_DELIVERY_MAP,
    payload: regionOfDelivery,
  }),

  getDeliveryPriceInMap: (): BaseAction => ({
    type: AddressActionsTypes.GET_DELIVERY_PRICE_IN_MAP_REQUEST,
  }),

  setDeliveryPriceInMap: (deliveryPrice: number): BaseAction => ({
    type: AddressActionsTypes.SET_DELIVERY_PRICE_IN_MAP,
    payload: deliveryPrice,
  }),
}

export function bindAddressActions(dispatch: any) {
  return bindActionCreators(Actions, dispatch)
}

export default function address(
  state: AddressState = INITIAL_STATE,
  action: BaseAction,
): AddressState {
  switch (action.type) {
    case AddressActionsTypes.SET_COORDINATES: {
      return {
        ...state,
        coordinates: action.payload,
      }
    }

    case AddressActionsTypes.SET_SELECTED_ADDRESS_IN_DELIVERY_MAP: {
      return {
        ...state,
        selectedAddressInDeliveryMap: action.payload,
      }
    }

    case AddressActionsTypes.CLEAR_SELECTED_ADDRESS_AND_COORDINATES_SUCCESS: {
      return {
        ...INITIAL_STATE,
        areaRegionOfDeliveryList: state.areaRegionOfDeliveryList,
      }
    }

    case AddressActionsTypes.SET_ADDRESS_SEARCH_VALUE: {
      return {
        ...state,
        addressSearchValue: action.payload,
      }
    }

    case AddressActionsTypes.SET_FOUND_ADDRESSES: {
      return {
        ...state,
        foundAddresses: action.payload,
      }
    }

    case AddressActionsTypes.IMMEDIATE_LOAD_AREA_REGION_OF_DELIVERY_LIST_SUCCESS: {
      return {
        ...state,
        areaRegionOfDeliveryList: action.payload,
      }
    }

    case AddressActionsTypes.SET_SELECTED_REGION_OF_DELIVERY_IN_DELIVERY_MAP: {
      return {
        ...state,
        selectedRegionOfDeliveryInDeliveryMap: action.payload,
      }
    }

    case AddressActionsTypes.SET_DELIVERY_PRICE_IN_MAP: {
      return {
        ...state,
        deliveryPriceInMap: action.payload,
      }
    }

    default: {
      return {
        ...state,
      }
    }
  }
}
