import {ActionState} from '../types'
import {BaseAction} from '../../common/BaseAction'
import {ActionActionsType} from './index'

const INITIAL_STATE: ActionState = {
  actions: undefined,
  selectedAction: null,
  isRequestLoad: false,
  isRequestUpdate: false,
  conditionsMap: {},
  definitionsMap: {},
  availableDefinitions: [],
  selectedCouponDefinitionId: undefined,
}

export default function action(
  state: ActionState = INITIAL_STATE,
  baseAction: BaseAction,
): ActionState {
  switch (baseAction.type) {
    case ActionActionsType.LOAD_ACTIONS_REQUEST: {
      return {
        ...state,
        isRequestLoad: true,
      }
    }

    case ActionActionsType.SET_ACTIONS: {
      return {
        ...state,
        actions: baseAction.payload.actions,
        definitionsMap: baseAction.payload.definitionsMap,
        conditionsMap: baseAction.payload.conditionsMap,
        isRequestLoad: false,
        isRequestUpdate: false,
      }
    }

    case ActionActionsType.SET_SELECTED_ACTION: {
      return {
        ...state,
        selectedAction: baseAction.payload,
      }
    }

    case ActionActionsType.SET_IS_REQUEST_LOAD_ACTIONS: {
      return {
        ...state,
        isRequestLoad: baseAction.payload,
      }
    }

    case ActionActionsType.UPDATE_ACTIONS_REQUEST: {
      return {
        ...state,
        isRequestUpdate: true,
      }
    }

    case ActionActionsType.SET_IS_REQUEST_UPDATE_ACTIONS: {
      return {
        ...state,
        isRequestUpdate: baseAction.payload,
      }
    }

    case ActionActionsType.SET_AVAILABLE_DEFINITIONS: {
      return {
        ...state,
        availableDefinitions: baseAction.payload,
      }
    }

    case ActionActionsType.SET_SELECTED_COUPON_DEFINITION_ID: {
      return {
        ...state,
        selectedCouponDefinitionId: baseAction.payload,
      }
    }

    case ActionActionsType.SET_SELECTED_COUPON_DEFINITION_ID_REQUEST: {
      return {
        ...state,
        selectedCouponDefinitionId: baseAction.payload,
      }
    }

    default: {
      return {
        ...state,
      }
    }
  }
}
