import React, {useMemo} from 'react'
import {ProcessCardItemDTO} from 'grilnica-share'
import {ProductPreview} from './ProductPreview'
import {Button} from 'grilnica-reactstrap'
import {cutToLength} from '../../utils/cutToLength'
import {
  MENU_CATEGORY_ID_FOR_PREVIEW,
  MenuCategoryDTO,
  ProductDTO,
  ProductMap,
  WeightMapDto,
} from 'grilnica-store-share'
import {getProductById} from '../../../../../../../../../../utils/order/getProductById'
import {
  getProductLink,
  getProductPriceBlock,
  getMinDefaultWeight,
} from '../../../../../../../../../../utils/menu'

interface ProductPreviewListProps {
  processProductItems: ProcessCardItemDTO[]
  productMap: ProductMap
  menuCategoryList: MenuCategoryDTO[]
  weightMap: WeightMapDto
}

const ProductPreviewList: React.FC<ProductPreviewListProps> = ({
  processProductItems,
  productMap,
  menuCategoryList,
  weightMap,
}): React.ReactElement => {
  const renderContent: React.ReactElement = useMemo(() => {
    const items: React.ReactElement[] = []
    for (let processProductItem of processProductItems || []) {
      const product: ProductDTO = getProductById(processProductItem.productId, productMap)
      if (product) {
        items.push(
          <ProductPreview
            key={processProductItem.productId}
            product={product}
            priceBlock={getProductPriceBlock(product)}
            productLink={getProductLink(
              product.alias,
              product.menuCategoryId || processProductItem.menuCategoryId,
              menuCategoryList || [],
            )}
            weight={getMinDefaultWeight(product, weightMap)}
            isNotForSale={processProductItem.menuCategoryId === MENU_CATEGORY_ID_FOR_PREVIEW}>
            <Button style={'link'} isRoundedButton size={'sm'} className={'mb-x2 mr-x4'}>
              {cutToLength(processProductItem.productName)}
            </Button>
          </ProductPreview>,
        )
      }
    }
    if (processProductItems?.length > 0) {
      return <div className={'px-x4 mb-x3 pt-x1 d-flex flex-wrap'}>{items}</div>
    } else {
      return null
    }
  }, [menuCategoryList, processProductItems, productMap, weightMap])

  return <>{renderContent}</>
}

export {ProductPreviewList}
