declare var SITE_SERVER: string
declare var STORE_SERVER: string
declare var AUTH_SERVER: string
declare var CDN_SERVER: string
declare var SOCKET_API: string

const CONSTANTS = {
  apiStoreUrl: STORE_SERVER + '/api',
  siteUrl: SITE_SERVER,
  siteCdnUrl: SITE_SERVER + '/cdn',
  authUrl: AUTH_SERVER,
  authUrlApi: AUTH_SERVER + '/api',
  cdnUrl: CDN_SERVER,
  cdnUrlUpload: CDN_SERVER + '/upload',
  socketUrl: SOCKET_API,
  siteSocketUrl: SITE_SERVER + '/socket',
  subsystemId: 'b9965c24-608c-4161-89b9-e2969669871f',
  subsystemLogin: 'site',
  subsystemPassword: 'aEdSA#ff',
  subsystemName: 'Сайт',
}

export {CONSTANTS}
