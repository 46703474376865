import React, {useMemo} from 'react'
import {CatalogListItem} from './CatalogListItem'
import {Row} from 'grilnica-reactstrap'
import {MenuCategoryDTO} from 'grilnica-store-share'
import {CityDTO} from 'grilnica-share'

interface CatalogListProps {
  categoryList: MenuCategoryDTO[]
  selectedCity: CityDTO
}

const CatalogList: React.FC<CatalogListProps> = ({
  categoryList,
  selectedCity,
}): React.ReactElement => {
  const renderContent: React.ReactElement = useMemo(() => {
    return (
      <Row className={'catalog-list'}>
        {categoryList.map((menu) => {
          return (
            !menu.parentMenuCategoryId && (
              <CatalogListItem key={menu.menuCategoryId} menu={menu} selectedCity={selectedCity} />
            )
          )
        })}
      </Row>
    )
  }, [categoryList, selectedCity])
  return <>{renderContent}</>
}

export {CatalogList}
