import React, {useEffect, useMemo, useRef, useState} from 'react'
import {getStaticUrl} from '../../../utils/static'

interface LazyImageProps {
  className?: string
  src: string
  alt: string
  title: string
  typePlaceholder: 'catalog-category' | 'product' | 'restaurant'
}

const LazyImage: React.FC<LazyImageProps> = ({
  className,
  src,
  alt,
  title,
  typePlaceholder,
}): React.ReactElement => {
  const [loaded, setLoaded] = useState(false)

  let imgRef: React.MutableRefObject<HTMLImageElement> = useRef(null)

  useEffect(() => {
    if (imgRef?.current) {
      setLoaded(true)
    }
  }, [imgRef])

  const renderContent: React.ReactElement = useMemo(() => {
    return (
      <div className={'lazy-image'}>
        {!loaded && (
          <img
            src={getStaticUrl(`/img/placeholder-${typePlaceholder}.svg`)}
            alt={alt}
            title={title}
            width={'100%'}
            height={'auto'}
            aria-hidden={'true'}
            className={'img'}
          />
        )}
        <img
          loading={'lazy'}
          src={src}
          alt={alt}
          title={title}
          ref={imgRef}
          width={'100%'}
          height={'auto'}
          className={'img source ' + (loaded ? 'loaded' : '')}
        />
      </div>
    )
  }, [loaded, typePlaceholder, alt, title, src])

  return <>{renderContent}</>
}

export {LazyImage}
