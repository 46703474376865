import React, {useMemo} from 'react'
import {Button, ButtonIcon, ButtonLink} from 'grilnica-reactstrap'
import {instagramSVG, logoSVG, vkSVG, youtubeSVG} from '../svg'
import {CityDTO} from 'grilnica-share'
import {ClientDTO, ContactDetailsMap} from 'grilnica-store-share'
import {getFullUrl} from '../../../utils/url'
import {onChangePhone, reFormatPhone} from '../../site/utils/UserUtils'
import {arrowLeftSVG, logoutSVG} from '../svg/SystemIcons'
import {ymTarget} from '../../../utils/metrica/ym/ymTarget'
import {gaTarget} from '../../../utils/metrica/ga/gaTarget'
import {getStaticUrl} from '../../../utils/static'

interface MenuModalProps {
  onClose: () => void | Promise<void>
  selectedCity: CityDTO
  onOpenSelectCityModal: () => void
  client: ClientDTO
  urlPrefix: string
  contactDetailsMap: ContactDetailsMap
}

const MenuModal: React.FC<MenuModalProps> = ({
  onClose,
  selectedCity,
  onOpenSelectCityModal,
  client,
  urlPrefix,
  contactDetailsMap,
}) => {
  const renderSocialBlock: React.ReactElement = useMemo(() => {
    if (
      selectedCity &&
      (selectedCity.youtubeUrl || selectedCity.instagramUrl || selectedCity.vkUrl)
    ) {
      return (
        <div className={'pb-secondary'}>
          {selectedCity.youtubeUrl && (
            <a className={'mr-x2 ml-x2'} title={'Youtube'} href={selectedCity.youtubeUrl}>
              {youtubeSVG}
            </a>
          )}
          {selectedCity.instagramUrl && (
            <a className={'mr-x2 ml-x2'} title={'Instagram'} href={selectedCity.instagramUrl}>
              {instagramSVG}
            </a>
          )}
          {selectedCity.vkUrl && (
            <a title={'VK'} className={'mr-x2 ml-x2'} href={selectedCity.vkUrl}>
              {vkSVG}
            </a>
          )}
        </div>
      )
    }
  }, [selectedCity])

  const renderContent: React.ReactElement = useMemo(() => {
    let phoneNumber: string = null
    let additionalPhoneNumber: string = null
    if (selectedCity?.cityId) {
      phoneNumber = contactDetailsMap[selectedCity.cityId]?.phoneNumber
      additionalPhoneNumber = contactDetailsMap[selectedCity.cityId]?.additionalPhoneNumber
    } else {
      phoneNumber = contactDetailsMap.default.phoneNumber
    }

    return (
      <div className={'menu-modal'}>
        <div className={'content'}>
          <div className={'header'}>
            <ButtonIcon
              iconPath={arrowLeftSVG}
              isHoverArea
              onClick={() => {
                onClose()
              }}
              title={'Меню'}
            />
            <div className={'logo'} title={'Грильница'}>
              {logoSVG}
            </div>
          </div>
          <div className={'body'}>
            {selectedCity && (
              <div className={'block-select-city'}>
                <Button
                  isRoundedButton
                  size={'sm'}
                  style={'text'}
                  onClick={() => {
                    onOpenSelectCityModal()
                  }}>
                  {selectedCity.name}
                </Button>
              </div>
            )}
            <nav className={'block-nav-site'}>
              <ul>
                <li>
                  <a href={getFullUrl('/actions', urlPrefix)}>Акции</a>
                </li>
                <li>
                  <a href={getFullUrl('/rest', urlPrefix)}>Рестораны</a>
                </li>
                <li>
                  <a href={getFullUrl('/shipping', urlPrefix)}>Доставка</a>
                </li>
                <li>
                  <a href={getFullUrl('/qualitycontrol', urlPrefix)}>Контроль качества</a>
                </li>
                <li>
                  <a href={'https://career.grilnica.ru/'}>Карьера</a>
                </li>
                <li>
                  <a href={'https://vkusnayafranshiza.ru/'}>Франшиза</a>
                </li>
              </ul>
            </nav>
            <div className={'profile'}>
              {selectedCity ? (
                client ? (
                  <Button
                    style={'text'}
                    className={'font-weight-bold'}
                    onClick={() => {
                      window.location.href = getFullUrl('/profile', urlPrefix)
                    }}
                    isRoundedButton>
                    {client.firstName
                      ? client.firstName
                      : onChangePhone(client.phone, client.phone)}
                  </Button>
                ) : (
                  <ButtonLink
                    isRoundedButton
                    size={'sm'}
                    href={getFullUrl('/login', urlPrefix)}
                    iconPosition={'left'}
                    iconPath={logoutSVG}
                    style={'text'}
                    className={'d-flex align-items-center font-weight-bold'}>
                    Войти
                  </ButtonLink>
                )
              ) : null}
            </div>
          </div>
          <div className={'footer'}>
            {phoneNumber && (
              <a
                className={'menu-phone ' + (additionalPhoneNumber ? 'mb-secondary ' : '')}
                onClick={() => {
                  ymTarget('ZVONOK')
                  gaTarget('click-call', 'callback')
                }}
                href={'tel:' + reFormatPhone(phoneNumber)}>
                {reFormatPhone(phoneNumber)}
              </a>
            )}
            {additionalPhoneNumber && (
              <a
                className={'menu-phone'}
                onClick={() => {
                  ymTarget('ZVONOK')
                  gaTarget('click-call', 'callback')
                }}
                href={'tel:' + reFormatPhone(additionalPhoneNumber)}>
                {reFormatPhone(additionalPhoneNumber)}
              </a>
            )}
            {selectedCity && renderSocialBlock}
            <div className={'pb-secondary'}>
              <a
                className={'mr-x4'}
                href={'https://apps.apple.com/ru/app/grilnicaapp/id1536865948'}>
                <img
                  alt={'Приложение Грильница для IOS'}
                  title={'Приложение Грильница для IOS'}
                  src={getStaticUrl('/img/app-store-download.svg')}
                />
              </a>
              <a href={'https://play.google.com/store/apps/details?id=ru.grilnica.mobile'}>
                <img
                  alt={'Приложение Грильница для Android'}
                  title={'Приложение Грильница для Android'}
                  src={getStaticUrl('/img/google-play-download.svg')}
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    )
  }, [
    client,
    contactDetailsMap,
    onClose,
    renderSocialBlock,
    onOpenSelectCityModal,
    selectedCity,
    urlPrefix,
  ])

  return <React.Fragment>{renderContent}</React.Fragment>
}
export {MenuModal}
