import React, {useCallback, useMemo} from 'react'
import {ButtonIcon, COLORS, ThemeColor} from 'grilnica-reactstrap'
import {Spinner} from 'reactstrap'
import {closeSVG} from '../svg/SystemIcons'

interface ToastProps {
  toastText: string
  color?: 'gray' | 'danger' | 'success' | 'warning'
  isLoading?: boolean
  className?: string
  onClick?: () => void
  isShow: boolean
  position?: 'top-left' | 'top-right' | 'top' | 'center' | 'bottom-left' | 'bottom-right' | 'bottom'
  isTerminal?: boolean
}

const Toast: React.FC<ToastProps> = ({
  toastText,
  color,
  isLoading,
  className,
  isShow,
  position,
  onClick,
  isTerminal,
}): React.ReactElement => {
  const getPositionToast = useMemo(() => {
    switch (position) {
      case 'top-left':
        return 'top-left'
      case 'top-right':
        return 'top-right'
      case 'top':
        return 'top'
      case 'center':
        return 'center'
      case 'bottom-left':
        return 'bottom-left'
      case 'bottom-right':
        return 'bottom-right'
      default:
        return 'bottom'
    }
  }, [position])

  const getStyleToast = useMemo(() => {
    switch (color) {
      case 'danger':
        return 'toast-danger'
      case 'success':
        return 'toast-success'
      case 'warning':
        return 'toast-warning'
      default:
        return 'toast-gray'
    }
  }, [color])

  const getColorIcon = useMemo(() => {
    switch (color) {
      case 'danger':
        return COLORS.white
      case 'success':
        return COLORS.white
      case 'warning':
        return COLORS.gray800
      default:
        return COLORS.gray100
    }
  }, [color])

  const classNameResult = isShow
    ? 'gr-toast ' +
      getStyleToast +
      ' ' +
      getPositionToast +
      ' ' +
      (isTerminal ? 'terminal ' : '') +
      (className ? className : '')
    : 'd-none'

  const getHoverIconColorCloseButton = useCallback((): ThemeColor => {
    if (!color) {
      return 'white'
    }
    switch (color) {
      case 'danger':
        return 'white'
      case 'warning':
        return 'white'
      case 'success':
        return 'white'
    }
  }, [color])

  return (
    <div className={classNameResult}>
      <div className={'d-flex align-items-center '}>
        {isLoading && <Spinner className={'mr-x4'} color={getColorIcon} />}
        <p className={'mb-0 ' + (!isLoading ? 'mr-x4' : '')}>{toastText}</p>
        {!isLoading && (
          <ButtonIcon
            iconPath={closeSVG}
            isHoverArea
            color={getColorIcon}
            hoverIconColor={getHoverIconColorCloseButton()}
            title={'Закрыть'}
            onClick={onClick ? onClick : undefined}
          />
        )}
      </div>
    </div>
  )
}

export {Toast}
