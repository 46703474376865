import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {PrePaymentComponent, PrepaymentOrderDTO} from './components/PrePaymentComponent'
import {
  createPrepaymentOrderPaymentService,
  loadPrepaymentOrderService,
} from '../../../../../services/payment'
import {bindErrorActions} from '../../../../../store/ducks/error'
import {Container} from 'grilnica-reactstrap'
import {connectComponent} from '../../../../../store/common'

interface PrePaymentPageProps {
  prepaymentOrderId: string
  errorActions: any
}

const PrePaymentPage: React.FC<PrePaymentPageProps> = ({
  prepaymentOrderId,
  errorActions,
}): React.ReactElement => {
  const [prepaymentOrder, setPrepaymentOrder] = useState<PrepaymentOrderDTO>(null)
  const [formUrl, setFormUrl] = useState<string>(null)
  const [isShowButton, setIsShowButton] = useState(false)

  const loadPrepaymentOrder = useCallback(async () => {
    const newPrepaymentOrder: PrepaymentOrderDTO = await loadPrepaymentOrderService(
      prepaymentOrderId,
      errorActions,
      null, //TODO потом можно добавить ID города
    )

    setPrepaymentOrder(newPrepaymentOrder)
    return newPrepaymentOrder
  }, [errorActions, prepaymentOrderId])

  const createPayment = useCallback(async () => {
    const newPrepaymentOrder: PrepaymentOrderDTO = await loadPrepaymentOrder()
    if (newPrepaymentOrder) {
      const newFormUrl: string = await createPrepaymentOrderPaymentService(
        newPrepaymentOrder.prepaymentOrderId,
        errorActions,
        null, //TODO потом можно добавить ID города
      )
      setFormUrl(newFormUrl)
    }
  }, [errorActions, loadPrepaymentOrder])

  useEffect(() => {
    const newTimer = setInterval(() => {
      setIsShowButton(true)
      clearInterval(newTimer)
    }, 120000)
    createPayment()
    return () => {
      clearInterval(newTimer)
    }
  }, [createPayment])

  const renderContent: React.ReactElement = useMemo(() => {
    return (
      <Container size={'normal'} className={'mt-x10 mb-x10'}>
        <PrePaymentComponent
          prepaymentOrder={prepaymentOrder}
          formUrl={formUrl}
          onReCreatePayment={createPayment}
          isShowButton={isShowButton}
        />
      </Container>
    )
  }, [createPayment, formUrl, isShowButton, prepaymentOrder])

  return <>{renderContent}</>
}

export default connectComponent(
  (state: any, props: any) => ({
    prepaymentOrderId: props.match.params.prepaymentOrderId,
  }),
  (dispatch: any) => ({
    errorActions: bindErrorActions(dispatch),
  }),
  PrePaymentPage,
)
