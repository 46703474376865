import React, {useCallback, useMemo} from 'react'
import {OptionDTO, SelectedProductDTO} from 'grilnica-store-share'
import {InputSelect, InputSelectOption} from 'grilnica-reactstrap'
import {OptionCategoriesList} from './card/OptionCategoriesList'
import {RestrictionsMap} from '../../../../../../../../../types/product/RestrictionsMap'
import {getActiveOptionCategoryId} from '../utils/getActiveOptionCategoryId'

interface ProductOptionsProps {
  selectedProduct: SelectedProductDTO
  restrictionsMap: RestrictionsMap
  toggleOption: (optionCategoryId: string, optionId: string) => void
}

const ProductOptions: React.FC<ProductOptionsProps> = ({
  selectedProduct,
  toggleOption,
  restrictionsMap,
}): React.ReactElement => {
  const getDefaultValueOption = useCallback((options: OptionDTO[]): InputSelectOption => {
    for (let item of options) {
      if (item.restrictions.isDefault) {
        return {
          label: item.name,
          value: item.optionId,
        }
      }
    }
  }, [])

  const renderContent: React.ReactElement = useMemo(() => {
    let renderOptions: React.ReactElement[] = []
    if (selectedProduct && selectedProduct.product && selectedProduct.product.optionCategories) {
      for (let newOption of selectedProduct.product.optionCategories) {
        if (
          restrictionsMap[newOption.optionCategoryId].max === 1 &&
          restrictionsMap[newOption.optionCategoryId].min === 1
        ) {
          if (newOption.isShowIcon) {
            renderOptions.push(
              <OptionCategoriesList
                key={newOption.optionCategoryId}
                optionCategory={newOption}
                activeOptionCategoryId={getActiveOptionCategoryId(
                  selectedProduct.selectedOptions,
                  newOption.optionCategoryId,
                )}
                onChangeOption={(isProductVariant: boolean, optionId: string) => {
                  toggleOption(newOption.optionCategoryId, optionId)
                }}
              />,
            )
          } else {
            renderOptions.push(
              <InputSelect
                id={
                  'input-option-' + newOption.optionCategoryId + selectedProduct.product?.productId
                }
                instanceId={
                  'instance-' + newOption.optionCategoryId + selectedProduct.product?.productId
                }
                key={newOption.optionCategoryId}
                options={newOption.options.map((item) => {
                  return {
                    label: item.name,
                    value: item.optionId,
                  }
                })}
                defaultValue={getDefaultValueOption(newOption.options)}
                size={'sm'}
                isSearchable={false}
                className={'selected-addition'}
                onChange={(value: InputSelectOption) => {
                  toggleOption(newOption.optionCategoryId, value.value)
                }}
                name={'colors'}
                isRounded
              />,
            )
          }
        }
      }
    }
    return (
      <div className={'options ' + (renderOptions.length !== 0 ? 'mt-n-x1' : '')}>
        {renderOptions}
      </div>
    )
  }, [selectedProduct, restrictionsMap, toggleOption, getDefaultValueOption])

  return <>{renderContent}</>
}

export {ProductOptions}
