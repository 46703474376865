import React, {useMemo} from 'react'
import {DeliveryTypeEnum, PrepareOrderTime} from 'grilnica-store-share'
import {Divider, Loading} from 'grilnica-reactstrap'
import {formatDate, formatDateTime, getIntervalTime, round, transformVariable} from 'grilnica-share'
import {formatDeliveryTime} from 'grilnica-share/lib/utils'
import moment from 'moment-timezone'

interface OrderFullPriceProps {
  deliveryPrice: number
  deliveryType: DeliveryTypeEnum
  priceFull: number
  priceResult: number
  deliverySale: number
  prepareTime: PrepareOrderTime
  isSeparateDeliveryPayment: boolean
  deliveryTimeInMinutes: number
  isCheckActions: boolean
  isErrorUpdateCheckActions: boolean
}

const OrderFullPrice: React.FC<OrderFullPriceProps> = ({
  deliveryType,
  deliveryPrice,
  priceFull,
  priceResult,
  deliverySale,
  prepareTime,
  isSeparateDeliveryPayment,
  deliveryTimeInMinutes,
  isCheckActions,
  isErrorUpdateCheckActions,
}): React.ReactElement => {
  const renderOrderPrice = useMemo(() => {
    const isSeparateDeliveryPaymentReal = isSeparateDeliveryPayment && !!deliveryPrice
    return (
      <>
        {(priceFull - priceResult > 0 || !!deliverySale) && (
          <div className={'order-price'}>
            <p>Без скидки:</p>
            {isErrorUpdateCheckActions ? (
              <p className={'price'}>-</p>
            ) : (
              <p className={'price'}>{round(priceFull)} &#8381;</p>
            )}
          </div>
        )}
        {!!deliverySale && (
          <div className={'order-price'}>
            <p>Бонус за доставку {deliverySale}:</p>
            <p className={'price'}>-{deliverySale} &#8381;</p>
          </div>
        )}
        {priceFull - priceResult > 0 && (
          <div className={'order-price'}>
            <p>Скидки по акциям:</p>
            {isCheckActions ? (
              <Loading isNoneWidth size={'m'} />
            ) : isErrorUpdateCheckActions ? (
              <p className={'price'}>-</p>
            ) : (
              <p className={'price'}>-{round(priceFull - priceResult)} &#8381;</p>
            )}
          </div>
        )}
        {deliveryType === DeliveryTypeEnum.DELIVERY && (
          <>
            <div className={'order-price mb-x4'}>
              <p>Сумма заказа:</p>
              {isCheckActions ? (
                <Loading isNoneWidth size={'m'} />
              ) : isErrorUpdateCheckActions ? (
                <p className={'price'}>-</p>
              ) : (
                <p className={'price'}>
                  {round(priceResult) - transformVariable(deliverySale, 0)} &#8381;
                </p>
              )}
            </div>
            <Divider isDashed className={'mb-x4'} />
            <div
              className={
                'order-price ' + (isSeparateDeliveryPaymentReal ? 'separate-delivery' : '')
              }>
              <p>Стоимость доставки:</p>
              {(deliveryPrice || deliveryPrice === 0) && !isErrorUpdateCheckActions ? (
                <p className={'price'}>{round(deliveryPrice)} &#8381;</p>
              ) : (
                <p className={'price'}>-</p>
              )}
            </div>
            {isSeparateDeliveryPaymentReal && (
              <p className={'separate-delivery-block caption'}>(оплачивается курьеру отдельно)</p>
            )}
            <div className={'order-price mb-x4'}>
              {prepareTime?.isQuickly ? (
                <>
                  <p>Доставим в течение:</p>
                  <p className={'price'}>
                    {deliveryTimeInMinutes && !isErrorUpdateCheckActions
                      ? formatDeliveryTime(deliveryTimeInMinutes)
                      : '-'}
                  </p>
                </>
              ) : (
                <>
                  <p>Доставим:</p>
                  <p className={'price'}>
                    {isErrorUpdateCheckActions
                      ? '-'
                      : deliveryType === DeliveryTypeEnum.DELIVERY
                      ? prepareTime.orderTime
                        ? formatDate(prepareTime.orderTime) +
                          ' ' +
                          getIntervalTime(
                            moment(prepareTime.orderTime).format('HH:mm'),
                            15,
                            15,
                            'с --:-- до --:--',
                          )
                        : '-'
                      : formatDateTime(prepareTime.orderTime)}
                  </p>
                </>
              )}
            </div>
            <Divider isDashed className={'mb-x4'} />
          </>
        )}
        {deliveryType === DeliveryTypeEnum.PICKUP && (
          <>
            {priceFull - priceResult > 0 && <Divider isDashed className={'mb-x4'} />}
            <div className={'order-price mb-x4'}>
              <p>Приготовим:</p>
              <p className={'price'}>
                {isErrorUpdateCheckActions
                  ? '-'
                  : prepareTime?.isQuickly
                  ? 'как можно скорее'
                  : formatDateTime(prepareTime.orderTime)}
              </p>
            </div>
            <Divider isDashed className={'mb-x4'} />
          </>
        )}
        <div className={'order-price total-price mb-x4'}>
          <p>Итого:</p>
          {isCheckActions ? (
            <Loading isNoneWidth size={'m'} />
          ) : isErrorUpdateCheckActions ? (
            <p className={'price'}>-</p>
          ) : (
            <p className={'price'}>
              {priceResult +
                (deliveryType === DeliveryTypeEnum.DELIVERY && !deliverySale
                  ? deliveryPrice
                    ? deliveryPrice
                    : 0
                  : 0) +
                ' '}
              &#8381;
            </p>
          )}
        </div>

        <Divider className={'mb-x4'} />
      </>
    )
  }, [
    isSeparateDeliveryPayment,
    deliveryPrice,
    isErrorUpdateCheckActions,
    priceFull,
    isCheckActions,
    priceResult,
    deliveryType,
    deliverySale,
    prepareTime?.isQuickly,
    prepareTime.orderTime,
    deliveryTimeInMinutes,
  ])

  return <>{renderOrderPrice}</>
}

export {OrderFullPrice}
