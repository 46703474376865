import React from 'react'
import {Route, Switch} from 'react-router-dom'
import SingUpPage from '../SingUpPage'
import {State} from '../../../../../store/ducks'
import {connectComponent} from '../../../../../store/common'
import {ClientDTO} from 'grilnica-store-share'
import {NotFoundPage} from '../../../../components/common/NotFoundPage'

interface AuthLayoutProps {
  client: ClientDTO
}

const AuthLayout: React.FC<AuthLayoutProps> = ({client}): React.ReactElement => {
  const defaultRoute = '/login'
  return (
    <Switch>
      <Route exact path={defaultRoute} component={SingUpPage} />
      <Route path={'/*'} component={NotFoundPage} />
    </Switch>
  )
}

export default connectComponent(
  (state: State, props: any) => ({
    client: state.auth.client,
  }),
  (dispatch) => ({}),
  AuthLayout,
)
