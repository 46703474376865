import React, {useMemo} from 'react'
import {connectComponent} from '../../../../../store/common'
import {State} from '../../../../../store/ducks'
import {ProductWeekOrDayComponent} from './ProductWeekOrDayComponent'
import {MenuCategoryDTO, ProductDTO, ProductMap, RestaurantByProductMap} from 'grilnica-store-share'
import {HappyHourAction} from '../../../../../store/action/types/HappyHourActionMap'

interface ProductWeekOrDayContainerProps {
  productMap: ProductMap
  happyHourDay: HappyHourAction
  happyHourWeek: HappyHourAction
  restaurantByProductMap: RestaurantByProductMap
  categoryList: MenuCategoryDTO[]
}

export interface HappyHourProducts {
  productOfWeek: ProductDTO
  productOfDay: ProductDTO
}

const ProductWeekOrDayContainer: React.FC<ProductWeekOrDayContainerProps> = ({
  productMap,
  happyHourWeek,
  happyHourDay,
  restaurantByProductMap,
  categoryList,
}): React.ReactElement => {
  const renderContent: React.ReactElement = useMemo(() => {
    return (
      <ProductWeekOrDayComponent
        productMap={productMap}
        restaurantByProductMap={restaurantByProductMap}
        categoryList={categoryList}
        happyHourDay={happyHourDay}
        happyHourWeek={happyHourWeek}
      />
    )
  }, [categoryList, happyHourDay, happyHourWeek, productMap, restaurantByProductMap])
  return <>{renderContent}</>
}

export default connectComponent(
  (state: State, props: any) => ({
    alias: props.match.params.alias,
    childAlias: props.match.params.childAlias,
    productMap: state.menu.productMap,
    categoryList: state.menu.categoryList,
    isShowRestaurantByProductMap: state.restaurantProduct.isShowRestaurantByProductMap,
    initialize: state.initialize,
    restaurantByProductMap: state.restaurantProduct.restaurantByProductMap,
    happyHourDay: state.common.happyHoursActions?.day
      ? state.common.happyHoursActions?.day[state.city?.selectedCity?.cityId]
      : null,
    happyHourWeek: state.common.happyHoursActions?.week
      ? state.common.happyHoursActions?.week[state.city?.selectedCity?.cityId]
      : null,
  }),
  () => ({}),
  ProductWeekOrDayContainer,
)
