import {apiGet} from '../../api'
import {CONSTANTS} from '../../../constants'
import {PrepaymentOrderDTO} from '../../view/site/containers/payment/prePayment/components/PrePaymentComponent'
import {PaymentStatusDto} from 'grilnica-share'

const loadPrepaymentOrderService = async (
  prepaymentOrderId: string,
  errorActions: any,
  cityId: string,
): Promise<PrepaymentOrderDTO> => {
  return await apiGet(CONSTANTS.apiStoreUrl + '/order/prepayment/' + prepaymentOrderId, {
    cityId: cityId,
  })
}

const createPrepaymentOrderPaymentService = async (
  prepaymentOrderIs: string,
  errorActions: any,
  cityId: string,
): Promise<string> => {
  return await apiGet(
    CONSTANTS.apiStoreUrl + '/payment/create-prepayment-order-sber-payment/' + prepaymentOrderIs,
    {
      cityId: cityId,
    },
  )
}

async function createPaymentOnlineCardService(orderId: string, cityId: string) {
  return await apiGet(CONSTANTS.apiStoreUrl + '/payment/create-sber-payment/' + orderId, {
    timeout: 600000,
    cityId: cityId,
  })
}

async function cancelPaymentOnlineCardService(sberOrderId: string, cityId: string) {
  return await apiGet(CONSTANTS.apiStoreUrl + '/payment/cancel-sber-payment/' + sberOrderId, {
    cityId: cityId,
  })
}

async function checkPaymentStatusService(
  paymentId: string,
  cityId: string,
): Promise<PaymentStatusDto> {
  return await apiGet(CONSTANTS.apiStoreUrl + '/payment/check/' + paymentId, {
    cityId: cityId,
  })
}

export {
  loadPrepaymentOrderService,
  createPrepaymentOrderPaymentService,
  createPaymentOnlineCardService,
  cancelPaymentOnlineCardService,
  checkPaymentStatusService,
}
