import {bindActionCreators} from 'redux'
import {BaseAction} from '../../common/BaseAction'
import {ContactDetailsActionsTypes} from './types'
import {ContactDetailsMap} from 'grilnica-store-share'

export const Actions = {
  setContactDetailsMap: (contactDetailsMap: ContactDetailsMap): BaseAction => ({
    type: ContactDetailsActionsTypes.SET_CONTACT_DETAILS_MAP,
    payload: contactDetailsMap,
  }),
}

export type ContactDetailsActionsType = typeof Actions

export function bindContactDetailsActions(dispatch: any) {
  return bindActionCreators(Actions, dispatch)
}
