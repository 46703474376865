import React, {useMemo} from 'react'
import {Toast} from './Toast'
import {connectComponent} from '../../../store/common'
import {bindProductActions} from '../../../store/ducks/product'
import {State} from '../../../store/ducks'

interface ToastComponentProps {
  productPopoverText: string
  productAction: any
  isTerminal: boolean
}

const ToastComponent: React.FC<ToastComponentProps> = ({
  productPopoverText,
  productAction,
  isTerminal,
}): React.ReactElement => {
  const renderContent: React.ReactElement = useMemo(() => {
    return (
      <Toast
        toastText={productPopoverText}
        isShow={!!productPopoverText}
        position={'bottom'}
        isTerminal={isTerminal}
        onClick={() => {
          productAction.setProductPopoverText(null)
        }}
      />
    )
  }, [isTerminal, productAction, productPopoverText])

  return <>{renderContent}</>
}
export default connectComponent(
  (state: State) => ({
    productPopoverText: state.product.productPopoverText,
    isTerminal: state.terminal?.terminalAlias?.length > 0,
  }),
  (dispatch) => ({
    productAction: bindProductActions(dispatch),
  }),
  ToastComponent,
)
